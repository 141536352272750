import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 20px;
  background-color: ${colors.secondary};
  ${props =>
    props.isThem &&
    `
    background-color: ${colors.white};
  `}
`

const MessageText = styled.p`
  margin: 0px;
  font-size: ${fontSizes.small};
  white-space: pre-wrap;
  color: ${colors.white};

  ${props =>
    props.isThem &&
    `
      background-color: ${colors.white};
      color: ${colors.regular};
    
  `}
`

const TranslatedText = styled(MessageText)`
  font-style: italic;
  color: ${colors.mediumLightGrey};
  ${props =>
    props.isThem &&
    `
      color: ${colors.mediumGrey};
    `}
`

const Message = ({ event, isThem, translatedText }) => {
  const isTranslated = !!translatedText
  return (
    <Wrapper isThem={isThem}>
      <MessageText isThem={isThem}>{event?.event_info?.message}</MessageText>
      {isTranslated && (
        <TranslatedText isThem={isThem}>
          {`"`}
          {translatedText}
          {`"`}
        </TranslatedText>
      )}
    </Wrapper>
  )
}

export default React.memo(Message)
