import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useRole from 'hooks/use-role'
import AlertBanner from 'components/alert-banner'
import isVerified from 'utils/is-verified'

const VerifiedBanner = ({ user, listing, loading, ...rest }) => {
  const role = useRole()
  const { t } = useTranslation()

  const listingId = listing?.listing_id
  const messages = useRef({
    'tenant-unverified': {
      sticky: true,
      status: 'warning',
      timeout: 10000,
      message: t(
        'l.chat.view.banner.renter_unverified.text',
        'Heads up! This renter does not have a verified account. Proceed with caution, or request that they verify their profile.'
      )
    },
    'tenant-verified': {
      hide: loading,
      sticky: false,
      status: 'confirmation',
      message: t(
        'l.chat.view.banner.renter_verified.text',
        'Good news! This renter has been ID verified.'
      )
    },
    'landlord-verified-verified': {
      hide: loading,
      sticky: false,
      status: 'confirmation',
      message: t(
        'r.chat.view.banner.landlord_both_verified.text',
        'Good news! This landlord has been ID verified. This listing has also been address verified.'
      )
    },
    'landlord-unverified-unverified': {
      sticky: true,
      timeout: 10000,
      status: 'warning',
      message: t(
        'r.chat.view.banner.landlord_both_unverified.text',
        'Heads up! This landlord does not have a verified account or listing. Proceed with caution, or ask that they verify their profile.'
      )
    },
    'landlord-verified-unverified': {
      sticky: true,
      timeout: 10000,
      status: 'warning',
      message: t(
        'r.chat.view.banner.landlord_account_verified.text',
        "Just a heads up - this landlord's account is verified, but their listing is not. Proceed with caution."
      )
    },
    'landlord-unverified-verified': {
      sticky: true,
      timeout: 10000,
      status: 'warning',
      message: t(
        'r.chat.view.banner.landlord_listing_verified.text',
        "Just a heads up - this listing is verified, but the landlord's account is not. Proceed with caution, or request that the landlord verify their profile."
      )
    }
  })

  const getProps = () => {
    const listingVerified = listing?.verified_state_machine === 'approved'
    const idVerified = isVerified(user, user?.company)
    const toKey = value => (value ? 'verified' : 'unverified')
    const key =
      role !== 'tenant'
        ? `tenant-${toKey(idVerified)}`
        : `landlord-${toKey(idVerified)}-${toKey(listingVerified)}`
    return messages.current[key] || {}
  }
  const { message, hide, status, sticky, timeout } = getProps()
  if (!message || hide) return null
  return (
    <AlertBanner
      {...rest}
      sticky={sticky}
      type={status}
      timeout={timeout}
      listingId={listingId}
      message={message}
    />
  )
}

export default React.memo(VerifiedBanner)
