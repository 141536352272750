/* eslint-disable react/display-name */
import React from 'react'
import styled from 'styled-components'
import { colors, fontWeights, fontSizes } from 'styles'
import { path, toPairs } from 'ramda'
import { useTranslation } from 'react-i18next'
import toArray from 'utils/to-array'
import Icon from 'components/icon'

const DetailsWrapper = styled.div`
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 0 0 10px 10px;
  border-top: 0;
  margin-top: -8px;
  padding-top: 10px;

  @media print {
    border: none;
  }
`

const DetailSection = styled.div`
  margin: 0 15px 20px;
  border-bottom: 1px solid ${colors.mediumLightGrey};

  :last-child {
    border-bottom: none;
  }
`

const SectionTitle = styled.p`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 10px 0 3px;
`

const SectionRatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const RatingTitle = styled.p`
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  margin: 0 0 5px;
  color: ${({ color }) => color};
`

const RatingValue = styled.div`
  font-size: 11px;
  letter-spacing: 0.07px;
  line-height: 13px;

  p {
    display: inline;
    color: ${colors.darkGrey};
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeights.medium};
    letter-spacing: -0.39px;
    line-height: 20px;
  }

  span {
    color: ${colors.mediumGrey};
    margin-left: 2px;
  }
`

const LivCardDescription = styled.p`
  font-size: 11px;
  letter-spacing: 0.07px;
  line-height: 13px;
  margin: 5px 0 20px;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;

  :last-child {
    margin-bottom: 20px;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
`

const ItemLabel = styled.p`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.32px;
  line-height: 21px;
  margin: 0;
`

const ItemValue = styled.p`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.26px;
  line-height: 21px;
  color: ${({ color }) => color};
  margin: 0;
`

const RecordsText = styled.p`
  font-size: 11px;
  margin: 0 0 0 10px;
`

const RecordWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;

  p {
    font-size: 11px;
    line-height: 18px;
    margin: 0;
  }

  svg {
    margin-right: 5px;
  }
`

const COLORS = {
  excellent: '#468C19',
  average: '#F7C40A',
  poor: '#DC0027',
  verified: '#468C19',
  not_verified: '#DC0027',
  pending: '#9B9B9B',
  failed: '#DC0027',
  passed: '#468C19'
}

const CardDetails = ({
  data,
  userRole,
  professionId,
  csoVerifiedState,
  salarySort,
  listingData
}) => {
  const { t } = useTranslation()

  const SCORES = {
    excellent: t('b.liv_score_card.view.excellent.text', 'Excellent'),
    average: t('b.liv_score_card.view.average.text', 'Average'),
    'very good': t('b.liv_score_card.view.very_good.text', 'Very Good'),
    poor: t('b.liv_score_card.view.poor.text', 'Poor'),
    verified: t('b.liv_score_card.view.verified.text', 'Verified'),
    not_verified: t('b.liv_score_card.view.not_verified.text', 'Not Verified'),
    pending: t('b.liv_score_card.view.pending.text', 'Pending...'),
    failed: t('b.liv_score_card.view.failed.text', 'Failed'),
    passed: t('b.liv_score_card.view.verified.text', 'Verified')
  }

  const CSOSCORES = {
    pending: t('b.liv_score_card.view.pending.text', 'Pending...'),
    passed: t('b.liv_score_card.view.passed.text', 'Not Found'),
    failed: t('b.liv_score_card.view.failed.text', 'Found')
  }

  const LABELS = {
    payments: t('b.liv_score_card.view.credit.label', 'Credit'),
    payment: t('b.liv_score_card.view.payment_record.label', 'Payment Record'),
    bankruptcy: t(
      'b.liv_score_card.view.bankruptcy_index.label',
      'Bankruptcy Index'
    ),
    collections: t(
      'b.liv_score_card.view.collections_record.label',
      'Collections Record'
    ),
    credit_utilization: t(
      'b.liv_score_card.view.credit_utilization.label',
      'Credit Utilization'
    ),
    trustworthiness: t(
      'b.liv_score_card.view.trustworthiness.label',
      'Trustworthiness'
    ),
    employers: t(
      'b.liv_score_card.view.employment_verification.label',
      'Employment Verification'
    ),
    income: t(
      'b.liv_score_card.view.income_verification.label',
      'Income Verification'
    ),
    social_media: t(
      'b.liv_score_card.view.social_verification.label',
      'Social Verification'
    ),
    verified_id: t(
      'b.liv_score_card.view.identity_verification.label',
      'Identity Verification'
    ),
    income_verfication: t(
      'b.liv_score_card.view.income_verification.label',
      'Income Verification'
    ),
    income_to_rent_ratio: t(
      'b.liv_score_card.view.income_to_rent_ratio.label',
      'Income-to-rent Ratio'
    ),
    court_record_verification: t(
      'b.liv_score_card.view.court_record.label',
      'Court Record Verification'
    )
  }

  const DESCRIPTIONS = {
    payments: {
      landlord: t(
        'l.liv_score_card.view.credit_description.text',
        'This score is an extensive analysis from the last 4 years of renter’s credit history on all accounts.'
      ),
      tenant: t(
        'r.liv_score_card.view.credit_description.text',
        'This score is an extensive analysis from the last 4 years of your credit history on all accounts.'
      )
    },
    trustworthiness: {
      landlord: t(
        'l.liv_score_card.view.trustworthiness_description.text',
        'Renter’s trustworthiness are determined from the following metrics below to ensure their integrity.'
      ),
      tenant: t(
        'r.liv_score_card.view.trustworthiness_description.text',
        'Your trustworthiness are determined from the following metrics below to ensure their integrity.'
      )
    },
    income_to_rent_ratio: {
      landlord: t(
        'l.liv_score_card.view.income_to_rent_ratio_description.text',
        "To achieve a passing score, the rent price for the suite should be 1/3 of renter's self-reported monthly income."
      )
    },
    court_record_verification: {
      landlord: t(
        'b.liv_score_card.view.court_record_verification.text',
        'If records of possible infractions are detected on any public provincial/state court records, the results will be indicated here.'
      ),
      tenant: t(
        'b.liv_score_card.view.court_record_verification.text',
        'If records of possible infractions are detected on any public provincial/state court records, the results will be indicated here.'
      )
    }
  }

  const DETAILS = {
    records: number => (
      <RecordsText key="records">
        {t('b.liv_score_card.view.bankruptcy_record_count.label', {
          defaultValue: '- {{count}} Record Found',
          defaultValue_plural: '- {{count}} Records Found',
          count: number
        })}
      </RecordsText>
    ),
    accounts: number => (
      <RecordsText key="accounts">
        {t('b.liv_score_card.view.accounts_count.label', {
          defaultValue: '- {{count}} Account Checked',
          defaultValue_plural: '- {{count}} Accounts Checked',
          count: number
        })}
      </RecordsText>
    ),
    t4: () => (
      <RecordWrapper key="t4">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.t4.label', 'T4')}</p>
      </RecordWrapper>
    ),
    job_offer: () => (
      <RecordWrapper key="job_offer">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.job_offer.label', 'Job Offer')}</p>
      </RecordWrapper>
    ),
    payslip: () => (
      <RecordWrapper key="payslip">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.payslip.label', 'Pay Slip')}</p>
      </RecordWrapper>
    ),
    student_loan: () => (
      <RecordWrapper key="student_load">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.student_loan.label', 'Student Loan')}</p>
      </RecordWrapper>
    ),
    school_letter: () =>
      professionId === 'student' && (
        <RecordWrapper key="school_letter">
          <Icon id="small_check" width={5} height={5} />
          <p>
            {t('b.liv_score_card.view.school_letter.label', 'Enrolment Letter')}
          </p>
        </RecordWrapper>
      ),
    school_id: () =>
      professionId === 'student' && (
        <RecordWrapper key="school_id">
          <Icon id="small_check" width={5} height={5} />
          <p>{t('b.liv_score_card.view.school_id.label', 'School ID')}</p>
        </RecordWrapper>
      ),
    bank_statement: () => (
      <RecordWrapper key="bank_statement">
        <Icon id="small_check" width={5} height={5} />
        <p>
          {t('b.liv_score_card.view.bank_statement.label', 'Bank Statement')}
        </p>
      </RecordWrapper>
    ),
    business_card: () => (
      <RecordWrapper key="business_card">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.business_card.label', 'Business Card')}</p>
      </RecordWrapper>
    ),
    tax_slip: () => (
      <RecordWrapper key="tax_slip">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.tax_slip.label', 'Tax Slips')}</p>
      </RecordWrapper>
    ),
    passport: () => (
      <RecordWrapper key="passport">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.passport.label', 'Passport')}</p>
      </RecordWrapper>
    ),
    permanent_resident: () => (
      <RecordWrapper key="permanent_resident">
        <Icon id="small_check" width={5} height={5} />
        <p>
          {t(
            'b.liv_score_card.view.permanent_resident.label',
            'Permanent Resident'
          )}
        </p>
      </RecordWrapper>
    ),
    health_insurance: () => (
      <RecordWrapper key="health_insurance">
        <Icon id="small_check" width={5} height={5} />
        <p>
          {t(
            'b.liv_score_card.view.health_insurance.label',
            'Health Insurance'
          )}
        </p>
      </RecordWrapper>
    ),
    drivers_license: () => (
      <RecordWrapper key="drivers_license">
        <Icon id="small_check" width={5} height={5} />
        <p>
          {t('b.liv_score_card.view.drivers_license.label', `Driver's Licence`)}
        </p>
      </RecordWrapper>
    ),
    selfie: () => (
      <RecordWrapper key="selfie">
        <Icon id="small_check" width={5} height={5} />
        <p>{t('b.liv_score_card.view.selfie.label', 'Selfie Check')}</p>
      </RecordWrapper>
    )
  }

  const getLabel = key => LABELS[key] || key

  const getInfo = (item, label) => {
    const documents = toPairs(item.details[label])
    return documents.map(doc => {
      if (doc[1] === false) return null
      return doc[1] !== null ? DETAILS[doc[0]](doc[1]) : null
    })
  }

  const getScore = key => SCORES[key] || '-'
  const getCSOScore = key => CSOSCORES[key] || '-'

  const getDescriptionByRole = key => DESCRIPTIONS[key][userRole]

  const mapVerification = (item, key) =>
    !Array.isArray(path(['details', key], item)) &&
    !!path(['details', key], item)

  const getCanAfford = () => {
    const dateConversion = {
      monthly: 12,
      weekly: 52,
      daily: 365
    }

    const incomeRatio =
      (listingData.price * dateConversion[listingData.price_frequency]) /
      Number(salarySort)
    const canAfford = incomeRatio < 0.3

    return Number(salarySort) ? (
      <RatingTitle color={COLORS[canAfford ? 'verified' : 'poor']}>
        {canAfford
          ? t('b.component_liv_score_application_card.view.pass.text', 'Pass')
          : t(
              'b.component_liv_score_application_card.view.failed.text',
              'Failed'
            )}
      </RatingTitle>
    ) : (
      <RatingTitle>-</RatingTitle>
    )
  }
  return (
    <DetailsWrapper>
      {toArray(data?.breakdown).map((item, index) => (
        <DetailSection key={item.category_name}>
          <SectionTitle>{getLabel(item.category_name)}</SectionTitle>
          <SectionRatingWrapper>
            <RatingTitle>{getScore(item.message)}</RatingTitle>
            <RatingValue>
              <p>{item.score} </p>
              {t('b.liv_score_card.view.points.text', 'pts')}
              <span> /{item.maximum}</span>
            </RatingValue>
          </SectionRatingWrapper>
          <LivCardDescription>
            {getDescriptionByRole(item.category_name)}
          </LivCardDescription>
          {!!item.labels &&
            Object.keys(item.labels).map((label, key) => (
              <ItemWrapper key={key}>
                <Item>
                  <ItemLabel>{getLabel(label)}</ItemLabel>
                  <ItemValue color={COLORS[item.labels[label]]}>
                    {getScore(item.labels[label])}
                  </ItemValue>
                </Item>
                {mapVerification(item, label) && getInfo(item, label)}
              </ItemWrapper>
            ))}
        </DetailSection>
      ))}
      {!!listingData && (
        <DetailSection>
          <SectionTitle>{getLabel('income_to_rent_ratio')}</SectionTitle>
          <SectionRatingWrapper>{getCanAfford()}</SectionRatingWrapper>
          <LivCardDescription>
            {t(
              'l.liv_score_card.view.income_to_rent_ratio_description.text',
              "To achieve a passing score, the rent price for the suite should be 1/3 of renter's self-reported monthly income."
            )}
          </LivCardDescription>
        </DetailSection>
      )}
      {!!csoVerifiedState && (
        <DetailSection>
          <SectionTitle>{getLabel('court_record_verification')}</SectionTitle>
          <SectionRatingWrapper>
            <RatingTitle color={COLORS[csoVerifiedState]}>
              {getCSOScore(csoVerifiedState)}
            </RatingTitle>
          </SectionRatingWrapper>
          <LivCardDescription>
            {t(
              'b.liv_score_card.view.court_record_verification.text',
              'If records of possible infractions are detected on any public provincial/state court records, the results will be indicated here.'
            )}
          </LivCardDescription>
        </DetailSection>
      )}
    </DetailsWrapper>
  )
}

export default CardDetails
