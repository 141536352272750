import styled from 'styled-components'

const Actionset = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  z-index: 1;
`

export default Actionset
