import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'
import Icon from 'components/icon'
import VideoPlayer from 'components/video-player'
import { createModal, useModalize } from 'components/modalize'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div`
  position: relative;
  max-width: calc(100% - 40px);
  max-height: 100%;
  margin: 0 auto;
  position: relative;
  overflow: visible;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: calc(100% - 20px);
  }
`

const CloseButton = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: -23px;
  right: -23px;
  height: 52px;
  width: 52px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    top: -12px;
    right: -12px;
    height: 40px;
    width: 40px;
  }
`

const videoStyles = css`
  height: auto;
  width: unset;
  max-width: 100%;
  max-height: calc(100vh - 40px);

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-height: calc(100vh - 20px);
  }
`

const VideoLightBox = ({ videoLocation, controls, autoPlay }) => {
  const { close } = useModalize()

  return (
    <Wrapper>
      <ContentWrapper>
        <CloseButton id="close_button" onClick={close} />
        <VideoPlayer
          location={videoLocation}
          controls={controls}
          autoPlay={autoPlay}
          customStyle={videoStyles}
        />
      </ContentWrapper>
    </Wrapper>
  )
}

export const show = createModal(VideoLightBox, {
  showBackground: false,
  width: '100%',
  borderRadius: '0px',
  allowOverflow: true
})
