import { Helmet } from 'react-helmet'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PageTitle from 'components/page-title'
import { colors, breakpoints } from 'styles'
import styled, { createGlobalStyle } from 'styled-components'
import SideBar from './side-bar'
import ChatRoom from './chat-room'

const Overflow = createGlobalStyle`
  body {
    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      overflow-y: hidden;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  height: calc(100vh - 70px);
  position: relative;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 100vh;
  }
`

const Chat = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true)

  const dispatch = useDispatch()
  const { getPresets: getChatPresets } = dispatch.chatPreset
  const { getContracts } = dispatch.contract

  useEffect(() => {
    getChatPresets()
  }, [getChatPresets])
  useEffect(() => {
    getContracts()
  }, [getContracts])
  return (
    <Wrapper>
      <Helmet>
        <title>{t('b.chat.view.chat.title', 'Chat')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Overflow />
      <PageTitle>{t('b.chat.view.chat.title', 'Chat')}</PageTitle>
      <SideBar isOpen={isOpen} closeNav={() => setIsOpen(false)} />
      <ChatRoom handleBack={() => setIsOpen(true)} />
    </Wrapper>
  )
}

export default React.memo(Chat)
