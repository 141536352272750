import React from 'react'
import 'react-virtualized/styles.css'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import ENV_VARS from 'config'
import ReactDOM from 'react-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as Sentry from '@sentry/browser'
import { hijackEffects } from 'stop-runaway-react-effects'
import AppContainer from './app'
import store from './store'
import { FlagsProvider, flags } from './flags'
import './index.css'
import { unregister as unRegisterServiceWorker } from './registerServiceWorker'
import './i18n'

if (process.env.NODE_ENV === 'development') {
  hijackEffects({ callCount: 100, timeLimit: 500 })
}

if (ENV_VARS.BUILD_ENV === 'prod') {
  Sentry.init({
    dsn: 'https://a2a3605f8efc4d199257356920708c45@sentry.liv.rent/4'
  })
}

ReactDOM.render(
  <Provider store={store}>
    <FlagsProvider flags={flags}>
      <BrowserRouter basename={ENV_VARS.APP_PATH}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AppContainer />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </FlagsProvider>
  </Provider>,
  document.getElementById('root')
)
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept()
}

unRegisterServiceWorker()
