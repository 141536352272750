import React from 'react'
import { toInteger } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatCurrency from 'utils/format-currency'

const checkPet = pet => !!pet && toInteger(pet) > 0

const PetPolicy = ({ petPolicy, petDeposit, allowCats, allowDogs }) => {
  const { t } = useTranslation()
  const petLimitations = checkPet(allowCats) || checkPet(allowDogs)
  const showCount = petPolicy && petLimitations
  return (
    <CollapsibleBox
      title={t('b.contract.view.pet_policy.subtitle', 'Pet Policy')}
      wrapChildren
    >
      {!showCount && (
        <LabeledValue
          label={t('b.contract.view.pet_policy.label', 'Pet policy')}
          value={
            petPolicy
              ? t('b.contract.view.allowed.text', 'Allowed')
              : t('b.contract.view.not_allowed.text', 'Not Allowed')
          }
        />
      )}
      {showCount && (
        <LabeledValue
          label={t(
            'b.contract.view.max_cats_allowed.text',
            'Max. cats allowed'
          )}
          value={toInteger(allowCats)}
        />
      )}
      {showCount && (
        <LabeledValue
          label={t('b.contract.view.dogs_allowed.text', 'Max. dogs allowed')}
          value={toInteger(allowDogs)}
        />
      )}
      {petPolicy && (
        <LabeledValue
          label={t('b.contract.view.pet_deposit.label', 'Pet deposit')}
          value={
            toInteger(petDeposit) === 0
              ? t('b.contract.view.included.text', 'Included')
              : formatCurrency(petDeposit)
          }
        />
      )}
    </CollapsibleBox>
  )
}

PetPolicy.defaultProps = {
  petPolicy: false,
  petDeposit: null
}

export default PetPolicy
