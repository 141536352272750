import React, { Fragment, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import styled, { css } from 'styled-components'
import { colors, breakpoints } from 'styles'
import { select } from 'store'
import { Autosave } from 'components/form'
import { toast } from 'components/toast-notifications'
import { Button } from 'components/button'
import useMediaQuery from 'hooks/use-media-query'
import ProfileDiscovery from './discovery'

const mobileStyle = css`
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 95px;
  max-height: 400px;
`

const Wrapper = styled.div`
  background: ${colors.white};
  width: 100%;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.8);
  @media screen and (max-height: 800px) {
    ${mobileStyle}
  }
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    ${mobileStyle}
  }
`

const Inner = styled.div`
  max-width: 767px;
  width: 100%;
  display: flex;
  align-items: center;
  > form {
    width: 100%;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`

const MobilePadding = styled.div`
  padding: 0 20px;
`

const InfoText = styled.p`
  padding-right: 20px;
`

const FlexButton = styled(Button)`
  white-space: pre;
`

const FormWrapper = styled.div`
  margin: 30px 0 35px;
  > section {
    > div:last-of-type {
      flex-direction: row;
      align-items: center;
      > p {
        max-width: 425px;
        margin-bottom: 0;
      }
    }
  }
  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 0 30px;
  }
`

const MobileWrapper = styled.div`
  margin: 25px 0;
  > section {
    > div:last-of-type {
      flex-direction: column-reverse;
      margin-top: 10px;
      > p {
        max-width: 425px;
        margin-bottom: 0;
      }
      > div {
        margin-bottom: 20px;
      }
    }
  }
`

const Discovery = ({ displayMobile, ...rest }) => {
  const DiscoveryWrapper = displayMobile ? MobileWrapper : FormWrapper
  const DiscoveryPadding = displayMobile ? MobilePadding : Fragment
  return (
    <DiscoveryPadding>
      <DiscoveryWrapper>
        <ProfileDiscovery {...rest} condensed />
      </DiscoveryWrapper>
    </DiscoveryPadding>
  )
}


const BarContent = () => {
  const { t } = useTranslation()
  const [opened, setOpen] = useState()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const label = t(
    'r.settings.view.profile_preview.label',
    'This is what your profile looks like to landlords.'
  )
  const showDrawer = !opened && displayMobile
  return showDrawer ? (
    <MobilePadding>
      <FlexContainer>
        <InfoText>{label}</InfoText>
        <FlexButton inversed theme="sane" onClick={() => setOpen(true)}>
          {t('r.settings.view.profile_edit_privacy.button', 'Edit Privacy')}
        </FlexButton>
      </FlexContainer>
    </MobilePadding>
  ) : (
    <Discovery displayMobile={displayMobile} prefix={!displayMobile ? label : ''}/>
  )
}

const Bar = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const settings = useSelector(select.profile.settings)
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async values => {
      const response = await dispatch.profile.updateSettings(values)
      if (!response?.ok) {
        toast(
          t(
            'b.settings.view.settings.failed_save',
            'Your settings could not be saved.'
          ),
          {
            title: t('common.error', 'Error'),
            iconId: 'error',
            autoClose: 6000
          }
        )
      }
    },
    [dispatch.profile, t]
  )
  return (
    <Wrapper ref={ref}>
      <Inner>
        <Formik initialValues={settings}>
          {({ values }) => (
            <Autosave values={values} onSave={onSubmit}>
              <Form>
                <BarContent />
              </Form>
            </Autosave>
          )}
        </Formik>
      </Inner>
    </Wrapper>
  )
})

Bar.displayName = 'Bar'

export default Bar
