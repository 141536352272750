import React from 'react'
import Section from 'components/section'
import { SectionHeader } from 'components/headings'
import AmenitiesDisplay from 'components/amenities-display'

const Amenities = ({
  amenities,
  customAmenities,
  unitScope,
  header,
  translationContext
}) =>
  amenities.length > 0 && (
    <Section>
      <SectionHeader>{header}</SectionHeader>
      <AmenitiesDisplay
        translationContext={translationContext}
        amenities={amenities}
        customAmenities={customAmenities}
        showType={unitScope !== 'entire'}
      />
    </Section>
  )

Amenities.defaultProps = {
  amenities: []
}

export default Amenities
