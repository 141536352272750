import React from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import { getImageUrl } from 'utils/image'
import { Avatar, defaultAvatar } from 'components/avatar'
import livLogoDark from './images/LivLogoDark.png'
import livLogoWhite from './images/LivLogoWhite.png'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 15px;
`

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  flex: 1;
  color: ${({ whiteBg }) => (whiteBg ? colors.regular : colors.white)};

  @media print {
    color: ${colors.regular};
  }
`

const ScoreTitle = styled.p`
  font-size: 11px;
  font-weight: ${fontWeights.medium};
  line-height: 13px;
  margin: 0;
`

const ScoreRating = styled.p`
  font-size: 17px;
  font-weight: ${fontWeights.medium};
  line-height: 20px;
  margin: 4px 0 0 0;
`

const LivLogo = styled.img`
  width: 28px;
  height: 25px;
`

const getImage = url => (url ? getImageUrl(url) : defaultAvatar)

const CardHeader = props => {
  const livLogo = props.whiteBg ? livLogoDark : livLogoWhite

  return (
    <Wrapper>
      <Avatar location={getImage(props.avatarUrl)} width={36} height={36} />
      <ScoreWrapper whiteBg={props.whiteBg}>
        <ScoreTitle>{props.title}</ScoreTitle>
        <ScoreRating>{props.rating}</ScoreRating>
      </ScoreWrapper>
      <LivLogo src={livLogo} />
    </Wrapper>
  )
}

CardHeader.defaultProps = {
  avatarUrl: '',
  whiteBg: false,
  rating: ''
}

export default CardHeader
