import React from 'react'
import styled from 'styled-components'
import Icon from 'components/icon'

const Badge = styled(Icon)`
  width: ${({ width }) => width || 16}px;
  height: ${({ height }) => height || 16}px;
  flex-shrink: 0;
  ${({ inline }) => inline && 'display: inline-block'};
  margin-top: ${({ top }) => top || 0}px;
  margin-right: ${({ right }) => right || 0}px;
  margin-bottom: ${({ bottom }) => bottom || 0}px;
  margin-left: ${({ left }) => left || 0}px;
`

const VerifiedBadge = ({ verified, type = 'id', ...rest }) => {
  const ICONS = {
    id: {
      src: 'verified_id',
      alt: 'This user is ID/Businesss Licence verified'
    },
    listing: {
      src: 'verified_listing',
      alt: 'This listing is address verified'
    }
  }

  return verified === 'verified' || verified === true ? (
    <Badge id={ICONS[type].src} alt={ICONS[type].alt} height={20} {...rest} />
  ) : null
}

VerifiedBadge.defaultProps = {
  verified: false
}

export default VerifiedBadge
