import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { AgreementDescription } from 'components/agreement'
import { Input } from 'components/form'

const AgreeWrapper = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AgreeText = styled.p`
  flex: 9999;
`

const ReviewTerms = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const completeStep = dispatch.contract.completeStep
  const incompleteStep = dispatch.contract.incompleteStep

  useEffect(() => {
    dispatch.agreement.loadAgreement('terms_conditions')
  }, [dispatch.agreement])

  const isStepComplete = useSelector(state =>
    select.contract.isStepComplete(state, { step: 'terms' })
  )

  return [
    <AgreementDescription key="content" agreementId="terms_conditions" />,
    <AgreeWrapper key="agree-section">
      <AgreeText>
        {t(
          'b.contract.view.agree_with_terms.text',
          'I agree with Terms & Conditions'
        )}
      </AgreeText>
      <Input
        type="checkbox"
        checked={isStepComplete}
        onChange={() =>
          isStepComplete ? incompleteStep('terms') : completeStep('terms')
        }
      />
    </AgreeWrapper>
  ]
}

export default ReviewTerms
