import React, { useRef } from 'react'
import { useField } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import moment from 'moment'
import Base from './base'
import Error from '../error'

const FieldDatePicker = ({
  name,
  onChange,
  format = 'YYYY-MM-DD',
  displayFormat,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)
  const fieldRef = useRef()
  const hasError = !!meta.error && meta.touched
  useFocusOnError({ fieldRef, name })
  const value = field.value ? moment(field.value) : null
  return (
    <div ref={fieldRef}>
      <Base
        {...field}
        {...rest}
        hasError={hasError}
        value={value}
        format={displayFormat}
        onChange={date =>
          date && helpers.setValue(date.format ? date.format(format) : date)
        }
      />
      <Error name={name} />
    </div>
  )
}

export default React.memo(FieldDatePicker)
