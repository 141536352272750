import React, { useRef } from 'react'
import { useField } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import Base from './base'
import Error from '../error'

const FieldTextarea = ({ name, ...rest }) => {
  const [field] = useField(name)
  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })

  return (
    <>
      <Base ref={fieldRef} {...field} {...rest} />
      <Error name={name} />
    </>
  )
}

export default React.memo(FieldTextarea)
