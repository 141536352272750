import React from 'react'
import { FieldArray } from 'formik'
import { Error } from 'components/form'
import SelectorList from './selector-list-base'

const CheckboxSelectorList = ({ name, value = [], children, ...rest }) => (
  <>
    <SelectorList {...rest}>
      <FieldArray name={name}>
        {arrayHelpers =>
          React.Children.map(children, child => {
            return React.cloneElement(child, {
              name,
              type: 'checkbox',
              checked: child.props.checked
                ? child.props.checked
                : value.includes(child.props.value),
              onChange: child.props.onChange
                ? child.props.onChange
                : e => {
                    const target = e.target
                    if (target.checked) {
                      arrayHelpers.push(target.value)
                    } else {
                      const idx = value.indexOf(target.value)
                      arrayHelpers.remove(idx)
                    }
                  }
            })
          })
        }
      </FieldArray>
    </SelectorList>
    <Error name={name} />
  </>
)

export default React.memo(CheckboxSelectorList)
