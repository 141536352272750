import styled from 'styled-components'

const FilterBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.static &&
    `
    position: static;
  `}
`

export default FilterBar
