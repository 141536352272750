import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTemplate from './card-template'
import equifaxAverage from './images/Credit_Score_Average.png'

const AverageCredit = props => {
  const { t } = useTranslation()

  const creditDescription =
    props.userRole === 'tenant'
      ? t(
          'r.liv_score_card.view.provide_financial_story.text',
          'Hold on! You can help your landlord understand you better by providing a more detailed financial story.'
        )
      : t(
          'l.liv_score_card.view.liv_score_description.text',
          'While this is a good score, it is not definitive. You may want to request further financial documents, such as a payslip, from the applicant to be safe.'
        )

  return (
    <CardTemplate
      userRole={props.userRole}
      title={props.title}
      creditDetails={props.creditDetails}
      creditDescription={creditDescription}
      creditRatingResult={t('b.liv_score_card.view.average.text', 'Average')}
      cardImage={equifaxAverage}
      avatarUrl={props.avatarUrl}
      professionId={props.professionId}
      csoVerifiedState={props.csoVerifiedState}
      salarySort={props.salarySort}
      listingData={props.listingData}
      data-testid={props['data-testid']}
    />
  )
}

AverageCredit.defaultProps = {
  userRole: 'tenant',
  title: '',
  creditDetails: {}
}

export default AverageCredit
