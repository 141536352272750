import ENV_VARS from 'config'
import { get, post, put, del } from './api'

const {
  BACKEND_SERVER,
  HERE_MAPS_GEOCODER_SERVER,
  HERE_MAPS_APP_ID,
  HERE_MAPS_APP_CODE
} = ENV_VARS

export const buildRequest = url =>
  get(`${BACKEND_SERVER}/${url}`, {
    noLoading: true,
    failSilently: true
  })()

export const getSession = get(`${BACKEND_SERVER}/users/bootstrap/session`, {
  failSilently: true
})

// PAYMENT
export const connectStripe = get(`${BACKEND_SERVER}/payment_request/connect`, {
  failSilently: true
})
export const paymentStripe = post(`${BACKEND_SERVER}/payment_request/process`, {
  failSilently: true
})

// AGREEMENT
export const getAgreement = get(`${BACKEND_SERVER}/agreements/%agreementId%`)
export const agreeToAgreement = post(
  `${BACKEND_SERVER}/users/agreements/%agreementId%/agree`
)
export const agreeToAgreements = put(`${BACKEND_SERVER}/users/agreements/agree`)

export const geoCoding = get(
  `${HERE_MAPS_GEOCODER_SERVER}?app_id=${HERE_MAPS_APP_ID}&app_code=${HERE_MAPS_APP_CODE}&gen=9&jsonAttributes=1`,
  { credentials: 'omit', noLoading: true }
)

// BUILDING
export const createBuilding = post(`${BACKEND_SERVER}/buildings`)
export const searchBuilding = post(`${BACKEND_SERVER}/buildings/search`, {
  noLoading: true
})

// FILES
export const uploadFile = put(`${BACKEND_SERVER}/files`)
export const updateFile = put(`${BACKEND_SERVER}/files/%fileId%`)
export const deleteFile = del(`${BACKEND_SERVER}/files/%fileId%`)

export const logUserView = put(`${BACKEND_SERVER}/l/users/%userId%`)
