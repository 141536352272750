import React from 'react'
import PropTypes from 'prop-types'
import { OptionItem } from 'components/option-list'
import { css } from 'styled-components'
import { breakpoints, fontSizes } from 'styles'

const style = css`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
    padding: 10px;
    height: 40px;
  }
`

const DropdownItem = ({ children, ...rest }) => (
  <OptionItem {...rest} customStyle={style}>
    {children}
  </OptionItem>
)

DropdownItem.propTypes = {
  children: PropTypes.node
}

DropdownItem.defaultProps = {
  children: null
}

export default DropdownItem
