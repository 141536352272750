import React, { forwardRef } from 'react'
import { compose, pure } from 'recompose'
import styled from 'styled-components'
import { breakpoints, colors } from 'styles'

const Wrapper = styled.section`
  display: flex;
  border: 1px solid ${colors.grey};

  & > * {
    border-radius: 0;
    border: none;
    flex: 1;
    border-right: 1px solid ${colors.grey};
  }

  & > *:last-child {
    border-right: none;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;

    & > * {
      border: none;
      border-bottom: 1px solid ${colors.grey};
    }

    & > *:last-child {
      border: none;
    }
  }
`

const ButtonGroup = forwardRef(({ children, ...rest }, ref) => {
  return (
    <Wrapper {...rest} ref={ref}>
      {children}
    </Wrapper>
  )
})

ButtonGroup.displayName = 'ButtonGroup'

export default compose(pure)(ButtonGroup)
