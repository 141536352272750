import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontSizes, fontWeights } from 'styles'
import { useDispatch } from 'react-redux'
import { toNumber } from 'lodash'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Button } from 'components/button'
import addressImage from './confirm-address.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  min-height: 400px;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 20px 20px 0 0;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
`

const ModalText = styled.p`
  font-size: 15px;
  margin: 25px 0 30px;
  text-align: center;
  max-width: 280px;
`

const AddressImage = styled.img`
  height: auto;
  width: 160px;
`

const Address = styled.p`
  font-size: 15px;
  margin: 35px 0;
  text-align: center;
  max-width: 270px;
  font-weight: ${fontWeights.medium};
`

const StyledButton = styled(Button)`
  flex-shrink: 0;

  :last-child {
    margin: 30px 0;
  }
`

const VerifyAddressModal = ({ listingData, onGetSupport }) => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const dispatch = useDispatch()

  const { listings, unit, building } = listingData

  const onConfirm = async () => {
    await dispatch.listing.requestVerifyCode({
      listingId: toNumber(listings.id)
    })
    close()
  }

  const onRequestSupport = () => {
    onGetSupport()
    close()
  }

  const unitNumber = unit.unit_number ? `${unit.unit_number} - ` : ''
  const fullAddress = `${unitNumber}${building.full_address}`

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Title>
          {t(
            'l.verify_listing.view.confirm_your_address.title',
            'Confirm Your Address'
          )}
        </Title>
        <ModalText>
          {t(
            'l.verify_listing.view.confirm_address.text',
            `Is the following address correct for this listing? We'll be sending a postcard.`
          )}
        </ModalText>
        <AddressImage src={addressImage} />
        <Address>{fullAddress}</Address>
        <StyledButton
          theme="primary"
          inversed
          height={48}
          width={160}
          disabled={false}
          onClick={onConfirm}
        >
          {t('l.verify_listing.view.yes_confirm.button', 'Yes, I confirm')}
        </StyledButton>
        <StyledButton
          theme="primary"
          height={48}
          width={160}
          disabled={false}
          onClick={onRequestSupport}
        >
          {t('l.verify_listing.view.no_support.button', 'No, get support')}
        </StyledButton>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(VerifyAddressModal)
