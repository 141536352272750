const toArrayOfStrings = data => {
  if (data) {
    if (typeof data === 'string') {
      return [data]
    }

    if (data instanceof Array) {
      return data
    }

    if (data instanceof Object) {
      return Object.keys(data).map(key => `${key}: ${data[key]}`)
    }
  }

  return []
}

export default toArrayOfStrings
