import React from 'react'
import { Form as FormikForm } from 'formik'

const Form = ({ children, ...rest }) => {
  // const { errors, touched, setFieldTouched } = useFormikContext()
  // useTranslateFormErrors(errors, touched, setFieldTouched)
  return <FormikForm {...rest}>{children}</FormikForm>
}

export default Form
