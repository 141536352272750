import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import AutomobileView from './view'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const AutomobileList = ({
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  showRequiredWarning = false
}) => {
  const { t } = useTranslation()
  return (
    <MultiRowForm
      title={t('r.profile.view.automobile.subtitle', 'Automobile Information')}
      addText={t('r.profile.view.add_automobile.button', '+ Add Automobile')}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={AutomobileView}
      EditComponent={EditComponent}
      EmptyComponent={<EmptyMessage>{emptyMessage}</EmptyMessage>}
      editable={editable}
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(AutomobileList)
