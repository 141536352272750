import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { breakpoints, colors, fontSizes, fontWeights, structure } from 'styles'
import {
  reject,
  equals,
  map,
  pluck,
  prop,
  flatten,
  defaultTo,
  includes,
  append,
  props,
  values,
  length,
  pipe
} from 'ramda'
import toBoolean from 'utils/to-boolean'
import useMediaQuery from 'hooks/use-media-query'
import ShowingsContainer from 'features/Listing/showings-container'
import { Input } from 'components/form'
import SearchBar from 'components/search-bar'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import {
  FilterBar,
  FilterButton,
  SearchBarContainer,
  StripeBar
} from 'components/search-filter'
import {
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableHeaderCell,
  TableBody
} from 'components/table'
import { showAddSlotModal } from './add-a-slot-modal'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;

  margin: 0 auto 0 auto;
  background-color: ${colors.white};
  padding-bottom: 50px;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-bottom: 150px;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-width: 768px;
  background-color: ${colors.white};

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    min-width: 0%;
    max-width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-width: 320px;
    width: 100%;
  }
`

const StickyContainer = styled.div`
  top: ${structure.header.height}px;
  width: 100%;
  position: sticky;
  z-index: 20;
  background: ${colors.white};

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
  }
`

const StyledSearchBarContainer = styled(SearchBarContainer)`
  height: 87px;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 95%;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    justify-content: space-between;
    padding: 6px;
  }
`

const SearchInner = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    max-width: 325px;
  }
`

const GrayStripe = styled(StripeBar)`
  background-color: ${colors.darkGrey};
  width: 100%;
  position: absolute;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const TableBodyContainer = styled(TableBody)`
  ${TableRow} {
    border-bottom: 1px solid ${colors.grey};
  }
`

const StyledTableHeaderCell = styled(TableHeaderCell)`
  position: sticky;
  background-color: ${colors.darkGrey};
  vertical-align: middle;
  top: 250px;
`

const SelectCell = styled(StyledTableHeaderCell)`
  line-height: 0;
  padding-left: 10px;
`

const CheckBox = styled(Input)`
  &[type='checkbox'] {
    min-width: 23px;
    width: 23px;
    height: 23px;
  }
`

const HeaderText = styled.p`
  color: ${colors.white};
  text-align: left;
  margin: 10px 25px;
  font-size: 14px;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 10px 50px 10px 20px;
  }
`

const ShowingsTable = styled(Table)`
  width: 1096px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 95%;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
    max-width: 768px;
  }
`

const EmptyMessage = styled(HeaderText)`
  color: ${colors.darkGrey};
`

const AddSlotButton = styled.button`
  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background: ${colors.white};
  padding: 10px 20px;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: center;
  margin-right: 30px;
  cursor: pointer;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${colors.secondary};
    color: ${colors.white};
    font-size: ${fontSizes.regular};
    letter-spacing: -0.39px;
    line-height: 20px;
    border: none;
    border-radius: 0;
    margin-right: 0;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    width: 100%;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
  }
`

const StyledButtonBar = styled(ButtonBar)`
  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 0;
    margin-bottom: 56px;
  }
`

const LeftButtonsContainer = styled.div`
  > :first-child {
    margin-right: 10px;
  }
`

const ListingShowings = ({
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState('upcoming')
  const [tabFilteredData, setTabFilteredData] = useState([])
  const [selectedShowings, setSelectedShowings] = useState([])
  const [active, setActive] = useState(false)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  const [tabCount, setTabCount] = useState({
    upcoming: 0,
    history: 0
  })

  useEffect(() => {
    dispatch.listing.getAppointments(listingId)
  }, [dispatch.listing, listingId])

  const showings = useSelector(state =>
    select.listing.appointments(state, { listingId })
  )

  useEffect(() => {
    setTabFilteredData(showings[currentTab])
    setTabCount({
      upcoming: pipe(
        prop('upcoming'),
        defaultTo([]),
        flatten,
        length
      )(showings),
      history: pipe(prop('history'), defaultTo([]), flatten, length)(showings)
    })
  }, [currentTab, showings])

  const handleSearch = searchString => {
    if (!searchString) setTabFilteredData(showings[currentTab])

    const filtered = showings[currentTab].reduce((accum, item) => {
      const filterdItem = item.filter(appointment => {
        return moment(appointment.meeting_at)
          .format('ddd dddd MMM MMMM')
          .toLowerCase()
          .includes(searchString.trim().toLowerCase())
          ? JSON.stringify(values(appointment)).toLowerCase()
          : JSON.stringify(values(appointment))
              .toLowerCase()
              .includes(searchString.trim().toLowerCase())
      })

      if (filterdItem.length > 0) {
        return [...accum, filterdItem]
      }
      return accum
    }, [])

    setTabFilteredData(filtered)
  }

  const renderShowings = () => {
    return tabFilteredData?.length > 0 ? (
      tabFilteredData.map((showing, index) => (
        <ShowingsContainer
          currentTab={currentTab}
          key={index}
          showing={showing}
          selectedShowings={selectedShowings}
          onSelectChange={onSelectChange}
          active={active}
          listingId={listingId}
        />
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={6}>
          <EmptyMessage>
            {t(
              'b..listing.showings.no_appointments_empty.text',
              'No appointments for today'
            )}
          </EmptyMessage>
        </TableCell>
      </TableRow>
    )
  }

  const hideAppointment = async appointmentId => {
    await dispatch.listing.hideAppointment(appointmentId)
  }

  const unhideAppointment = async appointmentId => {
    await dispatch.listing.unhideAppointment(appointmentId)
  }

  const handleCancel = () => setSelectedShowings([])

  const allShowings = pipe(
    props(['upcoming', 'history']),
    defaultTo([]),
    flatten
  )(showings)

  const handleSelectAll = () => {
    const selected = pluck('id', allShowings)

    if (selectedShowings.length > 0) {
      handleCancel()
      setActive(false)
    } else {
      setSelectedShowings(selected)
      setActive(true)
    }
  }

  const hideSelected = () => {
    map(hideAppointment, selectedShowings)
    setSelectedShowings([])
  }

  const unhideSelected = () => {
    map(unhideAppointment, selectedShowings)
    setSelectedShowings([])
  }

  const onSelectChange = appointmentId => {
    const newValue = includes(appointmentId, selectedShowings)
      ? reject(equals(appointmentId), selectedShowings)
      : append(appointmentId, selectedShowings)
    setSelectedShowings(newValue)
  }

  const handleDelete = async () => {
    selectedShowings.forEach(appointmentId =>
      dispatch.listing.deleteAppointment({ appointmentId, listingId })
    )
    setSelectedShowings([])
  }

  const hideOrUnhide = () => {
    const firstSelected = allShowings.find(
      appointment => appointment?.id === selectedShowings[0]
    )

    return toBoolean(firstSelected?.hidden) ? (
      <Button
        theme="primary"
        type="submit"
        width={150}
        onClick={unhideSelected}
      >
        {t('l.listing.showings.unhide_selected.button', 'Unhide Selected')}
      </Button>
    ) : (
      <Button
        theme="primary"
        type="submit"
        width={150}
        onClick={hideSelected}
        disabled={!firstSelected}
      >
        {t('l.listing.showings.hide_selected.button', 'Hide Selected')}
      </Button>
    )
  }

  return (
    <Wrapper>
      <StickyContainer>
        <StyledSearchBarContainer>
          <FilterBar>
            <FilterButton
              active={currentTab === 'upcoming'}
              onClick={() => setCurrentTab('upcoming')}
            >
              {t('l.listing.showings.upcoming.button', 'Upcoming ')}
              {`(${tabCount.upcoming})`}
            </FilterButton>
            <FilterButton
              active={currentTab === 'history'}
              onClick={() => setCurrentTab('history')}
            >
              {t('l.listing.showings.history.button', 'History ')}
              {`(${tabCount.history})`}
            </FilterButton>
          </FilterBar>
          <SearchInner>
            <AddSlotButton
              onClick={() =>
                showAddSlotModal({
                  listingId,
                  title: t(
                    'l.showings.view.add_showing.title.text',
                    'Set Availability'
                  )
                })
              }
            >
              {t('l.listing.showings.add_slot.button', 'Add new slot')}
            </AddSlotButton>
            <SearchBar
              placeholder={t('common.search', 'Search')}
              onSearch={handleSearch}
              fullWidth={displayMobile}
            />
          </SearchInner>
        </StyledSearchBarContainer>
        <GrayStripe />
      </StickyContainer>
      <Container>
        <TableContainer>
          <ShowingsTable>
            <TableHeader sticky>
              <TableRow sticky top={displayMobile ? 141 : 157}>
                {currentTab === 'upcoming' && (
                  <SelectCell>
                    <CheckBox
                      onChange={handleSelectAll}
                      type="checkbox"
                      checked={allShowings.length === selectedShowings.length}
                    ></CheckBox>
                  </SelectCell>
                )}
                {displayMobile ? (
                  <StyledTableHeaderCell>
                    <HeaderText>
                      {t('l.listing.view.showings.title', 'Showings')}
                    </HeaderText>
                  </StyledTableHeaderCell>
                ) : (
                  <>
                    <StyledTableHeaderCell>
                      <HeaderText>{t('common.date', 'Date')}</HeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <HeaderText>{t('common.times', 'Time')}</HeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <HeaderText>
                        {t('common.duration', 'Duration')}
                      </HeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <HeaderText>
                        {t('common.attendees', 'Attendees')}
                      </HeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <HeaderText>{t('common.status', 'Status')}</HeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell>
                      <HeaderText>{}</HeaderText>
                    </StyledTableHeaderCell>
                  </>
                )}
              </TableRow>
            </TableHeader>
            <TableBodyContainer>{renderShowings()}</TableBodyContainer>
          </ShowingsTable>
        </TableContainer>
      </Container>
      {selectedShowings.length !== 0 && currentTab === 'upcoming' ? (
        <StyledButtonBar sticky>
          <LeftButtonsContainer>
            <Button
              theme="sane"
              width={displayMobile ? 85 : 110}
              type="button"
              inversed
              onClick={handleCancel}
            >
              {t('common.cancel', 'Cancel')}
            </Button>
            <Button
              theme="dark"
              type="button"
              width={displayMobile ? 85 : 110}
              onClick={handleDelete}
              disabled={selectedShowings.length === 0}
            >
              {t('common.delete', 'Delete')}
            </Button>
          </LeftButtonsContainer>
          {hideOrUnhide()}
        </StyledButtonBar>
      ) : null}
    </Wrapper>
  )
}

export default ListingShowings
