import { css } from 'styled-components'

const colors = {
  link: '#007aff',
  blue: '#4a90e2',
  lightBlue: '#6ea7ea',
  secondaryLightBlue: '#7494ac',
  secondary: '#3e5b77',
  primary: '#fe5f55',
  red: '#cf281e',
  pink: '#fb6964',
  lightRed: '#eb7f78',
  lightPink: '#fc8985',
  black: '#000000',
  regular: '#303030',
  darkGrey: '#4a4a4a',
  mediumGrey: '#929292',
  grey: '#d8d8d8',
  mildLightGrey: '#ccc',
  mediumLightGrey: '#d9d7d7',
  lightGrey: '#f8f8f8',
  white: '#ffffff',
  blueGrey: '#e3e9ee',
  babyBlue: '#65cbc3',
  darkBlue: '#335c7a',
  green: '#00d0a6',
  brightGreen: '#27ae60'
}

const fontWeights = {
  regular: 400,
  medium: 600,
  bold: 800
}

const fontSizes = {
  extraLarge: '30px',
  large: '23px',
  medium: '20px',
  regular: '16px',
  small: '13px'
}

const breakpoints = {
  smallPhone: '370px',
  phoneMax: '599px',
  tabletPortrait: '600px',
  ipadMiniMax: '767px',
  tabletLandscape: '900px',
  dashboardMobile: '1180px',
  desktop: '1200px',
  bigDesktop: '1800px'
}

const shadows = {
  header: '0 1px 3px 0 rgba(0, 0, 0, 0.4)'
}

const transitions = {
  ease: '0.25s ease-in 0s',
  floatingLabel: `
    color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms
  `
}

const structure = {
  header: {
    height: 70,
    heightMobile: 54
  },
  footer: {
    height: 62
  },
  dashboard_nav: {
    height: 50
  },
  wizard_bar: {
    height: 50
  },
  button_bar: {
    height: 90
  },
  sidebar: {
    width: 66,
    widthExpanded: 150
  }
}

const zIndex = {
  agreementModal: 9001,
  welcomeModal: 9000,
  header: 502,
  sidebar: 505,
  modal: 510,
  toast: 500,
  footer: 11,
  buttonBar: 10
}

const media = {
  phoneOnly: css`
    @media (min-width: ${breakpoints.phoneMax}) {
      display: none;
    }
  `,
  phoneHidden: css`
    @media only screen and (max-width: ${breakpoints.phoneMax}) {
      display: none;
    }
  `,
  tabletOnly: css`
    @media only screen and (max-width: ${breakpoints.phoneMax}) {
      display: none;
    }
    @media only screen and (min-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `,
  tabletHidden: css`
    @media only screen and (min-width: ${breakpoints.tabletPortrait}) and (max-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `,
  desktopOnly: css`
    @media only screen and (max-width: ${breakpoints.desktop}) {
      display: none;
    }
  `,
  desktopHidden: css`
    @media only screen and (min-width: ${breakpoints.desktop}) {
      display: none;
    }
  `,
  tabletAtLeast: css`
    @media only screen and (max-width: ${breakpoints.tabletPortrait}) {
      display: none;
    }
  `,
  tabletLandscapeAtLeast: css`
    @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
      display: none;
    }
  `
}
export {
  breakpoints,
  colors,
  fontWeights,
  fontSizes,
  media,
  structure,
  zIndex,
  shadows,
  transitions
}
