import client from 'ports/api-client'
import { useQuery } from 'react-query'

async function getData(_, listingId) {
  return client.get(`/web/listings/${listingId}/fetch`)
}

const useGetSuiteInfo = (listingId, config) => {
  const { data } = useQuery(['listing-info', listingId], getData, {
    staleTime: 30000,
    enabled: listingId,
    ...config
  })
  return data
}

export default useGetSuiteInfo
