import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Wrapper = styled.section`
  background: ${props => props.color || colors.white};
  border: 1px solid ${colors.lightGrey};
  padding: ${props => props.vSpace}px ${props => props.hSpace}px;
  margin-top: ${props => (props.top ? `${props.top}px` : 0)};

  @media print {
    border: none;
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Panel = React.memo(
  React.forwardRef(({ children, hSpace = 20, vSpace = 20, ...rest }, ref) => (
    <Wrapper hSpace={hSpace} vSpace={vSpace} {...rest} ref={ref}>
      {children}
    </Wrapper>
  ))
)

export default Panel
