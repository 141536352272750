import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createModal, useModalize } from 'components/modalize'
import { fontWeights } from 'styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'
import Icon from 'components/icon'
import { Button } from 'components/button'
import {
  Row,
  FieldInput,
  FieldDatePicker,
  FieldDropdown,
  Group
} from 'components/form'

const Wrapper = styled.div`
  padding: 20px 30px;
  position: relative;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin: 20px;
  font-weight: ${fontWeights.medium};
  text-align: center;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
`

const SubHeader = styled.p`
  font-size: 15px;
  font-weight: ${fontWeights.medium};
  padding: 20px 0px;
  margin-top: 0px;
  text-align: left;
`

const StyledButton = styled(Button)`
  padding: 5px 25px;
  border-radius: 2px;
  display: block;
  margin: 30px auto;
`

const FeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`

const FeeText = styled.p`
  letter-spacing: -0.39px;
  line-height: 20px;
  margin: 0;
`

const RequestPaymentModal = ({ listingId, tenantId }) => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const dispatch = useDispatch()
  const createPayment = dispatch.payment.createPaymentRequest

  const formOptions = () => ({
    initialValues: {
      description: '',
      requested_amount: '',
      due_date: ''
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required(),
      requested_amount: Yup.number().min(1).max(25000).required(),
      due_date: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await createPayment({
        listingId,
        tenantId,
        ...values
      })
      if (response.ok) {
        close()
        return
      }
      setSubmitting(false)
    }
  })

  const buttonText = t(
    'l.payments.view.request_payment_modal_send_request.button',
    'Send Request'
  )

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        width={24}
        height={24}
        id="close"
        onClick={close}
      />
      <Title>
        {t('l.payments.view.request_payment_modal.title', 'Payment Request')}
      </Title>
      <SubHeader>
        {t('l.payments.view.request_payment_modal.subtitle', 'Payment info')}
      </SubHeader>
      <Formik {...formOptions()}>
        {({ values, errors, isSubmitting }) => (
          <Form>
            <Row gutter={0} bottom={15}>
              <Group col="12">
                <FieldDropdown
                  id="description"
                  name="description"
                  placeholder={
                    '1. ' +
                    t(
                      'l.payments.view.request_payment_modal_subject.label',
                      'What is this for?'
                    )
                  }
                  autoFocus
                  options={[
                    {
                      value: 'Rent Payment',
                      label: t(
                        'l.payments.view.payment_type_rent_payment.text',
                        'Rent Payment'
                      )
                    },
                    {
                      value: 'Deposit',
                      label: t(
                        'l.payments.view.payment_type_deposit.text',
                        'Deposit'
                      )
                    }
                  ]}
                />
              </Group>
            </Row>
            <Row gutter={0} bottom={15}>
              <Group col="12">
                <FieldInput
                  id="requested_amount"
                  name="requested_amount"
                  prefix="$"
                  placeholder={
                    '2. ' +
                    t(
                      'l.payments.view.request_payment_modal_amount.label',
                      'How much do you want to charge?'
                    )
                  }
                />
                <FeeInfoWrapper onClick={showProcessingFeesModal}>
                  <FeeText>
                    {t(
                      'l.payment.edit.request_payment_transaction_fees.text',
                      'Payout fees'
                    )}
                  </FeeText>
                  <Icon id="info_blue" width={16} height={16} />
                </FeeInfoWrapper>
              </Group>
            </Row>
            <Row gutter={0}>
              <Group col="12">
                <FieldDatePicker
                  id="due_date"
                  name="due_date"
                  placeholder={
                    '3. ' +
                    t(
                      'l.payments.view.request_payment_modal_due_date.label',
                      'When is this payment due?'
                    )
                  }
                  disablePast
                />
              </Group>
            </Row>
            <StyledButton
              aria-label={buttonText}
              disabled={isSubmitting}
              type="submit"
              theme="primary"
            >
              {buttonText}
            </StyledButton>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export const show = createModal(RequestPaymentModal)
