import React from 'react'
import styled from 'styled-components'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import {
  ShareByPhone,
  ShareByCraigslist,
  ShareByEmail,
  ShareByKijiji,
  ShareByUrl
} from './index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const getComponent = shareType => {
  switch (shareType) {
    case 'email':
      return ShareByEmail
    case 'phone':
      return ShareByPhone
    case 'craigslist':
      return ShareByCraigslist
    case 'kijiji':
      return ShareByKijiji
    case 'url':
      return ShareByUrl
    default:
      return () => null
  }
}

const ShareModal = ({ shareType, listingId }) => {
  const { close } = useModalize()

  const Component = getComponent(shareType)

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Component listingId={listingId} onClose={close} />
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showShareModal = createModal(ShareModal)
