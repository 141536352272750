import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'hooks/use-media-query'

import backArrow from './back-arrow.png'
import NoChatBG from './no-chat-placeholder.png'

const EmptyWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  background-color: ${colors.lightGrey};
  padding-bottom: 58px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
`

const BackArrow = styled.img`
  display: none;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: block;
    position: absolute;
    left: 10px;
    top: 30px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`

const Text = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 35px;
  margin-top: 30px;
  color: ${colors.regular};
  text-align: center;

  a {
    color: ${colors.secondary};
    text-decoration: underline;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: ${fontSizes.regular};
    line-height: 24px;
  }
`

const NoChatImage = styled.img`
  width: 425px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    max-width: 80%;
  }
`

const EmptyChatRoom = ({
  handleBack,
  message,
  sideBarHeader,
  chatFooterButton,
  noTimelines,
  ...rest
}) => {
  const { t } = useTranslation()
  const displayTablet = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  const HeaderComponent = sideBarHeader
  const ButtonComponent = chatFooterButton

  return (
    <EmptyWrapper {...rest}>
      {sideBarHeader && noTimelines && displayTablet ? (
        <HeaderWrapper>
          <HeaderComponent />
        </HeaderWrapper>
      ) : (
        <BackArrow src={backArrow} onClick={handleBack} alt="back" />
      )}
      <NoChatImage src={NoChatBG} alt="" />
      <Text>
        {message ||
          t('b.chat.view.no_chat_selected.text', 'No chat room selected')}
      </Text>
      {chatFooterButton && noTimelines && displayTablet && (
        <ButtonWrapper>
          <ButtonComponent />
        </ButtonWrapper>
      )}
    </EmptyWrapper>
  )
}

export default EmptyChatRoom
