import React, { useState } from 'react'
import styled from 'styled-components'
import { Manager, Reference, Popper } from 'react-popper'
import { find, propEq, equals } from 'ramda'
import { CloseClickOutside } from 'components/window'
import { colors, fontWeights } from 'styles'
import arrowIcon from './down-arrow-white.png'

const Box = styled.div`
  height: 30px;
  padding: 0 10px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 300;
`

const Icon = styled.img`
  margin-left: 10px;
  width: 12px;
  transition: all 0.3s linear;
  transform: rotate(0deg);

  ${({ visible }) => (visible ? 'transform: rotate(180deg);' : '')}
`

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 148px;
  border: 1px solid ${colors.grey};
  border-radius: 0 0 3px 3px;
  cursor: pointer;
  background-color: ${colors.white};
  z-index: 1;
`

const OptionItem = styled.a`
  color: ${colors.darkGrey};
  font-size: 11px;
  letter-spacing: 0.25px;
  font-weight: ${fontWeights.medium};
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid ${colors.darkGrey};

  &:hover {
    background: ${colors.grey};
  }

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.active &&
    `
    opacity: 0.3;
    cursor: default;
  `}
`

const LANGUAGES = [
  {
    value: 'en',
    label: 'Canada (EN)'
  },
  {
    value: 'zh',
    label: '中文 (CN)'
  }
]

const LanguageSelector = ({ value, onChange }) => {
  const [opened, setOpened] = useState(false)
  const language = find(propEq('value', value.substring(0, 2)))(LANGUAGES) || {}

  const toggle = () => setOpened(!opened)
  const close = () => setOpened(false)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <Box onClick={toggle} ref={ref}>
            {language.label}
            <Icon visible={opened} src={arrowIcon} alt="Toggle Dropdown" />
          </Box>
        )}
      </Reference>
      <Popper placement="top-end">
        {({ ref, style, placement }) =>
          opened && (
            <CloseClickOutside onClickOutside={close}>
              <Dropdown style={style} ref={ref} data-placement={placement}>
                {LANGUAGES.map(option => (
                  <OptionItem
                    onClick={() => onChange(option.value)}
                    active={equals(option, language)}
                    key={option.value}
                  >
                    {option.label}
                  </OptionItem>
                ))}
              </Dropdown>
            </CloseClickOutside>
          )
        }
      </Popper>
    </Manager>
  )
}

export default LanguageSelector
