import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, breakpoints } from 'styles'
import DoubleText from 'components/double-text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 240px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.12);

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column-reverse;
    box-shadow: none;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 240px;
  flex: 1.1;
  padding-left: 40px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
  }
`

const NeighbourhoodWrapper = styled.div`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const PhotoWrapper = styled.div`
  background-color: #f1f2f1;
  height: 240px;
  flex: 1;
  overflow: hidden;
  border-radius: 0 5px 5px 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
    border-radius: 5px;
  }
`

const Title = styled.h3`
  color: ${colors.regular};
  font-size: ${fontSizes.large};
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 30px;
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }
`

const Link = styled.a`
  font-size: ${fontSizes.regular};
  color: ${colors.link};
  margin-top: 15px;
  text-decoration: none;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-top: 7px;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  height: 55vw;
  max-height: 240px;
  object-fit: cover;
  object-position: bottom;
`

const BuildingInfo = ({ data, photo, link: { text, ...link } = {} }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <InfoWrapper>
        <Title>{data.name}</Title>
        <NeighbourhoodWrapper>
          {data.neighborhood ? (
            <DoubleText
              topText={t('b.listing.view.neighbourhood.text', 'Neighbourhood')}
              bottomText={data.neighborhood}
            />
          ) : (
            <DoubleText
              topText={data.full_street_name}
              bottomText={`${data.city}, ${data.state}`}
            />
          )}
        </NeighbourhoodWrapper>
        {link && <Link {...link}>{text}</Link>}
      </InfoWrapper>
      <PhotoWrapper>
        <Image src={photo} alt={data.name} />
      </PhotoWrapper>
    </Wrapper>
  )
}

export default BuildingInfo
