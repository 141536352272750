import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, fontWeights } from 'styles'
import { getFees } from 'models/payment/ports'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { Input } from 'components/form'
import { Error } from '../elements'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 40px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0 0 20px 0;
  text-align: center;
`

const InputWrapper = styled.div`
  width: 300px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
  }
`

const StyledButton = styled(Button)`
  margin-top: 40px;
`

const DiscountCodeModal = ({
  requested_amount,
  setFieldValue,
  setFeesInfo
}) => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)

  const handleAddDiscount = async () => {
    if (!code) return setError(true)
    const { body, response } = await getFees({
      body: {
        amount: requested_amount,
        promo_code: code
      }
    })
    if (response.ok) {
      setFieldValue('promo_code', code)
      setFeesInfo(body)
      close()
    } else {
      setError(true)
    }
  }

  const buttonText = t('r.payment.edit.add.button', 'Add')

  return (
    <Wrapper
      role="dialog"
      aria-labelledby="discount-dialog-title"
      aria-describedby="discount-dialog-message"
    >
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Icon id="info_circle" width={75} height={75} />
        <ModalTitle id="discount-dialog-title">
          {t('r.payment.edit.add_discount.title', 'Add Discount')}
        </ModalTitle>
        <ModalText id="discount-dialog-message" as="label" htmlFor="promo_code">
          {t(
            'r.payment.edit.enter_discount_code.text',
            'Enter your discount code below'
          )}
        </ModalText>
        <InputWrapper>
          <Input
            id="promo_code"
            aria-labelledby="discount-dialog-message"
            placeholder={t(
              'r.payment.edit.enter_code.placeholder',
              'Enter code'
            )}
            onChange={e => setCode(e.target.value)}
          />
          {error && (
            <Error role="alert">
              **{' '}
              {t(
                'r.payment.edit.invalid_discount_code.error',
                'Invalid code. Please try again.'
              )}
            </Error>
          )}
        </InputWrapper>
        <StyledButton
          aria-label={buttonText}
          theme="primary"
          width={150}
          onClick={handleAddDiscount}
        >
          {buttonText}
        </StyledButton>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(DiscountCodeModal)
