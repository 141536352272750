import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import styled from 'styled-components'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import View from './view'
import FormComponent from './form'
import Completed from './completed'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const ContentWrapper = styled.div`
  padding: 0px 20px 30px;
  overflow-y: auto;
  max-height: 70vh;
`

const CloseButton = styled(Icon)`
  position: absolute;
  top: 25px;
  right: 25px;
  margin: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

const EndTenancyModal = ({ listingId, endTenancyId }) => {
  const dispatch = useDispatch()
  const requestEndTenancy = dispatch.endTenancy.request
  const cancelEndTenancy = dispatch.endTenancy.cancel
  const loadEndTenancyData = dispatch.endTenancy.loadAll
  const loadListingData = dispatch.listing.load
  const listingData = useSelector(state =>
    select.listing.listingDetail(state, {
      listingId
    })
  )

  useEffect(() => {
    loadEndTenancyData({ listingId })
    loadListingData(listingId)
  }, [listingId, loadEndTenancyData, loadListingData])

  const endTenancyRequestData = useSelector(state =>
    select.endTenancy.endTenancyRequestData(state, { listingId, endTenancyId })
  )

  const { close } = useModalize()
  const handleCancelRequest = async () => {
    const response = await cancelEndTenancy({
      listingId,
      requestId: endTenancyRequestData?.id
    })
    if (response?.response?.ok) {
      close()
    }
  }

  const renderView = state => {
    if (
      (!endTenancyRequestData?.id ||
        endTenancyRequestData?.state_machine === 'canceled' ||
        endTenancyRequestData?.end_response === 'declined' ||
        endTenancyRequestData?.end_response === 'support_requested') &&
      !endTenancyId
    ) {
      return (
        <FormComponent
          close={close}
          onSubmit={requestEndTenancy}
          listingId={listingId}
        />
      )
    }
    const Component =
      endTenancyRequestData?.state_machine === 'active' ? View : Completed

    return (
      <Component
        onSubmit={handleCancelRequest}
        data={endTenancyRequestData}
        listingData={listingData}
        close={close}
      />
    )
  }

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      <ContentWrapper>{renderView()}</ContentWrapper>
    </Wrapper>
  )
}

export const show = createModal(EndTenancyModal)
