import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import Page from 'components/page'
import DashboardHeader from 'features/Tenant/dashboard-header'
import MainResources from './main-resources'
import ContractHistory from './contract-history'

const TenantResources = props => {
  const { t } = useTranslation()

  return (
    <>
      <DashboardHeader
        listingId={props.match.params.listingId}
        border
        currentPage={t('r.rental_dashboard.edit.resources.title', 'Resources')}
      />
      <Page>
        <Helmet>
          <title>
            {t('r.rental_dashboard.edit.resources.title', 'Resources')}
          </title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Switch>
          <Route
            exact
            path="/tenant/:listingId/resources"
            component={MainResources}
          />
          <Route
            exact
            path="/tenant/:listingId/resources/contract-history"
            component={ContractHistory}
          />
        </Switch>
      </Page>
    </>
  )
}

export default TenantResources
