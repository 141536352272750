import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { colors, fontWeights, breakpoints } from 'styles'
import { CompanyListings } from 'features/CompanyManagement'
import MobileFooterButton from 'components/mobile-footer-button'

const EditButton = styled(Link)`
  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background: ${colors.white};
  padding: 10px 20px;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: center;
  margin-right: 30px;

  :hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const ManageListings = ({ history, match }) => {
  const { t } = useTranslation()

  const userId = match?.params?.userId
  const users = useSelector(state => state?.companyManagement?.users)
  const user = users?.[userId]

  const buildings = useSelector(state =>
    select.companyManagement.buildings(state, {
      userId
    })
  )

  const handleRowClick = listingId => {
    history.push(`/manage/listings/${listingId}/`)
  }

  const getPageTitle = () => {
    if (user) {
      const nameEndsWithS = user.first_name.match(/.*(s)$/i)
      return `${user.first_name}${nameEndsWithS ? `'` : `'s`} ${t(
        'common.listings',
        'Listings'
      )}`
    }
    return ''
  }

  const EditUserButton = () => (
    <EditButton to={`/manage/users/${userId}/edit/`}>
      {t(
        'l.manage_users.list.manage_users_listings_edit_user.button',
        'Edit user'
      )}
    </EditButton>
  )

  const MobileEditUserButton = () => (
    <MobileFooterButton
      to={`/manage/users/${userId}/edit/`}
      breakpoint={breakpoints.ipadMiniMax}
    >
      {t(
        'l.manage_users.list.manage_users_listings_edit_user.button',
        'Edit user'
      )}
    </MobileFooterButton>
  )

  return (
    <CompanyListings
      pageTitle={getPageTitle()}
      buildings={buildings}
      onRowClick={handleRowClick}
      buttonComponent={EditUserButton}
      mobileButtonComponent={MobileEditUserButton}
    />
  )
}

export default React.memo(ManageListings)
