import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { select } from 'store'
import { update } from 'ramda'
import { colors, fontSizes, fontWeights } from 'styles'
import { AddendumTitle, AddendumPlainDescription } from 'components/addendum'
import { UnstyledButton } from 'components/anchor'
import { SectionHeader } from 'components/headings'
import ListBox from 'components/list-box'
import Section from 'components/section'
import { Strong } from 'components/typography'
import { showAddendumModalWizard } from './addendum-modal-wizard'
import { showAddendumModalView } from './addendum-modal-view'
import InfoIcon from './info-icon.svg'
import formatVariables from '../format-variables'

const Row = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  flex-direction: row;
  padding: 20px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey};
  }

  &:hover {
    background-color: ${colors.lightGrey};
  }
`

const Left = styled.div`
  flex: 9999;
  margin-right: 34px;
  overflow: hidden;
`

const InfoIconWrapper = styled.div`
  flex: 0.1;
  flex-basis: 20px;
  height: 20px;
  background-image: url(${InfoIcon});
  background-position: center right;
  background-repeat: no-repeat;
`

const SignButton = styled(UnstyledButton)`
  flex: 0.1;
  flex-basis: 26px;
  color: ${colors.link};
  font-size: 14px;

  :hover {
    opacity: 0.7;
  }
`

const Title = styled(AddendumTitle)`
  font-weight: ${fontWeights.medium};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin = '0px' }) => margin};
`

const Description = styled(AddendumPlainDescription)`
  display: block;
  font-size: ${fontSizes.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

const CustomTitle = styled(Strong)`
  font-weight: ${fontWeights.medium};
  font-size: ${({ fontSize }) => fontSize};
`

const CustomDescription = styled.span`
  display: block;
  font-size: ${fontSizes.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const mapState = (state, ownProps) => {
  const isStepComplete = select.contract.isStepComplete(state, {
    step: 'review'
  })
  return {
    isStepComplete
  }
}

const mapDispatch = dispatch => ({
  completeStep: () => dispatch.contract.completeStep('review'),
  incompleteStep: () => dispatch.contract.incompleteStep('review')
})

class Addendums extends React.Component {
  constructor(props) {
    super(props)

    const addendums = [
      ...props.contract.addendums,
      ...props.contract.custom_addendums
    ].map(addendum => ({
      ...addendum,
      complete: props.isStepComplete || props.contract.is_complete
    }))

    this.initialState = {
      addendums,
      formattedVariables: formatVariables(props.contract),
      isTenant: props.userType === 'tenant'
    }

    this.state = { ...this.initialState }

    if (!this.state.isTenant || addendums.length === 0) {
      props.completeStep()
    }

    this.onAgreementChange = this.onAgreementChange.bind(this)
  }

  onAgreementChange(item) {
    const itemIndex = this.state.addendums.findIndex(
      addendum => item.id === addendum.id
    )
    const updatedAddendum = {
      ...item,
      complete: !item.complete
    }

    this.setState(
      {
        addendums: update(itemIndex, updatedAddendum, this.state.addendums)
      },
      () =>
        this.hasMissingAddendum()
          ? this.props.incompleteStep()
          : this.props.completeStep()
    )
  }

  onChange() {
    return this.state.isTenant && !this.props.disabled
      ? this.onAgreementChange
      : null
  }

  openAddendumWizard(key) {
    const { addendums } = this.state

    showAddendumModalWizard({
      data: addendums,
      activeStep: key,
      onChange: this.onChange(),
      variables: this.state.formattedVariables
    })
  }

  toggleAddendumView(addendum, signed) {
    showAddendumModalView({
      match: this.props.match,
      addendum,
      onChange: this.onChange(),
      variables: this.state.formattedVariables,
      signed
    })
  }

  hasAddendums() {
    return this.state.addendums.length > 0
  }

  hasMissingAddendum() {
    return (
      !this.hasAddendums() ||
      this.state.addendums.some(({ complete }) => !complete)
    )
  }

  clickAddendum(item) {
    const { isTenant } = this.state
    const { disabled } = this.props

    if (item.complete || (!isTenant && item.complete)) {
      this.toggleAddendumView(item, { signed: true })
    } else if (!isTenant || disabled) {
      this.toggleAddendumView(item)
    } else {
      this.openAddendumWizard(item.id)
    }
  }

  renderAddendumRow(item) {
    const fontSize = fontSizes.regular
    const { isTenant } = this.state
    const { disabled } = this.props
    const { t } = this.props

    return (
      <Row key={item.id} onClick={() => this.clickAddendum(item)}>
        <Left>
          {item.title ? (
            <>
              <CustomTitle fontSize={fontSize}>{item.title}</CustomTitle>
              <CustomDescription>{item.description}</CustomDescription>
            </>
          ) : (
            <>
              <Title addendumId={item.id} fontSize={fontSize} />
              <Description
                addendum={item}
                addendumId={item.id}
                variables={this.state.formattedVariables}
              />
            </>
          )}
        </Left>
        {isTenant && !disabled && !item.complete ? (
          <SignButton>
            {t('r.contract.view.addendums_sign.button', 'Sign')}
          </SignButton>
        ) : (
          <InfoIconWrapper />
        )}
      </Row>
    )
  }

  render() {
    const { t } = this.props
    const { addendums } = this.state

    if (addendums.length === 0) {
      return null
    }

    return (
      <>
        <Section>
          <SectionHeader>
            {t('b.contract.view.addendums.text', 'Addendums')} (
            {addendums.length})
          </SectionHeader>
          <p>
            {t(
              'b.contract.view.addendums_description.text',
              'The following Addendums are attached as schedules to this Agreement and incorporated by reference into this Agreement.'
            )}
          </p>
          <ListBox
            data={addendums}
            renderItem={item => this.renderAddendumRow(item)}
          />
        </Section>
      </>
    )
  }
}

Addendums.defaultProps = {
  contract: {},
  userType: null,
  isStepComplete: false,
  disabled: false
}

const AddendumsContainer = compose(
  withTranslation(),
  connect(mapState, mapDispatch)
)(Addendums)

export default withRouter(AddendumsContainer)
