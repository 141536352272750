import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import AutomobileList from './list'
import AutomobileForm from './form'

// prettier-ignore
const AutomobileFullList = ({ showRequiredWarning = false }) => {
  const { t } = useTranslation()

  const vehicles = useSelector(select.profile.vehicles)
  return (
    <AutomobileList
      data={vehicles}
      AddComponent={AutomobileForm}
      EditComponent={AutomobileForm}
      emptyMessage={t(
        'r.profile.view.empty_automobile.text',
        'You don\'t have automobiles added in your profile.'
      )}
      editable
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(AutomobileFullList)
