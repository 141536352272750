import styled from 'styled-components'
import { colors, fontWeights, fontSizes } from 'styles'

const H1 = styled.h1`
  color: ${colors.regular};
  font-size: 40px;
  line-height: 46px;
  font-weight: ${fontWeights.medium};
  margin-bottom: 3vh;
`

const H2 = styled.h2`
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
`

const PageTitle = styled(H1)`
  font-size: ${fontSizes.large};
`

const SectionHeader = styled(H2)`
  font-size: ${fontSizes.medium};
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 1px ${colors.grey};
`

export { H1, H2, PageTitle, SectionHeader }
