import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  @media print {
    page-break-inside: avoid;
    page-break-after: always;
  }
`

const AvoidBreakInside = ({ children }) => <Wrapper>{children}</Wrapper>

export default AvoidBreakInside
