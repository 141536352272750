import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TabPanel from './tab-panel'
import { Provider } from './context'

class Tabs extends PureComponent {
  static propTypes = {
    selectedInitialId: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  static defaultProps = {
    selectedInitialId: null
  }

  constructor(props) {
    super(props)
    this.state = {
      activeTabId: props.selectedInitialId || this.getFirstId()
    }

    this.changeTab = this.changeTab.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedInitialId !== prevProps.selectedInitialId) {
      this.changeTab(this.props.selectedInitialId)
    }
  }

  getFirstId() {
    return this.props.children
      .filter(a => a.type === TabPanel)
      .reduce(a => a.props.id)
  }

  changeTab(newTabId) {
    this.setState({
      activeTabId: newTabId
    })
  }

  render() {
    return (
      <Provider
        value={{
          activeTabId: this.state.activeTabId,
          changeTab: this.changeTab
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export default Tabs
