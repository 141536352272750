import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useRole from 'hooks/use-role'
import { colors, fontSizes, fontWeights } from 'styles'
import { FieldSwitchToggle } from 'components/form'
import { SectionHeading, SectionRow } from '../elements'

const Section = styled.section`
  max-width: 660px;
`

const SubGroup = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    padding-bottom: 50px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${colors.grey};
  }
`

const PanelSubHeading = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 24px;
  letter-spacing: -0.38px;
  margin: 0 0 30px;
`

const PanelText = styled.p`
  color: ${colors.regular};
  font-size: 13px;
  line-height: 18px;
  margin: 0 0 30px;
`

const Label = styled.label`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 22px;
  margin: 0;
`

const Notifications = () => {
  const { t } = useTranslation()
  const userRole = useRole()

  return (
    <Section>
      <SectionHeading>
        {t('b.settings.view.notifications.title', 'Notifications')}
      </SectionHeading>
      <SubGroup>
        <PanelSubHeading>
          {t('b.settings.view.chat.title', 'Chat')}
        </PanelSubHeading>
        <PanelText>
          {userRole === 'tenant'
            ? t(
                'r.settings.view.activity.text',
                'Receive messages and notifications from landlords whenever an event occurs e.g., when a showing is delayed or when both parties have signed the lease contract.'
              )
            : t(
                'l.settings.view.activity.text',
                'Receive messages and notifications from renters whenever an event occurs e.g., when a showing is delayed or when both parties have signed the lease contract.'
              )}
        </PanelText>
        <SectionRow>
          <Label>
            {t(
              'b.settings.view.push_notifications.label',
              'Push notifications'
            )}
          </Label>
          <FieldSwitchToggle name="activity_receive_push_notifications" />
        </SectionRow>
        <SectionRow>
          <Label>
            {t('b.settings.view.text_messages.label', 'Text messages')}
          </Label>
          <FieldSwitchToggle name="activity_receive_text_messages" />
        </SectionRow>
        <SectionRow>
          <Label>{t('b.settings.view.email.label', 'Email')}</Label>
          <FieldSwitchToggle name="activity_receive_emails" />
        </SectionRow>
      </SubGroup>
      <SubGroup>
        <PanelSubHeading>
          {t('b.settings.view.marketing.title', 'Marketing')}
        </PanelSubHeading>
        <PanelText>
          {t(
            'b.settings.view.marketing.text',
            'Receive messages, newsletters, marketing emails and deals from Liv.Rent. '
          )}
        </PanelText>
        <SectionRow>
          <Label>{t('b.settings.view.email.label', 'Email')}</Label>
          <FieldSwitchToggle name="setting_promo_emails" />
        </SectionRow>
      </SubGroup>
    </Section>
  )
}

export default Notifications
