import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints } from 'styles'
import useRole from 'hooks/use-role'
import { showChangePasswordModal } from 'features/Auth/ChangePasswordModal'
import MenuCard from 'components/menu-card'
import { SectionHeading } from '../elements'

const Section = styled.section``

const MenuCardsWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  justify-content: start;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 290px);
  max-width: 950px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    grid-gap: 20px 25px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const AccountSettings = () => {
  const { t } = useTranslation()
  const userRole = useRole()
  const isLandlord = userRole === 'landlord'

  const LANDLORD_SETTINGS = [
    {
      title: t('b.settings.view.payment_settings.label', 'Payment Settings'),
      description: t(
        'b.settings.view.payment_settings.text',
        'Set up financial accounts to receive rental payments'
      ),
      iconId: 'money',
      to: '/settings/payment-settings'
    }
  ]

  const TENANT_SETTINGS = [
    {
      title: t('b.settings.view.billing_address.label', 'Billing Address'),
      description: t(
        'b.settings.view.billing_address.text',
        'Add or remove your billing address here'
      ),
      iconId: 'money',
      to: '/settings/billing-address'
    }
  ]

  const GENERAL_SETTINGS = [
    {
      title: t(
        'b.settings.view.change_phone_number.label',
        'Change Phone Number'
      ),
      mobileTitle: t(
        'b.settings.view.change_phone_no.label',
        'Change Phone No.'
      ),
      description: t(
        'b.settings.view.change_phone_number.text',
        'Change your login phone number here'
      ),
      iconId: 'telephone',
      to: '/settings/change-phone-number'
    },
    {
      title: t('b.settings.view.change_password.label', 'Change Password'),
      description: t(
        'b.settings.view.change_password.text',
        'Change your account password here'
      ),
      iconId: 'secure',
      onClick: showChangePasswordModal
    },
    {
      title: t('b.settings.view.delete_my_account.label', 'Delete My Account'),
      description: t(
        'b.settings.view.delete_my_account.text',
        'No longer need us?'
      ),
      iconId: 'delete',
      to: '/settings/feedback'
    }
  ]

  const menuItems = (isLandlord ? LANDLORD_SETTINGS : TENANT_SETTINGS).concat(
    GENERAL_SETTINGS
  )

  return (
    <Section>
      <SectionHeading>
        {t('b.settings.view.account_settings.title', 'Account Settings')}
      </SectionHeading>
      <MenuCardsWrapper>
        {menuItems.map(item => (
          <MenuCard key={`settings-card-${item.to}`} item={item} />
        ))}
      </MenuCardsWrapper>
    </Section>
  )
}

export default AccountSettings
