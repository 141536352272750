import createFlags from 'flag'
// import config from 'config'

// const isDev = config.BUILD_ENV === 'dev'

const flags = {
  features: {
    chat: {
      menu: true,
      menuReport: true
    },
    application: {
      create: true
    },
    newProfile: true,
    contract: {
      create: true,
      renew: true
    },
    newApplication: true,
    payments: true,
    listing: {
      settings: true
    },
    manage: true,
    profile: {
      tenant: true,
      landlord: true
    },

    settings: {
      tenant: true,
      landlord: true
    },
    support: true,
    tenantDashboard: true,
    sharp: false,
    tenantsDashboardResources: true
  }
}
const { FlagsProvider, Flag, useFlag, useFlags } = createFlags()

export { FlagsProvider, Flag, useFlag, useFlags, flags }
