import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 2;
`

const DropHereText = styled.span`
  font-size: 24px
  position: absolute;
  color: ${colors.secondary};
  font-size: 35px;
  text-align: center;
  padding: 125px;
  border: 3px dashed ${colors.secondary};
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 75px;
    font-size: 25px;
  }
`

const EmptyView = () => {
  const [t] = useTranslation()

  return (
    <Wrapper>
      <DropHereText>
        <p> {t('b.chat.view.send_files_drop_here.text', 'DROP HERE')}</p>
        <p>
          {' '}
          {t(
            'b.chat.view.send_files_max_files.text',
            'Max. 10 files per upload'
          )}
        </p>
      </DropHereText>
    </Wrapper>
  )
}

export default EmptyView
