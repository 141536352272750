import { set, lensPath, pipe, path, mergeLeft, dissocPath } from 'ramda'
import selectorBuilder from 'models/selector-builder'
import {
  cancelEndTenancy,
  requestEndTenancy,
  forceEndTenancy,
  replyRequest,
  getEndTenancies
} from './ports'
import { endTenancyRequestSelector } from './selectors'

const endTenancy = {
  state: {},
  reducers: {
    patch: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), mergeLeft(payload))(state), state),
    remove: (state, at) => dissocPath(at, state)
  },

  effects: dispatch => ({
    async request({ listingId, values }) {
      try {
        const { response, body } = await requestEndTenancy(
          { body: values },
          { listingId }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.endTenancy.patch(['end_tenancy_requests'], body)
        return { response }
      } catch (error) {
        console.error('[endTenancy/request]', error)
      }
    },
    async forceEndTenancy(payload) {
      try {
        const { response, body } = await forceEndTenancy(
          { body: payload.body },
          { listingId: payload.listingId, endTenancyId: payload.endTenancyId }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.endTenancy.patch(['end_tenancy_requests'], body)
        return { response }
      } catch (error) {
        console.error('[endTenancy/request]', error)
      }
    },
    async loadAll({ listingId }) {
      try {
        const { response, body } = await getEndTenancies({ listingId })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.endTenancy.patch(['end_tenancy_requests'], body)
        return { response }
      } catch (error) {
        console.error('[endTenancy/loadAll]', error)
      }
    },
    async cancel({ requestId, listingId }) {
      try {
        const { response, body } = await cancelEndTenancy(
          {},
          { requestId, listingId }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.endTenancy.patch(['end_tenancy_requests'], body)
        return { response }
      } catch (error) {
        console.error('[endTenancy/cancel]', error)
      }
    },
    async reply({ values, listingId }) {
      try {
        const { response, body } = await replyRequest(
          { body: values },
          { listingId }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.endTenancy.patch(['end_tenancy_requests'], body)
        return { response }
      } catch (error) {
        console.error('[endTenancy/cancel]', error)
      }
    }
  }),
  selectors: selectorBuilder(slice => ({
    endTenancyRequestData() {
      return slice(endTenancyRequestSelector)
    }
  }))
}

export default endTenancy
