import React from 'react'
import styled from 'styled-components'
import { Label, Row, Group } from 'components/form'
import { colors, fontSizes } from 'styles'
import { NavLink } from 'react-router-dom'

export { Label }

export const Description = styled.div`
  margin-top: 10px;
  font-size: ${fontSizes.small};
  color: ${colors.regular};
`

const Button = styled.button`
  text-decoration: none;
  text-align: left;
  background: none;
  width: 100%;
  border: none;
  padding: 0;
`

const StyledGroup = styled(Group)`
  display: block;
  padding: 22px 0;
  margin: 0;
  border-bottom: 1px solid ${colors.grey};

  label {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  display: block;
  ${props =>
    props.hasIcon &&
    `
    display: flex;
    width: 100%;
  `}
`

export const MenuItem = ({ children, to, onClick, hasIcon, ...rest }) => {
  const Wrapper = typeof onClick === 'function' ? Button : NavLink
  return (
    <Wrapper to={to} onClick={onClick} {...rest}>
      <StyledGroup col={12}>
        <StyledRow hasIcon={hasIcon}>{children}</StyledRow>
      </StyledGroup>
    </Wrapper>
  )
}
