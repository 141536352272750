import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { find, propEq, values } from 'ramda'
import { useDispatch } from 'react-redux'
import { breakpoints, colors, fontSizes, fontWeights, structure } from 'styles'
import {
  getUnionPayPayout,
  verifyPayoutDeletion
} from 'models/settlements/ports'
import useBasic from 'hooks/use-basic'
import { getFormattedCountries } from 'utils/address'
import Panel from 'components/panel'
import { Label, Row, Group } from 'components/form'
import PageTitle from 'components/page-title'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import Icon from 'components/icon'

const Wrapper = styled.div`
  padding-top: 80px;
  padding-bottom: ${structure.button_bar.height + 80}px;
`

const ContentWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 10px;
  }
`

export const StyledPanel = styled(Panel)`
  text-align: left;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0px;
  }
`

export const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

export const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  border-top: ${props => (props.borderTop ? 1 : 0)}px solid ${colors.grey};
`

export const StyledLabel = styled(Label)`
  flex: 1;
  margin-right: 20px;

  ${props =>
    props.bold &&
    `
      font-weight: ${fontWeights.medium};
  `}
`

export const StyledValue = styled(Label)`
  font-weight: ${fontWeights.medium};
  flex: 1.5;
  text-align: right;
`

export const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: 10px;
`

const ConnectedText = styled.p`
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.38px;
  line-height: 24px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  padding-bottom: 30px;
  margin: 0 10px 30px;
`

const Description = styled.p`
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0 10px;
`

const ViewUnionPay = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { countries, financial_institutions } = useBasic([
    'countries',
    'financial_institutions'
  ])
  const formattedCountries = getFormattedCountries(countries)
  const [data, setData] = useState('')
  const [deleteAllowed, setDeleteAllowed] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const { body } = await getUnionPayPayout()
      setData(body.payout)
    }
    loadData()
  }, [])

  useEffect(() => {
    const verifyDelete = async () => {
      const { response, body } = await verifyPayoutDeletion()
      if (response.ok) {
        setDeleteAllowed(body.is_allowed)
      }
    }
    verifyDelete()
  }, [])

  if (!data) return null

  const accountTypes = [
    {
      value: 'checking',
      label: t(
        'l.payment_settings.edit.checking_account.label',
        'Chequing account'
      )
    },
    {
      value: 'savings',
      label: t(
        'l.payment_settings.edit.savings_account.label',
        'Savings account'
      )
    }
  ]

  const getCountryName = countryCode =>
    find(propEq('value', countryCode), formattedCountries).label

  const formatAddress = ({ street1, street2, city, state, country }) => {
    const fullCountry = getCountryName(country)
    return `${street1}, ${
      street2 ? street2 + ',' : ''
    } ${city} ${state}, ${fullCountry}`
  }

  const getBankName = bank =>
    find(propEq('txt_id', bank))(values(financial_institutions))?.name || bank

  const getAccountType = account =>
    find(propEq('value', account))(accountTypes).label

  const handleRemoveUnionPay = () => {
    const modalText = deleteAllowed
      ? t(
          'l.payment_settings.edit.remove_unionpay.text',
          'Are you sure you want to continue? You will no longer be able to receive UnionPay payments.'
        )
      : t(
          'l.payment_settings.edit.delete_payment_account.text',
          'Are you sure you want to continue? If you delete this account now, your pending payment requests will be cancelled.'
        )

    return confirm(
      t(
        'l.payment_settings.edit.remove_unionpay.title',
        'Remove Payout Deposit'
      ),
      modalText,
      {
        okLabel: t('b.payment_settings.edit.remove.button', 'Remove'),
        cancelLabel: t('b.payment_settings.edit.back.button', 'Back')
      }
    ).then(async () => {
      const response = await dispatch.settlements.deleteUnionPayPayout()
      if (response.ok) {
        history.push('/settings/payment-settings')
      }
    })
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('l.payment_settings.view.unionpay.title', 'UnionPay')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('l.payment_settings.view.unionpay.title', 'UnionPay')}
      </PageTitle>
      <ContentWrapper>
        <StyledPanel hSpace={30} vSpace={40}>
          <Row>
            <ConnectedText>
              <StyledIcon id="checked_green" width={24} height={24} />
              {t(
                'l.payment_settings.view.payout_deposit_connected.text',
                'Payout Deposit Connected!'
              )}
            </ConnectedText>
          </Row>
          <Row>
            <Description>
              {t(
                'l.payment_settings.view.unionpay_connected.description',
                'You’re now able to receive UnionPay payments. To edit this account info, please remove the current one, and re-enter your new info.'
              )}
            </Description>
          </Row>
        </StyledPanel>
        <AreaTitle>
          {t(
            'l.payment_settings.view.bank_account_detail.subtitle',
            'Bank Account Detail'
          )}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={50}>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('l.payment_settings.view.name.label', 'Name')}
            </StyledLabel>
            <StyledValue>{`${data.personal_info.first_name} ${data.personal_info.last_name}`}</StyledValue>
          </StyledRow>
          {data.personal_info.company_name && (
            <StyledRow bottom={10}>
              <StyledLabel>
                {t('l.payment_settings.view.company.label', 'Company')}
              </StyledLabel>
              <StyledValue>{data.personal_info.company_name}</StyledValue>
            </StyledRow>
          )}
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('l.payment_settings.view.address.label', 'Address')}
            </StyledLabel>
            <StyledValue>{formatAddress(data.personal_info)}</StyledValue>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              {t('l.payment_settings.view.postal_code.label', 'Postal code')}
            </StyledLabel>
            <StyledValue>{data.personal_info.zip.toUpperCase()}</StyledValue>
          </StyledRow>
        </StyledPanel>
        <AreaTitle>
          {t(
            'l.payment_settings.view.financial_account_information.subtitle',
            'Financial Account Information'
          )}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={50}>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t(
                'l.payment_settings.view.financial_institution.label',
                'Financial instituition'
              )}
            </StyledLabel>
            <StyledValue>
              {getBankName(data.financial_info.financial_institution_name)}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('l.payment_settings.view.account_type.label', 'Account type')}
            </StyledLabel>
            <StyledValue>
              {getAccountType(data.financial_info.account_type)}
            </StyledValue>
          </StyledRow>
          <StyledRow>
            <StyledLabel>
              {t(
                'l.payment_settings.view.account_number.label',
                'Account number'
              )}
            </StyledLabel>
            <StyledValue>{data.financial_info.account_number}</StyledValue>
          </StyledRow>
        </StyledPanel>
      </ContentWrapper>
      <ButtonBar sticky>
        <Button
          theme="sane"
          width={150}
          inversed
          onClick={() => history.push('/settings/payment-settings')}
        >
          {t('l.payment_settings.view.back.button', 'Back')}
        </Button>
        <Button theme="primary" width={150} onClick={handleRemoveUnionPay}>
          {t('l.payment_settings.view.remove.button', 'Remove')}
        </Button>
      </ButtonBar>
    </Wrapper>
  )
}

export default ViewUnionPay
