const formatCurrency = (
  amount,
  locale,
  currency = 'CAD',
  style = 'currency',
  options = {}
  // eslint-disable-next-line max-params
) => {
  const parsedAmount = parseFloat(amount, 10)

  if (isNaN(parsedAmount)) {
    return NaN
  }

  return parsedAmount.toLocaleString(locale, {
    style,
    currency,
    ...options
  })
}

export default formatCurrency
