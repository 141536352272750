import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import useCurrentSession from 'hooks/use-current-session'
import useIsMobile from 'hooks/use-is-mobile'
import downloadImages from 'utils/download-images'
import { Button } from 'components/button'
import { showShareModal } from './share-modal'
import CopyListingCraigslist from './copy-listing-craigslist'
import CopyListingKijiji from './copy-listing-kijiji'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  width: 360px;
  border: 1px solid ${colors.grey};
  border-radius: 0 0 3px 3px;
  cursor: pointer;
  background-color: ${colors.white};
`

const ShareItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0 15px;
  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey};
  }
`

const RowTitle = styled.span``

const ShareMenu = React.memo(({ generateDeeplink, listingId, ...rest }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const session = useCurrentSession()

  const data = useSelector(state =>
    select.listing.editListing(state, {
      listingId
    })
  )

  const handleShare = async type => {
    const DATA = {
      craigslist: CopyListingCraigslist,
      kijiji: CopyListingKijiji,
      url: dispatch.listing.generateDeeplink
    }

    const copyToClipboard = async i => {
      const str = await DATA[i](data, session.setting_size_unit)
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    }

    if (DATA[type]) {
      await copyToClipboard(type)
    }

    if ((type === 'craigslist' || type === 'kijiji') && !isMobile) {
      downloadImages(data.images)
    }

    showShareModal({ shareType: type, listingId })
  }

  const shareItems = [
    {
      title: 'Craigslist',
      action: () => handleShare('craigslist')
    },
    {
      title: 'Kijiji',
      action: () => handleShare('kijiji')
    },
    {
      title: t('l.listing.view.share_invite_phone.label', 'Invite by Phone'),
      action: () => handleShare('phone')
    },
    {
      title: t('l.listing.view.share_invite_email.label', 'Invite by Email'),
      action: () => handleShare('email')
    },
    {
      title: t('l.listing.view.share_invite_url.label', 'Share Listing URL'),
      action: () => handleShare('url')
    }
  ]

  return (
    <Wrapper {...rest}>
      {shareItems.map(item => (
        <ShareItem key={item.title}>
          <RowTitle>{item.title}</RowTitle>
          <Button
            type="button"
            theme="primary"
            size="small"
            width={110}
            onClick={item.action}
          >
            {['Email', 'Phone'].some(el => item.title.includes(el))
              ? t('l.listing.view.share_invite.button', 'Invite')
              : t('l.listing.view.share_copy.button', 'Copy')}
          </Button>
        </ShareItem>
      ))}
    </Wrapper>
  )
})

export default ShareMenu
