import { when, equals, set, lensPath, omit, always, forEach } from 'ramda'
import { batch } from 'react-redux'
import { queryCache } from 'react-query'
import {
  login,
  requestAutoLogin,
  setInitialPassword,
  logout,
  autoLogin,
  register
} from './ports'

const setGaUserId = userId => window.dataLayer.push({ userId })

const auth = {
  state: {},
  reducers: {
    update: (state, payload) => ({ ...state, ...payload })
  },
  effects: dispatch => ({
    async logout() {
      try {
        await logout()

        const keysToRemove = ['session-id', '@session:hasLoggedBefore']
        forEach(item => localStorage.removeItem(item), keysToRemove)
        setGaUserId(null)
        queryCache.clear()
        dispatch.session.clear()
      } catch (error) {
        console.error('[auth/logout] Error Occured: ', error)
      }
    },
    async registerUser(payload, { session }) {
      const parseData = () => {
        const response = {
          body: {
            first_name: payload.first_name,
            last_name: payload.last_name,
            username: payload.email,
            new_password: payload.new_password,
            password_confirm: payload.password_confirm,
            company_info: {
              account_type: payload.account_type,
              operation_type: payload.operation_type,
              name: payload.company_name
            }
          }
        }
        return when(
          always(equals(payload.operation_type, 'tenant')),
          set(
            lensPath(['body', 'company_info']),
            omit(['account_type', 'name'], response.body.company_info)
          )
        )(response)
      }
      try {
        const { response, body } = await register(parseData())

        if (response.ok) {
          const data = {
            ...body,
            operation_type: payload.operation_type,
            agreements: Object.entries(session.agreements).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: {
                  ...value[key],
                  system_agreement_version: value.version
                }
              }),
              {}
            )
          }
          batch(() => {
            dispatch.session.updateSession(data)
            dispatch.profile.updateData(data)
            dispatch.profile.load()
          })
          setGaUserId(body.id)
        }
      } catch (error) {
        console.error('[auth/registerUser] Error Occured: ', error)
      }
    },
    async setInitialPassword(payload) {
      try {
        const { response, body } = await setInitialPassword({ body: payload })
        if (!response.ok) {
          throw new Error(body.message || response.statusText)
        }
        dispatch.session.loadSession()
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async login(payload) {
      dispatch.loading.setLoading()
      try {
        const { response, body } = await login({ body: payload })
        if (!response.ok) {
          throw new Error(body.message || response.statusText)
        }
        await dispatch.session.loadSession()
        return { response, body }
      } catch (error) {
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async requestAutoLogin(payload) {
      dispatch.loading.setLoading()
      try {
        const { response, body } = await requestAutoLogin(payload)
        if (!response.ok) {
          throw new Error(body.message || response.statusText)
        }
        const email = body?.email || payload.username
        return { response, body, email }
      } catch (error) {
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async autoLogin(loginHash) {
      try {
        const { response } = await autoLogin(undefined, { loginHash })
        if (response.ok) {
          localStorage.setItem('auto-login', true)
        }
      } catch (error) {
        console.error('[auth/validatePhone] Error Occured: ', error)
      }
    }
  })
}

export default auth
