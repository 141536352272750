import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Wrapper = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 6px;
`

const ListBox = ({ data, renderItem }) =>
  data.length > 0 && (
    <Wrapper>{data.map((row, index) => renderItem(row, index, data))}</Wrapper>
  )

ListBox.defaultProps = {
  data: []
}

export default ListBox
