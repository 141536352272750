import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import useProfile from 'hooks/use-profile'
import useBasic from 'hooks/use-basic'
import { getFormattedCountries } from 'utils/address'
import Page from 'components/page'
import PageTitle from 'components/page-title'
import ContentWrapper from 'components/content-wrapper'
import ProfileButtonBar from 'features/Profile/button-bar'
import Edit from './edit'
import View from './view'

const UpperProfile = styled.div`
  height: 128px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin-right: auto;
  margin-bottom: 20px;
`

const EditButton = styled.button`
  height: 28px;
  min-width: 68px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 20px;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
`

const BillingAddress = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const userProfile = useProfile()
  const hasAddress = userProfile.has_billing_address
  const addressData = useSelector(path(['settlements', 'billing_address']))
  const { countries } = useBasic(['countries'])
  const formattedCountries = getFormattedCountries(countries)

  useEffect(() => {
    if (hasAddress && !addressData) dispatch.settlements.getBillingAddress()
  }, [hasAddress, addressData, dispatch.settlements])

  const editButtonText = hasAddress
    ? t('r.settings.view.edit.button', 'Edit')
    : `+ ${t(
        'r.settings.view.add_billing_address.button',
        'Add a Billing Address'
      )}`

  return (
    <Page>
      <Helmet>
        <title>
          {t('b.settings.view.account_settings.title', 'Account Settings')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('b.settings.view.account_settings.title', 'Account Settings')}
      </PageTitle>
      <Page>
        <ContentWrapper className="mb-50">
          <UpperProfile>
            <PanelTitle>
              {t('r.settings.view.billing_address.title', 'Billing Address')}
            </PanelTitle>
            {!isEditing && (
              <EditButton type="button" onClick={() => setIsEditing(true)}>
                {editButtonText}
              </EditButton>
            )}
          </UpperProfile>
          {isEditing ? (
            <Edit
              setIsEditing={setIsEditing}
              hasAddress={hasAddress}
              addressData={addressData}
              formattedCountries={formattedCountries}
              countries={countries}
            />
          ) : (
            <View
              hasAddress={hasAddress}
              addressData={addressData}
              formattedCountries={formattedCountries}
            />
          )}
        </ContentWrapper>
        <ProfileButtonBar handleBack={history.goBack} sticky />
      </Page>
    </Page>
  )
}

export default BillingAddress
