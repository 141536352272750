import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { colors, fontWeights, breakpoints, structure } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import SearchBar from 'components/search-bar'

const PageHeader = styled.div`
  top: ${structure.header.height}px;
  position: sticky;
  z-index: 20;
  background-color: ${colors.white};

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    box-shadow: 0 2px 8px 0 rgba(146, 146, 146, 0.69);
  }
`

const PageHeaderInner = styled.div`
  height: 122px;
  max-width: 900px;
  margin: 0 100px;
  padding: 45px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 102px;
    margin: 0 25px;
    padding: 20px 0;
    justify-content: flex-end;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    height: 80px;
    justify-content: center;
  }
`

const HeaderTitle = styled.h1`
  font-size: 30px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 41px;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 50px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    justify-content: center;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
  }
`

const SearchInner = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
    max-width: 325px;
  }
`

const CreateButton = styled(Link)`
  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background: ${colors.white};
  padding: 10px 20px;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: center;
  margin-right: 30px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const ManageUsersHeader = ({ onSearch }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const pageTitle = t('l.profile.list.manage_users.title', 'Manage Users')

  return (
    <PageHeader>
      <PageHeaderInner>
        <HeaderTitle>{pageTitle}</HeaderTitle>
        <FiltersWrapper>
          <SearchInner>
            <CreateButton to="/manage/users/create">
              {t('l.profile.list.add_new_user.button', 'Add new user')}
            </CreateButton>
            <SearchBar
              placeholder={t('common.search', 'Search')}
              onSearch={onSearch}
              fullWidth={displayMobile}
            />
          </SearchInner>
        </FiltersWrapper>
      </PageHeaderInner>
    </PageHeader>
  )
}

export default ManageUsersHeader
