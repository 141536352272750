import React, { useState } from 'react'
import styled from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'
import { breakpoints, structure } from 'styles'
import { toUnit } from 'utils/styles'
import { ContractList, ContractView, ContractPreview } from 'pages/Contract'
import {
  MyListings,
  CreateListing,
  ListingDashboard,
  EditListing,
  VerifyListing
} from 'pages/Listing'
import ProfilePage from 'pages/Profile'
import CommpanyManagemmentPage from 'pages/CompanyManagement'
import {
  ApplicationView,
  ApplicationCreate,
  ApplicationContractCreate
} from 'pages/Application'
import ErrorPage from 'pages/Pages/ErrorPage'
import ChatContainer from 'pages/Chat'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import AgreementModal from 'pages/Pages/agreement'
import WelcomeModal from 'features/Profile/WelcomeModal'
import { StripePaymentContainer, StripeConnectContainer } from 'pages/Payment'
import Settings from 'pages/Settings'
import Support from 'pages/Support'
import { MyTenants, TenantDashboard } from 'pages/Tenants'
import { MyRentals, RentalDashboard } from 'pages/Rentals'
import Header from 'components/header'
import Sidebar from 'components/sidebar'
import useMediaQuery from 'hooks/use-media-query'
import AuthenticatedView from './authenticated-view'

const Layout = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-left: unset;
  }
`

const Wrapper = styled.section`
  display: flex;
  min-height: 100vh;
`

const Content = styled.main`
  width: 100%;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  padding-left: ${props =>
    toUnit(
      props.sidebarExpanded
        ? structure.sidebar.widthExpanded
        : structure.sidebar.width
    )};

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-left: 0;
  }
`

const AuthenticatedApp = ({ session }) => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const [sidebarExpanded, setSidebarExpanded] = useState(!isMobile)
  const defaultPath =
    session.operation_type === 'landlord' ? '/listings' : '/profile'

  return (
    <>
      <AgreementModal />
      <WelcomeModal />
      <Wrapper>
        <Sidebar expanded={sidebarExpanded} setSidebar={setSidebarExpanded} />
        <Content sidebarExpanded={sidebarExpanded}>
          <Header
            sidebarExpanded={sidebarExpanded}
            setSidebar={setSidebarExpanded}
          />
          <Layout>
            <Switch key="switch">
              <AuthenticatedView
                exact
                path="/contracts"
                component={ContractList}
                allowTo={['tenant']}
              />
              <AuthenticatedView
                exact
                path="/tenants"
                component={MyTenants}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                path="/tenant/:listingId"
                component={TenantDashboard}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                exact
                path="/rentals"
                component={MyRentals}
                allowTo={['tenant']}
              />
              <AuthenticatedView
                path="/rental/:listingId"
                component={RentalDashboard}
                allowTo={['tenant']}
              />
              <Route exact path="/chat/:timelineId" component={ChatContainer} />
              <Route exact path="/chat" component={ChatContainer} />
              <AuthenticatedView
                exact
                path="/contract/:contractId/preview/:currentStep(terms)?"
                component={ContractPreview}
                allowTo={['landlord']}
              />
              <Route
                path="/contract/:contractId/:step(review|terms|sign)?"
                component={ContractView}
              />
              <AuthenticatedView
                path="/application/:applicationId/contract/create/:contractId(\d+)"
                component={ApplicationContractCreate}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                path="/application/:applicationId/contract/create"
                component={ApplicationContractCreate}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                exact
                path="/listings"
                component={MyListings}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                exact
                path="/listing/create"
                component={CreateListing}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                exact
                path="/listing/edit-building/:listingId"
                component={CreateListing}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                exact
                path="/listing/:mode(create|edit)/:listingId"
                component={EditListing}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                path="/listing/verify/:listingId"
                component={VerifyListing}
                allowTo={['landlord']}
              />
              <AuthenticatedView
                path="/listing/:listingId"
                component={ListingDashboard}
                allowTo={['landlord']}
              />
              <Route path="/settings" component={Settings} />
              <Route path="/support" component={Support} />
              <Route
                exact
                path="/application/:applicationId"
                component={ApplicationView}
              />
              <AuthenticatedView
                exact
                path="/application/create/:listingId"
                component={ApplicationCreate}
                allowTo={['tenant']}
              />
              <Route
                exact
                path="/stripe-connect"
                component={StripeConnectContainer}
              />
              <Route
                exact
                path="/stripe-payment"
                component={StripePaymentContainer}
              />
              <AuthenticatedView
                path="/manage"
                component={CommpanyManagemmentPage}
                allowTo={['landlord']}
              />
              <Route path="/profile" component={ProfilePage} />
              <Route exact path="/linkedin" component={LinkedInPopUp} />
              <Route path="[^/stripe]" key="404" component={ErrorPage} />
              <Redirect to={defaultPath} />
            </Switch>
          </Layout>
        </Content>
      </Wrapper>
    </>
  )
}

export default AuthenticatedApp
