import React from 'react'
import HtmlToReact, { Parser } from 'html-to-react'
import { camelCase } from 'lodash'
import { fontSizes } from 'styles'

const splitStyles = styleString =>
  styleString
    .replace(/[\w]/g, '')
    .split(';')
    .reduce((accum, item) => {
      const splits = item.split(':')
      return {
        ...accum,
        [camelCase(splits.shift())]: splits.shift()
      }
    }, {})

const isValidNode = () => true
const parser = new Parser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const processingInstructions = [
  {
    /** FOR INVALID HTML TAG PAGEBREAK */
    shouldProcessNode: node => node.name === 'pagebreak',
    processNode: () => null
  },
  {
    /** FOR FLOAT DIVS */
    shouldProcessNode: node =>
      node.name === 'div' &&
      node.attribs &&
      node.attribs.style &&
      node.attribs.style.includes('float'),
    // eslint-disable-next-line react/display-name
    processNode: (node, children, index) =>
      React.createElement(
        node.name,
        {
          key: index,
          style: {
            ...splitStyles(node.attribs.style),
            float: 'none',
            width: 'auto',
            display: 'inline-block'
          }
        },
        children
      )
  },
  {
    /** FOR FONT-SIZES */
    shouldProcessNode: node =>
      node.attribs &&
      node.attribs.style &&
      node.attribs.style.includes('font-size'),
    // eslint-disable-next-line react/display-name
    processNode: (node, children, index) =>
      React.createElement(
        node.name,
        {
          key: index,
          style: {
            ...splitStyles(node.attribs.style),
            fontSize: fontSizes.regular
          }
        },
        children
      )
  },
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode
  }
]

const htmlToReact = content =>
  parser.parseWithInstructions(content, isValidNode, processingInstructions)

export default htmlToReact
