import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors } from 'styles'
import ChatSkeleton from 'features/Chat/basic-skeleton'
import Breadcrumbs from 'components/breadcrumbs'
import MobileFooterButton from 'components/mobile-footer-button'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 20px;
  border-bottom: 1px solid ${colors.grey};

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const TenantChat = props => {
  const { t } = useTranslation()

  const ChatDashboardHeader = () => {
    const breadcrumbs = [
      {
        to: '/tenants',
        label: t('l.tenants.view.all_tenants.link', 'All Tenants')
      },
      {
        to: `/tenant/${props.match.params.listingId}`,
        label: t('l.tenants.view.dashboard.link', 'Dashboard')
      },
      { to: null, label: t('l.tenants.view.chats.link', 'Chats') }
    ]

    return (
      <HeaderWrapper>
        <BreadcrumbsWrapper>
          <Breadcrumbs items={breadcrumbs} />
        </BreadcrumbsWrapper>
      </HeaderWrapper>
    )
  }

  const ChatFooterButton = () => {
    return (
      <MobileFooterButton
        to={`/tenant/${props.match.params.listingId}`}
        breakpoint={breakpoints.ipadMiniMax}
        displayBlock
      >
        {t(
          'l.tenants.view.back_to_tenant_dashboard.button',
          'Back to Tenant Dashboard'
        )}
      </MobileFooterButton>
    )
  }

  return (
    <ChatSkeleton
      {...props}
      basePath="tenant"
      getTimelineSelector={(select, listingId) => state =>
        select.chat.tenantTimelines(state, { listingId })}
      sideBarHeader={ChatDashboardHeader}
      chatFooterButton={ChatFooterButton}
    />
  )
}

export default TenantChat
