import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import SeparatorIcon from './separator.svg'
import CompletedStepIcon from './completed-step.svg'

const OutterWrapper = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const Wrapper = styled.div`
  max-width: 720px;
  min-height: 50px;
  margin: 0 auto;
  padding: 17px 30px;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Step = styled.div`
  position: relative;
  font-size: ${fontSizes.small};
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
`

const CurrentStep = styled(Step)`
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
`

const CompleteMark = styled.img`
  position: absolute;
  left: 50%;
  margin-left: -11px;
`

const Separator = styled.div`
  width: 32px;
  background-image: url(${SeparatorIcon});
  background-repeat: no-repeat;
  background-position: center center;
`

const isLastStep = (steps, step) => steps.indexOf(step) + 1 === steps.length

const isComplete = (steps, step, index) => steps.indexOf(step) > index

const NamedWizard = ({ steps, currentStep, ...rest }) => (
  <OutterWrapper {...rest}>
    <Wrapper>
      {Object.keys(steps).reduce((accum, step, index, arr) => {
        const showSeparator = !isLastStep(arr, step)
        let stepComponent = <Step key={`key-${step}`}>{steps[step]}</Step>

        if (step === currentStep) {
          stepComponent = (
            <CurrentStep aria-current="step" key={`key-${step}`}>
              {steps[step]}
            </CurrentStep>
          )
        } else if (isComplete(arr, currentStep, index)) {
          stepComponent = (
            <Step key={`key-${step}`}>
              {steps[step]}
              <CompleteMark src={CompletedStepIcon} />
            </Step>
          )
        }

        return [
          ...accum,
          stepComponent,
          showSeparator ? <Separator key={`separator-${step}`} /> : null
        ]
      }, [])}
    </Wrapper>
  </OutterWrapper>
)

NamedWizard.defaultProps = {
  steps: [],
  currentStep: 0
}

export default NamedWizard
