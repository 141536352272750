import React, { forwardRef, createRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import { colors, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import { Textarea } from 'components/form/textarea'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${props =>
    props.height &&
    `
    height: ${props.height}px;
  `}

  transition: height 0.1s linear;
`

const StyledTextArea = styled(Textarea)`
  font-weight: ${fontWeights.regular};
  overflow: hidden;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin-top: 10px;
`

const Action = styled.p`
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: ${props => props.color || colors.regular};

  ${props =>
    props.disabled &&
    `
    cursor: unset;
    color: ${colors.mediumLightGrey};
  `}
`

const StaticDisplay = styled.div`
  padding: 15px 15px;
  border-radius: 6px;
  border: 1px solid ${colors.mediumGrey};
  height: ${props => props.height || 50}px;
  border-radius: 6px;
  background-color: ${colors.white};
  overflow: hidden;
  outline: none;
  width: ${props => props.width};
  display: flex;
  align-items: center;
`

const Text = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: start;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  letter-spacing: -0.39px;
  width: 100%;
  ${props =>
    props.isPlaceholder &&
    `
    color: ${colors.mediumGrey};
    opacity: 0.5;
  `}
  margin-right: 10px;
  margin-left: ${props => props.marginLeft || 0}px;
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  cursor: pointer;
  ${props =>
    props.alignToEnd &&
    `
    margin-left: auto;
  `}
`

const moveCursorToEnd = e => {
  const input = e.target.value
  e.target.value = ''
  e.target.value = input
}

const calculateTextAreaHeight = (textArea, textAreaWrapper, minHeight) => {
  if (!textArea || !textAreaWrapper) return minHeight
  const wrapperPadding = textAreaWrapper.clientHeight - textArea.clientHeight

  //when text is erased the scrollheight of the textarea does not decrease on it's own
  //so if several lines are erased, the scrollheight will still account for those lines
  //by setting height to 0, the erased lines will no longer be counted as part of the scroll height, and only the text content is included
  const prevHeightValue = textArea.style.height
  textArea.style.height = 0
  const newHeight = Math.max(textArea.scrollHeight + wrapperPadding, minHeight)
  textArea.style.height = prevHeightValue

  return newHeight
}

const ExpandableTextArea = forwardRef(
  (
    {
      isExpanded = false,
      expandedHeight = 100,
      contractedHeight = 50,
      name,
      onDelete = noop,
      onDone = noop,
      onClick = noop,
      editPlaceholder,
      previewPlaceholder,
      isReorderEnabled = true,
      ...rest
    },
    ref
  ) => {
    const textWrapperRef = createRef()
    const [textArea, setTextArea] = useState()
    const [field] = useField(name)
    const { t } = useTranslation()
    const [fullHeight, setFullHeight] = useState(expandedHeight)

    const setTextWrapperRef = wrapperRef => {
      textWrapperRef.current = wrapperRef

      if (typeof ref === 'function') {
        ref(wrapperRef)
      } else if (ref) {
        ref.current = wrapperRef
      }
    }

    //should use a ref to compute this but oh well
    //need in order to compute overall height for transition
    const actionBarHeight = 41

    const onInputChange = e => {
      field.onChange(e)
      if (!textArea || !textWrapperRef.current) return
      setFullHeight(
        calculateTextAreaHeight(
          textArea,
          textWrapperRef.current,
          expandedHeight
        )
      )
    }

    useEffect(() => {
      if (textArea && textWrapperRef.current)
        setFullHeight(
          calculateTextAreaHeight(
            textArea,
            textWrapperRef.current,
            expandedHeight
          )
        )
    }, [textArea, textWrapperRef, expandedHeight])

    const beginEdit = () => onClick(name)

    return (
      <Wrapper
        height={isExpanded ? actionBarHeight + fullHeight : contractedHeight}
      >
        {isExpanded ? (
          <>
            <StyledTextArea
              {...field}
              {...rest}
              autoResize
              setTextArea={setTextArea}
              placeholder={editPlaceholder}
              onChange={onInputChange}
              height={fullHeight}
              fontWeight={fontWeights.regular}
              autoFocus
              onFocus={moveCursorToEnd}
              border={`1px solid ${colors.mediumGrey}`}
              ref={setTextWrapperRef}
            />
            <ActionContainer>
              <Action color={colors.red} onClick={() => onDelete(name)}>
                {t('b.expanding_text.delete.button', 'Delete')}
              </Action>
              <Action
                color={colors.link}
                onClick={field.value ? () => onDone(name) : null}
                disabled={!field.value}
              >
                {t('b.expanding_text.save.button', 'Save')}
              </Action>
            </ActionContainer>
          </>
        ) : (
          <StaticDisplay {...rest} height={contractedHeight}>
            {isReorderEnabled && (
              <StyledIcon id="reorder" width={21} height={21} />
            )}
            <Text
              isPlaceholder={!field.value}
              marginLeft={isReorderEnabled ? 15 : 0}
              onClick={beginEdit}
            >
              {field.value || previewPlaceholder}
            </Text>
            <StyledIcon
              id="edit"
              width={21}
              height={21}
              alignToEnd
              onClick={beginEdit}
            />
          </StaticDisplay>
        )}
      </Wrapper>
    )
  }
)

ExpandableTextArea.displayName = 'ExpandableTextArea'

Textarea.defaultProps = {
  value: ''
}

export default ExpandableTextArea
