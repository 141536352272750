import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import SuiteInfo from 'features/Listing/suite-info'
import TenantPaymentHistory from 'features/Payments/tenant-payment-history'
import MakePayment from './make-payment'

const Payment = ({
  match: {
    params: { listingId, paymentId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loadPaymentData = dispatch.payment.getPayment
  const paymentData = useSelector(state =>
    select.payment.payment(state, { paymentId, listingId })
  )

  useEffect(() => {
    if (paymentId) {
      loadPaymentData({ paymentId })
    }
  }, [loadPaymentData, paymentId])

  if (!paymentData) return null

  return (
    <>
      <Helmet>
        <title>{t('b.payments.view.payments.title', 'Payments')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SuiteInfo listingId={listingId} />
      {paymentData.state_machine === 'pending' ? (
        <MakePayment paymentData={paymentData} history={history} />
      ) : (
        <TenantPaymentHistory paymentData={paymentData} history={history} />
      )}
    </>
  )
}

export default Payment
