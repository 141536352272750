import styled from 'styled-components'
import { breakpoints, colors, fontSizes } from 'styles'
import { H1 } from 'components/headings'
import Taskbar from 'components/taskbar'

export const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 425px;
  max-width: 95%;
  margin: 0 100px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
  text-align: center;
`

export const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 425px;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

export const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 10px;
`

export const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`
