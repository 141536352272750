import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import noop from 'lodash/noop'

const Wrapper = styled.div`
  display: inline-flex;
`

const Toggler = styled.div`
  height: 33px;
  width: 55px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;

  &::before,
  &::after {
    left: 0;
    position: absolute !important;
  }

  &::before {
    content: '';
    height: inherit;
    width: inherit;
    border-radius: 16px;
    will-change: background;
    transition: all 0.3s 0.2s;
    background: ${props =>
      props.enabled ? colors.secondary : colors.lightGrey}!important;
    border: 2px solid ${props => (props.enabled ? 'transparent' : '#d7d7d7')};
    box-sizing: border-box;
  }
  &::after {
    content: '';
    top: ${props => (props.enabled ? '2px' : '1px')};
    left: -1px;
    height: 29px;
    width: 29px;
    text-align: center;
    color: ${props => (props.enabled ? colors.secondary : 'transparent')};
    border-radius: 50%;
    background: #ffffff !important;
    will-change: transform;
    transition: all 0.3s;
    transform: translateX(${props => (props.enabled ? 25 : 1)}px);
    border: ${props => (props.enabled ? 'none' : '1px solid #dadada')};
  }
`

const ToggleSwitch = (
  { enabled = false, onClick = noop, onChange = noop, ...rest },
  ref
) => {
  const [checked, setChecked] = useState(enabled)
  useEffect(() => {
    setChecked(enabled)
  }, [enabled])

  const toggleSwitch = evt => {
    evt.preventDefault()
    setChecked(!checked)
    onClick(!checked)
    onChange(!checked)
  }

  return (
    <Wrapper ref={ref} onClick={toggleSwitch}>
      <Toggler
        {...rest}
        role="checkbox"
        aria-checked={checked}
        enabled={checked}
        data-cy={checked ? 'on-switch' : 'off-switch'}
      />
    </Wrapper>
  )
}

export default React.memo(forwardRef(ToggleSwitch))
