import React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'styles'
import dogSleeping from './dog_sleeping.png'

type Props = {
  location?: string
  alt: string
}

const Image = styled.img`
  width: 450px;
  height: auto;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 450px;
    width: 100%;
  }
`

const EmptyImage: React.FC<Props> = ({
  location = dogSleeping,
  alt = 'Nothing here',
  children,
  ...rest
}) => {
  return (
    <Image src={location} alt={alt} {...rest}>
      {children}
    </Image>
  )
}

export default EmptyImage
