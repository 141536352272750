import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, fontWeights } from 'styles'
import Icon from 'components/icon'

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
`

const Title = styled.h5`
  color: ${colors.regular};
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.34px;
  line-height: 21px;
  margin-bottom: 5px;
`

const Text = styled.p`
  color: ${colors.regular};
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0;

  strong {
    font-weight: ${fontWeights.medium};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 12px;
  }
`

const StyledIcon = styled(Icon)`
  height: 32px;
  width: 32px;
  align-self: flex-end;
  margin-bottom: 2px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 30px;
    width: 30px;
  }
`

const CloseButton = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: -16px;
  right: -16px;
  height: 48px;
  width: 48px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
  }
`

const Notification = ({ title, iconId, autoClose, closeToast, children }) => {
  if (autoClose) {
    setTimeout(closeToast, autoClose)
  }

  return (
    <>
      <CloseButton id="close_button_dark" onClick={closeToast} />
      <InnerWrapper>
        {title && <Title>{title}</Title>}
        <Text>{children}</Text>
      </InnerWrapper>
      {iconId && <StyledIcon id={iconId} />}
    </>
  )
}

export default Notification
