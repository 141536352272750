import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { select } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { breakpoints, fontSizes, colors, fontWeights, structure } from 'styles'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import { toUnit } from 'utils/styles'
import useMediaQuery from 'hooks/use-media-query'
import DashboardHeader from 'features/Listing/dashboard-header'
import { Dropdown, DropdownItem } from 'components/dropdown'
import { leftCloud, rightCloud } from 'components/clouds'

import { show as showSortModal } from './sort-modal'
import List from './list'

import backgroundBottom from './images/background-bottom.png'
import backgroundTopMobile from './images/background-top-mobile.png'

const Wrapper = styled.div`
  padding: 30px 50px 180px 100px;
  min-height: calc(100vh - ${toUnit(structure.header.height)});
  background: ${colors.white};

  background-image: url(${leftCloud}), url(${rightCloud}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -65px 240px, 95% 0px, 100% 100%;
  background-size: 240px, 230px, 540px;

  @media screen and (max-width: 1270px) {
    padding: 30px 40px 180px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 20px 20px 180px;

    background-image: url(${leftCloud}), url(${backgroundTopMobile}),
      url(${backgroundBottom});
    background-position: -60px 500px, 100% 0px, 100% 100%;
    background-size: 240px, 77px, 360px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 10px 20px 170px;
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
    background-position: -60px 500px, 100% 60px, 100% 100%;
  }
`

const ContentWrapper = styled.div`
  max-width: 1035px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    align-items: center;
  }
`

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionHeading = styled.h1`
  font-size: 25px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 15px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0;
  }
`

const SectionSubheading = styled.h2`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  padding-bottom: 10px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const SortButton = styled.button`
  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background: ${colors.white};
  padding: 10px 20px;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: center;
  margin: 0;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`

const SortDropdown = styled.div`
  display: flex;
  align-items: center;

  > p {
    color: ${colors.mediumGrey};
    font-size: ${fontSizes.regular};
    letter-spacing: -0.3px;
    line-height: 22px;
    margin: 0 10px 0 0;
  }
`

const ShowMoreButton = styled.button`
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  background: ${colors.white};
  border: 2px solid ${colors.secondary};
  padding: 10px 20px;
  margin: 0 auto;
  width: 325px;
  height: 48px;
  cursor: pointer;

  :hover,
  :disabled {
    opacity: 0.8;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    width: 100%;
  }
`

const AllLoadedText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.darkGrey};
  font-weight: ${fontWeights.medium};
  margin: 0 auto 50px;
`

const EmptyText = styled(AllLoadedText)`
  margin: 100px auto 0;
`

const sortValue = (by, type) => ({
  sort_by: by,
  order: type
})

const ListingSuggestedTenants = ({
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [sort, setSort] = useState(sortValue('verified', 1))
  const [page, setPage] = useState(1)
  const [showMoreButton, setShowMoreButton] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  useEffect(() => {
    if (!page || page === 1) {
      dispatch.listing.getSuggestedTenants({
        listingId,
        queries: {
          page: page,
          sort: sort.sort_by,
          order: sort.order
        },
        reset: true
      })
    }
  }, [dispatch.listing, listingId, page, sort])

  useEffect(() => {
    dispatch.listing.load(listingId)
  }, [dispatch.listing, listingId])

  const tenants = useSelector(state =>
    select.listing.suggestedTenants(state, { listingId })
  )

  const SORT_VALUES = {
    verified: {
      1: t(
        'l.listings.view.suggested_tenants_sort_id_verified.text',
        'ID Verified'
      )
    },
    salary: {
      2: t(
        'l.listings.view.suggested_tenants_sort_salary_highest.text',
        'Salary (highest)'
      )
    },
    budget: {
      2: t(
        'l.listings.view.suggested_tenants_sort_budget_highest.text',
        'Budget (highest)'
      ),
      1: t(
        'l.listings.view.suggested_tenants_sort_budget_lowest.text',
        'Budget (lowest)'
      )
    },
    completion: {
      1: t(
        'l.listings.view.suggested_tenants_sort_profile_completion.text',
        'Profile Completion'
      )
    }
  }
  const formatSort = ({ sort_by, order }) => SORT_VALUES[sort_by][order]

  const handleSortChange = e => {
    setSort(e)
    setPage(1)
    setShowMoreButton(true)
  }

  const handleShowMore = async () => {
    setLoadingMore(true)
    const response = await dispatch.listing.getSuggestedTenants({
      listingId,
      queries: {
        page: page + 1,
        sort: sort.sort_by,
        order: sort.order
      },
      reset: false
    })
    if (
      response?.body?.listing_matches === null ||
      response?.body?.listing_matches.length < 9
    ) {
      setShowMoreButton(false)
    } else if (response?.response?.ok) {
      setPage(page + 1)
    }
    setLoadingMore(false)
  }

  return (
    <>
      <Helmet>
        <title>
          {t('l.listing.view.suggested_tenants.title', 'Suggested Tenants')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <DashboardHeader
        listingId={listingId}
        currentPage={t(
          'l.listing.view.suggested_tenants.title',
          'Suggested Tenants'
        )}
        height={55}
      />
      <Wrapper>
        <ContentWrapper>
          <Header>
            <HeaderLeft>
              <SectionHeading>
                {t(
                  'l.listing.view.suggested_tenants.title',
                  'Suggested Tenants'
                )}
              </SectionHeading>
              <SectionSubheading>
                {t(
                  'l.listings.view.suggested_tenants_looking_for_rent.text',
                  'Renters looking for listings in your area'
                )}
              </SectionSubheading>
            </HeaderLeft>
            {displayMobile ? (
              <SortButton
                onClick={() =>
                  showSortModal({
                    currentValue: sort,
                    handleSortChange: handleSortChange
                  })
                }
              >
                {t('b.listings.view.sort.button', 'Sort')}
              </SortButton>
            ) : (
              <SortDropdown>
                <p>{t('b.listings.view.sort_by.label', 'Sort by')}</p>
                <Dropdown
                  value={sort}
                  display={formatSort(sort)}
                  onChange={handleSortChange}
                  minWidth={185}
                >
                  <DropdownItem value={sortValue('verified', 1)}>
                    {t(
                      'l.listings.view.suggested_tenants_sort_id_verified.text',
                      'ID Verified'
                    )}
                  </DropdownItem>
                  <DropdownItem value={sortValue('salary', 2)}>
                    {t(
                      'l.listings.view.suggested_tenants_sort_salary_highest.text',
                      'Salary (highest)'
                    )}
                  </DropdownItem>
                  <DropdownItem value={sortValue('budget', 2)}>
                    {t(
                      'l.listings.view.suggested_tenants_sort_budget_highest.text',
                      'Budget (highest)'
                    )}
                  </DropdownItem>
                  <DropdownItem value={sortValue('budget', 1)}>
                    {t(
                      'l.listings.view.suggested_tenants_sort_budget_lowest.text',
                      'Budget (lowest)'
                    )}
                  </DropdownItem>
                  <DropdownItem value={sortValue('completion', 1)}>
                    {t(
                      'l.listings.view.suggested_tenants_sort_profile_completion.text',
                      'Profile Completion'
                    )}
                  </DropdownItem>
                </Dropdown>
              </SortDropdown>
            )}
          </Header>
          {isEmpty(tenants) || !tenants ? (
            <EmptyText>
              {t(
                'b.listing.view.no_suggested_tenants.text',
                'No suggested tenants available'
              )}
            </EmptyText>
          ) : (
            <>
              <List listingId={listingId} tenants={tenants} />
              {showMoreButton ? (
                <ShowMoreButton
                  theme="primary"
                  onClick={handleShowMore}
                  disabled={loadingMore}
                >
                  {loadingMore
                    ? t(
                        'l.listings.view.suggested_tenants_loading.button',
                        'Loading...'
                      )
                    : t(
                        'l.listings.view.suggested_tenants_show_more.button',
                        'Show more'
                      )}
                </ShowMoreButton>
              ) : (
                <AllLoadedText>
                  {t(
                    'l.listings.view.suggested_tenants_all_loaded.text',
                    'All suggested tenants have been loaded'
                  )}
                </AllLoadedText>
              )}
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </>
  )
}

export default ListingSuggestedTenants
