import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import Landing from 'pages/Pages/landing'
import Footer from 'components/footer'

const UnauthenticatedApp = ({ session }) => {
  const history = useHistory()

  useEffect(() => {
    if (session) {
      return history.push('/auth/register')
    }
  }, [session, history])

  return (
    <>
      <Route path="/" component={Landing} />
      <Footer minimal />
    </>
  )
}

export default UnauthenticatedApp
