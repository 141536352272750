import React from 'react'
import { useTranslation } from 'react-i18next'

const ListingTypeText = ({ unitType, unitScope }) => {
  const { t } = useTranslation()
  const listingTypes = {
    condo: {
      entire: t('b.listing.view.entire_apartment.text', 'Entire Apartment'),
      partial: t(
        'b.listing.view.partial_apartment.text',
        'Partial Apartment'
      ),
      single: t(
        'b.listing.view.room_in_apartment.text',
        'Private Room in Apartment'
      )
    },
    house: {
      entire: t('b.listing.view.entire_house.text', 'Entire House'),
      partial: t('b.listing.view.partial_house.text', 'Partial House'),
      single: t('b.listing.view.room_in_house.text', 'Private Room in House')
    },
    townhouse: {
      entire: t('b.listing.view.entire_townhouse.text', 'Entire Townhouse'),
      partial: t(
        'b.listing.view.partial_townhouse.text',
        'Partial Townhouse'
      ),
      single: t(
        'b.listing.view.room_in_townhouse.text',
        'Private Room in Townhouse'
      )
    }
  }
  return <>{listingTypes[unitType?.toLowerCase()]?.[unitScope?.toLowerCase()]}</>
}

export default ListingTypeText
