import { flags } from 'flags'
import config from 'config'

const useSharp = flags.features.sharp

const getSize = (width, height) => {
  if (!height && !width) return ''
  return `/${width}x${height}`
}

const getValues = (...values) => values.map(v => (v ? `/${v}` : '')).join('')

const getSharpUrl = ({ image, width, height, fit, type, quality }) => {
  return btoa(
    JSON.stringify({
      bucket: 'mbapi-dev',
      key: image,
      edits: {
        options: {
          width,
          height,
          fit,
          quality
        },
        smartCrop: type === 'smart'
      }
    })
  )
}

export const getImageUrl = (
  src,
  { width = '', height = '', fit = null, type = null, quality = 70 } = {}
) => {
  if (!src) return null
  const match = src.match(/static_file.+$/)
  if (!match) return null
  const image = match[0].replace(/^\//, '')
  const qualityStr = quality !== null ? `filters:quality(${quality})/` : ''
  if (useSharp) return getSharpUrl({ image, width, height, fit, type, quality })
  return `${config.IMAGES_URL}${getSize(width, height)}${getValues(
    fit,
    type
  )}/${qualityStr}${image}`
}
