import React from 'react'
import styled from 'styled-components'
import { Anchor } from 'components/anchor'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { colors, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import useRole from 'hooks/use-role'
import metadata from './metadata'
import ShowingIcon from '../chat-icons/viewing-icon'
import ShowingDelayedIcon from '../chat-icons/viewing-delayed-icon'
import NewLeaseIcon from '../chat-icons/new-lease-icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isThem ? 'row' : 'row-reverse')};
  margin: 0px;
  width: 260px;
  font-size: ${fontSizes.small};
  ${props =>
    props.clickable &&
    `
    cursor: pointer;
  `}
`

const EventContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color || colors.secondary};
  width: 88px;
  height: 94px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.white};
  width: calc(100% - 88px);
  padding: 10px 15px;
`

const ChatText = styled.p`
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 18px;
  background-color: ${colors.white};
  color: ${colors.regular};
`

const ChatEventLink = styled(Anchor)`
  margin: 0 0 5px 0;
  font-size: ${fontSizes.small};
  line-height: 20px;
  background-color: ${colors.white};
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
`

const ViewDetailsLink = styled(ChatEventLink)`
  margin: 0;
  text-decoration: none;
  font-size: ${fontSizes.small};
  color: ${colors.link};
  font-weight: ${fontWeights.regular};
`

const ViewDetailsText = styled.div`
  text-decoration: none;
  font-size: ${fontSizes.small};
  color: ${colors.link};
  font-weight: ${fontWeights.regular};
`

const IconImage = styled.img`
  display: flex;
  justify-content: center;
`

const Default = ({ event, isThem, history, className }) => {
  const { t } = useTranslation()
  const userRole = useRole()
  const data = metadata(isThem, event, t, userRole, history)
  const eventType = event?.event_type
  const MessageText = data?.to ? ChatEventLink : ChatText
  const DateIcon =
    eventType === 'chat_contract_extension_started' ? NewLeaseIcon : ShowingIcon
  const linkText =
    data?.link || t('b.chat.view.view_details.text', 'View details')

  const renderEventCard = () => {
    if (data?.icon) {
      return (
        <EventContainer color={data?.background}>
          <IconImage src={data?.icon} />
        </EventContainer>
      )
    } else {
      return eventType === 'chat_appointment_delayed' ? (
        <ShowingDelayedIcon info={event?.event_info} color={data?.background} />
      ) : (
        <EventContainer color={data?.background}>
          <DateIcon info={event?.event_info} color={data?.background} />
        </EventContainer>
      )
    }
  }

  return (
    <Wrapper
      isThem={isThem}
      clickable={!!data?.to || !!data?.onClick}
      onClick={() => (data?.onClick ? data?.onClick(event) : noop())}
      className={className}
    >
      {renderEventCard()}
      <TitleContainer>
        <MessageText to={data?.to}>
          {data?.title || event?.event_info?.message}
        </MessageText>
        {!!data?.to && (
          <ViewDetailsLink to={data?.to}>{linkText}</ViewDetailsLink>
        )}
        {!!data?.onClick && <ViewDetailsText>{linkText}</ViewDetailsText>}
      </TitleContainer>
    </Wrapper>
  )
}

export default withRouter(React.memo(Default))
