import React from 'react'
import { connect } from 'react-redux'
import { select } from 'store'
import htmlToReact from 'utils/html-to-react'

const mapState = (state, ownProps) => {
  const agreement = select.agreement.agreements(state, ownProps) || {}
  return { description: agreement.description }
}

const Description = ({ description }) =>
  description && <div>{htmlToReact(description)}</div>

Description.defaultProps = {
  description: null
}

const AgreementDescription = connect(mapState)(Description)

export default AgreementDescription
