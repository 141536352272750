import React from 'react'
import styled from 'styled-components'
import { fontSizes, fontWeights, colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'

const Wrapper = styled.div`
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Text = styled.p`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 0;
`

const CloseButton = styled(Icon)`
  path {
    fill: ${colors.white};
  }
`

const SuccessNotification = styled(Wrapper)`
  background-color: ${colors.secondary};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.secondaryLightBlue};
  }
`

const ErrorNotification = styled(Wrapper)`
  background-color: ${colors.red};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.lightRed};
  }
`

const TYPE_MAP = {
  success: SuccessNotification,
  error: ErrorNotification
}

const NotificationModal = ({ message, type = 'success' }) => {
  const { close } = useModalize()

  const NotificationWrapper = TYPE_MAP[type]

  return (
    <NotificationWrapper onClick={close}>
      <Text>{message}</Text>
      <CloseButton aria-label="Close" id="close" width={24} height={24} />
    </NotificationWrapper>
  )
}

export const show = createModal(React.memo(NotificationModal), {
  overlay: false
})
