import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import config from 'config'

const client = new ApolloClient({
  link: createHttpLink({
    uri: config.GRAPHQL_SERVER,
  }),
  cache: new InMemoryCache().restore(
    typeof window === 'undefined' ? {} : window.__APOLLO_STATE__
  ),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

export default client
