import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import AddendumView from './addendum-view'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
`

const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 20px 10px;
  border-bottom: 1px solid ${colors.mediumLightGrey};

  svg {
    cursor: pointer;
    margin: 0;

    :hover {
      opacity: 0.5;
    }
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 25px;
`

const AddendumModalView = ({
  addendum,
  onChange,
  variables,
  match,
  signed = false
}) => {
  const { close } = useModalize()
  const [currentAddendum, setCurrentAddendum] = useState(addendum)

  const handleChange = item => {
    const updatedAddendum = {
      ...item,
      complete: !item.complete
    }
    setCurrentAddendum(updatedAddendum)
    onChange(item)
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <AddendumView
          match={match}
          addendum={currentAddendum}
          onChange={onChange && (item => handleChange(item))}
          variables={variables}
          signed={signed}
        />
      </ScrollWrapper>
    </Wrapper>
  )
}

AddendumModalView.defaultProps = {
  addendum: {},
  onChange: null,
  variables: {}
}

export const showAddendumModalView = createModal(AddendumModalView, {
  width: '720px'
})
