import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import valid from 'card-validator'
import { breakpoints } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import { Row, Group, FieldInput, MaskedInput } from 'components/form'
import { Error } from '../elements'

import iconAmericanExpress from './american-express.png'
import iconVisa from './visa.png'
import iconMasterCard from './mastercard.png'
import iconUnionPay from './unionpay.png'

const CardImage = styled.img`
  position: absolute;
  right: 8px;
  top: 9px;
  height: 32px;
`

const CardInputWrapper = styled.div`
  position: relative;
`

const cardTypes = {
  visa: {
    flag: iconVisa
  },
  mastercard: {
    flag: iconMasterCard
  },
  'american-express': {
    flag: iconAmericanExpress
  },
  unionpay: {
    flag: iconUnionPay
  }
}

const validNumber = cardNumber =>
  valid.number(cardNumber, {
    luhnValidateUnionPay: true
  })

const validExpirationDate = expirationDate =>
  valid.expirationDate(expirationDate)

const validCvv = (securityCode, maxLength = 3) =>
  valid.cvv(securityCode, maxLength)

const generateCardMask = ({ gaps, lengths }) => {
  let finalMask = ''

  for (let i = 0; i < lengths[lengths.length - 1]; i++) {
    finalMask += '#'
    if (gaps.includes(i + 1)) {
      finalMask += ' '
    }
  }

  return finalMask
}

const CreditCardInput = ({
  values,
  setCardInfoValid,
  setCardMethodValid,
  cardMethods
}) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  const { acceptedCards, error: errorCard } = cardMethods

  const cardInfo = {
    cardNumber: values.card_number,
    expirationDate: values.expiration_date,
    securityCode: values.security_code
  }

  const [cardType, setCardType] = useState('')
  const [cardMask, setCardMask] = useState('')
  const [isValidMethod, setIsValidMethod] = useState(false)
  const [expValid, setExpValid] = useState(false)
  const [cardValid, setCardValid] = useState(false)
  const [codeValid, setCodeValid] = useState(false)
  const [minCardLength, setMinCardLength] = useState(undefined)
  const [showCardError, setShowCardError] = useState(false)

  // Check to Display Error Messages
  const showMethodError =
    cardInfo.cardNumber.length > 3 &&
    acceptedCards !== '' &&
    cardType !== '' &&
    !isValidMethod

  const checkCardValidityOnBlur = () => {
    setShowCardError(!showMethodError && !cardValid)
  }

  useEffect(() => {
    if (
      !showMethodError &&
      cardInfo.cardNumber.length >= minCardLength &&
      !cardValid
    ) {
      return setShowCardError(true)
    }
    return setShowCardError(false)
  }, [
    cardInfo.cardNumber,
    cardValid,
    minCardLength,
    setShowCardError,
    showMethodError
  ])

  // Card Number Validation
  useEffect(() => {
    const { isValid: isCardValid, card } = validNumber(cardInfo.cardNumber)
    const { isValid: isExpValid } = validExpirationDate(cardInfo.expirationDate)
    const { isValid: isCodeValid } = validCvv(
      cardInfo.securityCode,
      card?.code.size
    )

    setMinCardLength(card?.lengths[0])
    setCardValid(isCardValid)
    setExpValid(isExpValid)
    setCodeValid(isCodeValid)
    setCardInfoValid(isCardValid && isExpValid && isCodeValid)
  }, [
    setCardInfoValid,
    cardInfo.cardNumber,
    cardInfo.expirationDate,
    cardInfo.securityCode
  ])

  // Card Method Validation
  useEffect(() => {
    const { card = {} } = validNumber(cardInfo.cardNumber)
    const isIncluded = acceptedCards.includes(card?.type)
    setIsValidMethod(isIncluded)
    setCardMethodValid(isIncluded)
  }, [cardInfo.cardNumber, acceptedCards, setCardMethodValid])

  // Save Card Type
  useEffect(() => {
    if (!cardInfo.cardNumber) {
      setCardType('')
      setCardMask('')
      return
    }
    const { card } = validNumber(cardInfo.cardNumber)
    if (card) {
      setCardMask(generateCardMask(card))
    }
    setCardType(card?.type)
  }, [cardType, cardInfo.cardNumber])

  // Set Card Image
  const { flag: flagIcon = null } = cardTypes[cardType] || {}

  return (
    <>
      <Row>
        <Group col={colSize}>
          <CardInputWrapper>
            <MaskedInput
              inputmode="numeric"
              htmlFor="creditcard"
              id="card_number"
              name="card_number"
              format={cardMask}
              onBlur={checkCardValidityOnBlur}
              placeholder={
                t(
                  'r.payment.edit.make_payment_card_number.label',
                  'Card number'
                ) + '*:'
              }
            />
            <CardImage src={flagIcon} alt="" />
          </CardInputWrapper>
          {showMethodError && <Error role="alert">{errorCard}</Error>}
          {showCardError && (
            <Error role="alert">
              {t(
                'r.payment.edit.invalid_card_number.error',
                'Invalid card number'
              )}
            </Error>
          )}
        </Group>
        <Group col={colSize}>
          <FieldInput
            id="expiration_date"
            name="expiration_date"
            maxLength={4}
            placeholder={
              t(
                'r.payment.edit.make_payment_expiration_date.label',
                'Expiration date (MMYY)'
              ) + '*:'
            }
            inputmode="numeric"
          />
          {cardInfo.expirationDate.length === 4 && !expValid && (
            <Error role="alert">
              {t(
                'r.payment.edit.invalid_expiration_date.error',
                'Invalid expiration date'
              )}
            </Error>
          )}
        </Group>
      </Row>
      <Row>
        <Group col={colSize}>
          <FieldInput
            id="security_code"
            inputmode="numeric"
            name="security_code"
            maxLength={4}
            placeholder={
              t(
                'r.payment.edit.make_payment_security_code.label',
                'Security code'
              ) + '*:'
            }
          />
          {cardInfo.securityCode.length > 2 && !codeValid && (
            <Error role="alert">
              {t(
                'r.payment.edit.invalid_security_code.error',
                'Invalid security code'
              )}
            </Error>
          )}
        </Group>
      </Row>
    </>
  )
}

export default CreditCardInput
