import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import { FieldInput, FieldTextarea } from 'components/form'

const CustomAddendumForm = ({ addendum, values, setFieldValue }) => {
  const [t] = useTranslation()

  return (
    <Form>
      <FieldInput
        name="title"
        value={values?.title}
        onChange={e => setFieldValue('title', e.target.value)}
        placeholder={t(
          'l.contract.edit.custom_addendum_modal_title_field.placeholder',
          'Title'
        )}
      />
      <FieldTextarea
        name="description"
        value={values?.description}
        height={330}
        maxLength={540}
        top={30}
        onChange={e => setFieldValue('description', e.target.value)}
        placeholder={t(
          'l.contract.edit.custom_addendum_modal_description.placeholder',
          'Describe your additional term'
        )}
      />
    </Form>
  )
}

export default CustomAddendumForm
