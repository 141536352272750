import { companyUsersSelector } from './selectors'

const user = {
  state: null,
  selectors: {
    companyUsers() {
      return companyUsersSelector
    }
  }
}

export default user
