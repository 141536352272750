import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { getImageUrl } from 'utils/image'
import ProfileRequired from 'components/profile-required'
import useMediaQuery from 'hooks/use-media-query'
import { breakpoints } from 'styles'
import Avatar from './avatar'
import AvatarPicker from './avatar-picker'
import defaultAvatar from './default-avatar.svg'

const AvatarRequiredWrapper = styled.div`
  height: ${({ height }) => height}px;
`

const AvatarWrapper = styled.div`
  margin-left: auto;
`

const Flex = styled.div`
  display: flex;
`

const AvatarWithPicker = ({ editable, data }) => {
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const AvatarComponent = editable ? AvatarPicker : Avatar
  const getImage = url => (url ? getImageUrl(url) : defaultAvatar)
  const avatarLocation = data.avatar?.aws_s3_key
  const handleUploadAvatar = file => {
    dispatch.profile.uploadAvatar({
      file,
      fileId: data.avatar?.id
    })
  }
  return (
    <AvatarWrapper>
      <Flex>
        <AvatarComponent
          right={0}
          height={displayMobile ? 80 : 120}
          width={displayMobile ? 80 : 120}
          location={getImage(avatarLocation)}
          onSelect={handleUploadAvatar}
          alt={data.first_name}
          showIcon={!avatarLocation}
        />
        { editable && (
          <AvatarRequiredWrapper height={displayMobile ? 80 : 120}>
            <ProfileRequired fields={['avatar']} area="basic_info" left="0" />
          </AvatarRequiredWrapper>
        )}
      </Flex>
    </AvatarWrapper>
  )
}

export default AvatarWithPicker
