import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { colors, fontWeights, breakpoints } from 'styles'
import AreaTitle from 'components/area-title'
import Page from 'components/page'
import Panel from 'components/panel'
import { showUserPicker } from 'components/user-picker'
import { Error } from 'components/form'
import ListingUsersContainer from 'features/Listing/landlord-container'
import { showTenantModalOptions } from 'components/tenant/tenant-modals'
import { values, map, prop, isEmpty, pipe, concat } from 'ramda'

const Wrapper = styled(Page)`
  margin-top: ${({ top }) => top}px;
  padding-bottom: ${({ bottom }) => bottom}px;
`

const AddTenantButton = styled.button`
  display: flex;
  height: 28px;
  width: 120px;
  border: 1px solid ${colors.grey};
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 20px;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.7;
  }
`

const StyledPanel = styled(Panel)`
  position: relative;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const EmptyMessage = styled.p`
  font-size: 12px;
  margin: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const StyledUsersContainer = styled(ListingUsersContainer)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const ContractParties = ({ data, listingId, setFieldValue }) => {
  const { t } = useTranslation()

  const allTenants = useSelector(select.listing.applicants)

  const allLandlords = useSelector(state =>
    select.listing.landlords(state, {
      listingId
    })
  )

  const handleDeleteLandlord = landlord => {
    setFieldValue(
      'contract_landlords',
      data.contract_landlords.filter(l => l.id !== landlord.id)
    )
  }

  const handleDeleteTenant = tenant => {
    const signing_ids = pipe(
      map(prop('id')),
      values
    )(data.contract_signing_tenants)

    if (signing_ids.includes(tenant.id)) {
      setFieldValue(
        'contract_signing_tenants',
        data.contract_signing_tenants.filter(obj => obj.id !== tenant.id)
      )
    } else {
      setFieldValue(
        'contract_extra_users',
        data.contract_extra_users.filter(obj => obj.id !== tenant.id)
      )
    }

    if (!tenant.signature_required) {
      setFieldValue(
        'contract_non_signing_tenants',
        data.contract_non_signing_tenants.filter(obj => obj.id !== tenant.id)
      )
    }
  }

  const showLandlordModal = () => {
    showUserPicker({
      title: t(
        'l.listing.contract.parties.select_landlords.title',
        'Select Landlords'
      ),
      allUsers: allLandlords,
      activeUsers: data.contract_landlords,
      setActiveUsers: handleSelectedLandlords
    })
  }

  const handleSelectedLandlords = landlords => {
    setFieldValue('contract_landlords', landlords)
  }

  const renderLandlords = () =>
    data?.contract_landlords?.length > 0 ? (
      data.contract_landlords.map(landlord => (
        <StyledUsersContainer
          key={landlord.id}
          user={landlord}
          showPrimary={false}
          handleDelete={() => handleDeleteLandlord(landlord)}
        />
      ))
    ) : (
      <StyledPanel hSpace={30} vSpace={30}>
        <EmptyMessage>
          {t(
            'l.contract.create.signing_landlordss_empty.text',
            'You have not added any landlords'
          )}
        </EmptyMessage>
      </StyledPanel>
    )

  const renderTenants = tenants => {
    return tenants?.length > 0 ? (
      tenants.map(
        (tenant, index) =>
          !isEmpty(tenant) && (
            <StyledUsersContainer
              key={`${tenant?.first_name} - index`}
              user={tenant}
              showPrimary={false}
              handleDelete={() => handleDeleteTenant(tenant)}
            />
          )
      )
    ) : (
      <StyledPanel hSpace={30} vSpace={30}>
        <EmptyMessage>
          {t(
            'l.contract.create.signing_tenants_empty.text',
            'You have not added any tenants'
          )}
        </EmptyMessage>
      </StyledPanel>
    )
  }

  return (
    <Wrapper bottom="210">
      <AreaTitle bottom="20">
        {t('l.contract.create.signing_tenants.subtitle', 'Signing Tenant')}
      </AreaTitle>
      <ButtonContainer>
        <AddTenantButton
          type="button"
          onClick={() =>
            showTenantModalOptions({
              listingId: listingId,
              allTenants: allTenants,
              data,
              disabledUsers: data.contract_non_signing_tenants,
              onAddNewUser: tenants =>
                setFieldValue('contract_signing_tenants', tenants)
            })
          }
        >
          {t('l.contract.create.add_tenant.button', '+ Add a tenant')}
        </AddTenantButton>
      </ButtonContainer>
      {renderTenants(data.contract_signing_tenants)}
      <Error name={'contract_signing_tenants'} />
      <AreaTitle bottom="20">
        {t(
          'l.contract.create.non_signing_tenants.subtitle',
          'Non-Signing Tenant (optional)'
        )}
      </AreaTitle>
      <ButtonContainer>
        <AddTenantButton
          type="button"
          onClick={() =>
            showTenantModalOptions({
              listingId: listingId,
              allTenants: allTenants,
              data,
              isExtraUser: true,
              onSelectApplicant: tenants =>
                setFieldValue('contract_non_signing_tenants', tenants),
              onAddNewUser: tenants =>
                setFieldValue('contract_extra_users', tenants)
            })
          }
        >
          {t('l.contract.create.add_tenant.button', '+ Add a tenant')}
        </AddTenantButton>
      </ButtonContainer>
      {renderTenants(
        concat(data.contract_extra_users, data.contract_non_signing_tenants)
      )}
      <AreaTitle bottom="20">
        {t('l.contract.create.the_landlords.subtitle', 'The Landlord / Agent')}
      </AreaTitle>
      <ButtonContainer>
        <AddTenantButton type="button" onClick={showLandlordModal}>
          {t('l.contract.create.add_landlord.button', '+ Add a landlord')}
        </AddTenantButton>
      </ButtonContainer>
      {renderLandlords()}
      <Error name={'contract_landlords'} />
    </Wrapper>
  )
}

export default ContractParties
