import React from 'react'
import Dock from 'react-dock'

const Modal = ({ children, zIndex = 9000, ...rest }) => (
  <Dock position="bottom" zIndex={zIndex} size={1} {...rest}>
    {children}
  </Dock>
)

Modal.defaultProps = {
  children: null
}

export default Modal
