import { set, pipe, lensPath, mergeLeft, path, dissocPath } from 'ramda'
import selectorBuilder from 'models/selector-builder'
import { batch } from 'react-redux'
import round from 'lodash/round'
import { headObj } from 'utils'
import {
  getConnectUrl,
  getListingPayments,
  getPayment,
  cancelPaymentRequest,
  createPaymentRequest,
  connectStripe,
  replyPaymentRequest
} from './ports'
import { groupedPaymentsSelector, paymentWithUsersSelector } from './selectors'

const payment = {
  state: {},
  reducers: {
    patch: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), mergeLeft(payload))(state), state),
    remove: (state, at) => dissocPath(at, state)
  },
  effects: dispatch => ({
    async getPayment({ paymentId }) {
      try {
        const { response, body } = await getPayment({ paymentId })
        if (!response.ok) {
          throw Error(response.statusText)
        }

        batch(() => {
          dispatch.payment.patch(
            [body.payment_requests[paymentId].listing_id, 'payment_requests'],
            body.payment_requests
          )
          dispatch.payment.patch(
            [body.payment_requests[paymentId].listing_id, 'users'],
            body.users
          )
        })

        return { body }
      } catch (error) {
        return { error }
      }
    },
    async getListingPayments({ listingId }) {
      try {
        const { response, body } = await getListingPayments({ listingId })
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch.payment.patch([listingId], body)
      } catch (error) {
        return ''
      }
    },
    async createPaymentRequest(payload) {
      const {
        tenantId,
        listingId,
        description,
        requested_amount,
        due_date
      } = payload
      try {
        const { response, body } = await createPaymentRequest(
          {
            body: {
              description,
              requested_amount: round(requested_amount, 2),
              due_date
            }
          },
          {
            listingId,
            tenantId
          }
        )
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch.payment.patch([listingId, 'payment_requests'], body)
        return response
      } catch (error) {
        return ''
      }
    },
    async cancelPaymentRequest({ paymentId }) {
      try {
        const { response, body } = await cancelPaymentRequest(undefined, {
          paymentId
        })
        if (!response.ok) {
          throw Error(response.statusText)
        }
        dispatch.payment.patch(
          [body[paymentId].listing_id, 'payment_requests'],
          body
        )

        return { body }
      } catch (error) {
        return { error }
      }
    },
    async getConnectUrl() {
      try {
        const { response, body } = await getConnectUrl()
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return body.message
      } catch (error) {
        return ''
      }
    },
    async connectSuccessful(payload) {
      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'stripe_connect_successful',
              payload
            })
          )
        } else {
          const { body } = await connectStripe(undefined, payload)
          dispatch.profile.patch(['company_info'], headObj(body))
        }
      } catch (error) {
        console.error('[payment/connectSuccessful] Error occured: ', error)
      }
    },
    async connectFailed() {
      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'stripe_connect_failed'
            })
          )
        }
      } catch (error) {
        console.error('[payment/connectFailed] Error occured: ', error)
      }
    },
    async process(payload) {
      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'stripe_payment_successful',
              payload
            })
          )
        } else {
          const { body } = await replyPaymentRequest({ body: payload.body })
          dispatch.payment.patch([payload.listingId, 'payment_requests'], body)
        }
      } catch (error) {
        console.error('[payment/process] Error occured', error)
      }
    }
  }),
  selectors: selectorBuilder(slice => ({
    listingPayments() {
      return slice(groupedPaymentsSelector)
    },
    payment() {
      return slice(paymentWithUsersSelector)
    }
  }))
}

export default payment
