import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Trans } from 'react-i18next'
import useRole from 'hooks/use-role'

const RowWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 30px;
  margin: 20px;
  color: ${colors.secondary};
  background-color: ${colors.white};
  border: 1px solid ${colors.mediumLightGrey};
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;

  :hover {
    border-color: ${colors.secondary};
  }
`

const HeaderText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
`
const LabelText = styled.p``

const formatDate = date => moment(date, 'YYYY-MM-DD').format('MMM DD YYYY')

const getDateRange = ({ start_date, end_date }) => {
  const startDate = formatDate(start_date)
  const endDate = formatDate(end_date)

  if (!end_date) {
    return (
      <LabelText>
        <Trans
          i18nKey="l.tenants.view.contract_start_date.text"
          values={{ startDate }}
          defaults="Since {{ startDate }}"
        />
      </LabelText>
    )
  }
  return (
    <LabelText>
      <Trans
        i18nKey="l.tenants.view.past_contract_dates.text"
        values={{
          startDate,
          endDate
        }}
        defaults="{{ startDate }} to {{ endDate }}"
      />
    </LabelText>
  )
}

const ContractList = props => {
  const userRole = useRole()
  const list = props.contracts
  const path = userRole === 'landlord' ? 'tenant' : 'rental'

  if (!list?.length) {
    return null
  }

  return (
    <div>
      <HeaderText>{props.title}</HeaderText>
      {list.map((contract, index) => {
        return (
          <RowWrapper
            key={index}
            to={`/${path}/${contract.listing_id}/resources/contract-history/${contract.id}`}
          >
            <LabelText>
              <Trans
                i18nKey="l.tenants.view.contract_number.text"
                values={{ contractNumber: index + 1 }}
                defaults="Contract {{ contractNumber }}"
              />
            </LabelText>
            {getDateRange(contract)}
          </RowWrapper>
        )
      })}
    </div>
  )
}

export default ContractList
