import React, { useRef } from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'
import { compose, pure } from 'recompose'
import useFocusOnError from 'hooks/use-focus-on-error'
import { colors } from 'styles'

const Wrapper = styled.p`
  color: ${colors.pink};
  font-size: 11px;
  padding-left: 6px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  text-align: left;
`

const Error = ({ focusOnError = true, name, ...rest }) => {
  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })
  return (
    <ErrorMessage
      {...rest}
      name={name}
      render={msg => (
        <Wrapper
          ref={focusOnError ? fieldRef : undefined}
          data-testid={`errors-${name}`}
          role="alert"
        >
          {msg}
        </Wrapper>
      )}
    />
  )
}

export default compose(pure)(Error)
