import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { map, pipe, pickAll, evolve, values as ramdaValues } from 'ramda'
import * as Yup from 'yup'
import { breakpoints } from 'styles'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import noop from 'lodash/noop'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import {
  Row,
  Group,
  FieldInput,
  PhoneInput,
  FieldDropdown,
  FieldDatePicker
} from 'components/form'
import { defaultIfFalsy } from 'utils'
import 'utils/validation'

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const RoommateForm = React.memo(
  ({ data = {}, onCancel = noop, onSave = noop }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(['translation', 'basic'])
    const { relationships } = useBasic(['relationships'])
    const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
      ? 12
      : 6

    const relationshipOptions = pipe(
      ramdaValues,
      map(a => ({
        value: a.txt_id,
        label: t(`basic:b.contact.relationships.${a.txt_id}.text`)
      }))
    )(relationships)

    const initialValues = pipe(
      pickAll([
        'id',
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'dob',
        'relationship'
      ]),
      evolve({
        relationship: defaultIfFalsy(''),
        first_name: defaultIfFalsy(''),
        last_name: defaultIfFalsy(''),
        email: defaultIfFalsy(''),
        phone_number: defaultIfFalsy(''),
        dob: defaultIfFalsy('')
      })
    )(data)

    const validationSchema = Yup.object().shape({
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
      email: Yup.string().email().trim().required(),
      phone_number: Yup.string().phone().required(),
      relationship: Yup.string().required(),
      dob: Yup.date()
    })

    const isEditing = !!data.id

    const handleDeleteClick = useCallback(() => {
      return confirm(
        t('r.profile.edit.delete_roommate.title', 'Delete Roommate'),
        t(
          'r.profile.edit.delete_roommate_confirmation.text',
          'Are you sure you want to delete this roommate?'
        )
      ).then(async () => {
        await dispatch.profile.deleteRoommate(data.id)
      })
    }, [data.id, dispatch.profile, t])

    const onSubmit = async (values, { setSubmitting }) => {
      const action = isEditing
        ? dispatch.profile.updateRoommate
        : dispatch.profile.addRoommate
      const response = await action(values)
      setSubmitting(false)

      if (!response.error) {
        onSave()
      }
    }
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, handleChange, handleSubmit }) => (
          <Form>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="first_name"
                  placeholder={
                    t('r.profile.edit.first_name.label', 'First Name') + '*:'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="last_name"
                  placeholder={
                    t('r.profile.edit.last_name.label', 'Last Name') + '*:'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="email"
                  placeholder={t('r.profile.edit.email.label', 'Email') + '*:'}
                />
              </Group>
              <Group col={colSize}>
                <PhoneInput
                  name="phone_number"
                  value={values.phone_number}
                  countryCodeAlpha={data.phone_country_code_alpha}
                  onChange={handleChange('phone_number')}
                  placeholder={
                    t('r.profile.edit.phone_number.label', 'Phone Number') +
                    '*:'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldDatePicker
                  name="dob"
                  data-cy="dob"
                  initialFocusedDate={moment().subtract(18, 'years')}
                  placeholder={
                    t('r.profile.edit.date_of_birth.label', 'Date of Birth') +
                    ':'
                  }
                  minDate={moment().subtract(100, 'years')}
                  displayFormat="YYYY-MM-DD"
                  disableFuture
                />
              </Group>
              <Group col={colSize}>
                <FieldDropdown
                  id="relationship"
                  name="relationship"
                  placeholder={
                    t('r.profile.edit.relationship.label', 'Relationship') +
                    '*:'
                  }
                  options={relationshipOptions}
                  isSearchable={false}
                />
              </Group>
            </Row>
            <Buttons>
              {isEditing ? (
                <ActionButton
                  theme="danger"
                  onClick={handleDeleteClick}
                  size="small"
                >
                  {t('r.profile.edit.delete.button', 'Delete')}
                </ActionButton>
              ) : (
                <ActionButton theme="neutral" onClick={onCancel} size="small">
                  {t('r.profile.edit.cancel.button', 'Cancel')}
                </ActionButton>
              )}
              <ActionButton
                id="close_form_button"
                type="button"
                theme="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isEditing
                  ? t('r.profile.edit.update.button', 'Update')
                  : t('r.profile.edit.add.button', 'Add')}
              </ActionButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    )
  }
)

export default RoommateForm
