import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import ProfileRequired from 'components/profile-required'
import EmptyMessageCheckbox from '../EmptyMessageCheckbox'
import PetView from './view'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const PetsList = ({
  anchorTop,
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  isChecked = false,
  showRequiredWarning = false
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(isChecked)

  const updatePetOwnerStatus = checked => {
    dispatch.profile.updatePetOwner({ pet_owner: checked ? 0 : 1 })
  }
  return (
    <MultiRowForm
      anchorTop={anchorTop}
      disabled={disabled}
      title={
        <>
          {t('r.profile.view.pets.subtitle', 'Pets')}
          { editable && (
            <ProfileRequired
              fields={['pet_owner', 'pet_information']}
              area="basic_info"
            />
          )}
        </>
      }
      addText={t('r.profile.view.add_pet.button', '+ Add Pet')}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={PetView}
      EditComponent={EditComponent}
      EmptyComponent={
        editable ? (
          <EmptyMessageCheckbox
            setDisabled={setDisabled}
            emptyMessage={emptyMessage}
            isChecked={isChecked}
            update={updatePetOwnerStatus}
          />
        ) : (
          <EmptyMessage>{emptyMessage}</EmptyMessage>
        )
      }
      editable={editable}
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(PetsList)
