import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { Input } from 'components/form'

const CodeWrapper = styled.label`
  display: flex;
  cursor: text;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-self: stretch;
`

const CodeDigit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2%;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey};
  border-radius: 6px;
  height: 47px;
  width: 40px;
  font-size: 28px;
  color: ${colors.regular};

  ${props =>
    props.focused &&
    `
      box-shadow: 0 0 5px 0 ${colors.lightPink};
  `}
`

const HiddenInput = styled(Input)`
  opacity: 0;
  position: absolute;
  left: -200vw;
  top: 180px;
`

const CodeInput = ({ codeLength, onSubmit }) => {
  const [code, setCode] = useState('')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    const submitCode = async () => {
      await onSubmit({
        code
      })
      setCode('')
    }
    if (code.length === codeLength) {
      submitCode()
    }
  }, [code, codeLength, onSubmit])

  const handleInput = e => {
    setCode(e.target.value)
  }

  return (
    <>
      <CodeWrapper htmlFor="code">
        {Array(codeLength)
          .fill(null)
          .map((val, index) => (
            <CodeDigit
              key={index}
              onClick={() => setFocused(true)}
              focused={focused}
            >
              {code[index]}
            </CodeDigit>
          ))}
      </CodeWrapper>
      <HiddenInput
        id="code"
        name="code"
        type="text"
        pattern="[0-9]*"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={code}
        onChange={handleInput}
        autoFocus
        autoComplete="off"
        maxLength={codeLength}
      />
    </>
  )
}

export default CodeInput
