import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Formik, Form } from 'formik'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import useRole from 'hooks/use-role'
import ProfileButtonBar from 'features/Profile/button-bar'
import PageTitle from 'components/page-title'
import {
  Label,
  Row,
  Group,
  Error,
  FieldInput as CheckBox,
  FieldTextarea
} from 'components/form'
import Page from 'components/page'
import ContentWrapper from 'components/content-wrapper'
import Panel from 'components/panel'
import { Button } from 'components/button'

const PanelContainer = styled(Panel)`
  margin: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px 0;
  }
`

const PanelHeading = styled.h1`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  border-bottom: 1px solid ${colors.grey};
  padding-bottom: 30px;
  margin-bottom: 30px;
`

const StyledLabel = styled(Label)`
  font-size: 14px;
  margin: 0;
  padding-left: 20px;
`

const StyledContainer = styled.div`
  margin-top: 14px;
  > p:first-of-type {
    margin-bottom: 35px;
  }
  > p:not(:last-of-type) {
    margin-bottom: 7px;
  }
  > p + p {
    margin-bottom: 10px;
  }
`

const StyledText = styled.p`
  margin: 0;
  font-size: 14px;
`

const StyledGroup = styled(Group)`
  padding: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const StyledRow = styled(Row)`
  margin: 0;
  align-items: center;
`

const Feedback = ({ history }) => {
  const { t } = useTranslation()
  const role = useRole()
  const isTenant = role === 'tenant'
  const goToDelete = state => {
    history.push('/settings/delete-account', state)
  }

  const Item = ({ name, children, value }) => (
    <StyledGroup col={12}>
      <StyledRow>
        <CheckBox name={name} id={name} value={value || name} type="checkbox" />
        <StyledLabel htmlFor={name}>{children}</StyledLabel>
      </StyledRow>
    </StyledGroup>
  )
  const anyTouched = ({ any, ...data }) => Object.keys(data).length > 0
  const toFeedback = feedback =>
    Object.values(feedback)
      .map(([key]) => key)
      .filter(el => !!el)
  const formOptions = () => ({
    initialValues: {},
    validateOnMount: true,
    validate: values => {
      const { other_form: custom, other, ...selected } = values
      const atLeastOne = toFeedback(selected).length > 0 || other
      const errors = {}
      if (!atLeastOne)
        errors.any = t(
          'b.feedback.view.at_least_one.error',
          'Please specify at least 1 option'
        )
      if (other && (!custom || custom.length <= 0))
        errors.other_form = t(
          'b.feedback.view.other_feedback.error',
          'Please specify other feedback'
        )
      return errors
    },
    onSubmit: async values => {
      const { other_form: custom, other, ...selected } = values
      const metadata = toFeedback(selected)
      const data = { metadata }
      if (other) data.message = custom
      goToDelete(data)
    }
  })
  return (
    <Formik {...formOptions()}>
      {({
        setFieldTouched,
        touched,
        errors,
        values,
        isSubmitting,
        isValid
      }) => {
        if (!touched.any) setFieldTouched('any')
        return (
          <Form className="flex-full">
            <Page>
              <ContentWrapper className="mb-50 mt-50">
                <Helmet>
                  <title>
                    {t('b.feedback.view.title', 'Feedback')} | Liv Rent
                  </title>
                  <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <PageTitle>{t('b.feedback.view.title', 'Feedback')}</PageTitle>
                <PanelContainer vSpace={30} hSpace={30}>
                  <PanelHeading>
                    {t('b.feedback.view.title', "We're sorry to see you go!")}
                  </PanelHeading>
                  <StyledContainer>
                    <StyledText>
                      {t(
                        'b.feedback.view.label',
                        "We're always looking for ways to improve our platform. We'd love to hear your feedback on why you want to delete your account."
                      )}
                    </StyledText>
                    {anyTouched(values) && <Error name="any" />}
                  </StyledContainer>
                  {isTenant ? (
                    <Item name="found_place_outside_livrent">
                      {t(
                        'r.feedback.view.outside.label',
                        'I found a place outside of liv.rent'
                      )}
                    </Item>
                  ) : (
                    <Item name="found_tenant_outside_livrent">
                      {t(
                        'l.feedback.view.outside.label',
                        'I found a tenant outside of liv.rent'
                      )}
                    </Item>
                  )}
                  {isTenant ? (
                    <Item name="could_not_find_anything">
                      {t(
                        'r.feedback.view.find.label',
                        "I couldn't find anything"
                      )}
                    </Item>
                  ) : (
                    <Item name="no_longer_renting_my_place">
                      {t(
                        'l.feedback.view.renting.label',
                        'I am no longer renting my place'
                      )}
                    </Item>
                  )}
                  {isTenant ? (
                    <Item name="too_much_info_required">
                      {t(
                        'r.feedback.view.too_much_info.label',
                        'Too much information required'
                      )}
                    </Item>
                  ) : (
                    <Item name="not_enough_inquiries">
                      {t(
                        'l.feedback.view.inquiries.label',
                        'Not enough leads/inquiries'
                      )}
                    </Item>
                  )}
                  <Item name="platform_is_too_confusing">
                    {t(
                      'b.feedback.view.confusing.label',
                      'The platform is too confusing'
                    )}
                  </Item>
                  {isTenant ? (
                    <Item name="not_sure_how_to_search_for_listings">
                      {t(
                        'r.feedback.view.search.label',
                        'Not sure how to search for listings'
                      )}
                    </Item>
                  ) : (
                    <Item name="not_sure_how_to_upload_listing">
                      {t(
                        'l.feedback.view.upload.label',
                        'Not sure how to upload my listing'
                      )}
                    </Item>
                  )}
                  <Item name="too_hard_to_chat">
                    {t('b.feedback.view.chat.label', 'Too hard to chat')}
                  </Item>
                  {isTenant && (
                    <Item name="application_too_long">
                      {t(
                        'r.feedback.view.application.label',
                        'Application too long'
                      )}
                    </Item>
                  )}
                  <Item name="other" value>
                    {t('b.feedback.view.other.label', 'Other (please specify)')}
                  </Item>
                  {values.other && (
                    <FieldTextarea
                      height={120}
                      maxLength={500}
                      name="other_form"
                      placeholder={t(
                        'b.feedback.view.other_description.label',
                        'Tell us about your experience and how we can do better.'
                      )}
                    />
                  )}
                </PanelContainer>
              </ContentWrapper>
              <ProfileButtonBar handleBack={history.goBack} sticky>
                <Button
                  theme="sane"
                  width={150}
                  left={10}
                  right={10}
                  onClick={() => goToDelete()}
                >
                  {t('b.feedback.view.skip.button', 'Skip this step')}
                </Button>
                <Button
                  theme="primary"
                  width={150}
                  left="auto"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  isSubmitting={isSubmitting}
                >
                  {t('b.feedback.view.next.button', 'Next')}
                </Button>
              </ProfileButtonBar>
            </Page>
          </Form>
        )
      }}
    </Formik>
  )
}

Feedback.displayName = 'Feedback'

export default Feedback
