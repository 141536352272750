import React from 'react'
import styled from 'styled-components'
import icons from './icons'

const Icon = ({ id, fallback, name, width, height, ...rest }) => {
  const Svg = icons[id]
  return <Svg className="icon" width={width} height={height} {...rest} />
}

Icon.defaultProps = {
  name: 'Icon',
  fallback: undefined,
  width: 35,
  height: 35
}

export default styled(Icon)``
