import React, { Suspense, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import LoadingContainer from 'components/loading'
import { Helmet } from 'react-helmet'
import { ApolloProvider } from '@apollo/client'
import client from 'graphql-client'
import NotificationContainer from 'components/notification'
import { ToastContainer } from 'components/toast-notifications'
import ErrorPage from 'pages/Pages/ErrorPage'
import useEventListener from 'hooks/use-event-listener'
import useScrollToTop from 'hooks/use-scroll-to-top'
import media from 'media'
import AuthenticatedApp from './authenticated-app'
import UnauthenticatedApp from './unauthenticated-app'

const mapState = state => ({
  ready: state.master.ready,
  session: state.session?.session
})

const mapDispatch = dispatch => ({
  load: dispatch.master.load,
  setupSSE: dispatch.sse.subscribe,
  terminateSSE: dispatch.sse.unsubscribe
})

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  ${media.lessThan('phoneMax')`
    min-height: 100%;
  `}
`

const Content = styled.div`
  flex: 1;
`

const onError = e => {
  if (e.message === 'ResizeObserver loop limit exceeded') {
    e.stopPropagation()
    e.stopImmediatePropagation()
  }
}

const AppReady = ({ AppComponent, session }) => {
  useScrollToTop()
  const { i18n } = useTranslation()
  const lang = i18n.languages[0] === 'zh' ? 'zh-Hans' : 'en'
  return (
    <Content>
      <Helmet titleTemplate="%s | liv.rent" htmlAttributes={{ lang }} />
      <NotificationContainer />
      <ToastContainer />
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <AppComponent session={session} />
      </ErrorBoundary>
    </Content>
  )
}

const App = ({ ready, load, session, setupSSE, terminateSSE }) => {
  useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('error', onError)
    return () => window.addEventListener('error', onError)
  }, [])
  useEffect(() => {
    load()
  }, [load])
  useEffect(() => {
    if (session) {
      setupSSE(session.uuid)
    }
    return terminateSSE
  }, [session, setupSSE, terminateSSE])
  const dispatch = useDispatch()
  useEventListener('storage', e => {
    if (e.key === 'session-id' && !!e.oldValue && e.oldValue !== e.newValue) {
      dispatch.session.clear()
    }
  })

  const AppComponent =
    !session || session?.registration_complete !== '1'
      ? UnauthenticatedApp
      : AuthenticatedApp

  return (
    <Suspense fallback={null}>
      <ApolloProvider client={client}>
        <Wrapper>
          <LoadingContainer />
          {ready && <AppReady AppComponent={AppComponent} session={session} />}
        </Wrapper>
      </ApolloProvider>
    </Suspense>
  )
}

App.defaultProps = {
  ready: false,
  location: {
    search: ''
  }
}

const AppContainer = compose(
  withRouter,
  connect(mapState, mapDispatch)
)(React.memo(App))

export default AppContainer
