import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { breakpoints } from 'styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import { mergeRight } from 'ramda'
import { getFormattedCountries } from 'utils/address'
import {
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  PhoneInput
} from 'components/form'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import StateDropdown from 'components/state-dropdown'
import ContentWrapper from 'components/content-wrapper'
import { Wrapper, StyledAreaTitle, StyledPanel } from './elements'
import Context from './context'
import 'utils/validation'

const PersonalInfo = ({ setCurrentStep, handleBack }) => {
  const { t } = useTranslation()
  const { formData, setFormData } = useContext(Context)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const { countries } = useBasic(['languages', 'countries'])
  const formattedCountries = getFormattedCountries(countries)

  const initialValues = {
    first_name: formData?.first_name || '',
    last_name: formData?.last_name || '',
    phone_number: formData?.phone_number || '',
    email: formData?.email || '',
    company_name: formData?.company_name || '',
    street1: formData?.street1 || '',
    street2: formData?.street2 || '',
    city: formData?.city || '',
    zip: formData?.zip || '',
    country: formData?.country || 'ca',
    state: formData?.state || ''
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().min(2).required(),
    last_name: Yup.string().min(2).required(),
    phone_number: Yup.string().phone().required(),
    email: Yup.string().email().required(),
    company_name: Yup.string(),
    street1: Yup.string().min(2).required(),
    street2: Yup.string(),
    city: Yup.string().min(2).required(),
    zip: Yup.string().min(2).required(),
    country: Yup.string().required(),
    state: Yup.string().required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setFormData(mergeRight(formData, values))
    setSubmitting(false)
    setCurrentStep('financial_info')
    window.scrollTo(0, 0)
  }

  const countryDropDown = (setValue, countryName, stateName) => (
    <Group col={colSize}>
      <FieldDropdown
        id={countryName}
        name={countryName}
        options={formattedCountries}
        onChange={() => setValue(stateName, '')}
        placeholder={
          t('l.payment_settings.edit.country.label', 'Country') + ':'
        }
      />
    </Group>
  )

  const provinceDropDown = (formValues, countryName, stateName) => (
    <StateDropdown
      col={colSize}
      country={formValues[countryName]}
      countries={countries}
      id={stateName}
      name={stateName}
    />
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, handleChange, isSubmitting }) => (
        <Form className="flex-full">
          <Wrapper>
            <ContentWrapper className="mb-50">
              <StyledAreaTitle>
                {t(
                  'l.payment_settings.edit.bank_account_detail.subtitle',
                  'Bank Account Detail'
                )}
              </StyledAreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="first_name"
                      name="first_name"
                      placeholder={
                        t(
                          'l.payment_settings.edit.first_name.label',
                          'First name'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="last_name"
                      name="last_name"
                      placeholder={
                        t(
                          'l.payment_settings.edit.last_name.label',
                          'Last name'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <PhoneInput
                      id="phone_number"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange('phone_number')}
                      bordered
                      showFlag={false}
                      placeholder={
                        t(
                          'l.payment_settings.edit.phone_number.label',
                          'Phone number'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="email"
                      name="email"
                      placeholder={
                        t('l.payment_settings.edit.email.label', 'Email') + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="company_name"
                      name="company_name"
                      placeholder={
                        t(
                          'l.payment_settings.edit.company_name_optional.label',
                          'Company name (optional)'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="street1"
                      name="street1"
                      placeholder={
                        t(
                          'l.payment_settings.edit.street_address.label',
                          'Street address'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="street2"
                      name="street2"
                      placeholder={
                        t(
                          'l.payment_settings.edit.unit_number.label',
                          'Unit number'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="city"
                      name="city"
                      placeholder={
                        t('l.payment_settings.edit.city.label', 'City') + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="zip"
                      name="zip"
                      placeholder={
                        t(
                          'l.payment_settings.edit.postal_code.label',
                          'Postal code'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  {countryDropDown(setFieldValue, 'country', 'state')}
                  {provinceDropDown(values, 'country', 'state')}
                </Row>
              </StyledPanel>
            </ContentWrapper>
          </Wrapper>
          <ButtonBar sticky>
            <Button theme="sane" width={150} inversed onClick={handleBack}>
              {t('l.payment_settings.view.back.button', 'Back')}
            </Button>
            <Button
              type="submit"
              theme="primary"
              width={150}
              isSubmitting={isSubmitting}
            >
              {t('l.payment_settings.view.next.button', 'Next')}
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  )
}

export default PersonalInfo
