import React from 'react'
import { propEq, find, pipe, path } from 'ramda'
import { useTranslation } from 'react-i18next'
import { getImageUrl } from 'utils/image'
import { prefixUnitNumber } from 'utils/listings'
import formatName from 'utils/format-name'
import useRole from 'hooks/use-role'
import useContract from 'hooks/use-contract'
import isVerified from 'utils/is-verified'
import hideUnit from 'utils/hide-unit'
import ChatCardBase from './chat-card-base'

const AVATAR_CONFIG = {
  width: 110,
  height: 110
}

const ChatCard = ({
  timeline: {
    applications,
    buildings,
    isRented,
    last_event,
    last_event_message,
    listings,
    listing_id,
    last_read,
    timeline_room,
    state_machine,
    units,
    users,
    primary_user_ids
  }
}) => {
  const { t } = useTranslation()
  const role = useRole()
  const buildingName = buildings?.name
  const contract = useContract(listing_id)
  if (users.them.length <= 0 && users.us.length <= 0) return null

  const unitHidden = hideUnit(units, contract)

  const getLandlordAddress = () => {
    const unitNumber = units.unit_number
    const streetAddress = buildings.full_street_name
    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${streetAddress}`
      : streetAddress

    return address
  }

  const getTenantHouseAddress = () => {
    const unitNumber = unitHidden
      ? t('common.house', 'House')
      : units.unit_number
    const streetAddress = unitHidden
      ? buildings.street_name
      : buildings.full_street_name
    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${streetAddress}`
      : streetAddress

    return address
  }

  const getTenantApartmentAddress = () => {
    const unitNumber = unitHidden ? null : units.unit_number
    const streetAddress = buildings.full_street_name
    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${buildingName || streetAddress}`
      : buildingName || streetAddress

    return address
  }

  const getTenantAddress = () => {
    const isHouse = buildings.building_type_txt_id === 'house'
    const address = isHouse
      ? getTenantHouseAddress()
      : getTenantApartmentAddress()

    return address
  }

  const avatarUrl = getImageUrl(
    pipe(
      find(propEq('id', primary_user_ids.them)),
      path(['avatar', 'aws_s3_key'])
    )(users.them),
    AVATAR_CONFIG
  )
  const other = find(propEq('id', primary_user_ids.them), users.them)
  return (
    <ChatCardBase
      names={users.them.map(user => formatName(user))}
      address={role === 'tenant' ? getTenantAddress() : getLandlordAddress()}
      avatar={avatarUrl}
      last_event={last_event}
      last_read={last_read}
      last_event_message={last_event_message?.event_info.message}
      timeline_room={timeline_room}
      stateMachine={state_machine}
      listingStateMachine={listings?.state_machine}
      hasApplied={!!applications?.id}
      isRented={isRented}
      isIdVerified={isVerified(other, other?.company)}
    />
  )
}

ChatCard.defaultProps = {
  timeline: {},
  url: '',
  name: '',
  unit: '',
  last_event_read: '',
  last_event: ''
}

export default React.memo(ChatCard)
