import React, { useState } from 'react'
import styled from 'styled-components'
import { fontWeights } from 'styles'
import TimeStamp from './timestamp'
import ChatEvent from '../chat-event'

const MessageBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  align-self: ${props => (props.isThem ? 'flex-start' : 'flex-end')};
  position: relative;
`

const DisplayNameText = styled.p`
  margin: 0px;
  max-width: 360px;
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const BubbleMessage = ({ event, isThem, showName }) => {
  const [translatedText, setTranslatedText] = useState()

  return (
    <>
      {showName && isThem && event?.display_name && (
        <DisplayNameText>{event?.display_name}.</DisplayNameText>
      )}
      <MessageBubbleContainer isThem={isThem}>
        <ChatEvent
          translatedText={translatedText}
          isThem={isThem}
          event={event}
        />
        <TimeStamp
          translatedText={translatedText}
          setTranslatedText={setTranslatedText}
          isThem={isThem}
          event={event}
        />
      </MessageBubbleContainer>
    </>
  )
}

export default React.memo(BubbleMessage)
