import styled from 'styled-components'
import { fontWeights, fontSizes, colors, transitions } from 'styles'

const offset = 10

export const active = `
  color: ${colors.darkGrey};
  transform: translate(0, -${offset}px);
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
`

const Label = styled.label`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  z-index: 1;
  transition: ${transitions.floatingLabel};
  position: absolute;
  pointer-events: none;
  font-size: ${fontSizes.regular};
  top: 0;
  left: 0;
  ${props => `
    transform: translate(${props.x || offset}px, ${props.y || 12.5}px);
  `}
  padding-left: 0 !important;
  color: ${colors.mediumGrey};

  display: flex;
  align-items: center;
`

export default Label
