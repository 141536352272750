import React from 'react'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import some from 'utils/some'

const StrataInfo = ({ lotNumber, planNumber }) => {
  const { t } = useTranslation()
  return some(lotNumber, planNumber) ? (
    <CollapsibleBox
      title={t('b.contract.view.strata_info.subtitle', 'Strata Info')}
      wrapChildren
    >
      {!!lotNumber && (
        <LabeledValue
          key="strata-number"
          label={t(
            'b.contract.view.strata_lot_number.label',
            'Strata lot number'
          )}
          value={lotNumber}
        />
      )}
      {!!planNumber && (
        <LabeledValue
          key="strata-number"
          label={t(
            'b.contract.view.strata_plan_number.label',
            'Strata plan number'
          )}
          value={planNumber}
        />
      )}
    </CollapsibleBox>
  ) : null
}

StrataInfo.defaultProps = {
  lotNumber: null,
  planNumber: null
}

export default StrataInfo
