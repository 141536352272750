/* eslint-disable max-statements */
/* global H */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import debounce from 'lodash/debounce'
import useScript from 'hooks/use-script'
import config from 'config'
import MapContext from './context'

const { HERE_MAPS_APP_ID, HERE_MAPS_APP_CODE } = config

const loadOpts = {
  async: false
}

const getMap = (container, layers, settings) => {
  return new H.Map(container, layers, settings)
}

const changeTheme = (theme, platform, map) => {
  if (!theme) return
  const tiles = platform.current.getMapTileService({ type: 'base' })
  const layer = tiles.createTileLayer('maptile', theme, 256, 'png')
  map.setBaseLayer(layer)
}

const resizeMap = map => {
  if (map) {
    map.getViewPort().resize()
  }
}

const debouncedResizeMap = debounce(resizeMap, 200)

const Map = ({
  appId = HERE_MAPS_APP_ID,
  appCode = HERE_MAPS_APP_CODE,
  children,
  theme,
  center,
  zoom = 15
}) => {
  const [data, setData] = useState({})
  const platform = useRef()
  const events = useRef()
  const ui = useRef()
  const element = useRef()
  useScript('https://js.api.here.com/v3/3.0/mapsjs-core.js', loadOpts)
  useScript('https://js.api.here.com/v3/3.0/mapsjs-service.js', loadOpts)
  useScript('https://js.api.here.com/v3/3.0/mapsjs-ui.js', loadOpts)
  const [loaded] = useScript(
    'https://js.api.here.com/v3/3.0/mapsjs-mapevents.js',
    loadOpts
  )

  useEffect(() => {
    if (!loaded || data.map) return
    platform.current = new H.service.Platform({
      app_id: appId,
      app_code: appCode,
      useHTTPS: true
    })
    const layers = platform.current.createDefaultLayers()
    const map = getMap(element.current, layers.normal.transit, {
      center,
      zoom
    })
    events.current = new H.mapevents.MapEvents(map)
    const behavior = new H.mapevents.Behavior(events.current)
    ui.current = new H.ui.UI.createDefault(map, layers)
    setData({
      map,
      behavior
    })
  }, [appCode, appId, center, data.map, loaded, zoom])

  useEffect(() => {
    if (!data.map) return
    resizeMap(data.map)
    data.map.setCenter(center)
    data.map.setZoom(zoom)
  }, [center, zoom, data.map])

  useEffect(() => {
    changeTheme(theme, data.platform, data.map)
  }, [data, theme])

  useEffect(() => {
    const resize = () => debouncedResizeMap(data.map)
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [data.map])

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://js.api.here.com/v3/3.0/mapsjs-ui.css?dp-version=1526040296"
        />
      </Helmet>
      <MapContext.Provider value={data}>
        <div
          id="here-map"
          ref={element}
          style={{ width: '100%', height: '100%', background: 'transparent' }}
        >
          {data.map ? children : null}
        </div>
      </MapContext.Provider>
    </>
  )
}

Map.propTypes = {
  center: PropTypes.object.isRequired,
  options: PropTypes.object
}

Map.defaultProps = {
  options: {
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false
  }
}

export default React.memo(Map)
