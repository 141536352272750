import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import SecurePhotoUploadedIcon from './chat-icons/SecurePhotoUploaded.svg'

const mapDispatch = dispatch => ({
  getSecureFile: dispatch.chat.getSecureFile
})

const Wrapper = styled.div`
  display: flex;
  margin: 0px;
  width: 260px;
  font-size: ${fontSizes.small};
  color: ${colors.white};
  cursor: pointer;
`

const EventContainer = styled.div`
  padding: 15px 25px;
  display: flex;
  align-items: center;
  background-color: ${colors.secondary};
`
const IconImage = styled.img`
  display: flex;
  justify-content: center;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-width: 180px;
  background-color: ${colors.white};
  padding: 10px;
`

const MessageText = styled.p`
  margin: 0px;
  font-size: ${fontSizes.small};
  line-height: 20px;
  background-color: ${colors.white};
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
`

const DownloadText = styled(MessageText)`
  text-decoration: underline;
  font-size: ${fontSizes.small};
  color: ${colors.secondary};
  font-weight: ${fontWeights.regular};
`

const SupportFile = ({ event, getSecureFile, isThem }) => {
  const { t } = useTranslation()
  const handleClick = () => {
    const fileInfo = event?.event_info?.file_info
    getSecureFile({ fileId: fileInfo?.id, fileName: fileInfo?.metadata?.name })
  }
  return (
    <Wrapper onClick={handleClick}>
      <EventContainer>
        <IconImage src={SecurePhotoUploadedIcon} />
      </EventContainer>
      <TitleContainer>
        <MessageText>
          {t('b.chat.view.file_uploaded.text', 'File Uploaded')}
        </MessageText>
        <DownloadText>
          {t('b.chat.view.download_file.text', 'Download File')}
        </DownloadText>
      </TitleContainer>
    </Wrapper>
  )
}

export default connect(null, mapDispatch)(React.memo(SupportFile))
