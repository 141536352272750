import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { update } from 'ramda'
import { colors } from 'styles'
import scrollTop from 'utils/scroll-top'
import { createModal, useModalize } from 'components/modalize'
import { ProgressWizard, WizardContent } from 'components/wizard'
import Icon from 'components/icon'
import { Button } from 'components/button'
import AddendumView from './addendum-view'

const CONTENT_ID = 'modal-wizard-content'

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  max-height: 95vh;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 20px 10px;

  svg {
    cursor: pointer;
    margin: 0;

    :hover {
      opacity: 0.5;
    }
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  padding: 25px;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px 0 20px 0;
`

const AddendumModalWizard = ({ data, activeStep, onChange, variables }) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(activeStep)
  const [addendums, setAddendums] = useState(data)
  const [buttonLabel, setButtonLabel] = useState('')

  const getButtonLabel = useCallback(() => {
    const incompleteSteps = addendums.filter(({ complete }) => !complete).length
    const remainingSteps = addendums.filter(
      step => !step.complete || currentStep === step.id
    ).length

    if (incompleteSteps < 2 && remainingSteps === 1) {
      setButtonLabel(t('b.contract.view.done.button', 'Done'))
    } else {
      setButtonLabel(t('b.contract.view.next_addendum.button', 'Next Addendum'))
    }
  }, [addendums, currentStep, t])

  useEffect(() => {
    getButtonLabel()
  }, [getButtonLabel])

  const handleChange = item => {
    const itemIndex = addendums.findIndex(addendum => item.id === addendum.id)
    const updatedAddendum = {
      ...item,
      complete: !item.complete
    }
    setAddendums(update(itemIndex, updatedAddendum, addendums))
    onChange(item)
  }

  const resetScroll = scrollTop(CONTENT_ID)

  const setStep = step => {
    setCurrentStep(step)
    resetScroll()
  }

  const getStepKey = step => {
    return step.id
  }

  const getSteps = () => {
    const steps = addendums.filter(
      step => !step.complete || currentStep === getStepKey(step)
    )

    const stepIndex = steps.findIndex(item => getStepKey(item) === currentStep)
    const before = steps.slice(0, stepIndex)
    const after = steps.slice(stepIndex)

    return [...after, ...before]
  }

  const goToNextStep = steps => {
    if (steps.length === 1) {
      close()
    } else {
      const itemIndex = steps.findIndex(
        item => getStepKey(item) === currentStep
      )
      const newStep = steps[itemIndex + 1]
      setStep(getStepKey(newStep))
    }
  }

  const renderStep = item => {
    return (
      <AddendumView
        key={item.id}
        addendum={item}
        onChange={addendum => handleChange(addendum)}
        variables={variables}
      />
    )
  }

  const renderCurrentStep = (step, index, arr) => {
    const key = getStepKey(step)
    return (
      <Step index={key} key={key}>
        {renderStep(step, index, arr)}
      </Step>
    )
  }

  const isButtonDisabled = () => {
    const step = addendums.find(item => getStepKey(item) === currentStep) || {}
    return !step.complete
  }

  const getProgress = () => {
    const completed = addendums.filter(item => item.complete)
    return (completed.length / addendums.length) * 100
  }

  const validSteps = getSteps()

  return (
    <Wrapper>
      <HeaderWrapper>
        <ProgressWizard progress={getProgress()} />
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
      </HeaderWrapper>
      <ScrollWrapper id={CONTENT_ID}>
        <WizardContent currentStep={currentStep}>
          {validSteps.map((step, index, arr) =>
            renderCurrentStep(step, index, arr)
          )}
        </WizardContent>
        <ButtonWrapper>
          <Button
            theme="primary"
            type="submit"
            width={150}
            onClick={() => goToNextStep(validSteps)}
            disabled={isButtonDisabled()}
          >
            {buttonLabel}
          </Button>
        </ButtonWrapper>
      </ScrollWrapper>
    </Wrapper>
  )
}

AddendumModalWizard.defaultProps = {
  data: [],
  currentStep: null
}

export const showAddendumModalWizard = createModal(AddendumModalWizard, {
  width: '720px'
})
