import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { props, not, isNil, all } from 'ramda'
import { showVerifyModal } from 'components/verify'
import hasVerified from 'utils/has-verified'
import { PanelContainer as Panel } from 'features/Settings/elements'
import { Helmet } from 'react-helmet'
import PageTitle from 'components/page-title'
import { breakpoints, fontWeights, colors, structure, shadows } from 'styles'
import { TextLink } from 'components/button/text-button'
import Page from 'components/page'
import ContentWrapper from 'components/content-wrapper'
import { AvatarWithPicker } from 'components/avatar'
import ContactInfo from 'components/contact-info'
import { AnchorButton } from 'components/anchor'
import useProfile from 'hooks/use-profile'
import useRole from 'hooks/use-role'
import TenantSummary from 'features/Profile/TenantSummary'
import { MenuItem, Label, Description } from '../menu-item'

const PreferenceWrapper = styled.div`
  margin-bottom: 10px;
`

const PanelContainer = styled(Panel)`
  > :last-child {
    > div {
      border: none;
    }
  }
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 10px 30px;
    max-width: none;
    border: none;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledButton = styled(AnchorButton)`
  width: 130px;
  letter-spacing: -0.39px;
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    padding: 23px 0;
  }
`

const VerifyAlert = styled(StyledButton)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const hasCompletedBasicInfo = data => {
  const fields = [
    'about_me',
    'work_history_properties_managed',
    'work_history_year_started'
  ]
  const company = ['address', 'state', 'country_code', 'city', 'zip']
  return all(
    x => not(isNil(x)),
    props(fields, data).concat(props(company, data.company_info))
  )
}

const DashboardItems = ({ isLandlord }) => {
  const { t } = useTranslation()
  const data = useProfile()
  const isVerified = hasVerified(data.identity_verified_state)
  const isCompanyVerified = hasVerified(
    data.company_info?.business_licence_verified_state
  )
  const isCompany = data.account_type === 'business'
  return (
    <PreferenceWrapper>
      <PanelContainer vSpace={20} hSpace={60}>
        {!isVerified && (
          <MenuItem
            onClick={() => {
              showVerifyModal({ data })
            }}
          >
            <Flex>
              <div>
                <Label>
                  {t('b.settings.view.verify_id.label', 'Verify ID')}
                </Label>
                <Description>
                  {t(
                    'b.settings.view.verify_id.description',
                    'Become verified to improve your credibility.'
                  )}
                </Description>
              </div>
              <VerifyAlert as={values => <div {...values} />}>
                Verify Now{' '}
              </VerifyAlert>
            </Flex>
          </MenuItem>
        )}
        {!isCompanyVerified && isCompany && isLandlord && (
          <MenuItem
            to={{ pathname: '/profile/edit', data: { verifyBusiness: true } }}
          >
            <Label>
              {t('b.settings.view.verify_business.label', 'Verify Business')}
            </Label>
            <Description>
              {t(
                'b.settings.view.verify_business.description',
                'Verify by entering your business license number.'
              )}
            </Description>
          </MenuItem>
        )}
        {(!isLandlord || !hasCompletedBasicInfo(data)) && (
          <MenuItem to="/profile/edit">
            <Label>
              {t(
                'b.settings.view.basic_information.label',
                'Basic Information'
              )}
            </Label>
            <Description>
              {t(
                'b.settings.view.basic_information.description',
                'Profile photo, about yourself, ID, address...'
              )}
            </Description>
          </MenuItem>
        )}
        <MenuItem to="/settings/preferences">
          <Label>{t('b.settings.view.preferences.label', 'Preferences')}</Label>
          <Description>
            {t(
              'b.settings.view.preferences.description',
              'Measurement units, language settings...'
            )}
          </Description>
        </MenuItem>
        <MenuItem to="/settings">
          <Label>
            {t('b.settings.view.account_settings.label', 'Account Settings')}
          </Label>
          <Description>
            {t(
              'b.settings.view.account_settings.description',
              'Payment settings, change password...'
            )}
          </Description>
        </MenuItem>
      </PanelContainer>
    </PreferenceWrapper>
  )
}

const MainWrapper = styled(ContentWrapper)`
  margin-top: 50px;
  margin-bottom: 100px;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0;
    max-width: none;
  }
`

const MobileBackground = createGlobalStyle`
  body {
    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      background: ${colors.white};
    }
  }
`

const AccountInfo = styled.div`
  position: sticky;
  background: ${colors.white};
  box-shadow: ${shadows.header};
  z-index: 2;
  max-height: 215px;
  overflow: hidden;
  top: ${structure.header.height}px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    top: ${structure.header.heightMobile}px;
  }
`

const VerticalContainer = styled.div`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  > div:not(:last-of-type) {
    padding-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: none;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0px auto;
  padding: 25px;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 30px;
  }
`

const AdminContainer = styled(Container)`
  padding-top: 5px;
  > a:not(:last-of-type) {
    margin-right: 20px;
  }
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 20px 0 0 0;

    > a:not(:last-of-type) {
      margin-right: 1px;
    }
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ProfileLink = styled(TextLink)`
  margin-top: 0;
  padding: 5px 0;
`

const Dashboard = () => {
  const { t } = useTranslation()
  const role = useRole()
  const data = useProfile()
  const isLandlord = role === 'landlord'
  const profile = t('l.profile.view.my_profile.title', 'My Profile')
  const showAdminDashboard = () => {
    return (
      (data.account_type === 'business' || data.account_type === 'family') &&
      data.user_role === 'administrator' && (
        <>
          <StyledButton to="/manage/users/">
            {t('l.profile.manage_users.label', 'Manage Users')}
          </StyledButton>
          <StyledButton to="/manage/listings/">
            {t('l.profile.manage_listings.label', 'Manage Listings')}
          </StyledButton>
        </>
      )
    )
  }
  const viewProfile = t('l.settings.view.view_profile.button', 'View profile')
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>{profile}</title>
      </Helmet>
      <PageTitle />
      <MobileBackground />
      <PageTitle>{profile}</PageTitle>
      <Page>
        <AccountInfo>
          {isLandlord ? (
            <VerticalContainer>
              <Container>
                <TextContainer>
                  <ContactInfo
                    data={data}
                    showFullName={false}
                    lineWrap={false}
                  />
                  <ProfileLink to="/profile/view">{viewProfile}</ProfileLink>
                </TextContainer>
                <AvatarWithPicker data={data} editable />
              </Container>
              <AdminContainer>{showAdminDashboard()}</AdminContainer>
            </VerticalContainer>
          ) : (
            <TenantSummary data={data} editable isHeader>
              <ProfileLink to="/profile/basic-info">{viewProfile}</ProfileLink>
            </TenantSummary>
          )}
        </AccountInfo>
        <MainWrapper>
          <DashboardItems isLandlord={isLandlord} />
        </MainWrapper>
      </Page>
    </>
  )
}

export default Dashboard
