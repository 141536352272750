import styled from 'styled-components'
import { colors, fontSizes } from 'styles'

const P = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  margin-bottom: 60px;
`

export default P
