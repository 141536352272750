const downloadImages = images => {
  const urls = images.map(image => image.location)
  var link = document.createElement('a')
  link.setAttribute('download', null)
  link.style.display = 'none'

  document.body.appendChild(link)
  var offset = 0
  urls.forEach(url => {
    setTimeout(() => {
      link.setAttribute('href', url)
      link.click()
    }, offset)
    offset += 500
  })
  document.body.removeChild(link)
}

export default downloadImages
