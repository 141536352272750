import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, fontSizes } from 'styles'
import { Formik, Form } from 'formik'
import { PhoneInput, Input } from 'components/form'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import { H1 } from 'components/headings'
import P from 'components/paragraph'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const Wrapper = styled.div``

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 425px;
  max-width: 95%;
  margin: 0 100px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
  text-align: center;
`

const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 425px;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 10px;
`

const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`

const DescriptionText = styled(P)`
  margin: 30px 20px;
`

const AssuranceText = styled(P)`
  font-size: ${fontSizes.small};
  padding: 10px;
  margin: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  letter-spacing: -0.2px;
`

const PhoneForm = styled(Form)`
  display: flex;
  flex-direction: row;
  margin: 5px 20px 10px;
  height: 70px;
  overflow: hidden;
`

const Submit = styled(Input)`
  background-color: ${colors.secondary};
  border-radius: 5px;
  width: auto;
  height: 40px;
  border: none;
  color: ${colors.white};
  padding: 0 10px;
  margin: 5px;
  -webkit-appearance: none;
  appearance: none;

  &:disabled {
    cursor: default;
  }

  &:not(:disabled) {
    &:hover {
      opacity: 0.5;
    }
  }
`
const validationSchema = Yup.object().shape({
  phone: Yup.string().phone().required()
})

const ValidatePhone = ({ onSubmit }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <TaskbarContainer>
        <TaskbarItem left={<Tool to="/" type="back" />} />
        <Title>
          {t('b.auth.view.enter_phone.title', 'Enter Your Phone Number')}
        </Title>
      </TaskbarContainer>
      <DescriptionText>
        {t(
          'b.auth.view.enter_phone_instructions.text',
          'We will be using this number to send you an SMS message for authentication.'
        )}
      </DescriptionText>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ handleChange, values, isValid, errors }) => (
          <PhoneForm>
            <Wrapper>
              <PhoneInput
                name="phone"
                autoFocus
                placeholder={t(
                  'b.auth.view.phone_number.placeholder',
                  'Phone Number'
                )}
                value={values.phone}
                onChange={handleChange('phone')}
              />
            </Wrapper>
            <Submit
              type="submit"
              name="submit"
              value={t('b.auth.view.send.button', 'Send')}
              disabled={!isValid}
            />
          </PhoneForm>
        )}
      </Formik>
      <AssuranceText>
        {t(
          'b.auth.view.enter_phone_assurance.text',
          'Building trust and confidence is our priority. We ask for phone number to ensure members identity.'
        )}
      </AssuranceText>
    </Container>
  )
}

export default ValidatePhone
