import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, structure, breakpoints, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import { CloseClickOutside } from 'components/window'
import DashboardHeaderSubTitle from 'components/header/dashboard-header-subtitle'
import Icon from 'components/icon'

const MenuBar = styled.menu`
  background: ${colors.secondary};
  width: 100%;
  height: 50px;
  position: sticky;
  top: ${structure.header.height}px;
  z-index: 125;
  padding: 0 20px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
    background: ${colors.white};
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
    position: fixed;
    height: auto;
    padding: 0;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1000px) {
    justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: none;
    flex-direction: column;
  }
`

const Title = styled.div`
  display: flex;
  z-index: 125;
  padding: 0 20px;
  color: ${colors.white};
  background-color: ${colors.secondary};
  height: 40px;
  width: 100%;
  letter-spacing: 0.25px;
  line-height: 24px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  cursor: pointer;
`

const ActiveTab = styled.div`
  padding: 0 0 0 20px;
  display: flex;
  justify-content: center;
  width: 100%;
`

const activeClassName = 'nav-item-active'

const MenuItem = styled(NavLink)`
  min-width: 130px;
  padding: 0 20px;
  height: 26px;
  color: ${colors.white};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.25px;
  line-height: 24px;
  text-align: center;
  text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);
  border: none;
  background: none;
  border: 1px solid transparent;

  &.${activeClassName} {
    border: 1px solid ${colors.white};
    border-radius: 2px;

    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      border: none;
      border-radius: 0;
      background: ${colors.secondary};
      color: ${colors.white};
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.25px;

      :hover {
        background: ${colors.secondary};
      }
    }
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    pointer-events: none;
  `}

  @media screen and (max-width: 1000px) {
    min-width: 80px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    background: ${colors.white};
    color: ${colors.darkGrey};
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;

    :hover {
      background: ${colors.lightGrey};
    }
  }
`

const DashboardMenu = ({ menuItems }) => {
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
      {displayMobile && (
        <Title onClick={() => setShowMenu(!showMenu)}>
          <ActiveTab>
            <DashboardHeaderSubTitle />
          </ActiveTab>
          <Icon id="down_arrow_white" width={18} height={18}></Icon>
        </Title>
      )}
      {(!displayMobile || (showMenu && displayMobile)) && (
        <CloseClickOutside onClickOutside={() => setShowMenu(false)}>
          <MenuBar>
            <Container>
              {menuItems.map((item, index) => {
                if (item.hidden) return null
                return (
                  <MenuItem
                    key={`dashboard-menu-item-${index}`}
                    to={item.link}
                    activeClassName={activeClassName}
                    exact={item.exact}
                    disabled={item.disabled}
                    onClick={() => {
                      setShowMenu(false)
                    }}
                  >
                    {item.text}
                  </MenuItem>
                )
              })}
            </Container>
          </MenuBar>
        </CloseClickOutside>
      )}
    </>
  )
}

export default DashboardMenu
