import styled from 'styled-components'
import { Table } from 'components/table'
import { breakpoints } from 'styles'

const ListTable = styled(Table)`
  width: 1096px;
  min-width: 768px;
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    max-width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: auto;
  }
`
export default ListTable
