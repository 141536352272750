import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { colors, fontSizes } from 'styles'
import KJGiphy from './kijiji_post.gif'

const Title = styled.span`
  font-size: ${fontSizes.large};
  font-weight: bold;
  color: ${colors.regular};
  text-align: center;
`

const Description = styled.span`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  text-align: center;
  padding: 30px 0;
`

const Giphy = styled.img`
  width: 100%;
  margin-bottom: 50px;
`

const ShareByKijiji = ({ onClose }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    onClose()
    window.open('https://www.kijiji.ca/p-select-category.html', '_blank')
  }

  return (
    <>
      <Title>
        {t('l.listing.view.share_kijiji_modal.title', 'Description copied!')}
      </Title>
      <Description>
        {t(
          'l.listing.view.share_kijiji_modal_description.title',
          'Invite potential renters by sharing your listing and connecting them with liv.rent'
        )}
      </Description>
      <Giphy src={KJGiphy} />
      <Button
        type="submit"
        theme="primary"
        size="medium"
        width={160}
        onClick={handleClick}
      >
        {t(
          'l.listing.view.share_kijiji_modal_to_kijiji.button',
          'Go to Kijiji'
        )}
      </Button>
    </>
  )
}

export default React.memo(ShareByKijiji)
