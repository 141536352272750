import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { colors, transitions, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import { toUnit } from 'utils/styles'

const Wrapper = styled.div`
  height: ${props => toUnit(props.height) || '100px'};
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: ${props => props.top || 0}px;
`

const Control = styled.textarea`
  border-radius: 6px;
  padding: 15px;
  height: 100%;
  border-radius: ${props => (props.radius ? toUnit(props.radius) : '6px')};
  border: ${props => (props.border ? props.border : `1px solid #ccc`)};
  font-size: ${props =>
    props.fontSize ? `${props.fontSize}px` : fontSizes.regular};
  font-weight: ${props => props.fontWeight || fontWeights.medium};
  outline: none;
  letter-spacing: -0.39px;
  color: ${colors.regular};
  width: 100%;
  resize: none;
  background: ${props => props.background || colors.white};
  transition: border-color ${transitions.ease};

  &::placeholder {
    color: #ccc;
    font-weight: ${fontWeights.regular};
  }
  &:focus {
    border-color: ${colors.darkBlue};
  }
  &:disabled {
    opacity: 0.3;
  }
`

const Counter = styled.small`
  position: absolute;
  color: ${colors.regular};
  font-size: ${fontSizes.small};
  letter-spacing: -0.39px;
  line-height: 22px;
  text-align: right;
  right: 5px;
  bottom: 0px;
`

const Textarea = forwardRef(
  (
    {
      onChange,
      children,
      maxLength,
      border,
      value = '',
      setTextArea = noop,
      ...rest
    },
    ref
  ) => {
    const handleChange = e => {
      if (maxLength) e.target.value = e.target.value.slice(0, maxLength)
      onChange(e)
    }
    return (
      <Wrapper
        ref={ref}
        top={rest.top}
        height={rest.height}
        background={rest.background}
      >
        <Control
          {...rest}
          value={value}
          border={border}
          onChange={handleChange}
          ref={textRef => setTextArea(textRef)}
        >
          {children}
        </Control>
        {maxLength && (
          <Counter>
            {value.length}/{maxLength}
          </Counter>
        )}
      </Wrapper>
    )
  }
)

Textarea.displayName = 'Textarea'

export default Textarea
