import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints, structure, zIndex } from 'styles'
import { toUnit } from 'utils/styles'
import config from 'config'
import useMediaQuery from 'hooks/use-media-query'
import DashboardHeaderTitle from './dashboard-header-title'
import logoImage from './Logo.svg'
import { ReactComponent as MenuIcon } from './menu-icon.svg'

const { WEB_URL } = config

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  height: ${structure.header.height}px;
  z-index: ${zIndex.header};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
  transition: all 0.2s ease;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: ${structure.header.heightMobile}px;
    width: 100%;
  }

  @media print {
    position: static;
  }
`

const HeaderInner = styled.div`
  display: flex;
  height: 100%;
  max-width: 1330px;
  padding: 0 30px;
  margin-right: auto;
  margin-left: auto;
  align-items: stretch;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 38px;
    max-width: initial;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    align-items: center;
    padding: 0 25px;
  }
`

const LogoLink = styled.a`
  align-self: center;
`

const Logo = styled.img`
  height: 30px;

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    height: 20px;
  }
`

const HamburguerMenu = styled.button`
  border: none;
  background: none;
  flex-shrink: 0;
  padding: 0;
  cursor: pointer;
  svg {
    display: block;
  }
`

const Header = ({ sidebarExpanded, setSidebar }) => {
  const sidebarWidth = toUnit(
    sidebarExpanded ? structure.sidebar.widthExpanded : structure.sidebar.width
  )
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const toggleSidebar = () => setSidebar(current => !current)

  return (
    <HeaderContainer sidebarWidth={sidebarWidth}>
      <HeaderInner>
        {displayMobile && (
          <HamburguerMenu onClick={toggleSidebar}>
            <MenuIcon />
          </HamburguerMenu>
        )}
        <DashboardHeaderTitle />

        <LogoLink as="a" href={WEB_URL}>
          <Logo aria-label="Home" src={logoImage} alt="Liv.rent Logo" />
        </LogoLink>
      </HeaderInner>
    </HeaderContainer>
  )
}

export default Header
