import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import config from 'config'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import Panel from 'components/panel'
import { Label, Row } from 'components/form'
import CurrencyFormatter from 'components/currency-formatter'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import Icon from 'components/icon'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'

const ContentWrapper = styled.div`
  max-width: 700px;
  margin: 60px auto 70px auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 60px 10px 70px 10px;
  }
`

const StyledPanel = styled(Panel)`
  text-align: left;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0px;
  }
`

const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  border-top: ${props => (props.borderTop ? 1 : 0)}px solid ${colors.grey};
`

const StyledLabel = styled(Label)`
  flex: 1;
  margin-right: 20px;

  ${props =>
    props.bold &&
    `
      font-weight: ${fontWeights.medium};
  `}
`

const StyledValue = styled(Label)`
  font-weight: ${fontWeights.medium};
  flex: 1;
  text-align: right;
`

const FeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }
`

const LandlordPaymentHistory = ({
  paymentId,
  paymentData: {
    state_machine,
    state_machine_cycle,
    confirmation_id,
    sent_by,
    to,
    detail,
    due_date,
    transaction_date,
    ach,
    payment_method,
    payment_amount,
    transaction_fee,
    payout_total
  },
  history
}) => {
  const { t } = useTranslation()

  const paymentStates = {
    pending: t('b.payment.view.payment_status_pending.text', 'Pending'),
    payment_captured: t(
      'b.payment.view.payment_status_payment_received.text',
      'Payment Received'
    ),
    payment_disbursement_started: t(
      'b.payment.view.payment_status_payout_pending.text',
      'Payout Pending'
    ),
    payment_disbursement_success: t(
      'b.payment.view.payment_status_payout_processed.text',
      'Payout Processed'
    ),
    payment_disbursement_fail: t(
      'b.payment.view.payment_status_payout_failed.text',
      'Payout Failed'
    ),
    complete: t('b.payment.view.payment_status_completed.text', 'Completed'),
    cancelled: t('b.payment.view.payment_status_cancelled.text', 'Cancelled'),
    approved: t('b.payment.view.successful.text', 'Successful')
  }

  const getPdfUrl = () =>
    `${config.BACKEND_SERVER}/payment_request/receipt/landlord/${paymentId}/receipt.pdf`

  return (
    <>
      <ContentWrapper>
        <AreaTitle>
          {t('b.payment.view.transaction_summary.title', 'Transaction Summary')}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={50}>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.status.label', 'Status')}
            </StyledLabel>
            <StyledValue>{paymentStates[state_machine]}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.confirmation_number.label', 'Confirmation #')}
            </StyledLabel>
            <StyledValue>{confirmation_id}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.sent_by.label', 'Sent by')}
            </StyledLabel>
            <StyledValue>{sent_by}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>{t('b.payment.view.sent_to.label', 'To')}</StyledLabel>
            <StyledValue>{to}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.detail.label', 'Detail')}
            </StyledLabel>
            <StyledValue>{detail}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.due_date.label', 'Due date')}
            </StyledLabel>
            <StyledValue>
              {moment(due_date, 'YYYY-MM-DD').format('MM-DD-YYYY')}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.transaction_date.label', 'Transaction date')}
            </StyledLabel>
            <StyledValue>
              {moment(transaction_date, 'YYYY-MM-DD').format('MM-DD-YYYY')}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.payout_method.label', 'Payout method')}
            </StyledLabel>
            <StyledValue>{payment_method}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.payment_amount.label', 'Payment amount')}
            </StyledLabel>
            <StyledValue>
              <CurrencyFormatter value={payment_amount} showCents />
            </StyledValue>
          </StyledRow>
          {!!transaction_fee && (
            <StyledRow bottom={20}>
              <FeeInfoWrapper>
                <Label bottom="0">
                  {t(
                    'b.payment.view.make_payment_transaction_fee.text',
                    'Payout fee'
                  )}
                </Label>
                <Icon
                  id="info_blue"
                  width={16}
                  height={16}
                  onClick={showProcessingFeesModal}
                />
              </FeeInfoWrapper>
              <StyledValue bottom="0">
                <CurrencyFormatter value={transaction_fee} showCents />
              </StyledValue>
            </StyledRow>
          )}
          <StyledRow bottom="0" paddingTop={20} borderTop>
            {ach === 'upop' ? (
              <>
                <StyledLabel bottom="0" bold>
                  {t('b.payment.view.make_payment_total.label', 'Total')}
                </StyledLabel>
                <StyledValue bottom="0">
                  <CurrencyFormatter value={payout_total} showCents />
                </StyledValue>
              </>
            ) : (
              <StyledLabel bottom="0" bold>
                **{' '}
                {t(
                  'l.payment.view.final_total_in_stripe_account.label',
                  'Please refer to the Final Payout Total in your Stripe account.'
                )}
              </StyledLabel>
            )}
          </StyledRow>
          {state_machine_cycle === 'payment_disbursement_fail' && (
            <StyledRow bottom="0" paddingTop={20}>
              <StyledLabel bottom="0" bold>
                **{' '}
                {t(
                  'l.payment.view.transaction_failed_description.text',
                  'Transaction failed. This may be a result of change in payment settings. Please contact customer support.'
                )}
              </StyledLabel>
            </StyledRow>
          )}
          {state_machine_cycle === 'payment_disbursement_started' && (
            <StyledRow bottom="0" paddingTop={20}>
              <StyledLabel bottom="0" bold>
                **{' '}
                {t(
                  'l.payment.view.transaction_payout_pending.text',
                  'This transaction is being processed. You will get paid in approx. 5 business days.'
                )}
              </StyledLabel>
            </StyledRow>
          )}
        </StyledPanel>
      </ContentWrapper>

      <ButtonBar sticky>
        <Button theme="sane" width={150} inversed onClick={history.goBack}>
          {t('b.payment.view.make_payment_back.button', 'Back')}
        </Button>
        {state_machine_cycle === 'complete' && (
          <Button
            as="a"
            theme="primary"
            width={150}
            href={getPdfUrl()}
            target="_blank"
          >
            {t('b.payment.view.export_payment_receipt.button', 'Export')}
          </Button>
        )}
      </ButtonBar>
    </>
  )
}

export default LandlordPaymentHistory
