import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontWeights } from 'styles'
import noop from 'lodash/noop'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0 0 50px 0;
  text-align: center;
`

const MessageModal = ({
  title,
  message,
  buttonText,
  onButtonClick,
  icon = 'round_check',
  onClose = noop
}) => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const handleClose = () => {
    close()
    onClose()
  }

  const handleButtonClick = () => {
    close()
    onButtonClick ? onButtonClick() : onClose()
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={handleClose}
        />
      </ModalClose>
      <ScrollWrapper>
        <Icon id={icon} width={75} height={75} />
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
        <Button theme="primary" width={150} onClick={handleButtonClick}>
          {buttonText || t('b.components.success_modal.ok.button', 'OK')}
        </Button>
      </ScrollWrapper>
    </Wrapper>
  )
}

const show = createModal(MessageModal, { clickOutside: false })
export default show
export { MessageModal }
