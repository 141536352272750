import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, colors } from 'styles'

const Wrapper = styled.span`
  position: relative;
  display: inline-flex;
  margin-top: ${({ top }) => top || 0}px;
  margin-right: ${({ right }) => right || 0}px;
  margin-bottom: ${({ bottom }) => bottom || 0}px;
  margin-left: ${({ left }) => left || 0}px;
`

const topStyle = css`
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
`
const topArrowStyle = css`
  top: -11px;
  left: calc(50% - 5px);
  border-right: 5px solid transparent;
  border-top: 8px solid ${colors.secondary};
  border-left: 5px solid transparent;
`

const bottomStyle = css`
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
`
const bottomArrowStyle = css`
  bottom: -11px;
  left: calc(50% - 10px);
  border-right: 5px solid transparent;
  border-bottom: 8px solid ${colors.secondary};
  border-left: 5px solid transparent;
`

const rightStyle = css`
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
`
const rightArrowStyle = css`
  top: calc(50% - 5px);
  right: -11px;
  border-right: 9px solid ${colors.secondary};
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
`

const leftStyle = css`
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
`
const leftArrowStyle = css`
  top: calc(50% - 5px);
  left: -11px;
  border-left: 9px solid ${colors.secondary};
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
`

const Content = styled.span`
  color: ${colors.white};
  position: absolute;
  z-index: 10;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  border-radius: 2px;
  white-space: nowrap;
  background-color: ${colors.secondary};
  animation: fadeIn ease-in-out 0.2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: ${breakpoints.phoneMax}) {
    font-size: 13px;
  }

  ${props => props.position === 'top' && topStyle};
  ${props => props.position === 'bottom' && bottomStyle};
  ${props => props.position === 'right' && rightStyle};
  ${props => props.position === 'left' && leftStyle};

  ${props =>
    props.offsetLeft &&
    `
    left: ${props.offsetLeft}%;
  `};

  ${props =>
    props.offsetRight &&
    `
    right: ${props.offsetRight}%;
  `};
`

const Arrow = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  animation: fadeIn ease-in-out 0.2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${props => props.position === 'top' && topArrowStyle};
  ${props => props.position === 'bottom' && bottomArrowStyle};
  ${props => props.position === 'right' && rightArrowStyle};
  ${props => props.position === 'left' && leftArrowStyle};
`

const Tooltip = ({ text, position = 'top', children, ...rest }) => {
  const node = useRef()
  const [visible, setVisible] = useState(false)

  const handleClick = ({ target }) => {
    if (node.current.contains(target)) {
      return
    }
    setVisible(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <Wrapper
      ref={node}
      onClick={() => setVisible(true)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      {...rest}
    >
      {children}
      {visible && (
        <>
          <Content position={position} {...rest}>
            {text}
          </Content>
          <Arrow position={position} />
        </>
      )}
    </Wrapper>
  )
}

export default Tooltip
