const toId = (placeholder, prefix) => {
  if (!placeholder) return null
  const base = placeholder
    .toLowerCase()
    .replace(/(\s|\/)/g, '-')
    .replace(/\*|:|(\)|\(|\.)/g, '')
  return `${prefix || 'input'}-${base}`
}

export default toId
