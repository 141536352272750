import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { colors, fontSizes } from 'styles'

const Title = styled.span`
  font-size: ${fontSizes.large};
  font-weight: bold;
  color: ${colors.regular};
  text-align: center;
`

const Description = styled.span`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  text-align: center;
  padding: 30px 0;
`

const ShareByUrl = ({ onClose }) => {
  const [t] = useTranslation()
  return (
    <>
      <Title>
        {t('l.listing.view.share_url_modal.title', 'Listing link copied!')}
      </Title>
      <Description>
        {t(
          'l.listing.view.share_url_modal_description.text',
          'Listing link has been copied to your clipboard! Simply paste to start sharing with potential renters'
        )}
      </Description>
      <Button
        type="submit"
        theme="primary"
        size="medium"
        width={160}
        onClick={onClose}
      >
        {t('l.listing.view.share_url_modal_done.button', 'Done')}
      </Button>
    </>
  )
}

export default React.memo(ShareByUrl)
