import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, breakpoints } from 'styles'
import { Avatar } from 'components/avatar'
import { CompanyAddress } from 'components/company'
import { SectionHeader } from 'components/headings'
import Section from 'components/section'
import SectionSeparator from 'components/section-separator'
import UserFullName from 'components/user'
import { getImageUrl } from 'utils/image'
import SignedIcon from './signed-icon.svg'
import AwaitingSignatureIcon from './awaiting-signature.svg'

const GroupHeader = styled.span`
  display: block;
  font-size: 17px;
  font-weight: 700;
`

const SignatoreeList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Signatoree = styled.div`
  padding-right: 40px;
  flex-basis: 25%;
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    flex-grow: 1;
  }
`

const Signee = styled.span`
  display: block;
  font-size: ${fontSizes.small};
`

const CompanyName = styled.span`
  font-weight: 700;
  display: block;
`

const SigneeName = styled(UserFullName)`
  ${({ signed, partialView }) =>
    !signed &&
    partialView &&
    `
    font-weight: 700;
    color: ${colors.pink};
  `}
`

const RoommateName = styled.span`
  ${({ signed, partialView }) =>
    !signed &&
    partialView &&
    `
    font-weight: 700;
    color: ${colors.pink};
  `}
`

const SigneeAvatar = styled(Avatar)`
  ${({ signed, partialView }) =>
    (signed || partialView) &&
    `
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        box-shadow: inset 0 1px 2px 0 rgba(${
          signed ? '0,0,0' : '251,105,100'
        },0.4);
        background-image: url(${signed ? SignedIcon : AwaitingSignatureIcon});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
  `}
`

const hasSigned = (signatures, type) => userId =>
  signatures.filter(
    signature => signature.userId === userId && signature.userType === type
  ).length > 0

const Signatorees = props => {
  const {
    landlords,
    signingTenants,
    nonSigningTenants,
    extraRenters,
    signatures,
    partialView,
    company
  } = props
  const { t } = useTranslation()
  const hasUserSigned = hasSigned(signatures, 'user')
  const isBusinessAccount = company.account_type === 'business'

  return (
    <Section>
      <SectionHeader>
        {t('b.contract.view.agreement_between.subtitle', 'Agreement Between')}
      </SectionHeader>
      <div>
        <GroupHeader>
          {t('b.contract.view.landlords.text', 'Landlords')}:
        </GroupHeader>
        {isBusinessAccount && (
          <>
            <p>
              <CompanyName>{company.name}</CompanyName>
            </p>
            <CompanyAddress {...company} />
          </>
        )}
      </div>

      <div>
        {isBusinessAccount && (
          <GroupHeader>
            {t('b.contract.view.agents.text', 'Agents')}:
          </GroupHeader>
        )}
        <SignatoreeList>
          {landlords.map(landlord => (
            <Signatoree key={`landlord-${landlord.id}`}>
              <SigneeAvatar
                location={getImageUrl(landlord.avatar.aws_s3_key, {
                  height: 50,
                  width: 50
                })}
                signed={hasUserSigned(landlord.id, signatures)}
                partialView={partialView}
                bottom={10}
                width={50}
                height={50}
                shadow
              />
              <SigneeName
                firstName={landlord.first_name}
                lastName={landlord.last_name}
                signed={hasUserSigned(landlord.id, signatures)}
                partialView={partialView}
              />
            </Signatoree>
          ))}
        </SignatoreeList>
      </div>

      <SectionSeparator />

      <div>
        <GroupHeader>
          {t('b.contract.view.tenants.text', 'Tenants')}:
        </GroupHeader>
        <SignatoreeList>
          {signingTenants.map(renter => (
            <Signatoree key={`signator-${renter.id}`}>
              <SigneeAvatar
                location={getImageUrl(renter.avatar.aws_s3_key, {
                  height: 50,
                  width: 50
                })}
                signed={hasUserSigned(renter.id, signatures)}
                partialView={partialView}
                bottom={10}
                width={50}
                height={50}
                shadow
              />
              <Signee>{t('b.contract.view.signing.text', 'Signing')}:</Signee>
              <SigneeName
                firstName={renter.first_name}
                lastName={renter.last_name}
                signed={hasUserSigned(renter.id, signatures)}
                partialView={partialView}
              />
            </Signatoree>
          ))}
          {nonSigningTenants.map(renter => (
            <Signatoree key={`signator-${renter.id}`}>
              <SigneeAvatar
                location={getImageUrl(renter.avatar.aws_s3_key, {
                  height: 50,
                  width: 50
                })}
                partialView={partialView}
                signed
                bottom={10}
                width={50}
                height={50}
                shadow
              />
              <Signee>
                {t('b.contract.view.non_signing.text', 'Non-Signing')}:
              </Signee>
              <SigneeName
                firstName={renter.first_name}
                lastName={renter.last_name}
                partialView={partialView}
                signed
              />
            </Signatoree>
          ))}
          {extraRenters.map(renter => (
            <Signatoree key={`extraRenter-${renter.id}`}>
              <SigneeAvatar
                partialView={partialView}
                signed
                bottom={10}
                width={50}
                height={50}
                shadow
              />
              <Signee>
                {renter.signatureRequired
                  ? t('b.contract.view.signing.text', 'Signing')
                  : t('b.contract.view.non_signing.text', 'Non-Signing')}
                :
              </Signee>
              <RoommateName partialView={partialView} signed>
                {renter.first_name} {renter.last_name}
              </RoommateName>
            </Signatoree>
          ))}
        </SignatoreeList>
      </div>
    </Section>
  )
}

Signatorees.defaultProps = {
  nonSigningTenants: [],
  extraRenters: [],
  signatures: [],
  partialView: false
}

export default Signatorees
