import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import useRole from 'hooks/use-role'
import {
  breakpoints,
  structure,
  colors,
  fontSizes,
  fontWeights,
  shadows
} from 'styles'
import ContentWrapper from 'components/content-wrapper'
import ProfileRequired from 'components/profile-required'
import { NavLink, Route, Switch } from 'react-router-dom'
import PageTitle from 'components/page-title'
import useLivScore from 'hooks/use-liv-score'
import useProfile from 'hooks/use-profile'
import TenantBasicInfo from 'features/Profile/TenantBasicInfo'
import LivScore from 'features/Profile/LivScore'
import { EmploymentInfo } from 'features/Profile/EmploymentInfo'
import { PetsFullList } from 'features/Profile/Pets'
import { LandlordReferencesFullList } from 'features/Profile/LandlordReferences'
import { EmploymentReferencesFullList } from 'features/Profile/EmploymentReferences'
import TenantSummary from 'features/Profile/TenantSummary'
import { EmergencyContactsFullList } from 'features/Profile/EmergencyContacts'
import { RoommatesFullList } from 'features/Profile/Roommates'
import { InsuranceFullView } from 'features/Profile/InsuranceInfo'
import { AutomobileFullList } from 'features/Profile/Automobile'
import VerifyIdentity from 'features/Profile/VerifyIdentity'
import TextButton from 'components/button/text-button'
import Icon from 'components/icon'
import { showTenantProfileAsLandlord } from 'features/Profile/TenantProfileModal'

const Wrapper = styled.div`
  padding-bottom: 180px;
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: ${structure.header.height}px;
  left: 0;
  right: 0;
  background-color: ${colors.white};
  box-shadow: ${shadows.header};
  z-index: 2;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
    margin-left: 0;
  }
`

const TabsWrapper = styled.div`
  border-top: 1px solid ${colors.grey};
`

const TabsContainer = styled(ContentWrapper)`
  width: 100%;
  display: flex;
  flex: 1;
`

const ProfileTab = styled(NavLink)`
  height: 50px;
  color: ${colors.regular};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom-color: ${colors.secondary};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
`

const ProfileButton = styled(TextButton)`
  margin-top: 0;
  padding: 5px 0;
  text-align: left;
  max-width: 200px;
`

const BackButton = styled(ProfileButton)`
  display: flex;
  position: absolute;
  left: 100px;
  top: 30px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${colors.secondary};
  > svg {
    padding-top: 3px;
    margin-right: 10px;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    left: 50px;
    top: 20px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    left: 20px;
    top: 10px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const TenantProfile = ({ match: { params } }) => {
  const { t } = useTranslation()
  const data = useProfile()
  const userRole = useRole()
  const livScore = useLivScore()

  if (!data.id) return null

  const handleViewProfile = () => {
    showTenantProfileAsLandlord({ data })
  }

  return (
    <Wrapper sticky mode={params.mode}>
      <Helmet>
        <title>{t('r.profile.view.my_profile.title', 'My Profile')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('r.profile.view.my_profile.title', 'My Profile')}
      </PageTitle>
      <HeaderContainer>
        <BackButton as={NavLink} to="/profile">
          <Icon id="back_arrow" width={25} height={25} /> Back
        </BackButton>
        <TenantSummary editable data={data} isHeader>
          <ProfileButton onClick={handleViewProfile}>
            {t(
              'r.profile.view.profile_as_landlord.button',
              'View profile as landlord'
            )}
          </ProfileButton>
        </TenantSummary>
        <TabsWrapper>
          <TabsContainer>
            <ProfileTab exact to={'/profile/basic-info'}>
              {t('r.profile.edit.basic_info.tab', 'Basic Info')}
              <ProfileRequired
                fields={['dob', 'about_me', 'current_address']}
                area="basic_info"
              />
            </ProfileTab>
            <ProfileTab exact to={'/profile/liv-score'}>
              {t('r.profile.edit.liv_score.tab', 'Liv Score')}
              <ProfileRequired area="liv_score" />
            </ProfileTab>
            <ProfileTab exact to={'/profile/references'}>
              {t('r.profile.edit.references.tab', 'References')}
              <ProfileRequired area="references" />
            </ProfileTab>
            <ProfileTab exact to={'/profile/extra-info'}>
              {t('r.profile.edit.extra_info.tab', 'Extra Info')}
            </ProfileTab>
          </TabsContainer>
        </TabsWrapper>
      </HeaderContainer>
      <Content>
        <ContentWrapper>
          <Switch>
            <Route
              exact
              path={'/profile/basic-info'}
              render={props => (
                <>
                  <TenantBasicInfo showSummary={false} data={data} editable />
                  <PetsFullList
                    isChecked={data.pet_owner === '0'}
                    anchorTop={-300}
                  />
                  <EmergencyContactsFullList anchorTop={-300} />
                </>
              )}
            />
            <Route
              exact
              path={'/profile/liv-score'}
              render={props => (
                <>
                  <LivScore
                    data={data}
                    livScore={livScore}
                    userRole={userRole}
                    editable
                  />
                  <EmploymentInfo data={data} editable />
                  <VerifyIdentity data={data} />
                </>
              )}
            />
            <Route
              exact
              path={'/profile/references'}
              render={props => (
                <>
                  <EmploymentReferencesFullList anchorTop={-300} />
                  <LandlordReferencesFullList
                    anchorTop={-300}
                    isChecked={data.first_time_renter === '1'}
                  />
                </>
              )}
            />
            <Route
              exact
              path={'/profile/extra-info'}
              render={props => (
                <>
                  <RoommatesFullList anchorTop={-300} />
                  <InsuranceFullView
                    anchorTop={-300}
                    data={data.files.user_secure_files}
                  />
                  <AutomobileFullList />
                </>
              )}
            />
          </Switch>
        </ContentWrapper>
      </Content>
    </Wrapper>
  )
}

export default TenantProfile
