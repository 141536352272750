import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'
import { toUnit } from 'utils/styles'

const ButtonElem = styled.button`
  outline: none;
  background: none;
  border: none;
  color: ${props => props.color || colors.secondary};
  font-size: ${props => toUnit(props.fontSize) || fontSizes.regular};
  padding: 0;

  &:not(:disabled):not([disabled]) {
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.3;
  }
`

const Button = ({ onClick, children, ...rest }) => (
  <ButtonElem
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
    role="button"
    {...rest}
  >
    {children}
  </ButtonElem>
)

Button.defaultProps = {
  children: 'Button',
  className: '',
  disabled: false
}

export default Button
