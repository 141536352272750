import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { colors, fontSizes, fontWeights } from 'styles'
import {
  Box,
  ShadowedBox,
  DateText,
  TimeText,
  BoldText,
  DateWrapper
} from './elements'

const EventContainer = styled.div`
  background-color: ${props => props.color || colors.secondary};
  width: 88px;
  height: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const OldTimeText = styled(TimeText)`
  font-size: 11px;
  line-height: 12px;
  margin-top: 4px;
  text-decoration: line-through;
  font-weight: ${fontWeights.regular};
`

const ShadowedBoxWrapper = styled.div`
  min-width: 88px;
  min-height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`
const BlueBox = styled.div`
  width: 88px;
  min-height: 20px;
  background-color: ${colors.babyBlue};
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 2;
`

const NewTimeText = styled(TimeText)`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.small};
  line-height: 12px;
  margin: auto;
`
const ShowingDelayedIcon = props => {
  const oldTime = moment(props.info.appointment_original_meeting_at).format(
    'h:mm a'
  )
  const month = moment(props.info.appointment_next_meeting_at).format('MMM')
  const day = moment(props.info.appointment_next_meeting_at).format('DD')
  const time = moment(props.info.appointment_next_meeting_at).format('h:mm a')
  return (
    <EventContainer color={props.color}>
      <ShadowedBoxWrapper>
        <ShadowedBox height={80} width={80}>
          <Box height={55} color={props.color || colors.secondary} top={5}>
            <DateWrapper>
              <DateText>{month}</DateText>
              <BoldText>{day}</BoldText>
            </DateWrapper>
          </Box>
          <OldTimeText color={props.color}>{oldTime}</OldTimeText>
        </ShadowedBox>
      </ShadowedBoxWrapper>
      <BlueBox>
        <NewTimeText color={colors.white}>{time}</NewTimeText>
      </BlueBox>
    </EventContainer>
  )
}

export default ShowingDelayedIcon
