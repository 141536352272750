import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { pick, map, isEmpty } from 'ramda'
import { colors, breakpoints, structure } from 'styles'
import formatName from 'utils/format-name'
import { toUnit } from 'utils/styles'
import { prefixUnitNumber, renderListingPrice } from 'utils/listings'
import useMediaQuery from 'hooks/use-media-query'
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  StyledTableCell,
  SingleTableRow,
  Accordion,
  AccordionContent,
  AccordionTableRow,
  AccordionHeaderText,
  AccordionHeader,
  TableHeaderText,
  MobileRowWrapper,
  MobileUnitText,
  MobileUnitDetails,
  CellText
} from 'components/listings-table'
import { EmptyListComponent } from 'components/table-list'
import PageTitle from 'components/page-title'
import CompanyListingsHeader from './company-listings-header'

const Wrapper = styled.div`
  min-height: calc(100vh - ${toUnit(structure.header.height)});
  background-color: ${colors.white};
  padding: 0 0 120px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
  }
`

const TableWrapper = styled.div`
  max-width: 1130px;
  margin: 0 100px 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px 0;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 0;
  }
`

const LandlordText = styled.span`
  display: block;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: inline;
  }
`

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 5px;
  }
`

const ListingTag = styled.p`
  display: inline-block;
  background: ${colors.mediumGrey};
  border-radius: 2px;
  padding: 6px 20px;
  width: fit-content;
  color: ${colors.white};
  font-size: 14px;
  line-height: 19px;
  margin: 0;

  :not(:last-child) {
    margin-right: 5px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
  }
`

const getLandlordsNames = listing =>
  listing.listing_landlords.map(landlord => {
    return <LandlordText key={landlord.id}>{formatName(landlord)}</LandlordText>
  })

const getMobileLandlordsNames = landlords => {
  const landlordNames = landlords.listing_landlords.map(landlord =>
    formatName(landlord)
  )
  return landlordNames.join(', ')
}

const CompanyListings = ({
  buildings = [],
  pageTitle,
  onRowClick,
  buttonComponent,
  mobileButtonComponent
}) => {
  const { t } = useTranslation()
  const displayTablet = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )
  const [currentTab, setCurrentTab] = useState('published')
  const [filteredData, setFilteredData] = useState([])
  const [tabFilteredData, setTabFilteredData] = useState([])
  const [tabCount, setTabCount] = useState({
    rented: 0,
    published: 0,
    draft: 0
  })
  const [expanded, setExpanded] = useState(true)
  const MobileButtonComponent = mobileButtonComponent

  // Fetch data from BE
  useEffect(() => {
    if (isEmpty(buildings)) return
    setFilteredData(buildings)
  }, [buildings])

  // Set filtered tab data
  useEffect(() => {
    if (isEmpty(filteredData)) return
    setTabFilteredData(filteredData[currentTab])
  }, [currentTab, filteredData])

  // Calculate count for each tab
  useEffect(() => {
    const countListingsInTab = tab => {
      let listings = 0
      tab.forEach(building => {
        listings += building.listings.length
      })
      return listings
    }
    if (isEmpty(filteredData)) return
    const countAllTabs = map(countListingsInTab, filteredData)
    setTabCount(countAllTabs)
  }, [filteredData])

  // Handle search
  const searchListings = searchString => {
    if (!searchString) setFilteredData(buildings)
    const filterTabData = tab => {
      return tab
        .map(building => {
          const buildingSearchFields = pick(
            [
              'full_street_name',
              'street_name',
              'street_number',
              'city',
              'state',
              'zip',
              'name'
            ],
            building
          )
          const buildingSearch = JSON.stringify(
            buildingSearchFields
          ).toLowerCase()
          return {
            ...building,
            listings: building.listings.filter(listing => {
              const listingSearchFields = pick(['unit_number'], listing)
              return (
                JSON.stringify(listingSearchFields)
                  .toLowerCase()
                  .includes(searchString.trim().toLowerCase()) ||
                buildingSearch.includes(searchString.trim().toLowerCase())
              )
            })
          }
        })
        .filter(building => building.listings && building.listings.length > 0)
    }
    const filtered = map(filterTabData, buildings)
    setFilteredData(filtered)
  }

  const getBuildingTitle = (building, count) => {
    const { name, full_street_name, city, state } = building
    return (
      <AccordionHeaderText>
        <span>
          {name || full_street_name} ({count})
        </span>
        {`${full_street_name}, ${city}${state === null ? '' : `, ${state}`}`}
      </AccordionHeaderText>
    )
  }

  const getListingAddress = (listing, building) => {
    const { unit_number } = listing
    const { full_street_name, city, state } = building
    const unitNumber = unit_number ? `${prefixUnitNumber(unit_number)} - ` : ''
    const fullAddress = `${full_street_name}, ${city}, ${state}`
    return (
      <strong>
        {unitNumber}
        {fullAddress}
      </strong>
    )
  }

  const getUnitNumber = listing => {
    const { unit_number, unit_type_txt_id } = listing
    if (unit_number) return prefixUnitNumber(unit_number)
    if (unit_type_txt_id === 'house') return t('common.house', 'House')
    if (unit_type_txt_id === 'townhouse')
      return t('common.townhouse', 'Townhouse')
    return '-'
  }

  const renderListingTags = listing => {
    const isHidden = listing.is_hidden === '1'
    const isUpcoming = listing.state_machine === 'upcoming'

    return (
      <>
        {isHidden && currentTab === 'published' && (
          <ListingTag>{t('l.listing.list.hidden.text', 'Hidden')}</ListingTag>
        )}
        {isUpcoming && currentTab === 'rented' && (
          <ListingTag>
            {t('l.listing.list.upcoming.text', 'Upcoming')}
          </ListingTag>
        )}
      </>
    )
  }

  const MobileListingRow = ({
    listing,
    building,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <MobileRowWrapper indent={groupedListing}>
          <MobileUnitText>
            {singleListing
              ? getListingAddress(listing, building)
              : getUnitNumber(listing)}
          </MobileUnitText>
          <MobileUnitDetails>
            <p>{renderListingPrice(listing)}</p>
            <p>{getMobileLandlordsNames(listing)}</p>
          </MobileUnitDetails>
        </MobileRowWrapper>
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.profile.view.edit.button', 'Edit')}</CellText>
      </StyledTableCell>
    </>
  )

  const ListingRow = ({
    listing,
    building,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <CellText firstCell indent={groupedListing}>
          {singleListing
            ? getListingAddress(listing, building)
            : getUnitNumber(listing)}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>{renderListingPrice(listing)}</strong>
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText top={20} bottom={20}>
          {getLandlordsNames(listing)}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <TagWrapper>{renderListingTags(listing)}</TagWrapper>
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.profile.view.edit.button', 'Edit')}</CellText>
      </StyledTableCell>
    </>
  )

  return (
    <Wrapper>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{pageTitle}</PageTitle>
      <CompanyListingsHeader
        pageTitle={pageTitle}
        tabCount={tabCount}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        onSearch={searchListings}
        buttonComponent={buttonComponent}
      />
      <TableWrapper>
        <Table>
          {!displayTablet && (
            <TableHeader>
              <TableRow sticky top={structure.header.height + 157}>
                <TableHeaderCell>
                  <TableHeaderText firstCell>
                    {t('common.unit', 'Unit')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('common.price', 'Price')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t(
                      'l.profile.list.manage_listings_managing_lanlords.label',
                      'Managing Landlords'
                    )}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>&nbsp;</TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText
                    lastCell
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded
                      ? t('l.listing.list.collapse_all.text', 'Collapse All')
                      : t('l.listing.list.expand_all.text', 'Expand All')}
                  </TableHeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {tabFilteredData?.length > 0 ? (
              tabFilteredData.map((building, index) => {
                const listingCount = building.listings.length

                if (listingCount === 1) {
                  const listing = building.listings[0]
                  return (
                    <SingleTableRow
                      key={listing.listing_id}
                      onClick={() => onRowClick(listing.listing_id)}
                    >
                      {displayTablet ? (
                        <MobileListingRow
                          listing={listing}
                          building={building}
                          singleListing
                        />
                      ) : (
                        <ListingRow
                          listing={listing}
                          building={building}
                          singleListing
                        />
                      )}
                    </SingleTableRow>
                  )
                }

                return (
                  <Accordion key={building.id} expanded={expanded}>
                    <AccordionHeader colSpan={5}>
                      {getBuildingTitle(building, listingCount)}
                    </AccordionHeader>
                    <AccordionContent>
                      {building.listings.map((listing, i) => {
                        const isLastRow = i + 1 === listingCount

                        return (
                          <AccordionTableRow
                            key={listing.listing_id}
                            onClick={() => onRowClick(listing.listing_id)}
                          >
                            {displayTablet ? (
                              <MobileListingRow
                                listing={listing}
                                building={building}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            ) : (
                              <ListingRow
                                listing={listing}
                                building={building}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            )}
                          </AccordionTableRow>
                        )
                      })}
                    </AccordionContent>
                  </Accordion>
                )
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={5}>
                  <EmptyListComponent />
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      {mobileButtonComponent && <MobileButtonComponent />}
    </Wrapper>
  )
}

export default React.memo(CompanyListings)
