import React from 'react'
import { useTranslation } from 'react-i18next'
import { SectionHeader } from 'components/headings'
import Section from 'components/section'
import RentInfo from './rent-info'
import SecurityDeposit from './security-deposit'
import LeaseTerms from './lease-terms'
import StrataInfo from './strata-info'
import PetPolicy from './pet-policy'
import ParkingInfo from './parking-info'
import StorageInfo from './storage-info'
import ExtraFees from './extra-fees'

const BasicTerms = ({ contract }) => {
  const { t } = useTranslation()

  return (
    <Section>
      <SectionHeader>
        {t('b.contract.view.basic_terms.subtitle', 'Basic Terms')}
      </SectionHeader>
      <RentInfo
        price={contract.price}
        priceFrequency={contract.price_frequency}
        extraOffer={contract.extra_offer}
        extraOfferFrequency={contract.extra_offer_frequency}
      />
      <SecurityDeposit securityDeposit={contract.security_deposit} />
      <LeaseTerms
        leaseType={contract.leaseType}
        leaseEndAction={contract.lease_end_action}
        startDate={contract.tenancyStartDate}
        endDate={contract.tenancyEndDate}
      />
      <StrataInfo
        lotNumber={contract.strata_lot_number}
        planNumber={contract.strata_plan_number}
      />
      <PetPolicy
        petPolicy={contract.pet_policy}
        petDeposit={contract.pet_deposit}
        allowCats={contract.allow_cats}
        allowDogs={contract.allow_dogs}
      />
      <ParkingInfo parkingFee={contract.parking_fee} />
      <StorageInfo storageFee={contract.storage_fee} />
      <ExtraFees miscFees={contract.additional_fees} />
    </Section>
  )
}

export default BasicTerms
