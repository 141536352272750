import styled from 'styled-components'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Row, Group } from 'components/form'

export const Subtitle = styled.h3`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin-top: ${props => props.top || 0}px;
  margin-bottom: 20px;
`

export const StyledRow = styled(Row)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

export const StyledGroup = styled(Group)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`
