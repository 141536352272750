import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import config from 'config'
import { Input } from 'components/form'
import {
  StyledPanel,
  AreaTitle,
  StyledRow,
  StyledLabel,
  StyledValue,
  Link
} from '../elements'

const { WEB_URL } = config

const PaymentDetails = ({
  values: {
    payment_method,
    first_name,
    last_name,
    card_number,
    expiration_date,
    security_code
  },
  acceptedTerms,
  setAcceptedTerms
}) => {
  const { t } = useTranslation()

  return (
    <>
      <AreaTitle>
        {t('r.payment.edit.make_payment_card_details.subtitle', 'Card Details')}
      </AreaTitle>
      <StyledPanel hSpace={30} vSpace={50}>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_first_name.label', 'First name')}
          </StyledLabel>
          <StyledValue>{first_name}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_last_name.label', 'Last name')}
          </StyledLabel>
          <StyledValue>{last_name}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_card_number.label', 'Card number')}
          </StyledLabel>
          <StyledValue>{card_number.replace(/\d(?=\d{4})/g, '*')}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t(
              'r.payment.edit.make_payment_expiration_Date.label',
              'Exp. date (MMYY)'
            )}
          </StyledLabel>
          <StyledValue>{expiration_date}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel bottom={10}>
            {t(
              'r.payment.edit.make_payment_security_code.label',
              'Security Code'
            )}
          </StyledLabel>
          <StyledValue bottom="0">
            {security_code.replace(/./g, '*')}
          </StyledValue>
        </StyledRow>
        {payment_method === 'upop' && (
          <StyledRow bottom="0" paddingTop={20} borderTop>
            <Trans i18nKey="b.settings.edit.read_and_agree_to_terms.label">
              <StyledLabel bold>
                I have read and agree to the{' '}
                <Link
                  href={`${WEB_URL}/terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions.
                </Link>
              </StyledLabel>
            </Trans>
            <Input
              data-cy="agree_to_terms"
              type="checkbox"
              checked={acceptedTerms}
              onChange={e => setAcceptedTerms(e.target.checked)}
            />
          </StyledRow>
        )}
      </StyledPanel>
    </>
  )
}

export default PaymentDetails
