import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import Highlighter from 'react-highlight-words'
import { getImageUrl } from 'utils/image'
import { useTranslation } from 'react-i18next'
import Context from './context'

const Wrapper = styled.div`
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${colors.grey};

  &:hover {
    opacity: 0.5;
    background: ${colors.lightGrey};
  }
`

const Name = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  letter-spacing: -0.38px;
  line-height: 24px;
  margin: 0;
`
const Address = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;
`

const BuildingImage = styled.img`
  width: 112px;
  height: 112px;
  object-fit: cover;
`

const markStyle = {
  background: 'none',
  color: colors.secondary
}

const Option = ({ data, innerProps }) => {
  const { t } = useTranslation()
  const { searchText, defaultImage } = useContext(Context)
  const image = data.image?.aws_s3_key
    ? getImageUrl(data.image.aws_s3_key, { width: 112, height: 122 })
    : defaultImage
  const line2 = `${data.city}, ${data.state}, ${data.zip}`
  const highlight = searchText.split(' ')
  return (
    <Wrapper {...innerProps} key={data.value} data-testid={`option-${data.id}`}>
      {data.isNew ? (
        t('b.listing.create.create_new_building.text', 'Create new building')
      ) : (
        <div>
          <Name>
            <Highlighter
              highlightStyle={markStyle}
              searchWords={highlight}
              autoEscape
              textToHighlight={data.name}
            />
          </Name>
          <Address>
            <Highlighter
              highlightStyle={markStyle}
              searchWords={highlight}
              autoEscape
              textToHighlight={data.full_street_name}
            />
          </Address>
          <Address>
            <Highlighter
              highlightStyle={markStyle}
              searchWords={highlight}
              autoEscape
              textToHighlight={line2}
            />
          </Address>
        </div>
      )}
      <BuildingImage src={image} alt={data.name} />
    </Wrapper>
  )
}

export default React.memo(Option)
