import React from 'react'
import styled from 'styled-components'

const DashboardTitle = styled.div`
  flex: 1;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DashboardTitleText = styled.h1`
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`

const DashboardHeaderSubTitle = props => (
  <DashboardTitle>
    <DashboardTitleText id="header-subtitle">
      {props.children}
    </DashboardTitleText>
  </DashboardTitle>
)

export default DashboardHeaderSubTitle
