import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useRole from 'hooks/use-role'
import { colors } from 'styles'
import metadata from './metadata'
import TimeStamp from './timestamp'

const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: ${colors.mediumGrey};
  .spacer {
    margin: 0 3px;
  }
`

const DescriptionText = styled.p`
  font-size: 10px;
  color: ${colors.mediumGrey};
  text-align: center;
`

const SystemMessage = ({ isThem, event }) => {
  const { t } = useTranslation()
  const userRole = useRole()
  const data = metadata(isThem, event, t, userRole)
  return (
    <DescriptionWrapper>
      <TimeStamp isSystemMessage={true} isThem={isThem} event={event} />
      <span className="spacer">-</span>
      <DescriptionText>
        {data?.title || event?.event_info?.message}
      </DescriptionText>
    </DescriptionWrapper>
  )
}

export default SystemMessage
