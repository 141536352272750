import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, structure } from 'styles'
import { Helmet } from 'react-helmet'
import config from 'config'
import useRole from 'hooks/use-role'
import Content from 'components/content'
import { WhitePage } from 'components/page'
import PageTitle from 'components/page-title'
import { Button } from 'components/button'
import ContentWrapper from 'components/content-wrapper'

import ReviewDetailsContainer from '../review-details'
import ReviewSignatures from '../review-signatures'

const StyledContent = styled(Content)`
  padding-top: 50px;
`

const ContentHeader = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
  ${props =>
    props.sticky &&
    `
    padding-top: ${structure.header.height}px;

    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      padding-top: ${structure.header.heightMobile}px;
    }
  `}
`

const InnerContentHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1096px;
  margin: 0 auto;
  height: 85px;
  padding: 0 30px;
`

const Complete = ({ contract, user, sticky, noTitle, BackButtonComponent }) => {
  const { t } = useTranslation()
  const role = useRole()

  const getPdfUrl = contractId =>
    `${config.BACKEND_SERVER}/contracts/${contractId}/pdfDataDownload`
  return (
    <>
      <ContentHeader sticky={sticky}>
        <InnerContentHeader>
          {BackButtonComponent && <BackButtonComponent />}
          <Button
            as="a"
            theme="ghost"
            size="small"
            width={110}
            href={getPdfUrl(contract.id)}
            target="_blank"
            left="auto"
          >
            {t('l.contract.view.export.button', 'Export')}
          </Button>
        </InnerContentHeader>
      </ContentHeader>
      <WhitePage key="contract-view" bottom={120}>
        <ContentWrapper className="mb-50">
          <Helmet>
            <title>
              {t('b.contracts.view.contract_detail.title', 'Contract')}
            </title>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          {!noTitle && (
            <PageTitle>
              {t('b.contracts.view.contract_detail.title', 'Contract')}
            </PageTitle>
          )}
          <StyledContent>
            <ReviewDetailsContainer contract={contract} userType={role} />
            <ReviewSignatures
              contract={contract}
              userType={role}
              currentUser={user.id}
            />
          </StyledContent>
        </ContentWrapper>
      </WhitePage>
    </>
  )
}

Complete.defaultProps = {
  contract: {}
}

export default Complete
