import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { path } from 'ramda'
import { select } from 'store'
import moment from 'moment'
import styled from 'styled-components'
import { colors } from 'styles'
import { NavLink } from 'react-router-dom'
import { useFlag } from 'flags'
import ContentWrapper from 'components/content-wrapper'

const RowWrapper = styled.div`
  display: flex;
  color: ${colors.regular};
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 30px;
  margin: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.mediumLightGrey};
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  :hover {
    border-color: ${colors.secondary};
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.6;
    cursor: default;
  }
`

const LabelText = styled.p``

const StatusText = styled.p`
  color: ${colors.red};
`

const MainResources = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loadEndTenancyData = dispatch.endTenancy.loadAll
  const loadAllContracts = dispatch.contract.getContracts
  const showContractRenewal = useFlag(['features', 'contract', 'renew'])

  useEffect(() => {
    loadEndTenancyData({ listingId })
  }, [listingId, loadEndTenancyData])

  const endTenancyRequestData = useSelector(state =>
    select.endTenancy.endTenancyRequestData(state, { listingId })
  )

  useEffect(() => {
    loadAllContracts()
  }, [loadAllContracts])

  const nextContract = useSelector(state =>
    select.contract.nextContract(state, { listingId })
  )

  const lastActiveContract = useSelector(state =>
    select.contract.lastActiveContract(state, { listingId })
  )

  const leaseType = useSelector(
    path(['listing', listingId, 'contract', 'lease_type'])
  )

  const contractStateMachine = useSelector(
    path(['listing', listingId, 'contract', 'state_machine'])
  )

  const contractType = useSelector(
    path(['listing', listingId, 'contract', 'contract_type'])
  )

  const start_date = nextContract
    ? moment(nextContract?.end_date).add(1, 'days').format('YYYY-MM-DD')
    : moment(lastActiveContract?.end_date).add(1, 'days').format('YYYY-MM-DD')

  const end_date = moment(start_date).add(1, 'years').format('YYYY-MM-DD')

  const renewalContract = {
    ...(nextContract ? nextContract : lastActiveContract),
    start_date,
    end_date
  }

  const endTenancyDone =
    endTenancyRequestData?.state_machine === 'done' &&
    endTenancyRequestData?.end_response === 'accepted'

  const renewalStatus = () => {
    if (nextContract && nextContract.contract_type === 'extension') {
      const state = nextContract.state_machine
      if (state === 'draft')
        return t('l.rental_dashboard.edit.draft.label', 'Draft')
      if (state === 'sent')
        return t(
          'l.rental_dashboard.edit.renewal_in_progress.label',
          'Renewal In Progress'
        )
    }
    if (!showContractRenewal || endTenancyDone || leaseType !== 'fixed')
      return t('l.rental_dashboard.edit.not_available.label', 'Not Available')
    else if (contractStateMachine === 'active' && contractType === 'extension')
      return t(
        'l.rental_dashboard.edit.already_renewed.label',
        'Already Renewed'
      )
    else return ''
  }

  return (
    <ContentWrapper className="mb-50">
      <RowWrapper
        disabled={
          !showContractRenewal || endTenancyDone || leaseType !== 'fixed'
        }
        onClick={async () => {
          if (nextContract && nextContract.state_machine === 'draft') {
            await history.push(
              `/listing/${listingId}/contract/create/${nextContract.id}`
            )
          } else if (nextContract && nextContract.state_machine === 'sent') {
            await history.push(
              `/listing/${listingId}/contract/${nextContract.id}/sign`
            )
          } else {
            const { body, error } = await dispatch.contract.renewContract({
              contractId: renewalContract.id,
              ...renewalContract
            })
            if (!error) {
              history.push({
                pathname: `/listing/${listingId}/contract/create/${body.id}`
              })
            }
          }
        }}
      >
        <LabelText>
          {t(
            'l.rental_dashboard.edit.resources_renew_contract.text',
            'Renew Contract'
          )}
        </LabelText>
        <StatusText>{renewalStatus()}</StatusText>
      </RowWrapper>
      <RowWrapper
        as={NavLink}
        to={`/tenant/${listingId}/resources/contract-history`}
      >
        <LabelText>
          {t(
            'l.rental_dashboard.edit.resources_contract_history.text',
            'Contract History'
          )}
        </LabelText>
      </RowWrapper>
    </ContentWrapper>
  )
}

export default withRouter(MainResources)
