import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { colors, fontSizes } from 'styles'
import CLGiphy from './craigslist_post.gif'

const Title = styled.span`
  font-size: ${fontSizes.large};
  font-weight: bold;
  color: ${colors.regular};
  text-align: center;
`

const Description = styled.span`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  text-align: center;
  padding: 30px 0;
`

const Giphy = styled.img`
  width: 100%;
  margin-bottom: 50px;
`

const ShareByCraigslist = ({ onClose }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    onClose()
    window.open('https://post.craigslist.org/', '_blank')
  }

  return (
    <>
      <Title>
        {t(
          'l.listing.view.share_craigslist_modal.title',
          'Description copied!'
        )}
      </Title>
      <Description>
        {t(
          'l.listing.view.share_craigslist_modal_description.text',
          'Invite potential renters by sharing your listing and connecting them with liv.rent'
        )}
      </Description>
      <Giphy src={CLGiphy} />
      <Button
        type="submit"
        theme="primary"
        size="medium"
        width={160}
        onClick={handleClick}
      >
        {t(
          'l.listing.view.share_craigslist_modal_to_craigslist.button',
          'Go to Craigslist'
        )}
      </Button>
    </>
  )
}

export default React.memo(ShareByCraigslist)
