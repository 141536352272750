import {
  path,
  set,
  lensPath,
  pick,
  pipe,
  defaultTo,
  mergeDeepLeft,
  dissocPath
} from 'ramda'
import { getSession } from 'ports'
import { batch } from 'react-redux'
import { showCreatePasswordModal } from 'features/Auth/CreatePasswordModal'

const setGaUserId = userId => window.dataLayer.push({ userId })

const selectData = pick([
  'agreements',
  'session',
  'users',
  'companies',
  'company_users',
  'user_secure_files',
  'user_social_media_tokens'
])

class SessionError extends Error {
  constructor(data) {
    super()
    this.data = data
  }
}

const getNestedObjectKeys = (obj, pathToNestedObj) =>
  pipe(path(pathToNestedObj), Object.keys)(obj)

const patch = (original, at, payload) =>
  set(
    lensPath(at),
    pipe(path(at), defaultTo({}), mergeDeepLeft(payload))(original),
    original
  )

const session = {
  state: null,
  reducers: {
    update: (state, at, payload) => set(lensPath(at), payload, state),
    remove: (state, at) => dissocPath(at, state),
    patch,
    patchExisting: (state, at, payload) =>
      patch(state, at, pick(getNestedObjectKeys(state, at), payload)),
    set: (state, payload) => (state ? payload : { ...state, ...payload }),
    updateContract: (state, payload) =>
      state
        ? { ...state, contracts: { ...state.contracts, ...payload } }
        : null,
    updateSession: (state, payload) =>
      state ? { ...state, session: { ...state.session, ...payload } } : null,
    clearData: () => null
  },
  effects: dispatch => ({
    clear(payload = {}) {
      batch(() => {
        if (!payload.keepSession) {
          dispatch.session.clearData()
        }
        dispatch.profile.clear()
        dispatch.application.clear()
        dispatch.chat.clear()
        dispatch.supportRoom.clear()
        dispatch.contract.clear()
        dispatch.listing.clear()
        dispatch.contract.clearSteps()
        dispatch.companyManagement.clear()
        dispatch.settlements.clear()
      })
    },
    async loadSession() {
      try {
        const { response, body = {} } = await getSession()
        if (!response.ok) {
          throw new SessionError(body)
        }
        if (body.session) {
          localStorage.setItem('session-id', body.session.id)
          setGaUserId(body.session.id)
          batch(() => {
            dispatch.session.set(selectData(body))
            if (body.profile_progress?.break_down) {
              dispatch.profile.update(
                ['profile_progress'],
                body.profile_progress?.break_down
              )
            }
            if (body.session.registration_complete === '1') {
              dispatch.profile.load()
            }
          })
        }
      } catch (error) {
        if (error?.data?.password_setup_required) {
          showCreatePasswordModal()
        }
      }
    }
  }),
  selectors: {
    registrationPlan() {
      return path(['profile', 'company_info', 'registration_plan'])
    }
  }
}

export default session
