import styled from 'styled-components'

const Container = styled.td`
  ${props =>
    props.width &&
    `
    width: ${props.width}px;
  `}
  ${props =>
    props.align &&
    `
    text-align: ${props.align};
  `}
`

export default Container
