import React from 'react'

class CloseClickOutside extends React.Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false)
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClick, false)
  }
  handleClick(e) {
    const { ref } = this.props
    if (ref && ref.contains && ref.contains(e.target)) return true
    this.props.onClickOutside()
  }
  render() {
    return this.props.children
  }
}

export default CloseClickOutside
