import { batch } from 'react-redux'
import {
  always,
  append,
  defaultTo,
  dissocPath,
  evolve,
  lensPath,
  mergeDeepLeft,
  path,
  pipe,
  set,
  without,
  pick
} from 'ramda'
import { defaultIfFalsy, renameKeys } from 'utils'
import {
  updateBillingAddress,
  deleteBillingAddress,
  getBillingAddress,
  deleteStripePayout,
  updateUnionPayPayout,
  deleteUnionPayPayout,
  makePayment
} from './ports'

const formatUnionPay = data => {
  const formattedPersonal = pipe(
    pick([
      'first_name',
      'last_name',
      'email',
      'company_name',
      'phone_number',
      'street1',
      'street2',
      'city',
      'state',
      'country',
      'zip'
    ]),
    evolve({
      street2: defaultIfFalsy(undefined),
      company_name: defaultIfFalsy(undefined)
    })
  )(data)

  const formattedFiniancialInstitution =
    data.financial_institution_name === 'other'
      ? data.custom_financial_institution
      : data.financial_institution_name

  const formattedFinancial = pipe(
    pick([
      'financial_institution_name',
      'account_type',
      'account_number',
      'transit_number',
      'financial_institution_number',
      'bank_street1',
      'bank_street2',
      'bank_city',
      'bank_state',
      'bank_country',
      'bank_zip'
    ]),
    renameKeys({
      bank_street1: 'street1',
      bank_street2: 'street2',
      bank_city: 'city',
      bank_state: 'state',
      bank_country: 'country',
      bank_zip: 'zip'
    }),
    evolve({
      street2: defaultIfFalsy(undefined),
      financial_institution_name: always(formattedFiniancialInstitution)
    })
  )(data)

  const formattedSignature = path(['user_signature'], data)

  return {
    personal_info: formattedPersonal,
    financial_info: formattedFinancial,
    user_signature: formattedSignature
  }
}

const formatPayment = data =>
  data.payment_method === 'upop'
    ? pipe(
        pick([
          'payment_method',
          'card_number',
          'expiration_date',
          'security_code',
          'promo_code',
          'first_name',
          'last_name'
        ]),
        evolve({
          promo_code: defaultIfFalsy(undefined)
        })
      )(data)
    : pipe(
        pick([
          'payment_method',
          'card_number',
          'expiration_date',
          'security_code',
          'promo_code',
          'street1',
          'street2',
          'city',
          'state',
          'country',
          'zip',
          'first_name',
          'last_name',
          'save_billing_address'
        ]),
        evolve({
          street2: defaultIfFalsy(undefined),
          promo_code: defaultIfFalsy(undefined)
        })
      )(data)

const settlements = {
  state: {},
  reducers: {
    clear: () => ({}),
    patch: (state, at, payload) =>
      set(
        lensPath(at),
        pipe(path(at), defaultTo({}), mergeDeepLeft(payload))(state),
        state
      ),
    remove: (state, at) => dissocPath(at, state)
  },
  effects: dispatch => ({
    updateBillingAddress: async payload => {
      const data = {
        body: evolve({ street2: defaultIfFalsy(undefined) }, payload)
      }
      try {
        const { body, response } = await updateBillingAddress(data)
        if (response.ok) {
          batch(() => {
            dispatch.settlements.patch([], body)
            dispatch.profile.update(['has_billing_address'], true)
          })
        }
        return response
      } catch (error) {
        console.log('[settlements/updateBillingAddress]', error)
      }
    },
    getBillingAddress: async () => {
      try {
        const { body, response } = await getBillingAddress()
        if (response.ok) {
          dispatch.settlements.patch([], body)
        }
      } catch (error) {
        console.log('[settlements/getBillingAddress]', error)
      }
    },
    deleteBillingAddress: async () => {
      try {
        const { response } = await deleteBillingAddress()
        if (response.ok) {
          batch(() => {
            dispatch.settlements.remove(['billing_address'])
            dispatch.profile.update(['has_billing_address'], false)
          })
        }
        return response
      } catch (error) {
        console.log('[settlements/deleteBillingAddress]', error)
      }
    },
    deleteStripePayout: async (_, rootState) => {
      try {
        const { response } = await deleteStripePayout()
        if (response.ok) {
          const updatedAch = {
            ach_connected: without(
              'stripe',
              rootState.profile.company_info.ach_connected
            )
          }
          dispatch.profile.patch(['company_info'], updatedAch)
        }
        return response
      } catch (error) {
        console.log('[settlements/deleteStripePayout]', error)
      }
    },
    updateUnionPayPayout: async (payload, rootState) => {
      const formattedPayload = formatUnionPay(payload)
      try {
        const { response } = await updateUnionPayPayout({
          body: formattedPayload
        })
        if (response.ok) {
          const updatedAch = {
            ach_connected: append(
              'upop',
              rootState.profile.company_info?.ach_connected || []
            )
          }
          dispatch.profile.patch(['company_info'], updatedAch)
        }
        return response
      } catch (error) {
        console.log('[settlements/updateUnionPayPayout]', error)
      }
    },
    deleteUnionPayPayout: async (_, rootState) => {
      try {
        const { response } = await deleteUnionPayPayout()
        if (response.ok) {
          const updatedAch = {
            ach_connected: without(
              'upop',
              rootState.profile.company_info.ach_connected
            )
          }
          dispatch.profile.patch(['company_info'], updatedAch)
        }
        return response
      } catch (error) {
        console.log('[settlements/deleteUnionPayPayout]', error)
      }
    },
    makePayment: async ({ paymentRequestId, ...payload }) => {
      const params = {
        paymentRequestId
      }
      const data = {
        body: formatPayment(payload)
      }
      try {
        const { response, body } = await makePayment(data, params)
        if (response.ok) {
          return { response, body: body[paymentRequestId] }
        }
      } catch (error) {
        console.log('[settlements/getBillingAddress]', error)
      }
    }
  })
}

export default settlements
