import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, fontWeights } from 'styles'

const DashboardTitle = styled.div`
  flex: 1;
  min-width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    dispaly: none;
  }
`

const DashboardTitleText = styled.h1`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
`

const DashboardHeaderTitle = props => (
  <DashboardTitle>
    <DashboardTitleText id="header-title">{props.children}</DashboardTitleText>
  </DashboardTitle>
)

export default DashboardHeaderTitle
