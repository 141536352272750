import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import { Formik, Form } from 'formik'
import { Helmet } from 'react-helmet'
import { updateNotes, getNotes } from 'models/listing/ports'
import useMediaQuery from 'hooks/use-media-query'
import DashboardHeader from 'features/Listing/dashboard-header'
import { toast } from 'components/toast-notifications'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import { FieldTextarea } from 'components/form'
import { leftCloud } from 'components/clouds'

import backgroundBottom from './images/background-bottom.png'
import backgroundTopRight from './images/background-top-right.png'
import backgroundTopMobile from './images/background-top-mobile.png'

const Wrapper = styled.div`
  padding: 10px 40px 90px;
  min-height: 100%;
  background: ${colors.white};
  display: flex;
  justify-content: center;

  background-image: url(${leftCloud}), url(${backgroundTopRight}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -55px 50px, 100% 0px, 100% calc(100% - 90px);
  background-size: 238px, 200px, 280px;

  @media screen and (max-width: ${breakpoints.desktop}) {
    background-image: url(${leftCloud}), url(${backgroundTopMobile}),
      url(${backgroundBottom});
    background-position: -85px 190px, 100% 0px, 100% calc(100% - 90px);
    background-size: 238px, 80px, 170px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 10px 25px 90px;
    min-height: calc(100vh - 55px);
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px 10px 56px;

    background-position: -85px 190px, 100% 0px, 110% calc(100% - 70px);
    background-size: 238px, 80px, 170px;
  }
`

const ContentWrapper = styled.div`
  max-width: 700px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 110px;
`

const SectionHeading = styled.h1`
  font-size: 25px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 34px;
  margin: 0 0 30px;
  text-align: center;
`

const MobileButtonWrapper = styled.div`
  display: flex;
  height: 56px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.white};
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
`

const BackButton = styled.button`
  margin: 0;
  padding: 10px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  text-align: center;
  color: ${colors.secondary};
  background: ${colors.white};
  border: 2px solid ${colors.secondary};
  cursor: pointer;
  flex: 1;
`

const SubmitButton = styled(BackButton)`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  text-align: center;
  color: ${colors.white};
  background: ${colors.secondary};
  border: none;
`

const ListingNotes = ({
  history,
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [previousNote, setPreviousNote] = useState(null)

  useEffect(() => {
    const loadNote = async () => {
      const { body } = await getNotes({ listingId })
      setPreviousNote({ note: body.note })
    }
    loadNote()
  }, [listingId])

  const onSubmit = async (values, { setSubmitting }) => {
    const response = await updateNotes({ body: values }, { listingId })
    setSubmitting(false)
    if (response.response.ok) {
      toast(
        t(
          'l.listing.view.listing_dashboard_notes_saved.text',
          'Your private listing notes were successfully saved.'
        ),
        {
          title: t(
            'l.listing.view.listing_dashboard_notes_saved.title',
            'Listing Notes Saved'
          ),
          iconId: 'square_check',
          autoClose: 6000
        }
      )
    }
  }

  if (previousNote === null) return null

  const MobileButtons = () => (
    <MobileButtonWrapper>
      <BackButton onClick={history.goBack} type="button">
        {t('common.back', 'Back')}
      </BackButton>
      <SubmitButton type="submit">
        {t('l.notes.edit.save.button', 'Save')}
      </SubmitButton>
    </MobileButtonWrapper>
  )

  return (
    <>
      <Helmet>
        <title>
          {t('l.listing.view.listing_dashboard_notes.title', 'Notes')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <DashboardHeader
        listingId={listingId}
        currentPage={t('l.listing.view.listing_dashboard_notes.title', 'Notes')}
        height={55}
      />
      <Formik initialValues={previousNote} onSubmit={onSubmit}>
        {({ values, isSubmitting }) => (
          <Form style={{ flex: 1 }}>
            <Wrapper>
              <ContentWrapper>
                <SectionHeading>
                  {t(
                    'l.listing.view.listing_dashboard_my_private_notes.subtitle',
                    'My Private Notes'
                  )}
                </SectionHeading>
                <FieldTextarea
                  height="100%"
                  background={colors.lightGrey}
                  border={`1px solid ${colors.grey}`}
                  radius={3}
                  fontWeight={fontWeights.regular}
                  name="note"
                  placeholder={t(
                    'l.listing.view.listing_dashboard_notes.placeholder',
                    'Add note'
                  )}
                />
              </ContentWrapper>
            </Wrapper>
            {displayMobile ? (
              <MobileButtons />
            ) : (
              <ButtonBar sticky>
                <Button
                  theme="sane"
                  width={120}
                  type="button"
                  inversed
                  onClick={history.goBack}
                >
                  {t('common.back', 'Back')}
                </Button>
                <Button theme="primary" width={150} left="auto" type="submit">
                  {t('l.notes.edit.save.button', 'Save')}
                </Button>
              </ButtonBar>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ListingNotes
