import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { breakpoints } from 'styles'
import UserChatRoom from 'features/Chat/chat-room'
import EmptyChatRoom from 'features/Chat/chat-room/empty-chat-room'
import { useSelector, useDispatch } from 'react-redux'
import { select } from 'store'
import SupportChatRoom from './support-room'

const StyledEmptyRoom = styled(EmptyChatRoom)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-bottom: 0;
  }
`

const ChatRoom = ({
  match: {
    params: { timelineId }
  },
  handleBack
}) => {
  const dispatch = useDispatch()
  const timeline = useSelector(state =>
    select.chat.timeline(state, { timelineId })
  )

  const timelineEvents = useSelector(state =>
    select.chat.timelineEvents(state, { timelineId })
  )

  const timeline_room = timeline?.timeline_room
  const listing_id = timeline?.listing_id
  useEffect(() => {
    if (timeline_room) {
      dispatch.listing.getAppointments(listing_id)
    }
  }, [dispatch.listing, timeline_room, listing_id])

  if (!timelineId) {
    return <StyledEmptyRoom handleBack={handleBack} />
  }

  return timelineId === 'support-room' ? (
    <SupportChatRoom handleBack={handleBack} />
  ) : (
    <UserChatRoom
      handleBack={handleBack}
      timeline={timeline}
      timelineEvents={timelineEvents}
    />
  )
}

export default withRouter(React.memo(ChatRoom))
