import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { upperFirst } from 'lodash'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CustomIcon = styled(Icon)`
  flex-shrink: 0;
`

const Text = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 0 0 0 7px;
  word-break: break-word;
`

const Type = styled(Text)`
  margin: 0;
  font-weight: ${fontWeights.medium};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Amenity = ({ data, showType }) => {
  const { t } = useTranslation()

  const type = data.type || 'private'
  const types = data.types

  const renderTypes = () =>
    types ? (
      <Column>
        {Object.keys(types).map(amenity => (
          <Type key={amenity}>
            {types[amenity]}{' '}
            {t(`b.listing_features.view.${amenity}.label`, upperFirst(amenity))}
          </Type>
        ))}
      </Column>
    ) : (
      <Type>
        {t(`b.listing_features.view.${type}.label`, upperFirst(type))}
      </Type>
    )

  return (
    <Wrapper>
      <NameWrapper>
        {data.id && <CustomIcon id={data.id} width={24} height={24} />}
        <Text>{data.name}</Text>
      </NameWrapper>
      {showType && renderTypes()}
    </Wrapper>
  )
}

export default Amenity
