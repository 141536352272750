import React, { useState, useMemo } from 'react'
import {
  pipe,
  concat,
  path,
  prop,
  map,
  values,
  pick,
  partialRight,
  ifElse,
  __
} from 'ramda'
import { createSelector } from 'reselect'
import debounce from 'debounce-promise'
import { searchBuilding } from 'ports'
import { mergeSpec, headObj, isTruthy } from 'utils'
import { Dropdown } from 'components/form'

import Option from './option'
import Context from './context'
import buildingDefault from './building-default.png'
import houseDefault from './house-default.png'

const buildingSelector = createSelector(
  prop('buildings'),
  prop('extra_addresses'),
  path(['files', 'building']),
  (buildings, extras, files) =>
    pipe(
      values,
      concat(__, extras),
      map(
        pipe(
          pick([
            'id',
            'name',
            'street_name',
            'street_number',
            'street_address',
            'city',
            'state',
            'zip',
            'type',
            'street_name',
            'full_street_name'
          ]),
          mergeSpec({
            name: ifElse(
              pipe(prop('name'), isTruthy),
              prop('name'),
              prop('street_name')
            ),
            image: pipe(prop('id'), partialRight(prop, [files]), headObj),
            value: prop('full_street_name'),
            label: prop('full_street_name')
          })
        )
      )
    )(buildings)
)

const MAX_RESULTS_TO_ALLOW_CREATING = 5

const DropdownIndicator = () => null

const BuildingSearch = ({
  value,
  onChange,
  placeholder,
  buildingType,
  countryCode,
  hasError,
  onBlur
}) => {
  const [text, setText] = useState(value.full_street_name || '')
  const handleInputChange = e => {
    setText(e)
  }
  const getDefaultImage = () =>
    buildingType === 'condo' ? buildingDefault : houseDefault
  const onSearch = useMemo(
    () =>
      debounce(async (address, callback) => {
        const data = {
          address,
          building_type: buildingType,
          country_code: countryCode
        }
        const response = await searchBuilding({
          body: data
        })
        if (response.response.ok) {
          let result = buildingSelector(response.body)
          if (result.length < MAX_RESULTS_TO_ALLOW_CREATING) {
            result.push({
              id: null,
              building_name: '',
              full_street_name: address,
              street_name: address.replace(/^\s*([0-9]+)/, ''),
              street_number: address.match(/^\s*([0-9]+)/)?.[1] || '',
              value: address,
              label: address,
              isNew: true
            })
          }
          return callback(result)
        }
      }, 300),
    [buildingType, countryCode]
  )
  return (
    <Context.Provider
      value={{ searchText: text, defaultImage: getDefaultImage() }}
    >
      <Dropdown
        inputValue={text}
        id="building-search-input"
        data-testid="building-search-input"
        value={value}
        loadOptions={onSearch}
        noOptionsMessage={() => null}
        components={{ Option, DropdownIndicator }}
        onChange={onChange}
        onInputChange={handleInputChange}
        cacheOptions
        placeholder={placeholder}
        hasError={hasError}
        onBlur={onBlur}
      />
    </Context.Provider>
  )
}

export default React.memo(BuildingSearch)
