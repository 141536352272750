import React, { useState } from 'react'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { prop, path } from 'ramda'
import { SwitchToggle } from 'components/form'
import { confirm } from 'components/dialog'
import config from 'config'

const { LINKEDIN_CLIENT_ID, APP_PATH } = config

const LinkedInButton = ({ enabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const session = useSelector(prop('session')) || {}
  const userId = session.session?.id
  const linkedInId = path([userId, 'id'], session.user_social_media_tokens)
  const redirectUri = `${window.location.origin}${APP_PATH}/linkedin`
  const [checked, setChecked] = useState(enabled)

  const handleSuccess = data => {
    dispatch.profile.addLinkedIn({
      code: data.code,
      redirect_uri: redirectUri
    })
  }

  const handleFailure = error => {
    setChecked(false)
  }

  const handleDisconnect = () => {
    if (linkedInId) {
      dispatch.profile.removeLinkedIn({
        id: linkedInId
      })
    }
  }

  const confirmDisconnect = () => {
    return confirm(
      t('r.profile.edit.disconnect_linkedin.title', 'Disconnect Account'),
      t(
        'r.profile.edit.disconnect_linkedin.text',
        'By disconnecting, your Liv score will be affected. Are you sure you want to disconnect your account?'
      )
    ).then(async () => {
      await handleDisconnect()
    })
  }

  return (
    <LinkedIn
      scope={'r_liteprofile r_emailaddress'}
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      clientId={LINKEDIN_CLIENT_ID}
      className="linkedin"
      redirectUri={redirectUri}
      render={({ handleConnect }) => {
        const handleToggle = e => {
          if (e) {
            handleConnect()
            setChecked(true)
          } else if (linkedInId) {
            confirmDisconnect()
          }
        }
        return (
          <SwitchToggle
            enabled={checked}
            name="linkedin"
            onChange={handleToggle}
          />
        )
      }}
    />
  )
}

export default LinkedInButton
