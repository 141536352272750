import client from 'ports/api-client'
import { useQuery } from 'react-query'

const useMyListings = () => {
  const { data } = useQuery(
    'my-listings',
    async () => {
      return client.get('/listings')
    },
    { staleTime: 30000 }
  )
  return data
}

export default useMyListings
