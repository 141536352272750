import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Lottie from 'react-lottie'
import animationData from './loading.json'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9200;
`

const mapState = state => ({
  semaphore: state.loading.semaphore
})

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Loading = ({ semaphore }) =>
  semaphore > 0 ? (
    <LoadingWrapper>
      <Lottie options={lottieOptions} height={100} width={100} />
    </LoadingWrapper>
  ) : null

Loading.defaultProps = {
  semaphore: 0
}

const LoadingContainer = connect(mapState)(Loading)

export default LoadingContainer
