import React from 'react'
import { useTranslation } from 'react-i18next'
import MenuCard from 'components/menu-card'

const TenantMenuCards = ({ contract, unreadCount, paymentsLinkDisabled }) => {
  const { t } = useTranslation()
  const listingId = contract.listing_id

  const menuItems = [
    {
      title: t('l.tenants.view.chats.label', 'Chats'),
      description: t(
        'l.tenants.view.chats.text',
        'Reply to tenant messages from this listing'
      ),
      iconId: 'chat',
      to: `/tenant/${listingId}/chat`,
      count: unreadCount
    },
    {
      title: t('l.tenants.view.payments.label', 'Payments'),
      description: t(
        'l.tenants.view.payments.text',
        'Request and receive digital payments'
      ),
      iconId: 'payments',
      to: `/tenant/${listingId}/payments`,
      disabled: paymentsLinkDisabled
    },
    {
      title: t('l.tenants.view.contracts.label', 'Contracts'),
      description: t(
        'l.tenants.view.contracts.text',
        'Access contract history & renew contract'
      ),
      iconId: 'contract',
      to: `/tenant/${listingId}/contract/${contract?.listing_contract_id}`
    },
    {
      title: t('l.tenants.view.other_resources.label', 'Other Resources'),
      description: t(
        'l.tenants.view.other_resources.text',
        'Additional rental documents'
      ),
      iconId: 'resources',
      to: `/tenant/${listingId}/resources`
    }
  ]

  return (
    <>
      {menuItems.map((item, index) => (
        <MenuCard key={`tenant-card-${index}`} item={item} />
      ))}
    </>
  )
}

export default TenantMenuCards
