import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontSizes, fontWeights, breakpoints } from 'styles'
import { useDispatch } from 'react-redux'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Formik, Form } from 'formik'
import noop from 'lodash/noop'
import { omit } from 'ramda'
import moment from 'moment'
import {
  Row,
  FieldInput,
  PhoneInput,
  FieldDatePicker,
  Group
} from 'components/form'
import { Button } from 'components/button'
import Panel from 'components/panel'
import * as Yup from 'yup'
import { headObj } from 'utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const StyledForm = styled(Form)`
  overflow-y: auto;
`

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const AddButton = styled(Button)`
  margin: 20px auto 0;
  flex-shrink: 0;
`

const AddNewTenantModal = ({
  listingId,
  onAdd = noop,
  isExtraUser = false
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const formOptions = () => ({
    initialValues: {
      listing_id: listingId,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: null,
      dob: '',
      id: Date.now()
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      last_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      email: isExtraUser
        ? Yup.string().email()
        : Yup.string().email().required(),
      phone_number: isExtraUser
        ? Yup.string().phone()
        : Yup.string().phone().required(),
      dob: Yup.date().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (isExtraUser) {
        onAdd(values)
      } else {
        const { error, body } = await dispatch.application.addExternal(
          omit(['id'], values)
        )
        setSubmitting(false)
        if (!error) {
          onAdd(headObj(body.users))
        }
      }
      close()
    }
  })

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <Title>
        {t('l.listing.contract.parties.add_new_tenant.title', 'Add New Tenant')}
      </Title>
      <Formik {...formOptions()}>
        {({ values, handleChange, errors }) => (
          <StyledForm>
            <StyledPanel hSpace={30} vSpace={30}>
              <Row gutter={0}>
                <Group col="12">
                  <FieldInput
                    name="first_name"
                    autoFocus
                    placeholder={
                      t(
                        'r.profile.edit.legal_first_and_middle_name.label',
                        'Legal First & Middle Name'
                      ) + '*:'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={0}>
                <Group col="12">
                  <FieldInput
                    name="last_name"
                    placeholder={
                      t(
                        'r.profile.edit.legal_last_name.label',
                        'Legal Last Name'
                      ) + '*:'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={0}>
                <Group col="12">
                  <PhoneInput
                    name="phone_number"
                    value={values.phone_number}
                    onChange={handleChange('phone_number')}
                    placeholder={
                      t('r.profile.edit.phone_number.label', 'Phone Number') +
                      (isExtraUser ? '' : '*') +
                      ':'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={0}>
                <Group col="12">
                  <FieldInput
                    name="email"
                    placeholder={
                      t('r.profile.edit.email.label', 'Email') +
                      (isExtraUser ? '' : '*') +
                      ':'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={0}>
                <Group col="12">
                  <FieldDatePicker
                    name="dob"
                    initialFocusedDate={moment().subtract(18, 'years')}
                    placeholder={
                      t('r.profile.edit.dob.label', 'Birthday') + ':'
                    }
                    minDate={moment().subtract(100, 'years')}
                    displayFormat="YYYY-MM-DD"
                    disableFuture
                  />
                </Group>
              </Row>
              <Row>
                <AddButton theme="primary" width={150} type="submit">
                  {t('b.listing.edit.add.button', 'Add')}
                </AddButton>
              </Row>
            </StyledPanel>
          </StyledForm>
        )}
      </Formik>
    </Wrapper>
  )
}

export const showAddNewTenantModal = createModal(AddNewTenantModal)
