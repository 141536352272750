import React, { useState } from 'react'
import styled from 'styled-components'
import { showImageModal } from 'components/image-modal'
import { colors } from 'styles'
import { getImageUrl } from 'utils/image'
import Loading from 'components/loading-circle'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: ${colors.secondary};
  > div {
    transition: 'all 0s ease-out 0s';
  }
`

const StyledLoading = styled(Loading)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  filter: invert(100%);
`

const Image = styled.img`
  width: 230px;
  height: 300px;
  object-fit: cover;
`

const FileImage = React.memo(({ event }) => {
  const [loaded, setLoaded] = useState(false)
  const image = getImageUrl(event.event_info.file_info.aws_s3_key, {
    width: 230,
    height: 300
  })

  const onLoad = () => setLoaded(true)

  return (
    <Wrapper
      onClick={() =>
        showImageModal({
          src: getImageUrl(event.event_info.file_info.aws_s3_key)
        })
      }
    >
      <Image
        ref={img => {
          if (img && img.complete) {
            onLoad()
          }
        }}
        onLoad={onLoad}
        src={image}
      />
      {!loaded && <StyledLoading />}
    </Wrapper>
  )
})

export default FileImage
