import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontWeights } from 'styles'
import noop from 'lodash/noop'
import { createModal, useModalize } from 'components/modalize'
import { Button as AnchorButton } from 'components/anchor'
import Icon from 'components/icon'
import { PhoneCode } from 'components/code'
import { formatPhone } from 'utils/format-phone'
import { show as showSuccessModal } from 'components/success-modal'
import { changePhoneConfirmCode } from 'models/requests/ports'
import { useDispatch } from 'react-redux'
import config from 'config'

const { WEB_URL, APP_PATH } = config

const URL = `${WEB_URL}${APP_PATH}`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;
  padding: 25px 25px 0 0;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ResendButton = styled(AnchorButton)`
  display: inline-block;
  margin-bottom: 20px;
  font-weight: ${fontWeights.regular};
  margin-top: 20px;
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Code = styled(PhoneCode)`
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  margin: 0;
`

const ChangePhoneNumberModal = ({ phone, resend, onClose = noop }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { close } = useModalize()

  const logoutAndRedirect = async () => {
    await dispatch.auth.logout()
    window.location.assign(URL)
  }

  const onSubmit = async values => {
    const { response } = await changePhoneConfirmCode(values)
    if (response.ok) {
      return showSuccessModal({
        title: t(
          'b.settings.edit.change_phone_number_success.title',
          'Phone Number Changed'
        ),
        message: t(
          'b.settings.edit.change_phone_number_success.text',
          'Congratulations! You have successfully changed your phone number. You will be prompted to log back in using your new number.'
        ),
        onClose: () => logoutAndRedirect()
      })
    }
  }

  const handleClose = () => {
    close()
    onClose()
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={handleClose}
        />
      </ModalClose>
      <ScrollWrapper>
        <Code
          title={t('b.settings.edit.enter_code.title', 'Enter Code')}
          sendTo={formatPhone(phone, 'US')}
          onSubmit={onSubmit}
          tool={null}
        >
          <ResendButton onClick={resend}>
            {t(
              'b.settings.edit.change_phone_number_resend_code.button',
              'Resend Code'
            )}
          </ResendButton>
        </Code>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(ChangePhoneNumberModal)
