import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTemplateWhite from './card-template-white'
import equifaxAnalysing from './images/Credit_Score_Analysing.png'

const AnalyzingCredit = props => {
  const { t } = useTranslation()

  const creditDescription = t(
    'b.liv_score_card.view.background_check_description.text',
    'Background checks help us to keep our platform secure and trustworthy.'
  )

  return (
    <CardTemplateWhite
      userRole={props.userRole}
      title={props.title}
      creditDetails={props.creditDetails}
      creditDescription={creditDescription}
      creditRatingResult={t(
        'b.liv_score_card.view.analyzing_profile.text',
        'Analyzing Profile'
      )}
      cardImage={equifaxAnalysing}
      avatarUrl={props.avatarUrl}
      professionId={props.professionId}
      csoVerifiedState={props.csoVerifiedState}
      salarySort={props.salarySort}
      listingData={props.listingData}
      data-testid={props['data-testid']}
    />
  )
}

AnalyzingCredit.defaultProps = {
  userRole: 'tenant',
  title: ''
}

export default AnalyzingCredit
