import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { breakpoints, colors, fontSizes } from 'styles'
import { H1 } from 'components/headings'
import P from 'components/paragraph'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import { Anchor } from 'components/anchor'
import { show as showNotificationModal } from 'components/notification-modal'
import { useTranslation } from 'react-i18next'
import { resetPassword, verifyResetPasswordHash } from 'models/auth/ports'
import PasswordForm from 'features/Auth/PasswordForm'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 425px;
  max-width: 95%;
  margin: 0 100px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
`

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
`

const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 425px;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 10px;
`

const SmallAnchor = styled(Anchor)`
  font-size: ${fontSizes.regular};
  text-align: right;
`

const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`

const DescriptionText = styled(P)`
  margin: 30px 0;
`

const ResetPassword = ({ history, match: { params } }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [valid, setValid] = useState(null)

  useEffect(() => {
    const verify = async hash => {
      const { response, body } = await verifyResetPasswordHash({
        hash
      })

      setValid(response.ok && body.message === 'valid')
    }
    verify(params.hash)
  }, [params.hash])

  const onSubmit = async (values, { setSubmitting }) => {
    const { response } = await resetPassword(
      { body: values },
      {
        hash: params.hash
      }
    )
    setSubmitting(false)
    if (!response.ok) return
    showNotificationModal({
      message: t(
        'b.auth.edit.reset_success.text',
        'The password has been changed.'
      )
    })
    dispatch.session.loadSession()
  }
  const renderBody = () => {
    if (valid === false)
      return (
        <>
          <DescriptionText>
            {t(
              'b.auth.view.reset_invalid_hash.text',
              'The hash is either invalid or expired. Please try again.'
            )}
          </DescriptionText>
          <SmallAnchor to="/auth/login/reset" replace>
            {t('b.auth.view.reset_password.link', 'Reset my password.')}
          </SmallAnchor>
        </>
      )
    if (!valid)
      return (
        <DescriptionText>
          {t('b.auth.view.validating_hash.text', 'Validating hash...')}
        </DescriptionText>
      )
    return (
      <>
        <DescriptionText>
          {t(
            'b.auth.view.reset_description.text',
            'A link to reset your password will be sent to the email associated with your account.'
          )}
        </DescriptionText>
        <PasswordForm onSubmit={onSubmit} />
      </>
    )
  }
  return (
    <>
      <Helmet>
        <title>{t('b.auth.view.reset_password.title', 'Reset Password')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrapper>
        <TaskbarContainer>
          <TaskbarItem left={<Tool to="/auth/login" type="back" />} />
          <Title>
            {t('b.auth.view.reset_password.title', 'Reset Password')}
          </Title>
        </TaskbarContainer>

        <ScrollWrapper>{renderBody()}</ScrollWrapper>
      </Wrapper>
    </>
  )
}

export default ResetPassword
