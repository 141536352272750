import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { find, propEq } from 'ramda'
import useBasic from 'hooks/use-basic'
import { getFormattedCountries } from 'utils/address'
import { Input } from 'components/form'
import config from 'config'
import {
  StyledPanel,
  AreaTitle,
  StyledRow,
  StyledLabel,
  StyledValue,
  Link
} from '../elements'

const { WEB_URL } = config

const PaymentDetails = ({
  values: { payment_method, street1, street2, city, state, country, zip },
  acceptedTerms,
  setAcceptedTerms
}) => {
  const { t } = useTranslation()
  const { countries } = useBasic(['countries'])
  const formattedCountries = getFormattedCountries(countries)

  const getCountryName = countryCode => {
    return find(propEq('value', countryCode), formattedCountries).label
  }

  return (
    <>
      <AreaTitle>
        {t(
          'r.payment.edit.make_payment_billing_address.subtitle',
          'Billing Address'
        )}
      </AreaTitle>
      <StyledPanel hSpace={30} vSpace={50}>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t(
              'r.payment.edit.make_payment_street_address.label',
              'Street address'
            )}
          </StyledLabel>
          <StyledValue>{street1}</StyledValue>
        </StyledRow>
        {!!street2 && (
          <StyledRow bottom={10}>
            <StyledLabel>
              {t(
                'r.payment.edit.make_payment_apt_suite_unit_number.label',
                'Suite No. / Unit No.'
              )}
            </StyledLabel>
            <StyledValue>{street2}</StyledValue>
          </StyledRow>
        )}
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_city.label', 'City')}
          </StyledLabel>
          <StyledValue>{city}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_prov_state.label', 'Prov. / State')}
          </StyledLabel>
          <StyledValue>{state}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_country.label', 'Country')}
          </StyledLabel>
          <StyledValue>{getCountryName(country)}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_postal_code.label', 'Postal code')}
          </StyledLabel>
          <StyledValue>{zip}</StyledValue>
        </StyledRow>
        {payment_method === 'stripe' && (
          <StyledRow bottom="0" paddingTop={20} borderTop>
            <Trans i18nKey="b.settings.edit.read_and_agree_to_terms.label">
              <StyledLabel bold htmlFor="agree_to_terms">
                I have read and agree to the{' '}
                <Link
                  href={`${WEB_URL}/terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions.
                </Link>
              </StyledLabel>
            </Trans>
            <Input
              id="agree_to_terms"
              data-cy="agree_to_terms"
              data-testid="agree_to_terms"
              type="checkbox"
              checked={acceptedTerms}
              onChange={e => setAcceptedTerms(e.target.checked)}
            />
          </StyledRow>
        )}
      </StyledPanel>
    </>
  )
}

export default PaymentDetails
