import { useMemo, useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const throttler = (func, delay) => {
  if (typeof delay === 'undefined') return func
  return throttle(func, delay)
}

const useWindowDimensions = delay => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  const handleResize = useMemo(
    () =>
      throttler(() => {
        setWindowDimensions(getWindowDimensions())
      }, delay),
    [delay]
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return windowDimensions
}

export default useWindowDimensions
