import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints } from 'styles'
import { Avatar } from 'components/avatar'
import DoubleText from 'components/double-text'
import { ShowPhoneNumber } from 'components/phone-number'
import Tooltip from 'components/tooltip'
import VerifiedBadge from 'components/verified-badge'
import useCompany from 'hooks/use-company'
import isVerified from 'utils/is-verified'

const Landlord = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @media (max-width: 1165px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: space-between;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;
`

const DoubleTextWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`

const LandlordInfo = ({ landlord }) => {
  const { t } = useTranslation()
  const landlordName = landlord.preferred_name || landlord.first_name
  const company = useCompany(landlord.company_id)
  return (
    <Landlord>
      <DoubleTextWrapper>
        <Avatar
          width={40}
          height={40}
          right={10}
          top={2}
          location={landlord.picture}
          shadow
        />
        <Column>
          <DoubleText
            topText={t('b.listing.view.managed_by.text', 'Managed by')}
            bottomText={landlordName}
            maxWidth={235}
          >
            <Tooltip
              text={t(
                'b.listing.view.id_verified.tooltip',
                'This user is ID / Business Licence verified.'
              )}
              position="top"
            >
              <VerifiedBadge
                verified={isVerified(landlord, company)}
                type="id"
                height={20}
                width={30}
                left={5}
                bottom={-2}
              />
            </Tooltip>
          </DoubleText>
          <ShowPhoneNumber
            hidePhone={landlord.hide_phone_number}
            phoneNum={landlord.phone_number}
          />
        </Column>
      </DoubleTextWrapper>
    </Landlord>
  )
}

export default LandlordInfo
