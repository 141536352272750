import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints } from 'styles'
import DoubleText from 'components/double-text'
import companyIcon from './company.svg'

const Company = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

const CompanyIcon = styled.img`
  height: 38px;
  margin-top: 2px;
  margin-right: 7px;
  flex-shrink: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 34px;
    margin-right: 14px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const CompanyInfo = ({ name }) => {
  const { t } = useTranslation()
  if (!name) return null

  return (
    <Company>
      <CompanyIcon src={companyIcon} />
      <Column>
        <DoubleText
          topText={t('b.listing.view.company.text', 'Company')}
          bottomText={name}
          maxWidth={250}
        />
      </Column>
    </Company>
  )
}

export default CompanyInfo
