import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import ProfileRequired from 'components/profile-required'
import EmergencyContactView from './view'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const EmergencyContactsList = ({
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  return (
    <MultiRowForm
      title={
        <>
          {t('r.profile.view.emergency_contacts.subtitle', 'Emergency Contact')}
          <ProfileRequired fields={['emergency_contact']} area="basic_info" />
        </>
      }
      addText={t(
        'r.profile.view.add_emergency_contact.button',
        '+ Add Emergency Contact'
      )}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={EmergencyContactView}
      EditComponent={EditComponent}
      EmptyComponent={<EmptyMessage>{emptyMessage}</EmptyMessage>}
      editable={editable}
      showRequiredWarning={showRequiredWarning}
      anchorTop={anchorTop}
    />
  )
}

export default React.memo(EmergencyContactsList)
