import React from 'react'
import styled from 'styled-components'
import { fontSizes, fontWeights, colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { show as showForceEndTenancyModal } from 'features/EndTenancy/force-end-tenancy-modal'
import { Button } from 'components/button'
import useCurrentSession from 'hooks/use-current-session'
import RequestDetails from './request-details'
import ReplyForm from './reply-form'

const Title = styled.h2`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  border-bottom: 1px solid ${colors.mediumLightGrey};
  padding: 25px 0px;
`

const SubTitle = styled.h3`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  padding: 25px 0px 20px;
`

const ForceEndTenancyButton = styled(Button)`
  height: inherit;
  padding: 0;
  border: none;
  background: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};

  :hover {
    opacity: 0.7;
    background: none;
    color: ${colors.link};
    cursor: pointer;
  }
`

const SubmitButton = styled(Button)`
  display: block;
  padding: 10px 20px;
  margin: 25px auto 0px auto;
  border-radius: 2px;
`

const View = ({ data, onSubmit, listingData, close }) => {
  const [t] = useTranslation()
  const currentSession = useCurrentSession()
  const sentByOtherParty = currentSession?.id !== data?.user_id

  return (
    <>
      <Title>
        {t(
          'r.rental_dashboard.edit.resources_end_tenancy_modal.title',
          'Request Details'
        )}
      </Title>
      <RequestDetails
        showSummary={sentByOtherParty}
        data={data}
        listingData={listingData}
      />
      {!sentByOtherParty && (
        <>
          <SubTitle>
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_no_response.title',
              'Tenant not responsive?'
            )}
          </SubTitle>

          <ForceEndTenancyButton
            onClick={() => {
              return (
                close(),
                showForceEndTenancyModal({
                  listingId: data.listing_id,
                  endTenancyId: data.id
                })
              )
            }}
          >
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy.link',
              'Force End Tenancy'
            )}
          </ForceEndTenancyButton>
        </>
      )}
      {sentByOtherParty ? (
        <ReplyForm listingData={listingData} close={close} />
      ) : (
        <SubmitButton theme="primary" onClick={onSubmit}>
          {t(
            'r.rental_dashboard.edit.resources_end_tenancy_cancel_request.button',
            'Cancel Request'
          )}
        </SubmitButton>
      )}
    </>
  )
}

export default View
