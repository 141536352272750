import React, { useState } from 'react'
import styled from 'styled-components'
import { FieldArray } from 'formik'
import { assoc } from 'ramda'
import { colors, fontSizes, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/anchor'
import { Error } from 'components/form'
import Icon from 'components/icon'
import SelectorList from './selector-list-base'
import SelectorItemBase from './selector-item-base'

const Input = styled.input`
  border: none;
  font-size: ${fontSizes.regular};
  line-height: 28px;
  flex: 1;
  margin-right: 1rem;

  &:placeholder {
    color: ${colors.mediumGrey};
  }
`

const AddButton = styled(Button)`
  color: ${colors.link};
`

const CustomSelectorItem = styled(SelectorItemBase)`
  margin: 0;
  padding: 0 20px;
  border-bottom: none;
  border-top: 1px solid ${colors.grey};

  ${props =>
    !props.hasChildren &&
    `
    &:first-child {
      border-top: none;
    }
  `}
`

const CustomSelectorItemClickable = styled(CustomSelectorItem)`
  cursor: pointer;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
`

const handleKeyPress = fn => e => {
  if (e.key === 'Enter') {
    fn()
  }
}

const CustomSharedSelectorList = ({
  name,
  value = [],
  addText,
  defaultType = 'private',
  placeholder,
  maxLength,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const [showInput, setShowInput] = useState(false)
  const [text, setText] = useState('')

  const toggle = () => {
    setText('')
    setShowInput(!showInput)
  }

  const add = fn => () => {
    if (!text) return
    fn({ name: text, type: defaultType })
    setText('')
  }

  const handleChange = e => {
    setText(e.target.value)
  }

  return (
    <>
      <SelectorList {...rest}>
        <FieldArray name={name}>
          {arrayHelpers => (
            <>
              {!!value.length && (
                <div>
                  {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, {
                      name,
                      onRemove: () => arrayHelpers.remove(index),
                      onTypeChange: (val, type) => {
                        const newVal = assoc('type', type, value[index])
                        arrayHelpers.replace(index, newVal)
                      }
                    })
                  })}
                </div>
              )}

              <CustomSelectorItemClickable
                onClick={toggle}
                hasChildren={!!value.length}
              >
                {addText}
                <Icon id="plus" width={15} height={15} />
              </CustomSelectorItemClickable>
              {showInput && (
                <CustomSelectorItem>
                  <Input
                    value={text}
                    onChange={handleChange}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onKeyPress={handleKeyPress(add(arrayHelpers.push))}
                  />
                  <AddButton onClick={add(arrayHelpers.push)} disabled={!text}>
                    {t('common.add', 'Add')}
                  </AddButton>
                </CustomSelectorItem>
              )}
            </>
          )}
        </FieldArray>
      </SelectorList>
      <Error name={name} />
    </>
  )
}

CustomSharedSelectorList.defaultProps = {
  addText: 'Create new',
  placeholder: 'Enter',
  maxLength: 22
}

export default CustomSharedSelectorList
