import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { Input } from 'components/form'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const AppointmentBox = styled.div`
  max-height: 400px;
  overflow: auto;
`

const Appointment = styled.div`
  border: 1px solid ${colors.grey};
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px;
  margin: 10px;
`

const Time = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.blue};
  color: ${colors.blue};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 80px;
  margin-right: 10px;
`

const DelayWrapper = styled.div`
  color: ${colors.regular};
  font-size: ${fontSizes.small};
`

const RadioInput = styled(Input)`
  width: 24px;
  height: 24px;
  margin-left: auto;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin: 0;
  letter-spacing: 0.25px;
  margin: 30px auto;
`

const SnoozeModal = ({ title = false, delay15, delay30, appointmentId }) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [duration, setDuration] = useState('')

  const handleSnooze = async () => {
    const response = await dispatch.listing.delayAppointment({
      appointmentId: Number(appointmentId),
      duration
    })
    if (!response.ok) {
      close()
    }
  }

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>{title || t('l.showings.view.snooze.text', 'Snooze')}</Title>
      </Header>
      <AppointmentBox>
        <Appointment>
          <Time>{delay15}</Time>
          <DelayWrapper>
            <Trans i18nKey="l.showings.edit.delay_showing_15.text">
              Delay by <strong>15</strong> minutes
            </Trans>
          </DelayWrapper>
          <RadioInput
            type="radio"
            name="appointment"
            onChange={() => setDuration('15')}
          />
        </Appointment>
      </AppointmentBox>
      <AppointmentBox>
        <Appointment>
          <Time>{delay30}</Time>
          <DelayWrapper>
            <Trans i18nKey="l.showings.edit.delay_showing_30.text">
              Delay by <strong>30</strong> minutes
            </Trans>
          </DelayWrapper>
          <RadioInput
            type="radio"
            name="appointment"
            onChange={() => setDuration('30')}
          />
        </Appointment>
      </AppointmentBox>
      <ActionButton
        theme="primary"
        width={125}
        left="auto"
        type="submit"
        onClick={() => handleSnooze()}
      >
        {t('l.listing.edit.update.button', 'Update')}
      </ActionButton>
    </Wrapper>
  )
}

export const showSnoozeModal = createModal(SnoozeModal)
