import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { toast } from 'components/toast-notifications'
import PasswordForm from 'features/Auth/PasswordForm'
import { changePassword } from 'models/auth/ports'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  border-radius: 5px;
  background-color: ${colors.white};
`

const ModalClose = styled.div`
  text-align: right;
  padding: 25px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
`

const TitleText = styled.h1`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
  margin-bottom: 20px;
`

const PasswordModal = props => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const onSubmit = async (values, { setSubmitting }) => {
    const { response } = await changePassword({ body: values })
    setSubmitting(false)
    if (response.ok) {
      return (
        toast(
          t(
            'b.auth.edit.password_changed.text',
            'Your password has been successfully changed.'
          ),
          {
            title: t('b.auth.edit.password_changed.title', 'Password Changed!'),
            iconId: 'square_check',
            autoClose: 6000
          }
        ),
        close()
      )
    }
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <TitleText>
          {t('b.auth.edit.change_password.title', 'Change Password')}
        </TitleText>
        <PasswordForm askCurrentPassword onSubmit={onSubmit} />
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showChangePasswordModal = createModal(PasswordModal)
