import React from 'react'
import { path } from 'ramda'
import { toInteger } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatCurrency from 'utils/format-currency'
import formatPaymentFrequency from 'utils/format-payment-frequency'
import isTruthy from 'utils/is-truthy'

const StorageInfo = ({ storageFee }) => {
  const { t } = useTranslation()
  if (
    !isTruthy(path(['id'], storageFee)) ||
    storageFee.frequency === 'not_available'
  ) {
    return null
  }

  const frequency = formatPaymentFrequency(storageFee.frequency)
  const isIncluded = () =>
    frequency.toLowerCase() === 'free' || toInteger(storageFee.fee) === 0
  const name = () => isTruthy(storageFee.name) && <p>{storageFee.name}</p>

  return (
    <CollapsibleBox
      title={t('b.contract.view.storage_info.subtitle', 'Storage Info')}
      wrapChildren
    >
      {name()}
      <LabeledValue
        label={t('b.contract.view.storage_fee.label', 'Storage fee')}
        value={
          isIncluded()
            ? t('b.contract.view.included.text', 'Included')
            : formatCurrency(storageFee.fee)
        }
      />
      {!isIncluded() && (
        <LabeledValue
          label={t(
            'b.contract.view.payment_frequency.label',
            'Payment frequency'
          )}
          value={frequency}
        />
      )}
    </CollapsibleBox>
  )
}

StorageInfo.defaultProps = {
  storageFee: null
}

export default StorageInfo
