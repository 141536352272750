import React from 'react'
import styled from 'styled-components'
import { createModal, useModalize } from 'components/modalize'
import { fontSizes, fontWeights } from 'styles'

import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'

const Wrapper = styled.div`
  padding: 20px 30px;
  text-align: center;
`

const Title = styled.h1`
  margin: 10px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const DescriptionText = styled.p`
  margin: 30px 0px;
`

const ConfirmButton = styled(Button)`
  width: 150px;
  margin: 20px 0px;
`

const ExistingContract = () => {
  const [t] = useTranslation()
  const { close } = useModalize()
  return (
    <Wrapper>
      <Title>
        {t(
          'l.application_view.view.existing_contract.title',
          'A Contract Already Exists!'
        )}
      </Title>
      <DescriptionText>
        {t(
          'l.application_view.view.existing_contract_description.text',
          'You have an existing contract with another applicant. Please cancel the contract before proceeding with this applicant.'
        )}
      </DescriptionText>
      <ConfirmButton theme="primary" onClick={close}>
        {t('l.application_view.view.existing_contract_confirm.button', 'Okay')}
      </ConfirmButton>
    </Wrapper>
  )
}

export const show = createModal(ExistingContract)
