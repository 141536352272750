import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Icon from 'components/icon'

const VideoItem = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 'center';
  display: block;

  ${({ customStyle }) => customStyle}
`

const VideoPlayButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;

  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: default;
  `}
`

const VideoPlayer = ({
  location,
  controls,
  autoPlay,
  customStyle,
  disabled
}) => {
  const video = useRef()
  const [state, setState] = useState(autoPlay ? 'playing' : 'stopped')

  const handleClick = () => {
    if (video.current.paused) {
      video.current.play()
      setState('playing')
    } else {
      video.current.pause()
      setState('paused')
    }
  }

  useEffect(() => {
    const videoRef = video.current
    const resetIt = () => setState('stopped')
    videoRef.addEventListener('ended', resetIt, false)
    return () => videoRef.removeEventListener('ended', resetIt, false)
  })

  return (
    <>
      <VideoItem
        ref={video}
        controls={controls}
        autoPlay={autoPlay}
        customStyle={customStyle}
        preload="none"
      >
        {location.map((item, index) => (
          <source key={index} src={item.key} type={item.type} />
        ))}
      </VideoItem>
      {(state === 'stopped' || state === 'paused') && (
        <VideoPlayButton onClick={handleClick} disabled={disabled}>
          <Icon id="play" width={90} height={90} />
        </VideoPlayButton>
      )}
    </>
  )
}

export default VideoPlayer
