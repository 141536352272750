import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { H1 } from 'components/headings'
import P from 'components/paragraph'
import Taskbar from 'components/taskbar'
import CodeInput from './code-input'

const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 425px;
  max-height: 100%;
  max-width: 95%;
  margin: 0 10px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
  text-align: center;
`

const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100vw;
`

const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 0;
`

const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`

const DescriptionText = styled(P)`
  margin: 20px 0 40px 0;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const CODE_LENGTH = 4

const PhoneCode = ({ children, tool, title, sendTo, onSubmit, ...rest }) => (
  <LoginContainer {...rest}>
    <TaskbarContainer>
      <TaskbarItem left={tool} />
      <Title>{title}</Title>
    </TaskbarContainer>
    <ContentWrapper>
      <DescriptionText>
        <Trans
          i18nKey="b.auth.view.verify_number_instructions.text"
          values={{ codeLength: CODE_LENGTH }}
          defaults="Enter the {{codeLength}}-digit code we sent to"
        />
        <br />
        <Bold>{sendTo}</Bold>
      </DescriptionText>
      <CodeInput codeLength={CODE_LENGTH} onSubmit={onSubmit} />
      {children}
    </ContentWrapper>
  </LoginContainer>
)

export default PhoneCode
