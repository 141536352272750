import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { Helmet } from 'react-helmet'
import { colors, fontSizes, breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  defaultTo,
  pick,
  path,
  values as ramdaValues,
  find,
  propEq,
  propOr,
  head,
  pipe
} from 'ramda'
import * as Yup from 'yup'
import useMediaQuery from 'hooks/use-media-query'
import fileAsDataUrl from 'utils/file-as-data-url'
import ProfileButtonBar from 'features/Profile/button-bar'
import { Row, Group, FieldInput } from 'components/form'
import UploadButton from 'components/upload-button'
import { showImageModal } from 'components/image-modal'
import PageTitle from 'components/page-title'
import { confirm } from 'components/dialog'
import { Button } from 'components/button'
import Panel from 'components/panel'
import Page from 'components/page'
import ENV_VARS from 'config'
import ContentWrapper from 'components/content-wrapper'

const { BACKEND_SERVER } = ENV_VARS

const PanelHeader = styled.h2`
  margin: 60px 0 30px 0;
  font-size: ${fontSizes.medium};
  color: ${colors.regular};
  text-align: center;
`

const PanelContainer = styled(Panel)`
  & div:first-of-type {
    padding-top: 0;
  }

  > :last-child {
    padding-bottom: 0;
    border-bottom-width: 0;
  }
`

const PanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
  }
`

const PanelText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  margin: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 20px 20px 0;
    text-align: center;
  }
`

const ViewButtonWrapper = styled.div`
  display: flex;
`

const DeleteButton = styled.button`
  cursor: pointer;
  color: ${colors.red};
  background-color: ${colors.white};
  border: none;
  margin-right: 10px;
  font-size: ${fontSizes.small};
  outline: none;

  :active {
    opacity: 0.5;
  }
`

const ViewButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 18px;
  margin: 0;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  min-width: 83px;
  border: 2px solid ${colors.regular};

  :hover {
    border-color: ${colors.secondary};
    color: ${colors.secondary};
  }

  :active {
    opacity: 0.5;
  }
`

const EditInsuranceInfo = props => {
  const dispatch = useDispatch()
  const { history, location } = props
  const secureFiles = useSelector(
    pipe(path(['profile', 'files', 'user_secure_files']), ramdaValues)
  )
  const insuranceData = defaultTo(
    {},
    find(propEq('tag', 'insurance_info'), ramdaValues(secureFiles))
  )
  const { t } = useTranslation()
  const [imageUri, setImageUri] = useState(!!insuranceData.tag)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const handleDeleteClick = setFieldValue => {
    return confirm(
      t(
        'r.profile.edit.delete_insurance_confirmation.title',
        'Delete Insurance Policy?'
      ),
      t(
        'r.profile.edit.delete_insurance_confirmation.text',
        'Are you sure you want to delete this policy?'
      )
    ).then(async () => {
      setImageUri(undefined)
      setFieldValue('image', false)
    })
  }

  const handleImageUpload = setFieldValue => async files => {
    const data = await fileAsDataUrl(head(files))
    setFieldValue('image', true)
    setImageUri(data)
  }
  const existingValues = defaultTo(
    {},
    pick(
      ['policy_number', 'insurance_company_name'],
      propOr({}, ['metadata'], insuranceData)
    )
  )
  const formOptions = () => ({
    enableReinitialize: true,
    initialValues: {
      insurance_company_name: '',
      policy_number: '',
      image: !!imageUri,
      ...existingValues
    },
    validationSchema: Yup.object().shape({
      insurance_company_name: Yup.string().required(),
      policy_number: Yup.string().required(),
      image: Yup.lazy(value => {
        if (typeof imageUri !== 'undefined' && insuranceData.tag)
          return Yup.mixed()
        return Yup.bool().oneOf(
          [true],
          t(
            'r.profile.edit.upload_insurance_policy.error',
            'Please upload a photo of your insurance policy'
          )
        )
      })
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const hasOldImage = !!insuranceData.tag
      const hasNewImage = typeof imageUri === 'string'
      const data = {
        data: !hasOldImage || hasNewImage ? imageUri : undefined,
        tag: 'insurance_info',
        metadata: {
          document_type: 'insurance_info',
          insurance_company_name: values.insurance_company_name,
          policy_number: values.policy_number
        }
      }
      const request = async () => {
        if (hasOldImage) {
          if (!hasNewImage) {
            return await dispatch.insurance.updateFile({
              fileId: insuranceData.id,
              data
            })
          }
          await dispatch.insurance.deleteFile({ fileId: insuranceData.id })
        }
        return await dispatch.insurance.sendFile({ data })
      }
      const response = await request()
      setSubmitting(false)
      if (response.response.ok) {
        history.push(
          location.state?.onSuccessRedirectTo || '/profile/extra-info'
        )
        window.scrollTo(0, 0)
      }
    }
  })
  return (
    <Page>
      <Helmet>
        <title>
          {t(
            'r.profile.edit.insurance_information.title',
            'Insurance Information'
          )}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Formik {...formOptions(props)}>
        {({ setFieldValue, isSubmitting }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <PageTitle>
                {t(
                  'r.profile.edit.insurance_information.title',
                  'Insurance Information'
                )}
              </PageTitle>
              <PanelHeader>
                {t(
                  'r.profile.edit.your_insurance_information.title',
                  'Your Insurance Information'
                )}
              </PanelHeader>
              <PanelContainer hSpace={30} vSpace={40}>
                <Row>
                  <Group col={displayMobile ? 12 : 6}>
                    <FieldInput
                      name="insurance_company_name"
                      disabled={false}
                      placeholder={
                        t(
                          'r.profile.edit.insurance_company_name.label',
                          'Insurance Company Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                  <Group col={displayMobile ? 12 : 6}>
                    <FieldInput
                      name="policy_number"
                      disabled={false}
                      placeholder={
                        t(
                          'r.profile.edit.insurance_policy_number.label',
                          'Policy Number'
                        ) + '*:'
                      }
                    />
                  </Group>
                </Row>
                <PanelRow>
                  <PanelText>
                    {t(
                      'r.profile.edit.upload_insurance_policy.text',
                      'Upload a picture of your insurance policy cover page'
                    )}
                    *:
                  </PanelText>
                  {typeof imageUri !== 'undefined' ? (
                    <ViewButtonWrapper>
                      <DeleteButton
                        type="button"
                        onClick={() => handleDeleteClick(setFieldValue)}
                      >
                        {t(
                          'r.profile.edit.delete.button',
                          'Delete'
                        ).toLowerCase()}
                      </DeleteButton>
                      <ViewButton
                        type="button"
                        onClick={() =>
                          showImageModal({
                            src:
                              imageUri && typeof imageUri !== 'boolean'
                                ? imageUri
                                : `${BACKEND_SERVER}/secure/${insuranceData?.tag}/${insuranceData.id}/form.png`
                          })
                        }
                      >
                        {t('r.profile.edit.view.button', 'View')}
                      </ViewButton>
                    </ViewButtonWrapper>
                  ) : (
                    <UploadButton
                      name="image"
                      onDropAccepted={handleImageUpload(setFieldValue)}
                    />
                  )}
                </PanelRow>
              </PanelContainer>
            </ContentWrapper>
            <ProfileButtonBar handleBack={history.goBack} sticky>
              <Button
                theme="primary"
                width={150}
                left="auto"
                isSubmitting={isSubmitting}
                type="submit"
              >
                {t('r.profile.edit.add.button', 'Add')}
              </Button>
            </ProfileButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default React.memo(EditInsuranceInfo)
