import React from 'react'
import { connect } from 'react-redux'
import { select } from 'store'
import ChatCardBase from './chat-card-base'
import logo from './logo.png'

const mapState = state => ({
  support_room: select.supportRoom.roomInfo(state)
})
const ChatCard = ({
  support_room: {
    last_agent_event_at,
    last_user_read_at,
    last_event_message
  } = {}
}) => {
  return (
    <ChatCardBase
      names={['Liv Support']}
      avatar={logo}
      last_event={last_agent_event_at}
      last_read={last_user_read_at}
      last_event_message={last_event_message}
      timeline_room="support-room"
    />
  )
}

ChatCard.defaultProps = {
  timeline: {},
  url: '',
  name: '',
  unit: '',
  last_read: '',
  last_event: ''
}

export default connect(mapState)(React.memo(ChatCard))
