import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'styles'
import { prop } from 'ramda'
import toArrayOfStrings from 'utils/to-array-of-string'

const Wrapper = styled.div`
  padding: 20px;
  transition: background-color 100ms ease-in;
  position: fixed;
  top: 0;
  width: 100vw;
  cursor: pointer;
  z-index: 9100;
`

const ErrorNotification = styled(Wrapper)`
  background-color: ${colors.red};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.lightRed};
  }
`

const typeMap = {
  error: ErrorNotification
}

const Notification = () => {
  const error = useSelector(prop('error'))
  const dispatch = useDispatch()
  const NotificationWrapper = typeMap[error.type]

  if (!error.message || !NotificationWrapper) {
    return null
  }

  const messages = toArrayOfStrings(error.message)

  return (
    <NotificationWrapper onClick={dispatch.error.clearError}>
      {messages.map(message => (
        <div key={JSON.stringify(message)}>{message}</div>
      ))}
    </NotificationWrapper>
  )
}

export default Notification
