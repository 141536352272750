const FREQUENCIES = {
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  one_time: 'One-time',
  free: 'Free',
  not_available: 'Not Available'
}

const formatPaymentFrequency = frequency => FREQUENCIES[frequency]

export default formatPaymentFrequency
