import React from 'react'
import styled from 'styled-components'
import { AgreementDescription, AgreementTitle } from 'components/agreement'
import { Button } from 'components/anchor'
import Content from 'components/content'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'

const AgreementWrapper = styled(Content)`
  min-height: 90vh;
`

class Agreement extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.agreementId !== this.props.agreementId
  }

  renderConnectedAgreement() {
    const { agreementId, onChange } = this.props

    if (agreementId === 'privacy_policy') {
      return <Button onClick={() => onChange('terms_conditions')}>Terms</Button>
    } else if (agreementId === 'terms_conditions') {
      return <Button onClick={() => onChange('privacy_policy')}>Privacy</Button>
    }

    return null
  }

  render() {
    const { agreementId, onClose } = this.props

    return [
      <Taskbar
        key="title"
        left={this.renderConnectedAgreement()}
        right={<Tool type="close" onClick={onClose} />}
      >
        <AgreementTitle agreementId={agreementId} />
      </Taskbar>,
      <AgreementWrapper key="content">
        <AgreementDescription agreementId={agreementId} />
      </AgreementWrapper>
    ]
  }
}

Agreement.defaultProps = {
  agreementId: null
}

export default Agreement
