import React, { useRef } from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'
import { ButtonGroup } from 'components/button'
import Error from './error'

const FieldButtonPicker = ({
  name,
  children,
  component,
  onChange,
  disabled,
  className,
  ...rest
}) => {
  const formik = useFormikContext()
  const [field] = useField(name)
  const fieldRef = useRef()

  return (
    <>
      <ButtonGroup
        ref={fieldRef}
        value={field.value}
        className={className}
        role="radiogroup"
        {...rest}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            className: child.props.value === field.value ? 'selected' : null,
            role: 'radio',
            'aria-checked': child.props.value === field.value,
            disabled: disabled || child.props.disabled,
            onClick: () => {
              formik.setFieldValue(field.name, child.props.value)
              onChange && onChange(child.props.value)
            }
          })
        )}
      </ButtonGroup>
      <Error name={name} />
    </>
  )
}

export default styled(FieldButtonPicker)``
