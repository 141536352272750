import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from 'styles'
import Header from './header'

const Wrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 6px;

  margin-top: ${props => props.top || 0}px;
  margin-bottom: ${props => props.bottom || 0}px;
  min-width: ${props => `${props.minWidth}px` || 'unset'};
`

const Body = styled.div`
  ${props => props.height && `height: ${props.isOpen ? props.height : 0}px`};
  -webkit-transition: height 250ms linear;
  transition: height 250ms linear;
`

const BodyContent = styled.div`
  padding: 20px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const ItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const FooterContent = styled.div`
  background-color: ${colors.lightGrey};
  border-top: 1px solid ${colors.grey};
  padding: 20px;
  margin: 0 -20px -20px;
  border-radius: 0 0 5px 5px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0px -10px -20px;
  }
`

class Box extends React.Component {
  bodyElem = React.createRef()
  constructor(props) {
    super(props)

    this.initialState = {
      isOpen: !props.initiallyClosed,
      height: null
    }

    this.state = { ...this.initialState }
  }

  componentDidMount() {
    this.setHeight()
  }

  setHeight() {
    this.setState({ height: this.bodyElem.clientHeight })
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  static renderItem(item, index) {
    return <ItemWrapper key={`${index + 1}`}>{item}</ItemWrapper>
  }

  render() {
    const {
      title,
      children,
      className,
      footer,
      header,
      headerToggle,
      wrapChildren,
      ...rest
    } = this.props
    const { isOpen, height } = this.state
    const childrenArray = React.Children.toArray(children)
    const footerArr = React.Children.toArray(footer)
    const HeaderComponent = header || Header

    return (
      <Wrapper className={className} {...rest}>
        <HeaderComponent
          role="button"
          onClick={() => this.toggle()}
          isOpen={isOpen}
        >
          {title}
          {headerToggle}
        </HeaderComponent>
        <Body isOpen={isOpen} height={height} ref={this.bodyElem}>
          <BodyContent>
            {wrapChildren ? childrenArray.map(Box.renderItem) : children}
            {footer && (
              <FooterContent>{footerArr.map(Box.renderItem)}</FooterContent>
            )}
          </BodyContent>
        </Body>
      </Wrapper>
    )
  }
}

Box.defaultProps = {
  title: null,
  children: null,
  footer: null,
  className: null,
  header: null,
  headerToggle: null,
  initiallyClosed: false
}

export default Box
