import React, { useState, useEffect, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useParams } from 'react-router'
import useCurrentSession from 'hooks/use-current-session'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useTransition, animated } from 'react-spring'
import { trim, all } from 'ramda'
import throttle from 'lodash/throttle'
import noop from 'lodash/noop'
import { colors, fontSizes, breakpoints } from 'styles'
import { useFlag } from 'flags'
import useEventListener from 'hooks/use-event-listener'
import useRole from 'hooks/use-role'
import useKeyPress from 'hooks/use-key-press'
import { showTenantProfile } from 'features/Profile/TenantProfileModal'
import Thumbnail from 'components/thumbnail'
import Hidden from 'components/hidden'
import { Button } from 'components/button'
import { toast } from 'components/toast-notifications'
import Icon from 'components/icon'
import { PhoneNumber } from 'components/phone-number'
import LoadingIndicator from 'components/loading-indicator'
import { getUrl } from 'utils/listings'
import { NavLink } from 'react-router-dom'
import config from 'config'
import NameBanner from '../name-banner'
import ChatMenu from './chat-menu'
import Message from './chat-message'
import backArrow from './back-arrow.png'
import { PresetBubble } from './quick-reply'
import { show as ShowEditTemplateModal } from './quick-reply/edit-template-modal'
import FilePreview from './file-preview'
const { WEB_URL } = config

const thumbnailSpace = css`
  margin-right: 15px;
`

const DragDropWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.lightGrey};
`

const UserNameText = styled.p`
  font-size: 14px;
  color: ${colors.mediumGrey};

  @media screen and (max-width: ${breakpoints.desktop}) {
    max-width: 250px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 13px;
  }
`

const DetailsText = styled.p`
  margin: 0px 0px 0px 15px;
  padding-left: 15px;
  font-size: 14px;
  color: ${colors.mediumGrey};
  border-left: 1px solid ${colors.mediumLightGrey};
  > a {
    color: ${colors.blue};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 13px;
  }
`

const ListingLink = styled(DetailsText)`
  padding-right: 2px;
  color: ${colors.blue};
`

const PhoneLink = styled(ListingLink)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const CreateApplicationButton = styled(ListingLink)`
  border: none;
  padding: 0;
  margin: 0;
  flex-shrink: 1;
  overflow: visible;
`

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;

  ${props =>
    props.isLink &&
    `
   cursor: pointer;
  `}
`

const ApplyListingWrapper = styled(DetailsWrapper)`
  overflow: visible;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
  padding: 15px 30px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    padding: 15px;
  }
`

const BackArrow = styled.img`
  display: none;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 14px;
    height: 14px;
  }
`

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-wrap: wrap;
  overflow-y: hidden;
  height: 40px;
`

const MessagesContainer = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
  padding: 10px 30px 45px;
  overflow-y: scroll;
  flex: 1;
  font-size: 12px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-bottom: 140px;
    margin-bottom: 90px;
  }
`

const MessageInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 150px;
  background-color: ${colors.white};
  padding: 20px 4%;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex: 0 0 80px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const TextInput = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.mediumLightGrey};
  resize: none;
  outline: none;
  padding: 20px;
  padding-right: 30px;
  border-radius: 2px;
  font-size: ${fontSizes.small};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px;
    padding-right: 20px;
    font-size: ${fontSizes.small};
  }
`

const TextAreaWrapper = styled.div`
  height: 90px;
  position: relative;
  margin-right: 40px;
  flex: 1;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) and (min-width: ${breakpoints.ipadMiniMax}) {
    margin-right: 10px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 80px;
    margin-right: 10px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 50px;
  }
`

const PresetBubbleContainer = styled.div`
  padding-bottom: 15px;
  padding-left: 28px;
  background-color: transparent;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  position: absolute;
  top: -47px;
  left: 0;
  z-index: 3;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: 100vw;
    min-width: unset;
  }
`

const StyledPresetBubble = styled(PresetBubble)`
  margin-right: 10px;
`

const EditPresetBubble = styled(StyledPresetBubble)`
  width: auto;
  min-width: unset;
  padding: 0;
  width: 31px;
  height: 31px;
  border-radius: 50%;

  svg {
    g {
      stroke: ${colors.secondaryLightBlue};
    }
  }

  :active {
    svg {
      g {
        stroke: ${colors.white};
      }
    }
  }
`

const EditPresetIcon = styled(Icon)`
  padding-top: 2px;
`

const UploadButton = styled(Button)`
  width: 10px;
  height: 20px;
  padding: 0;
  position: absolute;
  right: 10px;
  bottom: 15px;

  :hover {
    background-color: ${colors.white};
  }

  > svg path {
    fill: ${colors.secondary};
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax} and (min-width: 500px)) {
    right: 15px;
  }
`

const SendButton = styled(Button)`
  width: 120px;
  height: 40px;
  border-radius: 2px;
  margin-left: auto;
  flex-grow: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 80px;
    height: 44px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 50px;
  }
`

const LoadingIndicatorStyled = styled(LoadingIndicator)`
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  z-index: 11;
`

const TimelineLockedText = styled.p`
  margin: 0px;
  padding: 5px;
  font-size: ${fontSizes.small};
  background-color: ${colors.secondary};
  color: ${colors.white};
  text-align: center;
`

const CallIcon = styled(Icon)`
  margin-left: 10px;
  display: none;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: block;
  }
`

const ChatRoom = ({
  avatarUrl,
  events,
  handleSendFile,
  handleSendMessage,
  lastEventLoaded,
  loading = false,
  timeline,
  names,
  address,
  onScrollTop = noop,
  welcomeMessage: WelcomeMessage,
  welcomeMessageProps = {},
  locked,
  handleBack,
  tenantData,
  phoneNumber,
  presetMessages = [],
  isPresetEditEnabled = true,
  isRented,
  isSupportRoom,
  ...rest
}) => {
  const params = useParams()
  const { t } = useTranslation()
  const me = useCurrentSession()?.id
  const [files, setFiles] = useState([])
  const [hasScrolled, setHasScrolled] = useState(false)
  const messagesContainerRef = useRef()
  const inputRef = useRef()
  const [autoScroll, setAutoScroll] = useState(true)
  const showName =
    timeline && timeline.users.us.length + timeline.users.them.length > 2

  const isLandlord = useRole() === 'landlord'
  const createApplicationFlag = useFlag(['features', 'application', 'create'])
  useEffect(() => {
    setAutoScroll(true)
  }, [params.timelineId])

  const hasApplied = !!timeline?.applications?.id
  const detailsLink = isRented
    ? `/tenant/${timeline?.listing_id}`
    : `/listing/${timeline?.listing_id}`

  const isApplyToListingShown =
    createApplicationFlag &&
    timeline?.state_machine === 'active' &&
    !hasApplied &&
    !isLandlord
  const isApplicationPresetShown =
    isLandlord && !hasApplied && timeline?.state_machine === 'active'

  const onScroll = useMemo(
    () =>
      throttle(event => {
        const el = event.target
        const scrollTop = el.scrollTop
        const scrollHeight = el.scrollHeight - el.clientHeight
        const ratio = scrollTop / scrollHeight
        if (ratio < 0.025) {
          onScrollTop(event)
        }
        if (ratio < 0.98) {
          autoScroll && setAutoScroll(false)
        } else {
          !autoScroll && setAutoScroll(true)
        }
        setHasScrolled(value => value || ratio < 0.985)
      }, 500),
    [autoScroll, onScrollTop]
  )

  const listingId = timeline?.listing_id

  useEffect(() => {
    setHasScrolled(false)
  }, [listingId])

  useEventListener('scroll', onScroll, messagesContainerRef.current)

  useEffect(() => {
    const container = messagesContainerRef.current
    const scrollHeight = container?.scrollHeight

    if (autoScroll) {
      container.scrollTop = scrollHeight
    }
  }, [autoScroll, events])

  const [message, setMessage] = useState('')
  const acceptedFileTypes =
    'image/png, image/jpg, image/jpeg, application/pdf, application/msword, text/plain'
  const { getInputProps, open } = useDropzone({
    accept: acceptedFileTypes,
    multiple: false,
    noKeyboard: true,
    onDrop: handleSendFile
  })
  const listingUrl = getUrl({
    building_type: timeline?.buildings?.building_type_txt_id,
    city: timeline?.buildings?.city,
    listing_id: timeline?.listing_id
  })

  const handleSendClick = e => {
    if (!trim(message)) return
    setAutoScroll(true)
    handleSendMessage(message)
    setMessage('')
  }

  const handleReturnKey = e => {
    if (e.shiftKey || e.ctrlKey || e.altKey) return true
    e.preventDefault()
    handleSendClick()
  }

  const showMenu = useFlag(['features', 'chat', 'menu'])
  useKeyPress('Enter', handleReturnKey, inputRef.current)

  const handleDragFile = items => {
    const isValid =
      all(item => acceptedFileTypes.includes(item.type), items) &&
      items.length <= 10
    if (!isValid)
      return toast(
        t(
          'b.chat.view.send_files.error',
          'Only valid file types and max. 10 files per upload.'
        ),
        {
          title: t('common.error', 'Error'),
          iconId: 'error',
          autoClose: 6000
        }
      )
    setFiles(items)
  }

  const { getRootProps, isDragActive } = useDropzone({
    multiple: true,
    noKeyboard: true,
    onDrop: handleDragFile
  })

  const slideTransitions = useTransition(
    isDragActive || files.length > 0,
    null,
    {
      from: { position: 'absolute', top: 2000 },
      enter: { top: -55 },
      leave: { top: 2000 }
    }
  )

  const handleViewProfile = () => {
    showTenantProfile({
      data: tenantData
    })
  }

  const ViewDetailsLink = ({ children }) =>
    isLandlord ? (
      <NavLink to={detailsLink}>{children}</NavLink>
    ) : (
      <a href={`${WEB_URL}${listingUrl}`}>{children}</a>
    )

  return (
    <DragDropWrapper {...getRootProps()}>
      <Wrapper>
        <HeaderContainer>
          <UserInfoContainer>
            <BackArrow src={backArrow} onClick={handleBack} alt="back" />
            <DetailsWrapper
              onClick={!!tenantData ? handleViewProfile : undefined}
              isLink={!!tenantData}
            >
              {names.length === 1 && (
                <Thumbnail src={avatarUrl} customStyle={thumbnailSpace} />
              )}
              <UserNameText>
                <NameBanner names={names} />
              </UserNameText>
            </DetailsWrapper>
            {phoneNumber && (
              <PhoneNumber
                phoneNumber={phoneNumber}
                aria-labelledby="call-label"
              >
                {number => (
                  <>
                    <CallIcon id="call" width={20} height={20} />
                    <Hidden id="call-label">
                      {t('b.chat.menu.call.button', 'Call {{number}}', {
                        number
                      })}
                    </Hidden>
                  </>
                )}
              </PhoneNumber>
            )}
            <DetailsWrapper>
              {phoneNumber && (
                <PhoneLink>
                  <PhoneNumber phoneNumber={phoneNumber} />
                </PhoneLink>
              )}
            </DetailsWrapper>
            <DetailsWrapper>
              {address && <DetailsText>{address}</DetailsText>}
            </DetailsWrapper>
            {timeline?.listing_id && (
              <DetailsWrapper>
                <ListingLink>
                  <ViewDetailsLink>
                    {t('b.chat.view.view_details.text', 'View Details')}
                  </ViewDetailsLink>
                </ListingLink>
              </DetailsWrapper>
            )}
          </UserInfoContainer>
          {isApplyToListingShown && (
            <ApplyListingWrapper>
              <CreateApplicationButton>
                <NavLink to={`/application/create/${timeline?.listing_id}`}>
                  {t('b.chat.view.apply_to_listing.link', 'Apply to Listing')}
                </NavLink>
              </CreateApplicationButton>
            </ApplyListingWrapper>
          )}
          {showMenu && params.timelineId !== 'support-room' && (
            <ChatMenu timelineRoomId={params.timelineId} data={timeline} />
          )}
        </HeaderContainer>
        {locked && (
          <TimelineLockedText>
            {t(
              'b.chat.view.timeline_locked.text',
              'This timeline has been locked'
            )}
          </TimelineLockedText>
        )}
        <MessagesContainer ref={messagesContainerRef}>
          {slideTransitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <FilePreview
                    handleSendFile={handleSendFile}
                    files={files}
                    setFiles={setFiles}
                  />
                </animated.div>
              )
          )}
          {loading && <LoadingIndicatorStyled data-testid="chat-loading" />}
          {!isRented && WelcomeMessage && (
            <WelcomeMessage
              hasScrolled={hasScrolled}
              {...welcomeMessageProps}
            />
          )}
          {events?.map((event, index) => (
            <Message
              showName={showName}
              position={me === event.user_id ? 'right' : 'left'}
              event={event}
              key={event.id}
              isFirst={index === 0}
            />
          ))}
        </MessagesContainer>
        {!locked && (
          <>
            <MessageInputContainer>
              <PresetBubbleContainer>
                {isApplicationPresetShown && (
                  <StyledPresetBubble applyMessage={setMessage} pinned>
                    Please submit your application first.
                  </StyledPresetBubble>
                )}
                {presetMessages &&
                  presetMessages.map(preset => (
                    <StyledPresetBubble
                      applyMessage={setMessage}
                      key={preset.id}
                      wide={isSupportRoom}
                    >
                      {preset.content}
                    </StyledPresetBubble>
                  ))}
                {isPresetEditEnabled && (
                  <EditPresetBubble onClick={() => ShowEditTemplateModal()}>
                    <EditPresetIcon id="edit" width={17} height={17} />
                  </EditPresetBubble>
                )}
              </PresetBubbleContainer>
              <TextAreaWrapper>
                <TextInput
                  isDragActive={isDragActive}
                  ref={inputRef}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  maxLength={1500}
                  placeholder={t(
                    'b.chat.view.type_your_message.placeholder',
                    'Type your message...'
                  )}
                />
                <input {...getInputProps()} data-testid="upload-button" />
                <UploadButton onClick={open}>
                  <Icon width={10} height={20} id="attachment" />
                </UploadButton>
              </TextAreaWrapper>
              <SendButton
                theme="primary"
                disabled={!trim(message)}
                onClick={handleSendClick}
              >
                {t('b.chat.view.send.button', 'Send')}
              </SendButton>
            </MessageInputContainer>
          </>
        )}
      </Wrapper>
    </DragDropWrapper>
  )
}

export default ChatRoom
