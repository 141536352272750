import React from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import { select } from 'store'
import UserChatRoom from '../chat-room'
import EmptyChatRoom from '../chat-room/empty-chat-room'

const ChatRoom = ({
  match: {
    params: { timelineId }
  },
  handleBack,
  sideBarHeader,
  chatFooterButton,
  emptyMessage,
  noTimelines
}) => {
  const timeline = useSelector(state =>
    select.chat.timeline(state, { timelineId })
  )

  const timelineEvents = useSelector(state =>
    select.chat.timelineEvents(state, { timelineId })
  )

  if (!timelineId) {
    return (
      <EmptyChatRoom
        handleBack={handleBack}
        message={emptyMessage}
        sideBarHeader={sideBarHeader}
        chatFooterButton={chatFooterButton}
        noTimelines={noTimelines}
      />
    )
  }

  return (
    <UserChatRoom
      handleBack={handleBack}
      timeline={timeline}
      timelineEvents={timelineEvents}
    />
  )
}

export default withRouter(ChatRoom)
