import React from 'react'
import styled from 'styled-components'
import { includes } from 'ramda'
import SystemMessage from './system-message'
import BubbleMessage from './bubble-message'

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.isFirst &&
    `
    margin-top: 50px;
  `}
`

const systemMessages = [
  'chat_new_user',
  'chat_remove_user',
  'chat_timeline_locked',
  'chat_timeline_deleted',
  'phantom_message',
  'chat_listing_match',
  'chat_welcome_message',
  'chat_copy_application_from',
  'chat_copy_application_to',
  'chat_welcome_support',
  'chat_end_contract_ended',
  'chat_end_contract_notice1',
  'chat_end_contract_notice2'
]

const Message = ({
  className,
  isFirst,
  event,
  position,
  showName,
  ...rest
}) => {
  const isThem = position === 'left'
  const isSystemMessage = includes(event?.event_type, systemMessages)

  return (
    <MessageContainer
      className="chat-event-message"
      isFirst={isFirst}
      data-testid={`message-${event.event_type}-${event.id}`}
    >
      {isSystemMessage ? (
        <SystemMessage isThem={isThem} event={event} />
      ) : (
        <BubbleMessage showName={showName} isThem={isThem} event={event} />
      )}
    </MessageContainer>
  )
}

export default React.memo(Message)
