import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import CardHeader from './card-header'
import CardDetails from './card-details'
import minusDark from './images/MinusDark.png'
import plusDark from './images/PlusDark.png'

const Wrapper = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 188px;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.20);
  background: ${colors.white};
  background-image: url('${({ url }) => url}');
  background-size: cover;

  @media print {
    box-shadow: none;
    border: 1px solid ${colors.grey};
  }
`

const ToggleIcon = styled.img`
  width: 63px;
  height: 65px;
  cursor: pointer;

  @media print {
    display: none;
  }
`

const ScoreDescription = styled.p`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.2px;
  margin: 0;
  padding: 10px 5px 10px 20px;
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
`

const CardTemplateWhite = props => {
  const {
    cardImage,
    title,
    creditRatingResult,
    avatarUrl,
    creditDescription,
    creditDetails,
    userRole,
    professionId,
    csoVerifiedState,
    salarySort,
    listingData
  } = props

  const [showDetails, setShowDetails] = useState(false)
  const printMedia = useMediaQuery('print')

  return (
    <Wrapper data-testid={props['data-testid']}>
      <CardWrapper onClick={() => setShowDetails(!showDetails)} url={cardImage}>
        <CardHeader
          whiteBg
          title={title}
          rating={creditRatingResult}
          avatarUrl={avatarUrl}
        />
        <BottomWrapper>
          <ScoreDescription>{creditDescription}</ScoreDescription>
          <ToggleIcon src={showDetails ? minusDark : plusDark} />
        </BottomWrapper>
      </CardWrapper>
      {(showDetails || printMedia) && (
        <CardDetails
          data={creditDetails}
          userRole={userRole}
          professionId={professionId}
          csoVerifiedState={csoVerifiedState}
          salarySort={salarySort}
          listingData={listingData}
        />
      )}
    </Wrapper>
  )
}

export default CardTemplateWhite
