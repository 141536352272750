import ReactDOM from 'react-dom'

const PageTitle = ({ children }) => {
  const el = document.getElementById('header-title')
  if (!el) return null
  return ReactDOM.createPortal(
    children,
    document.getElementById('header-title')
  )
}

export default PageTitle
