/* eslint-disable complexity */
const config = () => {
  const GMAPS_KEY = 'AIzaSyDbJ3A2XXOzEfBcNJA2pBMYm950nKy3CXQ'

  const defaultVars = {
    HERE_MAPS_APP_ID: 'Pbbjdufwhc49JwCwaAek',
    HERE_MAPS_APP_CODE: 'uR8qhAzi7xa078tR4utVRQ',
    HERE_MAPS_GEOCODER_SERVER: 'https://geocoder.api.here.com/6.2/geocode.json',
    HERE_MAPS_REVERSE_GEOCODER_SERVER:
      'https://reverse.geocoder.api.here.com/6.2/reversegeocode.json',
    GMAPS_KEY: process.env.REACT_APP_GMAPS_KEY || GMAPS_KEY,
    LINKEDIN_CLIENT_ID: '86woo29flgg5xm',
    LINKEDIN_CLIENT_SECRET: 'TGWxEAkH2U0EiUzm'
  }

  const envs = {
    test: {},
    dev: {
      ...defaultVars,
      BACKEND_SERVER:
        process.env.REACT_APP_BACKEND_SERVER || 'https://be-dev2.machobear.ca',
      GRAPHQL_SERVER:
        process.env.REACT_APP_GRAPHQL_SERVER ||
        'https://nemesis-dev.qb.rentful.net/graphql',
      WEB_URL: 'https://kratos-dev.machobear.ca',
      STRIPE_TOKEN: 'pk_test_ZXncuS0lYHorcgjP3FaM1DXY',
      APP_PATH: process.env.REACT_APP_APP_PATH || '',
      IMAGES_URL: 'https://cdn-dev.machobear.ca',
      VIDEOS_URL: 'https://cdn-dev-videos.machobear.ca',
      NCHAN_SERVER:
        process.env.NCHAN_SERVER || 'https://nchan-dev2.machobear.ca/sse'
    },
    stg: {
      ...defaultVars,
      BACKEND_SERVER:
        process.env.REACT_APP_BACKEND_SERVER || 'https://be-stg.machobear.ca',
      GRAPHQL_SERVER: process.env.REACT_APP_GRAPHQL_SERVER,
      WEB_URL: 'https://kratos-stg.machobear.ca',
      STRIPE_TOKEN: 'pk_test_ZXncuS0lYHorcgjP3FaM1DXY',
      GMAPS_KEY: process.env.REACT_APP_GMAPS_KEY || GMAPS_KEY,
      APP_PATH: process.env.REACT_APP_APP_PATH || '',
      IMAGES_URL: 'https://d3j1zuo7506rra.cloudfront.net',
      NCHAN_SERVER:
        process.env.NCHAN_SERVER || 'https://nchan-dev2.machobear.ca/sse'
    },
    demo: {
      ...defaultVars,
      BACKEND_SERVER:
        process.env.REACT_APP_BACKEND_SERVER || 'https://be-demo.machobear.ca',
      GRAPHQL_SERVER: process.env.REACT_APP_GRAPHQL_SERVER,
      STRIPE_TOKEN: 'pk_test_ZXncuS0lYHorcgjP3FaM1DXY',
      APP_PATH: process.env.REACT_APP_APP_PATH || '',
      NCHAN_SERVER:
        process.env.NCHAN_SERVER || 'https://nchan-dev2.machobear.ca/sse'
    },
    qa: {},
    prod: {
      ...defaultVars,
      BACKEND_SERVER:
        process.env.REACT_APP_BACKEND_SERVER || 'https://be-prod.liv.rent',
      GRAPHQL_SERVER:
        process.env.REACT_APP_GRAPHQL_SERVER ||
        'https://nemesis-prod.liv.rent/graphql',
      WEB_URL: 'https://liv.rent',
      STRIPE_TOKEN: process.env.REACT_APP_STRIPE_TOKEN,
      GMAPS_KEY: process.env.REACT_APP_GMAPS_KEY || GMAPS_KEY,
      APP_PATH:
        typeof process.env.REACT_APP_APP_PATH === 'undefined'
          ? '/app'
          : process.env.REACT_APP_APP_PATH,
      IMAGES_URL: 'https://cdn.liv.rent',
      VIDEOS_URL: 'https://cdn-videos.liv.rent',
      NCHAN_SERVER:
        process.env.NCHAN_SERVER || 'https://nchan-prod.trustliv.com/sse'
    }
  }

  const targetEnv = process.env.REACT_APP_BUILD_ENV || 'dev'
  const conf = {
    ...envs[targetEnv],
    BUILD_ENV: targetEnv
  }

  return conf
}

export default config()
