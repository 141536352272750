import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { pipe, pickAll, evolve, mergeRight } from 'ramda'
import { breakpoints } from 'styles'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import noop from 'lodash/noop'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import useMediaQuery from 'hooks/use-media-query'
import { Row, Group, FieldInput, FieldDropdown } from 'components/form'
import { defaultIfFalsy } from 'utils'
import 'utils/validation'

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const AutomobileForm = React.memo(
  ({ data = {}, onCancel = noop, onSave = noop }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(['translation', 'basic'])
    const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
      ? 12
      : 6

    const ownershipOptions = [
      {
        value: 'lease',
        label: t('r.profile.edit.automobile_ownership_lease.option', 'Lease')
      },
      {
        value: 'finance',
        label: t(
          'r.profile.edit.automobile_ownership_finance.option',
          'Finance'
        )
      },
      {
        value: 'own',
        label: t('r.profile.edit.automobile_ownership_own.option', 'Own')
      }
    ]

    const initialValues = pipe(
      pickAll(['make', 'model', 'model_year', 'ownership']),
      evolve({
        make: defaultIfFalsy(''),
        model: defaultIfFalsy(''),
        model_year: defaultIfFalsy(''),
        ownership: defaultIfFalsy('')
      })
    )(data)

    const validationSchema = Yup.object().shape({
      make: Yup.string().min(1).max(255).required(),
      model: Yup.string().min(1).max(100).required(),
      model_year: Yup.number().positive().min(1885).max(2100).required(),
      ownership: Yup.string().required()
    })

    const isEditing = !!data.id

    const handleDeleteClick = useCallback(() => {
      return confirm(
        t('r.profile.edit.delete_automobile.title', 'Delete Automobile?'),
        t(
          'r.profile.edit.automobile_delete_confirmation.text',
          'Are you sure you want to delete this automobile?'
        )
      ).then(async () => {
        dispatch.profile.deleteVehicle(data.id)
      })
    }, [data.id, dispatch.profile, t])

    const onSubmit = async (values, { setSubmitting }) => {
      let updateData = mergeRight(values, { id: data.id })
      const action = isEditing
        ? dispatch.profile.updateVehicle
        : dispatch.profile.addVehicle
      const response = await action(updateData)
      setSubmitting(false)

      if (!response.error) {
        onSave()
      }
    }
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="make"
                  placeholder={t('r.profile.edit.make.label', 'Make') + '*:'}
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="model"
                  placeholder={t('r.profile.edit.model.label', 'Model') + '*:'}
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="model_year"
                  placeholder={
                    t('r.profile.edit.model_year.label', 'Year') + '*:'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldDropdown
                  id="ownership"
                  name="ownership"
                  placeholder={
                    t('r.profile.edit.ownership.label', 'Ownership Type') + '*:'
                  }
                  options={ownershipOptions}
                  isSearchable={false}
                />
              </Group>
            </Row>

            <Buttons>
              {isEditing ? (
                <ActionButton
                  theme="danger"
                  onClick={handleDeleteClick}
                  size="small"
                >
                  {t('r.profile.edit.delete.button', 'Delete')}
                </ActionButton>
              ) : (
                <ActionButton theme="neutral" onClick={onCancel} size="small">
                  {t('r.profile.edit.cancel.button', 'Cancel')}
                </ActionButton>
              )}
              <ActionButton
                type="submit"
                theme="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isEditing
                  ? t('r.profile.edit.update.button', 'Update')
                  : t('r.profile.edit.add.button', 'Add')}
              </ActionButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    )
  }
)

export default AutomobileForm
