import { useEffect, useState } from 'react'

function useMediaQuery(queryInput: string) {
  const query = queryInput.replace('@media ', '')

  const [matches, setMatches] = useState(() => {
    return window.matchMedia(query).matches
  })

  useEffect(() => {
    const queryList = window.matchMedia(query)
    setMatches(queryList.matches)
    function handleMatchesChange(event: MediaQueryListEvent) {
      setMatches(event.matches)
    }

    queryList.addListener(handleMatchesChange)
    return () => {
      queryList.removeListener(handleMatchesChange)
    }
  }, [query])

  return matches
}

export default useMediaQuery
