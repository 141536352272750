import { useRef, useEffect } from 'react'

const useCallOnChange = (what, handler) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    savedHandler.current(what)
  }, [what])
}

export default useCallOnChange
