import styled, { css } from 'styled-components'
import React, { Fragment } from 'react'
import { breakpoints, colors, zIndex } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import useHasHeightChanged from 'hooks/use-has-height-changed'
import { useTranslation } from 'react-i18next'
import config from 'config'
import LanguageSelector from './language-selector'
import logo from './images/liv-rent-logo.svg'
import facebook from './images/facebook.svg'
import twitter from './images/twitter.svg'
import instagram from './images/instagram.svg'
import appStoreBadge from './images/app-store-badge.svg'
import googlePlayBadge from './images/google-play-badge.png'

const { WEB_URL } = config

const DetailStyle = css`
  @media (max-width: 1025px) {
    margin-bottom: 94px;
    width: 100%;
    display: inline-block;
  }

  @media (max-width: 479px) {
    margin-top: 0px;
    margin-bottom: 55px;
  }
`

const Wrapper = styled.div`
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: auto;
  z-index: ${zIndex.footer};
  background-color: ${colors.white};
  ${({ detail }) => (detail ? DetailStyle : {})}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    ${({ hideOnMobile }) => hideOnMobile && 'display: none'};
  }
`

const FooterContainer = styled.div`
  padding-top: ${({ minimal }) => (minimal ? 0 : 50)}px;
  background-color: #0e0303;
  background-image: -webkit-linear-gradient(270deg, #303030, #101010),
    -webkit-linear-gradient(270deg, #101010, #101010);
  background-image: linear-gradient(180deg, #303030, #101010),
    linear-gradient(180deg, #101010, #101010);

  @media screen and (max-width: 890px) {
    padding-bottom: ${({ minimal }) => (minimal ? 0 : 34)}px;
    padding-right: 20px;
    padding-left: 20px;
  }
`

const InnerFooterContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1096px;
  margin-bottom: 0px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: ${({ minimal }) => (minimal ? 12 : 30)}px;
  padding-top: ${({ minimal }) => (minimal ? 12 : 0)}px;

  @media screen and (max-width: 890px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    max-width: initial;
    width: 100%;
  }
`

const LinkContainer = styled.div`
  border-bottom: 1px solid #888;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 890px) {
    flex-direction: column;
    align-items: center;
  }
`

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 890px) {
    align-items: center;
    width: 100%;
  }
`

const PrimaryLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  padding-bottom: 50px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 890px) {
    max-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding-bottom: 15px;
  }
`

const Link = styled.a`
  font-size: 14px;
  line-height: 22px;
  position: relative;
  display: inline-block;
  padding-right: 35px;
  color: ${colors.white};
  text-decoration: none;

  &:hover {
    color: #fe5f55;
  }

  @media screen and (max-width: 890px) {
    padding-right: 13px;
    display: inline;
  }

  @media screen and (max-width: 479px) {
    position: static;
    padding-right: 0px;
    padding-bottom: 20px;
    justify-content: center;
  }
`

const LastLink = styled(Link)`
  padding-right: 0px;

  @media screen and (max-width: 890px) {
    padding-right: 0px;
  }
`

const LivLogoLink = styled.a`
  position: relative;
  display: inline-block;
  padding-right: 50px;

  @media screen and (max-width: 890px) {
    display: inline;
    padding-right: 20px;
  }

  @media screen and (max-width: 479px) {
    position: static;
    padding-right: 0px;
    padding-bottom: 35px;
    justify-content: center;
  }
`

const LivLogo = styled.img`
  height: 26px;
`

const AppDownloadLinks = styled.div`
  @media screen and (max-width: 890px) {
    display: inline;
    text-align: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 479px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const DownloadBadge = styled.img`
  height: 38px;
  padding-right: 25px;

  @media screen and (max-width: 479px) {
    padding-right: 0px;
    margin-bottom: 25px;
  }
`

const LastDownloadBadge = styled(DownloadBadge)`
  padding-right: 0px;

  @media screen and (max-width: 479px) {
    margin-bottom: 0px;
  }
`

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SecondaryLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 65px;

  @media screen and (max-width: 890px) {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 479px) {
    padding-bottom: 20px;
  }
`

const SocialLinks = styled.div`
  display: flex;

  @media screen and (max-width: 890px) {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    align-items: flex-end;
    text-align: center;
  }

  @media screen and (max-width: 479px) {
    margin-top: 16px;
    justify-content: space-around;
  }
`

const IconLink = styled.a`
  margin-right: 13px;
  margin-left: 13px;
  padding: 3px 16px 3px 3px;
  opacity: 1;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: 890px) {
    padding-right: 16px;
  }
`

const LastIconLink = styled(IconLink)`
  margin-right: 0px;
  padding: 3px 3px 3px 3px;

  @media screen and (max-width: 890px) {
    margin-right: 13px;
  }
`

const SubFooter = styled.div`
  margin-top: ${({ minimal }) => (minimal ? 0 : 20)}px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CopyrightText = styled.p`
  color: ${colors.white};
  font-size: 14px;
  text-align: left;
  font-weight: 300;
  line-height: 18px;
  margin: 10px 0px;

  @media screen and (max-width: 598px) {
    padding-top: ${({ minimal }) => (minimal ? 0 : 25)}px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    font-size: 12px;
  }
`

const currentYear = new Date().getFullYear()

const Footer = ({ detail, minimal, hideOnMobile, pushDownOnMobile = true }) => {
  const { t, i18n } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const copyrightText = displayMobile
    ? `Liv Strategies Inc. © ${currentYear}`
    : `Liv Strategies Inc. © ${currentYear} | ` +
      `Vancouver, BC | ${t(
        'b.footer.view.apartments_for_rent.text',
        'Apartments for Rent'
      )}`
  const hasHeightChanged = useHasHeightChanged()
  const pushDown = hasHeightChanged && pushDownOnMobile

  return (
    <Fragment>
      {minimal ? (
        <Wrapper
          detail={detail}
          minimal
          hideOnMobile={hideOnMobile}
          pushDown={pushDown}
        >
          <FooterContainer minimal>
            <InnerFooterContainer minimal>
              <SubFooter minimal>
                <CopyrightText minimal>{copyrightText}</CopyrightText>
                <LanguageSelector
                  value={i18n.language}
                  onChange={lang => i18n.changeLanguage(lang)}
                />
              </SubFooter>
            </InnerFooterContainer>
          </FooterContainer>
        </Wrapper>
      ) : (
        <Wrapper detail={detail} hideOnMobile={hideOnMobile}>
          <FooterContainer>
            <InnerFooterContainer>
              <LinkContainer>
                <LeftContainer>
                  <PrimaryLinks>
                    <LivLogoLink href={WEB_URL}>
                      <LivLogo src={logo} alt="Liv.rent Logo" />
                    </LivLogoLink>
                    <Link href="https://liv.rent/about.html">
                      {t('b.footer.view.about.link', 'About')}
                    </Link>
                    <Link href="https://blog.liv.rent">
                      {t('b.footer.view.blog.link', 'Blog')}
                    </Link>
                    <Link href="https://liv.rent/press.html">
                      {t('b.footer.view.press.link', 'Press')}
                    </Link>
                    <Link href="https://liv.rent/faq.html">
                      {t('b.footer.view.faq.link', 'FAQ')}
                    </Link>
                    <Link href="https://liv.rent/terms.html">
                      {t('b.footer.view.terms.link', 'Terms')}
                    </Link>
                    <Link href="https://liv.rent/privacy.html">
                      {t('b.footer.view.privacy.link', 'Privacy')}
                    </Link>
                    <LastLink href="https://angel.co/liv-rent/jobs">
                      {t('b.footer.view.join_us.link', 'Join Us')}
                    </LastLink>
                  </PrimaryLinks>
                  <AppDownloadLinks>
                    <a
                      href="https://itunes.apple.com/us/app/liv/id1321741040"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadBadge
                        src={appStoreBadge}
                        alt="Download from App Store"
                      />
                    </a>
                    <a
                      href={
                        'https://play.google.com/store/apps' +
                        '/details?id=rent.liv.ether'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LastDownloadBadge
                        src={googlePlayBadge}
                        alt="Download from Google Play"
                      />
                    </a>
                  </AppDownloadLinks>
                </LeftContainer>
                <RightContainer>
                  <SecondaryLinks>
                    <Link
                      href="https://liv.rent/app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('b.footer.view.sign_contract.link', 'Sign Contract')}
                    </Link>
                    <LastLink
                      href="https://verify.liv.rent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('b.footer.view.verify_signing.link', 'Verify Signing')}
                    </LastLink>
                  </SecondaryLinks>
                  <SocialLinks>
                    <IconLink
                      href="https://twitter.com/liv_rent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={twitter} alt="Twitter Icon" />
                    </IconLink>
                    <IconLink
                      href="https://www.instagram.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={instagram} alt="Instagram Icon" />
                    </IconLink>
                    <LastIconLink
                      href="https://www.facebook.com/liv.rent/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={facebook} alt="Facebook Icon" />
                    </LastIconLink>
                  </SocialLinks>
                </RightContainer>
              </LinkContainer>
              <SubFooter>
                <CopyrightText>{copyrightText}</CopyrightText>
                <LanguageSelector
                  value={i18n.language}
                  onChange={lang => i18n.changeLanguage(lang)}
                />
              </SubFooter>
            </InnerFooterContainer>
          </FooterContainer>
        </Wrapper>
      )}
    </Fragment>
  )
}

Footer.defaultProps = {
  detail: false,
  minimal: false
}

export default Footer
