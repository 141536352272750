import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { pipe, path, values, filter, propEq, anyPass } from 'ramda'
import { colors, fontWeights, breakpoints } from 'styles'
import { Link } from 'react-router-dom'
import LivScoreCard from 'components/liv-score-card'
import ProfileRequired from 'components/profile-required'
import { showImageModal } from 'components/image-modal'
import config from 'config'
import { showVerificationModal } from './verification-modal'
import Form from './liv-score-form'
import { PanelHeader, PanelTitle, StyledPanel, RequiredText } from '../elements'

const { BACKEND_SERVER } = config

const EditButton = styled.button`
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: center;
  }
`

const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 40px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    margin: 40px 0 0 0;
  }
`

const AddDocsTextSmall = styled.p`
  color: ${colors.darkGrey};
  font-size: 12px;
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0;
`

const AddDocsItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 40px;

  p {
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    margin: 10px 0;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-right: 0;
  }
`

const ViewText = styled.p`
  color: ${colors.secondary};
  cursor: pointer;
`

const getFinancialFiles = pipe(
  values,
  filter(
    anyPass([
      propEq('tag', 'school_verification'),
      propEq('tag', 'employment_verification'),
      propEq('tag', 'income_verification')
    ])
  )
)

const LivScore = ({
  data,
  userRole,
  livScore,
  editable = false,
  editProps = {},
  showRequiredWarning = false,
  listingData = false,
  history
}) => {
  const { t } = useTranslation()
  const secureFiles = getFinancialFiles(data.files.user_secure_files)
  const accountType = useSelector(path(['profile', 'account_type']))
  const personalVerifiedState = useSelector(
    path(['profile', 'identity_verified_state'])
  )
  const businessVerifiedState = useSelector(
    path(['profile', 'company_info', 'business_licence_verified_state'])
  )
  const landlordRole = useSelector(path(['profile', 'user_role']))

  const isPersonalAccount = () => {
    return (
      userRole === 'landlord' &&
      (accountType === 'personal' || accountType === 'family')
    )
  }

  const isBusinessAccount = () => {
    return userRole === 'landlord' && accountType === 'business'
  }

  const isUserVerified = () => {
    return (
      (isPersonalAccount() && personalVerifiedState === 'verified') ||
      (isBusinessAccount() &&
        businessVerifiedState === 'verified' &&
        personalVerifiedState === 'verified')
    )
  }

  const notUserVerified = () => {
    return userRole === 'landlord' && personalVerifiedState === null
  }

  const isUserPending = () => {
    return userRole === 'landlord' && personalVerifiedState === 'pending'
  }

  const notBusinessVerifiedAdmin = () => {
    return (
      isBusinessAccount() &&
      landlordRole === 'administrator' &&
      businessVerifiedState === null
    )
  }

  const notBusinessVerifiedAgent = () => {
    return (
      isBusinessAccount() &&
      landlordRole === 'agent' &&
      businessVerifiedState === null
    )
  }

  const isBusinessLandlordPending = () => {
    return isBusinessAccount() && businessVerifiedState === 'pending'
  }

  const documentTypes = {
    t4: t('r.profile.view.t4.text', 'T4'),
    tax_slip: t('r.profile.view.taxslip.text', 'Tax Slips'),
    payslip: t('r.profile.view.payslip.text', 'Pay Slip'),
    bank_statement: t('r.profile.view.bank_statement.text', 'Bank Statement'),
    business_card: t('r.profile.view.business_card.text', 'Business Card'),
    job_offer: t('r.profile.view.job_offer_letter.text', 'Job Offer Letter'),
    student_loan: t('r.profile.view.student_loans.text', 'Student Loans'),
    school_id: t('r.profile.view.school_id.text', 'School ID'),
    school_letter: t('r.profile.view.school_letter.text', 'Enrolment Letter'),
    school_verification: t(
      'r.profile.view.school_verification.text',
      'School Verification'
    ),
    income_verification: t(
      'r.profile.view.income_verification.text',
      'Income Verification'
    ),
    employment_verification: t(
      'r.profile.view.employment_verification.text',
      'Employment Verification'
    )
  }

  const handleViewSecureFile = secureFile => {
    if (isUserVerified()) {
      showImageModal({
        src: `${BACKEND_SERVER}/secure/${secureFile.tag}/${secureFile.id}/tenant_data.png`
      })
    } else if (isUserPending()) {
      showVerificationModal({
        warningMessage: t(
          'l.profile.view.secure_docs.pending.warning',
          "We've received your ID submission! Hold tight while we validate your submitted document."
        ),
        buttonText: 'Ok',
        history
      })
    } else if (notUserVerified()) {
      showVerificationModal({
        warningMessage: t(
          'l.profile.view.secure_docs.not_verified.warning',
          "Oops! You can't see tenant's personal documents until you verify your ID."
        ),
        buttonText: 'Verify Now',
        history
      })
    } else if (notBusinessVerifiedAdmin()) {
      showVerificationModal({
        warningMessage: t(
          'l.profile.view.secure_docs.not_business_admin_verified.warning',
          "Oops! You can't see tenant's personal documents until you verify your business."
        ),
        buttonText: 'Verify Now',
        business: true,
        history
      })
    } else if (notBusinessVerifiedAgent()) {
      showVerificationModal({
        warningMessage: t(
          'l.profile.view.secure_docs.not_business_agent_verified.warning',
          "Oops! You can't see tenant's personal documents until you verify your business. Please contact an administrator to verify the business licence."
        ),
        buttonText: 'Ok',
        history
      })
    } else if (isBusinessLandlordPending()) {
      showVerificationModal({
        warningMessage: t(
          'l.profile.view.secure_docs.business_verification_pending.warning',
          "We've received your business licence submission! Hold tight while we validate your submitted document."
        ),
        buttonText: 'Ok',
        history
      })
    } else {
      showImageModal({
        src: `${BACKEND_SERVER}/secure/${secureFile.tag}/${secureFile.id}/form.png`
      })
    }
  }

  return (
    <>
      <PanelHeader>
        <PanelTitle>
          {t('r.profile.view.liv_score.subtitle', 'Liv Score')}
          <ProfileRequired fields={['liv_verified_state']} area="liv_score" />
        </PanelTitle>
        {editable && (
          <Link to={{ pathname: '/profile/liv-score/edit', state: editProps }}>
            <EditButton data-cy="edit_liv_score">
              {t('r.profile.view.edit.button', 'Edit')}
            </EditButton>
          </Link>
        )}
      </PanelHeader>
      {showRequiredWarning && (
        <RequiredText>
          {t('r.profile.view.required.label', '*Required')}
        </RequiredText>
      )}
      <StyledPanel hSpace={40} vSpace={40}>
        <Wrapper>
          <LivScoreCard
            userRole={userRole}
            creditDetails={livScore}
            avatarUrl={data.avatar.aws_s3_key}
            professionId={data.job_title}
            csoVerifiedState={data.cso_verified_state}
            salarySort={data.salary_sort}
            listingData={listingData}
            data-testid="livscore-card"
          />
          {livScore?.credit_rating && secureFiles.length > 0 && (
            <DocumentsWrapper data-testid="document-list">
              <AddDocsTextSmall>
                {t(
                  'r.profile.view.additional_documents.text',
                  'Additional Documents Uploaded'
                )}
              </AddDocsTextSmall>
              {secureFiles.map(secureFile => {
                const docType = documentTypes[secureFile.tag]
                return secureFile ? (
                  <AddDocsItem key={secureFile.tag} data-testid="document-item">
                    <p>{docType}</p>
                    <ViewText onClick={() => handleViewSecureFile(secureFile)}>
                      {t('r.profile.view.view.text', 'View')}
                    </ViewText>
                  </AddDocsItem>
                ) : null
              })}
            </DocumentsWrapper>
          )}
        </Wrapper>
        <Form userRole={userRole} data={data} />
      </StyledPanel>
    </>
  )
}

export default withRouter(React.memo(LivScore))
