import React from 'react'
import useProfile from 'hooks/use-profile'
import { isEmpty, props as ramdaProps, all, not, and, isNil } from 'ramda'
import Required from 'components/required-indicator'

const ProfileRequired = ({ area, fields = [], ...rest }) => {
  const { profile_progress } = useProfile()
  const missing = profile_progress?.[area]?.missing || []
  return isEmpty(missing) ||
    and(
      not(isEmpty(fields)),
      all(isNil, ramdaProps(fields, missing))
    ) ? null : (
    <Required {...rest} />
  )
}

export default ProfileRequired
