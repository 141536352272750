import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { omit, pick } from 'ramda'
import { useDropzone } from 'react-dropzone'
import { colors, breakpoints } from 'styles'
import Panel from 'components/panel'

const Wrapper = styled(Panel)`
  border: 1px dashed #a5a5a5;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 28px 0 28px;
  outline: none;
  position: relative;
  border-radius: 6px;

  &.dropzone--isActive {
    border-color: #4a90e2;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 15px 15px 0 15px;
  }
`

const DropHere = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: -20px 0 0 -28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  color: ${colors.white};
  font-size: 28px;

  ${props =>
    !props.visible &&
    `
    display: none;
  `}
`

const inputKeys = ['onClick', 'onKeyDown', 'onFocus', 'onBlur']

const UploadPanel = ({ hasItems, disabled, children, ...rest }) => {
  const [t] = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone(rest)
  const rootProps = getRootProps()
  const boxProps = omit(hasItems ? inputKeys : [], rootProps)
  const clickProps = pick(hasItems ? inputKeys : [], rootProps)
  return (
    <Wrapper {...(disabled ? {} : boxProps)}>
      {children(clickProps)}
      <DropHere visible={isDragActive}>
        {t('l.upload_panel.edit.drop_here.placeholder', 'Drop here')}
      </DropHere>
      <input {...getInputProps()} />
    </Wrapper>
  )
}

export default React.memo(UploadPanel)
