import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import { Formik, Form } from 'formik'
import { Row, Group, FieldInput } from 'components/form'
import { Button } from 'components/button'
import Icon from 'components/icon'
import P from 'components/paragraph'
import { show as showSuccessModal } from 'components/success-modal'
import { requestResetPassword } from 'models/auth/ports'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import noop from 'lodash/noop'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  border-radius: 5px;
  background-color: ${colors.white};
`

const ModalClose = styled.div`
  text-align: right;
  padding: 25px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
`

const TitleText = styled.h1`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
  margin-bottom: 20px;
`

const DescriptionText = styled(P)`
  margin: 30px 20px;
`

const LoginForm = styled(Form)`
  width: 100%;
  padding: 0 30px;
`

const ButtonContainer = styled(Group)`
  text-align: center;
  margin: 15px auto;
`

const ResetPasswordModal = ({
  logoutBeforeSending = false,
  onSubmit = noop
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { close } = useModalize()

  const validationSchema = Yup.object().shape({
    username: Yup.string().required()
  })

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (logoutBeforeSending) {
      await dispatch.auth.logout()
    }
    const { response } = await requestResetPassword(values)
    setSubmitting(false)
    if (!response.ok) return
    close()
    return showSuccessModal({
      title: t('b.auth.edit.reset_sent.title', 'Password reset request sent.'),
      message: t(
        'b.auth.edit.reset_sent.text',
        'The password reset link will be sent to the email associated with your account. If you do not receive the reset link within 10-15 minutes, please try this process again.'
      ),
      onClose: onSubmit
    })
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <TitleText>
          {t('b.auth.view.reset_password.title', 'Reset Password')}
        </TitleText>
        <DescriptionText>
          {t(
            'b.auth.view.reset_description.text',
            'A link to reset your password will be sent to the email associated with your account.'
          )}
        </DescriptionText>
        <Formik
          initialValues={{ username: '' }}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid }) => (
            <LoginForm>
              <Row>
                <Group col={12}>
                  <FieldInput
                    name="username"
                    autoFocus
                    placeholder={t('b.auth.view.email.label', 'Email')}
                  />
                </Group>
              </Row>
              <Row>
                <ButtonContainer col={12}>
                  <Button
                    id="web-reset-password-button"
                    theme="primary"
                    width="100%"
                    disabled={isSubmitting || !isValid}
                    type="submit"
                  >
                    {t('b.auth.view.reset.button', 'Reset')}
                  </Button>
                </ButtonContainer>
              </Row>
            </LoginForm>
          )}
        </Formik>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showResetPasswordModal = createModal(ResetPasswordModal)
