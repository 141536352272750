import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { select } from 'store'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { colors } from 'styles'
import { show as showEndTenancyModal } from 'features/EndTenancy/end-tenancy-modal'
import PageSubtitle from 'components/page-subtitle'

const Wrapper = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 50px auto;
`

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 30px;
  margin: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.mediumLightGrey};
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  :hover {
    border-color: ${colors.secondary};
  }
`

const LabelText = styled.p``

const ActionText = styled.p`
  color: ${colors.secondary};
`

const Resources = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const loadEndTenancyData = dispatch.endTenancy.loadAll

  useEffect(() => {
    loadEndTenancyData({ listingId })
  }, [listingId, loadEndTenancyData])

  const endTenancyRequestData = useSelector(state =>
    select.endTenancy.endTenancyRequestData(state, { listingId })
  )

  const actionText = () => {
    if (
      endTenancyRequestData?.end_response === 'declined' ||
      endTenancyRequestData?.end_response === 'support_requested'
    )
      return 'Request'
    const states = { done: 'Completed', active: 'Requested' }
    return states[endTenancyRequestData?.state_machine] || 'Request'
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {t('r.rental_dashboard.edit.resources.title', 'Resources')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageSubtitle>{t('b.view.resources.link', 'Resources')}</PageSubtitle>
      <RowWrapper onClick={() => showEndTenancyModal({ listingId })}>
        <LabelText>
          {t(
            'r.rental_dashboard.edit.resources_end_tenancy.text',
            'End Tenancy'
          )}
        </LabelText>
        <ActionText>
          {t(
            `r.rental_dashboard.edit.resources_end_tenancy_${
              endTenancyRequestData?.state_machine || 'request'
            }.text`,
            actionText()
          )}
        </ActionText>
      </RowWrapper>
      <RowWrapper
        onClick={() =>
          history.push(`/rental/${listingId}/resources/contract-history`)
        }
      >
        <LabelText>
          {t(
            'r.rental_dashboard.edit.resources_contract_history.text',
            'Contract History'
          )}
        </LabelText>
      </RowWrapper>
    </Wrapper>
  )
}

export default withRouter(Resources)
