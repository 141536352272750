import React from 'react'
import { confirmable } from 'react-confirm'
import BaseDialog from './base'

const isDisabled = () => false

const Confirmation = ({ confirmation, ...rest }) => (
  <BaseDialog {...rest} isDisabled={isDisabled}>
    {confirmation}
  </BaseDialog>
)

export default confirmable(Confirmation)
