import React from 'react'
import styled from 'styled-components'
import join from 'utils/join'
import some from 'utils/some'

const Wrapper = styled.span`
  margin: 0;
`

const CompanyAddress = ({ address, state, city, zip, className }) =>
  some(address, state, city, zip) ? (
    <Wrapper className={className}>
      {address && `${address},`}
      {address && <br />}
      {join([state, city, zip], ', ')}
    </Wrapper>
  ) : null

CompanyAddress.defaultProps = {
  address: null,
  state: null,
  city: null,
  zip: null,
  className: ''
}

export default CompanyAddress
