import React from 'react'
import { useTranslation } from 'react-i18next'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import { CollapsibleBox } from 'components/collapsible'
import {
  Label,
  FieldInput,
  FieldDropdown,
  FieldSwitchToggle,
  FieldTextarea
} from 'components/form'
import { StyledRow, StyledGroup } from './elements'

const ParkingStorage = ({ values }) => {
  const { t } = useTranslation()

  return (
    <>
      <AreaTitle>
        {t('l.listing.edit.additional_terms.label', 'Additional Rental Terms')}
      </AreaTitle>
      <Panel>
        <CollapsibleBox
          title={t('l.listing.edit.parking.label', 'Parking')}
          maxLength={22}
        >
          <StyledRow>
            <StyledGroup col={12} inline justify="space-between">
              <Label htmlFor="parking.enabled" noGutter>
                {t('l.listing.edit.parking_included.label', 'Parking included')}
              </Label>
              <FieldSwitchToggle name="parking.enabled" id="parking.enabled" />
            </StyledGroup>
          </StyledRow>
          {values.parking.enabled && (
            <>
              <StyledRow>
                <StyledGroup col={12}>
                  <FieldTextarea
                    name="parking.name"
                    id="parking.name"
                    maxLength={100}
                    placeholder={t(
                      'l.listing.edit.write_info.placeholder',
                      'Write info'
                    )}
                  />
                </StyledGroup>
              </StyledRow>
              <StyledRow>
                <StyledGroup col={12} inline justify="space-between">
                  <Label htmlFor="parking.hasFee" noGutter>
                    {t('l.listing.edit.parking_fee.label', 'Parking fee')}
                  </Label>
                  <FieldSwitchToggle
                    name="parking.hasFee"
                    id="parking.hasFee"
                  />
                </StyledGroup>
              </StyledRow>
              {values.parking.hasFee && (
                <>
                  <StyledRow>
                    <StyledGroup col={6}>
                      <FieldInput
                        name="parking.fee"
                        id="parking.fee"
                        prefix="$"
                        placeholder={
                          t(
                            'l.listing.edit.parking_fee_amount.label',
                            'Parking fee amount'
                          ) + '*:'
                        }
                      />
                    </StyledGroup>
                    <StyledGroup col={6}>
                      <FieldDropdown
                        name="parking.fee_frequency_txt_id"
                        id="parking.fee_frequency_txt_id"
                        placeholder={
                          t(
                            'l.listing.edit.payment_frequency.label',
                            'Payment frequency'
                          ) + '*:'
                        }
                        options={[
                          {
                            value: 'weekly',
                            label: t(
                              'l.listing.edit.payment_frequency_weekly.text',
                              'Weekly'
                            )
                          },
                          {
                            value: 'monthly',
                            label: t(
                              'l.listing.edit.payment_frequency_monthly.text',
                              'Monthly'
                            )
                          },
                          {
                            value: 'one_time',
                            label: t(
                              'l.listing.edit.payment_frequency_one_time.text',
                              'One-time'
                            )
                          }
                        ]}
                        isSearchable={false}
                      />
                    </StyledGroup>
                  </StyledRow>
                </>
              )}
            </>
          )}
        </CollapsibleBox>
        <CollapsibleBox
          title={t('l.listing.edit.storage.label', 'Storage')}
          top={40}
        >
          <StyledRow>
            <StyledGroup col={12} inline justify="space-between">
              <Label htmlFor="storage.enabled" noGutter>
                {t('l.listing.edit.storage_included.label', 'Storage included')}
              </Label>
              <FieldSwitchToggle name="storage.enabled" id="storage.enabled" />
            </StyledGroup>
          </StyledRow>
          {values.storage.enabled && (
            <>
              <StyledRow>
                <StyledGroup col={12}>
                  <FieldTextarea
                    name="storage.name"
                    id="storage.name"
                    maxLength={100}
                    placeholder={t(
                      'l.listing.edit.write_info.placeholder',
                      'Write info'
                    )}
                  />
                </StyledGroup>
              </StyledRow>
              <StyledRow>
                <StyledGroup col={12} inline justify="space-between">
                  <Label htmlFor="storage.hasFee" noGutter>
                    {t('l.listing.edit.storage_fee.label', 'Storage fee')}
                  </Label>
                  <FieldSwitchToggle
                    name="storage.hasFee"
                    id="storage.hasFee"
                  />
                </StyledGroup>
              </StyledRow>
              {values.storage.hasFee && (
                <>
                  <StyledRow>
                    <StyledGroup col={6}>
                      <FieldInput
                        name="storage.fee"
                        id="storage.fee"
                        prefix="$"
                        placeholder={
                          t(
                            'l.listing.edit.storage_fee_amount.label',
                            'Storage fee amount'
                          ) + '*:'
                        }
                      />
                    </StyledGroup>
                    <StyledGroup col={6}>
                      <FieldDropdown
                        name="storage.fee_frequency_txt_id"
                        id="storage.fee_frequency_txt_id"
                        placeholder={
                          t(
                            'l.listing.edit.payment_frequency.label',
                            'Payment frequency'
                          ) + '*:'
                        }
                        options={[
                          {
                            value: 'weekly',
                            label: t(
                              'l.listing.edit.payment_frequency_weekly.text',
                              'Weekly'
                            )
                          },
                          {
                            value: 'monthly',
                            label: t(
                              'l.listing.edit.payment_frequency_monthly.text',
                              'Monthly'
                            )
                          },
                          {
                            value: 'one_time',
                            label: t(
                              'l.listing.edit.payment_frequency_one_time.text',
                              'One-time'
                            )
                          }
                        ]}
                        isSearchable={false}
                      />
                    </StyledGroup>
                  </StyledRow>
                </>
              )}
            </>
          )}
        </CollapsibleBox>
      </Panel>
    </>
  )
}

export default ParkingStorage
