import React, { useRef } from 'react'
import { useField } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import { Input } from 'components/form'
import SelectorItemBase from './selector-item-base'

const SelectorItem = ({
  name,
  type,
  value,
  checked,
  onChange,
  children,
  disabled,
  inputId,
  ...rest
}) => {
  const [field] = useField(name)
  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })
  return (
    <SelectorItemBase disabled={disabled} {...rest}>
      {children}
      <Input
        ref={fieldRef}
        type={type}
        checked={checked}
        {...field}
        value={value}
        disabled={disabled}
        onChange={onChange || field.onChange}
        id={inputId}
      />
    </SelectorItemBase>
  )
}

export default React.memo(SelectorItem)
