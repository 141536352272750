import React from 'react'
import Slider from 'react-slick'
import { getImageUrl } from 'utils/image'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const PhotoSlider = props => (
  <Slider {...props}>
    {props.images.map((image, index) => (
      <div key={image.id}>
        <img
          src={getImageUrl(image.aws_s3_key)}
          className="photo-carousel-image"
          onLoad={() => window.dispatchEvent(new Event('resize'))}
          alt=""
        />
      </div>
    ))}
  </Slider>
)

export default PhotoSlider
