import React from 'react'
import { useTranslation } from 'react-i18next'

const NameBanner = ({ names }) => {
  const { t } = useTranslation()
  const and = (pre, post) =>
    t('b.chat.menu.group_chat.label', '{{pre}} & {{post}}', { pre, post })
  const more = count =>
    t('b.chat.menu.group_chat_more.label', '{{count}} more...', { count })
  const people = names.length
  if (people === 1) return names[0]
  if (people === 2) return and(names[0], names[1])
  return (
    <>
      {and(names[0], '')}
      <span title={names.slice(1).join(', ')}>{more(people - 1)}</span>
    </>
  )
}

export default NameBanner
