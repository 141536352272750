import React from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation, Trans } from 'react-i18next'
import { fontSizes, fontWeights, colors } from 'styles'
import useRole from 'hooks/use-role'
import { FieldDatePicker, FieldTextarea } from 'components/form'
import { Button } from 'components/button'

const Title = styled.h2`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  margin: 25px 0px;
`

const Label = styled.p`
  margin: 25px 0px;
`

const Description = styled.p`
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 10px 0 20px;
`

const StyledDescription = styled(Description)`
  color: ${colors.pink};
`

const SubmitButton = styled(Button)`
  display: block;
  padding: 10px 20px;
  margin: 25px auto 0px auto;
  border-radius: 2px;
`

const FormComponent = ({ listingId, onSubmit, close }) => {
  const [t] = useTranslation()
  const userRole = useRole()
  const isLandlord = userRole === 'landlord'

  const formOptions = () => ({
    initialValues: {
      motive_end_date: '',
      motive_end_reason: ''
    },
    validationSchema: Yup.object().shape({
      motive_end_date: Yup.string().required(),
      motive_end_reason: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await onSubmit({
        values,
        listingId,
        requestId: '313'
      })
      setSubmitting(false)
      if (response?.response?.ok) {
        close()
      }
    }
  })
  return (
    <Formik {...formOptions()}>
      {({ values }) => (
        <Form>
          <Title>
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal.title',
              'End Tenancy Request'
            )}
          </Title>
          <FieldDatePicker
            name="motive_end_date"
            placeholder={t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_end_date_label.text',
              '1. Select your desired end date'
            )}
            disablePast
          />
          {isLandlord && (
            <Description>
              <Trans i18nKey="r.rental_dashboard.edit.resources_end_tenancy_modal_end_date_description.text">
                Remember, you must give <strong>2 months</strong> notice to your
                tenant prior to desired move out date.
              </Trans>
            </Description>
          )}
          {
            // prettier-ignore
            <Label>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_textarea_label.text',
                '2. What\'s the reason for your request?'
              )}
            </Label>
          }
          <FieldTextarea
            name="motive_end_reason"
            maxLength={230}
            height={185}
            placeholder={t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_reason_textarea.placeholder',
              'Please state your reasons here.'
            )}
          />
          {isLandlord && (
            <StyledDescription>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_textarea_support.text',
                '** If your tenant is no longer reachable, you may reach Liv Support to manually end the tenancy after submitting this request.'
              )}
            </StyledDescription>
          )}
          <SubmitButton type="submit" theme="primary">
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_submit.button',
              'Send Request'
            )}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default FormComponent
