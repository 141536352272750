import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { propEq, isEmpty, omit } from 'ramda'
import { Helmet } from 'react-helmet'
import { Prompt } from 'react-router'
import { Link } from 'react-router-dom'
import Page from 'components/page'
import AreaTitle from 'components/area-title'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import { Button } from 'components/button'
import { toast } from 'components/toast-notifications'
import ButtonBar from 'features/Listing/button-bar'
import BuildingInfo from 'features/Building/building-info/small'
import { Error } from 'components/form'
import ContentWrapper from 'components/content-wrapper'
import Details from './details'
import Terms from './terms'
import Features from './features'
import ParkingStorage from './parking-storage'
import Description from './description'
import ImageUpload from './image-upload'
import VideoUpload from './video-upload'

const isDraft = propEq('state_machine', 'draft')

const Detail = ({ data, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const updateUnit = dispatch.listing.updateUnit
  const sortImages = dispatch.listing.sortImages
  const uploadToast = useRef()
  const [uploading, setUploading] = useState(false)

  const videoSuccessMessage = () => {
    const { full_street_name, city, state } = data.building
    const videoAddress = `${full_street_name}, ${city} ${state}`
    return (
      <Trans i18nKey="l.listing.edit.video_added_to.text">
        A video has been added to <strong>{{ videoAddress }}</strong>.
      </Trans>
    )
  }

  const saveImageSort = async images => {
    const { response } = await sortImages(images)
    return response
  }

  const save = async values => {
    if (values.images && values.images.length > 0) {
      const sortResponse = await saveImageSort(values.images)
      if (!sortResponse.ok) return sortResponse
    }

    const { response } = await updateUnit(values)
    return response
  }

  const saveAndExit = async values => {
    const response = await save(values)
    if (response.ok) {
      history.push('/listings')
    }
  }

  const saveAndPreview = async (values, cb) => {
    const response = await save(values)
    cb()
    if (response.ok) {
      const next = isDraft(values) ? 'preview/' : ''
      history.push(`/listing/${values.listing_id}/${next}details`)
    }
  }

  const getButtonText = () =>
    isDraft(data)
      ? t('l.listing.edit.preview.button', 'Preview Draft')
      : t('l.listing.edit.update.button', 'Update')

  const validateMin = (values, min, max) => {
    if (values[max] && values[min] && +values[min] > +values[max]) {
      return {
        [min]: t(
          'l.listing.edit.min_max_range.error',
          "Value can't be greater than the max value"
        )
      }
    }
    return {}
  }

  const validateMax = (values, min, max) => {
    if (values[min] && values[max] && +values[max] < +values[min]) {
      return {
        [max]: t(
          'l.listing.edit.max_min_range.error',
          "Value can't be less than the min value"
        )
      }
    }
    return {}
  }

  const validateMinMax = (values, min, max) => {
    return {
      ...validateMin(values, min, max),
      ...validateMax(values, min, max)
    }
  }

  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      count_bedrooms: Yup.mixed().when('unit_type_scope_txt_id', {
        is: 'single',
        then: Yup.number(),
        otherwise: Yup.number().min(0).max(99).required()
      }),
      count_full_bathrooms: Yup.number().min(0).max(99.5).required(),
      count_dens: Yup.number().min(0).max(99),
      max_occupancy: Yup.number().min(1),
      count_full_shared_bathrooms: Yup.mixed().when('unit_type_scope_txt_id', {
        is: 'entire',
        then: Yup.number(),
        otherwise: Yup.number().min(0).max(99.5).required()
      }),
      isGroupListing: Yup.bool(),
      size: Yup.mixed().when('isGroupListing', {
        is: false,
        then: Yup.number().min(1).required(),
        otherwise: Yup.string().notRequired()
      }),
      gr_count: Yup.number(),
      gr_floors: Yup.string(),
      gr_min_size: Yup.mixed().when('isGroupListing', {
        is: true,
        then: Yup.number().min(1).required(),
        otherwise: Yup.string().notRequired()
      }),
      gr_max_size: Yup.mixed().when('isGroupListing', {
        is: true,
        then: Yup.number().min(1).required(),
        otherwise: Yup.string().notRequired()
      }),
      gr_min_price: Yup.mixed().when('isGroupListing', {
        is: true,
        then: Yup.number().positive().min(1).required(),
        otherwise: Yup.string().notRequired()
      }),
      gr_max_price: Yup.mixed().when('isGroupListing', {
        is: true,
        then: Yup.number().positive().min(1).required(),
        otherwise: Yup.string().notRequired()
      }),
      lease_type: Yup.string().required(),
      availability_date: Yup.date().required(),
      lease_length: Yup.string(),
      pet_limitations: Yup.bool(),
      allow_cats: Yup.number().min(0).max(99).nullable(),
      allow_dogs: Yup.number().min(0).max(99).nullable(),
      min_lease_period: Yup.string().when('lease_length', {
        is: 'other',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      min_lease_period_type: Yup.string().when('lease_length', {
        is: 'other',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      price: Yup.mixed().when('isGroupListing', {
        is: false,
        then: Yup.number().positive().required(),
        otherwise: Yup.string().notRequired()
      }),
      price_frequency: Yup.string().required(),
      furnished: Yup.string(),
      parking: Yup.object().shape({
        enabled: Yup.bool(),
        hasFee: Yup.bool(),
        fee_frequency_txt_id: Yup.mixed().when('hasFee', {
          is: true,
          then: Yup.string()
            .test(
              'fee_frequency_txt_id',
              t('l.listing.edit.required.error', 'Required'),
              val => val && val !== 'free' && val !== 'not_available'
            )
            .required(),
          otherwise: Yup.string()
        }),
        fee: Yup.mixed().when('hasFee', {
          is: true,
          then: Yup.number()
            .moreThan(
              0,
              t(
                'l.listing.edit.no_zero_fee.error',
                'Fee must be greater than $0'
              )
            )
            .required(),
          otherwise: Yup.string()
        })
      }),
      storage: Yup.object().shape({
        enabled: Yup.bool(),
        hasFee: Yup.bool(),
        fee_frequency_txt_id: Yup.mixed().when('hasFee', {
          is: true,
          then: Yup.string()
            .test(
              'fee_frequency_txt_id',
              t('l.listing.edit.required.error', 'Required'),
              val => val && val !== 'free' && val !== 'not_available'
            )
            .required(),
          otherwise: Yup.string()
        }),
        fee: Yup.mixed().when('hasFee', {
          is: true,
          then: Yup.number()
            .moreThan(
              0,
              t(
                'l.listing.edit.no_zero_fee.error',
                'Fee must be greater than $0'
              )
            )
            .required(),
          otherwise: Yup.string()
        })
      }),
      description: Yup.string(),
      images: Yup.array().min(
        1,
        t(
          'l.listing.edit.no_image_selected.error',
          'Please add at least one image'
        )
      )
    }),
    validate: values => {
      let errors = {}
      if (values.isGroupListing) {
        errors = {
          ...errors,
          ...validateMinMax(values, 'gr_min_size', 'gr_max_size'),
          ...validateMinMax(values, 'gr_min_price', 'gr_max_price')
        }
      }

      return errors
    },
    onSubmit: async (values, { setSubmitting }) => {
      await saveAndPreview(values, () => setSubmitting(false))
    }
  })

  if (isEmpty(data)) return null

  const initialValues = omit(['videos'], data)

  const listingAddress = `${data.unit_number ? `${data.unit_number} - ` : ''}${
    data.building.full_street_name
  }`

  return (
    <Page>
      <Formik {...formOptions()} initialValues={initialValues}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-100">
              <Helmet>
                <title>
                  {t(
                    'l.listing.create.create_listing.title',
                    'Create a New Listing'
                  )}
                </title>
                <meta name="robots" content="noindex, nofollow" />
              </Helmet>
              <PageTitle>{listingAddress}</PageTitle>
              {isDraft(data) && (
                <>
                  <AreaTitle>
                    {t(
                      'l.listing.edit.your_building.subtitle',
                      'Your Building'
                    )}
                  </AreaTitle>
                  <Panel vSpace={0} hSpace={0}>
                    <BuildingInfo
                      data={data.building}
                      link={{
                        to: `/listing/edit-building/${data.listing_id}`,
                        as: Link,
                        text: t(
                          'l.listing.edit.change_building.link',
                          'Change building'
                        )
                      }}
                    />
                  </Panel>
                </>
              )}
              <Details
                data={data}
                values={values}
                setFieldValue={setFieldValue}
              />
              <Terms data={data} values={values} />
              <Features values={values} />
              <ParkingStorage values={values} />
              <Description />
              <AreaTitle>
                {t(
                  'l.listing.edit.upload_photos.subtitle',
                  'Upload Your Unit Photos'
                )}
              </AreaTitle>
              <ImageUpload
                images={values.images}
                unitId={values.unit_id}
                onChange={newImages => setFieldValue('images', newImages)}
              />
              <Error name="images" />
              <AreaTitle>
                {t('l.listing.edit.upload_video.subtitle', 'Upload Video')}
              </AreaTitle>
              <VideoUpload
                items={data.videos}
                unitId={values.unit_id}
                listingId={values.listing_id}
                inProgress={uploading}
                onUploadStart={() => {
                  setUploading(true)
                  uploadToast.current = toast.progress({
                    title: t(
                      'l.listing.edit.uploading_video.text',
                      'Uploading Video'
                    ),
                    autoClose: 6000
                  })
                }}
                onUploadEnd={() => {
                  setUploading(false)
                  toast(videoSuccessMessage(), {
                    title: t(
                      'l.listing.edit.video_upload_completed.text',
                      'Video Upload Completed'
                    ),
                    iconId: 'square_check',
                    autoClose: 6000
                  })
                }}
                onUploadProgress={progress => {
                  toast.update(uploadToast.current, { progress })
                }}
              />
            </ContentWrapper>
            <ButtonBar
              listingId={values.listing_id}
              saveAndExit={() => saveAndExit(values)}
              isSubmitting={isSubmitting}
              isDraft={isDraft(data)}
            >
              <Button
                theme="primary"
                width={150}
                left="auto"
                type="submit"
                disabled={isSubmitting}
              >
                {getButtonText()}
              </Button>
            </ButtonBar>
            <Prompt
              when={uploading}
              message="You have unsaved changes, are you sure you want to leave?"
            />
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default Detail
