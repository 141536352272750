import React from 'react'
import Notifications from 'features/Settings/Notifications'

const NotificationsTab = () => {
  return (
    <>
      <Notifications />
    </>
  )
}

export default NotificationsTab
