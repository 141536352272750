import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'

export const FlexWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%;'};
  position: relative;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  padding: 40px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.mediumLightGrey};
    margin-bottom: 25px;
  }
`

export const EditText = styled.p`
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  line-height: 12px;
  position: absolute;
  top: 0;
  right: -24px;
`

export const FormWrapper = styled.div`
  position: relative;
  padding: 40px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.mediumLightGrey};
    margin-bottom: 25px;
  }
`

const FormItem = ({
  editable = true,
  ViewComponent,
  EditComponent,
  EditButtonComponent = EditText,
  initialMode = 'view',
  data
}) => {
  const { t } = useTranslation()
  const [mode, setMode] = useState(initialMode)

  return mode === 'view' ? (
    <FlexWrapper>
      {editable && (
        <EditButtonComponent onClick={() => setMode('edit')}>
          {t('r.profile.edit.edit.button', 'Edit')}
        </EditButtonComponent>
      )}
      <ViewComponent data={data} />
    </FlexWrapper>
  ) : (
    <FormWrapper>
      <EditButtonComponent onClick={() => setMode('view')}>
        {t('r.profile.edit.cancel.button', 'Cancel')}
      </EditButtonComponent>
      <EditComponent
        data={data}
        onCancel={() => setMode('view')}
        onSave={() => setMode('view')}
      />
    </FormWrapper>
  )
}

export default React.memo(FormItem)
