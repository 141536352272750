import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'

const Wrapper = styled.button`
  outline: none;
  background: none;
  border: none;
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 18px;
  letter-spacing: 0.33px;
  padding: 5px 18px;
  border-right: 1px solid ${colors.mediumLightGrey};
  cursor: pointer;

  :active {
    opacity: 0.7;
  }

  @media only screen and (max-width: 950px) {
    padding: 5px 10px;
  }

  :first-child {
    padding-left: 0;
  }

  :last-child {
    border-right: 0;
    padding-right: 0;
  }

  ${props =>
    props.active &&
    `
      color: ${colors.regular};
      font-weight: 700;
      text-decoration: underline;
  `}
`

const FilterButton = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default FilterButton
