import {
  __,
  gt,
  take,
  append,
  join,
  curry,
  concat,
  identity,
  toPairs,
  keys,
  when,
  propSatisfies,
  reduce,
  assoc,
  head,
  complement,
  isEmpty,
  map,
  applySpec,
  converge,
  mergeRight,
  pipe,
  equals,
  isNil,
  ifElse,
  always,
  mapObjIndexed,
  or,
  trim,
  lift,
  is,
  find,
  findLast,
  propEq,
  values,
  prop,
  tap
} from 'ramda'

export const isTruthy = pipe(Boolean, equals(true))
export const isFalsy = complement(isTruthy)
export const isNotNil = complement(isNil)
export const defaultIfNil = v => ifElse(isNil, always(v), identity)
export const defaultIfEmpty = v => ifElse(isEmpty, always(v), identity)
export const defaultIfFalsy = v => ifElse(isTruthy, identity, always(v))
export const defaultIfEquals = (v, e) => ifElse(equals(e), always(v), identity)
export const callOr = (fn, v) => ifElse(isTruthy, fn, always(v))
export const callOrSelf = fn => ifElse(isTruthy, fn, identity)

export const mergeSpec = curry((spec, value) =>
  converge(mergeRight, [identity, applySpec(spec)])(value)
)

export const concatSpec = curry((spec, value) =>
  converge(concat, [identity, applySpec(spec)])(value)
)

export const objectToArrayAs = curry((keyProp, valueProp) =>
  pipe(
    toPairs,
    map(([key, value]) => ({
      [keyProp]: key,
      [valueProp]: value
    }))
  )
)

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const headObj = curry((obj = {}) => {
  const k = pipe(keys, head)(obj)
  return obj[k]
})

export const headKey = pipe(keys, head)

export const truncate = (max, replace = '…', str) =>
  when(
    propSatisfies(gt(__, max), 'length'),
    pipe(take(max), append(replace), join(''))
  )(str)

export const log = msg => tap(x => console.log(msg, x))

export const parseObjectData = mapObjIndexed(defaultIfEquals('', null))

export const isNotEmpty = complement(isEmpty)

export const isNullOrEmpty = or(isEmpty, isNil)

export const nullWhenEmpty = v => when(isEmpty(trim(v)), null, v)

export const isEmptyString = lift(v => v === '')

export const nullWhenEmptyString = v => when(isEmptyString(trim(v)), null, v)

export const wrapArray = ifElse(is(Array), identity, a => [a])

export const findFileByTag = (tag, files) =>
  findLast(propEq('tag', tag))(values(files))

export const findFile = (param, value, files) =>
  find(propEq(param, value))(values(files))

export const mapBy = (key, data) =>
  when(isTruthy, map(pipe(prop(key), prop(__, data))))
