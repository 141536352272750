import styled from 'styled-components'
import { TableRow } from 'components/table'

const ListRow = styled(TableRow)`
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
`

export default ListRow
