/* global H */
export const DomIcons = new Map()
export default function getDomMarkerIcon(html) {
  if (!DomIcons.has(html)) {
    const icon = new H.map.DomIcon(html)
    DomIcons.set(html, icon)
  }

  return DomIcons.get(html)
}
