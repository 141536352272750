import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ValidatePhone from 'features/Auth/ValidatePhone'
import { useTranslation } from 'react-i18next'
import { verifyPhoneSignup } from 'models/auth/ports'
import Context from './context'

const Signup = ({ history }) => {
  const { t } = useTranslation()
  const { codeRecipient, setCodeRecipient } = useContext(Context)

  if (codeRecipient) {
    return <Redirect to="/auth/signup/code" />
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const { response } = await verifyPhoneSignup(values)
    setSubmitting(false)
    if (response.ok) {
      setCodeRecipient(values.phone)
      history.push('/auth/signup/code')
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('b.auth.view.enter_phone.title', 'Enter Your Phone Number')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ValidatePhone onSubmit={onSubmit} />
    </>
  )
}

export default Signup
