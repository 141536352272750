import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { path, pipe, prop } from 'ramda'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { headObj } from 'utils'
import useGetSuiteInfo from 'hooks/get-suite-info'
import useProfile from 'hooks/use-profile'
import { show as showPaymentDetailsModal } from 'features/Payments/payment-details-modal'
import SuiteInfo from 'features/Listing/suite-info'
import PaymentCard from 'features/Payments/payment-card'
import DashboardHeader from 'features/Tenant/dashboard-header'
import { AnchorButton } from 'components/anchor'
import { show as showRequestPaymentModal } from './request-payment-modal'
import { show as showNoPaymentMethodModal } from './no-payment-method-modal'
import emptyImage from './no-payments-placeholder.png'

const Wrapper = styled.div``

const ContentWrapper = styled.div`
  max-width: ${breakpoints.tabletLandscape};
  margin: 50px auto 50px auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 30px 10px 80px;
  }
`

const PaymentWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  > div {
    margin-bottom: 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column-reverse;
  }
`

const PaymentTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 30px;
  margin: 0;
  margin-bottom: ${props => props.bottom || 0}px;
  margin-top: ${props => props.top || 0}px;
  text-align: ${props => (props.center ? 'center' : 'left')};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    text-align: center;
  }
`

const EmptyText = styled.p`
  width: 100%;
  max-width: 600px;
  border: 1px solid ${colors.mediumLightGrey};
  margin: 0 auto;
  padding: 20px;
  background-color: ${colors.lightGrey};
`

const AddButton = styled.button`
  height: 28px;
  min-width: 68px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 20px;
  }
`

const StyledButton = styled(AnchorButton)`
  letter-spacing: -0.39px;
  margin: 0 auto;
`

const EmptyImage = styled.img`
  width: 262px;
  margin-top: 30px;
`

const TenantPayments = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const profile = useProfile()
  const loadPaymentData = dispatch.payment.getListingPayments
  const paymentsData = useSelector(state =>
    select.payment.listingPayments(state, { listingId })
  )
  const listingDetails = useGetSuiteInfo(listingId)
  const userRole = useSelector(path(['session', 'session', 'user_role']))
  const isAdmin = userRole === 'administrator'
  const stripeConnected = profile.company_info?.ach_connected.includes('stripe')
  const unionPayConnected = profile.company_info?.ach_connected.includes('upop')
  const hasPaymentMethod = stripeConnected || unionPayConnected
  const hasPendingPayments = paymentsData['pending']?.length > 0
  const hasApprovedPayments = paymentsData['approved']?.length > 0

  const tenantId = pipe(prop('users'), headObj, prop('id'))(listingDetails)

  useEffect(() => {
    loadPaymentData({ listingId })
  }, [listingId, loadPaymentData])

  if (!listingDetails) return null

  const handleGoToPayment = paymentId => {
    history.push(`/tenant/${listingId}/payments/${paymentId}`)
    window.scrollTo(0, 0)
  }

  const handleRequestPayment = () => {
    return hasPaymentMethod
      ? showRequestPaymentModal({ listingId, tenantId })
      : showNoPaymentMethodModal({ history })
  }

  return (
    <>
      <DashboardHeader
        listingId={listingId}
        border
        currentPage={t('b.payments.view.payments.title', 'Payments')}
      />
      <Wrapper>
        <Helmet>
          <title>{t('b.payments.view.payments.title', 'Payments')}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <SuiteInfo listingId={listingId} />
        <ContentWrapper>
          {!hasPaymentMethod && !hasPendingPayments && !hasApprovedPayments ? (
            <>
              <EmptyImage src={emptyImage} alt="" />
              <PaymentTitle center bottom={30} top={30}>
                {t(
                  'l.listing.view.no_payment_method_added.text',
                  'No payment method added yet'
                )}
              </PaymentTitle>
              {isAdmin ? (
                <StyledButton
                  height={48}
                  width={150}
                  to="/settings/payment-settings"
                >
                  {t(
                    'l.payment_settings.view.payment_settings.button',
                    'Payment Settings'
                  )}
                </StyledButton>
              ) : (
                <p>
                  {t(
                    'l.payment_settings.view.contact_admin_to_add_payment.button',
                    'Please contact your administrator to add one.'
                  )}
                </p>
              )}
            </>
          ) : (
            <>
              <PaymentWrapper>
                <TitleWrapper>
                  <PaymentTitle>
                    {t(
                      'l.listing.view.upcoming_rent_payments.text',
                      'Upcoming Rent Payment(s)'
                    )}
                  </PaymentTitle>
                  <AddButton onClick={handleRequestPayment}>
                    +{' '}
                    {t(
                      'l.listing.view.request_payment.button',
                      'Request Payment'
                    )}
                  </AddButton>
                </TitleWrapper>
                {hasPendingPayments ? (
                  paymentsData['pending']?.map(payment => (
                    <PaymentCard
                      key={payment.id}
                      data={payment}
                      onClick={() =>
                        showPaymentDetailsModal({
                          paymentId: payment.id,
                          listingId,
                          history
                        })
                      }
                    />
                  ))
                ) : (
                  <EmptyText>
                    {t(
                      'l.listing.view.no_upcoming_payments.text',
                      'No upcoming payments'
                    )}
                  </EmptyText>
                )}
              </PaymentWrapper>
              <PaymentWrapper>
                <PaymentTitle bottom={20} top={50}>
                  {t(
                    'l.listing.view.past_rent_payments.text',
                    'Past Rent Payment(s)'
                  )}
                </PaymentTitle>
                {hasApprovedPayments ? (
                  paymentsData['approved']?.map(payment => (
                    <PaymentCard
                      key={payment.id}
                      data={payment}
                      onClick={() => handleGoToPayment(payment.id)}
                    />
                  ))
                ) : (
                  <EmptyText>
                    {t(
                      'l.listing.view.no_past_payments.text',
                      'No past payments'
                    )}
                  </EmptyText>
                )}
              </PaymentWrapper>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </>
  )
}

export default TenantPayments
