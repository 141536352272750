import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: ${props => props.gutter}px;
  padding-left: ${props => props.gutter}px;
  flex: 0 0 ${props => (props.col * 100) / 12}%;
  max-width: ${props => (props.col * 100) / 12}%;
  margin-bottom: ${props =>
    props.bottom !== undefined ? `${props.bottom}px` : '1rem'};

  input {
    display: block;
  }

  label {
    padding-left: 12px;
  }

  ${props =>
    props.inline &&
    `
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    label {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  `}

  ${props =>
    props.justify &&
    `
    justify-content: ${props.justify};
  `}

  ${props => props.noLabel && `padding-top: 28px;`}
`
const Group = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>

Group.defaultProps = {
  col: 12,
  gutter: 10
}

export default React.memo(Group)
