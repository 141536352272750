import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Span = styled.span`
  color: ${colors.regular};
`

const Header = styled.header`
  min-height: 50px;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LeftDiv = styled(Div)`
  justify-content: flex-start;
  flex-basis: 20px;
`

const RightDiv = styled(Div)`
  justify-content: flex-end;
  flex-basis: 25px;
`

const Middle = styled(Div)`
  justify-content: center;
  flex: 1;
  margin: 0px 10px;
`

const Taskbar = props => (
  <Header className={props.className}>
    <LeftDiv>
      {typeof props.left === 'string' ? <Span>{props.left}</Span> : props.left}
    </LeftDiv>

    <Middle>
      {typeof props.children === 'string' ? (
        <Span>{props.children}</Span>
      ) : (
        props.children
      )}
    </Middle>

    <RightDiv>
      {typeof props.right === 'string' ? (
        <Span>{props.right}</Span>
      ) : (
        props.right
      )}
    </RightDiv>
  </Header>
)

Taskbar.defaultProps = {
  className: '',
  right: null,
  left: null,
  children: null
}

export default Taskbar
