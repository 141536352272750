import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, breakpoints, fontWeights } from 'styles'
import { equals } from 'ramda'
import { toNumber } from 'lodash'
import {
  isGroup,
  prefixUnitNumber,
  getAreaMeasurementLabel
} from 'utils/listings'
import { videoHasProcessed } from 'utils/video'
import CurrencyFormatter from 'components/currency-formatter'
import Icon from 'components/icon'
import Tooltip from 'components/tooltip'
import VerifiedBadge from 'components/verified-badge'
import ListingTypeText from 'components/listing-type-text'
import LandlordInfo from '../landlord-info'
import CompanyInfo from '../company-info'
import VideoButton from '../video-button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-top: ${props => (props.videoText ? '30px' : '0px')};
    margin-top: ${props => (props.hasVideo ? '84px' : '30px')};
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 30px;
  }
`

const MobileTitle = styled.div`
  display: none;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: block;
  }
`

const DesktopTitle = styled.div`
  display: block;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px);
  grid-gap: 15px 10px;
  margin-bottom: 15px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const DetailBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 160px;
  height: 45px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
  }
`

const TabletInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;

  > div:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (min-width: 1166px) {
    display: none;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const Type = styled.h4`
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  text-transform: uppercase;
  margin: 0 0 5px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
  }
`

const AddressWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`

const Address = styled.h1`
  display: block;
  font-size: ${fontSizes.medium};
  color: ${colors.regular};
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 20px;
    font-size: ${fontSizes.regular};
    line-height: 19px;
  }
`

const TabletPrice = styled.div`
  display: none;
  margin-bottom: 30px;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) and (max-width: 1165px) {
    display: block;
  }
`

const StyledIcon = styled(Icon)`
  margin-left: 10px;
  flex-shrink: 0;
`

const DetailText = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  margin: 0 0 0 5px;
`

const PriceWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`

const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const PriceStarting = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  font-weight: ${fontWeights.regular};
  margin: 0;
`

const Price = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.large};
  font-weight: 700;
  line-height: 24px;
  margin: 0;
`

const Frequency = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  margin: 0 0 -3px 5px;
`

const MobileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

const AddressRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  align-items: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 15px;
  }
`

const equalsOne = equals('1')

const formatPrice = price => <CurrencyFormatter value={price} />

const renderPrice = (listing, unit, t) => {
  if (listing.state_machine === 'rented') {
    return (
      <PriceWrapper>
        <Price>{t('b.listing.view.rented.text', 'Rented')}</Price>
      </PriceWrapper>
    )
  }

  const price = isGroup(unit)
    ? formatPrice(unit.gr_min_price)
    : formatPrice(listing.price)

  return (
    <PriceColumn>
      {isGroup(unit) && (
        <PriceStarting>
          {t('b.listings.view.starting_at.text', 'Starting at')}...
        </PriceStarting>
      )}
      <PriceWrapper>
        <Price>{price}</Price>
        <Frequency>
          {`/ ${t(
            `b.listing.view.price_frequency_${listing.price_frequency}.text`
          )}`}
        </Frequency>
      </PriceWrapper>
    </PriceColumn>
  )
}

const ListingSummary = ({
  address,
  partialAddress,
  furnished,
  landlord,
  companyName,
  unitNumber,
  listings,
  unit,
  unitVideos,
  areaMeasurementUnit = 'sqft'
}) => {
  const { t } = useTranslation()
  const displayUnit = unitNumber ? `${prefixUnitNumber(unitNumber)} - ` : ''
  const unitType = unit.unit_type_txt_id
  const unitScope = unit.unit_type_scope_txt_id
  const hasVideo = unitVideos.length > 0
  const showProcessingText = hasVideo
    ? !videoHasProcessed(unitVideos[0].created_at)
    : false
  const listingVerified = listings.verified_state_machine === 'approved'

  const getListingTypeText = () => <ListingTypeText unitType={unitType} unitScope={unitScope} />

  const addressVerifiedText = t(
    'b.listing.view.address_verified.tooltip',
    'This listing is address verified.'
  )

  const renderFullTitle = () => (
    <TitleWrapper>
      <Type>
        {getListingTypeText()}{' '}
        <Tooltip text={addressVerifiedText} position="right" left={5}>
          <VerifiedBadge
            verified={listingVerified}
            type="listing"
            height={24}
            width={24}
          />
        </Tooltip>
      </Type>
      <AddressRow>
        <AddressWrapper>
          <Address>
            {displayUnit}
            {address}
          </Address>
        </AddressWrapper>
      </AddressRow>
      <TabletPrice>{renderPrice(listings, unit, t)}</TabletPrice>
    </TitleWrapper>
  )

  const renderMobileTitle = () => (
    <TitleWrapper>
      <Type>
        {getListingTypeText()}{' '}
        <Tooltip text={addressVerifiedText} position="top" left={2}>
          <VerifiedBadge
            verified={listingVerified}
            type="listing"
            height={19}
            width={19}
          />
        </Tooltip>
      </Type>
      <Address>{partialAddress}</Address>
      <MobileInfoWrapper>
        {renderPrice(listings, unit, t)}
        <LandlordInfo landlord={landlord} />
        <CompanyInfo name={companyName} />
      </MobileInfoWrapper>
    </TitleWrapper>
  )

  const getBedrooms = () => {
    const sizeLabel = getAreaMeasurementLabel(areaMeasurementUnit)
    if (unitScope === 'single')
      return `${unit.size} ${sizeLabel} ${t('common.bedroom', 'Bedroom')}`
    const count = toNumber(unit.count_bedrooms)
    if (count === 0) return t('common.studio')
    return t('b.listing.view.count_bedrooms.text', {
      count,
      defaultValue: '{{count}} Bedroom',
      defaultValue_plural: '{{count}} Bedrooms'
    })
  }

  const getBathrooms = () => {
    if (unitScope === 'entire')
      return t('b.listing.view.count_bathrooms.text', {
        count: toNumber(unit.count_all_bathrooms),
        defaultValue: '{{count}} Bathroom',
        defaultValue_plural: '{{count}} Bathrooms'
      })
    const count = toNumber(unit.count_full_bathrooms)
    return t('b.listing.view.count_private_bathrooms.text', {
      count,
      defaultValue: '{{count}} Private Bath'
    })
  }

  const getSize = () => {
    const sizeLabel = getAreaMeasurementLabel(areaMeasurementUnit)
    if (isGroup(unit))
      return `${unit.gr_min_size}-${unit.gr_max_size} ${sizeLabel}`
    return `${unit.size} ${sizeLabel}`
  }

  const renderDetailBoxes = () => (
    <DetailsWrapper>
      <DetailBox>
        <StyledIcon id="bedroom" width={16} height={16} />
        <DetailText>{getBedrooms()}</DetailText>
      </DetailBox>
      {toNumber(unit.count_full_bathrooms) > 0 && (
        <DetailBox>
          <StyledIcon id="bathtub_dark" width={16} height={16} />
          <DetailText>{getBathrooms()}</DetailText>
        </DetailBox>
      )}
      {unitScope !== 'single' && (
        <DetailBox>
          <StyledIcon id="size" width={16} height={16} />
          <DetailText>{getSize()}</DetailText>
        </DetailBox>
      )}
      <DetailBox>
        <StyledIcon id="furnished_dark" width={16} height={16} />
        <DetailText>{furnished}</DetailText>
      </DetailBox>
      {equalsOne(unit.private_entrance) && unitScope === 'partial' && (
        <DetailBox>
          <StyledIcon id="entrance" width={16} height={16} />
          <DetailText>
            {t('b.listing.view.private_entrance.text', 'Private Entrance')}
          </DetailText>
        </DetailBox>
      )}
    </DetailsWrapper>
  )

  return (
    <Wrapper hasVideo={hasVideo} videoText={showProcessingText}>
      <MobileTitle>{renderMobileTitle()}</MobileTitle>
      <DesktopTitle>{renderFullTitle()}</DesktopTitle>
      {renderDetailBoxes()}
      {hasVideo && (
        <VideoButton
          unitVideos={unitVideos}
          showProcessingText={showProcessingText}
        />
      )}
      <TabletInfoWrapper>
        <LandlordInfo landlord={landlord} />
        <CompanyInfo name={companyName} />
      </TabletInfoWrapper>
    </Wrapper>
  )
}

ListingSummary.defaultProps = {
  unitType: '',
  address: '',
  partialAddress: '',
  furnished: '',
  companyName: '',
  unitNumber: ''
}

export default ListingSummary
