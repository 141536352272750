import { createContext } from 'react'

const context = createContext({
  activeTabId: '',
  changeTab: () => {}
})

const { Provider, Consumer } = context

export { Provider, Consumer }
