import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTop = always => {
  const { state, pathname } = useLocation()
  const scrollToTop = always || !!state?.scrollToTop
  useEffect(() => {
    if (scrollToTop && typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [pathname, scrollToTop])
  return null
}

export default useScrollToTop
