import React, { useEffect } from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import useProfile from 'hooks/use-profile'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import * as Sentry from '@sentry/browser'
import { replace, path } from 'ramda'
import {
  Accordion,
  AccordionHeader,
  AccordionContent
} from 'components/accordion'
import TextButton from 'components/button/text-button'
import config from 'config'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button } from 'components/button'
import { useHistory } from 'react-router'

import bgMobile from './Bg404.png'
import bg from './Bg404@2x.png'
import crash from './Crash@2x.png'
import crashMobile from './Crash.png'

const Types = {
  NotFound: '404',
  RuntimeError: 'runtime'
}

const WhiteBackground = createGlobalStyle`
  body {
    background: ${colors.white};
  }
`

const ErrorWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${({ customStyle }) => customStyle}
`

const Title = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.extraLarge};
  font-weight: ${fontWeights.regular};
  text-align: center;
  margin: 200px 10px 25px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.large};
    margin-bottom: 10px;
  }
`

const Subtitle = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 28px;
  text-align: center;
  margin: 0 10px 25px;
  font-weight: normal;
`

const ErrorTitle = styled.h3`
  font-weight: ${fontWeights.medium};
`

const MaxWidth = styled.div`
  max-width: 600px;
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 300px;
  }
`

const Code = styled.pre`
  white-space: pre-wrap
  padding: 16px 8px;
  margin-bottom: 8px;
  font-size: ${fontSizes.small};
`

const longButton = css`
  margin-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
`

const extractProfile = path([
  'id',
  'phone_number',
  'first_name',
  'last_name',
  'user_type'
])

const ErrorMessage = ({ componentStack, error }) => {
  const { t } = useTranslation()
  const errorType = replace(/^Error: /, '', error.toString())
  const stacktrace = t('b.error_page.view.stacktrace', 'See Full Stacktrace')
  return (
    <ErrorWrapper>
      <MaxWidth>
        <ErrorTitle>{errorType}</ErrorTitle>
      </MaxWidth>
      <Accordion>
        <AccordionHeader>
          <TextButton>{stacktrace}</TextButton>
        </AccordionHeader>
        <AccordionContent>
          <MaxWidth>
            <Code>{componentStack.trim()}</Code>
          </MaxWidth>
        </AccordionContent>
      </Accordion>
    </ErrorWrapper>
  )
}

const ErrorPage = ({ componentStack, error, resetErrorBoundary = noop }) => {
  const { t } = useTranslation()
  const MAP = {
    [Types.RuntimeError]: {
      title: t(
        'b.error_page.view.unknown_error.title',
        'Oops! An unknown error has occurred.'
      ),
      containerStyle: css`
        background: url(${crash}) 50% 100% no-repeat;
        min-height: 630px;
        background-size: auto 300px;
        @media screen and (max-width: ${breakpoints.tabletLandscape}) {
          background-size: auto 260px;
        }
        @media screen and (max-width: ${breakpoints.phoneMax}) {
          background-image: url(${crashMobile});
          min-height: 500px;
          background-size: auto 160px;
        }
      `
    },
    [Types.NotFound]: {
      title: t(
        'b.error_page.view.not_available.title',
        "Sorry, this page isn't available."
      ),
      subtitle: t(
        'b.error_page.view.error_message.text',
        'The link you followed may be broken, or the page may have been removed.'
      ),
      containerStyle: css`
        background: url(${bg}) 50% 100% no-repeat;
        background-size: auto 388px;
        min-height: 640px;
        @media screen and (max-width: ${breakpoints.phoneMax}) {
          min-height: 600px;
          background-image: url(${bgMobile});
          background-size: auto 270px;
        }
      `
    }
  }
  const profile = useProfile() || {}
  const history = useHistory()
  const type = error ? Types.RuntimeError : Types.NotFound
  const errorInfo = MAP[type]
  useEffect(() => {
    const unlisten = history.listen(resetErrorBoundary)
    return unlisten
  }, [history, resetErrorBoundary])
  useEffect(() => {
    if (!error) return
    Sentry.withScope(scope => {
      scope.setExtras({ profile })
      scope.setUser(extractProfile(profile))
      const eventId = Sentry.captureException(error)
      Sentry.showReportDialog({
        eventId,
        user: {
          email: profile.username,
          name: profile ? `${profile.first_name} ${profile.last_name}` : ''
        }
      })
    })
  }, [error, componentStack, profile])
  const notFound = t('b.error_page.view.page_title', 'Page Not Found')
  const refresh = t('b.error_page.view.refresh', 'Refresh Page')
  return (
    <>
      <Helmet>
        <title>{notFound}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrapper customStyle={errorInfo.containerStyle}>
        <WhiteBackground />
        <Title>{errorInfo.title}</Title>
        {errorInfo.subtitle ? (
          <Subtitle>{errorInfo.subtitle}</Subtitle>
        ) : (
          <Button
            onClick={() => history.go()}
            customStyle={longButton}
            theme="primary"
          >
            {refresh}
          </Button>
        )}
      </Wrapper>
      {error && config.BUILD_ENV !== 'prod' && (
        <ErrorMessage componentStack={componentStack} error={error} />
      )}
    </>
  )
}

export default ErrorPage
