export const dataURItoBlob = dataURI => {
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  tempLink.click()
  tempLink.remove()
  URL.revokeObjectURL(url)
}
