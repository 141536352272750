import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes } from 'styles'
import Amenity from './amenity'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
`

const Title = styled.h4`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: 700;
  margin-bottom: 30px;
`

const FeatureWrapper = styled.div`
  display: grid;
  grid-gap: 20px 100px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${breakpoints.phoneMax}) {
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }
`

const AmenitiesList = ({
  translationContext,
  title,
  amenities,
  customAmenities,
  showType
}) => {
  const { t } = useTranslation(['translation', 'basic'])

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <FeatureWrapper>
        {!amenities.length && !customAmenities.length && (
          <Amenity
            data={{ name: t('b.listing.view.no_features.text', 'None') }}
            key={'None'}
          />
        )}
        {amenities.map(({ txt_id, type, types } = {}) => (
          <Amenity
            data={{
              id: txt_id,
              name: t(`${translationContext}.${txt_id}.text`),
              type,
              types
            }}
            key={txt_id}
            showType={showType}
          />
        ))}
        {customAmenities.map(data => (
          <Amenity
            data={{ id: 'default', name: data.name, type: data.type }}
            key={data.name}
            showType={showType}
          />
        ))}
      </FeatureWrapper>
    </Wrapper>
  )
}

AmenitiesList.defaultProps = {
  title: '',
  amenities: [],
  customAmenities: []
}

export default AmenitiesList
