import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import { breakpoints, colors, fontSizes } from 'styles'
import { RoundedAnchor, Anchor } from 'components/anchor'
import Content from 'components/content'
import Icon from 'components/icon'
import Auth from 'pages/Auth'
import config from 'config'
import LandingBG from './landing-bg.png'

const { WEB_URL } = config
const SIGNUP_URL = '/auth/signup'
const LOGIN_URL = '/auth/login'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('${LandingBG}');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: calc(100vh - 62px);
  position: relative;

`
const LogoIcon = styled(Icon)`
  position: absolute;
  left: 40px;
  top: 20px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    left: 20px;
    top: 0px;
  }
`

const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 425px;
  height: 310px;
  margin: 100px 0px;
  max-width: 95%;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
`

const ContentContainer = styled(Content)`
  text-align: center;
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 28px;
  margin: 28px 0 10px;
`

const LoginButton = styled(RoundedAnchor)`
  text-align: center;
  margin: 20px 0;
  width: 200px;
`

const P = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};

  ${Anchor} {
    margin-left: 2px;
  }
`

const Landing = ({ location }) => {
  const { t } = useTranslation()
  return (
    <LoginContainer>
      <ContentContainer>
        <Title>{t('b.landing.view.sign_up.title', 'Sign Up')}</Title>
        <P>
          {t(
            'b.landing.view.get_started.text',
            'To get started, create an account first.'
          )}
        </P>
        <LoginButton to={{ ...location, pathname: SIGNUP_URL }}>
          {t('b.landing.view.create_account.button', 'Create Account')}
        </LoginButton>
        <P>
          {t(
            'b.landing.view.existing_account.text',
            'Already have an account?'
          )}
          <Anchor to={{ ...location, pathname: LOGIN_URL }}>
            {' '}
            {t('b.landing.view.log_in.link', 'Log in')}
          </Anchor>
        </P>
      </ContentContainer>
    </LoginContainer>
  )
}

const Layout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Helmet>
        <title>
          {t('b.landing.view.login_signup.title', 'Log in - Sign up')}
        </title>
        <link rel="canonical" href="https://liv.rent/app" />
      </Helmet>
      <a href={WEB_URL}>
        <LogoIcon id="liv_logo" width={75} height={75} />
      </a>
      {children}
    </Container>
  )
}

const Main = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={props => (
          <Layout>
            <Landing {...props} />
          </Layout>
        )}
      />
      <Route
        path="/auth"
        render={props => (
          <Layout>
            <Auth {...props} />
          </Layout>
        )}
      />
      <Route
        path="/reset_password"
        render={props => (
          <Layout>
            <Auth {...props} />
          </Layout>
        )}
      />
      <Route
        path="/listing/:listingId"
        render={({
          match: {
            params: { listingId }
          }
        }) => (window.location = `${WEB_URL}/rental-listings/${listingId}`)}
      />
      <Redirect to="/" />
    </Switch>
  )
}

export default Main
