import React, { useRef, useEffect, useState, useCallback } from 'react'
import useRole from 'hooks/use-role'
import useContract from 'hooks/use-contract'
import { useSelector, useDispatch } from 'react-redux'
import { select } from 'store'
import { intersection, head, path } from 'ramda'
import { getImageUrl } from 'utils/image'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { prefixUnitNumber } from 'utils/listings'
import formatName from 'utils/format-name'
import hideUnit from 'utils/hide-unit'
import { parsePhone } from 'utils/format-phone'
import useGetSuiteInfo from 'hooks/get-suite-info'
import BaseChatRoom from './base'
import VerifiedBanner from './verified-banner'

const isListingRented = (listing, ids) => {
  if (!listing) return false
  return (
    (listing.state_machine === 'rented' ||
      listing.state_machine === 'upcoming' ||
      listing.state_machine === 'history') &&
    intersection(listing.listing_tenants, ids).length > 0
  )
}
const ChatRoom = ({ timeline, timelineEvents, handleBack }) => {
  const loadedMore = useRef(false)
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const listing = useGetSuiteInfo(timeline?.listing_id)
  const [loading, setLoading] = useState(false)
  const lastId = path(['0', 'id'], timelineEvents)
  const role = useRole()
  const timelineLoaded = !!timeline
  const presetMessages = useSelector(select.chatPreset.presets)

  const loadMore = useCallback(async () => {
    if (loading) return
    setLoading(true)

    await dispatch.chat.getEvents({
      timelineRoomId: params.timelineId,
      lastEventId: lastId
    })
    setLoading(false)
  }, [dispatch.chat, lastId, loading, params.timelineId])

  useEffect(() => {
    return () => {
      setLoading(false)
      dispatch.chat.clearCurrentChat()
    }
  }, [dispatch.chat])

  useEffect(() => {
    loadedMore.current = false
  }, [params.timelineId])

  useEffect(() => {
    if (!lastId && timelineLoaded) {
      dispatch.chat.setTimelineRead(params.timelineId)
      return
    }
    if (!lastId || loadedMore.current) return
    loadedMore.current = true
    loadMore()
    dispatch.chat.setTimelineRead(params.timelineId)
  }, [dispatch.chat, lastId, loadMore, params.timelineId, timelineLoaded])

  const handleScrollTop = async e => {
    const oldFirstMessage = document.querySelector('.chat-event-message')
    if (!oldFirstMessage) return
    const oldPosition = oldFirstMessage.offsetTop
    const oldScroll = e.target.scrollTop
    await loadMore()
    e.target.scrollTop = oldFirstMessage.offsetTop - oldPosition + oldScroll
  }

  const handleSendMessage = message => {
    dispatch.chat.sendMessage({
      message,
      timelineRoomId: params.timelineId
    })
  }

  const handleSendFile = async files => {
    const sendAction = ['image/png', 'image/jpg', 'image/jpeg'].includes(
      head(files)?.type
    )
      ? dispatch.chat.sendImage
      : dispatch.chat.sendFile

    sendAction({
      file: head(files),
      timelineRoomId: params.timelineId
    })
  }

  const contract = useContract(timeline ? timeline.listing_id : -1)

  if (!timeline) return null

  const names = timeline.users.them.map(user => formatName(user))
  const other = timeline.users?.them?.[0]
  const avatarUrl = getImageUrl(other?.avatar?.aws_s3_key, {
    width: 80,
    height: 80
  })

  const isRented = isListingRented(timeline.listings, timeline.user_ids.them)

  const phoneNumber =
    isRented || role === 'tenant' ? parsePhone(other?.phone_number) : null

  const isLocked = timeline.state_machine === 'locked'

  const buildingName = timeline.buildings.name
  const city = timeline.buildings.city

  const unitHidden = hideUnit(timeline.units, contract)

  const getLandlordAddress = () => {
    const unitNumber = timeline.units.unit_number
    const streetAddress = timeline.buildings.full_street_name

    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${streetAddress}, ${city}`
      : `${streetAddress}, ${city}`

    return address
  }

  const getTenantHouseAddress = () => {
    const unitNumber = unitHidden
      ? t('common.house', 'House')
      : timeline.units.unit_number
    const streetAddress = unitHidden
      ? timeline.buildings.street_name
      : timeline.buildings.full_street_name
    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${streetAddress}`
      : streetAddress

    return `${address}, ${city}`
  }

  const getTenantApartmentAddress = () => {
    const unitNumber = unitHidden ? null : timeline.units.unit_number
    const streetAddress = timeline.buildings.full_street_name
    const address = unitNumber
      ? `${prefixUnitNumber(unitNumber)} - ${buildingName || streetAddress}`
      : buildingName || streetAddress

    return `${address}, ${city}`
  }

  const getTenantAddress = () => {
    const isHouse = timeline.buildings.building_type_txt_id === 'house'
    const address = isHouse
      ? getTenantHouseAddress()
      : getTenantApartmentAddress()

    return address
  }
  return (
    <BaseChatRoom
      loading={loading}
      avatarUrl={avatarUrl}
      names={names}
      address={role === 'tenant' ? getTenantAddress() : getLandlordAddress()}
      tenantData={role === 'tenant' ? null : other}
      lastEventLoaded={timeline.lastEventLoaded === lastId}
      events={timelineEvents}
      handleSendMessage={handleSendMessage}
      handleSendFile={handleSendFile}
      welcomeMessage={VerifiedBanner}
      welcomeMessageProps={{
        user: other,
        listing: listing,
        loading: loading
      }}
      onScrollTop={handleScrollTop}
      locked={isLocked}
      handleBack={handleBack}
      timeline={timeline}
      presetMessages={presetMessages}
      phoneNumber={phoneNumber}
      isRented={isRented}
    />
  )
}

export default React.memo(ChatRoom)
