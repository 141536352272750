import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import useSession from 'hooks/use-current-session'
import { Row, Group, Dropdown } from 'components/form'
import { Button } from 'components/button'
import { PanelContainer } from '../elements'
import { getUser } from './ports'

const PanelHeading = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  margin: 10px 0 30px;
  color: ${colors.white};
`

const StyledPanelContainer = styled(PanelContainer)`
  background: ${colors.secondary};
  border-radius: 6px;
  max-width: 660px;
`

const DropdownWrapper = styled.div`
  > div {
    width: 300px;
    max-width: calc(100vw - 80px);
    > label {
      color: ${colors.white} !important;
    }
  }
`

const DiscoveryGroup = styled(Group)`
  padding-bottom: 12px;
  label {
    padding-left: 0px;
  }
`

const DiscoveryRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

const GodmodeButton = styled(Button)`
  margin: 20px auto;
  display: block;
  border-radius: 2px;
  position: relative;
  transition: 0.5s;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: #d35400;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
  }

  :hover:before {
    transform: scale(1.2);
    box-shadow: 0 0 20px #ffeb11;
    filter: blur(3px);
  }

  :hover {
    color: #ffa502;
    box-shadow: 0 0 20px #ffeb11;
  }
`

const SelectButton = styled(Button)`
  display: block;
`

const DevSettings = () => {
  const [user, setUser] = useState()
  const [data, setData] = useState([])
  const [showGodmode, setShowGodmode] = useState(false)
  const session = useSession()

  useEffect(() => {
    const getinfo = async () => {
      if (!showGodmode) return
      const { response, body } = await getUser({ phone: session.phone_number })
      if (response.ok) {
        const structuredData = body.dev.users.map(userData => ({
          value: userData.phone_number,
          label: `${userData.user_type[0].toUpperCase()} - ${
            userData.first_name
          } ${userData.last_name} (${userData.phone_number})`
        }))
        setData(structuredData)
      }
    }
    getinfo()
  }, [session.phone_number, showGodmode])

  const onSubmit = async () => {
    const { response } = await getUser({ phone: user.value })
    if (response.ok) {
      window.location.reload()
    }
  }

  return (
    <StyledPanelContainer vSpace={30} hSpace={30}>
      <PanelHeading>Dev Settings</PanelHeading>
      {showGodmode ? (
        <>
          <DiscoveryGroup col={12}>
            <DiscoveryRow>
              <DropdownWrapper>
                <Dropdown
                  value={user}
                  onChange={setUser}
                  options={data || []}
                  placeholder="God mode"
                  styles={{ singleValue: { color: colors.white } }}
                />
              </DropdownWrapper>
            </DiscoveryRow>
          </DiscoveryGroup>
          <SelectButton
            theme="ghost"
            width={150}
            right={0}
            left={'auto'}
            onClick={onSubmit}
            disabled={!user}
          >
            Change User
          </SelectButton>
        </>
      ) : (
        <GodmodeButton
          theme="ghost"
          width={150}
          onClick={() => setShowGodmode(true)}
        >
          Godmode
        </GodmodeButton>
      )}
    </StyledPanelContainer>
  )
}

export default DevSettings
