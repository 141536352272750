import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import SignaturePad from 'react-signature-pad'
import { colors, fontSizes } from 'styles'
import TaskBar from 'components/taskbar'
import LabeledToggle from 'components/labeled-toggle'
import isTruthy from 'utils/is-truthy'
import SignatureBackground from './signature-pad-background.png'
import './myCanvas.css'

const Wrapper = styled.div``

const SignatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SignaturePadWrapper = styled.div`
  margin-bottom: 30px;
  background-color: ${colors.lightGrey};
  height: 250px;
  position: relative;
  display: flex;
  align-items: flex-end;
  .m-signature-pad {
    padding: 0px 30px;
    width: 100%;
  }
  .m-signature-pad--body {
    background-image: url(${props => props.url});
    background-size: cover;
    background-color: transparent;
    background-position: center;
  }
`

const SignatureTextInput = styled.input`
  width: 100%;
  display: flex;
  margin-bottom: 50px;
  text-align: center;
  font-family: Zapfino, cursive;
  font-size: ${fontSizes.large};
  border: 1px solid ${colors.lightGrey};
  outline: none;
  background-color: ${colors.lightGrey};
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: ${colors.grey};
  }
`

const NotDisplayed = styled.div`
  display: none;
`

const styles = {
  image: {
    width: '100%',
    height: '100%'
  }
}

const mapState = () => ({})

const mapDispatch = dispatch => ({
  setError: dispatch.error.setError
})

class Sign extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = {
      currentTab: 'draw',
      value: ''
    }

    this.state = { ...this.initialState }
  }

  sendSignature() {
    const { currentTab, value } = this.state
    const isText = currentTab === 'text'
    if (!isText && !this.mySignature.isEmpty()) {
      return this.mySignature.toDataURL()
    } else if (isText && isTruthy(value)) {
      return this.textToDataURL()
    }

    this.props.setError({
      type: 'error',
      message: ['Please enter your signature']
    })
    return null
  }

  textToDataURL() {
    const canvas = this.canvasText
    const canvasWidth = canvas.width / 2
    const canvasHeight = canvas.height / 2
    const ctx = canvas.getContext('2d')
    ctx.font = '30px Zapfino'
    ctx.textAlign = 'center'
    ctx.fillText(this.state.value, canvasWidth, canvasHeight)
    return canvas.toDataURL()
  }

  handleChange(event) {
    this.setState({ value: event.target.value })
  }

  handleOnChangeToggle() {
    const { currentTab } = this.state
    return this.setState({
      currentTab: currentTab === 'draw' ? 'text' : 'draw'
    })
  }

  reset() {
    this.setState({ value: '' })
    if (this.mySignature) {
      this.mySignature.clear()
    }
  }

  render() {
    const { image } = styles
    const { currentTab, value } = this.state
    const isText = currentTab === 'text'

    return (
      <Wrapper>
        <TaskBar>
          <LabeledToggle
            option1="Type"
            option2="Draw"
            onChange={() => this.handleOnChangeToggle()}
            checked={isText}
          />
        </TaskBar>
        <SignatureWrapper>
          {isText ? (
            <SignaturePadWrapper>
              <SignatureTextInput
                type="text"
                name="signature"
                placeholder="Type your signature here"
                value={value}
                onChange={event => this.handleChange(event)}
                maxLength={25}
              />
              <NotDisplayed>
                <canvas
                  ref={element => {
                    this.canvasText = element
                  }}
                  width="500px"
                  height="115px"
                />
              </NotDisplayed>
            </SignaturePadWrapper>
          ) : (
            <SignaturePadWrapper url={SignatureBackground}>
              <SignaturePad
                style={image}
                ref={element => {
                  this.mySignature = element
                }}
              />
            </SignaturePadWrapper>
          )}
        </SignatureWrapper>
      </Wrapper>
    )
  }
}

const SignPad = connect(mapState, mapDispatch, null, { forwardRef: true })(Sign)

export default SignPad
