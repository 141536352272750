import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AgreementDescription } from 'components/agreement'
import { fontWeights } from 'styles'
import { Input } from 'components/form'

const Wrapper = styled.div`
  padding-bottom: 90px;
`
const AgreeWrapper = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const AgreeText = styled.p`
  font-weight: ${fontWeights.medium};
`

const Terms = ({ hasAgreed, setHasAgreed }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AgreementDescription key="content" agreementId="terms_conditions" />
      <AgreeWrapper key="agree-section">
        <AgreeText>
          {t(
            'b.contract.view.agree_with_terms.text',
            'I agree with Terms & Conditions'
          )}
        </AgreeText>
        <Input
          type="checkbox"
          checked={hasAgreed}
          onChange={() => setHasAgreed(!hasAgreed)}
        />
      </AgreeWrapper>
    </Wrapper>
  )
}

export default Terms
