import React from 'react'
import some from 'utils/some'

const UserFullName = ({ firstName, lastName, className }) => {
  return some(firstName, lastName) ? (
    <span className={className}>
      {firstName} {lastName}
    </span>
  ) : null
}

UserFullName.defaultProps = {
  firstName: null,
  lastName: null,
  className: null
}

export default UserFullName
