import ENV_VARS from 'config'
import { get, post, put, del } from 'ports/api'

const { BACKEND_SERVER } = ENV_VARS

export const getListingSummary = get(
  `${BACKEND_SERVER}/web/listings/%listingId%/fetch`
)
export const getListing = get(`${BACKEND_SERVER}/listings/%listingId%`)
export const createListing = post(
  `${BACKEND_SERVER}/buildings/%buildingId%/units`
)
export const updateListing = put(`${BACKEND_SERVER}/listings/%listingId%`)
export const updateListingLandlords = put(
  `${BACKEND_SERVER}/listings/%listingId%/landlords`
)
export const deleteListing = del(`${BACKEND_SERVER}/listings/%listingId%`)
export const shareEmail = post(
  `${BACKEND_SERVER}/listings/%listingId%/share_listing`
)
export const getListingApplications = get(
  `${BACKEND_SERVER}/listings/%listingId%/applications`
)
export const shareText = post(
  `${BACKEND_SERVER}/users/invite/tenant/phone/%phoneNumber%/listings/%listingId%`
)
export const getListingTimelines = get(
  `${BACKEND_SERVER}/timeline/listings/%listingId%`
)
export const updateUnit = put(
  `${BACKEND_SERVER}/buildings/%buildingId%/units/%unitId%`
)
export const getContract = get(
  `${BACKEND_SERVER}/contracts/listings/%listingId%`
)
export const verifyCreateListing = post(
  `${BACKEND_SERVER}/listings/verify_renew_availability`
)
export const cloneListing = post(`${BACKEND_SERVER}/listings/%listingId%/clone`)

// Images
export const sortImages = post(`${BACKEND_SERVER}/files/sort`)

// Notes
export const updateNotes = put(`${BACKEND_SERVER}/listings/%listingId%/notes`)
export const getNotes = get(`${BACKEND_SERVER}/listings/%listingId%/notes`)

export const generateDeeplink = get(
  `${BACKEND_SERVER}/campaign/app/listing/%listingId%/link`
)

// Suggested tenants
export const getSuggestedTenants = get(
  `${BACKEND_SERVER}/listings/%listingId%/matches`
)
export const landlordSendFirstMessage = post(
  `${BACKEND_SERVER}/timeline/listings/%listingId%/tenants/%tenantId%`
)
export const removeSuggestedTenant = post(
  `${BACKEND_SERVER}/listings/%listingId%/users/%userId%/noinvite`
)

// Appointments
export const getListingAppointments = get(
  `${BACKEND_SERVER}/web/appointments/listings/%listingId%`
)
export const createAppointment = post(
  `${BACKEND_SERVER}/appointments/listings/%listingId%`
)
export const updateAppointment = put(
  `${BACKEND_SERVER}/appointments/%timeslotId%/calendar`
)
export const hideAppointment = put(
  `${BACKEND_SERVER}/appointments/%appointmentId%/hide`
)
export const unhideAppointment = put(
  `${BACKEND_SERVER}/appointments/%appointmentId%/unhide`
)
export const deleteAppointment = del(
  `${BACKEND_SERVER}/appointments/%appointmentId%`
)
export const delayAppointment = put(
  `${BACKEND_SERVER}/appointments/%appointmentId%/snooze/%duration%`
)
export const bookShowing = post(
  `${BACKEND_SERVER}/appointments/%timeslotId%/subscribe`
)
export const updateShowing = put(
  `${BACKEND_SERVER}/appointments/%timeslotId%/subscribe`
)
export const cancelShowing = post(
  `${BACKEND_SERVER}/appointments/%timeslotId%/unsubscribe`
)
export const videoRequest = post(
  `${BACKEND_SERVER}/files/units/%unitId%/video_request`
)
export const videoRequestSuccess = put(
  `${BACKEND_SERVER}/files/units/%unitId%/video_request_success`,
  {
    urlEncoded: true,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  }
)

// Verify listing
export const uploadListingVerification = post(
  `${BACKEND_SERVER}/secure/listing_verification/%listingId%`
)
export const requestListingCode = post(
  `${BACKEND_SERVER}/listings/verify/request_token`
)
export const verifyListingCode = post(
  `${BACKEND_SERVER}/listings/verify/validate_token`,
  {
    failSilently: true
  }
)
