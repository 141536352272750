import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik, Form, FastField } from 'formik'
import * as Yup from 'yup'
import { colors, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import moment from 'moment'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import {
  Label,
  Group,
  FieldDatetimePicker,
  FieldSwitchToggle,
  FieldInput
} from 'components/form'
import { showAddToCalendarModal } from 'features/Listing/add-to-calendar-modal.js'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const StyledGroup = styled(Group)`
  flex: 1;
  padding: 20px 30px 5px 30px;
  margin: 0;
`

const Disclaimer = styled.div`
  color: ${colors.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 42px;
  font-size: 12px;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin: 0;
  letter-spacing: 0.25px;
  margin: 30px auto;
`

const AddSlotModal = ({
  title = 'Set Availability',
  listingId,
  onSave = noop
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialValues = {
    meeting_at: '',
    hidden: false,
    duration: 15,
    add_to_calendar: ''
  }

  const validationSchema = Yup.object().shape({
    meeting_at: Yup.date()
      .min(
        moment(Date.now()).add(1, 'minutes'),
        t(
          'l.listing.showings.add_slot.meeting_at.error',
          'Start time cannot be in the past'
        )
      )
      .required(),
    hidden: Yup.boolean().required(),
    duration: Yup.number()
      .positive()
      .max(999)
      .integer('Please provide an integer')
      .required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    const hidden = values.hidden ? 1 : 0
    const duration = Number(values.duration)
    const meeting_at = moment(values.meeting_at).format('YYYY-MM-DD HH:mm:ss')

    const response = await dispatch.listing.createAppointment({
      listingId: listingId,
      body: {
        meeting_at: meeting_at,
        duration: duration,
        hidden: hidden
      }
    })
    setSubmitting(false)

    if (!response.ok) {
      return values.add_to_calendar
        ? (showAddToCalendarModal({ values, listingId }), onSave(), close())
        : (onSave(), close())
    }
  }

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>{title}</Title>
      </Header>
      <Formik
        padding="15px"
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <StyledGroup>
              <FieldDatetimePicker
                name="meeting_at"
                placeholder={
                  t(
                    'l.listing.showings.start_date.label',
                    'Date and meeting time'
                  ) + '*:'
                }
                disablePast
              />
            </StyledGroup>
            <StyledGroup>
              <FieldInput
                name="duration"
                suffix="minutes"
                placeholder={
                  t(
                    'l.listing.showings.showing_duration.label',
                    'Showing Duration'
                  ) + '*:'
                }
              />
            </StyledGroup>
            <StyledGroup inline justify="space-between">
              <Label>
                {t('l.listing.showings.hide_timeslot.label', 'Hide Timeslot')}
              </Label>
              <FieldSwitchToggle name="hidden" component={FastField} />
            </StyledGroup>
            <Disclaimer>
              {t(
                'l.listing.showings.hide_timeslot.discription',
                'Hiding a timeslot will make it invisible to everyone but you and the attendees.'
              )}
            </Disclaimer>
            <StyledGroup inline justify="space-between">
              <Label>
                {t(
                  'l.listing.showings.add_to_calndar.label',
                  'Add to my calendar'
                )}
              </Label>
              <FieldSwitchToggle name="add_to_calendar" component={FastField} />
            </StyledGroup>
            <Disclaimer>
              {t(
                'l.listing.showings.add_to_calendar.discription',
                'Enabling this will allow you to add this appointment to you calendar.'
              )}
            </Disclaimer>
            <ActionButton
              name="license_pill"
              theme="primary"
              width={125}
              left="auto"
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {t('l.listing.showings.add_a_slot_modal.submit.button', 'Submit')}
            </ActionButton>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export const showAddSlotModal = createModal(AddSlotModal)
