import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { colors, fontSizes } from 'styles'
import { Form, Formik } from 'formik'
import { FieldInput, Fieldset } from 'components/form'
import * as Yup from 'yup'

const Title = styled.span`
  font-size: ${fontSizes.large};
  font-weight: bold;
  color: ${colors.regular};
  text-align: center;
`

const Description = styled.span`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  text-align: center;
  padding: 30px 0;
`

const InputContainer = styled(Form)`
  width: 100%;
  overflow: hidden;
  text-align: center;
  & fieldset {
    margin-bottom: 15px;
  }
  :first-child {
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin-bottom: 40px;
  }
  > fieldset {
    > div:first-of-type {
      margin-top: 5px;
    }
  }
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required()
})

const ShareByEmail = ({ listingId, onClose }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const handleSend = async (values, { setSubmitting }) => {
    await dispatch.listing.sendEmailInvite({
      listingId: listingId,
      email: values.email
    })
    setSubmitting(false)
    onClose()
  }

  return (
    <>
      <Title>
        {t('l.listing.view.share_email_modal.title', 'Invite Contacts!')}
      </Title>
      <Description>
        {t(
          'l.listing.view.share_email_modal_description.text',
          'Invite potential renters by sharing your listing and connecting them with liv.rent'
        )}
      </Description>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSend}
      >
        {({ isSubmitting }) => (
          <InputContainer>
            <Fieldset>
              <FieldInput
                name="email"
                placeholder={t(
                  'l.listing.view.share_email_modal_placeholder.text',
                  'Email Address'
                )}
                autoFocus
              />
            </Fieldset>
            <Fieldset>
              <Button
                type="submit"
                name="submit"
                theme="primary"
                size="medium"
                width={160}
                disabled={isSubmitting}
              >
                {t(
                  'l.listing.view.share_email_modal_invite.button',
                  'Invite Renter'
                )}
              </Button>
            </Fieldset>
          </InputContainer>
        )}
      </Formik>
    </>
  )
}

export default React.memo(ShareByEmail)
