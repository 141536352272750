import React from 'react'
import styled from 'styled-components'
import { fontWeights, colors } from 'styles'

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`

const DetailTitle = styled.p`
  color: ${colors.mediumGrey};
  font-size: 12px;
  line-height: 17px;
  margin: 0;
`

const DetailText = styled.p`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  line-height: 19px;
  margin: 5px 0 10px 0;
  overflow-wrap: break-word;
  word-break: break-word;
`

const LabeledDetail = ({ label, value, width }) => (
  <DetailWrapper width={width}>
    <DetailTitle>{label}</DetailTitle>
    <DetailText>{value}</DetailText>
  </DetailWrapper>
)

LabeledDetail.defaultProps = {
  label: '',
  value: '',
  width: '100%'
}

export default LabeledDetail
