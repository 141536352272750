/* global H */
export const Icons = new Map()
export default function getMarkerIcon(bitmap) {
  if (!Icons.has(bitmap)) {
    const icon = new H.map.Icon(bitmap)
    Icons.set(bitmap, icon)
  }

  return Icons.get(bitmap)
}
