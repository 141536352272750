import { createSelector } from 'reselect'
import { mergeSpec, headObj, callOr } from 'utils'
import {
  pipe,
  map,
  filter,
  flip,
  prop,
  path,
  pathOr,
  values,
  propEq,
  __,
  pick,
  evolve,
  identity,
  find,
  defaultTo
} from 'ramda'
import { toInteger } from 'lodash'

const formatEditLanlordProfile = pipe(
  mergeSpec({
    business_licence: pathOr('', ['company_info', 'business_licence']),
    company_name: pathOr('', ['company_info', 'name']),
    company_phone_country_code: pathOr('', [
      'company_info',
      'phone_country_code'
    ]),
    company_phone_country_code_alpha: pathOr('', [
      'company_info',
      'phone_country_code_alpha'
    ]),
    company_phone_number: pathOr('', ['company_info', 'phone_number']),
    company_email: pathOr('', ['company_info', 'email']),
    company_unit_number: pathOr('', ['company_info', 'unit_number']),
    company_address: pathOr('', ['company_info', 'address']),
    company_city: pathOr('', ['company_info', 'city']),
    company_zip: pathOr('', ['company_info', 'zip']),
    company_state: pathOr('', ['company_info', 'state']),
    company_country_code: pathOr('', ['company_info', 'country_code']),
    company_verified_state: path([
      'company_info',
      'business_licence_verified_state'
    ]),
    about_me: pathOr('', ['about_me']),
    first_name: pathOr('', ['first_name']),
    last_name: pathOr('', ['last_name']),
    preferred_name: pathOr('', ['preferred_name']),
    phone: pathOr('', ['phone']),
    username: pathOr('', ['username']),
    work_history_year_started: pathOr('', ['work_history_year_started']),
    hide_phone_number: pathOr('', ['hide_phone_number'])
  })
)

const formatEditTenantProfile = pipe(
  mergeSpec({
    company_unit_number: pathOr('', ['company_info', 'unit_number']),
    company_address: pathOr('', ['company_info', 'address']),
    company_city: pathOr('', ['company_info', 'city']),
    company_zip: pathOr('', ['company_info', 'zip']),
    company_state: pathOr('', ['company_info', 'state']),
    company_country_code: pathOr('', ['company_info', 'country_code']),
    company_name: pathOr('', ['company_info', 'name']),
    about_me: pathOr('', ['about_me']),
    first_name: pathOr('', ['first_name']),
    last_name: pathOr('', ['last_name']),
    income_verification: pipe(
      path(['files', 'user_secure_files']),
      defaultTo({}),
      values,
      find(propEq('tag', 'income_verification')),
      defaultTo('')
    ),
    employment_verification: pipe(
      path(['files', 'user_secure_files']),
      defaultTo({}),
      values,
      find(propEq('tag', 'employment_verification')),
      defaultTo('')
    ),
    school_verification: pipe(
      path(['files', 'user_secure_files']),
      defaultTo({}),
      values,
      find(propEq('tag', 'school_verification')),
      defaultTo('')
    ),
    preferred_name: pathOr('', ['preferred_name']),
    phone: pathOr('', ['phone']),
    username: pathOr('', ['username']),
    dob: pathOr('', ['dob']),
    english_zodiac: pathOr('', ['zodiac', 'zodiac']),
    english_zodiac_unicode_sign: pathOr('', ['zodiac', 'zodiac_unicode_sign']),
    chinese_zodiac: pathOr('', ['zodiac', 'chinese_zodiac']),
    chinese_zodiac_unicode_sign: pathOr('', [
      'zodiac',
      'chinese_zodiac_unicode_sign'
    ]),
    job_title: pathOr('', ['job_title']),
    school_name: pathOr('', ['school_name']),
    school_subject: pathOr('', ['school_subject']),
    school_start_date: pathOr('', ['school_start_date']),
    school_side_working: pathOr(0, ['school_side_working'])
  })
)

const toBoolean = callOr(pipe(toInteger, Boolean), undefined)

export const editLandlordProfileSelector = createSelector(
  identity,
  formatEditLanlordProfile
)

export const editTenantProfileSelector = createSelector(
  identity,
  formatEditTenantProfile
)

export const petsSelector = createSelector(
  prop('user_pets'),
  path(['files', 'user_pet']),
  (pets, files) =>
    pipe(
      values,
      map(
        mergeSpec({
          avatar: pipe(prop('id'), prop(__, files), headObj)
        })
      )
    )(pets)
)

export const contactsSelector = createSelector(
  prop('user_contacts'),
  flip(prop('type')),
  (contacts, type) =>
    pipe(values, filter(propEq('contact_type', type)))(contacts)
)

export const employersSelector = createSelector(prop('user_employers'), values)

export const roommatesSelector = createSelector(
  prop('user_roommates'),
  contacts => pipe(values)(contacts)
)

export const vehiclesSelector = createSelector(prop('user_vehicles'), values)

export const settingsSelector = createSelector(
  identity,
  pipe(
    pick([
      'matches_receive_emails',
      'matches_receive_push_notifications',
      'matches_receive_text_messages',
      'activity_receive_emails',
      'activity_receive_push_notifications',
      'activity_receive_text_messages',
      'setting_promo_emails',
      'setting_language',
      'setting_size_unit',
      'setting_distance_unit',
      'setting_currency',
      'allow_discovery'
    ]),
    evolve({
      matches_receive_emails: toBoolean,
      matches_receive_push_notifications: toBoolean,
      matches_receive_text_messages: toBoolean,
      activity_receive_emails: toBoolean,
      activity_receive_push_notifications: toBoolean,
      activity_receive_text_messages: toBoolean,
      setting_promo_emails: toBoolean,
      allow_discovery: toBoolean
    })
  )
)
