import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LabeledDetail from 'components/labeled-detail'
import useMediaQuery from 'hooks/use-media-query'
import { useFlag } from 'flags'
import { breakpoints } from 'styles'
import { Row, Group } from 'components/form'
import { SubtitleText, DetailsWrapper, ContactNameWrapper } from '../elements'

const EmploymentReferenceRow = styled(Row)`
  width: 100%;
`
const EmploymentReferenceView = ({ data }) => {
  const [t] = useTranslation(['translation', 'basic'])
  const widthPercent = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? `100%`
    : `50%`

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 5

  const infoColSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 7

  const showNewProfile = useFlag(['features', 'newProfile'])

  return (
    <EmploymentReferenceRow>
      <Group col={colSize}>
        <ContactNameWrapper width={`100%`}>
          <SubtitleText>
            {data.first_name} {data.last_name}
          </SubtitleText>
        </ContactNameWrapper>
      </Group>
      <Group col={infoColSize}>
        <DetailsWrapper>
          <LabeledDetail
            label={t('r.profile.view.email.label', 'Email')}
            value={data.contact_email || '-'}
            width={widthPercent}
          />
          <LabeledDetail
            label={t('r.profile.view.phone_number.label', 'Phone Number')}
            value={data.phone_number}
            width={widthPercent}
          />
          <LabeledDetail
            label={t('r.profile.view.company_name.label', 'Company Name')}
            value={data.employer_name}
            width={widthPercent}
          />
          <LabeledDetail
            label={t('r.profile.view.job_title.label', 'Position')}
            value={data.job_title}
            width={widthPercent}
          />
          {showNewProfile && (
            <>
              <LabeledDetail
                label={t('r.profile.view.start_date.label', 'Start Date')}
                value={data.start_date}
                width={widthPercent}
              />
              <LabeledDetail
                label={t('r.profile.view.end_date.label', 'End Date')}
                value={
                  data.end_date
                    ? data.end_date
                    : t('r.profile.view.current_date.text', 'Current')
                }
                width={widthPercent}
              />
            </>
          )}
        </DetailsWrapper>
      </Group>
    </EmploymentReferenceRow>
  )
}

export default React.memo(EmploymentReferenceView)
