import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100px;
`

const TopText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.regular};
  margin: 0;
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'unset')};
`

const BottomText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const DoubleText = ({ topText, bottomText, children, maxWidth }) => (
  <Wrapper>
    <TopText>{topText}</TopText>
    <BottomWrapper maxWidth={maxWidth}>
      <BottomText>{bottomText}</BottomText>
      {children}
    </BottomWrapper>
  </Wrapper>
)

export default DoubleText
