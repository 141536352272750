import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { path, isEmpty } from 'ramda'
import { isNotEmpty } from 'utils'
import CreateContract from 'features/Contract/CreateContract'

const ApplicationCreateContract = ({
  match: {
    params: { applicationId, contractId }
  },
  history
}) => {
  const dispatch = useDispatch()

  const application = useSelector(state =>
    select.application.applicationDetail(state, {
      applicationId
    })
  )

  const listingContract = useSelector(
    path(['listing', application?.listing_id, 'contract'])
  )

  const contract = useSelector(state =>
    select.contract.contractDetail(state, {
      listingId: application?.listing_id,
      contractId,
      applicationId
    })
  )

  useEffect(() => {
    dispatch.contract.loadAddendumTemplates()
  }, [dispatch.contract])

  useEffect(() => {
    if (application?.listing_id) {
      dispatch.listing.load(application.listing_id)
      dispatch.listing.getContract(application.listing_id)
    }
  }, [application, dispatch.listing])

  useEffect(() => {
    dispatch.application.load(applicationId)
  }, [applicationId, dispatch.application])

  const listingContractId = listingContract?.listing_contract_id

  useEffect(() => {
    if (listingContractId) {
      history.replace(
        `/application/${applicationId}/contract/create/${
          listingContractId || ''
        }`
      )
    }
  }, [applicationId, history, listingContractId])
  useEffect(() => {
    if (contractId) {
      dispatch.contract.load(contractId)
    }
  }, [contractId, dispatch.contract])

  const preventRender = () => {
    return (
      !listingContract || (isNotEmpty(listingContract) && isEmpty(contract))
    )
  }

  if (preventRender()) return null

  return (
    <CreateContract
      applicationId={applicationId}
      listingId={application.listing_id}
      contractId={listingContract?.listing_contract_id}
      contract={contract}
    />
  )
}

export default ApplicationCreateContract
