import { createSelector } from 'reselect'
import {
  values,
  flip,
  prop,
  pipe,
  groupBy,
  sortBy,
  identity,
  defaultTo,
  when,
  __
} from 'ramda'
import { mergeSpec, isTruthy } from 'utils'

const listingPaymentInfoSelector = (field = 'payment_requests') =>
  createSelector(
    flip(prop('listingId')),
    identity,
    pipe(prop, defaultTo({}), prop(field))
  )

export const groupedPaymentsSelector = createSelector(
  listingPaymentInfoSelector('payment_requests'),
  pipe(values, sortBy(prop('due_date')), groupBy(prop('state_machine')))
)

const paymentSelector = createSelector(
  flip(prop('paymentId')),
  listingPaymentInfoSelector('payment_requests'),
  prop
)

export const paymentWithUsersSelector = createSelector(
  paymentSelector,
  listingPaymentInfoSelector('users'),
  (payment, users) => {
    return when(
      isTruthy,
      mergeSpec({
        tenant: pipe(prop('tenant_id'), prop(__, users)),

        user: pipe(prop('user_id'), prop(__, users))
      })
    )(payment)
  }
)
