import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'
import toInteger from 'lodash/toInteger'

const Wrapper = styled.div`
  height: ${props => props.height || 4}px;
  width: 100%;
  background-color: ${colors.mediumLightGrey};
  position: relative;
`

const InnerDiv = styled.div`
  height: ${props => props.height || 4}px;
  width: ${props => props.progress}%;
  background-image: linear-gradient(
    ${props =>
      props.fillColor ||
      `to right, ${colors.secondary}, ${colors.secondaryLightBlue}`}
  );
  position: relative;
  display: flex;
  justify-content: flex-end;
`

const ProgressText = styled.p`
  color: ${colors.white};
  font-size: ${fontSizes.small};
  position: absolute;
  top: -32px;
  right: ${props => (props.progress < 0.07 ? -25 : 0)}px;
`

const ProgressBar = ({
  height,
  progress = 0,
  fillColor,
  showPercentage = true
}) => {
  return (
    <Wrapper height={height}>
      <InnerDiv height={height} progress={progress} fillColor={fillColor}>
        {showPercentage && (
          <ProgressText progress={progress}>
            {toInteger(progress)}%
          </ProgressText>
        )}
      </InnerDiv>
    </Wrapper>
  )
}

export default ProgressBar
