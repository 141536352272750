import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import EmergencyContactsList from './list'
import EmergencyContactForm from './form'

const EmergencyContactsFullList = ({
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  const emergencyContacts = useSelector(state =>
    select.profile.contacts(state, {
      type: 'emergency'
    })
  )
  return (
    <EmergencyContactsList
      data={emergencyContacts}
      AddComponent={EmergencyContactForm}
      EditComponent={EmergencyContactForm}
      emptyMessage={t(
        'r.profile.view.empty_emergency_contacts.text',
        "You don't have emergency contacts in your profile."
      )}
      editable
      showRequiredWarning={showRequiredWarning}
      anchorTop={anchorTop}
    />
  )
}

export default React.memo(EmergencyContactsFullList)
