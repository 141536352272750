import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { pipe, pickAll, evolve, prop } from 'ramda'
import moment from 'moment'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import noop from 'lodash/noop'
import useMediaQuery from 'hooks/use-media-query'
import { breakpoints } from 'styles'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import {
  Label,
  Row,
  Group,
  FieldDatePicker,
  FieldInput,
  PhoneInput,
  SwitchToggle
} from 'components/form'
import { defaultIfFalsy, mergeSpec, isFalsy } from 'utils'

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const ToggleRow = styled(Row)`
  justify-content: space-between;
`

const PhoneRow = styled(Row)`
  align-items: flex-start;
  > div:first-of-type {
    padding-top: 6px;
  }
`

const EmploymentReferenceForm = React.memo(
  ({ data = {}, onCancel = noop, onSave = noop, onDelete = noop }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(['translation', 'basic'])
    const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
      ? 12
      : 6

    const initialValues = pipe(
      pickAll([
        'id',
        'first_name',
        'last_name',
        'employer_name',
        'job_title',
        'phone_number',
        'contact_email',
        'i_currently_work_here',
        'start_date',
        'end_date'
      ]),
      mergeSpec({
        i_currently_work_here: pipe(prop('end_date'), isFalsy)
      }),
      evolve({
        first_name: defaultIfFalsy(''),
        last_name: defaultIfFalsy(''),
        employer_name: defaultIfFalsy(''),
        job_title: defaultIfFalsy(''),
        phone_number: defaultIfFalsy(''),
        contact_email: defaultIfFalsy(''),
        start_date: defaultIfFalsy(null),
        end_date: defaultIfFalsy(null)
      })
    )(data)

    const validationSchema = Yup.object().shape({
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
      employer_name: Yup.string().required(),
      job_title: Yup.string().required(),
      phone_number: Yup.string().phone().required(),
      contact_email: Yup.string().email(),
      start_date: Yup.date().required(),
      end_date: Yup.mixed().when('i_currently_work_here', {
        is: false,
        then: Yup.date().required(),
        otherwise: null
      })
    })

    const isEditing = !!data.id

    const handleDeleteClick = useCallback(() => {
      return confirm(
        t(
          'r.profile.edit.delete_employer_reference.title',
          'Delete Employment Reference?'
        ),
        t(
          'r.profile.edit.delete_employer_confirmation.text',
          'Are you sure you want to delete this employment reference?'
        )
      ).then(async () => {
        const response = await dispatch.profile.deleteEmployer(data.id)
        if (!response.error) {
          onDelete()
        }
      })
    }, [data.id, dispatch.profile, onDelete, t])

    const onSubmit = async (values, { setSubmitting }) => {
      const action = isEditing
        ? dispatch.profile.updateEmployer
        : dispatch.profile.addEmployer
      const response = await action(values)
      setSubmitting(false)

      if (!response.error) {
        onSave()
      }
    }

    const initialMonth = values => {
      return values.start_date ? moment(values.start_date) : null
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          handleChange,
          handleSubmit
        }) => (
          <Form>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="first_name"
                  placeholder={
                    t('r.profile.edit.first_name.label', 'First Name') + ':*'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="last_name"
                  placeholder={
                    t('r.profile.edit.last_name.label', 'Last Name') + ':*'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="employer_name"
                  placeholder={
                    t('r.profile.edit.company_name.label', 'Company Name') +
                    ':*'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="job_title"
                  placeholder={
                    t('r.profile.edit.job_title.label', 'Job Title') + ':*'
                  }
                />
              </Group>
            </Row>
            <PhoneRow gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="contact_email"
                  placeholder={
                    t('r.profile.edit.email.label', 'Email') +
                    ' ' +
                    t('r.profile.edit.optional.label', '(Optional)') +
                    ':'
                  }
                />
              </Group>
              <Group col={colSize}>
                <PhoneInput
                  name="phone_number"
                  value={values.phone_number}
                  countryCodeAlpha={data.phone_country_code_alpha}
                  onChange={handleChange('phone_number')}
                  placeholder={
                    t('r.profile.edit.phone_number.label', 'Phone Number') +
                    ':*'
                  }
                />
              </Group>
            </PhoneRow>
            <Group col={colSize}>
              <ToggleRow>
                <Label>
                  {t(
                    'r.profile.references.add_reference.I_currently_work_here.label',
                    'I currently work here'
                  )}
                </Label>
                <SwitchToggle
                  name="i_currently_work_here"
                  enabled={values.i_currently_work_here}
                  onChange={() => {
                    setFieldValue(
                      'i_currently_work_here',
                      !values.i_currently_work_here
                    )
                    setFieldValue('end_date', null)
                  }}
                />
              </ToggleRow>
            </Group>
            <Row>
              <Group col={colSize}>
                <FieldDatePicker
                  name="start_date"
                  placeholder={
                    t('l.profile.edit.start_date.label', 'Start Date') + '*:'
                  }
                  maxDate={values.end_date ? moment(values.end_date) : moment()}
                  disableFuture
                />
              </Group>
              {!values.i_currently_work_here && (
                <Group col={colSize}>
                  <FieldDatePicker
                    name="end_date"
                    placeholder={
                      t('l.profile.edit.end_date.label', 'End Date') + '*:'
                    }
                    disabled={
                      values.i_currently_work_here || !values.start_date
                    }
                    initialFocusedDate={initialMonth(values)}
                    minDate={moment(values.start_date)}
                    disableFuture
                  />
                </Group>
              )}
            </Row>
            <Buttons>
              {isEditing ? (
                <ActionButton
                  theme="danger"
                  onClick={handleDeleteClick}
                  size="small"
                >
                  {t('r.profile.edit.delete.button', 'Delete')}
                </ActionButton>
              ) : (
                <ActionButton theme="neutral" onClick={onCancel} size="small">
                  {t('r.profile.edit.cancel.button', 'Cancel')}
                </ActionButton>
              )}
              <ActionButton
                type="button"
                theme="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isEditing
                  ? t('r.profile.edit.update.button', 'Update')
                  : t('r.profile.edit.add.button', 'Add')}
              </ActionButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    )
  }
)

export default EmploymentReferenceForm
