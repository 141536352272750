import { sendFile, deleteFile, updateFile } from './ports'

const insurance = {
  state: {},
  reducers: {},
  effects: dispatch => ({
    sendFile: async ({ data }) => {
      try {
        const response = await sendFile(
          { body: data },
          { tag: 'insurance_info' }
        )
        if (response.response.ok) {
          dispatch.profile.patchSecureFiles(response.body.files)
          return response
        }
      } catch (error) {
        console.log('[InsuranceInfo/sendFile]', error)
      }
    },
    updateFile: async ({ fileId, data }) => {
      try {
        const response = await updateFile(
          { body: data },
          {
            fileId,
            tag: 'insurance_info'
          }
        )
        if (response.response.ok) {
          dispatch.profile.patchSecureFiles(response.body.files)
          return response
        }
      } catch (error) {
        console.log('[InsuranceInfo/updateFile]', error)
      }
    },
    deleteFile: async ({ fileId }) => {
      try {
        const response = await deleteFile(undefined, {
          fileId,
          tag: 'insurance_info'
        })
        if (response.response.ok) {
          dispatch.profile.deleteSecureFile(fileId)
        }
      } catch (error) {
        console.log('[InsuranceInfo/deleteFile]', error)
      }
    }
  })
}

export default insurance
