import React from 'react'
import noop from 'lodash/noop'
import { MessageModal } from 'components/message-modal'
import { createModal } from 'components/modalize'

const SuccessModal = ({ title, message, buttonText, onClose = noop }) => (
  <MessageModal
    title={title}
    message={message}
    buttonText={buttonText}
    onClose={onClose}
    icon="round_check"
  />
)
export const show = createModal(SuccessModal, { clickOutside: false })
