import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { getImageUrl } from 'utils/image'
import defaultImage from './default-building.jpg'

const Wrapper = styled.div`
  display: flex;
`

const Image = styled.img`
  margin-right: 20px;
  object-fit: cover;
  width: 150px;
  max-height: 180px;
`

const Name = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.38px;
  line-height: 24px;
  margin-bottom: 10px;
  margin-top: 0;
`

const Info = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.3px;
  line-height: 24px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 10px;

  ${props =>
    props.leftBordered &&
    `
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid ${colors.darkGrey};
  `}

  ${props =>
    props.rightBordered &&
    `
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${colors.darkGrey};
  `}

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
`

const Link = styled.a`
  font-size: ${fontSizes.small};
  color: ${colors.link};
  text-decoration: none;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-top: 7px;
  }
`

const ListingInfo = ({ data, link: { text, ...link } }) => {
  const image = data.images.length
    ? getImageUrl(data.images[0].aws_s3_key, { height: 200 })
    : defaultImage
  return (
    <Wrapper>
      <Image src={image} alt={data.name} />
      <InfoWrapper>
        <Name>{data.name}</Name>
        <Info>
          {data.full_street_name}
          {data.neighborhood && ` - ${data.neighborhood}`}
          <br />
          {data.city}, {data.state}
        </Info>
        {link && <Link {...link}>{text}</Link>}
      </InfoWrapper>
    </Wrapper>
  )
}

export default React.memo(ListingInfo)
