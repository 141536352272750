import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { select } from 'store'
import formatContent from 'utils/format-content'
import htmlToReact from 'utils/html-to-react'
import { createModal, useModalize } from 'components/modalize'
import { Header } from 'components/collapsible/collapsible-box'
import { CollapsibleBox } from 'components/collapsible'
import Icon from 'components/icon'
import formatVariables from '../format-variables'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
`

const ModalHeader = styled.div`
  text-align: center;
  padding: 20px 15px;
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const ModalTitle = styled.h2`
  display: inline-block;
  margin: 0 0 0 24px;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  line-height: 28px;
`

const ModalClose = styled.div`
  float: right;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 10px 25px 0;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px 15px 0;
  }
`

const Terms = styled(CollapsibleBox)`
  border: none;
  border-bottom: 1px solid ${colors.grey};
  border-radius: 0;
`

const TermsHeader = styled(Header)`
  border-bottom: 0;
  margin-top: 20px;
  font-size: ${fontSizes.medium};
  line-height: 26px;
  font-weight: ${fontWeights.medium};
  background-color: transparent;
`

const ContractTermsModal = props => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const listingId = props.contract.listingId
  const terms = useSelector(state =>
    select.contract.terms(state, {
      listingId
    })
  )
  const formattedVariables = formatVariables(props.contract)

  useEffect(() => {
    dispatch.contract.getTerms(listingId)
  }, [dispatch.contract, listingId])

  const renderTerm = ({ title, content }) => {
    const formattedContent = formatContent(content, formattedVariables)

    return (
      <Terms
        key={`term-${title}`}
        title={title}
        header={TermsHeader}
        initiallyClosed
      >
        {htmlToReact(formattedContent)}
      </Terms>
    )
  }

  if (!terms) return null

  return (
    <Wrapper>
      <ModalHeader>
        <ModalTitle>
          {t(
            'l.contract.edit.bc_tenancy_agreement.text',
            'BC Tenancy Agreement'
          )}
        </ModalTitle>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
      </ModalHeader>
      <ScrollWrapper>
        {formattedVariables && terms.map(item => renderTerm(item))}
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showContractTermsModal = createModal(ContractTermsModal, {
  width: '768px'
})
