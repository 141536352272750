import React from 'react'
import { useTranslation } from 'react-i18next'
import { MessageModal } from 'components/message-modal'
import { createModal, useModalize } from 'components/modalize'

const PublishModal = ({ isPublished, goToSuggestedTenants, onClose }) => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const title = isPublished
    ? t('l.listing.view.published_listing.text', 'Listing published!')
    : t('l.listing.view.pending_listing.text', 'Listing pending...')

  const message = isPublished
    ? t(
        'l.listing.view.suggested_renters_message.text',
        'Click below to see suggested renters for your unit'
      )
    : t(
        'l.listing.view.under_review_message.text',
        'This building is currently under review. Hang tight as we go over some extra details before your listing goes live'
      )

  const buttonText =
    isPublished &&
    t('l.listing.view.view_suggestions.button', 'View Suggestions')

  const handleClose = () => {
    close()
    onClose()
  }

  const handleButtonClick = () => {
    close()
    isPublished ? goToSuggestedTenants() : onClose()
  }
  const icon = isPublished ? 'round_check' : 'pending'

  return (
    <MessageModal
      title={title}
      icon={icon}
      message={message}
      buttonText={buttonText}
      onClose={handleClose}
      onButtonClick={handleButtonClick}
    />
  )
}

export const show = createModal(PublishModal, { clickOutside: false })
