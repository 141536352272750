import React from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'

const Wrapper = styled.label`
  display: inline-block;
  color: ${props => (props.color ? props.color : colors.regular)};
  margin-top: ${props => (props.top ? `${props.top}px` : 0)};
  margin-bottom: ${props => (props.bottom ? `${props.bottom}px` : '.5rem')};
  font-size: ${props => `${props.size || 16}px`};
  letter-spacing: -0.39px;
  line-height: 20px;
  padding-left: ${props => (props.left ? `${props.left}px` : 0)};

  ${props =>
    props.strong &&
    `
    font-weight: ${fontWeights.medium};
  `}

  ${props =>
    props.noGutter &&
    `
    padding-left: 0 !important;
  `};
`
const Label = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>

export default Label
