import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import sampleDocumentImage from './sample-property-tax-notice.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  min-height: 400px;
`

const ModalClose = styled.div`
  text-align: right;
  padding: 15px 20px 10px;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
`

const ModalText = styled.p`
  margin: 30px 10px;
  text-align: center;
`

const Image = styled.img`
  max-width: 315px;
  height: auto;
  margin-bottom: 20px;
`

const ExampleDocumentModal = () => {
  const { t } = useTranslation()
  const { close } = useModalize()

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Title>
          {t(
            'l.verify_listing.view.sample_property_tax_notice.title',
            'Sample Property Tax Notice'
          )}
        </Title>
        <ModalText>
          {t(
            'l.verify_listing.view.sample_property_tax_notice.text',
            'Each year, you’ll receive a notice from the municipal. This document will help us verify your property.'
          )}
        </ModalText>
        <Image src={sampleDocumentImage} alt="Sample property tax notice" />
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(ExampleDocumentModal, { width: '470px' })
