import React, { useState, useEffect } from 'react'
import Context from './context'

const Accordion = ({ children, expanded, preventAutoCollapse }) => {
  const [visible, setVisible] = useState(expanded)
  const toggle = () => setVisible(!visible)

  useEffect(() => {
    if (!expanded && preventAutoCollapse) return
    setVisible(expanded)
  }, [expanded, preventAutoCollapse])

  return (
    <Context.Provider
      value={{
        active: visible,
        toggle
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default React.memo(Accordion)
