import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { createModal, useModalize } from 'components/modalize'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import {
  append,
  assoc,
  findIndex,
  isNil,
  lensIndex,
  map,
  pick,
  pipe,
  prop,
  propEq,
  set,
  reject,
  values as rValues
} from 'ramda'
import { show as showAddAddendumModal } from './add-addendum-modal'
import CustomAddendumForm from './custom-addendum-form'
import Content from './content'
import Options from './options'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  max-height: 95vh;
  position: relative;
`

const StyledTaskbar = styled(Taskbar)`
  background-color: ${colors.lightGrey};
  text-align: center;
  min-height: auto;
`

const NewButton = styled.button`
  color: ${colors.link};
  border: none;
  outline: none;
  font-size: ${fontSizes.small};
  background-color: transparent;
  cursor: pointer;
  min-width: 30px;
  :hover {
    opacity: 0.5;
  }
`

const StyledTool = styled(Tool)`
  min-width: 30px;
  :hover {
    opacity: 0.5;
  }
`

const ContentWrapper = styled.div`
  padding: 20px;
  background-color: ${colors.white};
  overflow-y: scroll;
`

const EditAddendumModal = ({
  data,
  contract,
  listingId,
  addendums,
  updateValue,
  createAddendum,
  deleteAddendum,
  contract_addendums = []
}) => {
  const [t] = useTranslation()
  const { close } = useModalize()

  const Types = {
    content: Content,
    options: Options
  }
  const Component = Types[data?.data_type] || CustomAddendumForm

  const formOptions = () => ({
    initialValues: {
      ...data,
      options: map(
        prop('listing_contract_addendum_template_option_txt_id'),
        rValues(data?.listing_contract_predefined_addendum_options)
      )
    },
    validationSchema: Yup.object().shape({
      title: Yup.mixed().when('data_type', {
        is: isNil,
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      description: Yup.mixed().when('data_type', {
        is: isNil,
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      allowed: Yup.bool()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!data) {
        updateValue(
          'contract_addendums',
          append(assoc('id', Date.now(), values), contract_addendums)
        )
      } else if (!data?.data_type) {
        updateValue(
          'contract_addendums',
          reject(propEq('id', data?.id), contract_addendums)
        )
      }
      if (data?.data_type) {
        updateValue(
          'contract_predefined_addendums',
          reject(
            propEq('listing_contract_addendum_template_txt_id', data?.txt_id),
            addendums
          )
        )
      }
      close()
    }
  })

  return (
    <Wrapper>
      <Formik {...formOptions()}>
        {({ values, setFieldValue, handleSubmit }) => {
          const handleBackAction = () => {
            if (!data) {
              showAddAddendumModal({
                data: addendums,
                updateValue,
                createAddendum
              })
              close()
              return
            }

            if (!data?.txt_id && data?.data_type !== 'options') {
              const index = findIndex(
                propEq('id', data?.id),
                contract_addendums
              )

              const updatedCustomAddendums = set(
                lensIndex(index),
                {
                  ...data,
                  description: values.description,
                  title: values.title
                },
                contract_addendums
              )

              updateValue('contract_addendums', updatedCustomAddendums)
            }

            if (data?.data_type === 'options') {
              const index = findIndex(
                propEq(
                  'listing_contract_addendum_template_txt_id',
                  data?.txt_id
                ),
                addendums
              )

              const updatedPredefinedAddendums = set(
                lensIndex(index),
                {
                  ...data,
                  allowed: values.allowed,
                  listing_contract_predefined_addendum_options: pipe(
                    pick(values.options),
                    map(option => ({
                      listing_contract_addendum_template_option_txt_id:
                        option.txt_id
                    })),
                    rValues
                  )(data?.listing_contract_addendum_template_options)
                },
                addendums
              )

              updateValue(
                'contract_predefined_addendums',
                updatedPredefinedAddendums
              )
            }

            close()
          }

          return (
            <>
              <StyledTaskbar
                left={
                  <StyledTool
                    type={data ? 'close' : 'back'}
                    onClick={handleBackAction}
                  />
                }
                right={
                  <NewButton onClick={handleSubmit}>
                    {data
                      ? t('common.delete', 'Delete')
                      : t('common.add', 'Add')}
                  </NewButton>
                }
              >
                {data
                  ? data?.title
                  : t(
                      'l.contract.edit.custom_addendum_modal.title',
                      'Custom Addendum'
                    )}
              </StyledTaskbar>
              <ContentWrapper>
                <Component
                  setFieldValue={setFieldValue}
                  values={values}
                  addendum={data}
                  updateValue={updateValue}
                  contract={contract}
                  listingId={listingId}
                  handleBackAction={handleBackAction}
                />
              </ContentWrapper>
            </>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export const show = createModal(EditAddendumModal, { width: '720px' })
