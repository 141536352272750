import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { any, equals, map, path, propEq, values } from 'ramda'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import useRole from 'hooks/use-role'
import { useFlag } from 'flags'
import TenantBasicInfo from 'features/Profile/TenantBasicInfo'
import LivScore from 'features/Profile/LivScore'
import { EmploymentInfo } from 'features/Profile/EmploymentInfo'
import { PetsList } from 'features/Profile/Pets'
import { LandlordReferencesList } from 'features/Profile/LandlordReferences'
import { EmploymentReferencesList } from 'features/Profile/EmploymentReferences'
import { EmergencyContactsList } from 'features/Profile/EmergencyContacts'
import { RoommatesList } from 'features/Profile/Roommates'
import { InsuranceView } from 'features/Profile/InsuranceInfo'
import { AutomobileList } from 'features/Profile/Automobile'
import ListingInfo from 'features/Listing/listing-info'
import Page from 'components/page'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import { confirm } from 'components/dialog'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Label, Row } from 'components/form'
import { PanelHeader } from 'features/Profile/elements'
import ContentWrapper from 'components/content-wrapper'
import { show as showExistingContract } from './existing-contract-modal'

const Wrapper = styled(Page)`
  @media print {
    > div {
      display: flex;
      flex-direction: column;
      max-width: 900px;
    }

    h2 {
      font-size: 28px;
      font-weight: ${fontWeights.medium};
      text-decoration: underline;
    }
  }
`

const MobileTitle = styled.h1`
  display: none;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: block;
    font-size: ${fontSizes.medium};
    font-weight: ${fontWeights.medium};
    margin-top: 50px;
    text-align: center;
  }
`

const Separator = styled.div`
  height: 1px;
  background: ${colors.mediumGrey};
  margin: 30px 0;
`

const ExtraOfferRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const ExtraOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
  }
`

const NoApplicationText = styled.p`
  text-align: center;
  font-size: ${fontSizes.regular};
`

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const StyledLabel = styled(Label)`
  color: ${colors.mediumGrey};
  font-size: 12px;
  line-height: 17px;
  margin: 0;
`

const BaseButton = ({ onClick, to, ...rest }) => {
  const extra =
    typeof onClick == 'function'
      ? { onClick }
      : {
          as: NavLink,
          to: {
            pathname: to,
            state: { scrollToTop: true }
          }
        }
  return <Button theme="primary" width={150} left={10} {...rest} {...extra} />
}

const ApplicationView = ({
  match: {
    params: { applicationId }
  },
  history
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.application.load(applicationId)
  }, [applicationId, dispatch.application])
  const { t } = useTranslation()
  const userRole = useRole()
  const showCreateContract = useFlag(['features', 'contract', 'create'])
  const showNewProfile = useFlag(['features', 'newProfile'])
  const showNewApplication = useFlag(['features', 'newApplication'])

  const application = useSelector(state =>
    select.application.applicationDetail(state, {
      applicationId
    })
  )

  const listingContract = useSelector(
    path(['listing', application?.listing_id, 'contract'])
  )

  useEffect(() => {
    if (application?.listing_id && userRole === 'landlord') {
      dispatch.listing.getContract(application.listing_id)
    }
  }, [application, dispatch.listing, userRole])

  const hasContract =
    listingContract?.listing_contract_id &&
    listingContract.state_machine !== 'draft'

  const hasValidContract =
    hasContract &&
    listingContract?.tenant_ids?.includes(application?.user_info?.id)

  const confirmWithdraw = () => {
    confirm(
      t(
        'l.withdraw_application_modal.edit.withdraw_application.title',
        'Withdraw Application?'
      ),
      t(
        'l.withdraw_application_modal.edit.confirmation.text',
        'Are you sure you want to withdraw this application?'
      )
    ).then(async () => {
      const { error } = await dispatch.application.delete(application.id)
      if (!error) {
        history.push(`/chat/${application.timeline_room}`)
        dispatch.chat.updateApplications(application.timeline_room)
      }
    })
  }

  const moveInDate = moment(application.move_in_date).format('YYYY-MM-DD')

  const applicationAvailable = !!application.id && !!application.listing_info

  const building = application.listing_info?.buildings
  const listingInBC =
    building && any(equals(true), values(map(propEq('state', 'BC'), building)))

  const hideContract =
    !applicationAvailable || !showCreateContract || !listingInBC

  const ViewContract = () => {
    if (userRole !== 'landlord' || hideContract) return null
    const create = t(
      'l.application.view.create_contract.button',
      'Create Contract'
    )
    if (!hasContract)
      return (
        <BaseButton to={`/application/${applicationId}/contract/create`}>
          {create}
        </BaseButton>
      )
    if (!hasValidContract)
      return <BaseButton onClick={showExistingContract}>{create}</BaseButton>
    return (
      <BaseButton to={`/contract/${listingContract.listing_contract_id}`}>
        {t('l.application.view.view_contract.button', 'View Contract')}
      </BaseButton>
    )
  }
  return (
    <Wrapper>
      <ContentWrapper className="mb-50">
        <Helmet>
          <title>
            {t(
              'b.application.view.tenancy_application.title',
              'Tenancy Application'
            )}
          </title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PageTitle>
          {t(
            'b.application.view.tenancy_application.title',
            'Tenancy Application'
          )}
        </PageTitle>
        {!applicationAvailable ? (
          <NoApplicationText style={{ marginTop: '200px' }}>
            {t(
              'b.application.view.tenancy_application_not_available.text',
              'This application is no longer available.'
            )}
          </NoApplicationText>
        ) : (
          <>
            <MobileTitle>
              {t(
                'b.application.view.tenancy_application.title',
                'Tenancy Application'
              )}
            </MobileTitle>
            {showNewApplication && (
              <>
                <PanelHeader />
                <StyledPanel hSpace={30} vSpace={30}>
                  <ListingInfo listingId={application.listing_id} />
                  <ExtraOfferRow top={50}>
                    <ExtraOfferWrapper>
                      <Label noGutter strong size={18} bottom="0">
                        {t(
                          'r.application.create.offer_more.label',
                          'Offer More'
                        )}
                      </Label>
                      <Label noGutter bottom={20} size={14}>
                        {t(
                          'b.application.view.tenant_offer.label',
                          "Tenant's Additional Offer"
                        )}
                        :
                      </Label>
                    </ExtraOfferWrapper>
                    <ExtraOfferWrapper>
                      <StyledLabel>
                        {t(
                          'r.application.view.additional_offer.label',
                          'Additional Offer'
                        )}
                      </StyledLabel>
                      <Label noGutter strong size={14} bottom="20">
                        {application.extra_offer
                          ? `$${application.extra_offer}`
                          : '-'}
                      </Label>
                    </ExtraOfferWrapper>
                    <ExtraOfferWrapper>
                      <StyledLabel>
                        {t(
                          'r.application.view.offer_frequency.label',
                          'Offer Frequency'
                        )}
                      </StyledLabel>
                      <Label noGutter strong size={14} bottom="20">
                        {application.extra_offer_frequency
                          ? t(
                              `l.listing.edit.payment_frequency_${application.extra_offer_frequency}.text`
                            )
                          : '-'}
                      </Label>
                    </ExtraOfferWrapper>
                  </ExtraOfferRow>
                  <Separator />
                  <ExtraOfferRow>
                    <ExtraOfferWrapper>
                      <StyledLabel>
                        {t(
                          'r.application.create.move_in_date.label',
                          'Desired Move-In Date'
                        )}
                      </StyledLabel>
                      <Label noGutter strong size={14} bottom={0}>
                        {application.move_in_date ? moveInDate : '-'}
                      </Label>
                    </ExtraOfferWrapper>
                  </ExtraOfferRow>
                </StyledPanel>
              </>
            )}
            <TenantBasicInfo data={application.user_info} />
            <PetsList data={application.user_pets}
              emptyMessage={t(
                'r.application.view.empty_pets.text',
                'No pets in this application'
              )}
            />
            <EmergencyContactsList
              data={application.emergency_contacts}
              emptyMessage={t(
                'r.application.view.empty_emergency_contacts.text',
                'No emergency contacts in this application'
              )}
            />
            <LivScore
              userRole={userRole}
              livScore={application.liv_score}
              data={application.user_info}
              listingData={
                application.listing_info.listings[application.listing_id]
              }
            />
            {showNewProfile && <EmploymentInfo data={application.user_info} />}
            <EmploymentReferencesList
              data={application.user_employers}
              emptyMessage={t(
                'r.application.view.empty_employment_references.text',
                'No employment references in this application'
              )}
            />
            <LandlordReferencesList
              data={application.landlord_references}
              emptyMessage={t(
                'r.application.view.empty_landlord_references.text',
                'I am a first-time renter and do not have any landlord references'
              )}
            />
            <RoommatesList
              data={application.user_roommates}
              emptyMessage={t(
                'r.application.view.empty_roommates.text',
                'No roommates in this application'
              )}
            />
            <InsuranceView insurance={application.insurance} />
            {showNewProfile && (
              <AutomobileList
                data={application.user_vehicles}
                emptyMessage={t(
                  'r.profile.view.no_automobiles.text',
                  'No automobiles added'
                )}
              />
            )}
          </>
        )}
      </ContentWrapper>
      <ButtonBar sticky>
        <Button theme="neutral" width={150} onClick={history.goBack}>
          {t('l.button_bar.edit.back.button', 'Back')}
        </Button>
        {userRole === 'tenant' && applicationAvailable && (
          <Button
            theme="danger"
            width={110}
            left={0}
            inversed
            onClick={confirmWithdraw}
          >
            {t('l.button_bar.edit.withdraw.button', 'Withdraw')}
          </Button>
        )}
        <ViewContract />
      </ButtonBar>
    </Wrapper>
  )
}

export default ApplicationView
