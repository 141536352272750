import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { breakpoints, colors, structure } from 'styles'
import media from 'media'
import { NamedWizard, WizardContent } from 'components/wizard'
import PageTitle from 'components/page-title'
import { toUnit } from 'utils/styles'
import PersonalInfo from './personal-info'
import FinancialInfo from './financial-info'
import Review from './review'
import Context from './context'

const WizardWrapper = styled.div`
  top: ${toUnit(structure.header.height)};
  background: ${colors.white};
  position: sticky;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid ${colors.mediumLightGrey};

  ${media.lessThan('ipadMiniMax')`
  top: ${toUnit(structure.header.heightMobile)};
  
  `}
`

const StyledNamedWizard = styled(NamedWizard)`
  margin: 0 auto;
  padding: 0 30px;
  max-width: 720px;
  background: transparent;
  border-bottom: none;

  > div {
    padding: 0px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 0 20px;
  }
`

const AddUnionPay = ({ history }) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState('personal_info')
  const [formData, setFormData] = useState({})

  const WIZARD_STEPS = {
    personal_info: t(
      'l.payment_settings.edit.banking_detail.text',
      'Banking detail'
    ),
    financial_info: t(
      'l.payment_settings.edit.financial_info.text',
      'Financial info'
    ),
    review: t('l.payment_settings.edit.review_terms.text', 'Review terms')
  }

  const handleBack = () => {
    if (currentStep === 'financial_info') {
      setCurrentStep('personal_info')
    } else if (currentStep === 'review') {
      setCurrentStep('financial_info')
    } else {
      history.push('/settings/payment-settings')
    }
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Helmet>
        <title>{t('l.payment_settings.view.unionpay.title', 'UnionPay')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('l.payment_settings.view.unionpay.title', 'UnionPay')}
      </PageTitle>
      <WizardWrapper>
        <StyledNamedWizard steps={WIZARD_STEPS} currentStep={currentStep} />
      </WizardWrapper>
      <Context.Provider value={{ formData, setFormData }}>
        <WizardContent currentStep={currentStep}>
          <PersonalInfo
            index="personal_info"
            setCurrentStep={setCurrentStep}
            handleBack={handleBack}
          />
          <FinancialInfo
            index="financial_info"
            setCurrentStep={setCurrentStep}
            handleBack={handleBack}
          />
          <Review index="review" handleBack={handleBack} history={history} />
        </WizardContent>
      </Context.Provider>
    </>
  )
}

export default AddUnionPay
