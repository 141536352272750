import { breakpoints } from 'styles'
import styled from 'styled-components'

const Element = styled.main`
  overflow: auto;
  padding-top: ${props => props.top}px;
  padding-right: ${props => props.right}px;
  padding-bottom: ${props => props.bottom}px;
  padding-left: ${props => props.left}px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const Content = Element

Content.defaultProps = {
  top: 30,
  right: 30,
  bottom: 0,
  left: 30
}

export default Content
