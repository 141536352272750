import {
  sortWith,
  ascend,
  prop,
  descend,
  toLower,
  pipe,
  defaultTo
} from 'ramda'

export const sortAscending = (propName, sortByFavourite) =>
  sortByFavourite ? sortAscendFavourite(propName) : sortAscend(propName)

export const sortDescending = (propName, sortByFavourite) =>
  sortByFavourite ? sortDescendFavourite(propName) : sortDescend(propName)

export const sortAscendFavourite = propName =>
  sortWith([
    descend(prop('favourite')),
    ascend(pipe(prop(propName), defaultTo(''), toLower))
  ])

export const sortAscend = propName =>
  sortWith([ascend(pipe(prop(propName), defaultTo(''), toLower))])

export const sortDescendFavourite = propName =>
  sortWith([
    descend(prop('favourite')),
    descend(pipe(prop(propName), defaultTo(''), toLower))
  ])

export const sortDescend = propName =>
  sortWith([descend(pipe(prop(propName), defaultTo(''), toLower))])
