import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import useFocusOnError from 'hooks/use-focus-on-error'
import { Button } from 'components/button'
import { Error } from 'components/form'
import { colors, fontSizes } from 'styles'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

const MAX_SIZE_MB = 6

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`

const ActionButton = styled(Button)`
  padding: 10px 20px;
  font-size: 12px;
  outline: none;
  :active {
    opacity: 0.5;
  }
`

const StyledError = styled(Error)`
  text-align: right;
  margin-right: 25px;
`

const ButtonWrapper = styled.div``

const RejectedText = styled.p`
  color: ${colors.red};
  margin: 0px;
  padding-top: 3px;
  font-size: ${fontSizes.small};

  ${props =>
    props.floatError &&
    `
    position: absolute;
    top: 50px;
    right: 0px;
    width: 250px;
    text-align: right;
  `}
`

const UploadButton = ({
  onDropAccepted,
  accept = 'image/png, image/jpg, image/jpeg',
  multiple = false,
  children,
  theme = 'primary',
  radius = 50,
  width = 83,
  className,
  disabled,
  name,
  showError = true,
  id,
  floatError,
  ...rest
}) => {
  const [t] = useTranslation()
  const [rejectError, setRejectError] = useState('')
  const [isRejected, setIsRejected] = useState(false)

  const sizeError = t(
    'b.profile.edit.max_upload_size.error',
    'Max file upload size is 6MB'
  )
  const typeError = t(
    'b.profile.edit.wrong_file_type.error',
    'Sorry, we do not support this file type'
  )

  const { getInputProps, open } = useDropzone({
    accept,
    multiple,
    noKeyboard: true,
    maxSize: MAX_SIZE_MB * 1000000,
    onDrop: (_, d) => {
      if (d.length > 0) {
        d[0].size > MAX_SIZE_MB * 1000000
          ? setRejectError(sizeError)
          : setRejectError(typeError)
        setIsRejected(true)
      }
    },
    onDropAccepted: onDropAccepted
  })

  const handleOnClick = () => {
    setIsRejected(false)
    open()
  }

  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })
  return (
    <Wrapper ref={fieldRef}>
      <input id={id} {...getInputProps()} />
      <ButtonWrapper>
        <ActionButton
          {...rest}
          className={className}
          onClick={handleOnClick}
          type="button"
          theme={theme}
          width={width}
          disabled={disabled}
        >
          {children || t('b.profile.edit.upload.button', 'Upload')}
        </ActionButton>
        {showError && <StyledError name={name} />}
      </ButtonWrapper>
      {isRejected && (
        <RejectedText floatError={floatError}>{rejectError}</RejectedText>
      )}
    </Wrapper>
  )
}

export default UploadButton
