import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'

const Text = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 0 0 5px;
  white-space: pre-line;
`

const ReadMore = styled(Text)`
  color: ${colors.blue};
  margin: 0 0 0 auto;
  text-align: right;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

const ReadMoreText = ({ maxChar = 100, description = '', ...rest }) => {
  const [t] = useTranslation()
  const [showAll, setShowAll] = useState(false)
  const substring = description?.substring(0, maxChar)
  const isLongerThanMax = description?.length > substring?.length
  const value = showAll
    ? description
    : substring?.concat(isLongerThanMax ? '...' : '')
  const handleClick = () => setShowAll(!showAll)

  return (
    <>
      <Text {...rest}>{value}</Text>
      {isLongerThanMax && (
        <ReadMore onClick={handleClick}>
          {showAll
            ? t('b.components.read_more_text.read_less.text', 'Read less')
            : t('b.components.read_more_text.read_more.text', 'Read more')}
        </ReadMore>
      )}
    </>
  )
}

export default ReadMoreText
