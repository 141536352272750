import ENV_VARS from 'config'
import { get, put, post, del } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const getPresets = get(`${BACKEND_SERVER}/canned_templates`)
export const createPreset = post(`${BACKEND_SERVER}/canned_templates`)
export const deletePreset = del(`${BACKEND_SERVER}/canned_templates/%presetId%`)
export const modifyPreset = put(
  `${BACKEND_SERVER}/canned_templates/%presetId%`,
  { noLoading: true }
)
export const setPresetPosition = put(
  `${BACKEND_SERVER}/canned_templates/sort/%presetId%/position/%position%`,
  { noLoading: true }
)
