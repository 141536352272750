import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { deleteFile, updateFile } from 'ports'
import {
  lensIndex,
  mergeLeft,
  move,
  over,
  pipe,
  evolve,
  sortWith,
  ascend,
  prop,
  propEq,
  reject,
  addIndex,
  includes,
  map,
  concat,
  filter,
  anyPass
} from 'ramda'
import { toInteger, partition } from 'lodash'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import Icon from 'components/icon'
import SortableList from 'components/sortable-list'
import useCallOnChange from 'hooks/use-call-on-change'
import { toast } from 'components/toast-notifications'
import UploadPanel from './upload-panel'
import ImageComponent from './image'

const ListingImageContainer = styled.div`
  object-fit: cover;
  width: 200px;
  height: 200px;
  position: relative;
  margin-bottom: 20px;
  &:nth-child(3n - 4) {
    margin: 0 30px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 156px;
    height: 156px;
    margin-bottom: 10px;
    margin-left: 10px;
    &:nth-child(3n - 4) {
      margin: 0 10px;
    }
  }
`

const ImagePlaceholder = styled(ListingImageContainer)`
  border-radius: 6px;
  border: 1px dashed #a5a5a5;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

const StyledIcon = styled(Icon)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 50px;
    height: 50px;
  }
`

const LimitDisclaimerWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  padding-bottom: 10px;
`

const LimitText = styled.p`
  font-size: 14px;
`

const indexedMap = addIndex(map)
const isFileOfType = type => file => includes(type, file.type?.toLowerCase())
const not = func => data => !func(data)

const sortInitialImages = images => {
  //newly added images that weren't sorted due to save (i.e user clsoed tab without saving) don't have a position
  //and will default to position 0, but with some wack tag. These haven't been sorted so just chuck them at the end since
  //they don't matter
  const [unsortedImages, sortedImages] = partition(
    images,
    image => toInteger(image.position) === 0 && image.tag !== 'cover_photo'
  )
  const sorted = pipe(
    map(
      evolve({
        position: toInteger
      })
    ),
    sortWith([ascend(prop('position'))])
  )(sortedImages)

  return concat(sorted, unsortedImages)
}

const ImageUpload = ({ unitId, onChange, images: listingImages }) => {
  const dispatch = useDispatch()
  const uploadImages = dispatch.listing.uploadImages
  const [images, setImages] = useState(sortInitialImages(listingImages) || [])
  const [t] = useTranslation()

  useCallOnChange(images, onChange)

  const handleSort = imgs => {
    const updatedImages = indexedMap(
      (img, index) => ({
        ...img,
        tag: index === 0 ? 'cover_photo' : `photo-${index}`
      }),
      imgs
    )

    setImages(updatedImages)
  }

  const onDrop = async files => {
    const filesAllowed = () => {
      if (images.length > 20) {
        return 0
      } else if (files.length + images.length > 20) {
        return 20 - images.length
      } else {
        return files.length
      }
    }

    const filesToUpload = files.slice(0, filesAllowed())

    if (filesToUpload.length !== 0) {
      const imageCount = filesToUpload.length
      const imagesText = t('l.listing.edit.number_of_images.text', {
        defaultValue: 'image',
        defaultValue_plural: 'images',
        count: imageCount
      })

      const newImages = await uploadImages({
        files: filesToUpload,
        unitId,
        imageCount: images.length
      })

      filesToUpload.length < files.length &&
        toast(
          <Trans i18nKey="l.listing.edit.image_upload_limit_exceeded.text">
            Uploaded only{' '}
            <strong>
              {{ imageCount }} {{ imagesText }}
            </strong>
            , since the maximum limit of 20 images has been reached.
          </Trans>,
          {
            title: t(
              'l.listing.edit.image_upload_limit_exceeded.title',
              'Uploaded Limit Exceeded'
            ),
            iconId: 'square_check',
            autoClose: 6000
          }
        )
      setImages([...images, ...newImages])
    } else if (images.length >= 20 && files.length > 0) {
      toast(
        <Trans i18nKey="l.listing.edit.image_upload_failed_max_limit.text">
          Oops! You have reached the maximum limit of <strong>20 photos</strong>
          . Remove some first to add more.
        </Trans>,
        {
          title: t(
            'l.listing.edit.image_upload_failed.title',
            'Photos Failed to Upload'
          ),
          iconId: 'error',
          autoClose: 6000
        }
      )
    }
  }

  const onDropRejected = files => {
    const invalidFiles = filter(
      not(
        anyPass([
          isFileOfType('jpg'),
          isFileOfType('png'),
          isFileOfType('jpeg')
        ])
      ),
      files
    )

    invalidFiles.length > 0 &&
      toast(
        t(
          'l.listing.edit.image_unsupported_format.text',
          'Oops! One or more of your files has an unsupported format. Please upload only jpg, png, or jpeg files.'
        ),
        {
          title: t(
            'l.listing.edit.image_unsupported_format.error.title',
            'File Format Unsupported'
          ),
          iconId: 'error',
          autoClose: 6000
        }
      )
  }

  const remove = async image => {
    let tasks = []
    if (image.tag === 'cover_photo' && images.length > 1) {
      const newCover = images[1]
      tasks = [
        updateFile(
          {
            body: {
              tag: 'cover_photo',
              reference_id: toInteger(unitId),
              reference_type: 'unit'
            }
          },
          { fileId: newCover.id }
        )
      ]
    }
    await Promise.all([deleteFile(undefined, { fileId: image.id }), ...tasks])
    const newImages = pipe(
      reject(propEq('id', image.id)),
      over(lensIndex(0), mergeLeft({ tag: 'cover_photo' }))
    )(images)
    setImages(newImages)
  }

  const setCover = async (image, oldList) => {
    const currentIndex = images.indexOf(image)
    const orderedImages = move(currentIndex, 0, images)
    handleSort(orderedImages)
  }

  const renderImages = clickProps => {
    const LastItem = () => {
      return (
        <ImagePlaceholder {...clickProps}>
          <StyledIcon id="camera_blue" width={73} height={73} />
          {t('b.listing.edit.image_upload_add_photos.button', 'Add Photos')}
        </ImagePlaceholder>
      )
    }

    const LimitDisclaimer = () => (
      <LimitDisclaimerWrapper>
        <LimitText>
          {t(
            'l.listing.edit.max_20_pictures.label',
            'Maximum of 20 pictures allowed.'
          )}
        </LimitText>
      </LimitDisclaimerWrapper>
    )

    const handleImageSort = (list, newIndex, oldIndex) => {
      if (newIndex === oldIndex) return
      handleSort(list)
    }

    return (
      <SortableList
        ItemComponent={ImageComponent}
        itemList={images}
        LastItem={LastItem}
        LimitDisclaimer={LimitDisclaimer}
        onChange={handleImageSort}
        itemProps={{ setCover: setCover, remove: remove }}
      />
    )
  }
  return (
    <UploadPanel
      accept="image/png,image/jpg,image/jpeg"
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      hasItems={!!images.length}
    >
      {clickProps => renderImages(clickProps)}
    </UploadPanel>
  )
}

export default React.memo(ImageUpload)
