import React from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'

const Bubble = styled.button`
  display: inline-block;
  max-width: ${props => (props.pinned || props.wide ? '250px' : '165px')};
  text-overflow: ellipsis;
  padding: 0px 13px;
  font-size: 14px;
  line-height: 19px;
  background-color: ${colors.white};
  border: 1px solid
    ${props => (props.pinned ? colors.secondary : colors.secondaryLightBlue)};
  color: ${props =>
    props.pinned ? colors.secondary : colors.secondaryLightBlue};
  height: 31px;
  text-align: center;
  border-radius: 15px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  cursor: pointer;

  ${props =>
    props.pinned &&
    `
    font-weight: ${fontWeights.medium};
  `}

  :active {
    background-color: ${props =>
      props.pinned ? colors.secondary : colors.secondaryLightBlue};
    color: ${colors.white};
  }
`

const PresetBubble = ({
  applyMessage,
  message,
  children,
  pinned,
  wide,
  ...rest
}) => (
  <Bubble
    onClick={() => applyMessage(message || children)}
    pinned={pinned}
    wide={wide}
    {...rest}
  >
    {message || children}
  </Bubble>
)

export default PresetBubble
