import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors, breakpoints } from 'styles'

import { Consumer } from './context'

const Button = styled.button`
  flex: 1;
  color: ${colors.regular};
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  height: 80px;
  border-bottom: 2px solid transparent;
  outline: none;
  letter-spacing: -0.48px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  background: none;

  &.active {
    border-bottom: 2px solid ${colors.pink};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
    line-height: 23px;
  }
`

class Tab extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  render() {
    const { id, disabled, children, className, ...rest } = this.props
    return (
      <Consumer>
        {({ changeTab, activeTabId }) => (
          <Button
            onClick={() => changeTab(id)}
            disabled={disabled}
            className={`${className} ${activeTabId === id ? 'active' : ''}`}
            {...rest}
          >
            {children}
          </Button>
        )}
      </Consumer>
    )
  }
}

export default styled(Tab)``
