import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import Anchor from './anchor'

const RoundedAnchor = styled(Anchor)`
  border-radius: 2px;
  font-weight: ${fontWeights.medium};
  background-color: ${colors.secondary};
  color: ${colors.white};
  width: 40%;
  display: inline-block;
  padding: 12px 0;
  transition: box-shadow 150ms ease-in-out, background-color 100ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 6px;
  outline: none;
  border: 1px solid ${colors.secondary};

  &:hover {
    background-color: ${colors.white};
    color: ${colors.secondary};
  }

  &:active {
    box-shadow: none;
  }
`

export default RoundedAnchor
