import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useBasic from 'hooks/use-basic'
import useMediaQuery from 'hooks/use-media-query'
import { select } from 'store'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import { headKey } from 'utils'
import { breakpoints, colors, fontWeights } from 'styles'
import Page from 'components/page'
import AreaTitle from 'components/area-title'
import BuildingSearch from 'components/building-search'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import { isEmpty, propEq } from 'ramda'
import { ImageButton, Button } from 'components/button'
import {
  Label,
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldSwitchToggle,
  FieldButtonPicker,
  Error
} from 'components/form'
import ContentWrapper from 'components/content-wrapper'
import { getFormattedStates } from 'utils/address'
import { showCloneModal } from './cloneModal'
import { showExistingListingModal } from './goToListingModal'

const Wrapper = styled(Page)`
  padding: 0 10px 130px;
`

const HelperText = styled.p`
  font-size: 11px;
  font-weight: ${fontWeights.regular};
  letter-spacing: -0.07px;
  line-height: 18px;
  margin: 0;
`

const StyledPanel = styled(Panel)`
  padding: 50px 20px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 0;
  }
`

const StyledFieldButtonPicker = styled(FieldButtonPicker)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const StyledImageButton = styled(ImageButton)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: flex-start !important;
    padding: 10px 10px 10px 25px;
    border-right: none;
    border-bottom: 1px solid ${colors.grey};

    :last-child {
      border: none;
    }
  }
`

const StyledRow = styled(Row)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const StyledGroup = styled(Group)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const ButtonPane = styled.section`
  text-align: center;
  margin-top: 60px;
`

const ToggleGroup = styled(StyledGroup)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: space-between;
  }
`

const ToggleLabel = styled(Label)`
  margin-right: 15px;
  margin-bottom: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 0 !important;
  }
`

const isDraft = propEq('state_machine', 'draft')

const CreateListing = ({ match: { params }, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.listingId) return
    dispatch.listing.load(params.listingId)
  }, [dispatch.listing, params.listingId])

  const listing = useSelector(state =>
    select.listing.editListing(state, {
      listingId: params.listingId
    })
  )

  useEffect(() => {
    if (!listing?.listing_id) return
    if (!isDraft(listing)) {
      history.replace(`/listing/edit/${listing.listing_id}`)
    }
  }, [history, listing])

  const registrationPlan = useSelector(select.session.registrationPlan)
  const { countries } = useBasic(['countries'])

  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  if (params.listingId && isEmpty(listing)) return null

  const initialValues = {
    type: listing?.unit_type_txt_id || '',
    groupListing: '0',
    scope: listing?.unit_type_scope_txt_id || '',
    suite: listing?.unit_number || '',
    building: listing?.building || '',
    address: listing?.building?.full_address || '',
    hide_unit_number: !!listing?.hide_unit_number,
    building_id: listing?.building_id || null,
    building_name: listing?.building?.name || '',
    street_number: listing?.building?.street_number || '',
    city: listing?.building?.city || '',
    province: listing?.building?.state || '',
    zip: listing?.building?.zip || '',
    country: 'ca'
  }

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    scope: Yup.string().when('groupListing', {
      is: '1',
      then: Yup.string(),
      otherwise: Yup.string().required()
    }),
    suite: Yup.string().when('type', {
      is: 'condo',
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    address: Yup.string().required(),
    building_name: Yup.string().when('type', {
      is: 'condo',
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    city: Yup.string().required(),
    province: Yup.string().required(),
    zip: Yup.string().required(),
    country: Yup.string().required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    if (!listing?.listing_id || listing.unit_number !== values.suite) {
      const {
        error: validationError,
        body: validationBody
      } = await dispatch.listing.verifyCreateListing({
        unit_number: values.suite,
        street_number: values.building.street_number,
        zip: values.zip
      })
      if (validationError) return setSubmitting(false)
      if (validationBody.listing_allowed_to_clone) {
        showCloneModal({
          listingId: validationBody.listing_id,
          values,
          history
        })
        return setSubmitting(false)
      }
      if (!validationBody.available_to_list) {
        showExistingListingModal({
          listingId: validationBody.listing_id,
          history
        })
        return setSubmitting(false)
      }
    }
    if (!values.building_id) {
      const { body, error } = await dispatch.building.create(values)
      if (error) return setSubmitting(false)
      values = { ...values, building_id: body.id }
    }
    const response = listing?.listing_id
      ? await dispatch.listing.updateUnit({
          from_building: listing.building_id,
          building_id: values.building_id,
          hide_unit_number: values.hide_unit_number,
          unit_number: values.suite,
          unit_id: listing.unit_id,
          listing_id: listing.listing_id
        })
      : await dispatch.listing.create(values)
    if (response.response.ok) {
      const listingId = headKey(response.body.listings)
      history.replace(`/listing/create/${listingId}`)
    } else {
      setSubmitting(false)
    }
  }

  const setBuildingValues = ({ setFieldValue, validateForm }) => building => {
    const {
      street_name: address,
      street_number,
      id: building_id,
      name: building_name,
      city,
      state: province,
      zip,
      ...rest
    } = building
    const values = {
      building,
      address,
      street_number,
      building_id,
      building_name,
      province: province || '',
      city: city || '',
      zip: zip || '',
      ...rest
    }
    Object.entries(values).forEach(([key, value]) => setFieldValue(key, value))
    validateForm(values)
  }

  const clearBuildingValues = ({ setFieldValue }) => {
    setFieldValue('building', '')
    setFieldValue('address', '')
    setFieldValue('suite', '')
    setFieldValue('street_number', '')
    setFieldValue('building_id', '')
    setFieldValue('building_name', '')
    setFieldValue('city', '')
    setFieldValue('province', '')
    setFieldValue('zip', '')
  }

  const onTypeChange = ({ values, setFieldValue }) => type => {
    if (type === values.type) return
    clearBuildingValues({ setFieldValue })
    setFieldValue('groupListing', '0')
    setFieldValue('scope', '')
  }

  const onGroupChange = ({ values, setFieldValue }) => groupListing => {
    if (groupListing === values.groupListing) return
    clearBuildingValues({ setFieldValue })
    setFieldValue('scope', '')
  }

  const onScopeChange = ({ values, setFieldValue }) => scope => {
    if (scope === values.scope) return
    clearBuildingValues({ setFieldValue })
  }

  const buildingTypeTitle = t(
    'l.listing.create.property_type.subtitle',
    'First select a property type'
  )

  const listingTypeTitle = t(
    'l.listing.create.listing_type.subtitle',
    'Select a listing type'
  )

  const scopeTypeTitle = t(
    'l.listing.edit.you_rent_out.subtitle',
    'Then select your rental space'
  )

  const addressTitle = t(
    'l.listing.create.address.subtitle',
    'Tell us where it is located'
  )

  const entireUnitText = {
    condo: t('l.listing.create.entire_apartment.label', 'Entire apartment'),
    house: t('l.listing.create.entire_house.label', 'Entire house'),
    townhouse: t('l.listing.create.entire_townhouse.label', 'Entire townhouse')
  }

  const partialUnitText = {
    condo: t('l.listing.create.partial_apartment.label', 'Partial apartment'),
    house: t('l.listing.create.partial_house.label', 'Partial house'),
    townhouse: t(
      'l.listing.create.partial_townhouse.label',
      'Partial townhouse'
    )
  }

  const propertyNameLabel = {
    condo: `${t('l.listing.create.building_name.label', 'Building name')}*`,
    house: t('l.listing.create.house_name.label', 'House name'),
    townhouse: t('l.listing.create.townhouse_name.label', 'Townhouse name')
  }

  return (
    <Wrapper sticky>
      <Helmet>
        <title>
          {t('l.listing.create.create_listing.title', 'Create a New Listing')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          setTouched,
          validateForm
        }) => {
          const isGroup = values.groupListing === '1'
          const isEnterprise = registrationPlan === 'enterprise'
          const isApartment = values.type === 'condo'
          const isHouse = values.type === 'house'
          return (
            <Form className="flex-full">
              <ContentWrapper>
                <PageTitle>
                  {t(
                    'l.listing.create.create_listing.title',
                    'Create a New Listing'
                  )}
                </PageTitle>
                <AreaTitle>{buildingTypeTitle}</AreaTitle>
                <StyledPanel>
                  <StyledFieldButtonPicker
                    name="type"
                    onChange={onTypeChange({ values, setFieldValue })}
                  >
                    <StyledImageButton
                      icon="building"
                      activeIcon="building_white"
                      size="large"
                      value="condo"
                    >
                      {t('common.apartment', 'Apartment')}
                    </StyledImageButton>
                    <StyledImageButton
                      icon="house"
                      activeIcon="house_white"
                      size="large"
                      value="house"
                    >
                      {t('common.house', 'House')}
                    </StyledImageButton>
                    <StyledImageButton
                      icon="townhouse"
                      activeIcon="townhouse_white"
                      size="large"
                      value="townhouse"
                    >
                      {t('common.townhouse', 'Townhouse')}
                    </StyledImageButton>
                  </StyledFieldButtonPicker>
                </StyledPanel>
                {isApartment && isEnterprise && (
                  <>
                    <AreaTitle>{listingTypeTitle}</AreaTitle>
                    <StyledPanel>
                      <StyledFieldButtonPicker
                        name="groupListing"
                        onChange={onGroupChange({ values, setFieldValue })}
                      >
                        <StyledImageButton
                          icon="building"
                          activeIcon="building_white"
                          size="large"
                          align="left"
                          value="0"
                        >
                          {t(
                            'l.listing.create.single_listing.label',
                            'Single listing'
                          )}
                        </StyledImageButton>
                        <StyledImageButton
                          icon="house"
                          activeIcon="house_white"
                          size="large"
                          align="left"
                          value="1"
                        >
                          <div>
                            {t(
                              'l.listing.create.group_listing.label',
                              'Multi-unit listing'
                            )}
                            <HelperText>
                              {t(
                                'l.listing.create.group_listing_helper.text',
                                'Multiple units in one rental building'
                              )}
                            </HelperText>
                          </div>
                        </StyledImageButton>
                      </StyledFieldButtonPicker>
                    </StyledPanel>
                  </>
                )}
                {!!values.type && (
                  <>
                    <AreaTitle>{scopeTypeTitle}</AreaTitle>
                    <StyledPanel>
                      <StyledFieldButtonPicker
                        name="scope"
                        onChange={onScopeChange({ values, setFieldValue })}
                      >
                        <StyledImageButton
                          icon="unit_entire"
                          activeIcon="unit_entire_white"
                          size="large"
                          value="entire"
                        >
                          {entireUnitText[values.type]}
                        </StyledImageButton>
                        <StyledImageButton
                          icon="unit_partial"
                          activeIcon="unit_partial_white"
                          size="large"
                          value="partial"
                        >
                          {partialUnitText[values.type]}
                        </StyledImageButton>
                        <StyledImageButton
                          icon="unit_room"
                          activeIcon="unit_room_white"
                          size="large"
                          value="single"
                        >
                          {t(
                            'l.listing.edit.single_room.label',
                            'A single room'
                          )}
                        </StyledImageButton>
                      </StyledFieldButtonPicker>
                    </StyledPanel>
                  </>
                )}
                {!!values.scope && (
                  <>
                    <AreaTitle>{addressTitle}</AreaTitle>
                    <Panel hSpace={displayMobile ? 25 : 50} vSpace={30}>
                      <StyledRow>
                        <StyledGroup col={12}>
                          <BuildingSearch
                            id="address"
                            onChange={setBuildingValues({
                              setFieldValue,
                              validateForm
                            })}
                            onBlur={() =>
                              setTouched({ address: true, ...touched })
                            }
                            value={values.building}
                            noOptionsMessage={() => null}
                            components={{ Option }}
                            buildingType={values.type}
                            countryCode={values.country}
                            placeholder={
                              t(
                                'l.listing.create.street_address.label',
                                'Street address'
                              ) + '*:'
                            }
                            hasError={!!errors['address'] && touched['address']}
                          />
                          <Error name="address" />
                        </StyledGroup>
                      </StyledRow>
                      {isGroup ? (
                        <StyledRow>
                          <StyledGroup col={6}>
                            <FieldInput
                              name="suite"
                              id="suite"
                              placeholder={
                                t(
                                  'l.listing.create.plan_name.label',
                                  'Plan name'
                                ) + ':*'
                              }
                            />
                          </StyledGroup>
                        </StyledRow>
                      ) : (
                        <>
                          <StyledRow>
                            <StyledGroup col={6}>
                              <FieldInput
                                name="suite"
                                id="suite"
                                placeholder={
                                  isApartment
                                    ? `${t(
                                        'l.listing.create.unit_number.label',
                                        'Unit number'
                                      )}:*`
                                    : t(
                                        'l.listing.create.unit_name_number.label',
                                        'Unit name / number'
                                      ) + ':'
                                }
                              />
                            </StyledGroup>
                          </StyledRow>
                          <StyledRow>
                            <ToggleGroup col={12}>
                              <ToggleLabel
                                htmlFor="hide_unit_number"
                                strong
                                id="unit-number-label"
                              >
                                {!isHouse
                                  ? t(
                                      'l.listing.create.hide_unit_number.label',
                                      'Hide unit number'
                                    )
                                  : t(
                                      'l.listing.create.hide_street_number.label',
                                      'Hide unit and street number'
                                    )}
                              </ToggleLabel>
                              <FieldSwitchToggle
                                name="hide_unit_number"
                                id="hide_unit_number"
                                aria-labelledby="unit-number-label"
                              />
                            </ToggleGroup>
                          </StyledRow>
                        </>
                      )}
                      <StyledRow>
                        <StyledGroup col={6}>
                          <FieldInput
                            name="building_name"
                            id="building_name"
                            disabled={values.building_id}
                            placeholder={propertyNameLabel[values.type] + ':'}
                          />
                        </StyledGroup>
                        <StyledGroup col={6}>
                          <FieldInput
                            name="city"
                            id="city"
                            disabled={values.building_id}
                            placeholder={
                              t('l.listing.create.city.label', 'City') + '*:'
                            }
                          />
                        </StyledGroup>
                      </StyledRow>
                      <StyledRow>
                        <StyledGroup col={6}>
                          <FieldDropdown
                            name="province"
                            id="province"
                            placeholder={
                              t('l.listing.create.province.label', 'Province') +
                              '*:'
                            }
                            options={getFormattedStates(countries, 'ca')}
                            isDisabled={values.building_id}
                            isSearchable={false}
                          />
                        </StyledGroup>
                        <StyledGroup col={6}>
                          <FieldInput
                            name="zip"
                            id="zip"
                            disabled={values.building_id}
                            placeholder={
                              t(
                                'l.listing.create.postal_code.label',
                                'Postal code'
                              ) + '*:'
                            }
                          />
                        </StyledGroup>
                      </StyledRow>
                      <StyledRow>
                        <StyledGroup col={6}>
                          <FieldDropdown
                            name="country"
                            id="country"
                            placeholder={
                              t('l.listing.create.country.label', 'Country') +
                              ':'
                            }
                            options={[{ value: 'ca', label: 'Canada' }]}
                            isDisabled
                            defaultValue
                          />
                        </StyledGroup>
                      </StyledRow>
                    </Panel>
                    <ButtonPane>
                      <Button
                        type="submit"
                        theme="primary"
                        width={270}
                        disabled={isSubmitting}
                      >
                        {t('l.listing.create.next.button', 'Next')}
                      </Button>
                    </ButtonPane>
                  </>
                )}
              </ContentWrapper>
            </Form>
          )
        }}
      </Formik>
    </Wrapper>
  )
}

export default CreateListing
