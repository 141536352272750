import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import fileAsDataUrl from 'utils/file-as-data-url'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Formik, Form } from 'formik'
import UploadButton from 'components/upload-button'
import { Label, FieldDropdown } from 'components/form'
import { Button } from 'components/button'
import useWindowDimensions from 'hooks/use-window-dimensions'
import VerifyId from './verify-id.svg'
import { showDocument } from './view-verification-document-modal'
import { ShowWebcam } from './webcam-modal'

const IdCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: 0 auto;
`

const OuterWrapper = styled(IdCardWrapper)`
  margin: auto;
  overflow: auto;
  > form:last-of-type {
    margin-bottom: 35px;
  }
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const Img = styled.img`
  height: 200px;
  width: 300px;
`

const StepText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0 15px;
  font-size: ${fontSizes.regular};
`

const DropDownContainer = styled.div`
  margin: 20px 30px 25px;
  align-items: center;
`

const UploadContainer = styled.div`
  margin: 10px 30px;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const UploadButtonStyled = styled(UploadButton)`
  background: none;
  border: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  padding: 0;
  width: auto;

  &:hover {
    border: none;
    color: ${colors.link};
  }

  &:disabled {
    color: #494949;
  }
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
  margin: 0 auto;
  width: 100%;
`

const OpenModalButton = styled(Button)`
  background: none;
  border: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  padding: 0px;
  width: auto;

  &:hover {
    background: none;
    border: none;
    color: ${colors.link};
  }
`

const Disclaimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 30px 22px;
  font-size: 14px;
`
const ButtonPane = styled.div`
  padding: 0 30px;
`

const VerifyModal = () => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const [verificationSelfie, setVerificationSelfie] = useState(null)
  const [verificationDoc, setVerificationDoc] = useState(null)
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [container, setContainer] = useState()
  useEffect(() => {
    setContainer(
      typeof document !== 'undefined'
        ? document.getElementById('modal-container')
        : undefined
    )
  }, [])

  const formOptions = () => ({
    initialValues: {
      document_type: ''
    },

    onSubmit: async values => {
      dispatch.profile.uploadFile({
        docFile: verificationDoc,
        identification_type: values.document_type,
        selfieFile: verificationSelfie
      })
      close()
    }
  })

  const handleFileSelection = values => async files => {
    if (files[0]) {
      const doc = await fileAsDataUrl(files[0])
      setVerificationDoc(doc)
    }
  }

  const handleDeleteDoc = () => {
    setVerificationDoc(null)
  }

  const handleDeleteSelfie = () => {
    setVerificationSelfie(null)
  }

  // Oddly specific workaround for react-select (https://github.com/JedWatson/react-select/issues/3533)
  // to ensure selector updates with zoom / window resize
  const dimensions = useWindowDimensions(300)
  return (
    <OuterWrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>
          {t('b.profile.edit.verfy_your_id.label', 'Verify your ID')}
        </Title>
      </Header>
      <IdCardWrapper>
        <Img src={VerifyId} />
      </IdCardWrapper>
      <StepText>
        {t('b.profile.edit.step1', 'Step 1: Provide your photo ID')}
      </StepText>
      <Formik {...formOptions()} padding="15px">
        {({ values }) => (
          <Form>
            <DropDownContainer>
              <FieldDropdown
                id="document_type"
                name="document_type"
                menuPosition="fixed"
                placeholder={t(
                  'b.profile.edit.document_type.label',
                  'Type of ID'
                )}
                menuPortalTarget={container}
                menuPlacement={
                  displayMobile || dimensions?.height < 745 ? 'top' : 'bottom'
                }
                isSearchable={!displayMobile}
                options={[
                  {
                    value: 'drivers_license',
                    label: t(
                      'b.profile.edit.drivers_license.text',
                      "Driver's Licence"
                    )
                  },
                  {
                    value: 'passport',
                    label: t('b.profile.edit.passport.text', 'Passport')
                  },
                  {
                    value: 'health_insurance',
                    label: t(
                      'b.profile.edit.health_insurance_card.text',
                      'Health Insurance Card'
                    )
                  },
                  {
                    value: 'permanent_resident',
                    label: t(
                      'b.profile.edit.residence_card.text',
                      'Permanent Residence Card'
                    )
                  }
                ]}
              />
            </DropDownContainer>
            {values.document_type && (
              <UploadContainer data-cy="upload_photo_id">
                <Label bottom="0">
                  {t('b.profile.edit.picture_of_id', 'Picture of ID')}
                </Label>
                {verificationDoc ? (
                  <OpenModalButton
                    onClick={() =>
                      showDocument({
                        src: verificationDoc,
                        handleDelete: handleDeleteDoc
                      })
                    }
                  >
                    {t('b.profile.edit.view.button', 'View')}
                  </OpenModalButton>
                ) : (
                  <UploadButtonStyled
                    name="license_pill"
                    width={125}
                    left="auto"
                    disabled={
                      !values.document_type || values.document_type === ''
                    }
                    onDropAccepted={handleFileSelection(values)}
                  />
                )}
              </UploadContainer>
            )}
            {values.document_type && verificationDoc && (
              <>
                <StepText>
                  {t(
                    'b.profile.edit.step2',
                    'Step 2: Upload a picture of yourself.'
                  )}
                </StepText>
                <UploadContainer>
                  <Label bottom="0">
                    {' '}
                    {t('b.profile.edit.picture_of_you', 'Picture of you')}
                  </Label>
                  {verificationSelfie ? (
                    <OpenModalButton
                      onClick={() =>
                        showDocument({
                          src: verificationSelfie,
                          handleDelete: handleDeleteSelfie
                        })
                      }
                    >
                      {t('b.profile.edit.view.button', 'View')}
                    </OpenModalButton>
                  ) : (
                    <OpenModalButton
                      width={125}
                      onClick={() => ShowWebcam({ setVerificationSelfie })}
                    >
                      {t('b.profile.edit.enable_camera', 'Enable Camera')}
                    </OpenModalButton>
                  )}
                </UploadContainer>
                <Disclaimer>
                  {t(
                    'b.profile.edit.webcam_disclaimer',
                    '** If you cannot take a picture, please log in on a cell phone and verify your ID with the phone camera.'
                  )}
                </Disclaimer>
                <ButtonPane>
                  <ActionButton
                    name="license_pill"
                    theme="primary"
                    width={125}
                    left="auto"
                    type="submit"
                    disabled={!verificationDoc || !verificationSelfie}
                  >
                    {t('b.profile.edit.verify.button', 'Verify')}
                  </ActionButton>
                </ButtonPane>
              </>
            )}
          </Form>
        )}
      </Formik>
    </OuterWrapper>
  )
}

export const showVerifyModal = createModal(VerifyModal)
