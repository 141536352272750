import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router'
import AuthenticatedView from 'authenticated-view'
import EditSettings from './EditSettings'
import BillingAddress from './BillingAddress'
import PaymentSettings from './PaymentSettings'
import ChangePhoneNumber from './ChangePhoneNumber'
import DeleteAccount from './DeleteAccount'
import Feedback from './Feedback'

const Settings = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('b.settings.view.settings.title', 'Settings')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Switch>
        <AuthenticatedView
          exact
          path="/settings/billing-address"
          component={BillingAddress}
          allowTo={['tenant']}
        />
        <AuthenticatedView
          path="/settings/payment-settings"
          component={PaymentSettings}
          allowTo={['landlord']}
        />
        <Route
          exact
          path="/settings/change-phone-number"
          component={ChangePhoneNumber}
        />
        <Route exact path="/settings/feedback" component={Feedback} />
        <AuthenticatedView
          exact
          path="/settings/delete-account"
          component={DeleteAccount}
        />
        <Route path="/settings" component={EditSettings} />
      </Switch>
    </>
  )
}

export default React.memo(Settings)
