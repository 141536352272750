import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { select } from 'store'
import { intersection, path } from 'ramda'
import useRole from 'hooks/use-role'
import { ChatProvider } from 'features/Chat/chat-side-bar/chat-context'
import { useTranslation } from 'react-i18next'
import ChatSideBar from 'features/Chat/chat-side-bar'

const SideBar = React.memo(({ match, closeNav, isOpen }) => {
  const { t } = useTranslation()
  const [initialTab, setInitialTab] = useState('tenants')
  const getTimelines = useDispatch().chat.getTimelines
  const getNextTimelines = useDispatch().chat.getNextTimelines
  const timelines = useSelector(select.chat.timelines)
  const timelineRoom = useSelector(
    path(['chat', 'timelines', match.params.timelineId])
  )
  const isLandlord = useRole() === 'landlord'
  const getPath = timelineId => `/chat/${timelineId}`

  useEffect(() => {
    getTimelines()
  }, [getTimelines])

  const isRented = timeline => {
    const listings = timeline?.listings
    return (
      (listings?.state_machine === 'rented' ||
        listings?.state_machine === 'upcoming' ||
        listings?.state_machine === 'history') &&
      intersection(listings?.listing_tenants, timeline?.user_ids.them).length >
        0
    )
  }

  const tabs = useMemo(() => {
    const sortedTimelines = timelines.reduce(
      (accum, curr) => {
        const rented = isRented(curr)
        return rented && isLandlord
          ? {
              ...accum,
              rented: [...accum.rented, { ...curr, isRented: true }]
            }
          : {
              ...accum,
              inquires: [...accum.inquires, curr]
            }
      },
      { rented: [], inquires: [] }
    )

    return isLandlord
      ? [
          {
            id: 'tenants',
            title: t('l.chat.view.rented_tab.title', 'Rented'),
            unread: 'rented_count',
            data: sortedTimelines.rented
          },
          {
            id: 'inquiries',
            title: t('l.chat.view.inquiries_tab.title', 'Inquiries'),
            unread: 'inquiry_count',
            data: sortedTimelines.inquires,
            support: true
          }
        ]
      : [
          {
            id: 'inquiries',
            title: t('l.chat.view.inquiries_tab.title', 'Inquiries'),
            unread: 'unread_count',
            data: timelines,
            support: true
          }
        ]
  }, [isLandlord, t, timelines])

  useEffect(() => {
    const isInquiry = () => {
      return !isRented(timelineRoom)
    }
    if (
      match.params.timelineId === 'support-room' ||
      !isLandlord ||
      !tabs[0].data.length ||
      (isLandlord && isInquiry())
    ) {
      setInitialTab('inquiries')
    } else {
      setInitialTab('tenants')
    }
  }, [isLandlord, timelineRoom, match.params.timelineId, tabs])

  return (
    <ChatProvider value={getPath}>
      <ChatSideBar
        isOpen={isOpen}
        closeNav={closeNav}
        initialTab={initialTab}
        onEndReached={getNextTimelines}
        tabs={tabs}
      />
    </ChatProvider>
  )
})

export default withRouter(SideBar)
