/* eslint-disable max-params */
import { createSelector } from 'reselect'
import {
  __,
  values,
  path,
  prop,
  flip,
  assoc,
  propEq,
  identity,
  pick,
  defaultTo,
  pipe,
  filter,
  map,
  find,
  ifElse,
  always,
  evolve,
  mergeLeft
} from 'ramda'
import { mergeSpec, headObj, wrapArray, isTruthy } from 'utils'

const selectApplicationSelector = createSelector(
  flip(prop('applicationId')),
  identity,
  prop
)

const selectApplicationUserInfoSelector = createSelector(
  selectApplicationSelector,
  prop('user_info')
)

const petsSelector = createSelector(
  selectApplicationUserInfoSelector,
  (application = {}) =>
    pipe(
      prop('user_pets'),
      values,
      map(
        mergeSpec({
          avatar: pipe(
            prop('id'),
            prop(__, application?.files?.user_pet),
            headObj
          )
        })
      )
    )(application)
)

const contactSelector = type =>
  createSelector(selectApplicationUserInfoSelector, (application = {}) =>
    pipe(
      prop('user_contacts'),
      values,
      filter(propEq('contact_type', type))
    )(application)
  )

const employmentReferencesSelector = createSelector(
  selectApplicationUserInfoSelector,
  pipe(prop('user_employers'), values)
)

const roommatesSelector = createSelector(
  selectApplicationUserInfoSelector,
  pipe(prop('user_roommates'), values)
)

const insuranceSelector = createSelector(
  selectApplicationUserInfoSelector,
  pipe(
    path(['files', 'user_secure_files']),
    values,
    defaultTo([]),
    find(propEq('tag', 'insurance_info'))
  )
)

const vehiclesSelector = createSelector(
  selectApplicationUserInfoSelector,
  pipe(prop('user_vehicles'), values)
)

const userLiveDataSelector = createSelector(
  selectApplicationSelector,
  prop('users'),
  (application, users) => prop(application?.user_id, users)
)

export const applicationSelector = createSelector(
  selectApplicationSelector,
  petsSelector,
  contactSelector('landlord'),
  contactSelector('emergency'),
  employmentReferencesSelector,
  roommatesSelector,
  insuranceSelector,
  vehiclesSelector,
  userLiveDataSelector,
  (
    application,
    pets,
    landlords,
    emergency,
    employers,
    roommates,
    insurance,
    vehicles,
    userLiveData = {}
  ) =>
    pipe(
      assoc('user_pets', pets),
      assoc('landlord_references', landlords),
      assoc('emergency_contacts', emergency),
      assoc('user_employers', employers),
      assoc('user_roommates', roommates),
      assoc('insurance', insurance),
      assoc('user_vehicles', vehicles),
      evolve({
        user_info: {
          avatar: mergeLeft(userLiveData.avatar || {})
        }
      }),
      evolve({
        user_info: mergeSpec({
          income_verification: pipe(
            path(['files', 'user_secure_files']),
            defaultTo({}),
            values,
            find(propEq('tag', 'income_verification')),
            defaultTo('')
          ),
          employment_verification: pipe(
            path(['files', 'user_secure_files']),
            defaultTo({}),
            values,
            find(propEq('tag', 'employment_verification')),
            defaultTo('')
          ),
          school_verification: pipe(
            path(['files', 'user_secure_files']),
            defaultTo({}),
            values,
            find(propEq('tag', 'school_verification')),
            defaultTo('')
          )
        })
      })
    )(application)
)

export const contractDataSelector = createSelector(
  selectApplicationSelector,
  pipe(
    mergeSpec({
      contract_signing_tenants: pipe(
        prop('user_info'),
        ifElse(
          isTruthy,
          pipe(
            pick([
              'id',
              'avatar',
              'first_name',
              'last_name',
              'identity_verified_state'
            ]),
            wrapArray
          ),
          always([])
        )
      )
    }),
    pick(['contract_signing_tenants', 'extra_offer', 'extra_offer_frequency'])
  )
)
