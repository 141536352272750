import {
  __,
  always,
  assoc,
  curry,
  defaultTo,
  evolve,
  ifElse,
  lensPath,
  map,
  mergeDeepLeft,
  mergeRight,
  omit,
  path,
  pick,
  pipe,
  prop,
  reduce,
  reject,
  remove,
  propEq,
  set,
  toPairs,
  values,
  dissocPath,
  concat
} from 'ramda'
import selectorBuilder from 'models/selector-builder'
import { toast } from 'components/toast-notifications'
import { batch } from 'react-redux'
import { toInteger, toNumber } from 'lodash'
import toBoolean from 'utils/to-boolean'
import { defaultIfFalsy, callOr, renameKeys, mergeSpec, headObj } from 'utils'
import moment from 'moment'
import { readAndCompressImage } from 'browser-image-resizer'
import { uploadFile, deleteFile } from 'ports'
import {
  createListing,
  updateListing,
  updateListingLandlords,
  updateUnit,
  deleteListing,
  getListing,
  getListingTimelines,
  getSuggestedTenants,
  landlordSendFirstMessage,
  removeSuggestedTenant,
  shareEmail,
  shareText,
  getListingApplications,
  getContract,
  generateDeeplink,
  getListingSummary,
  sortImages,
  verifyCreateListing,
  cloneListing,
  getListingAppointments,
  hideAppointment,
  unhideAppointment,
  createAppointment,
  delayAppointment,
  deleteAppointment,
  bookShowing,
  cancelShowing,
  requestListingCode,
  verifyListingCode,
  uploadListingVerification
} from './ports'
import {
  editListingSelector,
  listingSelector,
  applicationsSelector,
  appointmentsSelector,
  attendeesAvatarSelector,
  slotsSelector,
  applicantsSelector,
  contractDataSelector,
  listingLandlordsSelector,
  suggestedTenantsSelector
} from './selectors'

const formatDate = curry((format, date) => moment(date).format(format))
const defaultToNull = defaultTo(null)

const parseFeeName = ifElse(
  prop('enabled'),
  pipe(prop('name'), defaultToNull),
  always(null)
)

const parseFeeFrequency = ifElse(
  prop('enabled'),
  ifElse(
    prop('hasFee'),
    pipe(prop('fee_frequency_txt_id'), defaultIfFalsy('free')),
    always('free')
  ),
  always('not_available')
)

const parseFees = pipe(
  pick(['storage', 'parking']),
  toPairs,
  reduce(
    (acc, [key, value]) => [
      ...acc,
      {
        fee_txt_id: key,
        name: parseFeeName(value),
        fee_frequency_txt_id: parseFeeFrequency(value),
        fee:
          value.hasFee && value.enabled
            ? callOr(toNumber, null)(value.fee)
            : null
      }
    ],
    []
  )
)

const LISTING_INFO_FIELDS = [
  'furnished',
  'listing_utilities',
  'custom_utilities',
  'custom_features',
  'listing_id',
  'lease_type',
  'price',
  'max_occupancy',
  'availability_date',
  'price_frequency',
  'allow_pets',
  'allow_cats',
  'allow_dogs',
  'allow_smoking',
  'storage',
  'parking',
  'state_machine',
  'description'
]

const RESIZE_CONFIG = {
  quality: 1,
  maxWidth: 1600,
  maxHeight: 1600
}

const minLeaseNumber = (leaseLength, minLeaseValue) => {
  const LeaseNumOptions = {
    no_minimum: null,
    one_month: 1,
    six_months: 6,
    one_year: 1
  }
  if (leaseLength === 'other') {
    return toInteger(minLeaseValue)
  }
  return LeaseNumOptions[leaseLength] || null
}

const minLeaseType = (leaseLength, leaseType) => {
  const LeaseTypeOptions = {
    no_minimum: 'no_minimum',
    one_month: 'months',
    six_months: 'months',
    one_year: 'years'
  }
  if (leaseLength === 'other') {
    return leaseType
  }
  return LeaseTypeOptions[leaseLength] || null
}

const listing = {
  state: {},
  reducers: {
    clear: () => ({}),
    error: (state, payload) => ({ ...state, error: payload }),
    reset: () => ({}),
    updateAt: (state, at, payload) => set(lensPath(at), payload, state),
    patch: (state, at, payload) =>
      set(
        lensPath(at),
        pipe(path(at), defaultTo({}), mergeDeepLeft(payload))(state),
        state
      ),
    concat: (state, at, payload) =>
      set(
        lensPath(at),
        pipe(path(at), defaultTo([]), concat(__, payload))(state),
        state
      ),
    reject: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), remove(payload), values)(state), state),
    setValue: (state, at, payload) => set(lensPath(at), payload, state),
    merge: mergeRight,
    remove: (state, at) => dissocPath(at, state)
  },

  effects: dispatch => ({
    create: async (payload, state) => {
      const params = {
        buildingId: payload.building_id
      }
      const primaryUserId = toInteger(state.session.session.id)
      const data = {
        body: {
          unit_number: defaultIfFalsy(null)(payload.suite),
          building_id: toInteger(payload.building_id),
          status: 1,
          gr_unit: toInteger(payload.groupListing),
          unit_type_txt_id: payload.type,
          unit_type_scope_txt_id: payload.scope,
          hide_unit_number: payload.hide_unit_number ? 1 : 0,
          listing_info: {
            status: 1,
            is_hidden: 0,
            primary_user_id: primaryUserId,
            listing_landlords: [primaryUserId]
          }
        }
      }
      return await createListing(data, params)
    },
    update: async ({ listing_id, ...payload }) => {
      const params = {
        listingId: listing_id
      }
      const data = {
        body: payload
      }
      const response = await updateListing(data, params)

      if (response.response.ok) {
        batch(() => {
          dispatch.listing.patch(
            [listing_id, 'listings'],
            response.body.listings[listing_id]
          )

          map(id =>
            dispatch.listing.patch(
              [listing_id, 'users', id],
              response.body.users[id]
            )
          )(response.body.listings[listing_id]?.listing_landlords)
        })
      }
      return response
    },
    updateLandlords: async ({ listing_id, ...payload }) => {
      const params = {
        listingId: listing_id
      }
      const data = {
        body: payload
      }
      const { response, body } = await updateListingLandlords(data, params)

      if (response.ok) {
        const updatedListing = body.listings[listing_id]
        batch(() => {
          dispatch.listing.patch([listing_id], {
            listings: updatedListing,
            users: pick(payload.listing_landlords, body.users)
          })
          dispatch.companyManagement.updateAt(
            ['listings', listing_id],
            updatedListing
          )
          dispatch.companyManagement.patch(
            ['users'],
            pick(payload.listing_landlords, body.users)
          )
        })
      }

      return response
    },
    updateUnit: async ({ from_building, ...payload }) => {
      const params = {
        buildingId: from_building || payload.building_id,
        unitId: payload.unit_id
      }

      const listingInfo = pipe(
        pick(LISTING_INFO_FIELDS),
        renameKeys({
          listing_id: 'id'
        }),
        mergeSpec({
          allow_pets: pipe(prop('allow_pets'), toInteger),
          listing_fees: parseFees,
          min_lease_period: () =>
            minLeaseNumber(payload.lease_length, payload.min_lease_period),
          min_lease_period_type: () =>
            minLeaseType(payload.lease_length, payload.min_lease_period_type)
        }),
        evolve({
          id: toInteger,
          furnished: callOr(toInteger, null),
          lease_type: defaultIfFalsy(undefined),
          description: defaultIfFalsy(null),
          price: callOr(toNumber, undefined),
          price_frequency: defaultIfFalsy(undefined),
          max_occupancy: callOr(toInteger, undefined),
          availability_date: callOr(formatDate('YYYY-MM-DD'), undefined),
          is_hidden: callOr(toInteger, undefined),
          allow_smoking: toInteger,
          allow_cats: callOr(toInteger, null),
          allow_dogs: callOr(toInteger, null)
        }),
        omit(['parking', 'storage'])
      )(payload)

      const data = {
        body: pipe(
          assoc('listing_info', listingInfo),
          evolve({
            count_bedrooms: callOr(toInteger, null),
            count_full_bathrooms: callOr(toNumber, null),
            count_full_shared_bathrooms: callOr(toNumber, null),
            building_id: toInteger,
            count_dens: callOr(toInteger, null),
            size: callOr(toInteger, undefined),
            gr_count: callOr(toInteger, null),
            gr_floors: defaultIfFalsy(null),
            gr_min_size: callOr(toInteger, undefined),
            gr_max_size: callOr(toInteger, undefined),
            gr_min_price: callOr(toNumber, undefined),
            gr_max_price: callOr(toNumber, undefined),
            unit_number: defaultIfFalsy(null),
            hide_unit_number: toInteger,
            private_entrance: callOr(toBoolean, false)
          })
        )(
          pick(
            [
              'unit_number',
              'count_dens',
              'unit_features',
              'size',
              'gr_min_size',
              'gr_max_size',
              'gr_min_price',
              'gr_max_price',
              'gr_count',
              'gr_floors',
              'building_id',
              'count_bedrooms',
              'count_full_bathrooms',
              'count_full_shared_bathrooms',
              'hide_unit_number',
              'private_entrance'
            ],
            payload
          )
        )
      }
      const response = await updateUnit(data, params)
      if (response.response.ok) {
        dispatch.listing.remove([listingInfo.id])
      }
      return response
    },
    delete: async listingId => {
      try {
        const response = await deleteListing(undefined, { listingId })
        if (!response.response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        return response
      } catch (error) {
        return error
      }
    },
    load: async (listingId, rootState) => {
      if (rootState.listing[listingId]?.listings) return {}
      try {
        const { response, body } = await getListing({ listingId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch(
          [body.listings.id],
          omit(['listings_appointments'], body)
        )
        return body
      } catch (error) {
        return error
      }
    },
    getContract: async (listingId, rootState) => {
      if (path(['listing', listingId, 'contract'], rootState)) return
      try {
        const { response, body } = await getContract({ listingId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch([listingId], {
          contract: body
        })
      } catch (error) {
        return error
      }
    },
    getApplications: async listingId => {
      try {
        const { response, body } = await getListingApplications({ listingId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.merge({ listing_applications: body })
      } catch (error) {
        return error
      }
    },
    getAppointments: async listingId => {
      try {
        const { response, body } = await getListingAppointments({ listingId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch([listingId], {
          listing_appointments: body.listing_appointments,
          attending_to: body.attending_to || {},
          files: body.files || {},
          users: body.users || {}
        })
      } catch (error) {
        return error
      }
    },
    async hideAppointment(appointmentId) {
      try {
        const { response, body } = await hideAppointment(undefined, {
          appointmentId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch(
          [body.listing_id, 'listing_appointments', appointmentId],
          body
        )
        return response
      } catch (error) {
        return error
      }
    },
    async unhideAppointment(appointmentId) {
      try {
        const { response, body } = await unhideAppointment(undefined, {
          appointmentId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch(
          [body.listing_id, 'listing_appointments', appointmentId],
          body
        )
        return response
      } catch (error) {
        return error
      }
    },
    async createAppointment(payload) {
      try {
        const { response, body } = await createAppointment(
          { body: payload.body },
          {
            listingId: payload.listingId
          }
        )
        if (response.ok) {
          dispatch.listing.patch(
            [payload.listingId, 'listing_appointments'],
            body[payload.listingId]
          )
          return { response, body }
        }
      } catch (error) {
        return error
      }
    },
    async deleteAppointment(payload) {
      try {
        const { response } = await deleteAppointment(undefined, {
          appointmentId: payload.appointmentId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.listing.remove([
          payload.listingId,
          'listing_appointments',
          payload.appointmentId
        ])
        return response
      } catch (error) {
        return error
      }
    },
    async delayAppointment(payload) {
      try {
        const { response, body } = await delayAppointment(undefined, {
          appointmentId: payload.appointmentId,
          duration: payload.duration
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.patch(
          [body.listing_id, 'listing_appointments', payload.appointmentId],
          body
        )
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async bookShowing(payload) {
      try {
        const { response, body } = await bookShowing(undefined, {
          timeslotId: payload.timeslotId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.concat(
          [payload.listingId, 'attending_to', payload.listingId],
          [payload.timeslotId]
        )
        return response
      } catch (error) {
        return { error }
      }
    },
    async cancelShowing(payload) {
      try {
        const { response, body } = await cancelShowing(undefined, {
          timeslotId: payload.timeslotId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.listing.reject(
          [payload.listingId, 'attending_to'],
          payload.timeslotId
        )
        return response
      } catch (error) {
        return { error }
      }
    },
    async getListingTimelines(listingId, rootState) {
      try {
        const { response, body } = await getListingTimelines({ listingId })

        if (response.ok) {
          dispatch.chat.saveTimelines(body)
        }
      } catch (error) {
        console.error('[listing/getListingTimelines]', error)
      }
    },
    async verifyCreateListing(payload) {
      try {
        const { response, body } = await verifyCreateListing({
          body: payload
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async cloneListing(listingId) {
      try {
        const { response, body } = await cloneListing(undefined, { listingId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async sendEmailInvite(payload) {
      try {
        const response = await shareEmail(
          {
            body: {
              email: payload.email
            }
          },
          { listingId: payload.listingId }
        )
        if (response.response.ok) {
          console.log('[listing/sendEmailInvite] Successful')
          toast(`Invite successfully sent to ${payload.email}.`, {
            title: 'Email Invite Sent!',
            iconId: 'square_check',
            autoClose: 6000
          })
        }
      } catch (error) {
        console.error('[listing/sendEmailInvite] Error Occured: ', error)
      }
    },
    async sendTextInvite(payload) {
      try {
        const response = await shareText(undefined, {
          listingId: payload.listingId,
          phoneNumber: payload.phoneNumber
        })
        if (response.response.ok) {
          console.log('[listing/sendTextInvite] Successful')
          toast(`Invite successfully sent to ${payload.phoneNumber}.`, {
            title: 'Phone Invite Sent!',
            iconId: 'square_check',
            autoClose: 6000
          })
        }
      } catch (error) {
        console.error('[listing/sendTextInvite] Error Occured: ', error)
      }
    },
    async uploadImages(payload) {
      const { files, unitId, imageCount } = payload
      let responses = []
      try {
        dispatch.loading.setLoading()
        for (const [i, file] of files.entries()) {
          const resizedImage = await readAndCompressImage(file, RESIZE_CONFIG)
          const { response, body } = await uploadFile({
            resizedImage,
            body: {
              reference_type: 'unit',
              reference_id: toInteger(unitId),
              tag:
                !imageCount && i === 0
                  ? 'cover_photo'
                  : `photo-${imageCount + 1}`
            }
          })
          if (response.ok) responses.push(pipe(headObj, headObj)(body.unit))
        }
      } catch (error) {
        console.error('[listing/uploadImages] Error Occured: ', error)
      } finally {
        dispatch.loading.stopLoading()
        return responses
      }
    },
    async sortImages(payload) {
      const filteredPayload = map(
        pipe(
          pick(['id', 'reference_type', 'reference_id', 'tag']),

          evolve({
            id: toInteger,
            reference_id: toInteger
          })
        ),
        payload
      )
      try {
        const { response, body } = await sortImages({
          body: filteredPayload
        })

        if (response.ok) {
          return { response, body }
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async generateDeeplink({ listing_id }) {
      dispatch.loading.setLoading()
      dispatch.error.clearError()
      try {
        const { body, response } = await generateDeeplink({
          listingId: listing_id
        })

        if (!response.ok) {
          throw body.message
        }

        return body.message
      } catch (error) {
        dispatch.error.setError(error)
        return null
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async getListingSummary(listingId) {
      try {
        const { response, body } = await getListingSummary({ listingId })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { body }
      } catch (error) {
        return { error }
      }
    },
    async getSuggestedTenants(payload) {
      const { listingId, queries } = payload

      try {
        const { response, body } = await getSuggestedTenants(
          { listingId },
          queries
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        if (body.listing_matches) {
          batch(() => {
            dispatch.listing.patch([listingId, 'users'], body.users)
            if (payload.reset) {
              dispatch.listing.updateAt(
                [listingId, 'listing_matches'],
                body.listing_matches
              )
            } else {
              dispatch.listing.concat(
                [listingId, 'listing_matches'],
                body.listing_matches
              )
            }
          })
        }
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { body, response }
      } catch (error) {
        return { error }
      }
    },
    async landlordSendFirstMessage(payload, rootState) {
      const { listingId, tenantId, message } = payload
      try {
        const { response, body } = await landlordSendFirstMessage(
          {
            body: {
              message
            }
          },
          {
            listingId,
            tenantId
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        const updatedListingMatches = pipe(
          prop('listing_matches'),
          reject(propEq('user_id', tenantId))
        )(rootState.listing[listingId])

        dispatch.listing.updateAt(
          [listingId, 'listing_matches'],
          updatedListingMatches
        )
      } catch (error) {
        return { error }
      }
    },
    async removeSuggestedTenant(payload, rootState) {
      const { listingId, userId } = payload
      try {
        const { response, body } = await removeSuggestedTenant(undefined, {
          listingId,
          userId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        const updatedListingMatches = pipe(
          prop('listing_matches'),
          reject(propEq('user_id', userId))
        )(rootState.listing[listingId])

        dispatch.listing.updateAt(
          [listingId, 'listing_matches'],
          updatedListingMatches
        )
      } catch (error) {
        return { error }
      }
    },
    async deleteUnitFile(payload, rootState) {
      const { listingId, unitId, fileId } = payload
      try {
        const { response, body } = await deleteFile(undefined, { fileId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        const updatedFiles = omit(
          [fileId],
          rootState.listing[listingId].files.unit[unitId]
        )

        dispatch.listing.updateAt(
          [listingId, 'files', 'unit', unitId],
          updatedFiles
        )
      } catch (error) {
        return { error }
      }
    },
    async requestVerifyCode(payload) {
      const { listingId } = payload
      try {
        const { response, body } = await requestListingCode({
          body: { listing_id: listingId }
        })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        dispatch.listing.patch(
          [listingId, 'listings'],
          body.listings[listingId]
        )
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async submitVerifyCode(payload) {
      const { listingId, code } = payload
      try {
        const { response, body } = await verifyListingCode({
          body: {
            listing_id: listingId,
            code
          }
        })

        if (!response.ok) {
          return { response, body }
        }

        dispatch.listing.patch(
          [listingId, 'listings'],
          body.listings[listingId]
        )
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async uploadVerifyDocument(payload) {
      const { listingId, file } = payload
      try {
        const { response, body } = await uploadListingVerification(
          { file },
          { listingId }
        )

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        dispatch.listing.patch(
          [listingId, 'listings'],
          body.listings[listingId]
        )
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    parseUnitFilesAddedSSE(payload) {
      dispatch.listing.patch([payload.listing_id], {
        files: payload.files
      })
    },
    parseListingUpdatedCallback(payload) {
      const listingId = prop('id', headObj(payload))
      dispatch.listing.patch([listingId, 'listings'], payload[listingId])
    }
  }),
  selectors: selectorBuilder(slice => ({
    editListing() {
      return editListingSelector
    },
    listingDetail() {
      return listingSelector
    },
    applications() {
      return slice(applicationsSelector)
    },
    appointments() {
      return slice(appointmentsSelector)
    },
    attendees() {
      return slice(attendeesAvatarSelector)
    },
    slots() {
      return slice(slotsSelector)
    },
    applicants() {
      return slice(applicantsSelector)
    },
    contractData() {
      return slice(contractDataSelector)
    },
    landlords() {
      return slice(listingLandlordsSelector)
    },
    suggestedTenants() {
      return slice(suggestedTenantsSelector)
    }
  }))
}

export default listing
