import React from 'react'
import { useSelector } from 'react-redux'
import { map, path } from 'ramda'
import formatContent from 'utils/format-content'
import htmlToReact from 'utils/html-to-react'
import toArray from 'utils/to-array'

const AddendumDescription = ({ addendum, className, variables }) => {
  const addendumTemplate = useSelector(
    path(['contract', 'listing_contract_addendum_templates', addendum.id])
  )

  if (!addendumTemplate) return null
  if (addendumTemplate.data_type === 'content') {
    const content = toArray(
      addendumTemplate.listing_contract_addendum_template_options
    ).shift()
    const formattedContent = formatContent(content.description, variables)
    return <div className={className}>{htmlToReact(formattedContent)}</div>
  }

  return (
    <div className={className}>
      {addendum.allowed ? (
        <>
          <p>Smoking is allowed only in designated areas including:</p>
          <ul>
            {map(({ id }) => {
              const option =
                addendumTemplate.listing_contract_addendum_template_options[id]
              return option && <li key={option.id}>{option.title}</li>
            }, addendum.options)}
          </ul>
          <p>
            Anywhere else in the building is strictly prohibited under Strata
            Bylaw S2.3
          </p>
        </>
      ) : (
        <div>
          <p>
            We would like to clarify that as per your tenancy agreement, your
            suite is designated &quot;non-smoking&quot;. This also applies to
            the use of cannabis, e-cigarettes (vaping) and similar devices.
            Smoking is prohibited inclusive of balconies (limited common area).
            This is a mandatory term for your tenancy. Please note that a breach
            of a mandatory term will result in a notice to end tenancy.
          </p>
          <p>
            Growing marijuana/cannabis is not permitted in the unit, especially
            due to humidity, mold and odor issues. Please note: growing
            marijuana plants is also not permitted on limited common areas such
            as patios and balconies.
          </p>
          <ul>
            <li>In Unit</li>
            <li>Balcony</li>
            <li>Designated Area</li>
          </ul>
        </div>
      )}
    </div>
  )
}

AddendumDescription.defaultProps = {
  addendum: {},
  addendumTemplate: {},
  className: null,
  variables: {}
}

export default AddendumDescription
