import React from 'react'
import { path } from 'ramda'
import { toInteger } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatCurrency from 'utils/format-currency'
import formatPaymentFrequency from 'utils/format-payment-frequency'
import isTruthy from 'utils/is-truthy'

const ParkingInfo = ({ parkingFee }) => {
  const { t } = useTranslation()
  if (
    !isTruthy(path(['id'], parkingFee)) ||
    parkingFee.frequency === 'not_available'
  ) {
    return null
  }

  const frequency = formatPaymentFrequency(parkingFee.frequency)
  const isIncluded = () =>
    frequency.toLowerCase() === 'free' || toInteger(parkingFee.fee) === 0
  const name = () => isTruthy(parkingFee.name) && <p>{parkingFee.name}</p>

  return (
    <CollapsibleBox
      title={t('b.contract.view.parking_info.subtitle', 'Parking Info')}
      wrapChildren
    >
      {name()}
      <LabeledValue
        label={t('b.contract.view.parking_fee.label', 'Parking fee')}
        value={
          isIncluded()
            ? t('b.contract.view.included.text', 'Included')
            : formatCurrency(parkingFee.fee)
        }
      />
      {!isIncluded() && (
        <LabeledValue
          label={t(
            'b.contract.view.payment_frequency.label',
            'Payment frequency'
          )}
          value={frequency}
        />
      )}
    </CollapsibleBox>
  )
}

ParkingInfo.defaultProps = {
  parkingFee: null
}

export default ParkingInfo
