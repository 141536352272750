import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import editIcon from './edit-icon.svg'
import Base from '../base'

const EditButton = ({ listingId }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Base onClick={() => history.push(`/listing/edit/${listingId}`)}>
      <img src={editIcon} alt="" />
      {t('l.listing.edit.edit.button', 'Edit')}
    </Base>
  )
}

export default EditButton
