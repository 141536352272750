import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { colors, breakpoints, structure } from 'styles'
import { useTranslation } from 'react-i18next'
import { pick } from 'ramda'
import { useDispatch } from 'react-redux'
import { toUnit } from 'utils/styles'
import { prefixUnitNumber, renderListingPrice } from 'utils/listings'
import formatDate from 'utils/format-date'
import formatLeaseType from 'utils/format-lease-type'
import { groupedListings } from 'models/listing/selectors'
import useMediaQuery from 'hooks/use-media-query'
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  StyledTableCell,
  SingleTableRow,
  Accordion,
  AccordionContent,
  AccordionTableRow,
  AccordionHeaderText,
  AccordionHeader,
  TableHeaderText,
  MobileRowWrapper,
  MobileUnitText,
  MobileUnitDetails,
  CellText
} from 'components/listings-table'
import { EmptyListComponent } from 'components/table-list'
import PageTitle from 'components/page-title'
import MyTenantsHeader from './my-tenants-header'

import backgroundBottom from './images/background-bottom.png'
import backgroundBottomMobile from './images/background-bottom-mobile.png'

const Wrapper = styled.div`
  height: 100%;
  background-color: ${colors.white};
  padding: 0 0 230px;

  background-image: url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 542px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
    padding-bottom: 125px;

    background-image: url(${backgroundBottomMobile});
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 375px;
  }
`

const TableWrapper = styled.div`
  max-width: 1130px;
  margin: 0 100px 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px 0;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 0;
  }
`

const NameText = styled.span`
  display: block;
`

const pastStates = ['history']
const currentStates = ['rented']
const upcomingStates = ['upcoming']
const ignoredContractStates = ['draft', 'sent']

const MyTenants = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [currentTab, setCurrentTab] = useState('current')
  const [tabCount, setTabCount] = useState({ past: 0, current: 0, upcoming: 0 })
  const [filteredData, setFilteredData] = useState([])
  const [tabFilteredData, setTabFilteredData] = useState([])
  const [expanded, setExpanded] = useState(true)

  const displayTablet = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )

  // Fetch data from BE
  useEffect(() => {
    const loadContracts = async () => {
      const { body } = await dispatch.contract.getGroupedTenants()
      setData(body)
      setFilteredData(groupedListings(body))
    }
    loadContracts()
  }, [dispatch.contract])

  // Calculate count for each tab
  useEffect(() => {
    const calculateTabCounts = filtered => {
      let past = 0
      let current = 0
      let upcoming = 0
      filtered.forEach(building => {
        building.contracts.forEach(contract => {
          const validContractState = !ignoredContractStates.includes(
            contract.state_machine
          )
          if (
            pastStates.includes(contract.listing_state_machine) &&
            validContractState
          ) {
            past++
          } else if (
            currentStates.includes(contract.listing_state_machine) &&
            validContractState
          ) {
            current++
          } else if (
            upcomingStates.includes(contract.listing_state_machine) &&
            validContractState
          ) {
            upcoming++
          }
        })
      })
      setTabCount({ past, current, upcoming })
    }
    calculateTabCounts(filteredData)
  }, [filteredData])

  // Handle tab changes
  const handleSetTab = useCallback(
    machineStates => {
      const filtered = filteredData
        .map(building => ({
          ...building,
          contracts: building.contracts.filter(
            contract =>
              machineStates.includes(contract.listing_state_machine) &&
              !ignoredContractStates.includes(contract.state_machine)
          )
        }))
        .filter(building => building.contracts && building.contracts.length > 0)
      setTabFilteredData(filtered)
    },
    [filteredData]
  )
  useEffect(() => {
    if (currentTab === 'past') {
      handleSetTab(pastStates)
    } else if (currentTab === 'current') {
      handleSetTab(currentStates)
    } else if (currentTab === 'upcoming') {
      handleSetTab(upcomingStates)
    }
  }, [currentTab, handleSetTab])

  // Handle search
  const searchContracts = searchString => {
    const filtered = data
      .map(building => ({
        ...building,
        contracts: building.contracts.filter(contract => {
          const searchFields = pick(
            [
              'tenants',
              'unit_type_scope_txt_id',
              'unit_type_txt_id',
              'unit_number',
              'address',
              'building_name',
              'city',
              'state'
            ],
            contract
          )
          return JSON.stringify(searchFields)
            .toLowerCase()
            .includes(searchString.trim().toLowerCase())
        })
      }))
      .filter(building => building.contracts && building.contracts.length > 0)
    setFilteredData(filtered)
  }

  const getBuildingTitle = (building, count) => {
    const { building_name, address, city, state } = building
    return (
      <AccordionHeaderText>
        <span>
          {building_name || address} ({count})
        </span>
        {`${address}, ${city}${state === null ? '' : `, ${state}`}`}
      </AccordionHeaderText>
    )
  }

  const getListingAddress = listing => {
    const { unit_number, address, city, state } = listing
    const unitNumber = unit_number ? `${prefixUnitNumber(unit_number)} - ` : ''
    const fullAddress = `${address}, ${city}, ${state}`
    return (
      <strong>
        {unitNumber}
        {fullAddress}
      </strong>
    )
  }

  const getUnitNumber = listing => {
    const { unit_number, unit_type_txt_id } = listing
    if (unit_number) return prefixUnitNumber(unit_number)
    if (unit_type_txt_id === 'house') return t('common.house', 'House')
    if (unit_type_txt_id === 'townhouse')
      return t('common.townhouse', 'Townhouse')
    return '-'
  }

  const goToTenantDashboard = contract => {
    props.history.push(`/tenant/${contract.listing_id}`)
  }

  const MobileListingRow = ({
    listing,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <MobileRowWrapper indent={groupedListing}>
          <MobileUnitText>
            {singleListing
              ? getListingAddress(listing)
              : getUnitNumber(listing)}
          </MobileUnitText>
          <MobileUnitDetails bottom={10}>
            <p>{renderListingPrice(listing)}</p>
            <p>
              {formatDate(listing.start_date, 'MMM D, YYYY') ||
                t('b.components.view.not_avaiable.text', 'N/A')}{' '}
              -{' '}
              {formatDate(listing.end_date, 'MMM Do, YYYY') ||
                t('b.components.view.not_avaiable.text', 'N/A')}
            </p>
          </MobileUnitDetails>
          <MobileUnitText>
            {listing.tenant_first_names.join(', ')}
          </MobileUnitText>
        </MobileRowWrapper>
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.listing.list.view.text', 'View')}</CellText>
      </StyledTableCell>
    </>
  )

  const ListingRow = ({
    listing,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <CellText firstCell indent={groupedListing}>
          {singleListing ? getListingAddress(listing) : getUnitNumber(listing)}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>
            {listing.tenant_first_names.map(name => (
              <NameText key={name}>{name}</NameText>
            ))}
          </strong>
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>{renderListingPrice(listing)}</strong>
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>{formatLeaseType(listing.lease_type, t)}</CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          {formatDate(listing.start_date, 'MMM D, YYYY') ||
            t('b.components.view.not_avaiable.text', 'N/A')}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>
            {formatDate(listing.end_date, 'MMM Do, YYYY') ||
              t('b.components.view.not_avaiable.text', 'N/A')}
          </strong>
        </CellText>
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.listing.list.view.text', 'View')}</CellText>
      </StyledTableCell>
    </>
  )

  return (
    <Wrapper>
      <Helmet>
        <title>{t('l.tentants.view.tenants.title', 'My Tenants')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{t('l.tentants.view.tenants.title', 'My Tenants')}</PageTitle>
      <MyTenantsHeader
        tabCount={tabCount}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        onSearch={searchContracts}
      />
      <TableWrapper>
        <Table>
          {!displayTablet && (
            <TableHeader>
              <TableRow sticky top={structure.header.height + 157}>
                <TableHeaderCell>
                  <TableHeaderText firstCell>
                    {t('common.unit', 'Unit')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.tenants.view.tenant.text', 'Tenant')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('common.price', 'Price')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.tenants.view.lease_type.text', 'Lease Type')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.tenants.view.lease_start.text', 'Lease Start')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.tenants.view.lease_end.text', 'Lease End')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText
                    lastCell
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded
                      ? t('l.listing.list.collapse_all.text', 'Collapse All')
                      : t('l.listing.list.expand_all.text', 'Expand All')}
                  </TableHeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {tabFilteredData.length > 0 ? (
              tabFilteredData.map(building => {
                const contractCount = building.contracts.length

                if (contractCount === 1) {
                  const contract = building.contracts[0]
                  return (
                    <SingleTableRow
                      key={contract.listing_contract_id}
                      onClick={() => goToTenantDashboard(contract)}
                    >
                      {displayTablet ? (
                        <MobileListingRow listing={contract} singleListing />
                      ) : (
                        <ListingRow listing={contract} singleListing />
                      )}
                    </SingleTableRow>
                  )
                }

                return (
                  <Accordion key={building.building_id} expanded={expanded}>
                    <AccordionHeader colSpan={7}>
                      {getBuildingTitle(building, contractCount)}
                    </AccordionHeader>
                    <AccordionContent>
                      {building.contracts.map((contract, index) => {
                        const isLastRow = index + 1 === contractCount

                        return (
                          <AccordionTableRow
                            key={contract.listing_contract_id}
                            onClick={() => goToTenantDashboard(contract)}
                          >
                            {displayTablet ? (
                              <MobileListingRow
                                listing={contract}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            ) : (
                              <ListingRow
                                listing={contract}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            )}
                          </AccordionTableRow>
                        )
                      })}
                    </AccordionContent>
                  </Accordion>
                )
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={7}>
                  <EmptyListComponent />
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

export default MyTenants
