import React from 'react'
import styled from 'styled-components'

const Container = styled.tr`
  ${props =>
    props.sticky &&
    `
    th {
      position: sticky;
      top: ${props.top || 0}px;
      z-index: 20;
    }
  `}
`

const TableRow = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
)

export default styled(TableRow)``
