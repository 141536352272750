import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import RoommatesList from './list'
import RoommateForm from './form'

const RoommatesFullList = ({ anchorTop }) => {
  const { t } = useTranslation()
  const roommates = useSelector(select.profile.roommates)

  return (
    <RoommatesList
      anchorTop={anchorTop}
      data={roommates}
      AddComponent={RoommateForm}
      EditComponent={RoommateForm}
      emptyMessage={t(
        'r.profile.view.empty_roommates.text',
        "You don't have a roommate in your profile."
      )}
      editable
    />
  )
}

export default React.memo(RoommatesFullList)
