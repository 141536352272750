import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import { Button } from 'components/button'
import Webcam from 'react-webcam'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  max-height: 500px;
  align-items: center;
`

const StyledTaskbar = styled(Taskbar)`
  background-color: ${colors.lightGrey};
  position: relative;
  width: 400px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

const CaptureButton = styled(Button)`
  color: ${colors.secondary};
  width: 100%;
`
const videoConstraints = {
  facingMode: 'user'
}

const RetakeButton = styled.button`
  color: secondary;
  border: none;
  outline: none;
  font-size: ${fontSizes.small};
  background-color: transparent;
`

const Screenshot = styled.img`
  margin: 0;
  max-width: auto;
  max-height: auto;
`

const WebcamWrapper = styled.div`
  height: 300px;
`

const ViewWebcamModal = ({ setVerificationSelfie }) => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const webcamRef = useRef()
  const [screenshotData, setScreenshotData] = useState(null)

  const captureScreenshot = () => {
    const screenshotSrc = webcamRef.current.getScreenshot()
    setScreenshotData(screenshotSrc)
  }

  const handleDone = () => {
    setVerificationSelfie(screenshotData)
    close()
  }

  const handleRetake = () => {
    setScreenshotData(null)
  }

  const displaySelfieScreen = () => {
    return (
      <Wrapper>
        <StyledTaskbar
          left={<Tool type="close" onClick={close} />}
          right={
            <RetakeButton onClick={() => handleRetake()}>
              {t('b.webcam_modal.view.retake.button', 'Retake')}
            </RetakeButton>
          }
        >
          {t(
            'b.webcam_modal.view.take_a_selfie.button',
            'Take a picture of yourself'
          )}
        </StyledTaskbar>
        <WebcamWrapper>
          <Screenshot src={screenshotData} alt="Selfie Image" />
        </WebcamWrapper>
        <CaptureButton onClick={() => handleDone()}>
          {t('b.webcam_modal.view.confirm.button', 'Confirm')}
        </CaptureButton>
      </Wrapper>
    )
  }

  const takeSelfieScreen = () => {
    return (
      <Wrapper>
        <StyledTaskbar left={<Tool type="close" onClick={close} />}>
          {t(
            'b.webcam_modal.view.take_a_selfie.button',
            'Take a picture of yourself'
          )}
        </StyledTaskbar>
        <WebcamWrapper>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/png"
            height="100%"
            width="100%"
            videoConstraints={videoConstraints}
          />
        </WebcamWrapper>
        <CaptureButton onClick={() => captureScreenshot()}>
          {t('b.webcam_modal.view.take_photo.button', 'Capture Photo')}
        </CaptureButton>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {screenshotData ? displaySelfieScreen() : takeSelfieScreen()}
    </Wrapper>
  )
}
export const ShowWebcam = createModal(ViewWebcamModal)
