import {
  path,
  pipe,
  equals,
  set,
  reduce,
  lensProp,
  lensPath,
  dissocPath,
  reject,
  defaultTo,
  append,
  uniq,
  mergeLeft,
  mergeDeepLeft,
  assoc,
  dissoc,
  prop,
  propOr,
  pathOr,
  curry,
  evolve
} from 'ramda'
import toInteger from 'lodash/toInteger'
import selectorBuilder from 'models/selector-builder'
import moment from 'moment'
import { headObj, isNotEmpty, callOr } from 'utils'
import { batch } from 'react-redux'
import { downloadBlob } from 'utils/files'
import config from 'config'
import {
  getTimelines,
  getNextTimelines,
  getEvents,
  getSecureFile,
  sendMessage,
  sendImage,
  sendFile,
  getStats,
  setTimelineRead,
  tenantStartChat,
  deleteTimeline,
  reportProblem
} from './ports'
import {
  timelinesSelector,
  timelineSelector,
  timelineEventsSelector,
  callbackUserSelector,
  listingTimelinesSelector,
  tenantTimelinesSelector
} from './selectors'

if ('Notification' in window) {
  if (
    Notification.permission !== 'denied' ||
    Notification.permission !== 'granted'
  ) {
    Notification.requestPermission()
  }
}

const INITIAL_STATE = {
  intialTimelinesLoaded: false,
  ten: 1,
  inq: 1
}

const patchUsers = curry((users, state) =>
  set(lensProp('users'), pipe(prop('users'), mergeLeft(users))(state))(state)
)

const chat = {
  state: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    clearCurrentChat: dissoc('currentTimelineId'),
    remove: (state, at) => dissocPath(at, state),
    saveTimelines: (state, payload) =>
      pipe(
        assoc('intialTimelinesLoaded', prop('intialTimelinesLoaded', payload)),
        assoc('inq', propOr(state.inq, 'inq')(payload)),
        assoc('ten', propOr(state.ten, 'ten')(payload)),
        mergeDeepLeft({
          users: payload.users,
          timelines: payload.timelines,
          events: mergeLeft(state.events, payload.timeline_events2),
          lastEvents: payload.timeline_events2
        })
      )(state),
    updateTimeline: (state, payload) =>
      set(lensPath(['timelines', payload.timeline_room]), payload, state),
    updateApplications: (state, timelineRoom) =>
      set(
        lensPath(['timelines', timelineRoom, 'applications']),
        {
          id: null,
          user_id: null
        },
        state
      ),
    patchUsers: (state, payload) => patchUsers(payload, state),
    saveEvents: (state, payload) =>
      pipe(
        patchUsers(propOr({}, 'users', payload)),
        set(
          lensPath(['timelines', payload.timelineRoomId, 'lastEventLoaded']),
          payload.lastEventId
        ),
        set(
          lensPath(['events', payload.timelineRoomId]),
          pipe(
            path(['events', payload.timelineRoomId]),
            defaultTo({}),
            mergeLeft(
              pathOr({}, ['timeline_events2', payload.timelineRoomId], payload)
            )
          )(state)
        ),
        assoc('currentTimelineId', payload.timelineRoomId)
      )(state),
    addEvent: (state, payload) => {
      const timelineId = pipe(headObj, prop('timeline_room'))(payload)
      return pipe(
        set(
          lensPath(['events', timelineId]),
          pipe(
            path(['events', timelineId]),
            defaultTo({}),
            mergeLeft(payload)
          )(state)
        ),
        set(lensPath(['lastEvents', timelineId]), payload)
      )(state)
    },
    removePhantom: (state, { passthrough, timelineRoomId }) => {
      const events = reject(
        e => e.id === passthrough,
        state.events[timelineRoomId]
      )

      return {
        ...state,
        events: {
          ...state.events,
          [timelineRoomId]: events
        }
      }
    },
    saveUnread: (state, payload) => ({
      ...state,
      ...payload
    }),
    setRead: (state, payload) => {
      const removeFrom = curry((fields, data) =>
        reduce(
          (acc, value) =>
            set(
              lensProp(value),
              pipe(
                path([value]),
                defaultTo([]),
                reject(a => a === payload.timeline_room)
              )(acc)
            )(acc),
          data,
          fields
        )
      )
      return pipe(
        removeFrom([
          'unread_timeline_rooms',
          'unread_rented_rooms',
          'unread_inquiry_rooms'
        ]),
        set(
          lensPath(['timelines', payload.timeline_room]),
          pipe(
            path(['timelines', payload.timeline_room]),
            defaultTo({}),
            mergeLeft(payload)
          )(state)
        )
      )(state)
    },
    setUnread: (state, message) => {
      const type =
        message.listings.state_machine === 'rented'
          ? 'unread_rented_rooms'
          : 'unread_inquiry_rooms'
      const fields = ['unread_timeline_rooms', type]
      return reduce(
        (acc, value) =>
          set(
            lensProp(value),
            pipe(
              path([value]),
              defaultTo([]),
              append(message.timeline_room),
              uniq
            )(acc)
          )(acc),
        state,
        fields
      )
    }
  },
  effects: dispatch => ({
    getTimelines: async (_, rootState) => {
      if (rootState.chat.intialTimelinesLoaded) return
      dispatch.supportRoom.getSupportRoom()
      try {
        const { body, response } = await getTimelines()
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chat.saveTimelines({ ...body, intialTimelinesLoaded: true })
      } catch (error) {
        console.log('[Chat/getTimelines]', error)
      }
    },
    async getNextTimelines(payload, rootState) {
      const types = {
        tenants: 'ten',
        inquiries: 'inq'
      }

      const type = types[payload]
      const typePage = rootState.chat[type]

      try {
        const { body, response } = await getNextTimelines({
          type: type,
          pageNumber: typePage + 1
        })
        if (response.ok && isNotEmpty(body?.timelines)) {
          dispatch.chat.saveTimelines({ ...body, [type]: typePage + 1 })
        }
      } catch (error) {
        console.log('[Chat/getNextTimelines]', error)
      }
    },
    getEvents: async (payload, rootState) => {
      try {
        if (
          equals(
            payload.lastEventId,
            path(
              ['chat', 'timelines', payload.timelineRoomId, 'lastEventLoaded'],
              rootState
            )
          )
        )
          return

        const { response, body } = await getEvents({
          timelineRoomId: payload.timelineRoomId,
          lastEventId: payload.lastEventId
        })

        if (response.ok) {
          dispatch.chat.saveEvents({
            ...body,
            timelineRoomId: payload.timelineRoomId,
            lastEventId: payload.lastEventId
          })
        }
      } catch (error) {
        console.log('[Chat/getEvents]', error)
      }
    },
    async setTimelineRead(timelineRoomId, rootState) {
      if (!rootState.chat.timelines?.[timelineRoomId]) return
      const { body, response } = await setTimelineRead({ timelineRoomId })
      if (response.ok) {
        dispatch.chat.setRead(body)
      }
    },
    getSecureFile: async ({ fileId, fileName }) => {
      try {
        const response = await getSecureFile({ fileId })
        if (response.response.ok) {
          downloadBlob(response.body, fileName)
        }
      } catch (error) {
        console.log('[Chat/getSecureFile]', error)
      }
    },

    sendMessage: async payload => {
      payload.passthrough = Date.now()
      dispatch.chat.addPhantomEvent(payload)
      try {
        const { response, body } = await sendMessage(
          { message: payload.message, passthrough: payload.passthrough },
          { timelineRoomId: payload.timelineRoomId }
        )
        if (response.ok) {
          batch(() => {
            dispatch.chat.removePhantom(payload)
            dispatch.chat.addEvent(body.data)
          })
        }
      } catch (error) {
        console.log('[Chat/sendMessage]', error)
      }
    },

    addPhantomEvent(payload, rootState) {
      const message = {
        [payload.passthrough]: {
          id: payload.passthrough,
          event_type: 'chat',
          pending: true,
          timeline_room: payload.timelineRoomId,
          event_info: {
            message: payload.message
          },
          created_at: moment.utc().format(),
          user_id: rootState.session.session.id
        }
      }
      dispatch.chat.addEvent(message)
    },

    sendFile: async payload => {
      try {
        const { response, body } = await sendFile(
          { file: payload.file },
          { timelineRoomId: payload.timelineRoomId }
        )
        if (response.ok) {
          dispatch.chat.addEvent(body.data)
        }
      } catch (error) {
        console.log('[Chat/sendFile]', error)
      }
    },
    sendImage: async payload => {
      try {
        const { response, body } = await sendImage(
          { file: payload.file },
          { timelineRoomId: payload.timelineRoomId }
        )
        if (response.ok) {
          dispatch.chat.addEvent(body.data)
        }
      } catch (error) {
        console.log('[Chat/sendImage]', error)
      }
    },
    deleteTimeline: async timelineRoomId => {
      try {
        const { response, body } = await deleteTimeline(undefined, {
          timelineRoomId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chat.remove(['timelines', timelineRoomId])
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    reportProblem: async payload => {
      const parsePayload = evolve({
        user_id: toInteger,
        reference_id: callOr(toInteger, null)
      })
      try {
        const { response, body } = await reportProblem({
          body: parsePayload(payload)
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async checkUnreadMessages(_, rootState) {
      const { body } = await getStats()
      dispatch.chat.saveUnread(body)
    },
    parseTimelineEventSSE(message) {
      dispatch.chat.addEvent(message)
      const options = {
        body: pipe(headObj, path(['event_info', 'message']))(message)
      }
      const timelineRoom = pipe(headObj, prop('timeline_room'))(message)
      if ('Notification' in window && Notification.permission === 'granted') {
        const notification = new Notification('New message', options)
        notification.onclick = function (event) {
          event.preventDefault()
          location.replace(`${config.APP_PATH}/chat/${timelineRoom}`)
        }
      }
    },
    parseTimelineSSE(message, rootState) {
      batch(() => {
        dispatch.chat.updateTimeline(message)
        dispatch.chat.patchUsers(message?.users)
        dispatch.chat.setUnread(message)
        if (rootState.chat.currentTimelineId) {
          dispatch.chat.setTimelineRead(rootState.chat.currentTimelineId)
        }
      })
    },
    parseUserUpdatedCallback(payload) {
      const data = callbackUserSelector(payload)
      dispatch.chat.patchUsers({ [data.id]: data })
    },
    async startChat({ message, listingId }) {
      try {
        const { response, body } = await tenantStartChat(
          {
            message
          },
          {
            listingId
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error }
      }
    }
  }),

  selectors: selectorBuilder(slice => ({
    timelines() {
      return timelinesSelector
    },
    timeline() {
      return timelineSelector
    },
    timelineEvents() {
      return slice(timelineEventsSelector)
    },
    listingTimelines() {
      return listingTimelinesSelector
    },
    tenantTimelines() {
      return tenantTimelinesSelector
    }
  }))
}

export default chat
