import React, { useRef } from 'react'
import { useField } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import noop from 'lodash/noop'
import Input from './input'
import Error from './error'

const FieldInput = ({
  name,
  value: setValue,
  type,
  hideError,
  onToggle = noop,
  ...rest
}) => {
  const fieldRef = useRef()
  const [field, meta] = useField(name)
  const { value, onChange, ...fields } = field
  useFocusOnError({ fieldRef, name })
  const hasError = !!meta.error && meta.touched && !hideError
  const extra = {}
  if (type === 'radio') {
    const isChecked = value === setValue
    extra.defaultChecked = isChecked
    extra['aria-checked'] = isChecked
  } else if (rest.type === 'checkbox') {
    const values = field.value
    extra['aria-checked'] = Array.isArray(values) ? values.length > 0 : !!values
  }
  const onChangeWrap = (...args) => {
    onChange(...args)
    onToggle(...args)
  }
  return (
    <>
      <Input
        ref={fieldRef}
        hasError={hasError}
        {...fields}
        onChange={onChangeWrap}
        value={setValue || value}
        type={type}
        {...rest}
        {...extra}
      />
      {!hideError && <Error name={name} />}
    </>
  )
}

FieldInput.defaultProps = {
  onToggle: noop
}

export default React.memo(FieldInput)
