import ENV_VARS from 'config'
import { get, put, post, del } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const updateProfile = put(`${BACKEND_SERVER}/users/me`)
export const getProfile = get(`${BACKEND_SERVER}/users/me`)
export const setLinkedInProfile = post(
  `${BACKEND_SERVER}/users/social_media/linked_in`
)
export const removeLinkedInProfile = del(
  `${BACKEND_SERVER}/users/social_media/%id%`
)

export const uploadSecureDocument = post(
  `${BACKEND_SERVER}/secure/id_verification`
)
export const deleteSecureDocument = del(
  `${BACKEND_SERVER}/secure/id_verification/%id%`
)

export const addPet = post(`${BACKEND_SERVER}/users/pets`)
export const updatePet = put(`${BACKEND_SERVER}/users/pets/%petId%`)
export const deletePet = del(`${BACKEND_SERVER}/users/pets/%petId%`)

export const addContact = post(`${BACKEND_SERVER}/users/contacts`)
export const updateContact = put(`${BACKEND_SERVER}/users/contacts/%contactId%`)
export const deleteContact = del(`${BACKEND_SERVER}/users/contacts/%contactId%`)

export const addEmployer = post(`${BACKEND_SERVER}/users/employers`)
export const updateEmployer = put(
  `${BACKEND_SERVER}/users/employers/%employerId%`
)
export const deleteEmployer = del(
  `${BACKEND_SERVER}/users/employers/%employerId%`
)

export const addRoommate = post(`${BACKEND_SERVER}/users/roommates`)
export const updateRoommate = put(
  `${BACKEND_SERVER}/users/roommates/%roommateId%`
)
export const deleteRoommate = del(
  `${BACKEND_SERVER}/users/roommates/%roommateId%`
)

export const addVehicle = post(`${BACKEND_SERVER}/users/vehicles`)
export const updateVehicle = put(`${BACKEND_SERVER}/users/vehicles/%vehicleId%`)
export const deleteVehicle = del(`${BACKEND_SERVER}/users/vehicles/%vehicleId%`)

export const remoteAssist = post(`${BACKEND_SERVER}/users/assist`)
export const remoteAssistRequestToken = post(
  `${BACKEND_SERVER}/users/assist_token_request`
)
