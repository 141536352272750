import { createConfirmation } from 'react-confirm'
import { wrapArray } from 'utils'
import TextConfirmation from './text-confirmation'
import Confirmation from './confirmation'

const textConfirmation = createConfirmation(TextConfirmation)
const defaultConfirmation = createConfirmation(Confirmation)

export const confirmText = (confirmation, options = {}) =>
  textConfirmation({ confirmation, ...options })

export const confirm = (title, messages, options = {}) =>
  defaultConfirmation({ title, messages: wrapArray(messages), ...options })

export default Confirmation
