import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes } from 'styles'
import { Tabs, TabList, Tab, TabPanel } from 'components/tabs'
import SearchBar from 'components/search-bar'
import ChatNotification from 'components/chat-notification'
import ChatCardSupport from './chat-card-support'
import ChatCardUser from './chat-card-user'
import ChatCardsContainer from './chat-cards-container'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 450px;
  width: 25%;
  border-right: 1px solid ${colors.grey};
  margin-right: -1px;
  background-color: ${colors.white};

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: calc(100vw + 10px);
    min-width: unset;
    max-width: unset;
  }
`

const ChatTabsList = styled(TabList)`
  height: 57px;
  background-color: ${colors.white};
  box-shadow: none;
  border-bottom: 4px solid ${colors.grey};

  button {
    border: none;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    height: 57px;
    cursor: pointer;
  }
  p {
    margin: auto;
    font-size: ${fontSizes.regular};
  }
  .active {
    border-bottom: 4px solid ${colors.secondary};
  }
`

const SearchBarContainer = styled.div`
  background-color: #fbfbfb;
  border-bottom: 1px solid ${colors.grey};
`

const SideBarWrapper = styled.div`
  display: flex;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    position: absolute;
    left: ${props => (props.isOpen ? '-10px' : 'calc(-100vw - 20px)')};
    top: ${props => props.top || 0}px;
    bottom: 0px;
    z-index: 99;
    background-color: ${colors.white};
    transition: left 0.5s ease;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
  }
`

const NoChatsText = styled.h2`
  text-align: center;
  margin-top: 50px;
`

const TabText = styled.p`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > div {
    position: absolute;
    right: 16px;
    @media screen and (max-width: 355px) {
      right: 8px;
    }
  }
`

const ChatSideBar = ({
  isOpen,
  closeNav,
  initialTab,
  top,
  tabs = [],
  onEndReached = () => {},
  sideBarHeader = null,
  chatFooterButton = null
}) => {
  const { t } = useTranslation()
  const [filteredTabs, setFilteredTabs] = useState(tabs)

  const hasSideBarHeader = !!sideBarHeader
  const HeaderComponent = sideBarHeader
  const FooterButton = chatFooterButton

  const handleSearch = useMemo(
    () => searchString => {
      if (!searchString) return setFilteredTabs(tabs)

      const newTabs = tabs.reduce((accum, curr) => {
        const data = curr.data.filter(timeline =>
          JSON.stringify(timeline)
            .toLowerCase()
            .includes(searchString.trim().toLowerCase())
        )
        return [...accum, { ...curr, data }]
      }, [])

      setFilteredTabs(newTabs)
    },
    [tabs]
  )

  useEffect(() => {
    handleSearch()
  }, [handleSearch, tabs])

  return (
    <SideBarWrapper isOpen={isOpen} top={top}>
      <Wrapper>
        <Tabs selectedInitialId={initialTab}>
          {filteredTabs?.length > 1 && (
            <section>
              <ChatTabsList>
                {filteredTabs.map(tab => (
                  <Tab id={tab.id} key={tab.id}>
                    <TabWrapper>
                      <TabText>{tab?.title}</TabText>
                      <ChatNotification
                        type={tab.unread}
                        large
                        right="0"
                        top="-1px"
                      />
                    </TabWrapper>
                  </Tab>
                ))}
              </ChatTabsList>
            </section>
          )}
          {hasSideBarHeader && <HeaderComponent />}
          <SearchBarContainer>
            <SearchBar
              onSearch={handleSearch}
              placeholder={t('common.search', 'Search')}
              height="65px"
              padding="0px 20px"
              borderless
            />
          </SearchBarContainer>
          {filteredTabs?.map(tab => (
            <TabPanel key={tab.id} id={tab.id}>
              <ChatCardsContainer
                onEndReached={onEndReached}
                id={tab.id}
                onClick={closeNav}
              >
                {tab.support && <ChatCardSupport />}
                {tab?.data?.length > 0
                  ? tab?.data.map(timeline => (
                      <ChatCardUser
                        key={timeline.timeline_room}
                        timeline={timeline}
                      />
                    ))
                  : !tab.support && (
                      <NoChatsText>
                        {t(
                          'b.chat.view.no_chats_available.text',
                          'No chats available'
                        )}
                      </NoChatsText>
                    )}
              </ChatCardsContainer>
            </TabPanel>
          ))}
          {chatFooterButton && <FooterButton />}
        </Tabs>
      </Wrapper>
    </SideBarWrapper>
  )
}

export default React.memo(ChatSideBar)
