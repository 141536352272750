import { isEmpty, equals } from 'ramda'
import { toNumber } from 'lodash'
import moment from 'moment'
import capitalize from 'utils/capitalize'
import { getAreaMeasurementLabel } from 'utils/listings'
import config from 'config'

const equalsOne = equals('1')

const TYPES = {
  condo: 'apartment'
}

const getExternalLink = listing => {
  return (
    `${config.WEB_URL}/rental-listings/detail/` +
    (TYPES[listing.unit_type_txt_id] || listing.unit_type_txt_id) +
    `/${listing.building.city.toLowerCase().replace(' ', '-')}/` +
    listing.listing_id
  )
}

const getListingTypeText = (type, scope) => {
  const listingTypes = {
    condo: {
      entire: 'Entire Apartment',
      partial: 'Partial Apartment',
      single: 'Private Room in Apartment'
    },
    house: {
      entire: 'Entire House',
      partial: 'Partial House',
      single: 'Private Room in House'
    },
    townhouse: {
      entire: 'Entire Townhouse',
      partial: 'Partial Townhouse',
      single: 'Private Room in Townhouse'
    }
  }
  return listingTypes[type][scope]
}

const streetName = listing =>
  streetNoHidden(listing)
    ? listing?.building?.street_name
    : listing?.building?.full_street_name
const formatListingAddress = listing => {
  const { unit_number, building } = listing
  const showBuildingName =
    !isEmpty(unit_number) && building.name !== building.street_name
  return `${showBuildingName ? building.name + ' ' : ''}${streetName(listing)}`
}

const getBedrooms = (listing, areaMeasurementUnit) => {
  const sizeLabel = getAreaMeasurementLabel(areaMeasurementUnit)
  if (listing.unit_type_scope_txt_id === 'single')
    return `${listing.size} ${sizeLabel} bedroom`
  const count = toNumber(listing.count_bedrooms)
  if (count === 0) return 'Studio'
  return `${count} bedroom`
}

const getBathrooms = listing => {
  if (listing.unit_type_scope_txt_id === 'entire')
    return `  - ${listing.count_all_bathrooms} bathroom\n`
  const fullBath =
    toNumber(listing.count_full_shared_bathrooms) > 0
      ? `  - ${listing.count_full_bathrooms} private bathroom\n`
      : ''
  const privateBath =
    toNumber(listing.count_full_shared_bathrooms) > 0
      ? `  - ${listing.count_full_shared_bathrooms} shared bathroom\n`
      : ''
  return `${fullBath}${privateBath}`
}

const getFurnished = listing => {
  if (listing.furnished === '') return 'Furniture available'
  return equalsOne(listing.furnished) ? 'Furnished' : 'Unfurnished'
}

const renderAvailability = listing => {
  const isTodayOrPast = moment().diff(listing.availability_date) >= 0
  return isTodayOrPast
    ? 'Available now'
    : moment(listing.availability_date).format('MMMM Do YYYY')
}

const formatFee = (data, feeLabel) => {
  if (
    isEmpty(data.fee_frequency_txt_id) ||
    data.fee_frequency_txt_id === 'not_available'
  ) {
    return ''
  }
  if (data.fee_frequency_txt_id === 'free') {
    return `  - ${feeLabel} included\n`
  }
  return `  - Paid ${feeLabel} available\n`
}

const formatAmenities = (features, customFeatures, unitType) => {
  const combinedFeatures = [...features, ...customFeatures]
  const featuresArray = combinedFeatures.map(item => {
    let featureText = [item.name]
    if (unitType !== 'entire') {
      featureText.push(`(${item.type})`)
    }
    return `  - ${featureText.join(' ')}\n`
  })
  return featuresArray.join('')
}

const hideUnitNumber = listing => !!Number(listing.hide_unit_number)
const unitHidden = listing => hideUnitNumber(listing) || !listing.unit_number
const streetNoHidden = listing =>
  hideUnitNumber(listing) && listing?.building?.building_type_txt_id === 'house'

const CopyListingKijiji = (listing, areaMeasurementUnit) => `
  **Apply at ${getExternalLink(listing)}**

  ${getListingTypeText(
    listing.unit_type_txt_id,
    listing.unit_type_scope_txt_id
  )} ${
  !unitHidden(listing) ? `Unit ${listing.unit_number} ` : ''
}at ${formatListingAddress(listing)}

  Unit Details:
  - ${getBedrooms(listing, areaMeasurementUnit)}
${getBathrooms(listing)}${
  listing.unit_type_scope_txt_id === 'single'
    ? ''
    : `  - Size: ${listing.size} sqft\n`
}  - ${getFurnished(listing)}

  Rental Terms and Conditions:
${
  listing.max_occupancy > 0
    ? `  - Max. occupancy: ${listing.max_occupancy}\n`
    : ''
}  - Pets: ${listing.allow_pets ? 'Yes' : 'No'}
  - Smoking: ${listing.allow_smoking ? 'Yes' : 'No'}
  - Lease Type: ${capitalize(listing.lease_type).replace(/_/g, ' ')}
  - Available date: ${renderAvailability(listing)}

  ${listing.description && `About this Property:`}
  ${listing.description && listing.description}

  Amenities:
${formatFee(listing.parking, 'Parking')}${formatFee(
  listing.storage,
  'Storage'
)}${formatAmenities(
  listing.unit_features,
  listing.custom_features,
  listing.unit_type_scope_txt_id
)}${formatAmenities(
  listing.listing_utilities,
  listing.custom_utilities,
  listing.unit_type_scope_txt_id
)}
  About The Building:
  - Address: ${streetName(listing)}
  - Neighborhood: ${listing.building.neighborhood || listing.building.city}
`

export default CopyListingKijiji
