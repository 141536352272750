import React from 'react'
import { ModalizeConsumer } from './context'

const withModalize = (WrappedComponent, options = {}) => {
  class HOC extends React.Component {
    close(context) {
      return () => {
        this.setState({ visible: false })
        context.dispose()
      }
    }
    render() {
      return (
        <ModalizeConsumer>
          {context => (
            <WrappedComponent {...this.props} close={this.close(context)} />
          )}
        </ModalizeConsumer>
      )
    }
  }

  return HOC
}

export default withModalize
