import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Flag from 'react-country-flag'
import { fontSizes, fontWeights, colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h2`
  margin: 15px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 10px 20px;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 1px;
  cursor: pointer;
  background-color: ${props => (props.selected ? colors.grey : colors.white)};
  flex-shrink: 0;

  :hover {
    background-color: ${colors.lightGrey};
  }
`

const CountryName = styled.div`
  flex: 25;
  text-align: left;
`

const CountryCode = styled.div`
  font-weight: ${fontWeights.medium};
  flex: 1;
`

const flagStyle = {
  marginRight: '5px',
  width: '30px',
  height: '15px',
  backgroundSize: 'contain'
}

const CountrySelectModal = ({ countries, onSelect, currentValue }) => {
  const [t] = useTranslation()
  const { close } = useModalize()

  const handleSelect = country => {
    onSelect(country)
    close()
  }

  const renderCountryRow = country => {
    const isSelected = country.txt_id === currentValue
    return (
      <ListItem
        key={country.txt_id}
        onClick={() => handleSelect(country)}
        selected={isSelected}
      >
        <Flag code={country.txt_id} styleProps={flagStyle} svg />
        <CountryName>{country.name}</CountryName>
        <CountryCode>+{country.calling_code}</CountryCode>
      </ListItem>
    )
  }

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      <Title>
        {t('b.country_code_modal.edit.country_code.title', 'Country Code')}
      </Title>
      <ListWrapper>
        {countries.length > 0 &&
          countries.map(country => renderCountryRow(country))}
      </ListWrapper>
    </Wrapper>
  )
}

export const show = createModal(CountrySelectModal)
