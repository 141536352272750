import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTemplate from './card-template'
import equifaxPoor from './images/Credit_Score_Poor.png'

const PoorCredit = props => {
  const { t } = useTranslation()

  const creditDescription =
    props.userRole === 'tenant'
      ? t(
          'r.liv_score_card.view.poor_credit_description.text',
          'Hold on! You can help your landlord understand you better by providing a more detailed financial story.'
        )
      : t(
          'l.liv_score_card.view.poor_credit_description.text',
          'The applicant may have a history of not paying bills in time. You should request for further financial documents, such as a payslip, from the applicant.'
        )

  return (
    <CardTemplate
      userRole={props.userRole}
      title={props.title}
      creditDetails={props.creditDetails}
      creditDescription={creditDescription}
      creditRatingResult={t('b.liv_score_card.view.poor.text', 'Poor')}
      cardImage={equifaxPoor}
      avatarUrl={props.avatarUrl}
      professionId={props.professionId}
      csoVerifiedState={props.csoVerifiedState}
      salarySort={props.salarySort}
      listingData={props.listingData}
      data-testid={props['data-testid']}
    />
  )
}

PoorCredit.defaultProps = {
  userRole: '',
  title: '',
  creditDetails: {}
}

export default PoorCredit
