import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { getImageUrl } from 'utils/image'
import { Trans } from 'react-i18next'
import { fontSizes, fontWeights, colors } from 'styles'
import VerifiedBadge from 'components/verified-badge'
import ProgressRing from 'components/progress-ring'
import ProfileRequired from 'components/profile-required'
import Avatar from './avatar'
import AvatarPicker from './avatar-picker'
import defaultAvatar from './default-avatar.svg'

const AvatarRequiredWrapper = styled.div`
  height: ${({ height }) => height}px;
  position: absolute;
  right: 5px;
  top: 8px;
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Wrapper = styled(RowWrapper)`
  flex-direction: column;
`

const CompletionText = styled.p`
  color: ${colors.regular};
  font-size: 13px;
  line-height: 18px;
  margin: 0 0 7px;

  strong {
    font-weight: ${fontWeights.medium};
  }
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0 5px;
`

const NameText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  line-height: 27px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`

const AvatarWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    margin-left: 80px;
  }
`

const tolerance = 2
const stroke = 10

const AvatarWithStatus = ({
  width = 120,
  height = 120,
  data,
  hideText,
  editable
}) => {
  const dispatch = useDispatch()
  const handleUploadAvatar = file => {
    dispatch.profile.uploadAvatar({
      file,
      fileId: data.avatar?.id
    })
  }
  const AvatarComponent = editable ? AvatarPicker : Avatar
  const avatarLocation = data.avatar?.aws_s3_key
  const getImage = (url, options) =>
    url ? getImageUrl(url, options) : defaultAvatar
  const extra = editable
    ? {
        showIcon: !avatarLocation,
        onSelect: handleUploadAvatar
      }
    : {}
  const radius = Math.max(width, height) / 2 + stroke * 2.5
  const count = data.completion_percentage
  return (
    <RowWrapper>
      <Wrapper>
        {!hideText && (
          <CompletionText>
            <Trans
              i18nKey="b.avatar_component.view.profile_completion.text"
              count={count}
            >
              Profile completion <strong>{{ count }}%</strong>
            </Trans>
          </CompletionText>
        )}
        <ProgressRing
          radius={radius}
          stroke={stroke}
          progress={data.completion_percentage}
        >
          <AvatarWrapper>
            <AvatarComponent
              location={getImage(data.avatar?.aws_s3_key, {
                width: 240,
                height: 240
              })}
              width={width - tolerance}
              height={height - tolerance}
              shadow
              {...extra}
            />
          </AvatarWrapper>
        </ProgressRing>
        {!hideText && (
          <NameWrapper>
            <NameText>{data.preferred_name || data.first_name}</NameText>
            <VerifiedBadge
              type="id"
              width={30}
              height={20}
              left={7}
              bottom={-5}
              verified={data.identity_verified_state}
            />
          </NameWrapper>
        )}
      </Wrapper>
      {editable && (
        <AvatarRequiredWrapper height={height}>
          <ProfileRequired fields={['avatar']} area="basic_info" left="0" />
        </AvatarRequiredWrapper>
      )}
    </RowWrapper>
  )
}

export default AvatarWithStatus
