import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Button } from 'components/button'
import contractImage from './contract_signed.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const Congrats = styled.div`
  display: block;
  margin-bottom: 50px;
  height: 150px;
  width: 100%;
  background-color: ${colors.white};
  background-image: url('${({ url }) => url}');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`

const HeaderText = styled.p`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
  text-align: center;
  padding-bottom: 20px;
  line-height: 28px;
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin-bottom: 10px;
  letter-spacing: 0.25px;
`

const CongratulationsModal = () => {
  const { close } = useModalize()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <HeaderText>
          {t(
            'l.contract.view.contract_signing_complete_modal.title',
            'Contract Signing Completed!'
          )}
        </HeaderText>
        <Congrats url={contractImage} />
        <StyledButton
          theme="primary"
          width={200}
          left="auto"
          right="auto"
          type="button"
          onClick={close}
        >
          {t(
            'l.contract.view.contract_signing_complete_moda_close.button',
            'Ok, Got It!'
          )}
        </StyledButton>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showCongratulationsModal = createModal(CongratulationsModal)
