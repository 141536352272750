import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes } from 'styles'
import { parsePhone } from 'utils/format-phone'
import TextButton from 'components/anchor/button'
import PhoneNumber from './phone'

const anchor = css`
  font-size: ${fontSizes.small};
  color: ${colors.regular};
  text-decoration: none;
  line-height: 18px;
`

const ShowPhoneText = styled(TextButton)`
  font-size: ${fontSizes.small};
  color: ${colors.link};
  padding: 0;
`

const ShowPhoneNumber = ({ hidePhone, phoneNum }) => {
  const [isPhoneNumVisible, setIsPhoneNumVisible] = useState(false)
  const { t } = useTranslation()
  return hidePhone === 0 ? (
    <>
      {isPhoneNumVisible ? (
        <PhoneNumber phoneNumber={parsePhone(phoneNum)} customStyle={anchor} />
      ) : (
        <ShowPhoneText onClick={() => setIsPhoneNumVisible(true)}>
          {t('b.listing.view.show_phone_number.button', 'Show phone number')}
        </ShowPhoneText>
      )}
    </>
  ) : null
}

export default ShowPhoneNumber
