import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import ProfileRequired from 'components/profile-required'
import LandlordReferenceView from './view'
import EmptyMessageCheckbox from '../EmptyMessageCheckbox'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const LandlordReferencesList = ({
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  isChecked = false,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  const [disabled, setDisabled] = useState(isChecked)
  const dispatch = useDispatch()
  const updateRenterStatus = checked => {
    dispatch.profile.updateRenterStatus({
      first_time_renter: checked ? 1 : 0
    })
  }
  return (
    <MultiRowForm
      anchorTop={anchorTop}
      disabled={disabled}
      title={
        <>
          {t(
            'r.profile.view.landlord_references.subtitle',
            'Landlord References'
          )}
          <ProfileRequired fields={['landlord_reference']} area="references" />
        </>
      }
      addText={t(
        'r.profile.view.add_landlord_reference.button',
        '+ Add Landlord Reference'
      )}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={LandlordReferenceView}
      EditComponent={EditComponent}
      EmptyComponent={
        editable ? (
          <EmptyMessageCheckbox
            setDisabled={setDisabled}
            emptyMessage={emptyMessage}
            update={updateRenterStatus}
            isChecked={isChecked}
          />
        ) : (
          <EmptyMessage>{emptyMessage}</EmptyMessage>
        )
      }
      editable={editable}
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(LandlordReferencesList)
