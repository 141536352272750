import React from 'react'
import styled from 'styled-components'
import { getImageUrl } from 'utils/image'

const Logo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const LogoContainer = styled.div`
  ${props =>
    props.maxHeight &&
    `
    height: ${props.maxHeight}px;
  `}
  ${props =>
    props.maxWidth &&
    `
    width: ${props.maxWidth}px;
  `}
  display: flex;
  justify-content: center;
  align-items: center;
`

const BrokerLogo = ({ broker, maxWidth = 100, maxHeight = 75, ...rest }) => {
  const logo = broker?.logo
  if (!logo) return null
  return (
    <LogoContainer maxHeight={maxHeight} maxWidth={maxWidth}>
      <Logo
        src={getImageUrl(logo, { width: maxWidth })}
        alt={`${broker?.name || 'Broker'} Logo`}
      />
    </LogoContainer>
  )
}

export default BrokerLogo
