import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { colors } from 'styles'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Row, Group, PasswordInput, FieldInput, Form } from 'components/form'
import { Button, TextButton as LinkButton } from 'components/button'
import Tool from 'components/tools'
import { useTranslation } from 'react-i18next'
import useIsMounted from 'hooks/use-is-mounted'
import * as Yup from 'yup'
import {
  LoginContainer,
  TaskbarContainer,
  TaskbarItem,
  Title
} from './elements'

const DescriptionText = styled.p`
  margin: 30px 20px;
`

const PasswordGroup = styled(Group)`
  margin-bottom: 15px;
`

const LoginForm = styled(Form)`
  width: 100%;
  padding: 0 30px;
`

const ButtonContainer = styled(Group)`
  text-align: center;
  margin: 10px auto 15px;
`

const LoginWithPhone = styled(Group)`
  padding-top: 16px;
  margin-bottom: 4px;
  text-align: left;
  border-top: 1px solid ${colors.grey};
  display: flex;
  justify-content: center;
`

const NewText = styled.span`
  font-size: 15px;
`

const CenterRow = styled(Row)`
  justify-content: center;
`

const ForgotRow = styled(CenterRow)`
  margin-top: 10px;
  margin-bottom: 25px;
`

const TextButton = styled(LinkButton)`
  font-size: 15px;
`

const OrText = styled.span`
  font-size: 14px;
`

const LoginButtonContainer = styled(ButtonContainer)`
  margin-top: 5px;
`

const Login = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const [lastButtonPressed, setLastButton] = useState('autologin')

  const showError = () => setLastButton('autologin')

  const autologin = ({ username }) => async () => {
    const { email, error } = await dispatch.auth.requestAutoLogin({ username })
    if (error) {
      setLastButton('autologin')
    } else {
      history.push({ pathname: 'check-email', state: { email } })
    }
  }

  const login = async (values, { setSubmitting }) => {
    const { error } = await dispatch.auth.login(values)
    if (isMounted.current) {
      if (error) setLastButton('login')
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().phoneEmail(),
    password: Yup.string().required()
  })

  return (
    <>
      <Helmet>
        <title>{t('b.auth.view.account_login.title', 'Account Login')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LoginContainer>
        <TaskbarContainer>
          <TaskbarItem left={<Tool to="/" type="back" />} />
          <Title>{t('b.auth.view.account_login.title', 'Account Login')}</Title>
        </TaskbarContainer>
        <DescriptionText>
          {t(
            'b.auth.view.login_description.text',
            'Tired of typing your password? Enter your phone/email to receive an auto-login link!'
          )}
        </DescriptionText>
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={login}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid, values, errors, touched }) => (
            <LoginForm>
              <Row>
                <Group col={12}>
                  <FieldInput
                    name="username"
                    autoFocus
                    placeholder={t(
                      'b.auth.view.phone_email.label',
                      'Enter Phone / Email'
                    )}
                  />
                </Group>
              </Row>
              <Row>
                <PasswordGroup col={12}>
                  <PasswordInput
                    name="password"
                    placeholder={t('b.auth.view.password.label', 'Password')}
                    hideError={lastButtonPressed === 'autologin'}
                  />
                </PasswordGroup>
              </Row>
              <Row>
                <LoginButtonContainer col={12}>
                  <Button
                    id="web-login-submit-button"
                    theme="primary"
                    width="100%"
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    radius={2}
                  >
                    {t('b.auth.view.login.button', 'Log In')}
                  </Button>
                </LoginButtonContainer>
              </Row>
              <CenterRow>
                <OrText>{t('b.auth.view.login_or.text', 'OR')}</OrText>
              </CenterRow>
              <CenterRow>
                <ButtonContainer col={12}>
                  <TextButton
                    id="web-autologin-submit-button"
                    width="100%"
                    type="button"
                    onClick={
                      !errors['username'] ? autologin(values) : showError
                    }
                  >
                    {t(
                      'b.auth.view.autologin.button',
                      'Email me the auto-login link'
                    )}
                  </TextButton>
                </ButtonContainer>
              </CenterRow>
              <Row>
                <LoginWithPhone>
                  <NewText>
                    {t('b.auth.view.new_to_liv_rent.text', 'New to liv.rent?')}{' '}
                    <TextButton to="/auth/signup" as={Link}>
                      {t('b.auth.view.signup_now.button', 'Sign up now!')}
                    </TextButton>
                  </NewText>
                </LoginWithPhone>
              </Row>
              <ForgotRow>
                <TextButton
                  to="/auth/login/reset"
                  as={Link}
                  data-testid="reset-password"
                  replace
                >
                  {t('b.auth.view.forgot_password.link', 'Forgot password?')}
                </TextButton>
              </ForgotRow>
            </LoginForm>
          )}
        </Formik>
      </LoginContainer>
    </>
  )
}

export default Login
