import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles'
import { Button } from 'components/anchor'
import SelectorItemBase from './selector-item-base'

const RemoveButton = styled(Button)`
  color: ${colors.link};
`

const CheckboxSelectorItem = ({
  name,
  type,
  value,
  onRemove,
  children,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <SelectorItemBase {...rest}>
      {children}
      <RemoveButton type="button" onClick={onRemove} value={value}>
        {t('common.remove', 'Remove')}
      </RemoveButton>
    </SelectorItemBase>
  )
}

export default React.memo(CheckboxSelectorItem)
