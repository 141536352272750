import React from 'react'
import styled from 'styled-components'
import { breakpoints, zIndex } from 'styles'
import useHasHeightChanged from 'hooks/use-has-height-changed'

const Wrapper = styled.div`
  height: ${props => props.height || 90}px;
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
  z-index: ${zIndex.buttonBar};

  ${props =>
    props.sticky &&
    `
    position: ${props.pushDown ? 'static' : 'sticky'};
    left: 0;
    right: 0;
    bottom: 0;
  `}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: unset;
  }

  @media print {
    display: none;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: ${props => props.height || 90}px;
  max-width: 1045px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
`

const ButtonBar = ({ children, height, pushDownOnMobile = true, ...rest }) => {
  const hasHeightChanged = useHasHeightChanged()
  const pushDown = pushDownOnMobile && hasHeightChanged

  return (
    <Wrapper pushDown={pushDown} height={height} {...rest}>
      <InnerWrapper height={height}>{children}</InnerWrapper>
    </Wrapper>
  )
}

export default ButtonBar
