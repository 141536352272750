import ENV_VARS from 'config'
import { get, post, put } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const verifyPhoneSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/phone`
)
export const validatePhoneSignup = post(
  `${BACKEND_SERVER}/auth/registration/verify/code`
)

export const login = post(`${BACKEND_SERVER}/auth/login`)
export const requestAutoLogin = post(`${BACKEND_SERVER}/auth/login/auto`)
export const verifyPhoneLogin = post(
  `${BACKEND_SERVER}/auth/login/verify/phone`
)
export const verifyCodeLogin = post(`${BACKEND_SERVER}/auth/login/verify/code`)
export const setInitialPassword = put(`${BACKEND_SERVER}/auth/password/initial`)

export const requestResetPassword = post(
  `${BACKEND_SERVER}/auth/password/reset_request`
)
export const verifyResetPasswordHash = get(
  `${BACKEND_SERVER}/auth/password/reset_request/%hash%`,
  { failSilently: true }
)
export const resetPassword = put(
  `${BACKEND_SERVER}/auth/password/reset_request/%hash%`
)
export const changePassword = put(`${BACKEND_SERVER}/auth/password`)
export const autoLogin = post(`${BACKEND_SERVER}/users/otp/%loginHash%`)
export const register = put(`${BACKEND_SERVER}/users/me`)
export const logout = post(`${BACKEND_SERVER}/users/logout`)
