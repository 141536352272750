import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import fileAsDataUrl from 'utils/file-as-data-url'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import { FieldDropdown, Row, Group, Label } from 'components/form'
import { showImageModal } from 'components/image-modal'
import UploadButton from 'components/upload-button'
import ENV_VARS from 'config'
import { confirm } from 'components/dialog'
import { show as showImageEditModal } from 'components/image-edit-modal'
import getLabels from 'features/Profile/EmploymentInfo/labels'

const { BACKEND_SERVER } = ENV_VARS

const StyledRow = styled(Row)`
  margin-top: 30px;
  align-items: flex-end;
`

const StyledGroup = styled(Group)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
`

const ViewButtonWrapper = styled.div`
  margin-top: ${props => (props.noLabel ? 0 : 10)}px;
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: flex-end;
  align-items: center;
`

const PanelText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  margin: 10px 0;
  padding-left: 15px;
`

const DeleteButton = styled.button`
  color: ${colors.red};
  background-color: ${colors.white};
  border: none;
  margin-right: 10px;
  font-size: ${fontSizes.small};
  outline: none;
  cursor: pointer;
  :active {
    opacity: 0.5;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-right: 5px;
  }
`

const ViewButton = styled.p`
  padding: 8px 18px;
  margin: 0px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  min-width: 83px;
  border: 2px solid ${colors.regular};
  cursor: pointer;
  :hover {
    border-color: ${colors.secondary};
    color: ${colors.secondary};
  }
  :active {
    opacity: 0.5;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 8px 10px;
    min-width: 65px;
  }
`

const UploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 1px solid ${colors.mediumGrey};
  height: 50px;
  width: 100%;
`

const ProvideText = styled.p`
  padding-left: 10px;
`

const StyledUploadButton = styled(UploadButton)`
  width: 100px;
  align-self: flex-end;
  background: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  padding-right: 0;

  :hover {
    color: ${colors.link};
    border: none;
  }
`

const UploadIncomeForm = ({ values, setFieldValue }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  const LABELS = getLabels(t)

  const incomeFile = values.income_verification

  const handleDeleteClick = ({ fileId, tag }) => {
    return confirm(
      t('r.profile.edit.delete_document.title', 'Delete Document?'),
      t(
        'r.profile.edit.delete_document_confirmation.text',
        'Are you sure you want to delete this document?'
      )
    ).then(async () => {
      await dispatch.livScore.deleteFile({
        fileId,
        tag
      })
      setFieldValue(tag, '')
    })
  }

  return (
    <>
      {!incomeFile.id && (
        <StyledRow gutter={10}>
          <Group col={colSize}>
            <FieldDropdown
              showError={false}
              id="income_document_type"
              name="income_document_type"
              placeholder={
                t(
                  'r.profile.edit.liv_score_upload_verified_id.label',
                  'Document Type'
                ) + '*:'
              }
              options={[
                {
                  label: t('r.profile.view.t4.text', 'T4'),
                  value: {
                    tag: 'income_verification',
                    type: 't4'
                  }
                },
                {
                  label: t('r.profile.view.tax_slip.text', 'Tax Slips'),
                  value: {
                    tag: 'income_verification',
                    type: 'tax_slip'
                  }
                },
                {
                  label: t('r.profile.view.pay_slip.text', 'Pay Slip'),
                  value: {
                    tag: 'income_verification',
                    type: 'payslip'
                  }
                },
                {
                  label: t(
                    'r.profile.view.student_loans.text',
                    'Student Loans'
                  ),
                  value: {
                    tag: 'income_verification',
                    type: 'student_loan'
                  }
                },
                {
                  label: t(
                    'r.profile.view.bank_statement.text',
                    'Bank Statement'
                  ),
                  value: {
                    tag: 'income_verification',
                    type: 'bank_statement'
                  }
                },
                {
                  label: t(
                    'r.profile.view.job_offer_letter.text',
                    'Job Offer Letter'
                  ),
                  value: {
                    tag: 'income_verification',
                    type: 'job_offer'
                  }
                }
              ]}
            />
          </Group>
          {incomeFile ? (
            <>
              <Label>&nbsp;</Label>
              <ViewButtonWrapper>
                <DeleteButton
                  type="button"
                  onClick={() => setFieldValue('income_verification', '')}
                >
                  {t('r.profile.edit.credit_check_delete_doc.button', 'delete')}
                </DeleteButton>
                <ViewButton
                  onClick={() =>
                    showImageModal({
                      src: values?.income_verification
                    })
                  }
                >
                  {t('r.profile.edit.credit_check_view_doc.button', 'View')}
                </ViewButton>
              </ViewButtonWrapper>
            </>
          ) : (
            <Group col={colSize}>
              <Label>&nbsp;</Label>
              <UploadWrapper>
                <ProvideText>
                  {t(
                    'b.components.dropdown.photo_of_doc.placeholder',
                    'Photo of Document'
                  )}
                </ProvideText>
                <StyledUploadButton
                  showError={false}
                  id="income_upload_input"
                  data-cy="upload_income_document"
                  name="income_verification"
                  onDropAccepted={async files =>
                    showImageEditModal({
                      initialImageSrc: await fileAsDataUrl(files[0]),
                      onSave: imageDataUrl =>
                        setFieldValue('income_verification', imageDataUrl)
                    })
                  }
                  disabled={!values?.income_document_type.tag}
                  floatError
                />
              </UploadWrapper>
            </Group>
          )}
        </StyledRow>
      )}
      {incomeFile.id && (
        <Row top={20} gutter={10}>
          <StyledGroup col={colSize}>
            <PanelText>{LABELS[incomeFile.document_type]}</PanelText>
          </StyledGroup>
          <Group col={colSize}>
            <Label>&nbsp;</Label>
            <ViewButtonWrapper noLabel>
              <DeleteButton
                type="button"
                onClick={() =>
                  handleDeleteClick({
                    fileId: incomeFile.id,
                    tag: incomeFile.tag
                  })
                }
              >
                {t('r.profile.edit.credit_check_delete_doc.button', 'delete')}
              </DeleteButton>
              <ViewButton
                onClick={() =>
                  showImageModal({
                    src: `${BACKEND_SERVER}/secure/${incomeFile?.tag}/${incomeFile.id}/form.png`
                  })
                }
              >
                {t('r.profile.edit.credit_check_view_doc.button', 'View')}
              </ViewButton>
            </ViewButtonWrapper>
          </Group>
        </Row>
      )}
    </>
  )
}

export default UploadIncomeForm
