import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LabeledDetail from 'components/labeled-detail'
import useMediaQuery from 'hooks/use-media-query'
import { useFlag } from 'flags'
import { breakpoints } from 'styles'
import { Group, Row } from 'components/form'
import Moment from 'react-moment'
import { SubtitleText, DetailsWrapper, ContactNameWrapper } from '../elements'

const LandlordRow = styled(Row)`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 0;
  }
`

const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: ${({ top }) => top || '0px'};
`

const StyledGroup = styled(Group)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 0;
    margin-bottom: 0;
  }
`

const StyledContactNameWrapper = styled(ContactNameWrapper)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px 0 20px;
  }
`

const StyledLabeledDetail = styled(LabeledDetail)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 0;
  }
`

const renderAddress = data => {
  const address = data.suite_number
    ? `${data.suite_number} - ${data.street_address}`
    : data.street_address
  return `${address} ${data.city} ${data.state} ${data.zip?.toUpperCase()}`
}

const LandlordReferenceView = ({ data }) => {
  const [t] = useTranslation(['translation', 'basic'])
  const showNewProfile = useFlag(['features', 'newProfile'])
  const widthPercent = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? `100%`
    : `50%`

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 5

  const infoColSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 7

  return (
    <ListWrapper>
      <LandlordRow>
        <StyledGroup col={colSize}>
          <StyledContactNameWrapper>
            <SubtitleText>
              {data.first_name} {data.last_name}
            </SubtitleText>
          </StyledContactNameWrapper>
        </StyledGroup>
        <StyledGroup col={infoColSize}>
          <DetailsWrapper>
            <StyledLabeledDetail
              label={t('r.profile.view.phone_number.label', 'Phone Number')}
              value={data.phone_number}
              width={widthPercent}
            />
            <StyledLabeledDetail
              label={t('r.profile.view.email.label', 'Email')}
              value={data.email || '-'}
              width={widthPercent}
            />
          </DetailsWrapper>
        </StyledGroup>
      </LandlordRow>
      {showNewProfile && (
        <>
          <LandlordRow>
            <LabeledDetail
              label={t(
                'r.profile.edit.previous_rental_address.subtitle',
                'Previous Rental Address'
              )}
              value={
                data.extra_info?.street_address
                  ? renderAddress(data.extra_info)
                  : '-'
              }
              width="100%"
            />
          </LandlordRow>
          <LandlordRow>
            <LabeledDetail
              label={t(
                'r.profile.edit.previous_rental_duration.subtitle',
                'Tenancy Start & End Dates'
              )}
              value={
                data.extra_info?.start_date ? (
                  <>
                    <Moment format="YYYY-MM-DD">
                      {data.extra_info?.start_date}
                    </Moment>
                    {' - '}
                    {data.extra_info?.end_date ? (
                      <Moment format="YYYY-MM-DD">
                        {data.extra_info.end_date}
                      </Moment>
                    ) : (
                      t('r.profile.view.current_date.text', 'Current')
                    )}
                  </>
                ) : (
                  '-'
                )
              }
              width="100%"
            />
          </LandlordRow>
          <LandlordRow>
            <LabeledDetail
              label={t(
                'r.profile.edit.rental_history_reason.label',
                'Reason for leaving'
              )}
              value={
                data.extra_info?.reason_for_leaving
                  ? data.extra_info?.reason_for_leaving
                  : '-'
              }
              width="100%"
            />
          </LandlordRow>
        </>
      )}
    </ListWrapper>
  )
}

export default React.memo(LandlordReferenceView)
