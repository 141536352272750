import React, { useRef } from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import useFocusOnError from 'hooks/use-focus-on-error'
import { colors } from 'styles'
import { upperFirst } from 'lodash'
import { Input } from 'components/form'
import Icon from 'components/icon'

const Wrapper = styled.div`
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  &:last-of-type {
    border-bottom: none;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.height || '65px'};
  min-height: 65px;

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    & [type="radio"]:disabled,
    & [type="checkbox"]:disabled {
    cursor: default;
    opacity: 1;
  }
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background: ${colors.white};
  border: 1px solid ${colors.grey};
  border-right: 0;
  flex: 1;
  padding: 0 10px;
  cursor: pointer;
  outline: none;

  &:last-child {
    border-right: 1px solid ${colors.grey};
  }

  p {
    font-size: 11px;
    color: ${colors.regular};
    line-height: 13px;
    margin: 0;
  }

  svg {
    display: none;
    margin-top: 2px;
    margin-left: 5px;
  }

  ${props =>
    props.selected &&
    `
    background: ${colors.darkGrey};
    border: none;

    p { color: ${colors.white}; }
    svg { 
      display: block;
      g { stroke: ${colors.white}; }
    }
  `}
`

const SharedSelectorItem = ({
  name,
  currentType,
  type,
  options = ['private', 'shared'],
  value,
  featureName,
  checked,
  onChange,
  onTypeChange,
  showOptions,
  children,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation()
  const [field] = useField(name)
  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })

  return (
    <Wrapper disabled={disabled} {...rest}>
      <InputWrapper disabled={disabled}>
        {children}
        <Input
          ref={fieldRef}
          type={type}
          checked={checked}
          {...field}
          value={value}
          disabled={disabled}
          onChange={onChange || field.onChange}
        />
      </InputWrapper>
      {checked && showOptions && (
        <ButtonWrapper>
          {options.map(option => (
            <Button
              key={option}
              type="button"
              selected={currentType === option}
              onClick={() => onTypeChange(value, option)}
            >
              <p>
                {t(
                  `b.listing_features.view.${option}.label`,
                  upperFirst(option)
                )}
              </p>
              <Icon id="small_check" height={11} width={11} />
            </Button>
          ))}
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

export default React.memo(SharedSelectorItem)
