import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import { toUnit } from 'utils/styles'
import Anchor from './anchor'

const AnchorButton = styled(Anchor)`
  background-color: ${colors.secondary};
  color: ${colors.white};
  border: 1px solid ${colors.secondary};
  font-size: 14px;
  height: ${props => toUnit(props.height || 40)};
  width: ${props => toUnit(props.width || 'auto')};
  font-weight: ${fontWeights.medium};
  border-radius: ${props => toUnit(props.radius || 0)};

  ${({ outline }) =>
    !!outline &&
    `
    background-color: ${colors.white};
    color: ${colors.secondary};
    border: 2px solid ${colors.secondary};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${colors.white};
    color: ${colors.secondary};

    ${({ outline }) =>
      !!outline &&
      `
      background-color: ${colors.secondary};
      color: ${colors.white};
    `}
  }

  ${({ disabled }) =>
    !!disabled &&
    `
    cursor: default;
    opacity: 0.3;
    pointer-events: none;
  `}
`

AnchorButton.defaultProps = {
  outline: undefined
}

export default AnchorButton
