import React from 'react'
import NumberFormat from 'react-number-format'
import { toNumber } from 'lodash'

const CurrencyFormatter = ({ value, showCents = false, ...rest }) => (
  <NumberFormat
    value={toNumber(value)}
    displayType="text"
    thousandSeparator
    prefix="$"
    decimalScale={2}
    fixedDecimalScale={showCents}
    {...rest}
  />
)

export default CurrencyFormatter
