import {
  always,
  set,
  lensPath,
  pipe,
  path,
  mergeLeft,
  when,
  evolve,
  complement,
  propEq,
  pick,
  map,
  values,
  append,
  reject,
  pickAll,
  ifElse,
  either,
  prop,
  concat,
  omit,
  isEmpty,
  dissocPath
} from 'ramda'
import { toInteger, toNumber, round } from 'lodash'
import remove from 'utils/remove'
import { batch } from 'react-redux'
import selectorBuilder from 'models/selector-builder'
import uniquePush from 'utils/unique-push'
import { mergeSpec, defaultIfFalsy, isFalsy, isTruthy, renameKeys } from 'utils'
import { toast } from 'components/toast-notifications'
import {
  getContractTerms,
  sendSignature,
  sendRoomateSignature,
  getTenants,
  getGroupedTenants,
  getContracts,
  getContract,
  cancelContract,
  exportContract,
  updateContract,
  createContract,
  sendContract,
  renewContract,
  createAddendum,
  deleteAddendum,
  loadAddendumTemplates
} from './ports'
import {
  getContractSelector,
  contractDataSelector,
  contractViewSelector,
  activeContractsSelector,
  termsSelector,
  isStepCompleteSelector,
  listingContractsSelector,
  groupListingContractsSelector,
  nextContractSelector,
  lastActiveContractSelector
} from './selectors'

const INITIAL_STATE = {
  terms: {},
  completedSteps: [],
  signature: null
}

const parseFeeFrequency = ifElse(
  prop('enabled'),
  ifElse(
    prop('hasFee'),
    pipe(prop('fee_frequency_txt_id'), defaultIfFalsy('free')),
    always('free')
  ),
  always('not_available')
)

const parseFees = pipe(
  data =>
    pipe(
      prop('additional_fees'),
      append(data.parking || { fee_txt_id: 'parking' }),
      append(data.storage || { fee_txt_id: 'storage' })
    )(data),
  map(
    pipe(
      evolve({
        fee_txt_id: defaultIfFalsy('misc'),
        fee: toNumber
      }),
      when(
        complement(propEq('fee_txt_id', 'misc')),
        mergeSpec({
          fee: pipe(
            ifElse(pipe(prop('hasFee'), isTruthy), prop('fee'), always(0))
          ),
          fee_frequency_txt_id: parseFeeFrequency
        })
      ),
      pick(['fee', 'fee_txt_id', 'name', 'fee_frequency_txt_id'])
    )
  ),
  reject(fee => isFalsy(prop('fee_frequency_txt_id', fee)))
)

const parseAddendums = pipe(
  map(
    pipe(
      pick([
        'listing_contract_addendum_template_txt_id',
        'allowed',
        'listing_contract_predefined_addendum_options'
      ]),
      evolve({
        allowed: toInteger,
        listing_contract_predefined_addendum_options: pipe(
          map(option => ({
            listing_contract_addendum_template_option_txt_id:
              option.listing_contract_addendum_template_option_txt_id
          })),

          values
        )
      })
    )
  ),
  values
)

const parseEndAction = pipe(
  ifElse(
    either(
      propEq('lease_type', 'month_to_month'),
      pipe(prop('lease_end_action'), isFalsy)
    ),
    always(null),
    pipe(prop('lease_end_action'))
  )
)

const parseTenant = (data = {}) =>
  map(
    pipe(
      pick(['id']),
      renameKeys({
        id: 'user_id'
      }),
      evolve({
        user_id: toInteger
      }),
      mergeLeft(data)
    )
  )

const parseExtraUsers = pipe(
  prop('contract_extra_users'),
  map(
    pipe(
      pickAll([
        'first_name',
        'last_name',
        'dob',
        'email_address',
        'phone_number'
      ]),
      evolve({
        email_address: defaultIfFalsy(undefined),
        phone_number: defaultIfFalsy(undefined)
      })
    )
  )
)

const parseSigningTenants = parseTenant({ signing_required: 1 })
const parseNonSigningTenants = parseTenant({ signing_required: 0 })

const parseContractRentals = a =>
  a.contract_signing_tenants || a.contract_non_signing_tenants
    ? concat(
        parseSigningTenants(a.contract_signing_tenants || []),
        parseNonSigningTenants(a.contract_non_signing_tenants || [])
      )
    : map(
        pipe(
          renameKeys({ signature_required: 'signing_required' }),
          pick(['user_id', 'signing_required']),
          evolve({
            user_id: toNumber,
            signing_required: toNumber
          })
        ),
        a.contract_rentals
      )

const formatContractPayload = pipe(
  mergeSpec({
    contract_rentals: parseContractRentals,
    contract_fees: parseFees,
    lease_end_action: parseEndAction,
    contract_extra_users: parseExtraUsers
  }),
  pick([
    'contract_addendums',
    'contract_custom_utilities',
    'contract_custom_features',
    'contract_extra_users',
    'contract_features',
    'contract_fees',
    'contract_landlords',
    'contract_predefined_addendums',
    'contract_rentals',
    'contract_utilities',
    'end_date',
    'extra_offer',
    'extra_offer_frequency',
    'lease_end_action',
    'lease_type',
    'listing_id',
    'pet_deposit',
    'pet_policy',
    'allow_cats',
    'allow_dogs',
    'price',
    'price_frequency',
    'security_deposit',
    'start_date',
    'strata_lot_number',
    'strata_plan_number'
  ]),
  evolve({
    contract_addendums: map(pick(['description', 'title'])),
    contract_landlords: pipe(
      map(user => ({
        user_id: toNumber(user.user_id) || toNumber(user.id)
      }))
    ),
    contract_predefined_addendums: parseAddendums,
    end_date: defaultIfFalsy(null),
    extra_offer: toInteger,
    extra_offer_frequency: defaultIfFalsy(null),
    listing_id: toInteger,
    pet_deposit: toInteger,
    pet_policy: toInteger,
    allow_cats: toInteger,
    allow_dogs: toInteger,
    price: toInteger,
    security_deposit: x => round(toNumber(x), 2),
    start_date: defaultIfFalsy(null)
  })
)

const contract = {
  state: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    remove: (state, at) => dissocPath(at, state),
    update: (state, payload) => ({ ...state, ...payload }),
    updateAt: (state, at, payload) => set(lensPath(at), payload, state),
    patch: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), mergeLeft(payload))(state), state),
    updateTerms: (state, payload) => ({
      ...state,
      terms: { ...state.terms, ...payload }
    }),
    completeStep: (state, payload) => ({
      ...state,
      completedSteps: uniquePush(payload, state.completedSteps)
    }),
    incompleteStep: (state, payload) => ({
      ...state,
      completedSteps: remove(payload, state.completedSteps)
    }),
    clearSteps: state => ({ ...state, completedSteps: [], signature: null })
  },

  effects: dispatch => ({
    load: async (contractId, rootState) => {
      if (rootState.contract[contractId]) return {}
      try {
        const { response, body } = await getContract({ contractId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.contract.patch([contractId], body)
        return body
      } catch (error) {
        return error
      }
    },
    create: async data => {
      const payload = formatContractPayload(data)
      try {
        const { response, body } = await createContract(
          { body: payload },
          {
            listingId: payload.listing_id
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.contract.patch([body.id], body)
          dispatch.listing.remove([body.listing_id, 'contract'])
        })
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    save: async ({ contractId, ...data }) => {
      const payload = formatContractPayload(data)
      try {
        const { response, body } = await updateContract(
          { body: payload },
          {
            contractId
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.listing.patch([data.listing_id, 'contract'], body)
          dispatch.contract.patch([contractId], body)
        })

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    send: async ({ contractId, listingId }) => {
      try {
        const { response, body } = await sendContract(
          {},
          {
            contractId
          }
        )
        if (response.ok) {
          toast('The contract has been successfully sent.', {
            title: 'Contract Sent!',
            iconId: 'square_check',
            autoClose: 6000
          })
        }
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.listing.updateAt(
            [listingId, 'contract', 'state_machine'],
            'sent'
          )
          dispatch.contract.updateAt([contractId, 'state_machine'], 'sent')
        })
      } catch (error) {
        return { error }
      }
    },
    async cancelContract({ contractId, listingId }) {
      try {
        const { response, body } = await cancelContract(undefined, {
          contractId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        batch(() => {
          dispatch.listing.updateAt(
            [listingId, 'contract', 'state_machine'],
            'draft'
          )
          dispatch.contract.updateAt([contractId, 'state_machine'], 'draft')
        })

        return { response, body }
      } catch (error) {
        return { error }
      }
    },

    async renewContract({ contractId, ...data }) {
      const payload = formatContractPayload(data)
      try {
        const { response, body } = await renewContract(
          { body: payload },
          {
            contractId
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.listing.patch([data.listing_id, 'contract'], body)
          dispatch.contract.patch([contractId], body)
        })
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async getContracts(_, state) {
      const contracts = omit([
        'completedSteps',
        'signature',
        'terms',
        'listing_contract_addendum_templates'
      ])(state.contract)
      if (!isEmpty(contracts)) return
      try {
        const { response, body } = await getContracts()
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.contract.update(body)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async getTenants() {
      try {
        const { response, body } = await getTenants()
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error, body: [] }
      }
    },
    async getGroupedTenants() {
      try {
        const { response, body } = await getGroupedTenants()
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { response, body }
      } catch (error) {
        return { error, body: [] }
      }
    },
    async export(contractId) {
      try {
        const { response, body } = await exportContract({ contractId })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { body }
      } catch (error) {
        return { error }
      }
    },
    async getTerms(listingId, rootState) {
      if (path(['contract', 'terms', listingId], rootState)) return
      try {
        const response = await getContractTerms({ listingId })

        if (response.response.ok) {
          dispatch.contract.updateTerms({
            [listingId]: response.body.act_terms
          })
        }
      } catch (error) {
        console.error('[contract/getTerms]', error)
      }
    },

    async signContract(contractId, rootState) {
      const filteredSignature = rootState.contract.signature
      try {
        const response = await sendSignature(
          { body: { signature_base64_data: filteredSignature.trim() } },
          { contractId }
        )

        if (response.response.ok) {
          dispatch.contract.patch([response.body.id], response.body)
        }
      } catch (error) {
        console.error('[contract/sendSignature]', error)
      }
    },
    async signRoommate(params, rootState) {
      const filteredSignature = rootState.contract.signature
      try {
        const response = await sendRoomateSignature(
          { body: { signature_base64_data: filteredSignature.trim() } },
          params
        )

        if (response.response.ok) {
          dispatch.contract.patch([response.body.id], response.body)
        }
      } catch (error) {
        console.error('[contract/sendRoomate]', error)
      }
    },
    async loadAddendumTemplates(_, rootState) {
      if (rootState.contract.contractlisting_contract_addendum_templates) return
      try {
        const { response, body } = await loadAddendumTemplates()
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        await dispatch.contract.patch(
          ['listing_contract_addendum_templates'],
          body.listing_contract_addendum_templates
        )
      } catch (error) {
        console.error('[contract/loadAddendumTemplates]', error)
      }
    },
    async createAddendum({ contractId, title, description }) {
      try {
        const { response, body } = await createAddendum(
          { body: { title, description } },
          {
            contractId
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.contract.updateAt(
          [contractId, 'contract_addendums'],
          body.contract_addendums
        )
      } catch (error) {
        console.error('[contract/createAddendum]', error)
      }
    },
    async deleteAddendum({ contractId, addendumId }) {
      try {
        const { response, body } = await deleteAddendum(undefined, {
          contractId,
          addendumId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.contract.updateAt(
          [contractId, 'contract_addendums'],
          body.contract_addendums
        )
      } catch (error) {
        console.error('[contract/deleteAddendum]', error)
      }
    },
    parseListingContractCanceledSSE(message) {
      dispatch.contract.remove([message.id])
    },
    parseListingContractSendSSE(message, rootState) {
      if (rootState.contract[message.contract_id]) {
        dispatch.contract.remove([message.contract_id])
        dispatch.contract.load(message.contract_id)
      }
    }
  }),
  selectors: selectorBuilder((slice, createSelector) => ({
    contractDetail(models) {
      return createSelector(
        slice(getContractSelector),
        models.listing.contractData,
        models.application.contractData,
        contractDataSelector
      )
    },
    contractView() {
      return slice(contractViewSelector)
    },
    activeContracts() {
      return slice(activeContractsSelector)
    },
    terms() {
      return slice(termsSelector)
    },
    isStepComplete() {
      return slice(isStepCompleteSelector)
    },
    listingContracts() {
      return slice(listingContractsSelector)
    },
    groupListingContracts() {
      return slice(groupListingContractsSelector)
    },
    nextContract() {
      return slice(nextContractSelector)
    },
    lastActiveContract() {
      return slice(lastActiveContractSelector)
    }
  }))
}

export default contract
