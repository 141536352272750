import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, fontSizes } from 'styles'
import isTruthy from 'utils/is-truthy'
import { createModal, useModalize } from 'components/modalize'
import SignaturePad from 'components/signature-pad'
import { Button } from 'components/button'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 25px 15px;
  }
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    justify-content: space-between;
  }
`

const ActionButton = styled(Button)`
  margin: 0;
  height: 55px;
  width: 180px;
  font-size: ${fontSizes.regular};
  letter-spacing: 0px;

  &:first-child {
    margin-right: 10px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 45px;
    width: 125px;
    font-size: 14px;
  }
`

const SignatureModal = props => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const saveSignature = props.onSave
  const signRef = React.createRef()

  const handleSaveSignature = () => {
    const signature = signRef.current.sendSignature()

    if (isTruthy(signature)) {
      saveSignature({ signature })
      close()
    }
  }

  const handleResetSignature = () => {
    signRef.current.reset()
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <SignaturePad ref={signRef} onClose={close} />
      </ScrollWrapper>
      <ButtonWrapper>
        <ActionButton
          theme="neutral"
          type="button"
          onClick={handleResetSignature}
        >
          {t('b.contract.view.reset.button', 'Reset')}
        </ActionButton>
        <ActionButton
          theme="primary"
          type="button"
          onClick={handleSaveSignature}
        >
          {t('b.contract.view.done.button', 'Done')}
        </ActionButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export const showSignatureModal = createModal(SignatureModal, {
  width: '768px'
})
