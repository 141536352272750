import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 40px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0 0 10px 0;
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: 40px;
`

const NoPaymentMethodModal = ({ history }) => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const handleGoToSettings = () => {
    history.push('/settings/payment-settings')
    close()
  }

  const buttonText = t(
    'l.payment.view.payment_settings.button',
    'Payment Settings'
  )

  return (
    <Wrapper
      role="dialog"
      aria-labelledby="add-payment-dialog-title"
      aria-describedby="add-payment-dialog-message"
    >
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Icon id="warning" width={75} height={75} />
        <ModalTitle id="add-payment-dialog-title">
          {t('l.payment.view.get_paid.title', 'Get Paid')}
        </ModalTitle>
        <ModalText id="add-payment-dialog-message">
          {t(
            'l.payment.view.no_payment_method_modal.text',
            'No payment method added yet. Set up your payment method now to get paid!'
          )}
        </ModalText>
        <StyledButton
          aria-label={buttonText}
          theme="primary"
          width={150}
          onClick={handleGoToSettings}
        >
          {buttonText}
        </StyledButton>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(NoPaymentMethodModal)
