import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { select } from 'store'
import {
  props,
  map,
  mergeLeft,
  pathOr,
  pick,
  pickAll,
  pipe,
  prop,
  defaultTo,
  join,
  flatten
} from 'ramda'
import formatContent from 'utils/format-content'
import { renameKeys } from 'utils'

const Wrapper = styled.div`
  .leftside {
    text-align: left !important;
    width: 50% !important;
  }
`

const formatName = userInfo =>
  `</br>${userInfo.last_name}, ${userInfo.first_name} ( ${userInfo.phone_number} )`

const getFullNames = map(
  pipe(pick(['first_name', 'last_name', 'phone_number']), formatName)
)

const joinIfDefined = pipe(defaultTo([]), join(', '))

const getTenants = pipe(
  props(['contract_non_signing_tenants', 'contract_signing_tenants']),
  flatten,
  getFullNames,
  joinIfDefined
)
const getLandlords = pipe(
  prop('contract_landlords'),
  getFullNames,
  joinIfDefined
)

const getVariables = (listing, contract) =>
  pipe(
    pickAll(['strata_lot_number', 'strata_plan_number', 'start_date']),
    renameKeys({
      strata_lot_number: 'strataLot',
      strata_plan_number: 'strataPlan',
      start_date: 'tenancyStartDate'
    }),
    mergeLeft({
      unitAddress: pathOr('', ['building', 'full_street_name'], listing),
      formKTenantInfo: getTenants(contract),
      landlordNames: getLandlords(contract),
      companyName: '("Landlord(s)")',
      managingInfo: '',
      rentalUnitAddress: pathOr('', ['building', 'full_street_name'], listing)
    })
  )(contract)

const Content = ({ addendum, contract, listingId }) => {
  const listing = useSelector(state =>
    select.listing.listingDetail(state, { listingId })
  )

  const variables = getVariables(listing, contract)

  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        __html: formatContent(addendum.content, variables)
      }}
    />
  )
}

export default Content
