import React from 'react'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import PageTitle from 'components/page-title'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { pipe, prop, reject, join, isEmpty, values, head, path } from 'ramda'
import useMediaQuery from 'hooks/use-media-query'
import useProfile from 'hooks/use-profile'
import { isFalsy } from 'utils'
import Panel from 'components/panel'
import Page from 'components/page'
import { AvatarPicker, defaultAvatar } from 'components/avatar'
import { formatPhone } from 'utils/format-phone'
import { getImageUrl } from 'utils/image'
import VerifiedBadge from 'components/verified-badge'
import styled, { createGlobalStyle } from 'styled-components'
import BrokerLogo from 'components/broker-logo'
import ContentWrapper from 'components/content-wrapper'

const MobileBackground = createGlobalStyle`
  body {
    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      background: ${colors.white};
    }
  }
`

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
    border: none;
    padding-top: 0;
  }
`

const UpperProfile = styled.div`
  height: 128px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
    height: 80px;
  }
`

const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin-right: auto;
  margin-bottom: 20px;
`

const SectionTitle = styled.h3`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    margin-top: 10px;
    padding-top: 20px;
    border-top: 1px solid ${colors.grey};
  }
`

const EditButton = styled.button`
  height: 28px;
  width: 60px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 20px;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
`

const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 0;
  grid-row-gap: 30px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-row-gap: 0;
    grid-template-columns: auto;
  }
`

const ProfileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NameWrapper = styled(IconWrapper)`
  margin: 40px 0 20px 0;
  max-width: 300px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`

const NameText = styled.h1`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 31px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: 100%;
  }
`

const PhoneText = styled.p`
  font-size: ${fontSizes.regular};
  margin: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    text-align: center;
  }
`

const ContactText = styled(PhoneText)`
  padding-bottom: 20px;

  ${({ line }) =>
    line &&
    `
    border-bottom: 1px solid ${colors.grey};
    padding-bottom: 30px;
  `};
`

const ProfileDetails = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 25px;

  > :not(:last-child) {
    margin-bottom: 30px;
  }
`

const CompanyDetails = styled(ProfileDetails)`
  margin-top: 0;
  padding-top: 20px;
  border-top: 1px solid ${colors.grey};
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    border: none;
  }
`

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`

const DetailTitle = styled.p`
  color: ${colors.mediumGrey};
  font-size: 12px;
  line-height: 17px;
  margin: 0;
`

const DetailText = styled.p`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  line-height: 19px;
  margin: ${props => props.margin || `10px 0 0 0`};
`

const BrokerText = styled(DetailText)`
  word-break: break-all;
`

const Container = styled.div`
  width: 100%;
  display: flex;
`

const NoBreak = styled.span`
  white-space: pre;
`

const getImage = url => (url ? getImageUrl(url) : defaultAvatar)

const formatAddress = companyInfo => {
  if (!companyInfo?.address) return '-'

  const unit = companyInfo?.unit_number ? `${companyInfo?.unit_number} - ` : ''
  const address = pipe(
    reject(isFalsy),
    join(', ')
  )([
    companyInfo?.address,
    companyInfo?.city,
    companyInfo?.state,
    companyInfo?.zip?.toUpperCase()
  ])
  return `${unit} ${address}`
}

const LandlordProfile = () => {
  const { t } = useTranslation()
  const data = useProfile()
  const brokerInfo = {
    name: path(['company_info', 'broker', 'name'], data),
    logo: pipe(
      values,
      head,
      prop(['location'])
    )(path(['files', 'broker_logo'], data))
  }
  const hasBroker = !!brokerInfo?.name

  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  if (isEmpty(data)) return null

  const handleUploadAvatar = file => {
    dispatch.profile.uploadAvatar({
      file,
      fileId: data.avatar?.id
    })
  }

  const Verified = props => (
    <VerifiedBadge
      type="id"
      width={30}
      height={20}
      left={5}
      bottom={-2}
      verified={data.identity_verified_state}
      {...props}
    />
  )

  const Address = () => (
    <DetailWrapper width={hasBroker ? '50%' : '100%'}>
      <DetailTitle>{t('l.profile.view.address.text', 'Address')}</DetailTitle>
      <DetailText>{formatAddress(companyInfo)}</DetailText>
    </DetailWrapper>
  )

  const BrokerInfo = () => {
    if (!hasBroker) return null
    return (
      <DetailWrapper width="50%">
        <DetailTitle>
          {t('l.profile.view.broker_name.text', 'Brokerage')}
        </DetailTitle>
        <BrokerText>{brokerInfo.name || '-'}</BrokerText>
        <BrokerLogo broker={brokerInfo} />
      </DetailWrapper>
    )
  }

  const AboutMe = () => (
    <DetailWrapper>
      <DetailTitle>{t('l.profile.view.about_me.text', 'About Me')}</DetailTitle>
      <DetailText>{data.about_me || '-'}</DetailText>
    </DetailWrapper>
  )

  const showBusinessDetails = () => (
    <ProfileDetails>
      <DetailWrapper width="50%">
        <DetailTitle>
          {t('l.profile.view.units_managed.text', 'Units Managed')}
        </DetailTitle>
        <DetailText>{data.work_history_properties_managed || '-'}</DetailText>
      </DetailWrapper>
      <DetailWrapper width="50%">
        <DetailTitle>
          {t('l.profile.view.work_history.text', 'Work History')}
        </DetailTitle>
        <DetailText>
          {data.work_history_year_started
            ? `${t('l.profile.view.since.text', 'Since')}${' '}
        ${data.work_history_year_started}`
            : '-'}
        </DetailText>
      </DetailWrapper>
      <AboutMe />
    </ProfileDetails>
  )

  const showPersonalDetails = () => (
    <ProfileDetails>
      <AboutMe />
      <Address />
    </ProfileDetails>
  )

  const verifiedState = data.company_info?.business_licence_verified_state
  const showCompanyDetails = () => (
    <>
      <CompanyDetails>
        <SectionTitle>
          {t('l.profile.view.company_info.title', 'Company Info')}
        </SectionTitle>
      </CompanyDetails>
      <CompanyDetails>
        <DetailWrapper width="50%">
          <DetailTitle>
            {t('l.profile.view.company_name.text', 'Name')}
          </DetailTitle>
          <DetailText>{data.company_info?.name || '-'}</DetailText>
        </DetailWrapper>
        <DetailWrapper width="50%">
          <DetailTitle>
            {t('l.profile.view.business_licence.text', 'Business Licence')}
          </DetailTitle>
          <IconWrapper>
            <DetailText>
              {BUSINESS_STATUS_TEXT[verifiedState] || '-'}
            </DetailText>
            <Verified verified={verifiedState} bottom={-13} />
          </IconWrapper>
        </DetailWrapper>
        <DetailWrapper width="50%">
          <DetailTitle>{t('l.profile.view.phone.text', 'Phone')}</DetailTitle>
          <DetailText>
            {formatPhone(
              data.company_info?.phone_number,
              data.company_info?.phone_country_code_alpha
            ) || '-'}
          </DetailText>
        </DetailWrapper>
        <DetailWrapper width="50%">
          <DetailTitle>{t('l.profile.view.email.text', 'Email')}</DetailTitle>
          <DetailText>{data.company_info?.email || '-'}</DetailText>
        </DetailWrapper>
        <Container>
          <Address />
          <BrokerInfo />
        </Container>
      </CompanyDetails>
    </>
  )

  const BUSINESS_STATUS_TEXT = {
    pending: t('l.profile.view.pending.text', 'Pending'),
    verifying: t('l.profile.view.verifying.text', 'Verifying'),
    verified: t('l.profile.view.verified.text', 'Verified')
  }

  const companyInfo = data.company_info
  const avatarLocation = data.avatar?.aws_s3_key
  return (
    <Page>
      <ContentWrapper className="mb-50">
        <MobileBackground />
        <Helmet>
          <title>{t('l.profile.view.my_profile.title', 'My Profile')}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PageTitle>
          {t('l.profile.view.my_profile.title', 'My Profile')}
        </PageTitle>
        <UpperProfile>
          <PanelTitle>
            {t('l.profile.view.my_profile.title', 'My Profile')}
          </PanelTitle>
          <NavLink to="/profile/edit">
            <EditButton>{t('l.profile.view.edit.button', 'Edit')}</EditButton>
          </NavLink>
        </UpperProfile>
        <StyledPanel hSpace={30} vSpace={30}>
          <ProfileWrapper>
            <AvatarWrapper>
              <AvatarPicker
                location={getImage(avatarLocation)}
                width={160}
                height={160}
                right={displayMobile ? 0 : 40}
                onSelect={handleUploadAvatar}
                showIcon={!avatarLocation}
              />
            </AvatarWrapper>
            <ProfileContentWrapper>
              <NameWrapper>
                <NameText>
                  {`${data.first_name} ${data.last_name}`}{' '}
                  <NoBreak>
                    {`(${data.preferred_name})`} <Verified />
                  </NoBreak>
                </NameText>
              </NameWrapper>
              <PhoneText>
                {formatPhone(data.phone, data.phone_country_code_alpha)}
              </PhoneText>
              <ContactText line={data.account_type !== 'business'}>
                {data.username}
              </ContactText>
              {data.account_type === 'business' && (
                <SectionTitle>
                  {t('l.profile.view.work_history.title', 'Work History')}
                </SectionTitle>
              )}
              {data.account_type === 'business'
                ? showBusinessDetails()
                : showPersonalDetails()}
            </ProfileContentWrapper>
            {data.account_type === 'business' && showCompanyDetails()}
          </ProfileWrapper>
        </StyledPanel>
      </ContentWrapper>
    </Page>
  )
}

export default LandlordProfile
