import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontSizes, fontWeights, colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'

const Wrapper = styled.div`
  padding: 20px;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin: 10px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const Description = styled.p`
  margin: 30px 10px;
`

const MissingRow = styled.div`
  border: 1px solid ${colors.grey};
  margin: 20px 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
`

const MisssingItem = styled.p`
  font-size: ${fontSizes.regular};
  margin: 0px;
`

const AddButton = styled.button`
  outline: none;
  border: none;
  background-color: ${colors.white};
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  padding: 0px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`

const RequirementModal = ({ userData, isVerified, history }) => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const handleClick = route => {
    history.push(route)
    close()
  }

  const isBusinessLandlord = userData?.company_info?.account_type === 'business'

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        width={24}
        height={24}
        id="close"
        onClick={close}
      />
      <Title>
        {t(
          'l.listings.view.suggested_tenants_required_modal.title',
          'Action Required'
        )}
      </Title>
      <Description>
        {t(
          'l.listings.view.suggested_tenants_required_modal.description',
          'The following are required before you can start chatting'
        )}
        :
      </Description>
      {!userData?.avatar?.id && (
        <MissingRow>
          <MisssingItem>
            {t(
              'l.listings.view.suggested_tenants_required_modal_profile_photo.text',
              'Profile Photo'
            )}
          </MisssingItem>
          <AddButton onClick={() => handleClick('/profile')}>
            {t(
              'l.listings.view.suggested_tenants_required_modal_add.button',
              'Add'
            )}
          </AddButton>
        </MissingRow>
      )}
      {!isVerified() && (
        <MissingRow>
          <MisssingItem>
            {isBusinessLandlord
              ? t(
                  'l.listings.view.suggested_tenants_required_modal_company_id.text',
                  'Business Licence'
                )
              : t(
                  'l.listings.view.suggested_tenants_required_modal_verify_id.text',
                  'ID Verification'
                )}
          </MisssingItem>
          <AddButton onClick={() => handleClick('/profile/edit')}>
            {t(
              'l.listings.view.suggested_tenants_required_modal_verify.button',
              'Verify'
            )}
          </AddButton>
        </MissingRow>
      )}
    </Wrapper>
  )
}

export const show = createModal(RequirementModal)
