import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from 'components/button'
import { AnchorButton } from 'components/anchor'

const StyledButton = styled(AnchorButton)`
  letter-spacing: -0.39px;
`

const StripeLogin = ({ path = '/settings', isConnected, disabled }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [connectUrl, setConnectUrl] = useState()

  useEffect(() => {
    const loadUrl = async () => {
      const url = await dispatch.payment.getConnectUrl()
      setConnectUrl(url)
    }
    if (!isConnected) loadUrl()
  }, [dispatch.payment, isConnected])

  const handleConnectClick = () => {
    window.localStorage.setItem('redirect-stripe-url', path)
    window.location = connectUrl
  }

  return (
    <>
      {isConnected ? (
        <StyledButton
          height={48}
          width={120}
          outline="true"
          to="/settings/payment-settings/stripe/view"
          disabled={disabled}
          aria-disabled={disabled}
          data-testid="view-stripe"
        >
          {t('l.payment_settings.view.view.button', 'View')}
        </StyledButton>
      ) : connectUrl ? (
        <Button
          theme="primary"
          height={48}
          width={120}
          onClick={handleConnectClick}
          disabled={disabled}
          aria-disabled={disabled}
          data-testid="connect-stripe"
        >
          {t('b.payment_settings.edit.connect.button', 'Connect')}
        </Button>
      ) : null}
    </>
  )
}

export default StripeLogin
