/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'
import moment from 'moment'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import ENV_VARS from 'config'
import 'moment/locale/zh-cn'

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    ns: ['translation', 'basic'],
    keySeparator: false,
    whitelist: ['en', 'zh'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    detection: {
      caches: ['localStorage', 'cookie']
    },
    backend: {
      loadPath: ''.concat(ENV_VARS.APP_PATH, '/locales/{{lng}}/{{ns}}.json')
    }
  })

const LANGUAGES = {
  zh: 'zh-cn'
}

const FORMS = {
  en: {
    mixed: {
      default: 'Invalid',
      required: 'Required',
      notType: ({ type }) =>
        ({
          number: 'Please enter a valid number',
          date: 'Please enter a valid date'
        }[type])
    },
    number: {
      min: 'Should be at least ${min}',
      max: 'Should be at most ${max}',
      positive: 'Please enter a positive number',
      integer: 'Please enter an integer'
    },
    string: {
      email: 'Please enter a valid email',
      phone: 'Please enter a valid phone number',
      phoneEmail: 'Please enter a valid email or phone number',
      min: 'Should be at least ${min} characters',
      length: 'Should be ${length} characters'
    },
    array: {
      min: 'Please add at least one item'
    }
  },
  zh: {
    mixed: {
      default: '无效',
      required: '必填',
      notType: ({ type }) =>
        ({
          number: '请输入合格数字',
          date: '请出入有效日期'
        }[type])
    },
    number: {
      min: '必须多余 ${min}',
      max: '必须少于 ${max}',
      positive: '请输入正数',
      integer: '请输入整数'
    },
    string: {
      email: '请输入有效电子邮件',
      phone: '请输入有效的手机号码',
      phoneEmail: '请输入有效邮箱或手机号码',
      min: '必须多余 ${min}',
      length: '应为 ${length} 个字符'
    },
    array: {
      min: '请添加至少一个项目'
    }
  }
}

setLocale(FORMS['en'])

i18n.on('languageChanged', function (lng) {
  const parsedLang = LANGUAGES[lng] || lng
  moment.locale(parsedLang)
  setLocale(FORMS[lng.substring(0, 2)])
})

export default i18n
