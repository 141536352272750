import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { colors, fontWeights } from 'styles'
import useRole from 'hooks/use-role'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import config from 'config'

const { WEB_URL } = config

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 40px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0 0 10px 0;
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: 40px;
`

const Link = styled.a`
  color: ${colors.link};
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }
`

const ProcessingFeesModal = () => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const isTenant = useRole() === 'tenant'

  const titleText = isTenant
    ? t('r.payment.view.processing_fees.title', 'Processing Fees')
    : t('l.payment.view.transaction_fees.title', 'Payout Fees')

  const messageText = isTenant ? (
    <Trans i18nKey="r.payment.view.processing_fees_modal.text">
      The processing fee is charged to facilitate your payment through the Liv
      Rent platform. This fee may be subject to change without notice. Please
      check the latest fee amount at{' '}
      <Link
        href={`${WEB_URL}/pricing`}
        target="_blank"
        rel="noopener noreferrer"
      >
        https://liv.rent/pricing
      </Link>
      .
    </Trans>
  ) : (
    <Trans i18nKey="l.payment.view.processing_fees_modal.text">
      Based on the payment method, the fees charged for each payout may differ.
      Please refer to the latest fee amount at{' '}
      <Link
        href={`${WEB_URL}/pricing`}
        target="_blank"
        rel="noopener noreferrer"
      >
        https://liv.rent/pricing
      </Link>
      .
    </Trans>
  )

  const buttonText = t('r.payment.view.ok.button', 'OK')

  return (
    <Wrapper
      role="dialog"
      aria-labelledby="fees-dialog-title"
      aria-describedby="fees-dialog-message"
    >
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Icon id="info_circle" width={75} height={75} />
        <ModalTitle id="fees-dialog-title">{titleText}</ModalTitle>
        <ModalText id="fees-dialog-message">{messageText}</ModalText>
        <StyledButton
          aria-label={buttonText}
          theme="primary"
          width={150}
          onClick={close}
        >
          {buttonText}
        </StyledButton>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(ProcessingFeesModal)
