import moment from 'moment'
import { path, pipe, filter, find } from 'ramda'
import addOrdinal from 'utils/add-ordinal'
import capitalize from 'utils/capitalize'
import isTruthy from 'utils/is-truthy'
import safeEmpty from 'utils/safe-empty'

const calculateDueDate = (startDate, period) => {
  let position = ''
  if (period === 'day') {
    position = '1'
  } else if (period === 'week') {
    position = moment(startDate, 'YYYY-MM-DD').day()
  } else if (period === 'month') {
    position = moment(startDate, 'YYYY-MM-DD').date()
  }
  return position === '' ? position : addOrdinal(position)
}

const rentFrequency = { daily: 'day', monthly: 'month', weekly: 'week' }

const getLeaseEnd = lease_end_action =>
  lease_end_action === 'continue_month_to_month'
    ? '<em>Note: At the end of this lenght of time the tenancy will continue on a month-to-month basis</em>' //eslint-disable-line
    : '<em>Note: At the end of this length of time the tenant must move out of the residential unit</em>' //eslint-disable-line

const formatTenancyLength = (leaseType, startDate, endDate, lease_end_action) =>
  leaseType === 'month_to_month'
    ? 'on a month-to-month basis'
    : `for a fixed length of time: ${startDate} ending on ${
        endDate || '(MISSING)'
      } ${getLeaseEnd(lease_end_action)}` //eslint-disable-line

const formatPetDeposit = (petPolicy, petDeposit, tenancyStartDate) =>
  !petPolicy
    ? ''
    : `<strong>B. Pet Damage Deposit</strong> The tenant is required to pay a pet damage deposit of $${petDeposit} by ${tenancyStartDate}`

const formatAmenityType = amenity => {
  const { type, types } = amenity
  return amenity.types
    ? Object.keys(types).reduce(
        (total, currentElement) =>
          `${total}${total && ', '}${types[currentElement]} ${currentElement}`,
        ''
      )
    : type
}
const combineFeatureList = contract => {
  let baseFeatures = contract.features
  const unitScope = contract.unit.unit_type_scope_txt_id
  //shared bathrooms need to be listed in baseFeatures
  if (
    unitScope !== 'entire' &&
    contract.unit.count_full_shared_bathrooms &&
    parseFloat(contract.unit.count_full_shared_bathrooms) > 0
  ) {
    const dummyBathroomFeature = {
      name: 'Bathroom',
      txt_id: 'bathroom',
      types: {
        private: contract.unit.count_full_bathrooms || '0',
        shared: contract.unit.count_full_shared_bathrooms
      }
    }

    baseFeatures = [dummyBathroomFeature, ...baseFeatures]
  }

  const utilities = [
    ...(contract.utilities || []),
    ...(contract.customUtilities || [])
  ]
  const features = [...baseFeatures, ...contract.customFeatures]

  const parking =
    isTruthy(path(['parking_fee', 'frequency'], contract)) &&
    contract.parking_fee.frequency !== 'not_available'
      ? ['parking']
      : []
  const storage =
    isTruthy(path(['storage_fee', 'frequency'], contract)) &&
    contract.storage_fee.frequency !== 'not_available'
      ? ['storage']
      : []
  const isEntireUnit =
    path(['unit', 'unit_type_scope_txt_id'], contract) === 'entire'

  const formatAmenities = amenities =>
    (amenities || []).map(amenity =>
      isEntireUnit
        ? amenity.name
        : `${amenity.name} (${formatAmenityType(amenity)})`
    )
  const formattedUtilities = formatAmenities(utilities)
  const formattedFeatures = formatAmenities(features)
  const includedInRent = formattedUtilities
    .concat(formattedFeatures)
    .concat(parking)
    .concat(storage)

  return includedInRent
}

const formatFeature = feature => capitalize(feature, '_')

const getUserName = user => {
  if (!user) return null
  const { first_name, last_name } = user
  return `${last_name}, ${first_name}`
}

const joinTenants = tenants => {
  return `
  <ul>
    ${pipe(filter(isTruthy), users =>
      users.reduce((next, val) => `${next}<li>${val}</li>`, '')
    )(tenants)}
  </ul>
`
}

const formatTenantNames = contract =>
  joinTenants(
    [
      ...contract.signingTenants.map(getUserName),
      ...contract.nonSigningTenants.map(getUserName),
      ...contract.extra_users.map(getUserName)
    ].filter(a => !!a)
  )

const getUserPhone = data => {
  if (path(['phone'], data)) {
    return `(${data.phone})`
  } else if (path(['phoneNumber'], data)) {
    return `(+${data.phoneCountry.calling_code}${data.phoneNumber})`
  }
  return ''
}

const formatFormKTenantInfo = contract => {
  const tenants = [
    ...contract.signingTenants,
    ...contract.nonSigningTenants
  ].map(user => [getUserName(user), getUserPhone(user)].join(' '))
  const allTenantsValue = contract.extra_users.map(data =>
    [getUserName(data), getUserPhone(data)].join(' ')
  )
  return joinTenants([...tenants, ...allTenantsValue])
}

const formatVariables = contract => {
  const addendums = [...contract.addendums, ...contract.custom_addendums]
  const includedInRent = combineFeatureList(contract)
  const signature = find(
    s => contract.userId === s.userId && contract.contractId === s.contractId,
    contract.signatures
  )
  return {
    tenancyStartDate: safeEmpty(contract.tenancyStartDate),
    tenancyEndDate: safeEmpty(contract.tenancyEndDate),
    rentPrice: safeEmpty(contract.price),
    rentFrequency: rentFrequency[contract.price_frequency],
    price_frequency: rentFrequency[contract.price_frequency],
    rentDueDate: calculateDueDate(
      contract.tenancyStartDate,
      rentFrequency[contract.price_frequency]
    ),
    securityDeposit: safeEmpty(contract.security_deposit),
    addendumStatus: isTruthy(addendums) ? 'is' : 'is not',
    addendumTermsCount: !isTruthy(addendums)
      ? ''
      : `Number of additional terms in the Addenum: ${addendums.length}`,
    tenancyLengthInfo: formatTenancyLength(
      contract.leaseType,
      contract.tenancyStartDate,
      contract.tenancyEndDate,
      contract.lease_end_action
    ),
    petDamageDepositInfo: formatPetDeposit(
      contract.pet_policy,
      contract.pet_deposit,
      contract.tenacyStartDate
    ),
    includedInRent: `<ul>${includedInRent.reduce(
      (next, val) => `${next}<li>${formatFeature(val)}</li>`,
      ''
    )}</ul>`,
    unitAddress: contract.building.full_address,
    strataPlan: contract.strata_plan_number,
    strataLot: contract.strata_lot_number,
    companyName: '("Landlord(s)")',
    managingInfo: '',
    tenantSgnatures: 'TENANT SIGNATURE \n(Export to see Signature)',
    landlordSgnatures: 'LANDLORD SIGNATURE \n(Export to see Signature)',
    datedAt:
      `Dated at ${contract.building.city}, ${contract.building.state}, ` +
      `this day of ${
        signature
          ? moment(contract.createdAt).format('MMMM DD, YYYY')
          : '______, 20__'
      }.`,
    landlordNames: `
    <ul>
    ${contract.landlords.reduce(
      (next, landlord) => `${next}<li>${getUserName(landlord)}</li>`,
      ''
    )}
    </ul>
    `,
    tenantNames: formatTenantNames(contract),
    formKTenantInfo: formatFormKTenantInfo(contract)
  }
}

export default formatVariables
