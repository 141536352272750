import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import { FieldArray } from 'formik'
import { isEmpty } from 'ramda'
import useMediaQuery from 'hooks/use-media-query'
import Panel from 'components/panel'
import { CollapsibleBox } from 'components/collapsible'
import {
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldTextarea
} from 'components/form'
import AvoidBreakInside from 'components/avoid-break-inside'

const StyledPanel = styled(Panel)`
  position: relative;
  margin-top: 10px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const PanelHeader = styled.div`
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  &:not(:first-child) {
    height: 110px;
  }

  a:only-child {
    margin-left: auto;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin: 10px auto 20px;
`

const EditButton = styled.button.attrs({ type: 'button' })`
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
  outline: none;
  :hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 414px) {
    padding: 0 10px;
    font-size: 10px;
  }
`

const DeleteButton = styled.p`
  color: ${colors.link};
  font-size: 12px;
  margin: 0;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
`

const FeeHeader = styled.div`
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid #ccc;
  padding: 0 20px;
  font-weight: ${fontWeights.medium};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

const EmptyMessage = styled.p`
  font-size: 12px;
  margin: 0;
`

const ExtraFeeList = ({ data, contractId }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  useEffect(() => {}, [data])

  const newExtraFee = {
    contract_id: contractId || '',
    fee: '',
    fee_frequency_txt_id: '',
    fee_txt_id: 'misc',
    name: ''
  }

  const renderFee = (fee, index, arrayHelpers) => {
    return (
      <StyledPanel key={index} hSpace={20} vSpace={20}>
        <CollapsibleBox
          title={`${t(
            'l.contract.edit.extra_fee_payment.label',
            'Extra Fee / Payment'
          )} ${index + 1}`}
          maxLength={22}
          header={FeeHeader}
          headerToggle={
            <DeleteButton onClick={() => arrayHelpers.remove(index)}>
              {t('l.contract.edit.delete.button', 'Delete')}
            </DeleteButton>
          }
        >
          <Row>
            <Group col={12}>
              <FieldTextarea
                name={`additional_fees.${index}.name`}
                maxLength={100}
                placeholder={t(
                  'l.contract.edit.write_info.placeholder',
                  'Write info'
                )}
                validateOnChange
              />
            </Group>
          </Row>
          <Row>
            <Group col={colSize}>
              <FieldInput
                name={`additional_fees.${index}.fee`}
                placeholder={t('l.contract.edit.amount.label', 'Amount') + ':'}
                prefix="$"
                validateOnChange
              />
            </Group>
            <Group col={colSize}>
              <FieldDropdown
                name={`additional_fees.${index}.fee_frequency_txt_id`}
                placeholder={
                  t(
                    'l.contract.edit.payment_frequency.label',
                    'Payment frequency'
                  ) + ':'
                }
                options={[
                  {
                    value: 'weekly',
                    label: t(
                      'l.contract.edit.payment_frequency_weekly.text',
                      'Weekly'
                    )
                  },
                  {
                    value: 'monthly',
                    label: t(
                      'l.contract.edit.payment_frequency_monthly.text',
                      'Monthly'
                    )
                  },
                  {
                    value: 'one_time',
                    label: t(
                      'l.contract.edit.payment_frequency_one_time.text',
                      'One-time'
                    )
                  }
                ]}
                isSearchable={false}
                validateOnChange
              />
            </Group>
          </Row>
        </CollapsibleBox>
      </StyledPanel>
    )
  }

  return (
    <AvoidBreakInside>
      <FieldArray
        name="additional_fees"
        render={arrayHelpers => (
          <>
            <PanelHeader>
              <PanelTitle>
                {t(
                  'l.contract.edit.extra_fees_payment.subtitle',
                  'Extra Fees / Payments'
                )}
              </PanelTitle>
              <EditButton onClick={() => arrayHelpers.push(newExtraFee)}>
                {t(
                  'l.contract.edit.add_extra_fee.button',
                  '+ Add extra fee / payment'
                )}
              </EditButton>
            </PanelHeader>
            {isEmpty(data) ? (
              <StyledPanel hSpace={30} vSpace={30}>
                <EmptyMessage>
                  {t(
                    'l.contract.edit.empty_extra_fees.text',
                    'You have not added any extra fees/payments'
                  )}
                </EmptyMessage>
              </StyledPanel>
            ) : (
              data.map((fee, index) => renderFee(fee, index, arrayHelpers))
            )}
          </>
        )}
      />
    </AvoidBreakInside>
  )
}

export default React.memo(ExtraFeeList)
