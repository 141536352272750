import React from 'react'
import styled from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 20px 20px 10px 10px;
  }
`

const ModalFooter = styled.div`
  text-align: center;
  margin: 30px 0 50px 0;

  > :first-child {
    margin-right: 20px;
  }
`

const BaseDialog = ({
  okLabel,
  cancelLabel,
  show,
  proceed,
  dismiss,
  cancel,
  enableEscape,
  children,
  isDisabled
}) => {
  const [t] = useTranslation()
  return (
    <div>
      <Modal
        isOpen={show}
        centered
        onClosed={dismiss}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
      >
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={cancel}
          />
        </ModalClose>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button
            id="yes_confirm"
            theme="neutral"
            width={110}
            onClick={proceed}
            disabled={isDisabled()}
          >
            {okLabel || t('b.components.dialog.yes.button', 'Yes')}
          </Button>
          <Button theme="primary" width={110} onClick={cancel}>
            {cancelLabel || t('b.components.dialog.no.button', 'No')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

BaseDialog.defaultProps = {
  proceed: undefined,
  cancel: undefined,
  dismiss: undefined,
  enableEscape: true
}

export default BaseDialog
