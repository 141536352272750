import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { select } from 'store'
import { head } from 'ramda'
import BaseChatRoom from 'features/Chat/chat-room/base'
import useRole from 'hooks/use-role'
import logo from '../logo.png'

const ChatRoom = ({ handleBack }) => {
  const timelineEvents = useSelector(select.supportRoom.supportRoomEvents)
  const roomInfo = useSelector(state => state.supportRoom.room_info)
  const dispatch = useDispatch()
  const userRole = useRole()
  const roomId = roomInfo?.id
  const presetMessages = useSelector(state =>
    select.chatPreset.supportRoomPresets(state, { userRole })
  )
  useEffect(() => {
    if (roomId) {
      dispatch.supportRoom.updateSupportRoom(roomId)
    }
  }, [dispatch.supportRoom, roomId])

  const handleSendMessage = message => {
    dispatch.supportRoom.sendMessage({ message })
  }

  const handleSendFile = async files => {
    const sendAction = ['image/png', 'image/jpg', 'image/jpeg'].includes(
      head(files)?.type
    )
      ? dispatch.supportRoom.sendImage
      : dispatch.supportRoom.sendFile
    sendAction({
      file: head(files)
    })
  }

  return (
    <BaseChatRoom
      avatarUrl={logo}
      names={['Liv Support']}
      events={timelineEvents}
      handleSendMessage={handleSendMessage}
      handleSendFile={handleSendFile}
      handleBack={handleBack}
      presetMessages={presetMessages}
      isPresetEditEnabled={false}
      isSupportRoom
    />
  )
}

export default withRouter(ChatRoom)
