import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import useBasic from 'hooks/use-basic'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getImageUrl } from 'utils/image'
import { colors, breakpoints, fontSizes, fontWeights, structure } from 'styles'
import { isTruthy } from 'utils'
import { toUnit } from 'utils/styles'
import useMediaQuery from 'hooks/use-media-query'
import DashboardHeader from 'features/Listing/dashboard-header'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCell
} from 'components/table'
import { Avatar } from 'components/avatar'
import { sortAscending, sortDescending } from './utils'

import UnselectedHeart from './LikeListUnselected_Dark.svg'
import HeaderSelectedHeart from './LikeListActivated_White.svg'
import HeaderHeart from './LikeListUnselected_White.svg'
import SelectedHeart from './LikeListActivated_Dark.svg'

const Wrapper = styled.div`
  background-color: ${colors.lightGrey};
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`

const GrayStripe = styled.div`
  height: 41px;
  background-color: ${colors.darkGrey};
  width: calc(100% - ${toUnit(structure.sidebar.width)});
  position: absolute;
`
const ApplicationTable = styled(Table)`
  width: 1096px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 95%;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
    max-width: 768px;
  }
`
const StyledTableHeader = styled(TableHeader)`
  background-color: ${colors.darkGrey};
  height: 41px;
`
const StyledTableHeaderCell = styled(TableHeaderCell)`
  background-color: ${colors.darkGrey};
  text-align: left;
  vertical-align: middle;
  ${props =>
    props.onClick &&
    `
    :hover {
      opacity: 0.5;
    }
    cursor: pointer;
  `}
`
const ImageHeaderCell = styled(StyledTableHeaderCell)`
  line-height: 12px;
  :hover {
    opacity: 1;
  }
`
const TableHeaderText = styled.p`
  color: ${colors.white};
  margin: 10px 0px;
  font-size: 12px;
  text-align: left;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.medium};
  }
`

const ApplicationRow = styled(TableRow)`
  height: 80px;
  width: 750px;
  cursor: pointer;
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const ApplicationCell = styled(TableCell)`
  max-width: 220px;
  text-overflow: ellipsis;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
`

const ImageTableCell = styled(ApplicationCell)`
  line-height: 14px;
  width: 50px;
`

const PaddedTableCell = styled(TableCell)`
  display: table-cell;
  vertical-align: middle;
  max-width: 110px;
  text-overflow: ellipsis;
  padding: 0 10px 0 0;
`

const ApplicationCellText = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`

const NameText = styled(ApplicationCellText)`
  font-weight: ${fontWeights.medium};
  margin-left: 10px;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: 18px;
    margin-left: 0;
  }
`

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    display: none;
  }
`

const ApplicantWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ApplicationViewText = styled(ApplicationCellText)`
  color: ${colors.secondary};
  cursor: pointer;
`

const FavouriteImage = styled.img`
  height: ${props => props.height || 12}px;
  cursor: pointer;
  margin: 0 15px;

  :hover {
    opacity: 0.5;
  }
`

const MobileUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 15px 5px;
  width: 100%;

  > div {
    margin-bottom: 5px;
  }
`

const MobileContent = styled.div`
  display: flex;
`

const MobileContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

const MobileUserText = styled.p`
  font-size: ${fontSizes.small};
  line-height: 20px;
  margin: 0;

  ${props =>
    props.strong &&
    `
    font-weight: ${fontWeights.medium};
  `}
`

const formatDate = date => moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')

const getApplicationFavourite = app =>
  isTruthy(app.favourite) ? SelectedHeart : UnselectedHeart

const getApplicationFavouriteHeader = currentSort =>
  currentSort.favourite ? HeaderSelectedHeart : HeaderHeart

const ListingApplications = ({
  history,
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayTablet = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const [applications, setApplications] = useState([])
  const [currentSort, setCurrentSort] = useState({
    prop: 'name',
    direction: 'asc',
    favourite: true
  })

  const updateCurrentSort = propName => {
    if (currentSort.direction === 'asc' && currentSort.prop === propName) {
      setCurrentSort({
        prop: propName,
        direction: 'desc',
        favourite: false
      })
    } else {
      setCurrentSort({
        prop: propName,
        direction: 'asc',
        favourite: false
      })
    }
  }

  useEffect(() => {
    dispatch.listing.getApplications(listingId)
  }, [dispatch.listing, listingId])

  const apps = useSelector(select.listing.applications)

  useEffect(() => {
    const sortByName =
      currentSort.direction === 'asc'
        ? sortAscending('first_name', currentSort.favourite)
        : sortDescending('first_name', currentSort.favourite)

    const sortByProfession =
      currentSort.direction === 'asc'
        ? sortAscending('job_title', currentSort.favourite)
        : sortDescending('job_title', currentSort.favourite)

    const sortBySalary =
      currentSort.direction === 'asc'
        ? sortAscending('salary', currentSort.favourite)
        : sortDescending('salary', currentSort.favourite)

    const sortByDateApplied =
      currentSort.direction === 'asc'
        ? sortAscending('created_at', currentSort.favourite)
        : sortDescending('created_at', currentSort.favourite)

    const sortByLivScore =
      currentSort.direction === 'asc'
        ? sortAscending('overall', currentSort.favourite)
        : sortDescending('overall', currentSort.favourite)
    const sortByOffer =
      currentSort.direction === 'asc'
        ? sortAscending('extra_offer', currentSort.favourite)
        : sortDescending('extra_offer', currentSort.favourite)
    const functionMap = new Map()
    functionMap.set('name', sortByName)
    functionMap.set('job_title', sortByProfession)
    functionMap.set('salary', sortBySalary)
    functionMap.set('overall', sortByLivScore)
    functionMap.set('extra_offer', sortByOffer)
    functionMap.set('created_at', sortByDateApplied)
    setApplications(functionMap.get(currentSort.prop)(apps))
  }, [apps, currentSort.direction, currentSort.favourite, currentSort.prop])

  const { professions } = useBasic(['professions'])

  const CREDIT_RATING_TEXT = {
    average: t('l.listing.application.average.text', 'Average'),
    excellent: t('l.listing.application.excellent.text', 'Excellent'),
    'very good': t('l.listing.application.very_good.text', 'Very Good'),
    poor: t('l.listing.application.poor.text', 'Poor'),
    'not enough': t('l.listing.application.not_enough.text', 'Not Enough'),
    'N/A': t('l.listing.application.unknown.text', 'Unknown'),
    analyzing: t('l.listing.application.analyzing.text', 'Analyzing')
  }

  const SALARY_TEXT = {
    'No Income': t('r.profile.edit.no_income.text', 'No Income'),
    'Rent Prepay': t(
      'r.profile.edit.income_prepay_12_months.text',
      'I can prepay 12 months rent'
    ),
    '1-29k': '$1-$29k',
    '30k-59k': '$30k-$59k',
    '60k-79k': '$60k-$79k',
    '80k-99k': '$80k-$99k',
    '100k-149k': '$100-$149k',
    '150k+': '$150k+'
  }

  const getFrequencyText = application =>
    application.extra_offer &&
    application.extra_offer !== '0' &&
    application.extra_offer_frequency
      ? `/ ${t(
          `l.listing.edit.payment_frequency_${application.extra_offer_frequency}.text`
        )}`
      : ''

  const handleFavouriteApplication = app => {
    isTruthy(app.favourite)
      ? dispatch.application.unfavourite(app.id)
      : dispatch.application.favourite(app.id)
  }

  const goToApplication = application => {
    history.push(`/application/${application.id}`)
  }

  const renderUserName = user => (
    <NameText>
      {user.first_name} {user.last_name}
    </NameText>
  )

  const renderMobileApplicationRow = application => (
    <ApplicationCell onClick={() => goToApplication(application)}>
      <ApplicantWrapper>
        <StyledAvatar
          left="0"
          height={55}
          width={55}
          location={
            application.avatar?.aws_s3_key
              ? getImageUrl(application.avatar.aws_s3_key, {
                  width: 55,
                  height: 55
                })
              : undefined
          }
        />
        <MobileUserWrapper>
          <ApplicantWrapper>{renderUserName(application)}</ApplicantWrapper>
          <MobileContent>
            <MobileContentColumn>
              <MobileUserText>
                {t('l.listing.application.Profession.text', 'Profession')}:
              </MobileUserText>
              <MobileUserText>
                {t('l.listing.application.salary.text', 'Salary')}:
              </MobileUserText>
              <MobileUserText>
                {t('l.listing.application.live_score.text', 'Liv Score')}:
              </MobileUserText>
              <MobileUserText>
                {t('l.listing.application.offer_more.text', 'Offer More')}:
              </MobileUserText>
              <MobileUserText>
                {t('l.listing.application.date_applied.text', 'Date Applied')}:
              </MobileUserText>
            </MobileContentColumn>
            <MobileContentColumn>
              <MobileUserText strong>
                {application.job_title
                  ? professions[application.job_title].name
                  : '-'}
              </MobileUserText>
              <MobileUserText strong>
                {application.salary ? SALARY_TEXT[application.salary] : '-'}
              </MobileUserText>
              <MobileUserText strong>
                {application.credit_rating
                  ? CREDIT_RATING_TEXT[application.credit_rating]
                  : CREDIT_RATING_TEXT['N/A']}
              </MobileUserText>
              <MobileUserText strong>
                {application.extra_offer
                  ? `+ $${application.extra_offer} `
                  : '-'}
                {getFrequencyText(application)}
              </MobileUserText>
              <MobileUserText strong>
                {formatDate(application.created_at)}
              </MobileUserText>
            </MobileContentColumn>
          </MobileContent>
        </MobileUserWrapper>
      </ApplicantWrapper>
    </ApplicationCell>
  )

  const renderApplicationRow = application => (
    <>
      <ApplicationCell onClick={() => goToApplication(application)}>
        <ApplicantWrapper>
          <StyledAvatar
            left="0"
            height={45}
            width={45}
            location={
              application.avatar?.aws_s3_key
                ? getImageUrl(application.avatar.aws_s3_key, {
                    width: 55,
                    height: 55
                  })
                : undefined
            }
          />
          {renderUserName(application)}
        </ApplicantWrapper>
      </ApplicationCell>
      <PaddedTableCell onClick={() => goToApplication(application)}>
        <ApplicationCellText>
          {application.job_title
            ? professions[application.job_title].name
            : '-'}
        </ApplicationCellText>
      </PaddedTableCell>
      <PaddedTableCell onClick={() => goToApplication(application)}>
        <ApplicationCellText>
          {application.salary ? SALARY_TEXT[application.salary] : '-'}
        </ApplicationCellText>
      </PaddedTableCell>
      <PaddedTableCell onClick={() => goToApplication(application)}>
        <ApplicationCellText>
          {application.credit_rating
            ? CREDIT_RATING_TEXT[application.credit_rating]
            : CREDIT_RATING_TEXT['N/A']}
        </ApplicationCellText>
      </PaddedTableCell>
      <PaddedTableCell onClick={() => goToApplication(application)}>
        <ApplicationCellText>
          {application.extra_offer ? `+ $${application.extra_offer} ` : '-'}
          {getFrequencyText(application)}
        </ApplicationCellText>
      </PaddedTableCell>
      <PaddedTableCell onClick={() => goToApplication(application)}>
        <ApplicationCellText>
          {formatDate(application.created_at)}
        </ApplicationCellText>
      </PaddedTableCell>
      {!displayTablet && (
        <PaddedTableCell onClick={() => goToApplication(application)}>
          <ApplicationViewText>
            {t('l.listing.application.view.text', 'View')}
          </ApplicationViewText>
        </PaddedTableCell>
      )}
    </>
  )

  return (
    <>
      <Helmet>
        <title>
          {t(
            'l.listing.view.listing_dashboard_applications.title',
            'Applications'
          )}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <DashboardHeader
        listingId={listingId}
        border
        currentPage={t(
          'l.listing.view.listing_dashboard_applications.title',
          'Applications'
        )}
      />
      <Wrapper>
        <GrayStripe />
        <TableContainer>
          <ApplicationTable className="sortable">
            <StyledTableHeader>
              <TableRow>
                <ImageHeaderCell
                  onClick={() => {
                    setCurrentSort({
                      prop: currentSort.prop,
                      direction: currentSort.direction,
                      favourite: !currentSort.favourite
                    })
                  }}
                >
                  <FavouriteImage
                    height={14}
                    src={getApplicationFavouriteHeader(currentSort)}
                  />
                </ImageHeaderCell>
                {displayMobile ? (
                  <StyledTableHeaderCell>
                    <TableHeaderText>
                      {t(
                        'l.listing.application.applications.text',
                        'Applications'
                      )}
                    </TableHeaderText>
                  </StyledTableHeaderCell>
                ) : (
                  <>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('name')
                      }}
                    >
                      <TableHeaderText>
                        {t('l.listing.application.applicant.text', 'Applicant')}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('job_title')
                      }}
                    >
                      <TableHeaderText>
                        {t(
                          'l.listing.application.Profession.text',
                          'Profession'
                        )}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('salary')
                      }}
                    >
                      <TableHeaderText>
                        {t('l.listing.application.salary.text', 'Salary')}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('overall')
                      }}
                    >
                      <TableHeaderText>
                        {t(
                          'l.listing.application.live_score.text',
                          'Liv Score'
                        )}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('extra_offer')
                      }}
                    >
                      <TableHeaderText>
                        {t(
                          'l.listing.application.offer_more.text',
                          'Offer More'
                        )}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell
                      onClick={() => {
                        updateCurrentSort('created_at')
                      }}
                    >
                      <TableHeaderText>
                        {t(
                          'l.listing.application.date_applied.text',
                          'Date Applied'
                        )}
                      </TableHeaderText>
                    </StyledTableHeaderCell>
                  </>
                )}
                {!displayTablet && <StyledTableHeaderCell />}
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {applications.map(application => (
                <ApplicationRow key={application.user_id}>
                  <ImageTableCell
                    onClick={() => handleFavouriteApplication(application)}
                  >
                    <FavouriteImage
                      height={14}
                      src={getApplicationFavourite(application)}
                    />
                  </ImageTableCell>
                  {displayMobile
                    ? renderMobileApplicationRow(application)
                    : renderApplicationRow(application)}
                </ApplicationRow>
              ))}
            </TableBody>
          </ApplicationTable>
        </TableContainer>
      </Wrapper>
    </>
  )
}
export default React.memo(ListingApplications)
