import React from 'react'
import moment from 'moment'
import { colors } from 'styles'
import {
  Box,
  ShadowedBox,
  DateText,
  TimeText as YearText,
  BoldText,
  DateWrapper
} from './elements'

const NewLeaseIcon = ({ info, color }) => {
  const { contract_start_date: startDate } = info
  const month = moment(startDate).format('MMM')
  const day = moment(startDate).format('DD')
  const year = moment(startDate).format('YYYY')
  return (
    <ShadowedBox height={80} width={80}>
      <Box height={55} color={color || colors.secondary} top={5}>
        <DateWrapper>
          <DateText>{month}</DateText>
          <BoldText>{day}</BoldText>
        </DateWrapper>
      </Box>
      <YearText color={color}>{year}</YearText>
    </ShadowedBox>
  )
}

export default NewLeaseIcon
