import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fontWeights, colors, fontSizes, breakpoints } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Button } from 'components/button'
import ImageSketchpad, { IconContainer } from 'components/image-sketchpad'
import { useMediaQuery } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { show as showNotificationModal } from 'components/notification-modal'
import fileAsDataUrl from 'utils/file-as-data-url'
import styled from 'styled-components'

const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 6px;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-height: 95vh;
  overflow: hidden;

  ${props =>
    props.isFullscreen
      ? `
    max-height: unset;
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  `
      : `
    @media screen and (max-width: ${breakpoints.phoneMax}) {
      width: 95%;
      height: 95vh;
    }
  `}
`

const ModalClose = styled.div`
  text-align: right;
  margin-left: auto;
  margin-top: 30px;
  margin-right: 30px;
  height: 32px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-right: 15px;
    margin-top: 15px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const LimitWidthWrapper = styled.div`
  max-width: 1440px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;
  position: relative;

  ${props =>
    props.isFullscreen
      ? `
  min-height: inherit;
  padding-top: 50px;
  width: 100%;
  
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-top: 10px;
  }
  `
      : `
  box-sizing: border-box;
  `}
`

const EditContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 50px;
  flex-shrink: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    position: unset;

    flex-direction: column;
    align-items: center;
  }
`

const StyledSketchpad = styled(ImageSketchpad)`
  margin-top: 50px;
  @media screen and (min-width: ${breakpoints.ipadMiniMax}) {
    max-width: 560px;
    max-height: unset;
    margin-top: unset;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-height: 60vh;
  }

  @media screen and (min-width: ${breakpoints.tabletLandscape}) {
    max-width: 720px;
    max-height: unset;
  }

  @media screen and (min-width: 1100px) {
    max-width: 825px;
    max-height: unset;
  }

  @media screen and (min-width: 1250px) {
    max-width: 1050px;
    max-height: unset;
  }
`

const StyledIconContainer = styled(IconContainer)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: unset;
    right: 50%;
    bottom: 55px;
    position: unset;
    flex-direction: row;
    justify-content: center;
    margin-top: 70px;
    transform: unset;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 50px;
  }
`

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 47vh;
  object-fit: contain;
  border: 1px solid ${colors.mediumGrey};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 100%;
    width: unset;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 50px;
  ${props =>
    props.isFullscreen &&
    `
    margin-top: 50px;
  `}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: auto;
    width: 100%;
    margin-bottom: 0px;
  }
`

const Title = styled.p`
  font-size: 28px;
  line-height: 34px;
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
  text-align: center;
  margin: 0;

  ${props =>
    props.isFullscreen &&
    `
    margin: 0px 0px 50px;
  `}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: ${fontSizes.medium};
    margin: 0;
  }
`

const DescriptionText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  line-height: 21px;
  margin: 20px auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 85%;
  }
`

const StyledButton = styled(Button)`
  font-size: ${fontSizes.regular};

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex: 1;
    margin: 0px;
    width: unset;
  }
`

const EditButton = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
  color: ${colors.link};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: ${fontSizes.regularMobile};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-top: 30px;
  }
`

const EditIcon = styled(Icon)`
  margin-right: 5px;
  g {
    stroke: ${colors.link};
  }
`

const BorderedButton = styled(StyledButton)`
  border: 1px solid ${colors.mediumGrey};
`

const MAX_SIZE_MB = 6

const ImageEditModal = ({ initialImageSrc, onSave, ...rest }) => {
  /*
  initialImageSrc = image user uplaoded in form page, before modal has been opened
  baseImageSrc = clean version of the image the user is currently editing, it can either be the intial image,
  or a different image on disk if the user has replaced it using the 'replace' button
  editedImageSrc = image generated by the sketchpad.
  */
  const [t] = useTranslation()
  const { close } = useModalize()
  const canvasRef = useRef()
  const [isEditMode, setIsEditMode] = useState(false)
  const [baseImageSrc, setBaseImageSrc] = useState(initialImageSrc)
  const [editedImageSrc, setEditedImageSrc] = useState(baseImageSrc)
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  const { open, getInputProps } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    multiple: false,
    noKeyboard: true,
    noDrag: true,
    maxSize: MAX_SIZE_MB * 1000000,
    onDropRejected: files => {
      showNotificationModal({
        type: 'error',
        message:
          files[0].size > MAX_SIZE_MB * 1000000
            ? t(
                'b.profile.edit.max_upload_size.error',
                'Max file upload size is 6MB'
              )
            : t(
                'b.profile.edit.wrong_file_type.error',
                'Sorry, we do not support this file type'
              )
      })
    },
    onDropAccepted: async files => {
      const fileUrl = await fileAsDataUrl(files[0])
      setBaseImageSrc(fileUrl)
      setEditedImageSrc(fileUrl)
      setIsEditMode(false)
    }
  })

  const onDone = () => {
    if (!canvasRef.current) return
    const url = canvasRef.current.toDataURL()
    setEditedImageSrc(url)
    setIsEditMode(false)
  }

  const onSaveImage = () => {
    onSave(editedImageSrc)
    close()
  }

  const title = isEditMode
    ? t('b.image_markup.markup.label', 'Markup')
    : t('b.image_markup.preview.title', 'Preview')
  const cancelEdit = () => {
    setIsEditMode(false)
  }
  const isFullscreen = isEditMode || isMobile
  return (
    <ModalWrapper isFullscreen={isFullscreen}>
      {!isEditMode && (
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
      )}
      <ScrollWrapper>
        <LimitWidthWrapper isFullscreen={isFullscreen}>
          <Title isFullscreen={isFullscreen}>{title}</Title>
          {isEditMode ? (
            <EditContainer>
              <StyledSketchpad
                baseImageSrc={baseImageSrc}
                editedImageSrc={editedImageSrc}
                ref={canvasRef}
                ToolbarComponent={StyledIconContainer}
              />
            </EditContainer>
          ) : (
            <>
              <DescriptionText>
                {t(
                  'b.image_markup.preview.description.text',
                  'Use our markup tool to hide your important private info (e.g. SIN number).'
                )}
              </DescriptionText>
              <PreviewImage src={editedImageSrc} />
            </>
          )}
          {!isEditMode && (
            <EditButton onClick={() => setIsEditMode(true)}>
              <EditIcon id="edit" width={24} height={25} />
              {t('b.image_markup.markup.label', 'Markup')}
            </EditButton>
          )}
        </LimitWidthWrapper>
        <ButtonsWrapper>
          <input {...getInputProps()} />
          <BorderedButton
            width="140px"
            right={15}
            onClick={isEditMode ? cancelEdit : open}
          >
            {isEditMode ? t('common.cancel') : t('common.replace')}
          </BorderedButton>
          <StyledButton
            theme="primary"
            width="140px"
            onClick={isEditMode ? onDone : onSaveImage}
          >
            {isEditMode ? t('common.done') : t('common.save')}
          </StyledButton>
        </ButtonsWrapper>
      </ScrollWrapper>
    </ModalWrapper>
  )
}

export const show = createModal(ImageEditModal, {
  width: '100%',
  clickOutside: false,
  showBackground: false,
  isFullscreen: true
})
