import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { equals } from 'ramda'
import moment from 'moment'
import toInteger from 'lodash/toInteger'
import useBasic from 'hooks/use-basic'
import useMediaQuery from 'hooks/use-media-query'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import VerifiedBadge from 'components/verified-badge'
import { Avatar } from 'components/avatar'
import { getImageUrl } from 'utils/image'
import formatName from 'utils/format-name'
import toBoolean from 'utils/to-boolean'
import capitalize from 'utils/capitalize'
import { logUserView } from 'ports'

const Wrapper = styled.div`
  background: ${colors.white};
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  max-height: 95vh;
  max-width: 767px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-height: unset;
  }
`

const ModalClose = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.7;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: 20px;
    right: 20px;
  }
`

const MobileTopName = styled.p`
  display: none;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: block;
    color: ${colors.darkGrey};
    font-size: 14px;
    font-weight: ${fontWeights.medium};
    line-height: 24px;
    margin: 0;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
  }
`

const ScrollWrapper = styled.div`
  background: ${colors.white};
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 50px 35px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 50px 20px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 60px 20px 70px;
    flex-direction: column;
    align-items: center;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.grey};
  width: 282px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    max-width: 460px;
    border: none;
  }
`

const AvatarWrapper = styled.div`
  height: 280px;
  width: 280px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 30px 0 0;
  }
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const Name = styled.h2`
  font-size: 25px;
  color: ${colors.regular};
  font-weight: 700;
  letter-spacing: 0.47px;
  line-height: 34px;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SearchPreferencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SearchPreference = styled.div`
  display: flex;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 6px;
  margin: 0 10px 10px 0;
  height: 40px;
  padding: 10px;
  font-size: ${fontSizes.regular};
  color: ${colors.darkGrey};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.32px;
  line-height: 22px;
`

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 40px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-left: 20px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    max-width: 460px;
    margin: 20px 20px 40px;
  }
`

const SectionTitle = styled.h3`
  font-size: ${fontSizes.medium};
  font-weight: 700;
  border-bottom: 1px solid ${colors.grey};
  margin: 10px 0 20px;
  padding-bottom: 20px;

  :not(:first-child) {
    margin-top: 20px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Label = styled.p`
  display: flex;
  align-items: center;
  color: ${colors.mediumGrey};
  overflow-wrap: break-word;
  word-break: break-word;
  flex-shrink: 0;
  margin-right: 10px;

  svg {
    margin-right: 10px;
    opacity: 0.8;
  }
`

const Value = styled(Label)`
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  margin: 21px 0 16px;
  flex-shrink: 1;
`

const Text = styled(Label)`
  font-size: 15px;
  color: ${colors.regular};
  margin-top: 0;
  flex-shrink: 1;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background: ${colors.white};

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);
  }
`

const DismissButton = styled.button`
  flex: 1;
  margin: 0;
  padding: 10px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  border: none;
  cursor: pointer;
  width: 180px;
  height: 48px;
  color: ${colors.white};
  background: ${colors.darkGrey};

  :hover {
    opacity: 0.7;
  }

  :first-child {
    margin-right: 15px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 56px;

    :first-child {
      margin: 0;
    }
  }
`

const ChatButton = styled(DismissButton)`
  color: ${colors.white};
  background: ${colors.secondary};
`

const notNull = value => value !== null

const equalsOne = equals('1')

const getLanguages = (languages, spokenLanguages) => {
  const filteredLanguages = languages.filter(
    lang => spokenLanguages.indexOf(lang.txt_id) >= 0
  )
  return filteredLanguages.map(lang => lang.name).join(', ')
}

const TenantProfile = React.forwardRef(({ data, handleClickChat, handleRemoveTenant }, ref) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const { languages, professions } = useBasic(['languages', 'professions'])
  const birthMonth = moment(data.dob, 'YYYY-MM-DD').format('MMMM')
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const tenantName = formatName(data)
  // BE uses preference and preferences here interchangeably.  Check both.
  const preferences = data.user_search_preferences || data.user_search_preference

  useEffect(() => {
    logUserView(undefined, { userId: data.id })
  }, [data])

  const handleClickNope = e => {
    handleRemoveTenant(e)
    close()
  }

  const toFurnished = isFurnished => {
    if (toBoolean(isFurnished)) {
      return t('common.furnished', 'Furnished')
    }
    if (isFurnished === null) {
      return t('common.furnished_unfurnished', 'Furnished / Unfurnished')
    }
    return t('common.unfurnished', 'Unfurnished')
  }

  const formatSearchPreferences = ({ min_price, max_price, furnished }) => {
    const allPreferences = []
    const minPrice = toInteger(min_price)
    const maxPrice = toInteger(max_price)

    if (minPrice === 0 && maxPrice === 0) {
      allPreferences.push(
        <SearchPreference key="anyPrice">
          {t('b.profile.view.any_price.text', 'Any price')}
        </SearchPreference>
      )
    }

    if (minPrice > 0) {
      allPreferences.push(
        <SearchPreference key="minPrice">
          {`${t(
            'b.profile.view.min_budget.text',
            'Min. budget'
          )} $${min_price}`}
        </SearchPreference>
      )
    }

    if (max_price > 0) {
      allPreferences.push(
        <SearchPreference key="maxPrice">
          {`${t(
            'b.profile.view.max_budget.text',
            'Max. budget'
          )} $${max_price}`}
        </SearchPreference>
      )
    }

    allPreferences.push(
      <SearchPreference key="furnished">
        {toFurnished(furnished)}
      </SearchPreference>
    )

    return allPreferences
  }

  const getBirthday = () =>
    data.dob ? (
      <Trans
        i18nKey="b.profile.view.born_in_month.text"
        values={{ month: birthMonth }}
        defaults="In {{month}}"
      />
    ) : (
      '-'
    )

  return (
    <Wrapper ref={ref}>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <MobileTopName>{tenantName}</MobileTopName>
      <ScrollWrapper>
        <ProfileWrapper>
          <AvatarWrapper>
            <Avatar
              absolute={false}
              location={getImageUrl(data.avatar?.aws_s3_key, {
                width: 280,
                height: 280
              })}
              height={280}
              width={displayMobile ? '100%' : 280}
              radius={'0'}
            />
          </AvatarWrapper>
          <ContentWrapper>
            <NameWrapper>
              <Name>{tenantName}</Name>
              <VerifiedBadge
                type="id"
                width={35}
                height={25}
                left={10}
                bottom={-5}
                verified={data.identity_verified_state}
              />
            </NameWrapper>
            {preferences && (
              <SearchPreferencesWrapper>
                {formatSearchPreferences(preferences)}
              </SearchPreferencesWrapper>
            )}
          </ContentWrapper>
        </ProfileWrapper>
        <AboutWrapper>
          <SectionTitle>
            {t('b.profile.view.about_me.subtitle', 'About Me')}
          </SectionTitle>
          <Row>
            <Label>
              <Icon id="birthday" width={30} height={30} />
              {t('b.profile.view.birthday.label', 'Birthday')}
            </Label>
            <Value>{getBirthday()}</Value>
          </Row>
          <Row>
            <Label>
              <Icon id="language" width={32} height={32} />
              {t('b.profile.view.languages_spoken.label', 'Languages spoken')}
            </Label>
            <Value>
              {data.spoken_languages && !!data.spoken_languages.length
                ? getLanguages(languages, data.spoken_languages)
                : '-'}
            </Value>
          </Row>
          <Row>
            <Label>
              <Icon id="petsAllowed" width={29} height={29} />
              {t('b.profile.view.i_do.label', 'Pets')}
            </Label>
            <Value>
              {data.pet_owner
                ? equalsOne(data.pet_owner)
                  ? t('b.profile.view.have_pets.label', 'Has pet(s)')
                  : t('b.profile.view.not_have_pets.label', 'No pets')
                : '-'}
            </Value>
          </Row>
          <Row>
            <Label>
              <Icon id="profile" width={29} height={29} />
              {t('b.profile.view.story.label', 'Story')}
            </Label>
          </Row>
          <Row>
            <Text>{notNull(data.about_me) ? data.about_me : '-'}</Text>
          </Row>
          <SectionTitle>
            {t('b.profile.view.profession.subtitle', 'Profession')}
          </SectionTitle>
          <Row>
            <Label>
              <Icon id="profession" width={31} height={31} />
              {t('b.profile.view.occupation.label', 'Occupation')}
            </Label>
            <Value>
              {notNull(data.job_title)
                ? capitalize(professions[data.job_title].name)
                : '-'}
            </Value>
          </Row>
          <Row>
            <Label>
              <Icon id="salary_range" width={31} height={31} />
              {t('b.profile.view.salary.label', 'Salary')}
            </Label>
            <Value>{notNull(data.salary) ? data.salary : '-'}</Value>
          </Row>
          {handleClickChat && handleRemoveTenant && (
            <ButtonsWrapper>
              <DismissButton type="button" onClick={handleClickNope}>
                {t(
                  'b.profile.view.suggested_tenant_dismiss.button',
                  'Maybe Not'
                )}
              </DismissButton>
              <ChatButton type="button" onClick={handleClickChat}>
                {t('b.profile.view.suggested_tenant_chat.button', 'Chat')}
              </ChatButton>
            </ButtonsWrapper>
          )}
        </AboutWrapper>
      </ScrollWrapper>
    </Wrapper>
  )
})

TenantProfile.displayName = 'TenantProfile'

export default TenantProfile

export const showTenantProfile = createModal(TenantProfile, {
  maxWidth: '767px',
  width: '100%',
  borderRadius: '0px',
  alignSelf: 'flex-start'
})
