const metadata = (isThem, event, t, userRole) => {
  const displayName = event?.display_name
  const data = {
    chat_end_contract_notice1: {
      title: t(
        'b.chat.view.contract_end_notice.text',
        'Your lease is coming to an end soon, you may want to renew your contract'
      )
    },
    chat_end_contract_notice2: {
      title: t(
        'b.chat.view.contract_end_notice.text',
        'Your lease is coming to an end soon, you may want to renew your contract'
      )
    },
    chat_new_user: {
      title: t('b.chat.view.user_added_to_timeline.text', {
        defaultValue: '{{displayName}} has been added to the timeline',
        displayName
      })
    },
    chat_remove_user: {
      title: t('b.chat.view.timeline_user_removed.text', {
        defaultValue: '{{displayName}} has been removed from the timeline',
        displayName
      })
    },
    chat_timeline_locked: {
      title: isThem
        ? t('b.chat.view.removed_conversation.text', {
            defaultValue: '{{displayName}} has left this conversation',
            displayName
          })
        : t(
            'b.chat.view.left_conversation.text',
            'You have removed this conversation'
          )
    },
    chat_timeline_deleted: {
      title: t('b.chat.delete_timeline.text', {
        defaultValue: '{{displayName}} has left this conversation',
        displayName
      })
    }
  }
  return data[event?.event_type]
}
export default metadata
