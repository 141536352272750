import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors, fontWeights } from 'styles'

const Element = styled(Link)`
  color: ${colors.link};
  font-weight: ${fontWeights.regular};

  ${props =>
    props.left &&
    `
    margin-left: ${props.left}px;
  `}
`

const Anchor = Element

export default Anchor
