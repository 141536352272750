import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import {
  SharedSelectorItem,
  SharedSelectorList,
  CustomSharedSelectorList,
  CustomSharedSelectorItem
} from 'components/selector-list'
import { Label } from 'components/form'
import { StyledRow, StyledGroup } from './elements'

const Features = ({ values }) => {
  const { t } = useTranslation()
  const utilities = useSelector(select.basic.utilities)
  const features = useSelector(select.basic.features)
  const unitScope = values.unit_type_scope_txt_id

  const renderFeatures = () => (
    <StyledGroup col={6}>
      <Label htmlFor="unit_features" strong bottom={20}>
        {t('l.listing.edit.standard_features.label', 'Standard features')}
      </Label>
      <SharedSelectorList
        name="unit_features"
        id="unit_features"
        value={values.unit_features}
      >
        {features.map(feature => (
          <SharedSelectorItem
            key={feature.txt_id}
            featureName={feature.name}
            value={feature.txt_id}
            showOptions={unitScope !== 'entire'}
          >
            {t(`basic:b.listing.unit_features.${feature.txt_id}.text`)}
          </SharedSelectorItem>
        ))}
      </SharedSelectorList>
    </StyledGroup>
  )

  const renderCustomFeatures = () => (
    <StyledGroup col={6}>
      <Label htmlFor="custom_features" strong bottom={20}>
        {t('l.listing.edit.custom_features.label', 'Custom features')}
      </Label>
      <CustomSharedSelectorList
        name="custom_features"
        id="custom_features"
        value={values.custom_features}
        addText={t(
          'l.listing.edit.add_custom_feature.label',
          'Add custom feature'
        )}
        placeholder={t(
          'l.listing.edit.enter_custom_feature.placeholder',
          'Enter custom feature'
        )}
      >
        {values.custom_features.map((feature, i) => (
          <CustomSharedSelectorItem
            key={i}
            value={feature}
            showOptions={unitScope !== 'entire'}
          >
            {feature.name}
          </CustomSharedSelectorItem>
        ))}
      </CustomSharedSelectorList>
    </StyledGroup>
  )

  const renderUtilities = () => (
    <StyledGroup col={6}>
      <Label htmlFor="listing_utilities" strong bottom={20}>
        {t('l.listing.edit.standard_utilities.label', 'Standard utilities')}
      </Label>
      <SharedSelectorList
        name="listing_utilities"
        id="listing_utilities"
        defaultType="included"
        value={values.listing_utilities}
      >
        {utilities.map(utility => (
          <SharedSelectorItem
            key={utility.txt_id}
            value={utility.txt_id}
            options={['included', 'shared', 'metered']}
            featureName={utility.name}
            showOptions={unitScope !== 'entire'}
          >
            {t(`basic:b.listing.utilities.${utility.txt_id}.text`)}
          </SharedSelectorItem>
        ))}
      </SharedSelectorList>
    </StyledGroup>
  )

  const renderCustomUtilities = () => (
    <StyledGroup col={6}>
      <Label htmlFor="custom_utilities" strong bottom={20}>
        {t('l.listing.edit.custom_utilities.label', 'Custom utilities')}
      </Label>
      <CustomSharedSelectorList
        name="custom_utilities"
        id="custom_utilities"
        defaultType="included"
        value={values.custom_utilities}
        addText={t(
          'l.listing.edit.add_custom_utility.label',
          'Add custom utility'
        )}
        placeholder={t(
          'l.listing.edit.enter_custom_utility.placeholder',
          'Enter custom utility'
        )}
      >
        {values.custom_utilities.map((utility, i) => (
          <CustomSharedSelectorItem
            key={i}
            value={utility}
            options={['included', 'shared', 'metered']}
            showOptions={unitScope !== 'entire'}
          >
            {utility}
          </CustomSharedSelectorItem>
        ))}
      </CustomSharedSelectorList>
    </StyledGroup>
  )

  return (
    <>
      <AreaTitle>
        {t(
          'l.listing.edit.features_included.subtitle',
          'Select All Features Included'
        )}
      </AreaTitle>
      <Panel>
        <StyledRow>
          {renderFeatures()}
          {renderCustomFeatures()}
        </StyledRow>
      </Panel>
      <AreaTitle>
        {t(
          'l.listing.edit.utilities_included.subtitle',
          'Select All Utilities Included'
        )}
      </AreaTitle>
      <Panel>
        <StyledRow>
          {renderUtilities()}
          {renderCustomUtilities()}
        </StyledRow>
      </Panel>
    </>
  )
}

export default Features
