import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import useRole from 'hooks/use-role'
import { fontWeights, fontSizes, colors } from 'styles'
import { path, filter, pipe, propEq } from 'ramda'
import moment from 'moment'
import { AddendumDescription, AddendumTitle } from 'components/addendum'
import AddendumAgreement from './addendum-agreement'

const DefaultTitleStyle = `
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
  margin: 0 0 30px 0;
  text-align: center;
  line-height: 30px;
`

const DefaultDescriptionStyle = 'flex: 1'

const Title = styled(AddendumTitle)`
  ${DefaultTitleStyle}
`

const Description = styled(AddendumDescription)`
  ${DefaultDescriptionStyle}
`

const CustomTitle = styled.p`
  ${DefaultTitleStyle}
`

const CustomDescription = styled.div`
  ${DefaultDescriptionStyle}
`

const AgreeText = styled.p`
  font-weight: ${fontWeights.medium};
  flex: 9999;
  display: inline-block;
  margin: 0;
`

const DateSignedText = styled(AgreeText)`
  color: ${colors.link};
`

const AddendumView = ({
  addendum,
  onChange,
  variables,
  signed = false,
  match
}) => {
  const userRole = useRole()
  const isTenant = userRole === 'tenant'
  const userId = useSelector(path(['session', 'session', 'id']))

  const contractSignature = useSelector(
    path(['contract', match?.params?.contractId, 'contract_signatures'])
  )

  const signature = () => {
    return isTenant
      ? pipe(filter(propEq('user_id', userId)))(contractSignature)
      : pipe(filter(!propEq('user_id', userId)))(contractSignature)
  }

  const dateSigned = signed
    ? moment(signature.created_at).format('MMMM DD, YYYY')
    : ''

  const content = addendum.title ? (
    <>
      <CustomTitle>{addendum.title}</CustomTitle>
      <CustomDescription>{addendum.description}</CustomDescription>
      {signed && (
        <AgreeText htmlFor={`addendum-${addendum.id}`}>
          <Trans
            i18nKey="r.contract.view.agree_with_addendum_terms.text"
            dateSigned={dateSigned}
          >
            This addendum has been agreed to on{' '}
            <DateSignedText>{{ dateSigned }}</DateSignedText>.
          </Trans>
        </AgreeText>
      )}
    </>
  ) : (
    <>
      <Title addendumId={addendum.id} />
      <Description addendum={addendum} variables={variables} />
      {signed && (
        <AgreeText htmlFor={`addendum-${addendum.id}`}>
          <Trans
            i18nKey="r.contract.view.agree_with_addendum_terms.text"
            dateSigned={dateSigned}
          >
            This addendum has been agreed to on{' '}
            <DateSignedText>{{ dateSigned }}</DateSignedText>.
          </Trans>
        </AgreeText>
      )}
    </>
  )

  return (
    <>
      {content}
      {onChange && (
        <AddendumAgreement addendum={addendum} onChange={onChange} />
      )}
    </>
  )
}

AddendumView.defaultProps = {
  addendum: {},
  onChange: null,
  variables: {}
}

export default AddendumView
