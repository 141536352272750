import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import { showVerifyModal } from 'components/verify'
import { Button } from 'components/button'
import useProfile from 'hooks/use-profile'
import Icon from 'components/icon'
import { Group } from 'components/form'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const StyledGroup = styled(Group)`
  flex: 1;
  margin: 10px;
  padding: 25px 30px;
  margin: 0;
`

const StyledText = styled.p`
  margin: 0 0 30px 0;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
  margin: 15px auto;
  display: block;
`

const VerificationModal = ({
  title = 'Warning',
  warningMessage,
  buttonText,
  business = false,
  history
}) => {
  const { close } = useModalize()
  const data = useProfile()

  const handleOnClick = () => {
    if (buttonText === 'Ok') {
      close()
    } else if (buttonText === 'Verify Now' && business) {
      history.replace('/profile/edit')
      close()
    } else {
      showVerifyModal(data)
    }
  }

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>{title}</Title>
      </Header>
      <StyledGroup>
        <StyledText>{warningMessage}</StyledText>
        <ActionButton
          name="license_pill"
          theme="primary"
          type="submit"
          width={300}
          onClick={() => handleOnClick()}
        >
          {buttonText}
        </ActionButton>
      </StyledGroup>
    </Wrapper>
  )
}

export const showVerificationModal = createModal(VerificationModal)
