import React from 'react'
import { ContractView } from 'pages/Contract'
import ButtonBar from 'features/Profile/button-bar'

const ResourcesContractView = ({ history, ...props }) => {
  return (
    <>
      <ContractView {...props} />
      <ButtonBar handleBack={() => history.goBack()} sticky></ButtonBar>
    </>
  )
}

export default ResourcesContractView
