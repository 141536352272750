import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useHasHeightChanged from 'hooks/use-has-height-changed'
import { colors, breakpoints, fontWeights, structure } from 'styles'
import { FilterButton } from 'components/search-filter'
import { select } from 'store'
import Settings from './settings'
import Detail from './detail'

const StickyContainer = styled.div`
  top: ${structure.header.height}px;
  position: ${({ pushUp }) => (pushUp ? 'relative' : 'sticky')};
  width: 100%;
  z-index: 20;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.grey};

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
  }
`

const EditText = styled.p`
  font-size: 12px;
  color: ${colors.darkGrey};
  font-weight: ${fontWeights.medium};
  line-height: 18px;
  padding-right: 20px;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    text-align: center;
    font-weight: ${fontWeights.regular};
    padding-right: 0;
    margin-bottom: 10px;
  }
`

const EditBarContainer = styled.div`
  margin: 0 auto;
  max-width: 720px;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 95%;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 15px 20px;
    flex-direction: column;
    justify-content: space-evenly;
  }
`

const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const EditListing = ({
  match: { params },
  pushUpOnMobile = true,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState('detail')

  useEffect(() => {
    dispatch.listing.load(params.listingId)
  }, [dispatch.listing, params.listingId])

  const listing = useSelector(state =>
    select.listing.editListing(state, {
      listingId: params.listingId
    })
  )

  const showListingSettings = listing?.state_machine === 'published'

  const renderSettings = () => {
    return (
      <Settings
        {...props}
        data={listing}
        handleBack={() => setCurrentTab('detail')}
      />
    )
  }

  const hasHeightChanged = useHasHeightChanged()
  const pushUp = pushUpOnMobile && hasHeightChanged

  const renderDetail = () => {
    return <Detail {...props} data={listing} />
  }

  return (
    <>
      {showListingSettings && (
        <StickyContainer pushUp={pushUp}>
          <EditBarContainer>
            <EditText>
              {t(
                'l.listing.edit.contact_for_help.text',
                'Edit your listing below. If you require any help, please contact support@liv.rent'
              )}
            </EditText>
            <TabWrapper>
              <FilterButton
                active={currentTab === 'detail'}
                onClick={() => setCurrentTab('detail')}
              >
                {t('l.listing.edit.details.button', 'Details')}
              </FilterButton>
              <FilterButton
                active={currentTab === 'settings'}
                onClick={() => setCurrentTab('settings')}
              >
                {t('l.listing.edit.settings.button', 'Settings')}
              </FilterButton>
            </TabWrapper>
          </EditBarContainer>
        </StickyContainer>
      )}
      {currentTab === 'detail' ? renderDetail() : renderSettings()}
    </>
  )
}
export default EditListing
