import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import useBasic from 'hooks/use-basic'
import useProfile from 'hooks/use-profile'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { Button } from 'components/button'
import { AvatarPicker, defaultAvatar } from 'components/avatar'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { getImageUrl } from 'utils/image'
import * as Yup from 'yup'
import Icon from 'components/icon'
import { Row, Group, FieldDropdown } from 'components/form'
import { Formik, Form } from 'formik'
import { createModal, useModalize } from 'components/modalize'
import useWindowDimensions from 'hooks/use-window-dimensions'

const StyledRow = styled(Row)`
  margin-top: 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 95vh;
  border-radius: 5px;
  background-color: ${colors.white};
`

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
`

const TitleText = styled.h1`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
`

const SubtitleText = styled.h2`
  max-width: 275px;
  font-size: 14px;
  margin: 20px 0px;
  text-align: center;
`

const StyledForm = styled(Form)`
  align-self: center;
  width: 100%;
  max-width: 320px;
  margin-top: 10px;
`

const StyledButton = styled(Button)`
  margin: 10px auto;
  display: block;
`

const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 10px 10px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const AvatarWrapper = styled.div`
  display: flex;
  height: 160px;
  width: 160px;
  position: relative;
`

const getImage = url => (url ? getImageUrl(url) : defaultAvatar)

const WelcomeModal = props => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const data = useProfile()
  const { professions } = useBasic(['professions'])
  const [container, setContainer] = useState()
  useEffect(() => {
    setContainer(
      typeof document !== 'undefined'
        ? document.getElementById('modal-container')
        : undefined
    )
  }, [])

  // Oddly specific workaround for react-select (https://github.com/JedWatson/react-select/issues/3533)
  // to ensure selector updates with zoom / window resize
  useWindowDimensions(300)

  const handleUploadAvatar = file => {
    dispatch.profile.uploadAvatar({
      file,
      fileId: data.avatar?.id
    })
  }

  const getProfessions = () => {
    return Object.values(professions).map(profession => ({
      value: profession.txt_id,
      label: profession.name
    }))
  }

  const formOptions = () => ({
    initialValues: {
      job_title: '',
      salary: ''
    },
    validationSchema: Yup.object().shape({
      job_title: Yup.string().required(),
      salary: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await dispatch.profile.save(values)
      setSubmitting(false)
      if (response.ok) {
        close()
      }
    }
  })

  const avatarLocation = data.avatar?.aws_s3_key

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <TitleText>
          {t('r.profile.edit.welcome_modal.title', 'Introduce Yourself')}
        </TitleText>
        <SubtitleText>
          {t(
            'r.profile.edit.welcome_modal.subtitle',
            'Become a trusted renter by providing a bit more information about yourself.'
          )}
        </SubtitleText>
        <AvatarWrapper>
          <AvatarPicker
            height={160}
            width={160}
            location={getImage(avatarLocation)}
            onSelect={handleUploadAvatar}
            alt={data.first_name}
            showIcon={!avatarLocation}
          />
        </AvatarWrapper>
        <Formik {...formOptions(props)}>
          {({ values, isSubmitting }) => (
            <StyledForm>
              <StyledRow top={20}>
                <Group>
                  <FieldDropdown
                    maxMenuHeight={150}
                    placeholder={t(
                      'r.profile.edit.welcome_modal_job_title.label',
                      'Select profession'
                    )}
                    name="job_title"
                    options={getProfessions()}
                  />
                </Group>
              </StyledRow>
              <StyledRow top={20}>
                <Group>
                  <FieldDropdown
                    id="salary"
                    maxMenuHeight={195}
                    name="salary"
                    menuPosition="fixed"
                    menuPortalTarget={container}
                    menuPlacement={displayMobile ? 'top' : 'bottom'}
                    placeholder={t(
                      'r.profile.edit.welcome_modal_salary.label',
                      'Approx. annual salary'
                    )}
                    options={[
                      {
                        value: 'No Income',
                        label: t('r.profile.edit.no_income.text', 'No Income')
                      },
                      {
                        value: 'Rent Prepay',
                        label: t(
                          'r.profile.edit.income_prepay_12_months.text',
                          'I can prepay 12 months rent'
                        )
                      },
                      { value: '1-29k', label: '$1-$29k' },
                      { value: '30k-59k', label: '$30k-$59k' },
                      { value: '60k-79k', label: '$60k-$79k' },
                      { value: '80k-99k', label: '$80k-$99k' },
                      { value: '100k-149k', label: '$100-$149k' },
                      { value: '150k+', label: '$150k+' }
                    ]}
                    isSearchable={false}
                  />
                </Group>
              </StyledRow>
              <StyledButton
                type="submit"
                theme="primary"
                width={150}
                disabled={
                  !values.job_title || !values.salary || !data.avatar?.id
                }
                left="auto"
                isSubmitting={isSubmitting}
              >
                {t('r.profile.edit.welcome_modal_save.label', 'Save')}
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showWelcomeModal = createModal(WelcomeModal)
