import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
`

const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;

  svg {
    cursor: pointer;
    margin: 0;

    path {
      fill: ${colors.white};
    }
  }

  :hover {
    opacity: 0.5;
  }
`

const ModalImage = styled.img`
  height: auto;
`

const ImageModal = ({ src }) => {
  const { close } = useModalize()

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={32}
          height={32}
          onClick={close}
        />
      </ModalClose>
      <ContentWrapper>
        <ModalImage src={src} />
      </ContentWrapper>
    </Wrapper>
  )
}

export const showImageModal = createModal(ImageModal, {
  showBackground: false,
  width: 'auto'
})
