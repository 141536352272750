import React from 'react'
import { useTranslation } from 'react-i18next'
import ListingTableHeader from 'components/listings-table-header'
import { FilterBar, FilterButton } from 'components/search-filter'

const MyTenantsHeader = ({ tabCount, currentTab, setCurrentTab, onSearch }) => {
  const { t } = useTranslation()

  return (
    <ListingTableHeader
      title={t('l.tenants.list.tenants.title', 'Tenants')}
      onSearch={onSearch}
    >
      <FilterBar>
        <FilterButton
          active={currentTab === 'past'}
          onClick={() => setCurrentTab('past')}
        >
          {t('l.tenants.list.past.button', 'Past')} {`(${tabCount.past})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'current'}
          onClick={() => setCurrentTab('current')}
        >
          {t('l.tenants.list.current.button', 'Current')}{' '}
          {`(${tabCount.current})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'upcoming'}
          onClick={() => setCurrentTab('upcoming')}
        >
          {t('l.tenants.list.upcoming.button', 'Upcoming')}{' '}
          {`(${tabCount.upcoming})`}
        </FilterButton>
      </FilterBar>
    </ListingTableHeader>
  )
}

export default MyTenantsHeader
