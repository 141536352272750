import { useSelector } from 'react-redux'
import {
  path,
  defaultTo,
  find,
  compose,
  prop,
  propEq,
  pipe,
  values
} from 'ramda'
import { mergeSpec } from 'utils'

const getTag = tag =>
  pipe(
    path(['files', 'user_secure_files']),
    defaultTo({}),
    values,
    find(propEq('tag', tag)),
    defaultTo('')
  )

const useProfile = () =>
  useSelector(
    compose(
      mergeSpec({
        income_verification: getTag('income_verification'),
        employment_verification: getTag('employment_verification'),
        school_verification: getTag('school_verification')
      }),
      prop('profile')
    )
  )

export default useProfile
