import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontWeights, breakpoints } from 'styles'
import { Link } from 'react-router-dom'
import useBasic from 'hooks/use-basic'
import useMediaQuery from 'hooks/use-media-query'
import capitalize from 'utils/capitalize'
import LabeledDetail from 'components/labeled-detail'
import ProfileRequired from 'components/profile-required'
import Required from 'components/required-indicator'
import getLabels from 'features/Profile/EmploymentInfo/labels'
import { isEmpty, all, any } from 'ramda'
import { PanelHeader, PanelTitle, StyledPanel, RequiredText } from '../elements'

const EditButton = styled.button`
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
`

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: ${({ top }) => top || '0px'};
`

const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: space-between;
  }
`

const notNull = value => value !== null

const documentInfo = (data, t) => {
  const LABELS = getLabels(t)
  const inSchool = data.job_title === 'student'
  const isEmployed = data.job_title !== 'unemployed'
  const docs = []
  if (isEmployed)
    docs.push(
      inSchool ? data.school_verification : data.employment_verification
    )
  docs.push(data.income_verification)
  const isMissingDoc = any(isEmpty, docs)
  const isMissingAllDocs = all(isEmpty, docs)
  const docLabel = docs
    .map(doc => doc?.document_type)
    .map(key => LABELS[key] || '-')
  const label = !isMissingAllDocs ? docLabel.join(', ') : '-'
  return [label, isMissingDoc]
}

const EmploymentInfo = ({
  data,
  editable = false,
  editProps = {},
  showRequiredWarning = false
}) => {
  const { t } = useTranslation()
  const { professions } = useBasic(['languages', 'professions'])
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const detailWidth = displayMobile ? '45%' : '33%'
  const [doc, isMissingDoc] = documentInfo(data, t)
  const isStudent = data.job_title === 'student'
  const SupportingDoc = () => (
    <LabeledDetail
      label={
        <>
          {t(
            'r.profile.view.supporting_documents.label',
            'Supporting Documents'
          )}
          {isMissingDoc && <Required />}
        </>
      }
      value={doc}
      width="90%"
    />
  )
  return (
    <>
      <PanelHeader>
        <PanelTitle>
          {t('r.profile.view.financial_details.subtitle', 'Financial Details')}
          <ProfileRequired fields={['job_title', 'salary']} area="liv_score" />
        </PanelTitle>
        {editable && (
          <Link to={{ pathname: '/profile/liv-score/edit', state: editProps }}>
            <EditButton>{t('r.profile.view.edit.button', 'Edit')}</EditButton>
          </Link>
        )}
      </PanelHeader>
      {showRequiredWarning && (
        <RequiredText>
          {t('r.profile.view.required.label', '*Required')}
        </RequiredText>
      )}
      <StyledPanel hSpace={40} vSpace={40}>
        <DetailsWrapper top="15px">
          <DetailsRow>
            <LabeledDetail
              label={
                <>
                  {t('r.profile.view.profession.label', 'Profession')}
                  {!notNull(data.job_title) && <Required />}
                </>
              }
              value={
                notNull(data.job_title)
                  ? capitalize(professions[data.job_title].name)
                  : '-'
              }
              width={detailWidth}
            />
            <LabeledDetail
              label={
                <>
                  {t('r.profile.view.annual_salary.label', 'Annual Salary')}
                  {!notNull(data.job_title) && <Required />}
                </>
              }
              value={notNull(data.salary) ? data.salary : '-'}
              width={detailWidth}
            />
            {isStudent ? (
              <LabeledDetail
                label={t(
                  'r.profile.view.school_side_working.label',
                  'Part Time Work'
                )}
                value={
                  data.school_side_working === 0
                    ? t('r.profile.view.no.text', 'No')
                    : t('r.profile.view.yes.text', 'Yes')
                }
                width={detailWidth}
              />
            ) : (
              <SupportingDoc />
            )}
          </DetailsRow>
          {isStudent && (
            <DetailsRow>
              <LabeledDetail
                label={
                  <>
                    {t('r.profile.view.school_name.label', 'School Name')}
                    <ProfileRequired fields={['job_title']} area="liv_score" />
                  </>
                }
                value={data.school_name}
                width={detailWidth}
              />
              <LabeledDetail
                label={
                  <>
                    {t('r.profile.view.school_subject.label', 'Field of Study')}
                    <ProfileRequired fields={['job_title']} area="liv_score" />
                  </>
                }
                value={data.school_subject}
                width={detailWidth}
              />
              <LabeledDetail
                label={
                  <>
                    {t(
                      'r.profile.view.school_start_date.label',
                      'Year Started'
                    )}
                    <ProfileRequired fields={['job_title']} area="liv_score" />
                  </>
                }
                value={data.school_start_date}
                width={detailWidth}
              />
            </DetailsRow>
          )}
          {isStudent && (
            <DetailsRow>
              <SupportingDoc />
            </DetailsRow>
          )}
        </DetailsWrapper>
      </StyledPanel>
    </>
  )
}

export default React.memo(EmploymentInfo)
