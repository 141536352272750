import ENV_VARS from 'config'
import { post, put, del, get } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const getApplication = get(
  `${BACKEND_SERVER}/applications/%applicationId%`,
  {
    failSilently: true
  }
)
export const createApplication = post(
  `${BACKEND_SERVER}/applications/listings/%listingId%`
)
export const favouriteApplication = put(
  `${BACKEND_SERVER}/applications/%applicationId%/favourite`,
  {
    noLoading: true
  }
)
export const unfavouriteApplication = del(
  `${BACKEND_SERVER}/applications/%applicationId%/favourite`,
  {
    noLoading: true
  }
)
export const deleteApplication = del(
  `${BACKEND_SERVER}/applications/%applicationId%`
)
export const addExternal = post(`${BACKEND_SERVER}/applications/add_external`)

export const getApplications = get(
  `${BACKEND_SERVER}/users/bootstrap/listings/%listingId%/applications`
)
