import { init } from '@rematch/core'
import selectPlugin from '@rematch/select'
import models from 'models'

export const createStore = (options = {}) =>
  init({
    name: 'Lakshmi',
    models,
    plugins: [selectPlugin()],
    ...options
  })
const store = createStore()

export const getStore = () => {
  return store
}

export default store
const select = store.select
export { select }
