import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoints, fontSizes, colors } from 'styles'
import useProfile from 'hooks/use-profile'
import { Button } from 'components/button'
import { show as showRemoteAssistModal } from './assist-modal'
import { SectionHeading } from '../elements'

const Section = styled.section``

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 950px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Label = styled.label`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  max-width: 400px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 20px;
  }
`

const RemoteAssist = () => {
  const { t } = useTranslation()
  const profile = useProfile()
  if (!profile.allow_assist) return null
  return (
    <Section>
      <SectionHeading>
        {t('b.settings.view.remote_assist.title', 'Remote Assistance')}
      </SectionHeading>
      <InnerWrapper>
        <Label>
          {t(
            'b.settings.view.remote_assist_description.text',
            'Your account has been granted permission to remote assist another user'
          )}
        </Label>
        <Button onClick={showRemoteAssistModal} theme="danger" width={150}>
          {t('b.settings.view.remote_assist.button', 'Remote Assist')}
        </Button>
      </InnerWrapper>
    </Section>
  )
}

export default RemoteAssist
