import React from 'react'
import styled from 'styled-components'
import { fontWeights } from 'styles'
import LabeledValue from 'components/labeled-value'
import formatPaymentFrequency from 'utils/format-payment-frequency'
import formatCurrency from 'utils/format-currency'

const StyledLabeledValue = styled(LabeledValue)`
  margin-bottom: 20px;
`

const Strong = styled.span`
  font-weight: ${fontWeights.medium};
`

const FirstMonthLabel = () => (
  <span>
    <Strong>1st</Strong>
    {' payment'}
  </span>
)

const RestOfRentLabel = ({ frequency }) => (
  <span>
    <Strong>{formatPaymentFrequency(frequency)}</Strong>
    {' rent price'}
  </span>
)

RestOfRentLabel.defaultProps = {
  frequency: null
}

const RentInfoFooter = ({
  price,
  priceFrequency,
  extraOffer,
  extraOfferFrequency
}) => {
  const totalPrice = parseFloat(price) + parseFloat(extraOffer)

  if (extraOfferFrequency === 'one_time' && extraOffer !== '0.00') {
    return [
      <StyledLabeledValue
        key="first-month"
        label={<FirstMonthLabel />}
        value={formatCurrency(totalPrice)}
      />,
      <LabeledValue
        key="rest-rent"
        label={<RestOfRentLabel frequency={priceFrequency} />}
        value={formatCurrency(price)}
      />
    ]
  }

  return (
    <LabeledValue
      key="rent"
      label={<RestOfRentLabel frequency={priceFrequency} />}
      value={formatCurrency(totalPrice)}
    />
  )
}

RentInfoFooter.defaultProps = {
  price: null,
  priceFrequency: null,
  extraOffer: null,
  extraOfferFrequency: null
}

export default RentInfoFooter
