import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Button } from 'components/button'
import { fontSizes, fontWeights } from 'styles'
import { Row, Group, PasswordInput } from 'components/form'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import CheckMark from './images/Checkmark.svg'
import Dot from './images/Dot.svg'

const StyledForm = styled(Form)`
  align-self: center;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin: 20px auto 10px;
  display: block;
`

const WarningBox = styled.div`
  font-size: ${fontSizes.regular};
  font-size: ${fontSizes.small};
  letter-spacing: -0.21px;
  line-height: 16px;
`

const RequirementTitle = styled.p`
  margin: 0;
  line-height: 20px;
`

const Requirement = styled.p`
  margin: 0;
  line-height: 20px;
  font-weight: ${props =>
    props.complete ? fontWeights.medium : fontWeights.regular};
  &::before {
    margin-right: 10px;
    content: url('${props => (props.complete ? CheckMark : Dot)}');
    vertical-align: middle;
  }
`

const rule1 = new RegExp(/.{8,}/) // min. 8 characters
const rule2 = new RegExp(/\d/) // must contain a number
const rule3 = new RegExp(/[A-Za-z]/) // must contain a letter
const rule4 = new RegExp(/[!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?]/) // must contain a special character

const PasswordForm = ({ onSubmit, askCurrentPassword = false }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [requirements, setRequirements] = useState({})
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    setRequirements({
      one: rule1.test(password),
      two: rule2.test(password),
      three: rule3.test(password),
      four: rule4.test(password)
    })
  }, [password])

  const validationSchema = Yup.object().shape({
    ...(askCurrentPassword ? { old_password: Yup.string().required() } : {}),
    new_password: Yup.string()
      .min(8)
      .matches(
        /^\S*$/,
        t(
          'b.auth.edit.password_whitespace.error',
          'Whitespace characters not allowed.'
        )
      )
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?])[A-Za-z\d!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?]+$/,
        t(
          'b.auth.edit.password_doesn_match_requirements.error',
          "Password doesn't match the requirements."
        )
      )
      .required(),
    password_confirm: Yup.string()
      .oneOf(
        [Yup.ref('new_password'), null],
        t('b.auth.edit.passwords_must_match.error', 'Passwords must match')
      )
      .required()
  })

  const initialValues = {
    ...(askCurrentPassword ? { old_password: '' } : {}),
    new_password: '',
    password_confirm: ''
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, setFieldValue }) => (
        <StyledForm>
          {askCurrentPassword && (
            <Row>
              <Group col={12}>
                <PasswordInput
                  name="old_password"
                  placeholder={t(
                    'b.auth.edit.old_password.label',
                    'Enter Current Password'
                  )}
                />
              </Group>
            </Row>
          )}
          <Row>
            <Group col={12}>
              <WarningBox>
                <Trans i18nKey="b.auth.edit.password_requirements.text">
                  <RequirementTitle>Password Requirements:</RequirementTitle>
                  <Requirement complete={requirements.one}>
                    min. 8 characters
                  </Requirement>
                  <Requirement complete={requirements.two}>
                    at least 1 number
                  </Requirement>
                  <Requirement complete={requirements.three}>
                    at least 1 alphabet letter
                  </Requirement>
                  <Requirement complete={requirements.four}>
                    at least 1 special character
                  </Requirement>
                </Trans>
              </WarningBox>
            </Group>
          </Row>
          <Row>
            <Group col={12}>
              <PasswordInput
                name="new_password"
                onChange={e => {
                  setFieldValue('new_password', e.target.value)
                  setPassword(e.target.value)
                }}
                placeholder={t(
                  'b.auth.edit.password.label',
                  'Enter Password (min. 8 characters)'
                )}
                visible={showPassword}
                setVisible={setShowPassword}
              />
            </Group>
          </Row>
          <Row>
            <Group col={12}>
              <PasswordInput
                name="password_confirm"
                placeholder={t(
                  'b.auth.edit.confirm_password.label',
                  'Confirm Password'
                )}
                visible={showPassword}
              />
            </Group>
          </Row>
          <StyledButton
            type="submit"
            theme="primary"
            width={150}
            disabled={!isValid || isSubmitting}
            isSubmitting={isSubmitting}
          >
            {t('b.auth.edit.continue.button', 'Continue')}
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  )
}

export default PasswordForm
