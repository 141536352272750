import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/icon'
import { breakpoints } from 'styles'
import useTimeout from 'hooks/use-timeout'

const IconWrapper = styled.div`
  padding-right: 8px;
  ${props =>
    props.height &&
    `
    height: ${props.height}px;
  `}
`

const Yellow = styled.div`
  svg {
    g {
      fill: #ffc200;
    }
  }
`

const IconBase = ({ width, height, ...rest }) => (
  <IconWrapper width={width} height={height}>
    <Icon {...rest} width={width} height={height} />
  </IconWrapper>
)

const ConfettiIcon = () => <IconBase id="confetti" width={27} height={27} />

const WarningIcon = () => (
  <Yellow>
    <IconBase id="info_blue" width={23} height={23} />
  </Yellow>
)

const StatusIcon = ({ type }) =>
  type === 'warning' ? <WarningIcon /> : <ConfettiIcon />

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out 0s;
  ${props =>
    props.sticky &&
    `
    position: sticky;
    top: 0;
    z-index: 10;
  `}
  &[aria-hidden="true"] {
    opacity: 0;
  }
`

const warningBannerStyle = css`
  background: #ebf0f6;
  > p {
    margin-top: 2px;
    margin-bottom: 5px;
  }
`

const confirmationBannerStyle = css`
  background: #e8f0ef;
  > p {
    margin-top: 3px;
    margin-bottom: 0;
  }
`

const Banner = styled.div`
  padding: 15px;
  font-size: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  min-width: 400px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  ${confirmationBannerStyle}
  ${props => props.type === 'warning' && warningBannerStyle}
  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    min-width: 0;
  }

`

const Text = styled.p``

const AlertBanner = React.memo(({ message, sticky, type, ...rest }) => (
  <Wrapper sticky={sticky} {...rest}>
    <Banner type={type}>
      <StatusIcon type={type} />
      <Text>{message}</Text>
    </Banner>
  </Wrapper>
))

AlertBanner.displayName = 'AlertBanner'

const AlertBannerTimeout = ({ timeout, listingId, hasScrolled, ...rest }) => {
  const [closed, setClosed] = useState(false)

  useTimeout(() => {
    setClosed(true)
  }, timeout)

  useEffect(() => {
    if (hasScrolled) setClosed(true)
  }, [setClosed, hasScrolled])

  return <AlertBanner {...rest} aria-hidden={closed || hasScrolled} />
}

const AlertBannerWrapper = ({ timeout, listingId, hasScrolled, ...rest }) => {
  if (typeof listingId === 'undefined') return null
  if (timeout && timeout > 0)
    return (
      <AlertBannerTimeout
        {...rest}
        key={listingId}
        hasScrolled={hasScrolled}
        timeout={timeout}
        listingId={listingId}
      />
    )
  return <AlertBanner {...rest} />
}

export default React.memo(AlertBannerWrapper)
