import { colors, fontSizes } from 'styles'
import styled from 'styled-components'

const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-family: 'Open Sans';
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

export default AreaTitle
