import React, { useRef } from 'react'
import { DiscoveryBar } from 'features/Settings/ProfileDiscovery'
import { createModal, useModalize } from 'components/modalize'
import styled, { createGlobalStyle } from 'styled-components'
import useEventListener from 'hooks/use-event-listener'
import TenantProfile from './modal'

const HideScroll = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const TenantWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: block;
    height: max-content;
    max-width: 767px;
    width: 100%;
  }
`

const ForTenants = props => {
  const profile = useRef()
  const bar = useRef()
  useEventListener('mousedown', ({ target }) => {
    const isIn = ref => ref.current.contains(target)
    if (target.tagName === 'HTML' || isIn(profile) || isIn(bar)) return
    close()
  })
  const { close } = useModalize()
  return (
    <Wrapper>
      <HideScroll />
      <TenantWrapper>
        <TenantProfile {...props} ref={profile} />
      </TenantWrapper>
      <DiscoveryBar ref={bar} />
    </Wrapper>
  )
}

export const showTenantProfileAsLandlord = createModal(ForTenants, {
  width: '100%',
  borderRadius: '0px',
  clickOutside: false,
  showBackground: false,
  isFullscreen: true,
  alignSelf: 'flex-start'
})
