import styled from 'styled-components'

const ContentWrapper = styled.div`
  position: relative;
  max-width: 720px;
  width: 100%;
  margin: auto;
  ${({ flex = true }) =>
    flex &&
    `
  flex: 1;
  `}
`

export default ContentWrapper
