import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { breakpoints, colors, fontSizes } from 'styles'
import { Formik, Form } from 'formik'
import { Row, Group, FieldInput } from 'components/form'
import { Button } from 'components/button'
import { H1 } from 'components/headings'
import P from 'components/paragraph'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import { show as showSuccessModal } from 'components/success-modal'
import { useTranslation } from 'react-i18next'
import { requestResetPassword } from 'models/auth/ports'
import * as Yup from 'yup'

const ResetContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 425px;
  max-width: 95%;
  margin: 0 100px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
  text-align: center;
`

const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 425px;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 10px;
`

const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`

const DescriptionText = styled(P)`
  margin: 30px 20px;
`

const LoginForm = styled(Form)`
  width: 100%;
  padding: 0 30px;
`

const ButtonContainer = styled(Group)`
  text-align: center;
  margin: 15px auto;
`

const Reset = ({ history }) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object().shape({
    username: Yup.string().required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    const { response } = await requestResetPassword(values)
    setSubmitting(false)
    if (!response.ok) return
    return showSuccessModal({
      title: t('b.auth.edit.reset_sent.title', 'Password reset request sent.'),
      message: t(
        'b.auth.edit.reset_sent.text',
        'The password reset link will be sent to the email associated with your account. If you do not receive the reset link within 10-15 minutes, please try this process again.'
      ),
      onClose: () => history.push('/auth/login')
    })
  }
  return (
    <>
      <Helmet>
        <title>{t('b.auth.view.reset_password.title', 'Reset Password')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ResetContainer>
        <TaskbarContainer>
          <TaskbarItem left={<Tool to="/auth/login" type="back" />} />
          <Title>
            {t('b.auth.view.reset_password.title', 'Reset Password')}
          </Title>
        </TaskbarContainer>
        <DescriptionText>
          {t(
            'b.auth.view.reset_description.text',
            'A link to reset your password will be sent to the email associated with your account.'
          )}
        </DescriptionText>
        <Formik
          initialValues={{ username: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, isValid }) => (
            <LoginForm>
              <Row>
                <Group col={12}>
                  <FieldInput
                    name="username"
                    autoFocus
                    placeholder={t('b.auth.view.email.label', 'Email')}
                  />
                </Group>
              </Row>
              <Row>
                <ButtonContainer col={12}>
                  <Button
                    id="web-reset-password-button"
                    theme="primary"
                    width="100%"
                    disabled={isSubmitting || !isValid}
                    type="submit"
                  >
                    {t('b.auth.view.reset.button', 'Reset')}
                  </Button>
                </ButtonContainer>
              </Row>
            </LoginForm>
          )}
        </Formik>
      </ResetContainer>
    </>
  )
}

export default Reset
