import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { pipe } from 'ramda'
import { prefixUnitNumber } from 'utils/listings'
import Map from 'components/map'
import Marker from 'components/map/marker'
import Circle from 'components/map/circle'
import Icon from 'components/icon'
import toBoolean from 'utils/to-boolean'
import RouteDisplay from './route-display'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 350px;
`

const MapWrapper = styled.div`
  height: 350px;
  width: 100%;
  z-index: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 250px;
  }
`

const RoutesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
    padding-top: 25px;
  }
`

const RouteInfo = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 70px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    min-height: unset;
  }
`

const RouteInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RouteInfoIcons = styled.div`
  width: 24px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`

const RouteInfoContent = styled.div`
  width: 100%;
`

const Address = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 22px;
  margin: 0 10px 5px 0;
`

const Neighborhood = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.regular};
  margin: 0 0 10px 0;
`

const Distance = styled.p`
  color: ${colors.regular};
  font-size: 15px;
  font-weight: ${fontWeights.regular};
  margin: 3px 0 0 0;

  span {
    font-weight: ${fontWeights.medium};
  }
`

const StartIcon = styled.div`
  background: ${colors.lightGrey};
  border: 1px solid ${colors.regular};
  padding: 2px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin: 3px 15px 0 3px;
`

const StartIconInner = styled.div`
  position: relative;
  background: ${colors.regular};
  border: 2px solid ${colors.lightGrey};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-left: -1px;
  margin-top: -1px;
`

const CircleBox = styled.div`
  background: #ddd;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin: 6px auto;
`

const StyledIcon = styled(Icon)`
  margin-bottom: 3px;
`

const getLocation = (data, isHidden) => {
  let lat = Number(data.lat)
  let lng = Number(data.lng)

  if (isHidden) {
    lat = Math.floor(lat * 500 + 0.5) / 500
    lng = Math.floor(lng * 500 + 0.5) / 500
  }

  return {
    lat,
    lng
  }
}

const RANGE_BY = 15
const ONE_MINUTE_S = 60
const FIFTEEN_MINUTES_S = ONE_MINUTE_S * 15
const TRANSIT_TYPE_MAP = {
  bus: 'bus_station',
  subway: 'subway_station',
  boat: 'boats_station',
  railway: 'railway_station'
}

const TRANSIT_TYPES = Object.keys(TRANSIT_TYPE_MAP)

const processTransitRoute = (transitType = [], type) =>
  transitType
    .filter(item => item.time_s <= FIFTEEN_MINUTES_S && !!item.routes)
    .map(b => ({
      ...b,
      routes:
        b.routes &&
        b.routes.split(/\//g).map(item => item.replace(/\s/g, ' ').trim()),
      type
    }))

const groupByTime = list => {
  if (!list) return null

  const routes = TRANSIT_TYPES.reduce(
    (accum, type) => [
      ...accum,
      ...processTransitRoute(list[TRANSIT_TYPE_MAP[type]], type)
    ],
    []
  )

  const mergedRouteList = routes.reduce((acc, current) => {
    const key = Math.ceil(current.time_s / ONE_MINUTE_S / RANGE_BY) * RANGE_BY
    const obj = acc[key] || {}
    const arr = obj[current.type] || []

    return {
      ...acc,
      [key]: {
        ...obj,
        [current.type]: [...arr, current]
      }
    }
  }, {})

  return mergedRouteList
}

const removeDuplicateBusRoutes = bus =>
  bus && [
    ...new Set(
      bus
        .filter(f => !!f.routes)
        .reduce((accum, r) => [...accum, ...r.routes], [])
    )
  ]

const removeDuplicateRoutes = (routes, comparator) => {
  if (!routes) return undefined

  let needlesFound = []
  const cleanRoutes = routes.reduce((accum, route) => {
    const data = {
      ...route,
      routes: route.routes.filter(r => {
        const clean = !needlesFound.includes(r)

        if (comparator) {
          return clean && comparator(r)
        }

        return clean
      })
    }

    needlesFound = [...needlesFound, ...route.routes]

    if (data.routes.length > 0) {
      return [...accum, data]
    }

    return accum
  }, [])

  return cleanRoutes
}

const removeDuplicates = list => {
  const keys = Object.keys(list)

  const mergedRoutesList = keys.reduce(
    (accum, key) => ({
      ...accum,
      [key]: TRANSIT_TYPES.reduce(
        (acc, type) => ({
          ...acc,
          [type]:
            type === 'bus'
              ? removeDuplicateBusRoutes(list[key][type] || undefined)
              : removeDuplicateRoutes(list[key][type] || undefined)
        }),
        {}
      )
    }),
    {}
  )

  return mergedRoutesList
}

const removedDuplicatesBetweenBusGroups = (busRoutes, list, index) => {
  if (!busRoutes) return undefined

  const otherRoutes = Object.values(list)
    .slice(0, index)
    .filter(f => !!f.bus)
    .reduce((acc, cur) => [...acc, ...cur.bus], [])

  return busRoutes.filter(val => !otherRoutes.includes(val))
}

const removeDuplicatesBetweenTransitGroups = ({
  routes,
  list,
  index,
  type
}) => {
  if (!routes) return undefined
  const otherRoutes = Object.values(list)
    .slice(0, index)
    .reduce((acc, r) => (r ? [...acc, ...(r[type] || [])] : acc), [])
    .reduce((acc, r) => (r ? [...acc, ...r.routes] : acc), [])
  const comparator = r => !otherRoutes.includes(r)

  const newRoutes = routes.reduce((accum, route) => {
    const filteredRoutes = route.routes.filter(comparator)
    if (filteredRoutes.length === 0) return accum
    return [
      ...accum,
      {
        ...route,
        routes: filteredRoutes
      }
    ]
  }, [])

  return newRoutes
}

const removedDuplicatesBetweenGroups = list => {
  const newList = Object.keys(list).reduce(
    (accum, key, index) => ({
      ...accum,
      [key]: TRANSIT_TYPES.reduce(
        (acc, type) => ({
          ...acc,
          [type]:
            type === 'bus'
              ? removedDuplicatesBetweenBusGroups(list[key][type], list, index)
              : removeDuplicatesBetweenTransitGroups({
                  routes: list[key][type],
                  list,
                  index,
                  type
                })
        }),
        {}
      )
    }),
    {}
  )

  return newList
}

const TransitInfo = ({ data, unitNumber, address, isHidden }) => {
  useTranslation()
  if (!data.transit_info) return null
  const transitList = pipe(
    groupByTime,
    removeDuplicates,
    removedDuplicatesBetweenGroups
  )(data.transit_info)

  const unit = unitNumber ? `${prefixUnitNumber(unitNumber)} - ` : ''

  return (
    <Wrapper>
      <MapWrapper>
        <Map center={getLocation(data, isHidden)}>
          {isHidden ? (
            <Circle
              {...getLocation(data, isHidden)}
              radius={180}
              fillColor="rgba(74, 144, 226, 0.2)"
              strokeColor="rgba(74, 144, 226, 0.65)"
            />
          ) : (
            <Marker {...getLocation(data, isHidden)} />
          )}
        </Map>
      </MapWrapper>
      {toBoolean(Object.keys(transitList).length) && (
        <RoutesWrapper>
          <RouteInfoColumn>
            <RouteInfo>
              <StartIcon>
                <StartIconInner />
              </StartIcon>
              <RouteInfoContent>
                <Address>{`${unit}${address}`}</Address>
                {data.neighborhood && (
                  <Neighborhood>{data.neighborhood}</Neighborhood>
                )}
              </RouteInfoContent>
            </RouteInfo>
          </RouteInfoColumn>
          <RouteInfoColumn>
            <RouteInfo>
              <RouteInfoIcons>
                <StyledIcon id="walk" height={24} width={24} />
                <CircleBox />
                <CircleBox />
              </RouteInfoIcons>
              <RouteInfoContent>
                <Distance>
                  <Trans i18nKey="b.listing.view.within_walking_distance.text">
                    Within <span>15 min.</span> walking distance
                  </Trans>
                </Distance>
              </RouteInfoContent>
            </RouteInfo>
            {Object.keys(transitList).map(item => (
              <RouteDisplay key={`${item}-list`} data={transitList[item]} />
            ))}
          </RouteInfoColumn>
        </RoutesWrapper>
      )}
    </Wrapper>
  )
}

TransitInfo.defaultProps = {
  unitNumber: '',
  isHidden: false
}

export default React.memo(TransitInfo)
