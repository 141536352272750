import styled, { css } from 'styled-components'

export const hiddenStyle = css`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`

const Hidden = styled.div`
  ${hiddenStyle}
`

export default Hidden
