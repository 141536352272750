import React from 'react'
import config from 'config'
import AccountSettings from 'features/Settings/AccountSettings'
import DevSettings from 'features/Settings/Developer'
import RemoteAssist from 'features/Settings/RemoteAssist'

const AccountTab = () => {
  const isDev = config.BUILD_ENV === 'dev'

  return (
    <>
      <AccountSettings />
      <RemoteAssist />
      {!!isDev && <DevSettings />}
    </>
  )
}

export default AccountTab
