import React from 'react'
import styled from 'styled-components'
import Icon from './close.svg'

const Wrapper = styled.div`
  width: 14px;
  height: 14px;
  text-align: center;
  display: flex;
  align-items: center;
`

const Img = styled.img`
  height: 100%;
  width: auto;
`

const Close = () => (
  <Wrapper>
    <Img src={Icon} />
  </Wrapper>
)

export default Close
