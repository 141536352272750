import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'
import SuiteInfo from 'features/Listing/suite-info'
import PaymentCard from 'features/Payments/payment-card'
import PageSubtitle from 'components/page-subtitle'

const Wrapper = styled.div`
  margin: 0 0 50px 0;
  text-align: center;
`

const PaymentWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: left;

  > div {
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 10px;
  }
`

const PaymentTitle = styled.p`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 30px;
  margin: 50px 0px 20px 0px;
  text-align: ${props => (props.center ? 'center' : 'left')};
`

const EmptyText = styled.p`
  width: 100%;
  max-width: 600px;
  border: 1px solid ${colors.mediumLightGrey};
  margin: 0 auto;
  padding: 20px;
  background-color: ${colors.lightGrey};
`

const PaymentsDashboard = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loadPaymentData = dispatch.payment.getListingPayments
  const paymentsData = useSelector(state =>
    select.payment.listingPayments(state, { listingId })
  )

  useEffect(() => {
    loadPaymentData({ listingId })
  }, [listingId, loadPaymentData])

  if (!paymentsData) return null

  const handleGoToPayment = paymentId => {
    history.push(`/rental/${listingId}/payments/${paymentId}`)
    window.scrollTo(0, 0)
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('b.payments.view.payments.title', 'Payments')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageSubtitle>{t('b.view.payments.link', 'Payments')}</PageSubtitle>
      <SuiteInfo listingId={listingId} />
      <PaymentWrapper>
        <PaymentTitle>
          {t(
            'r.rental_dashboard.edit.upcoming_rent_payments.text',
            'Upcoming Rent Payment(s)'
          )}
        </PaymentTitle>

        {paymentsData['pending']?.length > 0 ? (
          paymentsData['pending']?.map(payment => (
            <PaymentCard
              key={payment.id}
              data={payment}
              onClick={() => handleGoToPayment(payment.id)}
            />
          ))
        ) : (
          <EmptyText>
            {t(
              'r.rental_dashboard.edit.no_upcoming_payments.text',
              'No upcoming payments'
            )}
          </EmptyText>
        )}
      </PaymentWrapper>
      <PaymentWrapper>
        <PaymentTitle>
          {t(
            'r.rental_dashboard.edit.past_rent_payments.text',
            'Past Rent Payment(s)'
          )}
        </PaymentTitle>
        {paymentsData['approved']?.length > 0 ? (
          paymentsData['approved']?.map(payment => (
            <PaymentCard
              key={payment.id}
              data={payment}
              onClick={() => handleGoToPayment(payment.id)}
            />
          ))
        ) : (
          <EmptyText>
            {t(
              'r.rental_dashboard.edit.no_past_payments.text',
              'No past payments'
            )}
          </EmptyText>
        )}
      </PaymentWrapper>
    </Wrapper>
  )
}

export default PaymentsDashboard
