import React from 'react'

const WizardContent = ({ children, currentStep }) =>
  React.Children.toArray(children).filter(
    child => child.props.index === currentStep
  )

WizardContent.defaultProps = {
  children: null,
  currentStep: null,
  className: null
}

export default WizardContent
