import ENV_VARS from 'config'
import { get, put, post, del } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

// Renter - Billing Address
export const updateBillingAddress = put(
  `${BACKEND_SERVER}/settlements/billing_address`
)
export const getBillingAddress = get(
  `${BACKEND_SERVER}/settlements/billing_address`
)
export const deleteBillingAddress = del(
  `${BACKEND_SERVER}/settlements/billing_address`
)

// Renter - Make Payment
export const makePayment = post(
  `${BACKEND_SERVER}/settlements/make_payment/%paymentRequestId%`
)

// Landlord - Payout - UnionPay
export const updateUnionPayPayout = put(
  `${BACKEND_SERVER}/settlements/payout/upop`
)
export const getUnionPayPayout = get(
  `${BACKEND_SERVER}/settlements/payout/upop`
)
export const deleteUnionPayPayout = del(
  `${BACKEND_SERVER}/settlements/payout/upop`
)

// Landlord - Payout - Stripe
export const getStripePayout = get(
  `${BACKEND_SERVER}/settlements/payout/stripe`
)
export const deleteStripePayout = del(
  `${BACKEND_SERVER}/settlements/payout/stripe`
)

// landlord - Payout - Verify deletion
export const verifyPayoutDeletion = get(
  `${BACKEND_SERVER}/settlements/payout/deletion_verify`
)
