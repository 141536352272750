import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { select } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { breakpoints, structure } from 'styles'
import { toUnit } from 'utils/styles'
import PageSubtitle from 'components/page-subtitle'
import ChatRoom from './chat-room'
import SideBar from './side-bar'

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${toUnit(structure.header.height)});
  position: relative;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
  }
`

const BasicSkeleton = ({
  match: {
    params: { listingId }
  },
  basePath,
  getTimelineSelector,
  sideBarHeader,
  chatFooterButton,
  noTimelinesMessage,
  notSelectedMessage
}) => {
  const { t } = useTranslation()

  const getChatPresets = useDispatch().chatPreset.getPresets

  useEffect(() => {
    getChatPresets()
  }, [getChatPresets])

  const getTimelines = useDispatch().listing.getListingTimelines
  const timelines = useSelector(getTimelineSelector(select, listingId))

  useEffect(() => {
    getTimelines(listingId)
  }, [getTimelines, listingId])

  const noTimelines = !timelines.length
  const emptyMessage = noTimelines ? noTimelinesMessage : notSelectedMessage

  const [isOpen, setIsOpen] = useState(!noTimelines)

  return (
    <>
      <Helmet>
        <title>{t('b.view.chat.title', 'Chat')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageSubtitle>{t('b.view.chat.link', 'Chat')}</PageSubtitle>
      <Wrapper>
        <SideBar
          isOpen={isOpen}
          closeNav={() => setIsOpen(false)}
          timelines={timelines}
          basePath={basePath}
          sideBarHeader={sideBarHeader}
          chatFooterButton={chatFooterButton}
        />
        <ChatRoom
          handleBack={() => setIsOpen(true)}
          sideBarHeader={sideBarHeader}
          chatFooterButton={chatFooterButton}
          emptyMessage={emptyMessage}
          noTimelines={noTimelines}
        />
      </Wrapper>
    </>
  )
}

export default withRouter(BasicSkeleton)
