import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import StripeCheckout from 'react-stripe-checkout'
import ENV_VARS from 'config'
import { WhitePage } from 'components/page'
import Content from 'components/content'
import LivLogo from './liv-logo.png'

const STRIPE_TRIGGER_ID = 'stripe-trigger'

const Button = styled.button`
  opacity: 0;
  height: 0;
  width: 0;
`

const mapState = () => ({})

const mapDispatch = dispatch => ({
  process: dispatch.payment.process
})

const openStripeModal = () => {
  document.getElementById(STRIPE_TRIGGER_ID).click()
}

class StripePayment extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = {
      requestId: null,
      shouldShowAgain: true,
      amount: 0
    }
    this.state = { ...this.initialState }
  }

  componentDidMount() {
    window.rnBridge = message => this.onMessage(message)
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: 'webview_ready' })
      )
    }
  }

  componentWillUnmount() {
    window.rnBridge = null
  }

  onToken(token) {
    this.setState({ shouldShowAgain: false })
    this.props.process({
      ...token,
      payment_request_id: this.state.requestId
    })
  }

  onMessage(message) {
    try {
      const { type, payload } = message
      if (type === 'stripe_payment_info') {
        this.setState(
          {
            ...payload
          },
          () => openStripeModal()
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  onClosed() {
    if (this.state.shouldShowAgain) {
      // Allows us to re-open the modal on close
      setTimeout(openStripeModal, 0)
    }
  }

  render() {
    return (
      <WhitePage>
        <Content>
          <StripeCheckout
            stripeKey={ENV_VARS.STRIPE_TOKEN}
            amount={Number(this.state.amount)}
            name="Liv Strategies Inc"
            description={this.state.description}
            image={LivLogo}
            locale="auto"
            currency="CAD"
            token={token => this.onToken(token)}
            closed={() => this.onClosed()}
            zipCode
            billingAddress
          >
            <Button id={STRIPE_TRIGGER_ID}>Modal Trigger</Button>
          </StripeCheckout>
        </Content>
      </WhitePage>
    )
  }
}

StripePayment.defaultProps = {
  location: {
    search: ''
  }
}

const StripePaymentContainer = withRouter(
  connect(mapState, mapDispatch)(StripePayment)
)

export default StripePaymentContainer
