import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes } from 'styles'
import { SwitchToggle } from 'components/form'

const HideToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: auto;

  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    margin: 10px 0;
  }
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 5px 0 0;
  }
`

const ToggleLabel = styled.label`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 20px;
  margin-right: 5px;
`

const HideListingToggle = ({ listingId, isHidden }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleToggleHidden = async () => {
    await dispatch.listing.update({
      listing_id: listingId,
      is_hidden: isHidden ? 0 : 1
    })
  }

  return (
    <HideToggle>
      <ToggleLabel htmlFor="hide_listing">
        {t(
          'l.listing.view.hide_listing_from_public_search.label',
          'Hide listing from public search'
        )}
      </ToggleLabel>
      <SwitchToggle
        id="hide_listing"
        enabled={isHidden}
        onChange={handleToggleHidden}
      />
    </HideToggle>
  )
}

export default HideListingToggle
