import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media print {
    padding-top: 0;
  }
`

const Page = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>

export default Page
