import styled from 'styled-components'
import { colors } from 'styles'
import Taskbar from './taskbar'

const WhiteTaskbar = styled(Taskbar)`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
`

export default WhiteTaskbar
