import generateDeepLink from './ports'

const deeplink = {
  name: 'deeplink',
  state: {},
  effects: dispatch => ({
    async generateDeeplink(payload) {
      dispatch.loading.setLoading()
      dispatch.error.clearError()
      try {
        const { body, response } = await generateDeepLink({
          body: payload
        })

        if (!response.ok) {
          throw body
        }

        return { data: body }
      } catch (error) {
        dispatch.error.setError(error)
        return { error }
      } finally {
        dispatch.loading.stopLoading()
      }
    }
  })
}

export default deeplink
