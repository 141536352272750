import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { colors } from 'styles'
import { RadioSelectorList, SelectorItem } from 'components/selector-list'
import { Button } from 'components/button'
import { FieldTextarea } from 'components/form'

const SelectorItemContainer = styled(SelectorItem)`
  background-color: ${colors.white};

  &:last-child {
    margin-bottom: 10px;
  }

  ${props =>
    props.visible &&
    `
    align-items: flex-start;
    margin-top: 20px;
  `}
`

const Description = styled.p`
  margin: 20px 0px 5px 0px;
`

const SubmitButton = styled(Button)`
  display: block;
  padding: 10px 20px;
  margin: 25px auto 0px auto;
  border-radius: 2px;
`

const AcceptedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`

const ListWrapper = styled.ul``

const ListItem = styled.li`
  margin-bottom: 10px;
`

const ReplyForm = ({ listingData, close }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const reply = dispatch.endTenancy.reply

  const formOptions = () => ({
    initialValues: {
      end_response: '',
      support_description: undefined
    },
    validationSchema: Yup.object().shape({
      end_response: Yup.string().required(),
      support_description: Yup.mixed().when('end_response', {
        is: 'support_requested',
        then: Yup.string().required(),
        otherwise: Yup.string()
      })
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await reply({
        values,
        listingId: listingData?.listings?.id
      })
      setSubmitting(false)
      if (response?.response?.ok) {
        close()
      }
    }
  })
  return (
    <Formik {...formOptions()}>
      {({ values, isSubmitting, isValid }) => (
        <Form>
          {
            // prettier-ignore
            <Description>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_response.text',
                "What's your answer to this request?"
              )}
            </Description>
          }
          <RadioSelectorList name="end_response" value={values.end_response}>
            <SelectorItemContainer
              height="auto"
              value="accepted"
              visible={values?.end_response === 'accepted'}
            >
              <AcceptedWrapper visible={values?.end_response === 'accepted'}>
                {t(
                  'r.rental_dashboard.edit.resources_end_tenancy_modal_agree.radio',
                  'I agree to end tenancy'
                )}
                {values?.end_response === 'accepted' && (
                  <ListWrapper>
                    <ListItem>
                      {t(
                        'r.rental_dashboard.edit.resources_end_tenancy_modal_agreement_1.text',
                        'I certify that I will/have settled all disputes and damages (if applicable)'
                      )}
                    </ListItem>
                    <ListItem>
                      {t(
                        'r.rental_dashboard.edit.resources_end_tenancy_modal_agreement_2.text',
                        'I certify that I will/have settled all payment balances remaining or refunds (if applicable)'
                      )}
                    </ListItem>
                    {
                      // prettier-ignore
                      <ListItem>
                        {t(
                          'r.rental_dashboard.edit.resources_end_tenancy_modal_agreement_3.text',
                          'I agree to Liv\'s Terms & Conditions'
                        )}
                      </ListItem>
                    }
                  </ListWrapper>
                )}
              </AcceptedWrapper>
            </SelectorItemContainer>
            <SelectorItemContainer value="declined">
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_decline.radio',
                'I do not want to end tenancy'
              )}
            </SelectorItemContainer>
            <SelectorItemContainer value="support_requested">
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_request_support.radio',
                'I request LIV support'
              )}
            </SelectorItemContainer>
          </RadioSelectorList>
          {values?.end_response === 'support_requested' && (
            <>
              <Description>
                {t(
                  'r.rental_dashboard.edit.resources_end_tenancy_modal_response_describe.text',
                  'Describe your problem as much as you can'
                )}
              </Description>
              <FieldTextarea
                name="support_description"
                maxLength={500}
                height={200}
              />
            </>
          )}
          <SubmitButton type="submit" theme="primary">
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_reply.button',
              'Reply to Request'
            )}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default ReplyForm
