import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Avatar } from 'components/avatar'
import { colors, breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'
import UserFullName from 'components/user'
import { Button } from 'components/anchor'
import isTruthy from 'utils/is-truthy'
import { getImageUrl } from 'utils/image'

const ViewSignature = styled.div`
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 100px;
  width: 100%;
  background-image: url('${({ url }) => url}');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    height: 80px;
  }
`
const Wrapper = styled.div`
  padding: 5px 10px;
  border-bottom: 2px dashed ${colors.grey};
  position: relative;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const SignatureContainer = styled.div`
  flex: 9999;
  height: 80px;
  width: 100%;
  justify-content: center;
  display: flex;
  text-align: center;
`

const nameStyle = css`
  padding: 5px 0px;
  flex: 9999;
  display: flex;
  justify-content: center;
  font-size: 13px;
`

const SigneeName = styled(UserFullName)`
  ${nameStyle}
`

const Awaiting = styled.span`
  display: flex;
  align-items: center;
  margin-top: 25px;
`

const AvatarContainer = styled.div`
  position: absolute;
  bottom: 15px;
`

const StyledButton = styled(Button)`
  color: ${colors.link};
  margin-top: 25px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 14px;
  }
`
const SignaturePreview = ({
  renter,
  signatures,
  isCurrentUser,
  toggleSignaturePad,
  localSignature
}) => {
  const { t } = useTranslation()
  const [showSignature, setShowSignature] = useState(false)

  const toggleViewSignature = () => {
    setShowSignature(!showSignature)
  }

  const fetchUrl = () => {
    const signature =
      signatures.find(({ userId }) => userId === renter.id) || {}
    return signature.url
  }

  const hasSigned = () =>
    isTruthy(fetchUrl()) || (localSignature !== null && isCurrentUser)

  const checkLocalSignature = () =>
    localSignature && isCurrentUser ? localSignature : fetchUrl()

  const renderAvatar = () => (
    <AvatarContainer>
      <Avatar
        location={getImageUrl(renter.avatar?.aws_s3_key, {
          width: 70,
          height: 70
        })}
        width={35}
        height={35}
        shadow
      />
    </AvatarContainer>
  )

  return (
    <>
      <Wrapper>
        {renderAvatar()}
        {hasSigned() ? (
          <SignatureContainer>
            {showSignature ? (
              <ViewSignature url={checkLocalSignature()} />
            ) : (
              <StyledButton onClick={toggleViewSignature}>
                {t(
                  'b.contract.view.reveal_signature.text',
                  'Click to reveal signature'
                )}
              </StyledButton>
            )}
          </SignatureContainer>
        ) : (
          <SignatureContainer>
            {isCurrentUser ? (
              <StyledButton onClick={toggleSignaturePad}>
                {t('b.contract.view.tap_to_sign.text', 'Tap to sign')}
              </StyledButton>
            ) : (
              <Awaiting>
                {t(
                  'b.contract.view.awaiting_signature.text',
                  'Awaiting Signature...'
                )}
              </Awaiting>
            )}
          </SignatureContainer>
        )}
      </Wrapper>
      <SigneeName firstName={renter.first_name} lastName={renter.last_name} />
    </>
  )
}

SignaturePreview.defaultProps = {
  signatures: [],
  renter: {},
  localSignature: null
}

export default SignaturePreview
