import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import ProfileButtonBar from 'features/Profile/button-bar'
import PageTitle from 'components/page-title'
import {
  Label,
  Row,
  Group,
  FieldInput,
  PasswordInput,
  Input
} from 'components/form'
import Page from 'components/page'
import Panel from 'components/panel'
import ContentWrapper from 'components/content-wrapper'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import { show as showSuccessModal } from 'components/success-modal'
import { deleteAccount } from 'models/requests/ports'
import config from 'config'

const { WEB_URL, APP_PATH } = config

const URL = `${WEB_URL}${APP_PATH}`

const PanelContainer = styled(Panel)`
  margin: 80px 0 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 40px 10px 0;
  }
`

const PanelHeading = styled.h1`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  border-bottom: 1px solid ${colors.grey};
  padding-bottom: 30px;
  margin-bottom: 30px;
`

const StyledLabel = styled(Label)`
  font-size: 14px;
`

const DeleteLabel = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`

const Keyword = styled.span`
  background: #dde1e6;
  font-weight: 700;
  border-radius: 4px;
  padding: 5px 8px;
  color: ${colors.secondary};
  margin: 0 2px;
`

const StyledGroup = styled(Group)`
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const StyledRow = styled(Row)`
  margin: 0;
`

const TermsContainer = styled.div`
  display: inline;
`

const TermsRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
`

const TermsLabel = styled(Label)`
  font-size: 15px;
  font-weight: ${fontWeights.medium};
  margin: 0;
  padding: 0;
`

const Link = styled.a`
  color: ${colors.link};
  font-weight: ${fontWeights.medium};
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }
`

const CheckBox = styled(Input)``

const DeleteAccount = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6
  const feedback = history?.location?.state || {}
  const withFeedback = msg => {
    if (Object.keys(feedback).length <= 0) return msg
    return (
      msg +
      '\n ' +
      t(
        'b.feedback_modal.view.description.text',
        'Your feedback was successfully submitted.  We appreciate your time and input!'
      )
    )
  }

  const logoutAndRedirect = async () => {
    await dispatch.auth.logout()
    window.location.assign(URL)
  }

  const formOptions = () => ({
    initialValues: {
      password: '',
      confirm_text: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(),
      confirm_text: Yup.string()
        .matches(
          /^(Delete|delete)$/,
          t(
            'b.settings.edit.text_must_match.error',
            'Please enter the text exactly as displayed to confirm'
          )
        )
        .required()
    }),
    onSubmit: values => {
      confirm(
        t('b.settings.edit.cannot_be_undone.title', 'This Cannot Be Undone'),
        t(
          'b.settings.edit.are_you_sure_delete_account.text',
          'Are you sure you want to delete this account?'
        )
      ).then(async () => {
        const { response } = await deleteAccount({
          body: {
            ...feedback,
            password: values.password
          }
        })
        if (response.ok) {
          return showSuccessModal({
            title: t(
              'b.settings.edit.account_deleted.title',
              'Account Deleted'
            ),
            message: withFeedback(
              t(
                'b.settings.edit.account_deleted_description.text',
                `We're sad to see you go! Your account will be deleted within 3 business days.`
              )
            ),
            onClose: () => logoutAndRedirect()
          })
        }
      })
    }
  })

  return (
    <Formik {...formOptions()}>
      {({ isSubmitting, isValid }) => (
        <Form className="flex-full">
          <Page>
            <ContentWrapper className="mb-50">
              <Helmet>
                <title>
                  {t('b.settings.edit.delete_account.title', 'Delete Account')}
                </title>
                <meta name="robots" content="noindex, nofollow" />
              </Helmet>
              <PageTitle>
                {t('b.settings.edit.delete_account.title', 'Delete Account')}
              </PageTitle>
              <PanelContainer vSpace={30} hSpace={30}>
                <PanelHeading>
                  {t(
                    'b.settings.edit.delete_my_account.title',
                    'Delete My Account'
                  )}
                </PanelHeading>
                <StyledLabel>
                  {t(
                    'b.settings.edit.enter_password_to_delete.label',
                    'To delete your account, please enter your current password.'
                  )}
                </StyledLabel>
                <StyledGroup col={colSize}>
                  <StyledRow>
                    <PasswordInput
                      name="password"
                      id="password"
                      placeholder={t(
                        'b.settings.edit.enter_password_to_delete_field.label',
                        'Password'
                      )}
                    />
                  </StyledRow>
                </StyledGroup>
                <DeleteLabel>
                  <Trans i18nKey="b.settings.edit.type_delete_to_confirm.label">
                    Type in <Keyword>Delete</Keyword> below to confirm.
                  </Trans>
                </DeleteLabel>
                <StyledGroup col={colSize}>
                  <StyledRow>
                    <FieldInput
                      name="confirm_text"
                      id="confirm_text"
                      placeholder={t(
                        'b.settings.edit.enter_password_to_confirm_field.label',
                        'Confirm'
                      )}
                    />
                  </StyledRow>
                </StyledGroup>
                <StyledGroup col={12}>
                  <TermsRow>
                    <TermsContainer inline>
                      <Trans i18nKey="b.settings.edit.agree_to_terms.label">
                        <TermsLabel>
                          I agree to Liv{`'`}s{' '}
                          <Link
                            href={`${WEB_URL}/terms`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions.
                          </Link>
                        </TermsLabel>
                      </Trans>
                    </TermsContainer>
                    <CheckBox
                      name="agree_to_terms"
                      id="agree_to_terms"
                      type="checkbox"
                      checked={acceptedTerms}
                      onChange={e => setAcceptedTerms(e.target.checked)}
                    />
                  </TermsRow>
                </StyledGroup>
              </PanelContainer>
            </ContentWrapper>
            <ProfileButtonBar
              handleBack={() => history.push('/settings')}
              sticky
            >
              <Button
                theme="primary"
                width={150}
                left="auto"
                type="submit"
                disabled={!acceptedTerms || !isValid}
                isSubmitting={isSubmitting}
              >
                {t('b.settings.edit.delete.label', 'Delete')}
              </Button>
            </ProfileButtonBar>
          </Page>
        </Form>
      )}
    </Formik>
  )
}

export default DeleteAccount
