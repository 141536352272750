import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { path } from 'ramda'
import { colors, fontSizes, breakpoints } from 'styles'
import Page from 'components/page'
import ContentWrapper from 'components/content-wrapper'
import Panel from 'components/panel'
import AreaTitle from 'components/area-title'
import { AnchorButton } from 'components/anchor'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import StripeLogin from 'components/stripe-login'
import Icon from 'components/icon'
import PageTitle from 'components/page-title'
import useProfile from 'hooks/use-profile'

const StyledAreaTitle = styled(AreaTitle)`
  height: auto;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
`

const StyledPanel = styled(Panel)`
  padding: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  padding: 50px 0;

  :first-child {
    border-bottom: 1px solid ${colors.grey};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    align-items: center;
  }
`

const Label = styled.p`
  display: flex;
  align-items: center;
  width: 200px;
  color: ${colors.regular};
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.31px;
  line-height: 21px;
  margin: 0;

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 5px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: auto;
    margin-bottom: 15px;
    text-align: center;
  }
`

const Description = styled.p`
  flex: 1;
  color: ${colors.regular};
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0;
  max-width: 300px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    text-align: center;
    margin-bottom: 30px;
  }
`

const StyledButton = styled(AnchorButton)`
  letter-spacing: -0.39px;
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`

const PaymentSettingsDashboard = ({ history }) => {
  const { t } = useTranslation()
  const profile = useProfile()
  const stripeConnected = profile.company_info?.ach_connected.includes('stripe')
  const unionPayConnected = profile.company_info?.ach_connected.includes('upop')
  const userRole = useSelector(path(['session', 'session', 'user_role']))
  const isAdmin = userRole === 'administrator'

  const adminTitles = {
    none: t(
      'l.payment_settings.view.what_type_of_payments.text',
      'What type of payments would you like to accept?'
    ),
    some: t(
      'l.payment_settings.view.accecpt_all_payment_types.text',
      'Would you like to accept all types of payments? '
    ),
    all: t('l.payment_settings.view.you_are_all_set.text', `You're all set!`)
  }

  const agentTitles = {
    none: t(
      'l.payment_settings.view.contact_admin_to_add_payment_methods.text',
      `Contact your company's administrator to add payment methods`
    ),
    some: t(
      'l.payment_settings.view.contact_admin_to_add_view_payment_methods.text',
      `Contact your company's administrator to add an additional payment method or see payment method details`
    ),
    all: t(
      'l.payment_settings.view.contact_admin_to_view_payment_methods.text',
      `Contact your company's administrator to view payment method details`
    )
  }

  const getSectionTitle = () => {
    const accounts = [stripeConnected, unionPayConnected]
    const total = accounts.length
    const connected = accounts.filter(Boolean).length
    const titles = isAdmin ? adminTitles : agentTitles
    if (connected === 0) return titles.none
    return connected === total ? titles.all : titles.some
  }

  return (
    <Page>
      <ContentWrapper>
        <PageTitle>
          {t('l.settings.view.payment_settings.title', 'Payment Settings')}
        </PageTitle>
        <StyledAreaTitle>{getSectionTitle()}</StyledAreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <Row>
            <LabelWrapper>
              <Label>
                {stripeConnected && (
                  <StyledIcon
                    id="checked_green"
                    width={24}
                    height={24}
                    data-testid="check-stripe"
                  />
                )}
                {t(
                  'l.payment_settings.view.visa_mastercard.label',
                  'Visa / Mastercard / American Express'
                )}
              </Label>
              <Description>
                {stripeConnected
                  ? t(
                      'l.payment_settings.view.stripe_disconnect_description.text',
                      'You may disconnect your Stripe account anytime.'
                    )
                  : t(
                      'l.payment_settings.view.stripe_add_description.text',
                      'Receive credit card payments by Stripe.'
                    )}
              </Description>
            </LabelWrapper>
            <StripeLogin
              path="/settings/payment-settings"
              isConnected={stripeConnected}
              disabled={!isAdmin}
            />
          </Row>
          <Row>
            <LabelWrapper>
              <Label>
                {unionPayConnected && (
                  <StyledIcon
                    id="checked_green"
                    width={24}
                    height={24}
                    data-testid="check-unionpay"
                  />
                )}
                {t('l.payment_settings.view.unionpay.label', 'UnionPay')}
              </Label>
              <Description>
                {unionPayConnected
                  ? t(
                      'l.payment_settings.view.unionpay_remove_description.text',
                      'To edit this account info, please remove the current one, and re-enter your new info.'
                    )
                  : t(
                      'l.payment_settings.view.unionpay_add_description.text',
                      'Receive UnionPay by Payout Deposit.'
                    )}
              </Description>
            </LabelWrapper>
            {unionPayConnected ? (
              <StyledButton
                height={48}
                width={120}
                outline="true"
                to="/settings/payment-settings/unionpay/view"
                disabled={!isAdmin}
                aria-disabled={!isAdmin}
                data-testid="view-unionpay"
              >
                {t('l.payment_settings.view.view.button', 'View')}
              </StyledButton>
            ) : (
              <StyledButton
                height={48}
                width={120}
                to="/settings/payment-settings/unionpay/add"
                disabled={!isAdmin}
                aria-disabled={!isAdmin}
                role="button"
                data-testid="connect-unionpay"
              >
                {t('l.payment_settings.view.connect.button', 'Connect')}
              </StyledButton>
            )}
          </Row>
        </StyledPanel>
      </ContentWrapper>
      <ButtonBar sticky>
        <Button
          theme="sane"
          width={150}
          inversed
          disabled={false}
          onClick={() => history.replace('/settings')}
        >
          {t('l.payment_settings.view.back.button', 'Back')}
        </Button>
      </ButtonBar>
    </Page>
  )
}

export default PaymentSettingsDashboard
