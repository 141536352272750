import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors, fontWeights } from 'styles'
import Icon from 'components/icon'

const Wrapper = styled.div`
  width: 100%;
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h5`
  color: ${colors.regular};
  font-size: 17px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.34px;
  line-height: 21px;
`

const Text = styled.p`
  color: ${colors.regular};
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: right;
  margin: 0;
`

const ProgressBar = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${colors.grey};
  margin-top: 15px;

  &::after {
    content: '';
    height: 4px;
    width: ${props => `${props.progress}%`};
    border-radius: 0 2px 2px 0;
    background-color: ${colors.secondary};
    display: block;
    transition: width 0.2s ease-out;
  }
`

const CloseButton = styled(Icon)`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: -16px;
  right: -16px;
  height: 48px;
  width: 48px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
  }
`

const Progress = ({ progress, title, autoClose, closeToast }) => {
  if (autoClose && progress >= 100) {
    setTimeout(closeToast, autoClose)
  }

  return (
    <>
      <Wrapper>
        {progress >= 100 && (
          <CloseButton id="close_button_dark" onClick={closeToast} />
        )}
        <InnerWrapper>
          <Title>{title}</Title>
          <Text>{progress}%</Text>
        </InnerWrapper>
        <ProgressBar progress={progress} />
      </Wrapper>
    </>
  )
}
export default Progress
