import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { colors, breakpoints, fontWeights, fontSizes } from 'styles'
import { useTranslation, Trans } from 'react-i18next'
import { select } from 'store'
import { path } from 'ramda'
import useMediaQuery from 'hooks/use-media-query'
import Panel from 'components/panel'
import Page from 'components/page'
import { CollapsibleBox } from 'components/collapsible'
import AreaTitle from 'components/area-title'
import {
  SharedSelectorItem,
  SharedSelectorList,
  CustomSharedSelectorList,
  CustomSharedSelectorItem
} from 'components/selector-list'
import {
  Label,
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldDatePicker,
  FieldSwitchToggle,
  FieldTextarea
} from 'components/form'
import capitalizeSentence from 'utils/capitalize-sentence'
import { ExtraFeesList } from './extra-fees'

const Break = styled.div`
  ${props =>
    props.on &&
    `
    flex-basis: 100%;
  `}
  height: 0;
`

const Wrapper = styled(Page)`
  padding-top: 20px;
  padding-bottom: 50px;
`

const Subtitle = styled.h3`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  margin-top: ${props => props.top || 0}px;
  margin-bottom: 20px;
`

const DescriptionText = styled.p`
  font-size: ${fontSizes.small};
  margin: 0;
  padding: 5px 15px;

  span {
    font-weight: ${fontWeights.medium};
  }
`

const RentSummaryWrapper = styled.div`
  background-color: ${colors.lightGrey};
  border-radius: 6px;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px;
    flex-direction: column;
  }
`

const RentSummaries = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const RentSummary = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 10px 0;

  p {
    margin: 0 0 3px;

    :last-child {
      font-size: 18px;
      font-weight: ${fontWeights.medium};
    }
  }
`

const OfferHeader = styled.div`
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid #ccc;
  padding: 0 20px;
  font-weight: ${fontWeights.medium};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`

const setTitle = (title, alt, prop) => (prop !== 'one_time' ? title : alt)

const setStringToFloat = val => parseFloat(val !== '' ? val : '0')

const handleAllowPets = (values, setFieldValue) => {
  if (values.pet_policy) {
    setFieldValue('allow_cats', null)
    setFieldValue('allow_dogs', null)
    setFieldValue('pet_limitations', false)
    setFieldValue('pet_deposit', '0')
  }
  setFieldValue('pet_policy', !values.pet_policy)
}

const handlePetLimitations = (values, setFieldValue) => {
  setFieldValue('allow_cats', values.pet_limitations ? null : 1)
  setFieldValue('allow_dogs', values.pet_limitations ? null : 1)
  setFieldValue('pet_limitations', !values.pet_limitations)
}

const handleExtraOffer = (values, setFieldValue) => {
  if (values.extra_offer_enabled) {
    setFieldValue('extra_offer', '')
    setFieldValue('extra_offer_frequency', '')
  }
  setFieldValue('extra_offer_enabled', !values.extra_offer_enabled)
}

const Amenities = ({
  amenitiesSelectionData,
  availableAmenitiesList,
  label,
  listName,
  listId,
  amenityTranslationContext,
  colSize,
  showOptions,
  options = ['private', 'shared']
}) => {
  const { t } = useTranslation()

  return (
    <Group col={colSize}>
      <Label strong bottom={20}>
        {label}
      </Label>
      <SharedSelectorList
        name={listName}
        id={listId}
        value={amenitiesSelectionData}
        defaultType={options[0]}
      >
        {availableAmenitiesList.map(feature => (
          <SharedSelectorItem
            key={feature.txt_id}
            featureName={feature.name}
            value={feature.txt_id}
            showOptions={showOptions}
            options={options}
          >
            {t(`${amenityTranslationContext}.${feature.txt_id}.text`)}
          </SharedSelectorItem>
        ))}
      </SharedSelectorList>
    </Group>
  )
}

const CustomAmenities = ({
  amenitiesSelectionData,
  label,
  listName,
  listId,
  addText,
  placeholder,
  colSize,
  showOptions,
  options = ['private', 'shared']
}) => (
  <Group col={colSize}>
    <Label strong bottom={20}>
      {label}
    </Label>
    <CustomSharedSelectorList
      name={listName}
      id={listId}
      value={amenitiesSelectionData}
      addText={addText}
      placeholder={placeholder}
      defaultType={options[0]}
    >
      {amenitiesSelectionData.map((amenity, i) => (
        <CustomSharedSelectorItem
          key={i}
          value={amenity}
          showOptions={showOptions}
          options={options}
        >
          {amenity.name}
        </CustomSharedSelectorItem>
      ))}
    </CustomSharedSelectorList>
  </Group>
)

const ContractTerms = ({ data, errors, contractId, listingId, setFieldValue }) => {
  const { t } = useTranslation()

  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  const features = useSelector(select.basic.features)
  const utilities = useSelector(select.basic.utilities)
  const unitScope = useSelector(
    path(['listing', listingId, 'unit', 'unit_type_scope_txt_id'])
  )
  const rentFreq = data.price_frequency
  const offerFreq = data.extra_offer_frequency
  const rentPrice = data.price
  const offerPrice = data.extra_offer
  const isLeaseFixed = data.lease_type === 'fixed'

  const extraOfferOptions = frequency => [
    {
      value: 'one_time',
      label: t('l.contract.edit.payment_frequency_one_time.text', 'One-time')
    },
    ...(frequency !== 'one_time'
      ? [
          {
            value: frequency,
            label: t(
              `l.contract.edit.payment_frequency_${frequency}.text`,
              capitalizeSentence(frequency).replace('_', ' ')
            )
          }
        ]
      : [])
  ]

  const getFrequencyTitle = () => {
    switch (rentFreq) {
      case 'daily':
        return setTitle(
          t(
            'l.contract.edit.rent_total_daily.title',
            'Total rental price per day'
          ),
          t('l.contract.edit.rent_daily.title', 'Rental price per day'),
          offerFreq
        )
      case 'weekly':
        return setTitle(
          t('l.contract.edit.weekly.title', 'Total rental price per week'),
          t('l.contract.edit.rent_weekly.title', 'Rental price per week'),
          offerFreq
        )
      case 'monthly':
        return setTitle(
          t(
            'l.contract.edit.rent_total_monthly.title',
            'Total rental price per month'
          ),
          t('l.contract.edit.rent_monthly.title', 'Rental price per month'),
          offerFreq
        )
      default:
        return t(
          'l.contract.edit.rent_one_time.title',
          'Total rental price one time'
        )
    }
  }

  const totalRentalPrice = () => {
    if (rentFreq === offerFreq) {
      return setStringToFloat(rentPrice) + setStringToFloat(offerPrice)
    }
    return setStringToFloat(rentPrice)
  }

  const firstMonthPrice = () => {
    if (offerFreq === 'one_time' && rentFreq !== 'one_time') {
      return setStringToFloat(rentPrice) + setStringToFloat(offerPrice)
    }
    return false
  }

  const initialMonth = () => {
    return data.start_date ? moment(data.start_date) : null
  }

  if (!data) return null

  const isFixed = data?.lease_type === 'fixed'
  return (
    <Wrapper sticky>
      <AreaTitle>
        {t(
          'l.contract.edit.define_rental_terms.subtitle',
          'Define Your Rental Terms'
        )}
      </AreaTitle>
      <Panel hSpace={30} vSpace={30}>
        <Subtitle>
          {t('l.contract.edit.tenancy_details.label', 'Tenancy Details')}
        </Subtitle>
        <Row>
          <Group col={colSize}>
            <FieldDropdown
              name="lease_type"
              placeholder={
                t('l.contract.edit.lease_type.label', 'Lease type') + '*:'
              }
              options={[
                {
                  value: 'fixed',
                  label: t('common.fixed_term', 'Fixed term')
                },
                {
                  value: 'month_to_month',
                  label: t('common.month_to_month', 'Month to month')
                }
              ]}
              isSearchable={false}
            />
          </Group>
          <Break on={displayMobile} />
          <Group col={colSize}>
            <FieldDatePicker
              name="start_date"
              placeholder={
                t('l.contract.edit.start_date.label', 'Tenancy Start Date') +
                '*:'
              }
              maxDate={
                isLeaseFixed && data.end_date
                  ? moment(data.end_date)
                  : undefined
              }
              maxDateMessage={null}
            />
          </Group>
        </Row>
        {isFixed && (
          <Row>
            <Group col={colSize}>
              <FieldDatePicker
                name="end_date"
                disabled={!data.start_date}
                placeholder={
                  t('l.contract.edit.end_date.label', 'Tenancy End Date') + '*:'
                }
                initialFocusedDate={initialMonth()}
                minDate={moment(data.start_date)}
                minDateMessage={null}
              />
            </Group>
            <Group col={colSize}>
              <FieldDropdown
                name="lease_end_action"
                placeholder={
                  t(
                    'l.contract.edit.when_lease_expires.label',
                    'When Lease Expires'
                  ) + '*:'
                }
                options={[
                  {
                    value: 'continue_month_to_month',
                    label: t(
                      'l.contract.edit.continue_monthly.label',
                      'Continue Month to Month'
                    )
                  },
                  {
                    value: 'tenant_must_vacate',
                    label: t(
                      'l.contract.edit.must_move_out.label',
                      'Tenant Must Move Out'
                    )
                  }
                ]}
                isSearchable={false}
              />
            </Group>
          </Row>
        )}

        <Subtitle top={10}>
          {t('l.contract.edit.strata_info.label', 'Strata Info')}
        </Subtitle>
        <Row>
          <Group col={6}>
            <FieldInput
              name="strata_lot_number"
              placeholder={
                t('l.contract.edit.strata_lot_no.label', 'Strata Lot No.') + ':'
              }
            />
          </Group>
          <Group col={6}>
            <FieldInput
              name="strata_plan_number"
              placeholder={
                t('l.contract.edit.strata_plan_no.label', 'Strata Plan No.') +
                ':'
              }
            />
          </Group>
        </Row>
        <Subtitle top={10}>
          {t('l.contract.edit.rent_details.label', 'Rent Details')}
        </Subtitle>
        <Row>
          <Group col={6}>
            <FieldInput
              name="price"
              placeholder={
                t('l.contract.edit.rental_price.label', 'Rent price') + '*:'
              }
              prefix="$"
            ></FieldInput>
          </Group>
          <Group col={6}>
            <FieldDropdown
              name="price_frequency"
              onChange={() => setFieldValue('extra_offer_frequency', '')}
              placeholder={
                t('l.contract.edit.payment_freq.label', 'Payment frequency') +
                '*:'
              }
              options={[
                {
                  value: 'daily',
                  label: t(
                    'l.contract.edit.payment_frequency_daily.text',
                    'Daily'
                  )
                },
                {
                  value: 'weekly',
                  label: t(
                    'l.contract.edit.payment_frequency_weekly.text',
                    'Weekly'
                  )
                },
                {
                  value: 'monthly',
                  label: t(
                    'l.contract.edit.payment_frequency_monthly.text',
                    'Monthly'
                  )
                },
                {
                  value: 'one_time',
                  label: t(
                    'l.contract.edit.payment_frequency_one_time.text',
                    'One-time'
                  )
                }
              ]}
              isSearchable={false}
            />
          </Group>
        </Row>
        <CollapsibleBox
          title={t('l.contract.edit.added_offer.label', 'Added Offer')}
          top={15}
          maxLength={22}
          header={OfferHeader}
          headerToggle={
            <FieldSwitchToggle
              onChange={() => handleExtraOffer(data, setFieldValue)}
              name="extra_offer_enabled"
            />
          }
        >
          {!!data.extra_offer_enabled && (
            <>
              <Row>
                <Group col={colSize}>
                  <FieldInput
                    name="extra_offer"
                    placeholder={
                      t(
                        'l.contract.edit.added_offer_amount.label',
                        'Added offer amount'
                      ) + ':'
                    }
                    prefix="$"
                  />
                </Group>
                <Group col={colSize}>
                  <FieldDropdown
                    key={data.price_frequency}
                    name="extra_offer_frequency"
                    placeholder={
                      t(
                        'l.contract.edit.payment_freq.label',
                        'Payment frequency'
                      ) + ':'
                    }
                    options={extraOfferOptions(data.price_frequency)}
                    isSearchable={false}
                  />
                </Group>
              </Row>
            </>
          )}
          <Row>
            <DescriptionText>
              <Trans i18nKey="l.contract.edit.added_offer_description.text">
                <span>Added Offer</span> lets renters offer an additional amount
                of money on the top of your initial rent price.
              </Trans>
            </DescriptionText>
          </Row>
        </CollapsibleBox>
        {(!!data.extra_offer_enabled && !errors?.extra_offer) && (
          <RentSummaryWrapper>
            <RentSummaries>
              {firstMonthPrice() && (
                <RentSummary>
                  <p>
                    {t(
                      'l.contract.edit.first_month_price.text',
                      '1st month rent price'
                    )}
                  </p>
                  <p>${firstMonthPrice()}</p>
                </RentSummary>
              )}

              <RentSummary>
                <p>{getFrequencyTitle()}</p>
                <p>${totalRentalPrice()}</p>
              </RentSummary>
            </RentSummaries>
          </RentSummaryWrapper>
        )}
        <Row top={20}>
          <Group col={colSize}>
            <FieldInput
              name="security_deposit"
              placeholder={
                t(
                  'l.contract.edit.security_deposit.label',
                  'Security deposit'
                ) + '*:'
              }
              prefix="$"
            />
          </Group>
        </Row>
      </Panel>
      <AreaTitle>
        {t(
          'l.contract.edit.features_included.subtitle',
          'Select All Features Included'
        )}
      </AreaTitle>
      <Panel hSpace={30} vSpace={30}>
        <Row>
          <Amenities
            amenitiesSelectionData={data.contract_features}
            availableAmenitiesList={features}
            label={t(
              'l.listing.edit.standard_features.label',
              'Standard features'
            )}
            listName="contract_features"
            listId="contract_features"
            amenityTranslationContext="basic:b.listing.unit_features"
            colSize={colSize}
            showOptions={unitScope !== 'entire'}
          />
          <CustomAmenities
            amenitiesSelectionData={data.contract_custom_features}
            label={t('l.listing.edit.custom_features.label', 'Custom features')}
            listName="contract_custom_features"
            listId="contract_custom_features"
            addText={t(
              'l.contract.edit.add_custom_feature.label',
              'Add custom feature'
            )}
            placeholder={t(
              'l.contract.edit.enter_custom_feature.placeholder',
              'Enter custom feature'
            )}
            colSize={colSize}
            showOptions={unitScope !== 'entire'}
          />
        </Row>
      </Panel>
      <AreaTitle>
        {t(
          'l.contract.edit.utilities_included.subtitle',
          'Select All Utilities Included'
        )}
      </AreaTitle>
      <Panel hSpace={30} vSpace={30}>
        <Row>
          <Amenities
            amenitiesSelectionData={data.contract_utilities}
            availableAmenitiesList={utilities}
            label={t(
              'l.contract.edit.standard_utilities.label',
              'Standard Utilities'
            )}
            listName="contract_utilities"
            listId="contract_utilities"
            amenityTranslationContext="basic:b.listing.utilities"
            colSize={colSize}
            showOptions={unitScope !== 'entire'}
            options={['included', 'shared', 'metered']}
          />
          <CustomAmenities
            amenitiesSelectionData={data.contract_custom_utilities}
            label={t(
              'l.contract.edit.custom_utilities.label',
              'Custom Utilities'
            )}
            listName="contract_custom_utilities"
            listId="contract_custom_utilities"
            addText={t(
              'l.contract.edit.add_custom_utility.label',
              'Add custom utility'
            )}
            placeholder={t(
              'l.contract.edit.enter_custom_utility.placeholder',
              'Enter custom utility'
            )}
            colSize={colSize}
            showOptions={unitScope !== 'entire'}
            options={['included', 'shared', 'metered']}
          />
        </Row>
      </Panel>
      <AreaTitle>
        {t(
          'l.contract.edit.additional_rental_terms.subtitle',
          'Additional Rental Terms'
        )}
      </AreaTitle>
      <Panel hSpace={30} vSpace={30}>
        <CollapsibleBox
          title={t('l.contract.edit.pet_policy.label', 'Pet Policy')}
          maxLength={22}
        >
          <Row>
            <Group col={12} inline justify="space-between">
              <Label noGutter>
                {t('l.contract.edit.pets_allowed.label', 'Pets allowed')}
              </Label>
              <FieldSwitchToggle
                name="pet_policy"
                onChange={() => handleAllowPets(data, setFieldValue)}
              />
            </Group>
          </Row>
          {!!data.pet_policy && (
            <>
              <Row>
                <Group col={12} inline justify="space-between">
                  <Label noGutter>
                    {t(
                      'l.contract.edit.set_restrictions.label',
                      'Set restrictions'
                    )}
                  </Label>
                  <FieldSwitchToggle
                    name="pet_limitations"
                    onChange={() => handlePetLimitations(data, setFieldValue)}
                  />
                </Group>
              </Row>
              {!!data.pet_limitations && (
                <Row>
                  <Group col={6}>
                    <FieldInput
                      name="allow_cats"
                      placeholder={
                        t('l.contract.edit.cats.label', 'Cats') + ':'
                      }
                    />
                  </Group>
                  <Group col={6}>
                    <FieldInput
                      name="allow_dogs"
                      placeholder={
                        t('l.contract.edit.dogs.label', 'Dogs') + ':'
                      }
                    />
                  </Group>
                </Row>
              )}
              <Row>
                <Group col={colSize}>
                  <FieldInput
                    name="pet_deposit"
                    placeholder={
                      t(
                        'l.contract.edit.pet_deposit_amount.label',
                        'Pet deposit amount'
                      ) + ':'
                    }
                    prefix="$"
                  />
                </Group>
              </Row>
              <DescriptionText>
                {t(
                  'l.contract.edit.pet_deposit_disclaimer.text',
                  'By law, the Pet Deposit Amount cannot be more than half of Monthly Rental Price.'
                )}
              </DescriptionText>
            </>
          )}
        </CollapsibleBox>
        <CollapsibleBox
          title={t('l.contract.edit.parking_info.label', 'Parking Info')}
          top={40}
          maxLength={22}
        >
          <Row>
            <Group col={12} inline justify="space-between">
              <Label noGutter>
                {t(
                  'l.contract.edit.parking_available.label',
                  'Parking available'
                )}
              </Label>
              <FieldSwitchToggle name="parking.enabled" />
            </Group>
          </Row>
          {data.parking.enabled && (
            <>
              <Row>
                <Group col={12}>
                  <FieldTextarea
                    name="parking.name"
                    maxLength={100}
                    placeholder={t(
                      'l.contract.edit.write_info.placeholder',
                      'Write info'
                    )}
                  />
                </Group>
              </Row>
              <Row>
                <Group col={12} inline justify="space-between">
                  <Label noGutter>
                    {t('l.contract.edit.parking_fee.label', 'Parking fee')}
                  </Label>
                  <FieldSwitchToggle name="parking.hasFee" />
                </Group>
              </Row>
              {data.parking.hasFee && (
                <>
                  <Row>
                    <Group col={colSize}>
                      <FieldInput
                        name="parking.fee"
                        placeholder={
                          t(
                            'l.contract.edit.parking_fee_amount.label',
                            'Parking fee amount'
                          ) + ':'
                        }
                        prefix="$"
                      />
                    </Group>
                    <Group col={colSize}>
                      <FieldDropdown
                        name="parking.fee_frequency_txt_id"
                        placeholder={
                          t(
                            'l.contract.edit.payment_frequency.label',
                            'Payment frequency'
                          ) + ':'
                        }
                        options={[
                          {
                            value: 'weekly',
                            label: t(
                              'l.contract.edit.payment_frequency_weekly.text',
                              'Weekly'
                            )
                          },
                          {
                            value: 'monthly',
                            label: t(
                              'l.contract.edit.payment_frequency_monthly.text',
                              'Monthly'
                            )
                          },
                          {
                            value: 'one_time',
                            label: t(
                              'l.contract.edit.payment_frequency_one_time.text',
                              'One-time'
                            )
                          }
                        ]}
                        isSearchable={false}
                      />
                    </Group>
                  </Row>
                </>
              )}
            </>
          )}
        </CollapsibleBox>
        <CollapsibleBox
          title={t('l.contract.edit.storage_info.label', 'Storage Info')}
          top={40}
        >
          <Row>
            <Group col={12} inline justify="space-between">
              <Label noGutter>
                {t(
                  'l.contract.edit.storage_available.label',
                  'Storage available'
                )}
              </Label>
              <FieldSwitchToggle name="storage.enabled" />
            </Group>
          </Row>
          {data.storage.enabled && (
            <>
              <Row>
                <Group col={12}>
                  <FieldTextarea
                    name="storage.name"
                    maxLength={100}
                    placeholder={t(
                      'l.contract.edit.write_info.placeholder',
                      'Write info'
                    )}
                  />
                </Group>
              </Row>
              <Row>
                <Group col={12} inline justify="space-between">
                  <Label noGutter>
                    {t('l.contract.edit.storage_fee.label', 'Storage fee')}
                  </Label>
                  <FieldSwitchToggle name="storage.hasFee" />
                </Group>
              </Row>
              {data.storage.hasFee && (
                <>
                  <Row>
                    <Group col={colSize}>
                      <FieldInput
                        name="storage.fee"
                        placeholder={
                          t(
                            'l.contract.edit.storage_fee_amount.label',
                            'Storage fee amount'
                          ) + ':'
                        }
                        prefix="$"
                      />
                    </Group>
                    <Group col={colSize}>
                      <FieldDropdown
                        name="storage.fee_frequency_txt_id"
                        placeholder={
                          t(
                            'l.contract.edit.payment_frequency.label',
                            'Payment frequency'
                          ) + ':'
                        }
                        options={[
                          {
                            value: 'weekly',
                            label: t(
                              'l.contract.edit.payment_frequency_weekly.text',
                              'Weekly'
                            )
                          },
                          {
                            value: 'monthly',
                            label: t(
                              'l.contract.edit.payment_frequency_monthly.text',
                              'Monthly'
                            )
                          },
                          {
                            value: 'one_time',
                            label: t(
                              'l.contract.edit.payment_frequency_one_time.text',
                              'One-time'
                            )
                          }
                        ]}
                        isSearchable={false}
                      />
                    </Group>
                  </Row>
                </>
              )}
            </>
          )}
        </CollapsibleBox>
      </Panel>
      <ExtraFeesList data={data.additional_fees} contractId={contractId} />
    </Wrapper>
  )
}

export default React.memo(ContractTerms)
