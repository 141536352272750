import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { upperFirst } from 'lodash'
import Icon from 'components/icon'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 20px 40px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: ${breakpoints.phoneMax}) {
    grid-gap: 20px;
    grid-template-columns: 1fr;
  }
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 24px;
`

const Name = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 0;
  word-break: break-word;
`

const Type = styled(Name)`
  font-weight: ${fontWeights.medium};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const AmenitiesDisplay = ({
  amenities,
  customAmenities,
  showType,
  translationContext
}) => {
  const { t } = useTranslation()

  const renderTypes = (type, types) =>
    types ? (
      <Column>
        {Object.keys(types).map(amenity => (
          <Type key={amenity}>
            {types[amenity]}{' '}
            {t(`b.listing_features.view.${amenity}.label`, upperFirst(amenity))}
          </Type>
        ))}
      </Column>
    ) : (
      <Type>
        {t(`b.listing_features.view.${type}.label`, upperFirst(type))}
      </Type>
    )

  const renderAmenity = amenity => (
    <Item key={`amenity-${amenity.txt_id}`}>
      <Name>{t(`${translationContext}.${amenity.txt_id}.text`)}</Name>
      {showType ? (
        renderTypes(amenity.type, amenity.types)
      ) : (
        <Icon id="check" height={24} width={24} />
      )}
    </Item>
  )

  const renderCustomAmenity = amenity => (
    <Item key={`custom-${amenity.name}`}>
      <Name>{amenity.name}</Name>
      {showType ? (
        <Type>
          {t(
            `b.listing_features.view.${amenity.type}.label`,
            upperFirst(amenity.type)
          )}
        </Type>
      ) : (
        <Icon id="check" height={24} width={24} />
      )}
    </Item>
  )

  return (
    <Wrapper>
      {amenities && amenities.map(feature => renderAmenity(feature))}
      {customAmenities &&
        customAmenities.map(feature => renderCustomAmenity(feature))}
    </Wrapper>
  )
}

AmenitiesDisplay.defaultProps = {
  amenities: [],
  customAmenities: []
}

export default AmenitiesDisplay
