import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const pillBackgroundColor = {
  default: 'white',
  primary: colors.secondary,
  success: colors.green,
  invalid: colors.darkGrey,
  error: colors.red
}

const pillColor = {
  default: colors.regular,
  primary: colors.white,
  success: colors.white,
  invalid: colors.white,
  error: colors.white
}

const Container = styled.p`
  height: ${props => props.height}px;
  line-height: ${props => props.height}px;
  width: ${props => props.width}px;
  border-radius: ${props => props.radius}px;
  background-color: ${props => pillBackgroundColor[props.theme]};
  color: ${props => pillColor[props.theme]};
  text-align: center;
  font-size: 12px;
  margin: 0 auto;
  ${props =>
    props.theme === 'default' &&
    `
    border: 1px solid ${colors.grey};
  `}
`

const Pill = props => <Container {...props}>{props.children}</Container>

Pill.defaultProps = {
  height: 30,
  width: 80,
  radius: 2,
  theme: 'default'
}

export default Pill
