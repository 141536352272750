import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { breakpoints, colors } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import Panel from 'components/panel'
import { Row, Group, FieldDropdown, FieldInput } from 'components/form'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import StateDropdown from 'components/state-dropdown'

const FormPanel = styled(Panel)`
  position: relative;
`

const ButtonRow = styled(Row)`
  justify-content: space-between;
  padding: 10px;
`

const RemoveButton = styled.p`
  position: absolute;
  top: 15px;
  right: 33px;
  color: ${colors.link};
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 16px;
  margin: 0;
`

const Edit = ({
  setIsEditing,
  hasAddress,
  addressData,
  formattedCountries,
  countries
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  const formOptions = () => ({
    initialValues: {
      street1: addressData?.street1 || '',
      street2: addressData?.street2 || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      country: addressData?.country || 'ca',
      zip: addressData?.zip || ''
    },
    validationSchema: Yup.object().shape({
      street1: Yup.string().required(),
      street2: Yup.string(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      country: Yup.string().required(),
      zip: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await dispatch.settlements.updateBillingAddress(values)
      setSubmitting(false)

      if (response.ok) {
        setIsEditing(false)
      }
    }
  })

  const handleRemove = () => {
    return confirm(
      t(
        'r.settings.edit.remove_billing_address.title',
        'Remove Billing Address?'
      ),
      t(
        'r.settings.edit.remove_billing_address.text',
        'Are you sure you want to remove your billing address?'
      )
    ).then(async () => {
      const response = await dispatch.settlements.deleteBillingAddress()
      if (response.ok) {
        setIsEditing(false)
      }
    })
  }

  const countryDropDown = setFieldValue => (
    <Group col={colSize}>
      <FieldDropdown
        id="country"
        name="country"
        options={formattedCountries}
        onChange={() => setFieldValue('state', '')}
        placeholder={t('r.settings.edit.country.label', 'Country') + ':'}
      />
    </Group>
  )

  const provinceDropDown = values => (
    <StateDropdown
      col={colSize}
      country={values.country}
      countries={countries}
      name="state"
    />
  )

  return (
    <Formik {...formOptions()}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <FormPanel vSpace={40} hSpace={30}>
            {hasAddress && (
              <RemoveButton onClick={handleRemove}>
                {t('r.settings.edit.remove.button', 'Remove')}
              </RemoveButton>
            )}
            <Row>
              <Group col={colSize}>
                <FieldInput
                  name="street1"
                  placeholder={
                    t(
                      'r.settings.edit.street_address.label',
                      'Street address'
                    ) + ':'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="street2"
                  placeholder={
                    t(
                      'r.settings.edit.apt_suite_unit_number.label',
                      'Suite No. / Unit No.'
                    ) + ':'
                  }
                />
              </Group>
            </Row>
            <Row>
              <Group col={colSize}>
                <FieldInput
                  name="city"
                  placeholder={t('r.settings.edit.city.label', 'City') + ':'}
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="zip"
                  placeholder={
                    t('r.settings.edit.postal_code.label', 'Postal code') + ':'
                  }
                />
              </Group>
            </Row>
            <Row>
              {countryDropDown(setFieldValue)}
              {provinceDropDown(values)}
            </Row>
            <ButtonRow>
              <Button
                theme="sane"
                inversed
                width={110}
                onClick={() => setIsEditing(false)}
              >
                {t('b.button_bar.edit.cancel.button', 'Cancel')}
              </Button>
              <Button
                type="submit"
                theme="primary"
                width={110}
                disabled={isSubmitting}
              >
                {hasAddress
                  ? t('b.button_bar.edit.update.button', 'Update')
                  : t('b.button_bar.edit.add.button', 'Add')}
              </Button>
            </ButtonRow>
          </FormPanel>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
