import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTemplate from './card-template'
import equifaxExcellent from './images/Credit_Score_Excellent.png'

const ExcellentCredit = props => {
  const { t } = useTranslation()

  const creditDescription =
    props.userRole === 'tenant'
      ? t(
          'r.liv_score_card.view.excellent_credit_description.text',
          'Very good! Your Liv score is looking great!'
        )
      : t(
          'l.liv_score_card.view.excellent_credit_description.text',
          'You should have little to worry at all about this renter when it comes to receiving your rent in time.'
        )

  return (
    <CardTemplate
      userRole={props.userRole}
      title={props.title}
      creditDetails={props.creditDetails}
      creditDescription={creditDescription}
      creditRatingResult={t(
        'b.liv_score_card.view.excellent.text',
        'Excellent'
      )}
      cardImage={equifaxExcellent}
      avatarUrl={props.avatarUrl}
      professionId={props.professionId}
      csoVerifiedState={props.csoVerifiedState}
      salarySort={props.salarySort}
      listingData={props.listingData}
      data-testid={props['data-testid']}
    />
  )
}

ExcellentCredit.defaultProps = {
  userRole: 'tenant',
  title: '',
  creditDetails: {}
}

export default ExcellentCredit
