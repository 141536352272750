import { useCallback } from 'react'
import useEventListener from 'hooks/use-event-listener'

const useKeyPress = (targetKey, handler, element = global) => {
  const handleKeyDown = useCallback(
    e => {
      if (targetKey === e.key) {
        handler(e)
      }
    },
    [handler, targetKey]
  )
  useEventListener('keydown', handleKeyDown, element)
}

export default useKeyPress
