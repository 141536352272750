import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${props => props.gutter}px;
  margin-left: -${props => props.gutter}px;
  margin-top: ${props => props.top}px;
  margin-bottom: ${props => props.bottom}px;
  padding-top: ${props => props.paddingTop}px;
  padding-bottom: ${props => props.paddingBottom}px;
  align-items: baseline;

  ${props =>
    props.justify &&
    `
    justify-content: ${props.justify};
  `}
`
const Row = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {React.Children.map(children, child => {
      if (!child) return null
      return React.cloneElement(child, {
        gutter: rest.gutter
      })
    })}
  </Wrapper>
)

Row.defaultProps = {
  gutter: 10
}

export default React.memo(Row)
