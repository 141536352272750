import { useSelector } from 'react-redux'
import {
  __,
  or,
  head,
  equals,
  filter,
  compose,
  path,
  pathOr,
  values
} from 'ramda'

const useCompany = companyId =>
  useSelector(
    compose(
      or(__, {}),
      head,
      filter(compose(equals(companyId), pathOr(false, ['id']))),
      values,
      path(['session', 'companies'])
    )
  )

export default useCompany
