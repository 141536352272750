import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { toNumber } from 'lodash'
import moment from 'moment'
import { colors, fontSizes, fontWeights } from 'styles'
import toBoolean from 'utils/to-boolean'
import formatLeaseType from 'utils/format-lease-type'
import { getPaymentFrequency } from 'utils/listings'
import CurrencyFormatter from 'components/currency-formatter'
import ListingSection from 'components/listing-section'
import ReadMoreText from 'components/read-more-text'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 50px;
`

const Title = styled.h4`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: 700;
  padding-top: 30px;
  margin: 30px 0;
  width: 100%;
  border-top: 1px solid ${colors.grey};

  @media (max-width: 1165px) {
    border-top: none;
    padding-top: 0px;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  line-height: 26px;
  box-shadow: inset 0 -1px 0 0 ${colors.grey};

  &:last-child {
    box-shadow: none;
  }

  @media (min-width: 1166px) {
    box-shadow: none;
    width: calc(50% - 18px);

    &:nth-child(odd) {
      margin-right: 36px;
    }
  }
`

const DetailText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 16px 0;
`

const DetailTextBold = styled(DetailText)`
  font-weight: ${fontWeights.medium};
`

const formatPrice = price => <CurrencyFormatter value={price} />

const LeaseDetails = ({ listings, unit }) => {
  const { t } = useTranslation()

  const getAdditionalFeatures = features => {
    const featureData = {
      parking: {
        name: t('b.listing.view.parking_available.text', 'Parking available')
      },
      storage: {
        name: t('b.listing.view.storage.available.text', 'Storage available')
      }
    }

    const formatFee = (fee, frequency) => {
      if (frequency === 'free') {
        return (
          <DetailTextBold>
            {t('b.listing.view.included.text', 'Included')}
          </DetailTextBold>
        )
      }
      return (
        <DetailTextBold>
          {formatPrice(fee)} / {getPaymentFrequency(frequency)}
        </DetailTextBold>
      )
    }

    return features.map(feature => {
      if (feature.fee_frequency_txt_id === 'not_available') {
        return null
      }
      const featureId = feature.fee_txt_id
      return (
        <DetailItem key={featureId}>
          <DetailText>{featureData[featureId].name}</DetailText>
          {formatFee(feature.fee, feature.fee_frequency_txt_id)}
        </DetailItem>
      )
    })
  }

  const renderAvailability = date => {
    const isTodayOrPast = moment().diff(date) >= 0
    return isTodayOrPast ? (
      t('b.listing.view.available_now.text', 'Available now')
    ) : (
      <Moment format="MMM D">{date}</Moment>
    )
  }

  const minLeaseLength = (value, measure) => {
    const count = toNumber(value)
    if (measure === 'no_minimum' || value === '0') {
      return t('b.listing.view.lease_length.text', 'No minimum')
    } else if (measure === 'days') {
      return t('b.listing.view.lease_length_day.text', {
        defaultValue: '{{count}} day',
        defaultValue_plural: '{{count}} days',
        count
      })
    } else if (measure === 'weeks') {
      return t('b.listing.view.lease_length_week.text', {
        defaultValue: '{{count}} week',
        defaultValue_plural: '{{count}} weeks',
        count
      })
    } else if (measure === 'months') {
      return t('b.listing.view.lease_length_month.text', {
        defaultValue: '{{count}} month',
        defaultValue_plural: '{{count}} months',
        count
      })
    } else if (measure === 'years') {
      return t('b.listing.view.lease_length_year.text', {
        defaultValue: '{{count}} year',
        defaultValue_plural: '{{count}} years',
        count
      })
    }
    return ''
  }

  const formatMaxOccupancy = value =>
    t('b.listing.view.max_occupancy_people.text', {
      defaultValue: '{{count}} person',
      defaultValue_plural: '{{count}} people',
      count: value
    })

  return (
    <Wrapper>
      <ListingSection
        condensed
        title={t('b.listing.view.lease_details.text', 'Lease Details')}
      >
        <InnerWrapper>
          <DetailItem>
            <DetailText>
              {t('b.listing.view.move_in.text', 'Move-in')}
            </DetailText>
            <DetailTextBold>
              {renderAvailability(listings.availability_date)}
            </DetailTextBold>
          </DetailItem>
          <DetailItem>
            <DetailText>{t('common.lease_type', 'Lease type')}</DetailText>
            <DetailTextBold>
              {formatLeaseType(listings.lease_type, t)}
            </DetailTextBold>
          </DetailItem>
          {toBoolean(listings.max_occupancy) && (
            <DetailItem>
              <DetailText>
                {t('b.listing.view.max_occupancy.text', 'Max. occupancy')}
              </DetailText>
              <DetailTextBold>
                {formatMaxOccupancy(toNumber(listings.max_occupancy))}
              </DetailTextBold>
            </DetailItem>
          )}
          {unit.gr_floors && (
            <DetailItem>
              <DetailText>
                {t('b.listing.view.available_floors.text', 'Available floors')}
              </DetailText>
              <DetailTextBold>{unit.gr_floors}</DetailTextBold>
            </DetailItem>
          )}
          {unit.gr_count && (
            <DetailItem>
              <DetailText>
                {t('b.listing.view.units_available.text', 'Units available')}
              </DetailText>
              <DetailTextBold>{unit.gr_count}</DetailTextBold>
            </DetailItem>
          )}
          {toBoolean(unit.count_dens) && (
            <DetailItem>
              <DetailText>
                {t(
                  'b.listing.view.den_flex_included.text',
                  'Den / flex included'
                )}
              </DetailText>
              <DetailTextBold>{unit.count_dens}</DetailTextBold>
            </DetailItem>
          )}
          {toBoolean(
            minLeaseLength(
              listings.min_lease_period,
              listings.min_lease_period_type
            )
          ) && (
            <DetailItem>
              <DetailText>
                {t('b.listing.view.minimum_lease.title', 'Minimum lease')}
              </DetailText>
              <DetailTextBold>
                {minLeaseLength(
                  listings.min_lease_period,
                  listings.min_lease_period_type
                )}
              </DetailTextBold>
            </DetailItem>
          )}
          {getAdditionalFeatures(listings.listing_fees)}
          {!!listings.description && (
            <>
              <Title>
                {t('b.listing.view.description.title', 'About this property')}
              </Title>
              <ReadMoreText description={listings.description} maxChar={200} />
            </>
          )}
        </InnerWrapper>
      </ListingSection>
    </Wrapper>
  )
}

LeaseDetails.propTypes = {
  unit: PropTypes.object.isRequired,
  listings: PropTypes.object.isRequired
}

export default LeaseDetails
