import React from 'react'
import ChatSkeleton from 'features/Chat/basic-skeleton'

const RentalChat = props => (
  <ChatSkeleton
    {...props}
    basePath="rental"
    getTimelineSelector={(select, listingId) => state =>
      select.chat.listingTimelines(state, { listingId })}
  />
)

export default RentalChat
