import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import PasswordForm from 'features/Auth/PasswordForm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 95vh;
  border-radius: 5px;
  background-color: ${colors.white};
  padding-top: 30px;
`

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
`

const TitleText = styled.h1`
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.large};
`

const SubtitleText = styled.h2`
  font-size: ${fontSizes.regular};
  margin: 20px 0px;
  text-align: center;
`

const PasswordModal = props => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const dispatch = useDispatch()

  const onSubmit = async (values, { setSubmitting }) => {
    const { error } = await dispatch.auth.setInitialPassword(values)
    setSubmitting(false)
    if (!error) {
      close()
    }
  }

  return (
    <Wrapper>
      <ScrollWrapper>
        <TitleText>
          {t('b.auth.edit.important_update.title', 'Important Update')}
        </TitleText>
        <SubtitleText>
          {t(
            'b.auth.edit.create_password_description.text',
            'To continue to provide a secure platform, we now require passwords for all accounts.'
          )}
        </SubtitleText>
        <PasswordForm onSubmit={onSubmit} />
      </ScrollWrapper>
    </Wrapper>
  )
}

export const showCreatePasswordModal = createModal(PasswordModal, {
  clickOutside: false
})
