import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { colors, fontSizes, fontWeights, breakpoints, structure } from 'styles'
import { Helmet } from 'react-helmet'
import { path } from 'ramda'
import formatDate from 'utils/format-date'
import { prefixUnitNumber, formatListingPrice } from 'utils/listings'
import useMediaQuery from 'hooks/use-media-query'
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell
} from 'components/listings-table'
import { EmptyListComponent } from 'components/table-list'
import PageTitle from 'components/page-title'
import MyRentalsHeader from './my-rentals-header'

const canViewDetail = state => ['draft', 'history'].indexOf(state) < 0

const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${colors.white};
  padding: 0 0 200px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 0 0 125px;
  }
`

const TableWrapper = styled.div`
  max-width: 1130px;
  margin: 0 100px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 0;
  }
`

const TableBodyContainer = styled(TableBody)`
  & ${TableRow} {
    border-bottom: 1px solid ${colors.grey};
  }
  & ${TableRow}:last-child {
    border-bottom: 0px solid ${colors.grey};
  }
`

const HeaderText = styled.p`
  color:  ${colors.white};
  text-align: left;
  margin: 10px 40px;
  font-size: 14px;

  ${props =>
    props.center &&
    `
    text-align: center; 
  `}

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 10px 25px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    text-align: center;
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeights.medium};
  }
`

const CellText = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  margin-left: 40px;
  text-overflow: ellipsis;
  max-width: 110px;
  overflow: hidden;

  strong {
    font-weight: ${fontWeights.medium};
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin-left: 25px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: ${fontSizes.small};
    margin: 0;
    max-width: none;
  }
`

const ContractRow = styled(TableRow)`
  ${props =>
    canViewDetail(props.state) &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`

const ListingCell = styled(TableCell)`
  padding: 10px 0 20px 0;
  border-bottom: 1px solid ${colors.grey};
`

const OffsetPill = styled.span`
  font-size: ${fontSizes.small};
  margin: 0;
`

const AddressText = styled(CellText)`
  max-width: 150px;
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 15px;
    margin-left: 0;
  }
`

const UnitText = styled(AddressText)`
  font-weight: ${fontWeights.medium};
  margin-bottom: 2px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 18px;
    margin-bottom: 2px;
  }
`

const CityText = styled(AddressText)`
  font-size: 12px;
  line-height: 20px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 12px;
  }
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
`

const MobileContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
`

const MobileContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const createTitle = tenants => {
  var title = tenants[0]
  if (tenants.length > 1) {
    title.concat(` +${tenants.length - 1}`)
  }
  return title
}

const getUnitNumber = contract => {
  return `${prefixUnitNumber(contract.unit_number)} ${contract.building_name}`
}

const MyRentals = props => {
  const { t } = useTranslation()
  const session = useSelector(path(['session', 'session']))
  const [data, setData] = useState([])
  const [filteredContracts, setFilteredContracts] = useState()
  const contracts = useSelector(select.contract.activeContracts)
  const dispatch = useDispatch()
  const displayTablet = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )

  useEffect(() => {
    dispatch.contract.getContracts()
  }, [dispatch.contract, session])

  useEffect(() => {
    setData(contracts)
    setFilteredContracts(contracts)
  }, [contracts])

  if (!filteredContracts) return null
  const searchContracts = searchString => {
    const filteredContract = data.filter(contract =>
      JSON.stringify(Object.values(contract))
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )
    setFilteredContracts(filteredContract)
  }

  const goToRentalDashboard = contract => {
    if (!canViewDetail(contract.state_machine)) return
    props.history.push(`/rental/${contract.listing_id}`)
  }

  const getBedrooms = contract => {
    const { bedrooms } = contract
    if (bedrooms === '0') return t('common.studio', 'Studio')
    return bedrooms
      ? `${bedrooms} ${t('common.bed', 'Bed')}`
      : t('b.components.view.not_avaiable.text', 'N/A')
  }

  const getPill = state => (
    <OffsetPill>{t(`b.contract.list.status_${state}.badge`)}</OffsetPill>
  )

  const renderMobileRentalRow = contract => {
    const state = contract.state_machine
    const tenants = contract.tenants.split('\n')

    return (
      <ListingCell>
        <MobileWrapper>
          <MobileContentTop>
            <AddressWrapper>
              <UnitText>{getUnitNumber(contract)}</UnitText>
              <AddressText>{contract.address}</AddressText>
              <CityText>{`${contract.city}, ${contract.state}`}</CityText>
            </AddressWrapper>
            <Bold>{formatListingPrice(contract.price)}</Bold>
          </MobileContentTop>
          <MobileContent>
            <CellText>
              {t('b.rentals.view.landlord.text', 'Landlord')}:{' '}
              <strong>{createTitle(tenants)}</strong>
            </CellText>
          </MobileContent>
          <MobileContent>
            <CellText>
              {t('b.rentals.view.lease_start.text', 'Lease Start')}:{' '}
              <strong>
                {formatDate(contract.start_date, 'MMM Do, YYYY') ||
                  t('b.components.view.not_avaiable.text', 'N/A')}
              </strong>
            </CellText>
            <CellText>
              {t('common.rooms', 'Rooms')}:{' '}
              <strong>{getBedrooms(contract)}</strong>
            </CellText>
          </MobileContent>
          <MobileContent>
            <CellText>
              {t('b.rentals.view.lease_end.text', 'Lease End')}:{' '}
              <strong>
                {formatDate(contract.end_date, 'MMM Do, YYYY') ||
                  t('b.components.view.not_avaiable.text', 'N/A')}
              </strong>
            </CellText>
            <CellText>
              {t('b.rentals.view.status.text', 'Status')}:{' '}
              <strong>{getPill(state)}</strong>
            </CellText>
          </MobileContent>
        </MobileWrapper>
      </ListingCell>
    )
  }

  const renderRentalRow = contract => {
    const state = contract.state_machine
    const tenants = contract.tenants.split('\n')

    return (
      <>
        <ListingCell>
          <UnitText>{getUnitNumber(contract)}</UnitText>
          <AddressText>{contract.address}</AddressText>
          <CityText>{`${contract.city}, ${contract.state}`}</CityText>
        </ListingCell>
        <ListingCell>
          <CellText>{getBedrooms(contract)}</CellText>
        </ListingCell>
        <ListingCell>
          <CellText>
            <Bold>{formatListingPrice(contract.price)}</Bold>
          </CellText>
        </ListingCell>
        <ListingCell>
          <CellText>
            <Bold>{createTitle(tenants)}</Bold>
          </CellText>
        </ListingCell>
        <ListingCell>
          <CellText>
            {formatDate(contract.start_date, 'MMM Do, YYYY') ||
              t('b.components.view.not_avaiable.text', 'N/A')}
          </CellText>
        </ListingCell>
        <ListingCell>
          <CellText>
            {formatDate(contract.end_date, 'MMM Do, YYYY') ||
              t('b.components.view.not_avaiable.text', 'N/A')}
          </CellText>
        </ListingCell>
        <ListingCell>
          <CellText>{getPill(state)}</CellText>
        </ListingCell>
      </>
    )
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('b.rentals.view.my_rentals.title', 'My Rentals')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('b.rentals.view.my_rentals.title', 'My Rentals')}
      </PageTitle>
      <MyRentalsHeader onSearch={searchContracts} />
      <TableWrapper>
        <Table>
          {!displayTablet && (
            <TableHeader>
              <TableRow sticky top={structure.header.height + 117}>
                <TableHeaderCell>
                  <HeaderText>{t('common.unit', 'Unit')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>{t('common.rooms', 'Rooms')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>{t('common.price', 'Price')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.rentals.view.landlord.text', 'Landlord')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.rentals.view.lease_start.text', 'Lease Start')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.rentals.view.lease_end.text', 'Lease End')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.rentals.view.status.text', 'Status')}
                  </HeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBodyContainer>
            {filteredContracts.length > 0 ? (
              filteredContracts.map((contract, index) => {
                const state = contract.state_machine
                return (
                  <ContractRow
                    key={`Contract - ${index}`}
                    state={state}
                    onClick={() => goToRentalDashboard(contract)}
                  >
                    {displayTablet
                      ? renderMobileRentalRow(contract)
                      : renderRentalRow(contract)}
                  </ContractRow>
                )
              })
            ) : (
              <TableRow>
                <ListingCell colSpan={7}>
                  <EmptyListComponent />
                </ListingCell>
              </TableRow>
            )}
          </TableBodyContainer>
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

export default MyRentals
