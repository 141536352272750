import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontWeights, breakpoints } from 'styles'
import { NavLink } from 'react-router-dom'
import useMediaQuery from 'hooks/use-media-query'
import AvoidBreakInside from 'components/avoid-break-inside'
import LabeledDetail from 'components/labeled-detail'
import { showImageModal } from 'components/image-modal'
import { Row, Group } from 'components/form'
import config from 'config'
import { PanelHeader, PanelTitle, StyledPanel } from '../elements'
const { BACKEND_SERVER } = config

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: ${({ width }) => width || '100%'};
  flex-direction: ${({ direction }) => direction || 'row'};
`
const PolicyWrapper = styled.div`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
  }
`

const SubtitleText = styled.p`
  font-weight: ${fontWeights.medium};
  line-height: 24px;
  margin: 0;
`

const PolicyTitle = styled.p`
  color: ${colors.mediumGrey};
  font-size: 12px;
  line-height: 17px;
  margin: 0;
`

const PolicyText = styled.p`
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  line-height: 19px;
  margin: 5px 0 10px 0;
`

const PolicyLink = styled(PolicyText)`
  color: ${colors.secondary};
  cursor: pointer;
`

const EditButton = styled.button`
  display: flex;
  align-items: center;
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
  &:disabled {
    opacity: 0.3;
  }
  @media screen and (max-width: 414px) {
    padding: 0 10px;
    font-size: 10px;
  }
`

const RemoveButton = styled.p`
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  line-height: 12px;
  position: absolute;
  top: -38px;
  right: -34px;
`

const SmallText = styled.p`
  font-size: 12px;
`

const InsuranceRow = styled(Row)`
  width: 100%;
  position: relative;
`

const InsuranceDetail = ({
  insurance,
  editable,
  handleDelete,
  editProps = {}
}) => {
  const { t } = useTranslation()

  const widthPercent = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? `100%`
    : `50%`

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 5

  const infoColSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 7

  const isEmpty = !insurance
  const Edit = () =>
    insurance ? (
      <EditButton
        id="remove_insurance_info_button"
        as={NavLink}
        to={{ pathname: '/profile/insurance-info', state: editProps }}
      >
        {t('r.profile.edit.edit.button', 'Edit')}
      </EditButton>
    ) : (
      <EditButton
        id="add_insurance_info_button"
        as={NavLink}
        to={{ pathname: '/profile/insurance-info', state: editProps }}
      >
        {t('r.profile.edit.add.button', 'Add')}
      </EditButton>
    )
  return (
    <AvoidBreakInside>
      <PanelHeader>
        <PanelTitle>
          {t(
            'r.profile.view.insurance_information.subtitle',
            'Insurance Information'
          )}
        </PanelTitle>
        {!!editable && <Edit />}
      </PanelHeader>
      <StyledPanel hSpace={40} vSpace={!isEmpty ? 40 : 0}>
        {!isEmpty ? (
          <InsuranceRow>
            {!!insurance && (
              <RemoveButton
                onClick={() =>
                  handleDelete({
                    fileId: insurance.id,
                    type: insurance.tag
                  })
                }
              >
                {t('r.profile.edit.remove.button', 'Remove')}
              </RemoveButton>
            )}
            <Group col={colSize}>
              <SubtitleText>
                {insurance?.metadata.insurance_company_name || '-'}
              </SubtitleText>
            </Group>
            <Group col={infoColSize}>
              <Wrapper>
                <LabeledDetail
                  label={t(
                    'r.profile.view.policy_number.label',
                    'Policy Number'
                  )}
                  value={insurance?.metadata.policy_number || '-'}
                  width={widthPercent}
                />
                <PolicyWrapper>
                  <PolicyTitle>
                    {t(
                      'r.profile.view.insurance_policy.label',
                      'Insurance Policy'
                    )}
                  </PolicyTitle>
                  {insurance ? (
                    <PolicyLink
                      onClick={() =>
                        showImageModal({
                          src: `${BACKEND_SERVER}/secure/${insurance.tag}/${insurance.id}/form.png`
                        })
                      }
                    >
                      {t('r.profile.view.view.text', 'View')}
                    </PolicyLink>
                  ) : (
                    <PolicyText>-</PolicyText>
                  )}
                </PolicyWrapper>
              </Wrapper>
            </Group>
          </InsuranceRow>
        ) : (
          <SmallText>
            {editable
              ? t(
                  'r.profile.view.no_insurance_profile.label',
                  "You don't have insurance info added in your profile."
                )
              : t('r.profile.view.no_insurance.label', 'No insurance added')}
          </SmallText>
        )}
      </StyledPanel>
    </AvoidBreakInside>
  )
}

export default React.memo(InsuranceDetail)
