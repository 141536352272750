export default {
  list: new Map(),
  emitQueue: new Map(),
  subscribe(event, callback) {
    this.list.has(event) || this.list.set(event, [])
    this.list.get(event).push(callback)
    return this
  },
  unsubscribe(event) {
    this.list.delete(event)
    return this
  },
  cancelEmit(event) {
    const timers = this.emitQueue.get(event)
    if (timers) {
      timers.forEach(timer => clearTimeout(timer))
      this.emitQueue.delete(event)
    }

    return this
  },
  emit(event, ...args) {
    this.list.has(event) &&
      this.list.get(event).forEach(callback => {
        const timer = setTimeout(() => {
          callback(...args)
        }, 0)

        this.emitQueue.has(event) || this.emitQueue.set(event, [])
        this.emitQueue.get(event).push(timer)
      })
  }
}
