import React, { useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { colors } from 'styles'
import noop from 'lodash/noop'

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  border: 1px solid ${colors.grey};
  border-radius: ${({ rounded }) => (rounded ? '6px' : 0)};

  > :first-child {
    border-radius: ${({ rounded }) => (rounded ? '6px 6px 0 0' : 0)};
  }

  > :last-child {
    border-radius: ${({ rounded }) => (rounded ? '0 0 6px 6px' : 0)};
    ${({ horizontal }) =>
      horizontal ? 'border-right: none;' : 'border-bottom: none;'};
    border-bottom: none;
  }

  ${({ customStyle }) => customStyle}
`

const OptionList = ({
  children,
  onChange,
  value,
  optionCustomStyle = [],
  rounded = false,
  clickOutsideCallback = noop,
  ...rest
}) => {
  const ref = useRef()
  const handleClick = useCallback(
    e => {
      if (ref.current.contains(e.target)) {
        return
      }

      clickOutsideCallback(e)
    },
    [clickOutsideCallback]
  )
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => document.removeEventListener('mousedown', handleClick, false)
  }, [handleClick])
  return (
    <div ref={ref}>
      <Wrapper {...rest}>
        {React.Children.map(children, child => {
          const selected = isEqual(child.props.value, value)
          return React.cloneElement(child, {
            isSelected: selected,
            customStyle: optionCustomStyle,
            horizontal: rest.horizontal,
            onClick: selected ? undefined : onChange
          })
        })}
      </Wrapper>
    </div>
  )
}

OptionList.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  rounded: PropTypes.bool,
  optionCustomStyle: PropTypes.array,
  clickOutsideCallback: PropTypes.func
}

export default OptionList
