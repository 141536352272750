import Nchan from 'nchan'
import { propOr, pipe } from 'ramda'
import { buildRequest } from 'ports'
import { wrapArray } from 'utils'
import config from 'config'
const { NCHAN_SERVER } = config

const STORAGE_KEY = '@SSE:lastEventId'

const NCHAN_OPTIONS = {
  subscriber: 'eventsource',
  shared: false,
  reconnect: false
}

const MODELS = {
  support_chat: 'supportRoom',
  be_callback: 'sse',
  user_updated: ['chat', 'profile'],
  profile_progress: 'profile',
  listing_contract_send: 'contract',
  listing_contract_canceled: 'contract',
  unit_files_added: 'listing',
  listing_updated: 'listing'
}

const getOptions = () => {
  let lastMessageId
  try {
    lastMessageId = localStorage.getItem(STORAGE_KEY)
  } catch (err) {
    console.log('Storage::error', err)
  }
  return {
    ...NCHAN_OPTIONS,
    msgId: lastMessageId
  }
}

const getModel = type => propOr('chat', type)(MODELS)

const formatType = type =>
  type
    .replace(/[_-]/g, ' ')
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')

let sub = null

const sse = {
  name: 'sse',
  state: null,
  reducers: {},
  effects: dispatch => ({
    subscribe(uuid) {
      sub = new Nchan(`${NCHAN_SERVER}/${uuid}`, getOptions())
      sub.on('message', (payload, meta) => {
        const message = JSON.parse(payload)
        dispatch.sse.dispatchMessage(message)
        localStorage.setItem(STORAGE_KEY, meta.id.split(',')[0])
      })
      sub.start()
    },
    unsubscribe() {
      if (sub?.running) {
        sub.stop()
      }
    },
    async parseBeCallbackSSE(url) {
      const { response, body } = await buildRequest(url)
      if (response.ok) {
        dispatch.sse.dispatchMessage(body, 'Callback')
      }
    },
    dispatchMessage(message, _, suffix = 'SSE') {
      const models = pipe(getModel, wrapArray)(message.type)
      for (const model of models) {
        const action =
          dispatch[model]?.[formatType(`parse_${message.type}${suffix}`)]
        action && action(message.data)
      }
    }
  })
}

export default sse
