import selectorBuilder from 'models/selector-builder'
import { createSelector } from 'reselect'
import {
  prop,
  pipe,
  lensProp,
  append,
  set,
  map,
  sortBy,
  compose,
  flip
} from 'ramda'
import {
  getPresets,
  createPreset,
  deletePreset,
  modifyPreset,
  setPresetPosition
} from './ports'

const INITIAL_STATE = {
  presets: [],
  initialPresetsLoaded: false,
  landlordSupportPresets: [
    {
      id: 1,
      content: 'I need help with my account.'
    },

    {
      id: 2,
      content: 'I need help finding tenants.'
    },
    {
      id: 3,
      content: 'I need help getting verified.'
    }
  ],
  renterSupportPresets: [
    {
      id: 1,
      content: 'I need help with my account.'
    },

    {
      id: 2,
      content: 'I need help finding listings.'
    },
    {
      id: 3,
      content: 'I need help getting verified.'
    }
  ]
}

const presetsSelector = createSelector(
  prop('presets'),
  sortBy(compose(parseInt, prop('position')))
)

const supportRoomPresetSelector = createSelector(
  flip(prop('userRole')),
  prop('landlordSupportPresets'),
  prop('renterSupportPresets'),
  (role, landlordPresets, renterPresets) =>
    role === 'landlord' ? landlordPresets : renterPresets
)

const chatPreset = {
  state: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    updatePresets: (state, payload) => ({
      ...state,
      ...payload
    }),
    addPreset: (state, payload) =>
      set(
        lensProp('presets'),
        pipe(prop('presets'), append(payload))(state),
        state
      ),
    updatePreset: (state, payload) =>
      set(
        lensProp('presets'),
        pipe(
          prop('presets'),
          map(preset => (preset.id === payload.id ? payload : preset))
        )(state),
        state
      )
  },

  effects: dispatch => ({
    getPresets: async (_, rootState) => {
      if (rootState.initialPresetsLoaded) return
      try {
        const { body, response } = await getPresets()
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chatPreset.updatePresets({
          presets: body,
          initialPresetsLoaded: true
        })
        return body
      } catch (error) {
        console.log('[Chat/getTimelines]', error)
        return false
      }
    },
    createPreset: async message => {
      try {
        const { response, body } = await createPreset({
          body: { content: message }
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chatPreset.addPreset(body)
        return body
      } catch (error) {
        console.log('chat-preset/createPreset: ', error)
        return false
      }
    },
    modifyPreset: async payload => {
      const { id, content } = payload
      try {
        const { response, body } = await modifyPreset(
          { body: { content } },
          { presetId: id }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chatPreset.updatePreset(body)
        return body
      } catch (error) {
        console.log('chat-preset/createPreset: ', error)
        return false
      }
    },
    deletePreset: async presetId => {
      try {
        const { response, body } = await deletePreset(undefined, { presetId })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chatPreset.updatePresets({ presets: body })
        return body
      } catch (error) {
        console.log('chat-preset/deletePreset: ', error)
        return false
      }
    },
    setPresetPosition: async payload => {
      //position starts at 1 in backend, make sure you take that into account when calling this function
      const { presetId, position } = payload
      try {
        const { response, body } = await setPresetPosition(undefined, {
          presetId,
          position
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.chatPreset.updatePresets({ presets: body })
        return body
      } catch (error) {
        console.log('chat-preset/deletePreset: ', error)
        return false
      }
    }
  }),

  selectors: selectorBuilder(slice => ({
    presets: () => {
      return slice(presetsSelector)
    },
    supportRoomPresets: () => {
      return slice(supportRoomPresetSelector)
    }
  }))
}

export default chatPreset
