import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import LandlordReferencesList from './list'
import LandlordReferenceForm from './form'

const LandlordReferencesFullList = ({
  isChecked,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  const landlordReferences = useSelector(state =>
    select.profile.contacts(state, {
      type: 'landlord'
    })
  )

  return (
    <LandlordReferencesList
      anchorTop={anchorTop}
      data={landlordReferences}
      AddComponent={LandlordReferenceForm}
      EditComponent={LandlordReferenceForm}
      emptyMessage={t(
        'r.profile.view.empty_landlord_references.text',
        'I am a first-time renter and do not have any landlord references.'
      )}
      isChecked={isChecked}
      editable
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(LandlordReferencesFullList)
