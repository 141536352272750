import { Trans, useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { show as showForceEndTenancyModal } from 'features/EndTenancy/force-end-tenancy-modal'
import { createModal, useModalize } from 'components/modalize'
import { confirm } from 'components/dialog'
import { colors, fontWeights } from 'styles'
import Icon from 'components/icon'
import { Button } from 'components/button'
import { Label, Row, Input } from 'components/form'
import config from 'config'

const { WEB_URL } = config

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const ContentWrapper = styled.div`
  padding: 0px 30px 30px;
  overflow-y: auto;
  max-height: 70vh;
`

const CloseButton = styled(Icon)`
  position: absolute;
  top: 25px;
  right: 25px;
  margin: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

const StyledLabel = styled(Label)`
  color: ${colors.pink};
  font-weight: ${fontWeights.medium};
  margin-bottom: 20px;
  padding-left: 10px;
`

const LegalPointsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const LegalPointListItem = styled.li`
  width: 100%;
  padding: 0 0 20px;
  margin: 0;
`

const TermsContainer = styled.div`
  display: inline;
  padding: 0 0 20px;
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const TermsLabel = styled(Label)`
  font-size: 15px;
  padding: 0;
`

const Link = styled.a`
  display: inline;
  color: ${colors.link};
  text-decoration: none;
  padding-left: 5px;
`

const CheckBox = styled(Input)``

const CheckBoxWrapper = styled.div`
  min-width: 83px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 30px 30px;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin: 0;
  letter-spacing: 0.25px;
  }
`

const CannotUndoModal = ({ listingId, endTenancyId, values }) => {
  const { close } = useModalize()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const forceEndTenancy = dispatch.endTenancy.forceEndTenancy
  const [checked, setChecked] = useState(false)

  const handleForceEndTenancy = () => {
    return confirm(
      t(
        'l.rental_dashboard.edit.resources_force_end_tenancy_confirmation.title',
        'Warning'
      ),
      t(
        'l.rental_dashboard.edit.resources_force_end_tenancy_confirmation.text',
        'This cannot be undone, are you sure you want to continue?'
      )
    ).then(async () => {
      const response = await forceEndTenancy({
        listingId,
        endTenancyId,
        body: { reason: values.reason, message: values.message }
      })
      if (response?.response?.ok) {
        close()
      }
    })
  }

  const handleClose = () => {
    return confirm(
      t(
        'l.rental_dashboard.edit.resources_leave_force_end_tenancy_confirmation.title',
        'Warning'
      ),
      t(
        'l.rental_dashboard.edit.resources_leave_force_end_tenancy_confirmation.text',
        'Leaving this form now will lose all your entered information. \nAre you sure you want to leave?\n'
      )
    ).then(async () => await close())
  }

  const handleBack = () => {
    return (
      close(),
      showForceEndTenancyModal({
        listingId: values.listing_id,
        endTenancyId: values.id,
        savedValues: {
          reason: values.reason,
          message: values.message
        }
      })
    )
  }

  return (
    <Wrapper>
      <CloseButton
        id="close"
        width={24}
        height={24}
        onClick={() => handleClose()}
      />
      <ContentWrapper>
        <Row>
          <StyledLabel>
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy_cannot_undo_modal.title',
              'This action cannot be undone'
            )}
          </StyledLabel>
        </Row>
        <LegalPointsListWrapper>
          <LegalPointListItem>
            I have provided adequate notice to my Tenant(s), in accordance with
            the applicable residential tenancy act in my jurisdiction.
          </LegalPointListItem>
          <LegalPointListItem>
            I have provided the tenant(s) with the forms required by the
            residential tenancy authority (if any are applicable).
          </LegalPointListItem>
          <LegalPointListItem>
            <TermsContainer inline>
              <Trans i18nKey="b.settings.edit.agree_to_terms.label">
                <TermsLabel>
                  I agree to Liv{`'`}s{' '}
                  <Link
                    href={`${WEB_URL}/terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions.
                  </Link>
                </TermsLabel>
              </Trans>
            </TermsContainer>
          </LegalPointListItem>
        </LegalPointsListWrapper>

        <Row>
          <Label top={10} left={10}>
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy_immediately.text',
              'I agree to end tenancy immediately.'
            )}
          </Label>
          <CheckBoxWrapper>
            <CheckBox
              onChange={() => {
                setChecked(!checked)
              }}
              checked={checked}
              type="checkbox"
            />
          </CheckBoxWrapper>
        </Row>
        <ButtonWrapper>
          <ActionButton theme="sane" onClick={() => handleBack()}>
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy_back.button',
              'Back'
            )}
          </ActionButton>
          <ActionButton
            theme="primary"
            disabled={!checked}
            onClick={() => handleForceEndTenancy()}
          >
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy.button',
              'End Tenancy'
            )}
          </ActionButton>
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export const show = createModal(CannotUndoModal)
