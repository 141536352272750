import styled from 'styled-components'
import { colors } from 'styles'

const Base = styled.button`
  height: 33px;
  width: 80px;
  background-color: ${colors.secondary};
  border: none;
  border-radius: 4px;
  color: ${colors.white};
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`

export default Base
