import { useState, useEffect } from 'react'

const useRefCallback = fn => {
  const [node, setRef] = useState(null)

  useEffect(() => {
    if (node) {
      fn(node)
    }
  }, [fn, node])

  return [setRef]
}

export default useRefCallback
