import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useCallback
} from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from 'styles'
import Icon from 'components/icon'

const CanvasWrapper = styled.div`
  max-width: 100%;
  max-height: 600px;
  overflow: hidden;
  border: 1px solid ${colors.mediumGrey};
`

//use width for mobile to allow users to zoom in a lot which is handled by max-width, can't use min width because it will override max-width and be zoomed in by default which we don't want
//use min-width on desktop to force image to a bigger size if it's too small. Can't use width because it will force bigger images to be smaller
const Canvas = styled.canvas`
  max-width: inherit;
  max-height: inherit;
  width: unset;
  height: unset;
  display: block;
  flex-shrink: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 3000px;
  }

  @media screen and (min-width: ${breakpoints.tabletLandscape}) {
    min-width: 700px;
    max-height: unset;
  }

  @media screen and (min-width: 1100px) {
    min-width: 900px;
  }

  @media screen and (min-width: 1250px) {
    min-width: 1000px;
  }
`

const StyledIcon = styled(Icon)`
  @media screen and (max-width: ${breakpoints.desktop}) {
    width: 45px;
    height: 45px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 35px;
    height: 35px;
  }
`

const IconButton = styled(StyledIcon)`
  cursor: pointer;

  margin-top: 30px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-top: 15px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 0px;
    margin-left: 15px;
  }

  :hover {
    circle {
      fill: ${colors.regular};
      stroke: ${colors.regular};
    }

    path,
    rect {
      fill: ${colors.white};
      stroke: ${colors.white};
    }

    path {
      fill: ${colors.white};
      stroke: ${colors.white};
    }

    rect {
      fill: ${colors.white};
      stroke: ${colors.white};
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: ${breakpoints.desktop}) {
    right: 0;
  }
`

const getPenPixelPosition = (input, canvas) => {
  const bounds = canvas.getBoundingClientRect()

  const x = ((input.clientX - bounds.left) / bounds.width) * canvas.width
  const y = ((input.clientY - bounds.top) / bounds.height) * canvas.height

  return { x, y }
}

const resetCanvasImage = (canvas, imageToEdit) => {
  if (canvas && imageToEdit) {
    canvas.width = imageToEdit.width
    canvas.height = imageToEdit.height

    const ctx = canvas.getContext('2d')
    ctx.drawImage(imageToEdit, 0, 0, imageToEdit.width, imageToEdit.height)
    ctx.lineWidth = Math.max(imageToEdit.height / 100, 3)
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
  }
}

const computeInputDistance = (a, b) =>
  Math.hypot(a.clientX - b.clientX, a.clientY - b.clientY)

const ImageSketchpad = (
  { baseImageSrc, editedImageSrc, ToolbarComponent = IconContainer, ...rest },
  ref
) => {
  const [canvas, setCanvas] = useState()
  const backgroundImage = useRef(new Image())

  backgroundImage.current.onload = useCallback(() => {
    //handle the situation described in useImageAsBase
    if (!!backgroundImage.current.src)
      resetCanvasImage(canvas, backgroundImage.current)
  }, [canvas])

  //use refs to avoid rerender
  const isPenDown = useRef()
  const touches = useRef([])
  const zoomLevel = useRef(1)
  const canvasWrapperRef = useRef()

  const setCanvasRef = canvasRef => {
    if (canvasRef) {
      if (ref) ref.current = canvasRef
      setCanvas(canvasRef)
    }
  }

  const getImageAsBase = useCallback(
    imageSrc => {
      //on safari, onload does not trigger if src is set to the same image that is currently loaded
      //editing an image does not change the image src so when the erase button is clicked, even though the canvas
      //drawing has been edited, because the backgroundImage.src is still the same url, the onLoad event does not fire
      //set to empty string and then target url to ensure the event is triggered
      backgroundImage.current.src = ''
      backgroundImage.current.src = imageSrc
    },
    [backgroundImage]
  )

  //load initial image as canvas background
  useEffect(() => {
    if (canvas) getImageAsBase(editedImageSrc || baseImageSrc)
  }, [baseImageSrc, canvas, getImageAsBase, editedImageSrc])

  //eslint-disable-next-line max-statements, complexity
  useEffect(() => {
    if (!canvas) return

    const ctx = canvas.getContext('2d')

    const onPenMove = eventPosition => {
      const { x, y } = getPenPixelPosition(eventPosition, canvas)

      if (isPenDown.current) {
        ctx.lineTo(x, y)
        ctx.stroke()
      }
    }

    const onPenDown = eventPosition => {
      const { x, y } = getPenPixelPosition(eventPosition, canvas)
      ctx.beginPath()
      ctx.moveTo(x, y)
      isPenDown.current = true
    }

    const onPenUp = eventPosition => {
      if (isPenDown.current) {
        const { x, y } = getPenPixelPosition(eventPosition, canvas)
        ctx.lineTo(x, y)
        ctx.stroke()
        isPenDown.current = false
      }
    }

    const handleSwipeGesture = event => {
      const movementScale = 1 //Math.max(zoomLevel.current / 1.5, 1) for faster translation later
      const xScroll =
        (event.touches[0].clientX - touches.current[0].clientX) * movementScale
      const yScroll =
        (event.touches[0].clientY - touches.current[0].clientY) * movementScale

      //wrapper scroll sometimes doesn't match canvas client bounding box
      //no issues with scrolling itself, but if there is an issue in the future it might be related
      canvasWrapperRef.current.scrollLeft -= xScroll
      canvasWrapperRef.current.scrollTop -= yScroll
      touches.current = event.touches
    }

    const handleZoomGesture = (
      event,
      currentTouchDistance,
      prevTouchDistance
    ) => {
      zoomLevel.current += (currentTouchDistance - prevTouchDistance) / 30
      zoomLevel.current = Math.max(1, zoomLevel.current)

      //only zooms to max natural size, if users upload super small images then sucks to be them
      canvas.style.maxWidth = `${100 * zoomLevel.current}%`
      canvas.style.maxHeight = `${100 * zoomLevel.current}%`

      touches.current = event.touches
    }

    const onGestureChange = event => {
      //two kinds of gessture events, pinch to zoom in or out, and drag to scroll

      //no touches stored for comparison, so store touches for next time
      //touches could also have 1 touch registered previously, so ensure same number of touches
      if (!touches.current || touches.current.length !== event.touches.length) {
        touches.current = event.touches
        return
      }

      canvasWrapperRef.current.style.overflow = 'auto'

      //zoom, distance between touches got smaller / bigger
      //drag, distance is similar within some threshold
      const prevTouchDistance = computeInputDistance(
        touches.current[0],
        touches.current[1]
      )
      const currentTouchDistance = computeInputDistance(
        event.touches[0],
        event.touches[1]
      )

      const zoomGestureDistanceThreshold = 1

      Math.abs(currentTouchDistance - prevTouchDistance) <
      zoomGestureDistanceThreshold
        ? handleSwipeGesture(event)
        : handleZoomGesture(event, currentTouchDistance, prevTouchDistance)
    }

    const onGestureEnd = () => {
      touches.current = []
    }

    const onTouchEvent = (touchCallback, gestureCallback) => event => {
      if (event.cancelable) {
        event.preventDefault() //prevent entire browser from zooming in/out as default behaviour
        event.stopPropagation()
      }
      if (event.touches.length < 2) {
        touchCallback(event.changedTouches[0])
      } else {
        isPenDown.current = false //placing one fingure down, and then the next starts a drawing event first, be sure to stop drawing
        gestureCallback(event)
      }
    }
    const onTouchStart = onTouchEvent(onPenDown, onGestureChange)
    const onTouchMove = onTouchEvent(onPenMove, onGestureChange)

    canvas.addEventListener('mousemove', onPenMove)
    canvas.addEventListener('mousedown', onPenDown)
    canvas.addEventListener('mouseup', onPenUp)
    canvas.addEventListener('mouseout', onPenUp)
    canvas.addEventListener('touchstart', onTouchStart)
    canvas.addEventListener('touchcancel', onGestureEnd)
    canvas.addEventListener('touchend', onGestureEnd)
    canvas.addEventListener('touchmove', onTouchMove)

    return () => {
      canvas.removeEventListener('mousemove', onPenMove)
      canvas.removeEventListener('mousedown', onPenDown)
      canvas.removeEventListener('mouseup', onPenUp)
      canvas.removeEventListener('mouseout', onPenUp)
      canvas.removeEventListener('touchstart', onTouchStart)
      canvas.removeEventListener('touchend', onGestureEnd)
      canvas.removeEventListener('touchcancel', onGestureEnd)
      canvas.removeEventListener('touchmove', onTouchMove)
    }
  }, [canvas])

  return (
    <>
      <CanvasWrapper ref={canvasWrapperRef} {...rest}>
        <Canvas ref={setCanvasRef} />
      </CanvasWrapper>
      <ToolbarComponent>
        <StyledIcon id="markup_marker" width={50} height={50} bottom={30} />
        <IconButton
          id="markup_eraser"
          width={50}
          height={50}
          onClick={() => {
            getImageAsBase(baseImageSrc)
          }}
        />
      </ToolbarComponent>
    </>
  )
}

export default forwardRef(ImageSketchpad)
export { IconContainer }
