import ENV_VARS from 'config'
import { post, get, del } from 'ports/api'

const { BACKEND_SERVER } = ENV_VARS

export const getConnectUrl = get(
  `${BACKEND_SERVER}/payment_request/connect_uri`
)
export const connectStripe = get(`${BACKEND_SERVER}/payment_request/connect`)

export const createPaymentRequest = post(
  `${BACKEND_SERVER}/payment_request/listings/%listingId%/tenants/%tenantId%`
)
export const cancelPaymentRequest = del(
  `${BACKEND_SERVER}/payment_request/%paymentId%`
)
export const replyPaymentRequest = post(
  `${BACKEND_SERVER}/payment_request/process`
)

export const getListingPayments = get(
  `${BACKEND_SERVER}/payment_request/listings/%listingId%`
)

export const getPayment = get(`${BACKEND_SERVER}/payment_request/%paymentId%`)

export const getFees = post(`${BACKEND_SERVER}/payment_request/get_fees`, {
  failSilently: true
})

// Landlord - Transaction summary details
// PDF download: GET payment_request/receipt/landlord/%paymentRequestId%/receipt.pdf
export const getLandlordPaymentReceipt = get(
  `${BACKEND_SERVER}/payment_request/receipt/landlord/%paymentRequestId%`
)

// Tenant - Payment summary details
// PDF download: GET payment_request/receipt/tenant/%paymentRequestId%/receipt.pdf
export const getPaymentReceipt = get(
  `${BACKEND_SERVER}/payment_request/receipt/%paymentRequestId%`
)
