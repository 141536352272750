import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Container = styled.th`
  background-color: ${colors.secondaryLightBlue};
`

const TableHeaderCell = props => (
  <Container {...props}>{props.children}</Container>
)

export default TableHeaderCell
