import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors } from 'styles'
import Breadcrumbs from 'components/breadcrumbs'

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  z-index: 100;

  ${props =>
    props.showBorder &&
    `
    border-bottom: 1px solid ${colors.grey};
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 0 20px;
  }
`

const Group = styled.div`
  display: flex;
  align-items: center;
`

const DashboardHeader = ({ listingId, border, currentPage }) => {
  const { t } = useTranslation()

  const breadcrumbs = [
    {
      to: '/tenants',
      label: t('l.tenants.view.all_tenants.link', 'All Tenants')
    },
    {
      to: `/tenant/${listingId}`,
      label: t('l.tenants.view.dashboard.link', 'Dashboard')
    },
    { to: null, label: currentPage }
  ]

  return (
    <Wrapper showBorder={border}>
      <Content>
        <Group>
          <Breadcrumbs items={breadcrumbs} />
        </Group>
      </Content>
    </Wrapper>
  )
}

export default DashboardHeader
