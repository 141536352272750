import React from 'react'
import { useTranslation } from 'react-i18next'
import CardTemplateWhite from './card-template-white'
import equifaxUnknown from './images/Credit_Score_Unknown.png'

const NotEnoughCredit = props => {
  const { t } = useTranslation()

  const creditDescription =
    props.userRole === 'tenant'
      ? t(
          'r.liv_score_card.view.not_enough_info_description.text',
          'Not enough info to determine a Liv Score. Please provide additional documentation to support your rental application.'
        )
      : t(
          'l.liv_score_card.view.not_enough_info_description.text',
          'Credit score cannot be determined, please refer to additional documents from the renter for their financial history.'
        )

  return (
    <CardTemplateWhite
      userRole={props.userRole}
      title={props.title}
      creditDetails={props.creditDetails}
      creditDescription={creditDescription}
      creditRatingResult={t(
        'b.liv_score_card.view.not_enough_info.text',
        'Not Enough Info'
      )}
      cardImage={equifaxUnknown}
      avatarUrl={props.avatarUrl}
      professionId={props.professionId}
      csoVerifiedState={props.csoVerifiedState}
      salarySort={props.salarySort}
      listingData={props.listingData}
      data-testid={props['data-testid']}
    />
  )
}

NotEnoughCredit.defaultProps = {
  userRole: 'tenant',
  title: ''
}

export default NotEnoughCredit
