import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Label } from 'components/form'
import CurrencyFormatter from 'components/currency-formatter'
import Icon from 'components/icon'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'
import {
  AreaTitle,
  StyledPanel,
  StyledRow,
  StyledLabel,
  StyledValue,
  FeeInfoWrapper,
  Error
} from '../elements'

const PaymentDetails = ({
  values: { payment_method, promo_code },
  paymentData: { description, due_date },
  feesInfo
}) => {
  const { t } = useTranslation()

  const hasPromoCode = !!promo_code
  const { amount, discount, fee, charge, discount_allowed } = feesInfo[
    payment_method
  ]

  return (
    <>
      <AreaTitle>
        {t('r.payment.edit.payment_details.subtitle', 'Payment Details')}
      </AreaTitle>
      <StyledPanel hSpace={30} vSpace={50}>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.payment_detail.label', 'Detail')}
          </StyledLabel>
          <StyledValue>{description}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_due_date.label', 'Due date')}
          </StyledLabel>
          <StyledValue>{moment(due_date).format('MM-DD-YYYY')}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t(
              'r.payment.edit.make_payment_payment_amount.label',
              'Payment amount'
            )}
          </StyledLabel>
          <StyledValue>
            <CurrencyFormatter value={amount} showCents />
          </StyledValue>
        </StyledRow>
        {hasPromoCode && (
          <StyledRow bottom={10}>
            <div>
              <StyledLabel>
                {t('r.payment.edit.discount.label', 'Discount')}
              </StyledLabel>
              {!discount_allowed && (
                <Error>
                  {t(
                    'r.payment.edit.invalid_discount_code_for_method.error',
                    '** The discount code used is not valid with the selected payment method.'
                  )}
                </Error>
              )}
            </div>
            <StyledValue>
              -<CurrencyFormatter value={discount} showCents />
            </StyledValue>
          </StyledRow>
        )}
        <StyledRow bottom={10} paddingTop={20} borderTop>
          <StyledLabel>
            {t('r.payment.edit.subtotal.label', 'Subtotal')}
          </StyledLabel>
          <StyledValue>
            <CurrencyFormatter value={amount - (discount || 0)} showCents />
          </StyledValue>
        </StyledRow>
        <StyledRow bottom={20}>
          <FeeInfoWrapper>
            <Label bottom="0">
              {t(
                'r.payment.edit.make_payment_processing_fees.text',
                'Processing fees'
              )}
            </Label>
            <Icon
              id="info_blue"
              width={16}
              height={16}
              onClick={showProcessingFeesModal}
            />
          </FeeInfoWrapper>
          <StyledValue bottom="0">
            <CurrencyFormatter value={fee} showCents />
          </StyledValue>
        </StyledRow>
        <StyledRow bottom="0" paddingTop={20} borderTop>
          <StyledLabel bottom="0" bold>
            {t('r.payment.edit.make_payment_total.label', 'Total')}
          </StyledLabel>
          <StyledValue bottom="0">
            <CurrencyFormatter value={charge} showCents />
          </StyledValue>
        </StyledRow>
      </StyledPanel>
    </>
  )
}

export default PaymentDetails
