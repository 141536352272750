import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { path, propEq } from 'ramda'
import MenuCard from 'components/menu-card'

const isDraft = propEq('state_machine', 'draft')

const ListingMenuCards = ({
  listingId,
  building,
  unreadCount,
  applicationCount,
  listingPending
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contract = useSelector(path(['listing', listingId, 'contract']))

  useEffect(() => {
    dispatch.listing.getContract(listingId)
  }, [dispatch.listing, listingId])

  const getContractLink = () => {
    if (!contract || !contract.listing_contract_id || isDraft(contract)) {
      return `/listing/${listingId}/contract/create/${
        contract?.listing_contract_id || ''
      }`
    }
    return `/listing/${listingId}/contract/${contract?.listing_contract_id}`
  }

  const contractLink = getContractLink()
  const listingInBC = building.state === 'BC'
  const contractLinkDisabled = listingPending || !listingInBC
  const suggestedTenantsLinkDisabled = listingPending

  const menuItems = [
    {
      title: t('l.listing.view.chats.label', 'Chats'),
      description: t(
        'l.listing.view.chats.text',
        'Reply to inquiries about this listing'
      ),
      iconId: 'chat',
      to: `/listing/${listingId}/chat`,
      count: unreadCount
    },
    {
      title: t('l.listing.view.suggested_tenants.label', 'Suggested Tenants'),
      description: t(
        'l.listing.view.suggested_tenants.text',
        'Check out potential tenants that match your preferences'
      ),
      iconId: 'search',
      to: `/listing/${listingId}/suggested-tenants`,
      disabled: suggestedTenantsLinkDisabled
    },
    {
      title: t('l.listing.view.showings.label', 'Showings'),
      description: t(
        'l.listing.view.showings.text',
        'Schedule showing times for renters to view listing'
      ),
      iconId: 'showings',
      to: `/listing/${listingId}/showings`
    },
    {
      title: t('l.listing.view.applications.label', 'Applications'),
      description: t(
        'l.listing.view.applications.text',
        'Compare applicants and find your best option'
      ),
      iconId: 'application',
      to: `/listing/${listingId}/applications`,
      count: applicationCount
    },
    {
      title: t('l.listing.view.contract.label', 'Contract'),
      description: t(
        'l.listing.view.contract.text',
        'Sign digital tenancy agreement using our template'
      ),
      iconId: 'contract',
      to: contractLink,
      disabled: contractLinkDisabled
    },
    {
      title: t('l.listing.view.notes.label', 'Notes'),
      description: t(
        'l.listing.view.notes.text',
        'Keep track of each listing using your private notes'
      ),
      iconId: 'note',
      to: `/listing/${listingId}/notes`
    }
  ]

  return (
    <>
      {menuItems.map((item, index) => (
        <MenuCard key={`listing-card-${index}`} item={item} />
      ))}
    </>
  )
}

export default ListingMenuCards
