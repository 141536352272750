import React, { useRef, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'

const Autosave = ({ children, values, onSave, delay = 900 }) => {
  const isMounted = useRef(false)
  const clean = useRef(true)
  const setClean = value => (clean.current = value)
  const save = useMemo(() => {
    return debounce(data => {
      onSave(data)
      setClean(true)
    }, delay)
  }, [delay, onSave])
  useEffect(() => (!clean.current ? save.flush : noop), [save])
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }
    setClean(false)
    save(values, setClean)
  }, [values, save])
  return <>{children}</>
}

export default Autosave
