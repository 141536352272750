import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { remove, concat } from 'ramda'
import { colors } from 'styles'
import Icon from 'components/icon'
import { Button } from 'components/button'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

const acceptedFileTypes = 'image/png, image/jpg, image/jpeg'

const SelectedImage = styled.img`
  height: 50%;
  width: 350px;
  max-height: 450px;
  max-width: 450px;
  margin: 30px auto 10 auto;
  display: block;
  object-fit: contain;
`

const FilesWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, 150px);
  max-width: 810px;
  margin: 50px auto;
`

const FileWrapper = styled.div`
  height: 150px;
  width: 150px;
  outline: none;
  position: relative;
  :hover {
    img {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.5;
    }
    svg {
      opacity: 1;
    }
  }
  ${({ active }) =>
    active &&
    `
    outline: 5px ${colors.secondary} solid;
  `}
`

const FileImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const RemoveIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  cursor: pointer;
  margin: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  path {
    fill: ${colors.regular};
  }
`
const SubmitButton = styled(Button)`
  display: block;
  width: 150px;
  margin: 25px auto 0px auto;
  font-size: 18px;
`

const AddBox = styled.div`
  border: 1px solid ${colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

const ListView = ({ files, setFiles, sendAllFiles }) => {
  const [t] = useTranslation()
  const [selectedFile, setSelectedFile] = useState({})

  const handleAddFile = newFiles => setFiles(concat(files, newFiles))

  const { getInputProps, open } = useDropzone({
    accept: acceptedFileTypes,
    multiple: false,
    noKeyboard: true,
    onDrop: handleAddFile
  })

  useEffect(() => {
    if (!selectedFile.file) {
      setSelectedFile({ file: files[0], index: 0 })
    }
  }, [files, selectedFile.file])

  if (!selectedFile.file) return null
  const selectedFileUrl = URL.createObjectURL(selectedFile.file)
  return (
    <>
      <SelectedImage src={selectedFileUrl} />
      <FilesWrapper>
        {files.map((file, index) => {
          const url = URL.createObjectURL(file)
          const deleteImage = e => {
            e.stopPropagation()
            const newFiles = remove(index, 1, files)
            setFiles(newFiles)

            if (index === selectedFile.index) {
              setSelectedFile({ file: newFiles[0], index: 0 })
            }
          }
          const onFileClick = () => {
            setSelectedFile({ file: files[index], index })
          }
          return (
            <FileWrapper
              key={`${file.name}-${index}`}
              active={index === selectedFile.index}
              onClick={onFileClick}
            >
              <RemoveIcon
                id="close"
                width={24}
                height={24}
                onClick={deleteImage}
              />
              <FileImage src={url} />
            </FileWrapper>
          )
        })}
        {files.length < 10 && (
          <AddBox onClick={open}>
            <input {...getInputProps()} />
            <Icon width={50} height={50} id="plus" />
          </AddBox>
        )}
      </FilesWrapper>
      <SubmitButton
        onClick={() => {
          sendAllFiles()
          setFiles([])
        }}
        type="submit"
        theme="primary"
      >
        {t('b.chat.view.send_files.button', 'Send')}
      </SubmitButton>
    </>
  )
}

export default ListView
