import React from 'react'
import { useTranslation } from 'react-i18next'
import ExcellentCredit from './excellent-credit'
import GoodCredit from './good-credit'
import AverageCredit from './average-credit'
import PoorCredit from './poor-credit'
import AnalyzingCredit from './analyzing-credit'
import UnknownCredit from './unknown-credit'
import NotEnoughCredit from './not-enough-credit'

const CardTypes = {
  excellent: ExcellentCredit,
  'very good': GoodCredit,
  average: AverageCredit,
  poor: PoorCredit,
  'not enough': NotEnoughCredit,
  'N/A': UnknownCredit,
  analyzing: AnalyzingCredit
}

const LivScoreCard = props => {
  const { t } = useTranslation()
  const Card = CardTypes[props.creditDetails?.credit_rating] || UnknownCredit

  const title =
    props.userRole === 'tenant'
      ? t('r.liv_score_card.view.current_score.title', 'Current Score:')
      : t('l.liv_score_card.view.tenant_score.title', 'Tenant Score:')

  return <Card title={title} {...props} />
}

LivScoreCard.defaultProps = {
  userRole: 'tenant'
}

export default LivScoreCard
