/* global H */
/* eslint-disable max-statements */
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'

import getDomMarkerIcon from './utils/get-dom-marker-icon'
import getMarkerIcon from './utils/get-marker-icon'
import { setMarkerDragEvent } from './utils/set-drag-event'
import MapContext from './context'

const markerEvents = {
  onDragStart: 'dragstart',
  onDrag: 'drag',
  onDragEnd: 'dragend'
}

export class Marker extends Component {
  static contextType = MapContext
  context
  marker

  componentDidMount() {
    const { map } = this.context
    if (map && !this.marker) {
      this.addMarkerToMap()
    }

    if (this.marker) {
      Object.entries(markerEvents).forEach(([event, hereEvent]) => {
        if (typeof this.props[event] === 'function') {
          this.marker.addEventListener(hereEvent, this.props[event])
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.setPosition({
        lat: this.props.lat,
        lng: this.props.lng
      })
    }
  }

  componentWillUnmount() {
    const { map } = this.context

    if (this.marker) {
      Object.entries(markerEvents).forEach(([event, hereEvent]) => {
        if (typeof this.props[event] === 'function') {
          this.marker.removeEventListener(hereEvent, this.props[event])
        }
      })
    }

    if (map && this.marker) {
      map.removeObject(this.marker)
    }
  }

  addMarkerToMap() {
    const { map, behavior } = this.context
    const { children, bitmap, lat, lng, draggable } = this.props

    let marker
    if (map) {
      if (React.Children.count(children) > 0) {
        const html = ReactDOMServer.renderToStaticMarkup(
          <div className="dom-marker">{children}</div>
        )
        const icon = getDomMarkerIcon(html)
        marker = new H.map.DomMarker({ lat, lng }, { icon })
      } else if (bitmap) {
        const icon = getMarkerIcon(bitmap)

        marker = new H.map.Marker({ lat, lng }, { icon })
      } else {
        marker = new H.map.Marker({ lat, lng })
      }

      if (draggable && behavior) {
        marker.draggable = draggable
        setMarkerDragEvent(map, behavior)
      }

      map.addObject(marker)
      this.marker = marker
    }
    return null
  }

  setPosition(point) {
    if (this.marker) {
      this.marker.setPosition(point)
    }
  }

  render() {
    return null
  }
}

export default Marker
