import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import {
  colors,
  breakpoints,
  fontSizes,
  fontWeights,
  structure,
  zIndex
} from 'styles'
import media from 'media'
import useAvatar from 'hooks/use-avatar'
import useMediaQuery from 'hooks/use-media-query'
import useProfile from 'hooks/use-profile'
import useRole from 'hooks/use-role'
import ChatNotification from 'components/chat-notification'
import { Avatar } from 'components/avatar'
import { TextButton } from 'components/button'
import Icon from 'components/icon'
import { toUnit } from 'utils/styles'
import { ReactComponent as ToggleIcon } from './toggle-icon.svg'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.secondary};
  z-index: ${zIndex.sidebar};
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.5);
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: ${props =>
    toUnit(
      props.expanded ? structure.sidebar.widthExpanded : structure.sidebar.width
    )};
  transition: all 0.2s ease;

  ${media.lessThan('ipadMiniMax')`
    ${props => !props.expanded && `    transform: translateX(-105%)`}
  `}

  ${media.lessThan('phoneMax')`
    height: 100%;
  `}

  @media print {
    display: none;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AvatarContainer = styled(Link)`
  margin: 20px 0 50px 0;
`

const Name = styled.span`
  color: ${colors.white};
  display: block;
  font-size: ${fontSizes.regular};
  letter-spacing: 0.33px;
  line-height: 18px;
  text-align: center;
  margin-top: 3px;
  transition: all 0.2s ease;
  ${props =>
    props.hidden &&
    `
    opacity: 0;
  `}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 10px;
  }
`

const Toggle = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.32);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0;
  margin: 40px 0;
  margin-left: 16px;

  transition: all 0.2s ease;
  transform: ${props => (props.expanded ? 'translateX(5px)' : 'translateX(0)')};
  cursor: pointer;

  :hover {
    background-color: #4e6b88;
  }

  svg {
    height: 10px;
    width: 16px;
    transform: ${props =>
      props.expanded ? 'rotate(90deg) translateY(2px)' : 'rotate(-90deg)'};
  }
`

const Tooltip = styled.p`
  border-radius: 2px;
  padding: 5px 15px;
  background: ${colors.mediumGrey};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: ${colors.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 75px;
  pointer-events: none;
  transform-origin: left;
  transform: scale(0.8);
  opacity: 0;
  transition: all 100ms ease-in-out;
`

const ItemWrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  background-color: ${colors.secondary};
  padding: 15px 21px;
  cursor: pointer;
  position: relative;
  overflow-x: hidden;

  :hover {
    background-color: #4e6b88;
    overflow-x: visible;

    ${Tooltip} {
      opacity: 1;
      transform: scale(1);
    }
  }

  ${props =>
    props.selected &&
    `
    background-color: ${colors.white};
    :hover {
      background-color: ${colors.white};
    }

    svg {
      filter: invert(90%) sepia(43%) saturate(457%) hue-rotate(168deg) brightness(200%) contrast(89%);
      }
    }
  `}
`

const LogoutButton = styled(TextButton)`
  color: ${colors.white};
  display: block;
  font-size: ${fontSizes.regular};
  letter-spacing: 0.33px;
  line-height: 18px;
  padding: 15px 21px;
  height: 48px;
  width: 100%;
  text-align: left;
  ${props =>
    props.hide &&
    `
    visibility: hidden;
  `}

  &:hover {
    background-color: #4e6b88;
  }
`

const ItemArrow = styled.div`
  position: absolute;
  left: 8px;
  top: 22px;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-left: 7px solid ${colors.secondary};
  border-bottom: 4px solid transparent;
`

const ItemLabel = styled.p`
  color: ${colors.white};
  font-size: 16px;
  letter-spacing: 0.33px;
  line-height: 18px;
  margin: 0 0 0 18px;
  white-space: nowrap;

  ${props =>
    props.selected &&
    `
      color: ${colors.secondary};
      font-weight: ${fontWeights.medium};
    `}
`

const ChatNotificationWrapper = styled.div`
  position: absolute;
  z-index: 1;
`

const ItemIcon = styled(Icon)`
  flex-shrink: 0;
`
const TopMenu = styled.div``
const BottomMenu = styled.div`
  margin-top: auto;
`

const MenuOption = ({ to, activePath, iconId, linkText, isChat, expanded }) => {
  const location = useLocation()
  const isActive = new RegExp('^' + activePath).test(location.pathname)

  const [show, setShow] = useState(false)

  const transitions = useTransition(expanded, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 80 },
    unique: true
  })

  return (
    <ItemWrapper
      to={to}
      selected={isActive}
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setShow(true)}
      aria-label={linkText}
    >
      {isActive && <ItemArrow />}
      {isChat && (
        <ChatNotificationWrapper>
          <ChatNotification top="-8px" load />
        </ChatNotificationWrapper>
      )}
      <ItemIcon id={iconId} width={24} height={24} />
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <ItemLabel selected={isActive}>{linkText}</ItemLabel>
            </animated.div>
          )
      )}
      {!expanded && show && <Tooltip>{linkText}</Tooltip>}
    </ItemWrapper>
  )
}

const Overlay = styled.div`
  display: none;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease;
  position: fixed;
  z-index: ${zIndex.sidebar - 1};
  transform: translateX(${props => (props.expanded ? 0 : '-100%')});

  ${media.lessThan('ipadMiniMax')`
    display: block;
  `}

  ${media.lessThan('phoneMax')`
    min-height: 100%;
  `}
`

const Sidebar = ({ expanded, setSidebar }) => {
  const { t } = useTranslation()
  const role = useRole()
  const profile = useProfile()
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const avatarUrl = useAvatar({
    width: isMobile ? 65 : 40,
    height: isMobile ? 65 : 40
  })
  const dispatch = useDispatch()
  const isTenant = role === 'tenant'

  const renderRentalsOption = () => {
    return (
      <MenuOption
        expanded={expanded}
        to="/rentals"
        iconId="menu_rentals"
        linkText={t('b.sidebar.view.rentals.link', 'Rentals')}
        activePath="/rental"
      />
    )
  }

  const renderListingsOption = () => (
    <MenuOption
      expanded={expanded}
      to="/listings"
      iconId="menu_listings"
      linkText={t('b.sidebar.view.listings.link', 'Listings')}
      activePath="/listing"
    />
  )

  const renderTenantsOption = () => (
    <MenuOption
      expanded={expanded}
      to="/tenants"
      iconId="menu_tenants"
      linkText={t('b.sidebar.view.tenants.link', 'Tenants')}
      activePath="/tenant"
    />
  )

  const renderChatOption = () => (
    <MenuOption
      expanded={expanded}
      to="/chat"
      iconId="menu_chat"
      linkText={t('b.sidebar.view.chat.link', 'Chat')}
      activePath="/chat"
      isChat
    />
  )

  const renderTenantMenu = () => (
    <>
      {renderRentalsOption()}
      {renderChatOption()}
    </>
  )

  const renderLandlordMenu = () => (
    <>
      {renderListingsOption()}
      {renderTenantsOption()}
      {renderChatOption()}
    </>
  )

  const toggleSidebar = () => setSidebar(current => !current)

  return (
    <>
      <Overlay expanded={expanded} onClick={toggleSidebar} />
      <Wrapper expanded={expanded}>
        <AvatarContainer to="/profile">
          <Avatar
            location={avatarUrl}
            width={isMobile ? 65 : 40}
            height={isMobile ? 65 : 40}
            left="auto"
            right="auto"
            shadow
          />
          <Name hidden={!expanded}>
            {profile.preferred_name || profile.first_name}
          </Name>
        </AvatarContainer>
        <InnerWrapper onClick={toggleSidebar}>
          <TopMenu onClick={e => e.stopPropagation()}>
            {isTenant ? renderTenantMenu() : renderLandlordMenu()}
          </TopMenu>
          <BottomMenu>
            <MenuOption
              expanded={expanded}
              to="/settings"
              iconId="settings"
              linkText={t('b.sidebar.view.settings.link', 'Settings')}
              activePath="/settings"
            />
            <MenuOption
              expanded={expanded}
              to="/support"
              iconId="support"
              linkText={t('b.sidebar.view.support.link', 'Support')}
              activePath="/support"
            />
            <LogoutButton
              onClick={() => dispatch.auth.logout()}
              hide={!expanded}
            >
              {t('b.header.view.logout.button', 'Log Out')}
            </LogoutButton>

            <Toggle
              aria-label="Toggle sidebar"
              aria-pressed={expanded}
              expanded={expanded}
            >
              <ToggleIcon />
            </Toggle>
          </BottomMenu>
        </InnerWrapper>
      </Wrapper>
    </>
  )
}

export default Sidebar
