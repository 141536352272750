import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { colors, fontWeights } from 'styles'
import { Button } from 'components/anchor'
import P from 'components/paragraph'
import { PhoneCode } from 'components/code'
import Tool from 'components/tools'
import Countdown from 'components/countdown'
import { toast } from 'components/toast-notifications'
import Context from './context'

const FailureText = styled(P)`
  font-weight: bold;
  margin: 50px 0 5px 0;
`

const Bottom = styled.div`
  margin-bottom: 20px;
  font-weight: ${fontWeights.regular};
`

const ResendButton = styled(Button)`
  display: inline-block;
  color: ${colors.link};
  font-weight: ${fontWeights.regular};
`

const CodeContainer = ({ sendCode, title, sendTo, validate }) => {
  const { t } = useTranslation()
  const [codeSent, setCodeSent] = useState(false)
  const { codeRecipient } = useContext(Context)

  const handleSendCode = async () => {
    const { response } = await sendCode()
    if (response.ok) {
      setCodeSent(true)
      toast(
        <Trans i18nKey="b.auth.view.code_send_successful.text">
          We have sent a 4-digit verification code to{' '}
          <strong>{{ sendTo }}</strong>.
        </Trans>,
        {
          title: t(
            'b.auth.view.code_send_successful.title',
            'Code Successfully Sent!'
          ),
          iconId: 'square_check',
          autoClose: 6000
        }
      )
    }
  }

  const onSubmit = ({ code }) => validate(code)

  if (!codeRecipient) {
    return <Redirect to="/" />
  }

  return (
    <PhoneCode
      title={title}
      sendTo={sendTo}
      onSubmit={onSubmit}
      tool={<Tool to="/" type="back" />}
    >
      <FailureText>
        {t(
          'b.auth.view.code_not_received.text',
          'Did not get the security code?'
        )}
      </FailureText>
      <Bottom>
        {codeSent ? (
          <Countdown
            text={t(
              'b.auth.view.resend_code_countdown.text',
              'You can resend in [current] seconds...'
            )}
            delay={60000}
            onComplete={() => setCodeSent(false)}
          />
        ) : (
          <ResendButton onClick={handleSendCode}>
            {t('b.auth.view.resend_code.button', 'Send a new code')}
          </ResendButton>
        )}
      </Bottom>
    </PhoneCode>
  )
}

export default CodeContainer
