import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { colors } from 'styles'
import Section from 'components/section'
import { SectionHeader } from 'components/headings'
import { Button } from 'components/anchor'
import { showContractTermsModal } from './contract-terms-modal'

const ViewButton = styled(Button)`
  color: ${colors.link};
`

const BCTenancyAgreement = ({ contract }) => {
  const { t } = useTranslation()

  return [
    <Section key="tenancy-section">
      <SectionHeader>
        {t('l.contract.edit.bc_tenancy_agreement.text', 'BC Tenancy Agreement')}
      </SectionHeader>
      <Trans i18nKey="l.contract.edit.bc_tenancy_agreement.description">
        <p>
          THIS AGREEMENT incorporates by reference the terms contained within
          the Residential Tenancy Agreement (British Columbia) as prepared by
          the Residential Tenancy Branch.
        </p>
      </Trans>
      <ViewButton
        onClick={() =>
          showContractTermsModal({
            contract
          })
        }
      >
        {t('l.contract.edit.view_full_agreement.button', 'View full agreement')}
      </ViewButton>
    </Section>
  ]
}

export default BCTenancyAgreement
