import React, { useRef } from 'react'
import { useField } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import Input from './input'
import Error from './error'

const getGap = (array, index) => {
  let count = 0
  for (let i = 0; i <= index; i++) {
    count += array[i].length
  }
  return count
}

const convertToGaps = splits => {
  let gaps = []
  for (let i = 0; i < splits.length - 1; i++) {
    gaps[i] = getGap(splits, i)
  }
  return gaps
}

const unmaskValue = (input = '') => input.replace(/ /g, '')

const maskValue = (input, mask) => {
  const splitedMask = mask.split(' ')
  const gaps = convertToGaps(splitedMask)
  const cleanedMask = unmaskValue(mask)

  let maskedInput = ''

  for (let i = 0; i < cleanedMask.length; i++) {
    if (input[i]) {
      if (gaps.includes(i)) {
        maskedInput += ' '
      }
      maskedInput += input[i]
    }
  }
  return maskedInput
}

const MaskedInput = ({ name, format, ...rest }) => {
  const fieldRef = useRef()
  const [field, , helpers] = useField(name)
  useFocusOnError({ fieldRef, name })

  const handleChange = e => {
    helpers.setValue(unmaskValue(e.target.value))
  }

  let maskedValue = format ? maskValue(field.value, format) : field.value

  return (
    <>
      <Input
        ref={fieldRef}
        maxLength={format ? format.length : null}
        {...field}
        {...rest}
        value={maskedValue}
        onChange={handleChange}
      />
      <Error name={name} />
    </>
  )
}

export default React.memo(MaskedInput)
