import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { videoHasProcessed } from 'utils/video'
import Icon from 'components/icon'
import ContextMenu from 'components/context-menu'
import { videoRequest } from 'models/listing/ports'
import { cleanFormData } from 'ports/api'
import axios from 'axios'
import VideoPlayer from 'components/video-player'
import UploadPanel from './upload-panel'

const ItemContainer = styled.div`
  object-fit: cover;
  width: 200px;
  height: 200px;
  position: relative;
  margin-bottom: 20px;
  border-radius: 6px;

  &:nth-child(3n - 4) {
    margin: 0 30px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 156px;
    height: 156px;
    margin-bottom: 10px;
    margin-left: 10px;

    &:nth-child(3n - 4) {
      margin: 0 10px;
    }
  }
`

const VideoContainer = styled(ItemContainer)`
  position: relative;
  height: 175px;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: ${colors.lightGrey};
`

const VideoProcessingNote = styled.p`
  font-size: 14px;
  color: ${colors.regular};
  text-align: center;
  margin: 5px auto 24px;
  padding: 0 10px;
`

const VideoThumbnail = ({ onRemove, videoLocation, disabled }) => {
  const { t } = useTranslation()
  const contextMenuItems = [
    {
      onClick: onRemove,
      label: t('b.listing.edit.image_upload_remove.button', 'Remove'),
      color: colors.red
    }
  ]
  return (
    <VideoContainer>
      <VideoPlayer location={videoLocation} disabled={disabled} />
      <ContextMenu items={contextMenuItems} />
    </VideoContainer>
  )
}

const UploadPlaceholder = styled(ItemContainer)`
  border: 1px dashed #a5a5a5;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  svg {
    margin-bottom: 20px;
  }

  ${props =>
    props.disabled &&
    `
    cursor: default;
    opacity: 0.5;
  `}
`

const StyledIcon = styled(Icon)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 50px;
    height: 35px;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
`

const ProcessingAnimation = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  div {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 2px 5px;
    background: ${colors.secondary};
    border-radius: 50%;
    animation: ${fadeIn} 0.6s infinite alternate;
  }

  div:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  div:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`

const VideoUpload = ({
  items = [],
  unitId,
  listingId,
  onUploadStart,
  onUploadEnd,
  onUploadProgress,
  inProgress = false
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [processing, setProcessing] = useState(false)
  const [showNote, setShowNote] = useState(false)
  const hasItems = !!items.length
  const processingDelay = 5000

  useEffect(() => {
    if (!hasItems || processing) return
    if (!videoHasProcessed(items[0].created_at)) {
      setShowNote(true)
    } else {
      setShowNote(false)
    }
  }, [items, hasItems, processing])

  useEffect(() => {
    if (processing && hasItems) {
      setTimeout(() => {
        setProcessing(false)
      }, processingDelay)
    }
  }, [hasItems, processing])

  const loadFormValues = async () => {
    const {
      body: { formAttributes, formInputs }
    } = await videoRequest(undefined, { unitId })
    const instance = axios.create({
      baseURL: formAttributes.action
    })
    instance.defaults.headers['Content-Type'] = 'multipart/form-data'
    return [instance, formInputs]
  }

  const onDrop = async files => {
    onUploadStart()
    const [uploadVideo, uploadBody] = await loadFormValues()
    const data = cleanFormData({
      ...uploadBody,
      success_action_status: 201,
      file: files[0]
    })
    await uploadVideo({
      method: 'post',
      responseType: 'text',
      data,
      onUploadProgress: function (progressEvent) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        onUploadProgress(progress)
      }
    })
    setProcessing(true)
    onUploadEnd()
  }

  const remove = async item => {
    await dispatch.listing.deleteUnitFile({
      listingId,
      unitId,
      fileId: item.id
    })
  }

  const renderItems = clickProps => {
    const Placeholder = () => {
      return (
        <UploadPlaceholder
          {...(inProgress ? {} : clickProps)}
          disabled={inProgress}
        >
          <StyledIcon id="file_up" width={47} height={55} />
          {inProgress
            ? `${t('b.listing.edit.uploading.text', 'Uploading')}...`
            : t('b.listing.edit.add_video.button', 'Add Video')}
        </UploadPlaceholder>
      )
    }

    const Processing = () => {
      return (
        <UploadPlaceholder disabled>
          <StyledIcon id="processing" width={47} height={55} />
          {t('b.listing.edit.processing.text', 'Processing')}
          <ProcessingAnimation>
            <div />
            <div />
            <div />
          </ProcessingAnimation>
        </UploadPlaceholder>
      )
    }

    if (processing) return <Processing />
    if (hasItems)
      return (
        <>
          {items.map(item => (
            <VideoThumbnail
              key={item.id}
              videoLocation={item.location}
              onRemove={() => remove(item)}
              disabled={showNote}
            />
          ))}
          {showNote && (
            <VideoProcessingNote>
              {t(
                'b.listing.view.video_processing.text',
                'Recently uploaded videos may take up to 10 minutes to be processed and displayed'
              )}
              .
            </VideoProcessingNote>
          )}
        </>
      )
    return <Placeholder />
  }
  return (
    <UploadPanel
      accept="video/mpeg, video/mp4, video/quicktime, video/x-ms-wmv, video/avi, video/msvideo, video/x-msvideo"
      onDrop={onDrop}
      maxFiles={1}
      hasItems={hasItems}
      disabled={inProgress || processing}
    >
      {clickProps => renderItems(clickProps)}
    </UploadPanel>
  )
}

export default React.memo(VideoUpload)
