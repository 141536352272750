import React from 'react'
import { withRouter } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import useRole from 'hooks/use-role'
import useCurrentSession from 'hooks/use-current-session'

const AuthenticatedView = ({
  component: Component,
  render,
  allowTo,
  ...rest
}) => {
  const operationType = useRole()
  const session = useCurrentSession()

  return (
    <Route
      {...rest}
      render={props => {
        if (allowTo && allowTo.indexOf(operationType) < 0)
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location.pathname }
              }}
            />
          )
        return render ? (
          render({ ...props, session })
        ) : (
          <Component {...props} session={session} />
        )
      }}
    />
  )
}

AuthenticatedView.defaultProps = {
  location: {},
  session: null,
  component: null,
  render: null
}

export default withRouter(AuthenticatedView)
