import { createSelector } from 'reselect'
import {
  values,
  flip,
  prop,
  pipe,
  last,
  defaultTo,
  propEq,
  filter,
  when
} from 'ramda'
import { isTruthy } from 'utils'

export const endTenancyRequestSelector = createSelector(
  prop('end_tenancy_requests'),
  flip(prop('listingId')),
  flip(prop('endTenancyId')),
  (endTenancyRequests, listingId, endTenancyId) =>
    pipe(
      values,
      defaultTo([]),
      filter(propEq('listing_id', listingId)),
      when(() => isTruthy(endTenancyId), filter(propEq('id', endTenancyId))),
      last
    )(endTenancyRequests)
)
