import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { Formik, Form } from 'formik'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { path } from 'ramda'
import noop from 'lodash/noop'
import moment from 'moment'
import formatName from 'utils/format-name'
import { getImageUrl } from 'utils/image'
import { confirm } from 'components/dialog'
import { createModal, useModalize } from 'components/modalize'
import { Avatar, defaultAvatar } from 'components/avatar'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { FieldSwitchToggle } from 'components/form'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 30px 25px;
`

const AttendeesRow = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
`

const AttendeesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const AttendeeWrapper = styled.div`
  padding: 20px 10px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AttendeeName = styled.p`
  font-size: ${fontSizes.small};
  margin: 5px 0 0;
`

const Label = styled.p`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.regular};
  margin: 0;
`

const Text = styled.p`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  margin: 0;
`

const NoAttendeesText = styled(Text)`
  align-items: flex-start;
  margin: 25px 0 5px;
`

const TimeslotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px 25px;
  padding-top: 25px;
  border-top: 1px solid ${colors.mediumLightGrey};
`

const TimeslotRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TimeslotText = styled.p`
  font-size: 11px;
  line-height: 17px;
  max-width: 280px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 30px 30px;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin: 0;
  letter-spacing: 0.25px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    width: 130px;
  }
`

const AttendeesModal = ({
  title = 'Showing Info',
  listingId,
  appointmentId,
  lastSnoozeTime,
  meetingAt,
  duration,
  currentTab,
  hidden,
  onSave = noop
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const initialValues = {
    hidden: hidden
  }

  const onSubmit = async (values, { setSubmitting }) => {
    if (values.hidden !== hidden && values.hidden) {
      const response = await dispatch.listing.hideAppointment(appointmentId)
      setSubmitting(false)

      if (response.ok) {
        onSave()
        close()
      }
    } else if (values.hidden !== hidden) {
      const response = await dispatch.listing.unhideAppointment(appointmentId)
      setSubmitting(false)

      if (response.ok) {
        onSave()
        close()
      }
    }
  }

  const handleDelete = async () => {
    const response = await dispatch.listing.deleteAppointment({
      appointmentId,
      listingId
    })
    if (response.ok) {
      close()
    }
  }

  const confirmDelete = () => {
    return confirm(
      t('l.showings.view.confirm_cancel_showing.title', 'Delete Showing?'),
      t(
        'l.showings.view.confirm_cancel_showing.text',
        'Are you sure you want to delete this showing?'
      )
    ).then(() => handleDelete())
  }

  useEffect(() => {
    dispatch.listing.getAppointments(listingId)
  }, [dispatch.listing, listingId])

  const attendeesIds = useSelector(
    path(['listing', listingId, 'listing_appointments', appointmentId, 'users'])
  )

  const attendeesAvatars = useSelector(state =>
    select.listing.attendees(state, {
      listingId,
      attendeesIds
    })
  )

  const getImage = url => (url ? getImageUrl(url) : defaultAvatar)

  const renderAvatars = () => {
    return attendeesIds ? (
      attendeesAvatars.map(attendee => {
        if (attendeesIds.includes(attendee.id)) {
          return (
            <AttendeeWrapper key={attendee.id}>
              <Avatar
                location={getImage(attendee.avatar.aws_s3_key)}
                width={45}
                height={45}
              />
              <AttendeeName>{formatName(attendee)}</AttendeeName>
            </AttendeeWrapper>
          )
        }
        return null
      })
    ) : (
      <NoAttendeesText>
        {t('l.showings.view.no_attendees.text', 'No attendees at the moment')}
      </NoAttendeesText>
    )
  }

  const updatedTime = lastSnoozeTime ? lastSnoozeTime : meetingAt

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>{title}</Title>
      </Header>
      <Formik
        padding="15px"
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, values }) => (
          <Form>
            <AttendeesRow>
              <Label>
                {t('l.showings.view.attendees.label', 'Attendees')}:
              </Label>
              <AttendeesWrapper>{renderAvatars()}</AttendeesWrapper>
            </AttendeesRow>
            <Row>
              <Label>{t('l.showings.view.date.label', 'Date')}:</Label>
              <Text>{moment(updatedTime).format('ddd, MMM. DD, YYYY')}</Text>
            </Row>
            <Row>
              <Label>{t('l.showings.view.time.label', 'Time')}:</Label>
              <Text>{moment(updatedTime).format('h:mm a')}</Text>
            </Row>
            <Row>
              <Label>
                {t(
                  'l.showings.view.showing_duration.label',
                  'Showing Duration'
                )}
                :
              </Label>
              <Text>{duration} min</Text>
            </Row>
            {currentTab !== 'history' ? (
              <>
                <TimeslotWrapper>
                  <TimeslotRow>
                    <Label>
                      {t(
                        'l.showings.view.hide_timeslot.label',
                        'Hide Timeslot'
                      )}
                    </Label>
                    <FieldSwitchToggle enabled={hidden} name="hidden" />
                  </TimeslotRow>
                  <TimeslotText>
                    {t(
                      'l.showings.view.hide_timeslot.text',
                      'Hiding a timeslot will make it invisible to everyone except yourself and attendees.'
                    )}
                  </TimeslotText>
                </TimeslotWrapper>
                <ButtonWrapper>
                  <ActionButton
                    name="license_pill"
                    theme="neutral"
                    width={150}
                    left="auto"
                    onClick={confirmDelete}
                  >
                    {t('l.listing.edit.delete.button', 'Delete')}
                  </ActionButton>
                  <ActionButton
                    name="license_pill"
                    theme="primary"
                    width={150}
                    left="auto"
                    type="submit"
                    disabled={isSubmitting || hidden === values.hidden}
                  >
                    {t('l.listing.edit.submit.button', 'Submit')}
                  </ActionButton>
                </ButtonWrapper>
              </>
            ) : (
              <Label></Label>
            )}
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export const showAttendeesModal = createModal(AttendeesModal)
