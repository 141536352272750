import { curry } from 'ramda'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as Yup from 'yup'
import locale from 'yup/lib/locale'

export const isValidRegex = curry((regex, value) => regex.test(value))

export const isValidEmail = isValidRegex(
  /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/
)

const isLoginPhoneValid = (phoneNumber = '') => {
  const phone = phoneNumber.replace(/[^(0-9+)]/g, '')
  const withPlus = !phone.startsWith('+') ? `+1${phone}` : phone
  return isPhoneValid(withPlus)
}

export const isPhoneValid = (phoneNumber = '') => {
  const phone = parsePhoneNumberFromString(phoneNumber)
  return phone && phone.isValid()
}

const isPhoneValidOrEmpty = (phoneNumber = '') =>
  phoneNumber === '' || isPhoneValid(phoneNumber)

Yup.addMethod(Yup.string, 'phone', function testPhone() {
  return this.test({
    name: 'phone',
    exclusive: true,
    params: locale.string,
    // eslint-disable-next-line
    message: '${phone}', // Yup supports template strings like this
    test: isPhoneValidOrEmpty
  })
})

Yup.addMethod(Yup.string, 'phoneLogin', function testPhoneLogin(message) {
  return this.test({
    name: 'phone',
    exclusive: true,
    params: locale.string,
    // eslint-disable-next-line
    message: message || '${phone}', // Yup supports template strings like this
    test: isLoginPhoneValid
  })
})

Yup.addMethod(Yup.string, 'phoneEmail', function testPhoneEmail() {
  return Yup.lazy((value = '') => {
    const cleaned = value.replace(/(\(|\)|\+|\s|-)/g, '')
    const isNumber = !isNaN(Number(cleaned))
    const message = locale.string.phoneEmail
    return isNumber && value
      ? Yup.string().phoneLogin(message)
      : Yup.string().email(message).required(message)
  })
})
