import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Consumer } from './context'

class TabPanel extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
  }

  render() {
    const { id, children } = this.props
    return (
      <Consumer>
        {({ activeTabId }) => (activeTabId === id ? children : null)}
      </Consumer>
    )
  }
}

export default TabPanel
