import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import FallbackImage from 'components/fallback-image'
import defaultAvatar from './default-avatar.svg'

export const Wrapper = styled.div`
  display: block;
  position: relative;
  margin-left: ${props => (isNaN(props.left) ? props.left : `${props.left}px`)};
  margin-right: ${props =>
    isNaN(props.right) ? props.right : `${props.right}px`};
  margin-bottom: ${props =>
    isNaN(props.bottom) ? props.bottom : `${props.bottom}px`};
  height: ${props =>
    isNaN(props.height) ? props.height : `${props.height}px`};
  width: ${props => (isNaN(props.width) ? props.width : `${props.width}px`)};
  border-radius: ${props => props.radius || 50}%;
  flex-shrink: 0;
  background-color: ${colors.lightGrey};
  background-repeat: no-repeat;
  opacity: ${props => props.opacity || 1};

  @media print {
    margin-right: 80px;
  }
`

const Image = styled.img`
  ${props =>
    props.absolute &&
    `
    position: absolute;
  `}
  width: 100%;
  height: 100%;
  border-radius: ${props => props.radius || 50}%;
  object-fit: cover;
`

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.radius || 50}%;

  ${props =>
    props.shadow &&
    `
      box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.4);
    `}
`

const Avatar = ({
  location,
  fallback = defaultAvatar,
  absolute = true,
  alt,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <FallbackImage
        src={location}
        fallback={fallback}
        alt={alt}
        ImageComponent={props => <Image {...props} absolute={absolute} />}
        {...rest}
      />
      <Shadow {...rest} />
    </Wrapper>
  )
}
export default React.memo(Avatar)
