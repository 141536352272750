import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import useBasic from 'hooks/use-basic'
import { colors, breakpoints, fontSizes } from 'styles'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { Formik, Form, FieldArray } from 'formik'
import { findFileByTag } from 'utils'
import { getFormattedCountries } from 'utils/address'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import useMediaQuery from 'hooks/use-media-query'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import Page from 'components/page'
import AreaTitle from 'components/area-title'
import ProfileRequired from 'components/profile-required'
import {
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldTextarea,
  PhoneInput,
  FieldDatePicker
} from 'components/form'
import ButtonBar from 'features/Profile/button-bar'
import { Button } from 'components/button'
import StateDropdown from 'components/state-dropdown'
import * as Yup from 'yup'
import {
  prop,
  values as ramdaValues,
  pipe,
  map,
  move,
  findIndex,
  propEq,
  filter,
  not,
  includes,
  sort,
  curry
} from 'ramda'
import ContentWrapper from 'components/content-wrapper'

const BirthdayRequired = styled(ProfileRequired)`
  position: static;
  margin-left: 3px;
  margin-bottom: 2px;
`

const AreaWrapper = styled.div``

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const LanguagePanel = styled(StyledPanel)`
  margin-bottom: 1px;
`

const InvisibleInput = styled.input`
  display: none !important;
`

const FieldLabel = styled.p`
  line-height: 20px;
  font-size: ${fontSizes.regular};
  margin: 4px 10px 8px 5px;
`

const StyledGroup = styled(Group)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: flex;
    justify-content: space-around;
  }
`

const OffsetButtonRight = styled(Button)`
  position: relative;
  float: right;
`

const DefaultButton = styled(Button)`
  border-radius: 2px;
  border: 2px solid ${colors.darkGrey};
  float: right;

  &:hover,
  &.selected {
    background-color: ${colors.secondary};
    color: ${colors.white};
    border: 0px;
    > svg path {
      fill: ${colors.white};
    }
  }
`

const PhoneRow = styled(Row)`
  align-items: flex-start;
  > div:not(:first-of-type) {
    padding-top: 6px;
  }
`

const LangRow = styled(Row)`
  align-items: flex-end;
`

const moveTo = curry((predicate, to, list) =>
  move(findIndex(predicate, list), to, list)
)

const EditTenantProfile = ({ history, location }) => {
  const { t } = useTranslation()
  const data = useSelector(select.profile.editTenantProfile)
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const session = useSelector(prop('session')) || {}
  const secureFiles = session.user_secure_files

  data['identification_type'] = findFileByTag(
    'verification_id',
    secureFiles
  )?.document_type

  const { languages, countries } = useBasic(['languages', 'countries'])

  const sortedLanguages = useCallback(
    () => sort((a, b) => a.name.localeCompare(b.name))(languages),
    [languages]
  )()

  const getLanguages = useCallback(() => {
    return pipe(
      ramdaValues,
      moveTo(propEq('txt_id', 'en'), 0),
      moveTo(propEq('txt_id', 'zh'), 1),
      moveTo(propEq('txt_id', 'es'), 2),
      moveTo(propEq('txt_id', 'fr'), 3),
      map(lang => ({
        value: lang.txt_id,
        label: lang.name
      }))
    )(sortedLanguages)
  }, [sortedLanguages])

  const filterSpokenLanguages = (spokenLanguages, allLanguages) => {
    const isNotSpokenLanguage = lang =>
      not(includes(lang.value, spokenLanguages))

    return spokenLanguages && spokenLanguages.length > 0
      ? filter(isNotSpokenLanguage, allLanguages)
      : allLanguages
  }

  const formattedCountries = getFormattedCountries(countries)

  if (!data.id) return null

  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .matches(
          /^[ A-Za-z-_]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      last_name: Yup.string()
        .matches(
          /^[ A-Za-z-_]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      preferred_name: Yup.string().matches(
        /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
        t(
          'b.profile.name.special_characters.error',
          'Cannot include special characters'
        )
      ),
      username: Yup.string().email().required()
    }),
    onSubmit: async (formValues, { setSubmitting }) => {
      const response = await dispatch.profile.save(formValues)
      setSubmitting(false)
      if (response.ok) {
        history.push(location.state?.onSuccessRedirectTo || '/profile')
        window.scrollTo(0, 0)
      }
    }
  })

  const renderLanguages = spokenLanguages => {
    const filteredLanguages = sortedLanguages.filter(
      lang => spokenLanguages?.indexOf(lang.txt_id) >= 0
    )

    return filteredLanguages
  }

  const pushToArray = (lang, spokenLanguages) => {
    if (lang === null || lang === undefined) {
      return spokenLanguages
    }
    if (spokenLanguages === null || !Array.isArray(spokenLanguages)) {
      return new Array(lang)
    }
    if (spokenLanguages?.indexOf(lang) < 0) {
      spokenLanguages.push(lang)
    }
    return spokenLanguages
  }

  const provinceDropDown = values => (
    <StateDropdown
      id="company_state"
      name="company_state"
      country={values.company_country_code}
      countries={countries}
      col={colSize}
    />
  )

  const countryDropDown = setFieldValue => (
    <Group col={colSize}>
      <FieldDropdown
        id="company_country_code"
        name="company_country_code"
        options={formattedCountries}
        onChange={() => setFieldValue('company_state', '')}
        placeholder={t('r.profile.edit.country.label', 'Country') + ':'}
      />
    </Group>
  )

  const companyZip = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_zip"
        placeholder={t('r.profile.edit.postal_code.label', 'Postal Code') + ':'}
      />
    </Group>
  )

  const companyCity = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_city"
        placeholder={t('r.profile.edit.city.label', 'City') + ':'}
      />
    </Group>
  )

  const companyAddress = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_address"
        placeholder={
          t('r.profile.edit.street_address.label', 'Street Address') + ':'
        }
      />
    </Group>
  )

  const companyUnitNumber = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_unit_number"
        placeholder={t('r.profile.edit.unit_number.label', 'Unit Number') + ':'}
      />
    </Group>
  )

  const initialMonth = () => {
    return data.dob ? moment(data.dob) : moment().subtract(18, 'years')
  }

  return (
    <Page>
      <Helmet>
        <title>{t('r.profile.edit.edit_profile.title', 'Edit Profile')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Formik {...formOptions()} initialValues={data}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <PageTitle>
                {t(
                  'r.profile.edit.basic_information.title',
                  'Basic Information'
                )}
              </PageTitle>
              <AreaTitle>
                {t(
                  'r.profile.edit.basic_information.subtitle',
                  'Enter Your Basic Information'
                )}
              </AreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="first_name"
                      disabled={data.identity_verified_state === 'verified'}
                      placeholder={
                        t(
                          'r.profile.edit.legal_first_and_middle_name.label',
                          'Legal First & Middle Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      name="last_name"
                      disabled={data.identity_verified_state === 'verified'}
                      placeholder={
                        t(
                          'r.profile.edit.legal_last_name.label',
                          'Legal Last Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="preferred_name"
                      placeholder={
                        t(
                          'r.profile.edit.preferred_name.label',
                          'Preferred Name'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldDatePicker
                      data-cy="dob"
                      name="dob"
                      initialFocusedDate={initialMonth()}
                      minDate={moment().subtract(100, 'years')}
                      placeholder={
                        t('r.profile.edit.dob.label', 'Birthday') + ':'
                      }
                      displayFormat="YYYY-MM-DD"
                      disableFuture
                    >
                      <BirthdayRequired fields={['dob']} area="basic_info" />
                    </FieldDatePicker>
                  </Group>
                </Row>
                <PhoneRow>
                  <Group col={colSize}>
                    <PhoneInput
                      name="phone"
                      value={values.phone_number}
                      countryCodeAlpha={data.phone_country_code_alpha}
                      placeholder={
                        t('r.profile.edit.phone_number.label', 'Phone Number') +
                        ':'
                      }
                      disabled
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      name="username"
                      placeholder={
                        t('r.profile.edit.email.label', 'Email') + ':'
                      }
                    />
                  </Group>
                </PhoneRow>
              </StyledPanel>
              <AreaWrapper>
                <AreaTitle>
                  {t(
                    'r.profile.edit.languages.subtitle',
                    'Add Languages You Speak'
                  )}
                </AreaTitle>
                <InvisibleInput name="spoken_languages" type="text" />
                <FieldArray
                  name="spoken_languages"
                  render={arrayHelpers => (
                    <AreaWrapper>
                      {renderLanguages(values.spoken_languages).map(lang => (
                        <LanguagePanel
                          key={lang.txt_id}
                          hSpace={30}
                          vSpace={30}
                        >
                          <LangRow>
                            <Group col={6}>
                              <FieldLabel>{lang.name}</FieldLabel>
                            </Group>
                            <Group col={6}>
                              <DefaultButton
                                name="remove_language"
                                width={125}
                                onClick={() => {
                                  // prettier-ignore
                                  const langIndex =
                                 values.spoken_languages.indexOf(
                                  lang.txt_id
                                )
                                  if (langIndex >= 0) {
                                    arrayHelpers.remove(langIndex)
                                  }
                                }}
                              >
                                {t('r.profile.edit.remove.label', 'Remove')}
                              </DefaultButton>
                            </Group>
                          </LangRow>
                        </LanguagePanel>
                      ))}
                    </AreaWrapper>
                  )}
                />
                <StyledPanel hSpace={30} vSpace={30}>
                  <LangRow>
                    <Group col={colSize} data-cy="selected_language">
                      <FieldDropdown
                        id="selected_language"
                        name="selected_language"
                        placeholder={t(
                          'r.profile.edit.select_language.label',
                          'Select Language'
                        )}
                        options={filterSpokenLanguages(
                          values.spoken_languages,
                          getLanguages()
                        )}
                      />
                    </Group>
                    <StyledGroup col={colSize}>
                      <OffsetButtonRight
                        name="add_language"
                        theme="primary"
                        width={125}
                        onClick={() => {
                          const arr = pushToArray(
                            values.selected_language,
                            values.spoken_languages
                          )
                          setFieldValue('spoken_languages', arr)
                          setFieldValue('selected_language', null)
                        }}
                      >
                        {t('r.profile.edit.add_new.label', 'Add')}
                      </OffsetButtonRight>
                    </StyledGroup>
                  </LangRow>
                </StyledPanel>
              </AreaWrapper>
              <AreaWrapper>
                <AreaTitle>
                  {t(
                    'r.profile.edit.current_address.text',
                    'Enter Your Current Address'
                  )}
                  <ProfileRequired
                    fields={['current_address']}
                    area="basic_info"
                  />
                </AreaTitle>
                <StyledPanel hSpace={30} vSpace={30}>
                  <Row>
                    {countryDropDown(setFieldValue)}
                    {companyAddress()}
                  </Row>
                  <Row>
                    {companyUnitNumber()}
                    {companyCity()}
                  </Row>
                  <Row>
                    {provinceDropDown(values)}
                    {companyZip()}
                  </Row>
                </StyledPanel>
              </AreaWrapper>
              <AreaTitle>
                {t('r.profile.edit.my_story.subtitle', 'Enter Your Story')}
                <ProfileRequired fields={['about_me']} area="basic_info" />
              </AreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <FieldTextarea
                  name="about_me"
                  maxLength={260}
                  height={displayMobile ? 230 : 130}
                  placeholder={t(
                    'r.profile.edit.my_story.placeholder',
                    'Here are some things you can share...tell the landlords more about you or share why you are moving!'
                  )}
                />
              </StyledPanel>
            </ContentWrapper>
            <ButtonBar handleBack={history.goBack} sticky>
              <Button
                type="submit"
                theme="primary"
                width={150}
                left={10}
                isSubmitting={isSubmitting}
              >
                {t('r.profile.edit.save.label', 'Save')}
              </Button>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default React.memo(EditTenantProfile)
