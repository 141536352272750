import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LabeledDetail from 'components/labeled-detail'
import useMediaQuery from 'hooks/use-media-query'
import { breakpoints } from 'styles'
import { Row, Group } from 'components/form'
import { DetailsWrapper } from '../elements'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const AutomobileRow = styled(Row)`
  width: 100%;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: flex;
    justify-content: space-around;
  }
`

const StyledGroup = styled(Group)`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 25%;
    max-width: 25%;
  }
`

const AutomobileView = ({ data }) => {
  const [t] = useTranslation(['translation', 'basic'])
  const widthPercent = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? `100%`
    : `50%`

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  const ownershipLabels = {
    lease: t('r.profile.edit.automobile_ownership_lease.option', 'Lease'),
    finance: t('r.profile.edit.automobile_ownership_finance.option', 'Finance'),
    own: t('r.profile.edit.automobile_ownership_own.option', 'Own')
  }

  return (
    <Wrapper>
      <AutomobileRow>
        <StyledGroup col={colSize}>
          <DetailsWrapper>
            <LabeledDetail
              label={t('r.profile.view.make.label', 'Make')}
              value={data.make}
              width={widthPercent}
            />
          </DetailsWrapper>
        </StyledGroup>
        <StyledGroup col={colSize}>
          <DetailsWrapper>
            <LabeledDetail
              label={t('r.profile.view.model.label', 'Model')}
              value={data.model || '-'}
              width={widthPercent}
            />
          </DetailsWrapper>
        </StyledGroup>
      </AutomobileRow>
      <AutomobileRow>
        <StyledGroup col={colSize}>
          <DetailsWrapper>
            <LabeledDetail
              label={t('r.profile.view.model_year.label', 'Year')}
              value={data.model_year?.toString() || '-'}
              width={widthPercent}
            />
          </DetailsWrapper>
        </StyledGroup>
        <StyledGroup col={colSize}>
          <DetailsWrapper>
            <LabeledDetail
              label={t('r.profile.view.ownership.label', 'Ownership Type')}
              value={ownershipLabels[data.ownership] || '-'}
              width={widthPercent}
            />
          </DetailsWrapper>
        </StyledGroup>
      </AutomobileRow>
    </Wrapper>
  )
}

export default React.memo(AutomobileView)
