import { createSelector } from 'reselect'
import {
  __,
  always,
  ascend,
  assoc,
  evolve,
  flatten,
  flip,
  groupBy,
  identity,
  ifElse,
  isNil,
  includes,
  map,
  isEmpty,
  complement,
  mergeLeft,
  not,
  filter,
  pick,
  pipe,
  prop,
  toPairs,
  sortWith,
  trim,
  when,
  values,
  defaultTo,
  length,
  propEq,
  props
} from 'ramda'
import { isTruthy, mergeSpec, defaultIfFalsy } from 'utils'

const listingsSelector = userId =>
  createSelector(
    prop('listings'),
    ifElse(
      always(isTruthy(userId)),
      filter(listing => includes(userId, listing.listing_landlords || [])),
      identity
    )
  )

export const buildingsSelector = createSelector(
  identity,
  flip(prop('userId')),
  (self, userId) =>
    when(
      complement(isEmpty),
      createSelector(
        prop('categorized_listings'),
        prop('buildings'),
        listingsSelector(userId),
        prop('users'),
        prop('units'),
        (
          categorized = [],
          buildings = {},
          filteredListings = {},
          users,
          units
          // eslint-disable-next-line max-params
        ) => {
          const allRented = flatten(props(['rented', 'upcoming'], categorized))
          const withoutRented = pick(['draft', 'published'], categorized)
          const formattedList = { rented: [...allRented], ...withoutRented }

          return map(
            pipe(
              map(prop(__, filteredListings)),
              filter(listing => not(isNil(listing))),
              map(listing =>
                pipe(
                  assoc(
                    'listing_landlords',
                    props(listing.listing_landlords || [], users)
                  ),
                  mergeLeft(prop(listing?.unit_id, units))
                )(listing)
              ),
              groupBy(prop('building_id')),
              toPairs,
              map(([building_id, listings]) => {
                const building = buildings[building_id]
                const buildingData = {
                  ...building,
                  listings
                }
                return evolve(
                  { name: defaultIfFalsy(trim(buildingData.full_street_name)) },
                  buildingData
                )
              }),
              sortWith([ascend(prop('name')), ascend(prop('full_street_name'))])
            ),
            formattedList
          )
        }
      )
    )(self)
)

export const userSelector = createSelector(
  prop('listings'),
  prop('users'),
  (listings, users = {}) =>
    pipe(
      map(
        mergeSpec({
          units_managed: user =>
            pipe(
              values,
              defaultTo([]),
              filter(
                pipe(
                  prop('listing_landlords'),
                  defaultTo([]),
                  includes(user.id)
                )
              ),
              length
            )(listings)
        })
      ),
      values
    )(users)
)

const getListingSelector = createSelector(
  flip(prop('listingId')),
  prop('listings'),
  prop
)

export const listingLandlordsSelector = createSelector(
  getListingSelector,
  prop('users'),
  (listing, users) =>
    when(
      isTruthy,
      mergeSpec({
        listing_landlords: pipe(
          prop('listing_landlords'),
          defaultTo([]),
          map(
            pipe(
              prop(__, users),
              mergeSpec({
                primary: propEq('id', listing?.primary_landlord)
              })
            )
          )
        )
      })
    )(listing)
)
