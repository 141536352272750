import React, { useEffect } from 'react'
import styled from 'styled-components'
import useMediaQuery from 'hooks/use-media-query'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import { toUnit } from 'utils/styles'

const TitleWrapper = styled.button`
  width: 100%;
  padding: 21px 25px;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :not(:disabled) {
    cursor: pointer;
  }
  &[aria-hidden="true"] {
    display: none;
  }
`

const Title = styled.p`
  color: ${colors.regular};
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  line-height: 25px;
  margin: 0;
`

const Toggle = styled.div`
  display: ${props => props.breakpoint ? 'block' : 'none'};
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.desktop}) {
    display: block;
  }
`

const Collapsible = styled.div`
  ${props => props.breakpoint && `
    height: 0;
    overflow: hidden;
    transition: height 0.4s ease-out;
    &[aria-hidden="false"] {
      height: ${toUnit(props.height)};
    }
  `}
`

export const Drawer = ({
  id,
  title,
  isOpen,
  setOpen = noop,
  breakpoint = true,
  hideHeader,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <>
      <TitleWrapper
        onClick={() => setOpen(!isOpen)}
        aria-controls={id}
        aria-expanded={isOpen}
        disabled={!breakpoint}
        aria-hidden={hideHeader && !breakpoint}
      >
        <Title>{title}</Title>
        <Toggle breakpoint={breakpoint}>
          {isOpen
            ? t('l.tenants.view.hide.button', 'Hide')
            : t('l.tenants.view.show.button', 'Show')}
        </Toggle>
      </TitleWrapper>
      <Collapsible
        aria-hidden={!isOpen}
        breakpoint={breakpoint}
        id={id}
        {...rest}
      />
    </>
  )
}

export const MobileDrawer = ({
  isOpen,
  setOpen = noop,
  breakpoint = `(max-width: ${breakpoints.desktop})`,
  ...rest
}) => {
  const displayMobile = useMediaQuery(breakpoint)
  useEffect(() => {
    setOpen(!displayMobile)
  }, [displayMobile, setOpen])
  const expanded = displayMobile ? isOpen : true
  return <Drawer isOpen={expanded} breakpoint={displayMobile} setOpen={setOpen} {...rest} />
}
