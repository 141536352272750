import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { toNumber } from 'lodash'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import Icon from 'components/icon'

const Wrapper = styled.button`
  width: 290px;
  height: 160px;
  border-radius: 6px;
  border: none;
  text-align: left;
  background: ${colors.white};
  box-shadow: 0 0 5px 2px ${colors.grey};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    min-height: 84px;
    align-items: center;
    justify-content: center;
    padding: 15px 10px;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
  `}
`

const IconWrapper = styled.div`
  position: relative;
`

const CardIcon = styled(Icon)`
  flex-shrink: 0;
  width: auto;
`

const Balloon = styled.span`
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: ${colors.primary};
  border-radius: 15px;
  height: 19px;
  min-width: 19px;
  line-height: 19px;
  text-align: center;
  color: ${colors.white};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  padding: 0 4px;
`

const CardTitle = styled.p`
  color: ${colors.regular};
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.38px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin: 20px 0 10px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 13px;
    letter-spacing: 0.27px;
    line-height: 18px;
    text-align: center;
    margin: 5px 0 0;
  }
`

const ArrowIcon = styled(Icon)`
  transform: rotate(90deg) scale(0.9);
  margin-left: 10px;
  margin-top: 1px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const Description = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`
const truncateNumber = n => (n > 99 ? '99+' : n.toString())

const MenuCard = ({ item }) => {
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const CardComponent = item.onClick ? undefined : Link

  return (
    <Wrapper
      as={CardComponent}
      to={item.to}
      onClick={item.onClick}
      disabled={item.disabled}
    >
      <IconWrapper>
        <CardIcon id={item.iconId} height={24} width={'100%'} />
        {toNumber(item.count) > 0 && (
          <Balloon>{truncateNumber(item.count)}</Balloon>
        )}
      </IconWrapper>
      <CardTitle>
        {displayMobile ? item.mobileTitle || item.title : item.title}
        <ArrowIcon id="arrow" height={10} width={16} />
      </CardTitle>
      <Description>{item.description}</Description>
    </Wrapper>
  )
}
export default MenuCard
