import { Switch, Route } from 'react-router'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import ManageListings from './manage-listings'
import ListingEdit from './listing-edit'
import ManageUsers from './manage-users'
import UserEdit from './user-edit'
import UserListings from './user-listings'

const Profile = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.companyManagement.getCompanyListings()
  }, [dispatch.companyManagement])

  return (
    <Switch>
      <Route exact path="/manage/listings" component={ManageListings} />
      <Route exact path="/manage/listings/:listingId" component={ListingEdit} />
      <Route exact path="/manage/users" component={ManageUsers} />
      <Route exact path="/manage/users/create" component={UserEdit} />
      <Route exact path="/manage/users/:userId" component={UserListings} />
      <Route exact path="/manage/users/:userId/edit" component={UserEdit} />
    </Switch>
  )
}

export default React.memo(Profile)
