import React from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.section`
  margin-bottom: 60px;
`

const SectionItem = styled.div`
  margin-bottom: 30px;
`

const Section = ({ children, wrapperComponent }) => {
  const WrapperComponent = wrapperComponent
    ? styled(wrapperComponent)`
        margin-bottom: 60px;
      `
    : SectionWrapper

  return (
    <WrapperComponent>
      {React.Children.toArray(children).map((child, index) => (
        <SectionItem key={`child-${index + 1}`}>{child}</SectionItem>
      ))}
    </WrapperComponent>
  )
}

Section.defaultProps = {
  children: null,
  wrapperComponent: null
}

export default Section
