import ENV_VARS from 'config'
import { post, put, get } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const requestEndTenancy = post(
  `${BACKEND_SERVER}/listings/%listingId%/end_tenancy/request`
)

export const forceEndTenancy = post(
  `${BACKEND_SERVER}/listings/%listingId%/end_tenancy/%endTenancyId%/force`
)

export const cancelEndTenancy = put(
  `${BACKEND_SERVER}/listings/%listingId%/end_tenancy/%requestId%/cancel`
)

export const replyRequest = post(
  `${BACKEND_SERVER}/listings/%listingId%/end_tenancy/response`
)

export const getEndTenancies = get(
  `${BACKEND_SERVER}/listings/%listingId%/end_tenancy`
)
