import { useSelector } from 'react-redux'
import {
  __,
  or,
  head,
  equals,
  filter,
  compose,
  path,
  pathOr,
  values
} from 'ramda'

const useContract = listingId =>
  useSelector(
    compose(
      or(__, {}),
      head,
      filter(compose(equals(listingId), pathOr(false, ['listing_id']))),
      values,
      path(['contract'])
    )
  )

export default useContract
