import React from 'react'
import styled from 'styled-components'
import arrayMove from 'utils/array-move'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import noop from 'lodash/noop'

const ListWrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex: 1;
`

const SortableListComponent = ({
  ItemComponent,
  itemList,
  itemProps,
  LastItem,
  LimitDisclaimer,
  axis = 'xy',
  onChange = noop
}) => {
  const SortableItem = SortableElement(({ value }) => (
    <ItemComponent {...itemProps} data={value} />
  ))

  const SortableList = SortableContainer(({ className, itemClass }) => {
    return (
      <ListWrapper>
        {itemList.map((value, index) => (
          <SortableItem
            className={itemClass}
            key={`item-${index}`}
            index={index}
            value={value}
          />
        ))}
        <LastItem />
        <LimitDisclaimer />
      </ListWrapper>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex, collection }) => {
    const newList = arrayMove(itemList, oldIndex, newIndex)

    onChange(newList, newIndex, oldIndex)
  }

  return (
    <SortableList
      distance={1}
      axis="xy"
      items={itemList}
      onSortEnd={onSortEnd}
    />
  )
}

export default React.memo(SortableListComponent)
