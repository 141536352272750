import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import PageTitle from 'components/page-title'
import useMediaQuery from 'hooks/use-media-query'
import { toUnit } from 'utils/styles'
import ProfileButtonBar from 'features/Profile/button-bar'
import { Label, Row, Group, PasswordInput, PhoneInput } from 'components/form'
import { Button as ButtonText, Anchor } from 'components/anchor'
import {
  changePhonePassword,
  changePhoneNewNumber
} from 'models/requests/ports'
import { Button } from 'components/button'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import * as Yup from 'yup'
import { PanelContainer, PanelHeader } from 'features/Settings/elements'
import { showResetPasswordModal } from 'features/Auth/ResetPasswordModal'
import Page from 'components/page'
import ContentWrapper from 'components/content-wrapper'
import { show as showCodeModal } from './code-modal'

const PanelHeading = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  margin: ${props => toUnit(props.top || 10)} 0 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
`

const StyledLabel = styled(Label)`
  font-size: ${fontSizes.small};
`

const StyledGroup = styled(Group)`
  margin-top: 10px;
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
`
const initialValues = {
  password: '',
  phone: ''
}

const validationSchema = Yup.object().shape({
  password: Yup.string().required(),
  phone: Yup.string().phone().required()
})

const ChangePhoneNumber = ({ history }) => {
  const { t } = useTranslation()
  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  const onSubmit = async (values, { setSubmitting }) => {
    const doIt = async () => {
      const { response: responsePassword } = await changePhonePassword({
        body: { password: values.password }
      })
      if (!responsePassword.ok) return { response: responsePassword }
      const { response: responseNewNumber } = await changePhoneNewNumber({
        phone: values.phone
      })
      return { response: responseNewNumber }
    }
    const { response } = await doIt()
    setSubmitting(false)
    if (response?.ok) {
      showCodeModal({ phone: values.phone, resend: doIt })
    }
  }

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting, handleChange, values, dirty }) => (
          <Form className="flex-full">
            <ContentWrapper>
              <PageTitle>
                {t(
                  'b.settings.edit.change_phone_number.title',
                  'Change My Phone Number'
                )}
              </PageTitle>
              <PanelHeader />
              <PanelContainer>
                <PanelHeading>
                  {t(
                    'b.settings.view.change_phone_number.title',
                    'Change My Phone Number'
                  )}
                </PanelHeading>
                <StyledLabel>
                  {t(
                    'b.settings.edit.change_phone_number_password.label',
                    'To change your phone number, please enter your current password.'
                  )}
                </StyledLabel>
                <StyledGroup col={colSize}>
                  <Row>
                    <PasswordInput
                      name="password"
                      id="password"
                      placeholder={t(
                        'b.settings.edit.change_phone_number_password_field.label',
                        'Password'
                      )}
                    />
                  </Row>
                </StyledGroup>
                <StyledLabel>
                  <ButtonText
                    onClick={() =>
                      showResetPasswordModal({
                        logoutBeforeSending: true,
                        onSubmit: () => history.push('/auth/login')
                      })
                    }
                    fontSize={fontSizes.small}
                    color={colors.link}
                  >
                    {t('b.auth.view.forgot_password.link', 'Forgot password?')}
                  </ButtonText>
                </StyledLabel>
                <PanelHeading top={50}>
                  {t(
                    'b.settings.view.enter_new_phone_number.title',
                    'Enter New Phone Number'
                  )}
                </PanelHeading>
                <StyledLabel>
                  {t(
                    'b.settings.edit.change_phone_number_sms_explanation.label',
                    'We will be using this number to send you an SMS message for verification.'
                  )}
                </StyledLabel>
                <StyledGroup col={colSize}>
                  <Row>
                    <StyledPhoneInput
                      name="phone"
                      value={values.phone}
                      onChange={handleChange('phone')}
                      showFlag={false}
                      placeholder={t(
                        'b.settings.edit.change_phone_number.label',
                        'Phone Number'
                      )}
                    />
                  </Row>
                </StyledGroup>
                <StyledLabel>
                  {t(
                    'b.settings.edit.having_trouble.label',
                    'Having trouble? Get help'
                  )}{' '}
                  <Anchor to="/support" fontSize={fontSizes.small}>
                    {t('b.auth.view.here.link', 'Here.')}
                  </Anchor>
                </StyledLabel>
              </PanelContainer>
            </ContentWrapper>
            <ProfileButtonBar handleBack={history.goBack} sticky>
              <Button
                theme="primary"
                width={150}
                left="auto"
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
                buttonText={t('b.settings.view.cancel.label', 'Cancel')}
              >
                {t('b.settings.view.change.label', 'Change')}
              </Button>
            </ProfileButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ChangePhoneNumber
