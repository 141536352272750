import React, { Fragment, useState, forwardRef } from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights, transitions } from 'styles'
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import noop from 'lodash/noop'
import toId from 'utils/to-id'
import Label, { active } from '../floating-label'

const StyledDateTimePicker = styled(({ hasError, ...rest }) => (
  <MuiDateTimePicker {...rest} />
))`
  height: 50px;
  border-radius: 0;
  background: #fff;
  display: flex !important;

  .MuiInputBase-root {
    transition: border-color ${transitions.ease};
    border-bottom: 1px solid ${colors.mediumGrey} !important;
    height: 50px;
    line-height: 24px;
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeights.medium};
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;

    &::before,
    &::after {
      display: none;
    }

    .MuiInput-input {
      height: 50px;
      padding: 0 11px 0 15px;
    }

    ${props =>
      props.hasError &&
      `
      border-color: ${colors.red} !important;
    `}
  }

  .Mui-focused {
    border-color: ${colors.darkBlue} !important;
  }
`

const customStyles = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: `${colors.secondary}`
      }
    },
    MuiTabs: {
      flexContainer: {
        backgroundColor: `${colors.secondary}`
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: `${colors.secondary}`,
        '&:hover': {
          backgroundColor: `${colors.secondary}`
        }
      },
      current: {
        color: `${colors.secondary}`
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: `${colors.secondary}`
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: `${colors.secondary}`
      },
      thumb: {
        borderColor: `${colors.secondary}`
      }
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: `${colors.secondary}`
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: `${colors.secondary}`
      }
    }
  }
})

const Wrapper = styled.div`
  position: relative;
  ${props =>
    props.isFilled &&
    `
    > label:last-of-type {
      ${active}
    }
  `}
`

const DateTimePicker = forwardRef(
  (
    {
      value,
      placeholder,
      variant = 'inline',
      autoOk = true,
      format = 'DD/MM/YYYY hh:mm A',
      onChange = noop,
      id: initialId,
      children,
      ...props
    },
    ref
  ) => {
    const [isFilled, setIsFilled] = useState(!!value)
    const onChangeWrap = date => {
      setIsFilled(!!date)
      return onChange(date)
    }
    const Outer = placeholder ? Wrapper : Fragment
    const containerProps = placeholder ? { isFilled } : {}
    const id = initialId || toId(placeholder)
    return (
      <ThemeProvider theme={customStyles}>
        <Outer {...containerProps}>
          <StyledDateTimePicker
            value={value}
            ref={ref}
            variant={variant}
            autoOk={autoOk}
            format={format}
            onChange={onChangeWrap}
            id={id}
            {...props}
          />
          {placeholder && (
            <Label>
              {placeholder} {children}
            </Label>
          )}
        </Outer>
      </ThemeProvider>
    )
  }
)

export default DateTimePicker
