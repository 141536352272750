import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontWeights } from 'styles'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatDate from 'utils/format-date'
import formatLeaseType from 'utils/format-lease-type'

const Strong = styled.span`
  font-weight: ${fontWeights.medium};
`

const LeaseTerms = ({ leaseType, leaseEndAction, startDate, endDate }) => {
  const { t } = useTranslation()

  return (
    <CollapsibleBox
      title={t('b.contract.view.lease_terms.subtitle', 'Lease Terms')}
      wrapChildren
    >
      <LabeledValue
        key="lease-type"
        label={t('b.contract.view.lease_type.label', 'Lease type')}
        value={formatLeaseType(leaseType, t)}
      />
      <LabeledValue
        key="start-date"
        label={t(
          'b.contract.view.tenancy_start_date.label',
          'Tenancy start date'
        )}
        value={formatDate(startDate, 'YYYY/MM/DD')}
      />
      {!!endDate && leaseType === 'fixed' && (
        <LabeledValue
          key="end-date"
          label={t(
            'b.contract.view.tenancy_end_date.label',
            'Tenancy end date'
          )}
          value={formatDate(endDate, 'YYYY/MM/DD')}
        />
      )}
      {leaseEndAction === 'tenant_must_vacate' && (
        <Strong>
          {t(
            'b.contract.view.tenant_must_vacate.text',
            'Tenants must move out when lease expires'
          )}
        </Strong>
      )}
      {leaseEndAction === 'continue_month_to_month' && (
        <Strong>
          {t(
            'b.contract.view.continue_month_to_month.text',
            'Tenancy continues on a month-to-month basis after lease ends'
          )}
        </Strong>
      )}
    </CollapsibleBox>
  )
}

LeaseTerms.defaultProps = {
  leaseType: null,
  leaseEndAction: null,
  startDate: null,
  endDate: null
}

export default LeaseTerms
