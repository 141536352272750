import React from 'react'
import styled from 'styled-components'
import { defaultAvatar } from 'components/avatar'

const Image = styled.img`
  width: ${props => props.width || 40}px;
  height: ${props => props.height || 40}px;
  border-radius: ${props => props.height / 2 || 20}px;
  ${({ customStyle }) => customStyle}

  ${props =>
    props.shadow &&
    `
    box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.4);
  `}
`

const Thumbnail = ({ src, width, height, ...rest }) => {
  const source = src || defaultAvatar
  return (
    <Image src={source} width={width} height={height} shadow={!src} {...rest} />
  )
}

export default Thumbnail
