import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints, colors, fontSizes } from 'styles'
import { Input } from 'components/form'

const EmptyMessageRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
`

const EmptyMessage = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  margin: 0px;
  margin-right: 40px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-right: 20px;
    font-size: 12px;
  }
`
const CheckBox = styled(Input)``

const CheckBoxWrapper = styled.div`
  min-width: 83px;
  text-align: center;
`

const EmptyMessageCheckbox = ({
  setDisabled,
  emptyMessage,
  update,
  isChecked,
  initialVisible = true
}) => {
  const [checked, setChecked] = useState(isChecked)

  return (
    <EmptyMessageRow>
      <EmptyMessage>{emptyMessage}</EmptyMessage>
      <CheckBoxWrapper>
        <CheckBox
          onChange={() => {
            setDisabled(!checked)
            setChecked(!checked)
            update(!checked)
          }}
          checked={checked}
          type="checkbox"
        />
      </CheckBoxWrapper>
    </EmptyMessageRow>
  )
}

export default EmptyMessageCheckbox
