import React from 'react'
import styled from 'styled-components'
import { fontWeights } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Label = styled.span`
  flex: 9999;
  margin-right: 30px;
`

const Value = styled.span`
  justify-content: flex-end;
  font-weight: ${fontWeights.medium};
`

const LabeledValue = ({ label, value, className }) => (
  <Wrapper className={className}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Wrapper>
)

LabeledValue.defaultProps = {
  className: null,
  label: null,
  value: null
}

export default LabeledValue
