import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import toNumber from 'lodash/toNumber'
import { join, reject, pipe } from 'ramda'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import useGetSuiteInfo from 'hooks/get-suite-info'
import { getPaymentFrequency } from 'utils/listings'
import { getImageUrl } from 'utils/image'
import { isFalsy } from 'utils'

const OuterWrapper = styled.div`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
`

const Wrapper = styled.div`
  display: flex;
  color: ${colors.regular};
  background-color: ${colors.white};
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const Image = styled.img`
  margin-right: 20px;
  max-height: 88px;
  min-width: 120px;
  object-fit: cover;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-right: 0;
    max-height: 250px;
    height: 250px;
  }
`

const Address = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.38px;
  line-height: 24px;
  margin: 5px 0 10px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-top: 15px;
  }
`

const Info = styled.span`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.3px;
  line-height: 24px;
  display: inline-block;

  :not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${colors.darkGrey};
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 14px;
  }
`

const LoadingState = styled.div`
  background: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
  width: 100%;
  height: 129px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    height: 302px;
  }
`

const formatAddress = address => {
  const unit = address.unit_number ? `${address.unit_number} - ` : ''
  const rest = pipe(
    reject(isFalsy),
    join(', ')
  )([
    address.full_street_name,
    address.city,
    address.state,
    address.zip?.toUpperCase()
  ])
  return `${unit}${rest}`
}

const SuiteInfo = ({ listingId }) => {
  const { t } = useTranslation()
  const data = useGetSuiteInfo(listingId)

  const bedrooms = () => {
    if (data.unit_type_scope_txt_id === 'single')
      return t('b.listing.view.private_room.text', 'Private room')

    const count = toNumber(data.bedrooms)
    if (count === 0) return t('common.studio')
    return t('b.listing.view.count_bedrooms.text', {
      count,
      defaultValue: '{{count}} Bedroom',
      defaultValue_plural: '{{count}} Bedrooms'
    })
  }

  const bathrooms = () => {
    return t('b.listing.view.count_bathrooms.text', {
      count: toNumber(data.bathrooms),
      defaultValue: '{{count}} Bathroom',
      defaultValue_plural: '{{count}} Bathrooms'
    })
  }

  if (!data) return <LoadingState />

  return (
    <OuterWrapper>
      <Wrapper>
        <Image
          src={getImageUrl(data.cover_photo, { width: 369, height: 264 })}
          alt=""
        />
        <div>
          <Address>{formatAddress(data)}</Address>
          <Info>
            ${data.price} /{' '}
            {getPaymentFrequency(data.price_frequency)?.toLowerCase()}
          </Info>
          <Info>{bedrooms()}</Info>
          {data.unit_type_scope_txt_id === 'entire' && (
            <Info>{bathrooms()}</Info>
          )}
        </div>
      </Wrapper>
    </OuterWrapper>
  )
}

export default React.memo(SuiteInfo)
