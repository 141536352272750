import React from 'react'
import styled from 'styled-components'
import { breakpoints, fontWeights, colors } from 'styles'
import { useTranslation } from 'react-i18next'
import useHasLinkedIn from 'hooks/use-has-linkedin'
import useMediaQuery from 'hooks/use-media-query'
import { AvatarWithPicker, AvatarWithStatus } from 'components/avatar'
import ProgressBar from 'components/progress-bar'
import ContactInfo from 'components/contact-info'
import linkedinIcon from './linkedin-icon.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 15px;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 0;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: minmax(30px, 130px);
  grid-column-gap: 40px;
  grid-row-gap: 5px;

  max-width: 720px;
  width: 100%;
  align-items: center;
  margin: 0px auto;
  padding: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 0;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-row-gap: 10px;
    grid-column-gap: 35px;
    padding: 10px 20px;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkedinAnchor = styled.a`
  height: 24px;
  width: 24px;
  margin-top: 10px;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 5px;
  }
`

const LinkedinIcon = styled.img`
  height: 24px;
  width: 24px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 20px;
    width: 20px;
  }
`

const CompletionWrapper = styled.div`
  position: absolute;
  top: -12px;
  right: 145px;
  font-weight: ${fontWeights.medium};

  h6 {
    color: ${colors.regular};
    font-size: 21px;
    margin: 15px 0 0;
  }

  p {
    color: #989898;
    font-size: 10px;
    letter-spacing: 0.8px;
    margin: 0;
  }
`

const AvatarContainer = styled.div`
  position: relative;
  margin-left: auto;
`

const Avatar = ({ data, editable }) => {
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const { t } = useTranslation()
  return displayMobile ? (
    <AvatarWithPicker
      editable={editable}
      data={data}
      width={80}
      height={80}
    />
  ) : (
    <AvatarContainer>
      <AvatarWithStatus
        editable={editable}
        data={data}
        hideText
      />
      <CompletionWrapper>
        <h6>{data.completion_percentage}%</h6>
        <p>{t('b.profile.view.completed.text', 'Completed')}</p>
      </CompletionWrapper>
    </AvatarContainer>
  )
}

const TenantSummary = ({ data, editable, isHeader, children }) => {
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const hasLinkedIn = useHasLinkedIn()
  return (
    <Wrapper>
      {displayMobile && (
        <ProgressBar
          progress={data.completion_percentage}
          showPercentage={false}
        />
      )}
      <ContentWrapper>
        <ProfileWrapper>
          <ContactInfo data={data} showFullName={!isHeader} lineWrap={!isHeader} />
          {hasLinkedIn && data.linked_in_url && (
            <LinkedinAnchor
              href={data.linked_in_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon src={linkedinIcon} />
            </LinkedinAnchor>
          )}
          {children}
        </ProfileWrapper>
        <Avatar editable={editable} data={data} />
      </ContentWrapper>
    </Wrapper>
  )
}

export default TenantSummary
