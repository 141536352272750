import { colors, fontWeights, fontSizes } from 'styles'
import styled from 'styled-components'

export const Box = styled.div`
  height: ${props => props.height || 100}%;
  width: ${props => props.width || 100}%;
  padding: 0px 5px;
  outline: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  background-color: ${props => props.color || colors.white};
  margin-top: ${props => props.top || 0}px;
`

export const ShadowedBox = styled(Box)`
  border-radius: 2px;
  box-shadow: ${props => props.shadow || '0 2px 0 0 rgba(0, 0, 0, 0.19)'};
`

export const DateText = styled.p`
  color: ${colors.white};
  font-size: ${fontSizes.small};
  display: flex;
  flex-direction: column;
  margin: 0;
  text-align: center;
`
export const TimeText = styled.span`
  color: ${props => props.color || colors.regular};
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.small};
  line-height: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
`

export const BoldText = styled(DateText)`
  font-size: 14px;
  font-weight: ${fontWeights.medium};
`

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
