import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints, fontSizes } from 'styles'
import Icon from 'components/icon'
import { useTranslation } from 'react-i18next'

import EmptyView from './empty-view'
import ListView from './list-view'

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  height: calc(100vh - 201.77px);
  width: calc(100vw - 350px);
  left: 350px;
  border: 1px solid ${colors.secondary};
  z-index: 2;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
  overflow: hidden;
`

const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.secondary};
  height: 54px;
`

const Title = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.white};
  margin: 0;
`

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 2;
  cursor: pointer;
  margin: 0;
  transition: opacity 0.2s ease-out;
  path {
    fill: ${colors.white};
  }
  transition: opacity 0.2 ease-in-out;
  :hover {
    opacity: 0.5;
  }
`

const FilePreview = ({ files = [], setFiles, handleSendFile }) => {
  const [t] = useTranslation()

  const close = () => setFiles([])
  const sendAllFiles = items => {
    const filesToSend = files.map(item => handleSendFile([item]))
    Promise.allSettled(filesToSend)
  }

  return (
    <Wrapper>
      <Header>
        <Title> {t('b.chat.view.send_files.title', 'Preview')}</Title>
        <CloseIcon
          aria-label="Close"
          id="close"
          width={30}
          height={30}
          onClick={close}
        />
      </Header>
      {files.length > 0 ? (
        <ListView
          files={files}
          setFiles={setFiles}
          sendAllFiles={sendAllFiles}
        />
      ) : (
        <EmptyView />
      )}
    </Wrapper>
  )
}

export default FilePreview
