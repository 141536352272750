/* eslint-disable max-statements */
import { stringify } from 'query-string'
import { isObject } from 'lodash'
import store from 'store'

const LOADING_DELAY = 500

const parametizeUrl = (template, params) => {
  let url = template

  Object.keys(params).forEach(key => {
    const value = params[key]
    if (url.indexOf(key) === -1) {
      return true
    }

    url = url.replace(`%${key}%`, encodeURI(value))
    return false
  })

  return url
}

const stringifyQueryString = queryString => {
  const stringified = stringify(queryString)
  return stringified ? `?${stringified}` : ''
}

const cleanData = data => {
  if (data instanceof File || data instanceof Blob) return data
  if (Array.isArray(data) || isObject(data)) {
    return JSON.stringify(data)
  }

  return data
}

export const cleanFormData = body => {
  const formData = new FormData()
  Object.keys(body).forEach(k => formData.append(k, cleanData(body[k])))
  return formData
}

const parseBody = (body, opts) => {
  return opts.urlEncoded ? stringify(body) : cleanFormData(body)
}
const parseErrorMessage = message => {
  if (!message.fields) return message
  return Object.values(message.fields)
    .map(m => m)
    .join(', ')
}

const handleError = (response, data) => {
  if (!response.ok) {
    const message = parseErrorMessage(data)
    store.dispatch.error.setError({
      type: 'error',
      message
    })
  }
}

const handleFetch = async (
  url,
  { failSilently, responseType = 'json', ...opts },
  queryString = {}
) => {
  try {
    const response = await fetch(
      `${url}${stringifyQueryString(queryString)}`,
      opts
    )
    const data = await response[responseType]()
    if (!failSilently) handleError(response, data)
    return { response, body: data }
  } catch (error) {
    console.error('[ports/handleFetch] Error occured', error)
    handleError({ ok: false }, { message: 'Unknown Error Occured' })
    return { response: { ok: false } }
  }
}

export const get = (url, opts = {}) => async (
  params = {},
  queryString = {},
  moreOpts = {}
) => {
  try {
    store.dispatch.error.clearError()
    const l = setTimeout(
      () => !opts.noLoading && store.dispatch.loading.setLoading(),
      LOADING_DELAY
    )
    const data = await handleFetch(
      parametizeUrl(url, params),
      {
        method: 'GET',
        credentials: 'include',
        ...opts,
        ...moreOpts
      },
      queryString
    )
    clearTimeout(l)
    !opts.noLoading && store.dispatch.loading.stopLoading()
    return data
  } catch (error) {
    console.error('[ports/get] Error occured', error)
  }

  return null
}

const requestWithBody = method => (url, opts = {}) => async (
  body = {},
  params = {},
  queryString = {},
  moreOpts = {}
) => {
  try {
    store.dispatch.error.clearError()
    const l = setTimeout(
      () => !opts.noLoading && store.dispatch.loading.setLoading(),
      LOADING_DELAY
    )
    const data = await handleFetch(
      parametizeUrl(url, params),
      {
        method,
        credentials: 'include',
        body: parseBody(body, opts),
        ...opts,
        ...moreOpts
      },
      queryString
    )
    clearTimeout(l)
    !opts.noLoading && store.dispatch.loading.stopLoading()
    return data
  } catch (error) {
    console.error(`[ports/${method}] Error occured`, error)
  }

  return null
}

export const post = requestWithBody('post')
export const put = requestWithBody('put')
export const patch = requestWithBody('patch')
export const del = requestWithBody('delete')
