import React from 'react'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatCurrency from 'utils/format-currency'

const SecurityDeposit = ({ securityDeposit }) => {
  const { t } = useTranslation()

  return securityDeposit ? (
    <CollapsibleBox
      title={t('b.contract.view.security_deposit.subtitle', 'Security Deposit')}
      wrapChildren
    >
      <LabeledValue
        label={t('b.contract.view.deposit_amount.label', 'Deposit amount')}
        value={formatCurrency(securityDeposit)}
      />
    </CollapsibleBox>
  ) : null
}

SecurityDeposit.defaultProps = {
  securityDeposit: null
}

export default SecurityDeposit
