import {
  defaultIfFalsy,
  defaultIfEquals,
  headObj,
  mergeSpec,
  renameKeys
} from 'utils'
import {
  compose,
  equals,
  prop,
  filter,
  not,
  mergeLeft,
  mergeRight,
  set,
  lensPath,
  path,
  dissoc,
  isEmpty,
  dissocPath,
  pick,
  evolve,
  pipe,
  values,
  head
} from 'ramda'
import selectorBuilder from 'models/selector-builder'
import { toNumber } from 'lodash'
import { uploadFile, updateFile } from 'ports'
import { batch } from 'react-redux'
import {
  updateProfile,
  getProfile,
  uploadSecureDocument,
  deleteSecureDocument,
  setLinkedInProfile,
  removeLinkedInProfile,
  addPet,
  updatePet,
  deletePet,
  addContact,
  updateContact,
  deleteContact,
  addEmployer,
  updateEmployer,
  deleteEmployer,
  addRoommate,
  updateRoommate,
  deleteRoommate,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  remoteAssist,
  remoteAssistRequestToken
} from './ports'
import {
  editLandlordProfileSelector,
  editTenantProfileSelector,
  petsSelector,
  contactsSelector,
  employersSelector,
  roommatesSelector,
  vehiclesSelector,
  settingsSelector
} from './selectors'

const formatPetFields = pipe(
  pick(['name', 'type', 'breed', 'birth_year', 'size']),
  evolve({
    birth_year: toNumber
  })
)

const formatContactFields = pipe(
  renameKeys({
    company_state: 'state'
  }),
  mergeSpec({
    extra_info: pick([
      'start_date',
      'end_date',
      'reason_for_leaving',
      'street_address',
      'suite_number',
      'city',
      'zip',
      'state',
      'country_code'
    ])
  }),
  pick([
    'first_name',
    'last_name',
    'phone_number',
    'email',
    'relationship',
    'contact_type',
    'extra_info'
  ]),
  evolve({
    email: defaultIfFalsy(null),
    suite_number: defaultIfFalsy(null)
  })
)

const formatEmployerFields = pipe(
  pick([
    'first_name',
    'last_name',
    'employer_name',
    'job_title',
    'phone_number',
    'contact_email',
    'start_date',
    'end_date'
  ]),
  evolve({
    contact_email: defaultIfFalsy(null)
  })
)

const formatRoommateFields = pipe(
  pick([
    'first_name',
    'last_name',
    'phone_number',
    'email',
    'relationship',
    'dob'
  ]),
  evolve({
    email: defaultIfFalsy(null),
    dob: defaultIfFalsy(null)
  })
)

const formatVehicleFields = pipe(
  pick(['make', 'model', 'model_year', 'ownership']),
  evolve({
    model_year: toNumber
  })
)

const formatNotificationFields = pipe(
  evolve({
    matches_receive_emails: toNumber,
    matches_receive_push_notifications: toNumber,
    matches_receive_text_messages: toNumber,
    activity_receive_emails: toNumber,
    activity_receive_push_notifications: toNumber,
    activity_receive_text_messages: toNumber,
    setting_promo_emails: toNumber,
    allow_discovery: toNumber
  })
)

const profile = {
  state: {},
  reducers: {
    clear: () => ({}),
    deleteSecureFile: (state, payload) =>
      set(
        lensPath(['files', 'user_secure_files']),
        compose(
          filter(compose(not, equals(payload), prop('id'))),
          path(['files', 'user_secure_files'])
        )(state),
        state
      ),
    error: (state, payload) => ({ ...state, error: payload }),
    patchSecureFiles: (state, payload) =>
      set(
        lensPath(['files', 'user_secure_files']),
        pipe(path(['files', 'user_secure_files']), mergeLeft(payload))(state),
        state
      ),
    updateSecureFiles: (state, payload) =>
      set(lensPath(['files', 'user_secure_files']), payload, state),
    update: (state, at, payload) => set(lensPath(at), payload, state),
    patch: (state, at, payload) =>
      set(lensPath(at), pipe(path(at), mergeLeft(payload))(state), state),
    remove: (state, at) => dissocPath(at, state),
    merge: mergeRight,
    updateData: (state, payload) => ({ ...state, ...payload, error: null })
  },

  effects: dispatch => ({
    save: async payload => {
      const data = {
        body: {
          about_me: defaultIfFalsy(null)(payload.about_me),
          first_name: payload.first_name,
          last_name: payload.last_name,
          dob: defaultIfFalsy(null)(payload.dob),
          spoken_languages: defaultIfEquals(
            null,
            'null'
          )(payload.spoken_languages),
          job_title: defaultIfFalsy(null)(payload.job_title),
          salary: defaultIfFalsy(null)(payload.salary),
          preferred_name: defaultIfFalsy(null)(payload.preferred_name),
          phone: payload.phone,
          hide_phone_number: toNumber(payload.hide_phone_number),
          username: payload.username,
          work_history_properties_managed: defaultIfFalsy(null)(
            payload.work_history_properties_managed
          ),
          work_history_year_started: payload.work_history_year_started,
          company_info: {
            name: defaultIfFalsy(null)(payload.company_name),
            address: defaultIfFalsy(null)(payload.company_address),
            unit_number: defaultIfFalsy(null)(payload.company_unit_number),
            city: defaultIfFalsy(null)(payload.company_city),
            zip: defaultIfFalsy(null)(payload.company_zip),
            state: defaultIfFalsy(null)(payload.company_state),
            country_code: defaultIfFalsy(null)(payload.company_country_code),
            business_licence: defaultIfFalsy(null)(payload.business_licence),
            phone_number: defaultIfFalsy(null)(payload.company_phone_number),
            phone_country_code: defaultIfFalsy(null)(
              payload.company_phone_country_code
            ),
            phone_country_code_alpha: defaultIfFalsy(null)(
              payload.company_phone_country_code_alpha
            ),
            email: defaultIfFalsy(null)(payload.company_email)
          },
          school_name: defaultIfFalsy(null)(payload.school_name),
          school_subject: defaultIfFalsy(null)(payload.school_subject),
          school_start_date: defaultIfFalsy(null)(payload.school_start_date),
          school_side_working: defaultIfFalsy(null)(payload.school_side_working)
        }
      }
      try {
        const { response, body } = await updateProfile(data)
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.updateData(body)
        return response
      } catch (error) {
        return error
      }
    },
    load: async (_, rootState) => {
      if (rootState.profile.id) return
      try {
        const { response, body } = await getProfile()
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.updateData(body)
      } catch (error) {
        return error
      }
    },
    uploadFile: async payload => {
      try {
        dispatch.loading.setLoading()
        const { response, body } = await uploadSecureDocument({
          body: [
            {
              data: payload.docFile,
              tag: 'id_verification',
              metadata: {
                document_type: payload.identification_type
              }
            },
            {
              data: payload.selfieFile,
              tag: 'id_verification',
              metadata: {
                document_type: 'selfie'
              }
            }
          ]
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        batch(() => {
          dispatch.profile.patchSecureFiles(body.files)
          dispatch.profile.updateData(head(values(body.users)))
        })
        return response
      } catch (error) {
        console.error(
          '[profile/identification_document] Error Occured: ',
          error
        )
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    deleteFile: async payload => {
      try {
        const { response, body } = await deleteSecureDocument(
          {
            body: {
              id: payload,
              tag: 'id_verification'
            }
          },
          { id: payload }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.remove(['files', 'user_secure_files', body.id])
      } catch (error) {
        return { error }
      }
    },
    addLinkedIn: async payload => {
      try {
        dispatch.loading.setLoading()
        const { response, body } = await setLinkedInProfile({
          body: payload
        })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }

        dispatch.session.update(
          ['user_social_media_tokens', body.user_id],
          body
        )
      } catch (error) {
        console.error(
          '[profile/identification_document] Error Occured: ',
          error
        )
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    removeLinkedIn: async payload => {
      try {
        dispatch.loading.setLoading()
        const { response, body } = await removeLinkedInProfile(undefined, {
          id: payload.id
        })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.session.update(['user_social_media_tokens'], {})
      } catch (error) {
        console.error(
          '[profile/identification_document] Error Occured: ',
          error
        )
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async addPet({ file, ...data }) {
      try {
        const payload = formatPetFields(data)
        const { response, body } = await addPet({
          file,
          body: payload
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_pets'], body.user_pets)
        dispatch.profile.patch(['files', 'user_pet'], body.files.user_pet)
        dispatch.profile.parseProfileProgressCallback(body.profile_progress)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updatePet({ file, ...data }) {
      try {
        const payload = formatPetFields(data)
        const { response, body } = await updatePet(
          {
            file,
            body: payload
          },
          {
            petId: data.id
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_pets'], body.user_pets)
        dispatch.profile.patch(['files', 'user_pet'], body.files.user_pet)
        dispatch.profile.parseProfileProgressCallback(body.profile_progress)

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async deletePet(petId) {
      try {
        const { response, body } = await deletePet(undefined, { petId })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }

        dispatch.profile.remove(['user_pets', petId])
        dispatch.profile.parseProfileProgressCallback(body.profile_progress)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updatePetOwner(payload) {
      const data = {
        body: {
          pet_owner: payload.pet_owner
        }
      }
      try {
        dispatch.loading.setLoading()
        const { response, body } = await updateProfile(data)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.profile.updateData(body)
        return response
      } catch (error) {
        return error
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async updateRenterStatus(payload) {
      const data = {
        body: {
          first_time_renter: payload.first_time_renter
        }
      }
      try {
        dispatch.loading.setLoading()
        const { response, body } = await updateProfile(data)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.profile.updateData(body)
        return response
      } catch (error) {
        return error
      } finally {
        dispatch.loading.stopLoading()
      }
    },
    async updateSettings(payload) {
      const data = {
        body: formatNotificationFields(payload)
      }
      try {
        const { response, body } = await updateProfile(data)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.profile.updateData(body)
        dispatch.session.patchExisting(['session'], body)
        return response
      } catch (error) {
        return error
      }
    },
    async uploadAvatar(payload, rootState) {
      const action = payload.fileId ? updateFile : uploadFile
      try {
        const { response, body } = await action(
          {
            file: payload.file,
            body: {
              reference_type: 'user',
              reference_id: toNumber(rootState.profile.id),
              tag: 'user_avatar'
            }
          },
          { fileId: payload.fileId }
        )
        if (response.ok) {
          const newFile = pipe(headObj, headObj)(body.user)
          dispatch.profile.update(['avatar'], newFile)
          dispatch.session.update(['users', rootState.profile.id, 'avatar'], {
            ...newFile,
            pipoca: 1
          })
        }
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async addContact(data, rootState) {
      try {
        let payload = formatContactFields(data)
        if (isEmpty(payload.extra_info)) {
          payload = dissoc('extra_info', payload)
        }
        const { response, body } = await addContact({
          body: payload
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }

        batch(() => {
          dispatch.profile.patch(['user_contacts'], body.user_contacts)
          dispatch.profile.patch(
            ['profile_progress'],
            body.profile_progress.break_down
          )
          dispatch.profile.merge({
            completion_percentage: path(
              ['users', rootState.session.session.id, 'completion_percentage'],
              body
            )
          })
        })

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updateContact(data) {
      try {
        let payload = formatContactFields(data)
        if (isEmpty(payload.extra_info)) {
          payload = dissoc('extra_info', payload)
        }
        const { response, body } = await updateContact(
          {
            body: payload
          },
          {
            contactId: data.id
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_contacts'], body.user_contacts)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async deleteContact(contactId) {
      try {
        const { response, body } = await deleteContact(undefined, {
          contactId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.remove(['user_contacts', contactId])
        dispatch.profile.parseProfileProgressCallback(body.profile_progress)

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    parseProfileProgressCallback(payload) {
      batch(() => {
        dispatch.profile.update(['profile_progress'], payload.break_down)
        dispatch.profile.update(
          ['completion_percentage'],
          payload.completion_percentage
        )
      })
    },
    async addEmployer(data, rootState) {
      try {
        const payload = formatEmployerFields(data)
        const { response, body } = await addEmployer({
          body: payload
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }

        batch(() => {
          dispatch.profile.patch(['user_employers'], body.user_employers)
          dispatch.profile.patch(
            ['profile_progress'],
            body.profile_progress.break_down
          )
          dispatch.profile.merge({
            completion_percentage: path(
              ['users', rootState.session.session.id, 'completion_percentage'],
              body
            )
          })
        })

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updateEmployer(data) {
      try {
        const payload = formatEmployerFields(data)
        const { response, body } = await updateEmployer(
          {
            body: payload
          },
          {
            employerId: data.id
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_employers'], body.user_employers)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async deleteEmployer(employerId, rootState) {
      try {
        const { response, body } = await deleteEmployer(undefined, {
          employerId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }

        batch(() => {
          dispatch.profile.remove(['user_employers', employerId])
          dispatch.profile.patch(
            ['profile_progress'],
            body.profile_progress.break_down
          )
          dispatch.profile.update(
            ['completion_percentage'],
            path(
              ['users', rootState.session.session.id, 'completion_percentage'],
              body
            )
          )
        })

        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async addRoommate(data) {
      try {
        const payload = formatRoommateFields(data)
        const { response, body } = await addRoommate({
          body: payload
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.update(['user_roommates', body.id], body)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updateRoommate(data) {
      try {
        const payload = formatRoommateFields(data)
        const { response, body } = await updateRoommate(
          {
            body: payload
          },
          {
            roommateId: data.id
          }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_roommates', body.id], body)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async deleteRoommate(roommateId) {
      try {
        const { response, body } = await deleteRoommate(undefined, {
          roommateId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.remove(['user_roommates', roommateId])
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async addVehicle(data) {
      try {
        const payload = formatVehicleFields(data)
        const { response, body } = await addVehicle({
          body: payload
        })
        if (!response.ok) {
          throw Error(response.body.message || response.response.statusText)
        }
        dispatch.profile.patch(['user_vehicles'], body.user_vehicles)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async updateVehicle(data) {
      try {
        const payload = formatVehicleFields(data)
        const { response, body } = await updateVehicle(
          {
            body: payload
          },
          {
            vehicleId: data.id
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.profile.patch(['user_vehicles'], body.user_vehicles)
        return { response, body }
      } catch (error) {
        return { error }
      }
    },
    async deleteVehicle(vehicleId) {
      try {
        const { response, body } = await deleteVehicle(undefined, {
          vehicleId
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.profile.remove(['user_vehicles', vehicleId])
        return { response, body }
      } catch (error) {
        return { error }
      }
    },

    async remoteAssistRequestToken(phone) {
      try {
        const { response, body } = await remoteAssistRequestToken({ phone })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { body }
      } catch (error) {
        return { error }
      }
    },
    async remoteAssist(payload) {
      try {
        const { response, body } = await remoteAssist(payload)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        localStorage.setItem('session-id', body.id)
        batch(() => {
          dispatch.session.clear({ keepSession: true })
          dispatch.session.loadSession()
        })
        return { body }
      } catch (error) {
        return { error }
      }
    }
  }),
  selectors: selectorBuilder(slice => ({
    editLandlordProfile() {
      return slice(editLandlordProfileSelector)
    },
    editTenantProfile() {
      return slice(editTenantProfileSelector)
    },
    pets() {
      return slice(petsSelector)
    },
    contacts() {
      return slice(contactsSelector)
    },
    employers() {
      return slice(employersSelector)
    },
    roommates() {
      return slice(roommatesSelector)
    },
    vehicles() {
      return slice(vehiclesSelector)
    },
    settings() {
      return slice(settingsSelector)
    }
  }))
}

export default profile
