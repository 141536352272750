/* eslint-disable complexity */
const getOrdinal = num => {
  const [ones, tens] = [Number(num) % 10, Number(num) % 100]

  if (ones === 1 && tens !== 11) {
    return `${num}st`
  }
  if (ones === 2 && tens !== 12) {
    return `${num}nd`
  }
  if (ones === 3 && tens !== 13) {
    return `${num}rd`
  }

  return `${num}th`
}

const addOrdinal = num => {
  if (isNaN(num)) {
    return num
  }

  return getOrdinal(num)
}

export default addOrdinal
