import React, { useState } from 'react'
import styled from 'styled-components'
import useSetInterval from 'hooks/use-interval'
import useTimeout from 'hooks/use-timeout'
import { fontSizes } from 'styles'

const Text = styled.p`
  margin: 0;
  font-size: ${fontSizes.small};
`

const Countdown = ({ text = '', delay, onComplete, ...rest }) => {
  const [count, setCount] = useState(Math.floor(delay / 1000))
  useTimeout(() => {
    onComplete()
  }, delay)
  useSetInterval(() => {
    setCount(current => current - 1)
  }, 1000)
  return <Text {...rest}>{text.replace('[current]', count)}</Text>
}

export default Countdown
