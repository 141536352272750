/* eslint-disable complexity */
import { isObject, isArray } from 'lodash'

const isTruthy = value => {
  const truthy = !!value && value !== '0'

  if (isArray(value)) {
    return truthy && value.length > 0
  } else if (isObject(value)) {
    return truthy && Object.keys(value).length > 0
  }

  return truthy
}

export default isTruthy
