import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Formik, Form } from 'formik'
import { equals, and } from 'ramda'
import * as Yup from 'yup'
import i18n from 'i18n'
import useMediaQuery from 'hooks/use-media-query'
import { Button } from 'components/button'
import { H1 } from 'components/headings'
import {
  Row,
  Group,
  PasswordInput,
  FieldInput,
  FieldDropdown
} from 'components/form'
import { RadioSelectorList, SelectorItem } from 'components/selector-list'
import Panel from 'components/panel'
import P from 'components/paragraph'
import Taskbar from 'components/taskbar'
import Icon from 'components/icon'
import Tool from 'components/tools'
import useIsMounted from 'hooks/use-is-mounted'
import CheckMark from './images/Checkmark.svg'
import Dot from './images/Dot.svg'

const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 425px;
  max-width: 95%;
  height: auto;
  margin: 60px 100px 60px 100px;
  background-color: ${colors.lightGrey};
  border-radius: 5px;
  box-shadow: 3px 3px 3px grey;
  max-height: calc(100vh - 130px);
`

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const PanelContainer = styled(Panel)`
  background-color: ${colors.lightGrey};
  border-radius: 0 0 6px 6px;

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 20px 5px;
  }
`

const SelectorItemContainer = styled(SelectorItem)`
  background-color: ${colors.white};
  margin: 0;
  padding: 0 10px;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`

const TaskbarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 425px;
  max-width: 100%;
  height: 60px;
  background-color: ${colors.white};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid ${colors.grey};
`

const TaskbarItem = styled(Taskbar)`
  position: absolute;
  left: 10px;
`

const Title = styled(H1)`
  font-size: ${fontSizes.large};
  margin: 10px 0px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: 20px;
  }
`

const DescriptionText = styled(P)`
  margin: 20px 0 0 0;
  text-align: center;
`

const ButtonContainer = styled(Group)`
  text-align: center;
  margin: 15px auto;
`

const TermsContainer = styled(Group)`
  justify-content: space-between;
  padding: 0 15px 0 5px;

  label {
    padding-left: 10px;
    margin-right: 6px;
  }
`

const AgreeLabel = styled.label`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  max-width: 250px;
  text-align: left;
`

const Link = styled.a`
  color: ${colors.regular};
  font-weight: ${fontWeights.regular};
  text-decoration: underline;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};

  svg {
    margin-right: 15px;
  }
`

const WarningBox = styled.div`
  font-size: ${fontSizes.regular};
  font-size: ${fontSizes.small};
  letter-spacing: -0.21px;
  line-height: 16px;
`

const RequirementTitle = styled.p`
  margin: 0;
  line-height: 20px;
`

const Requirement = styled.p`
  margin: 0;
  line-height: 20px;
  font-weight: ${props =>
    props.complete ? fontWeights.medium : fontWeights.regular};
  &::before {
    margin-right: 10px;
    content: url('${props => (props.complete ? CheckMark : Dot)}');
    vertical-align: middle;
  }
`

const rule1 = new RegExp(/.{8,}/) // min. 8 characters
const rule2 = new RegExp(/\d/) // must contain a number
const rule3 = new RegExp(/[A-Za-z]/) // must contain a letter
const rule4 = new RegExp(/[!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?]/) // must contain a special character

const RegisterContainer = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMounted = useIsMounted()
  const registerUser = dispatch.auth.registerUser
  const logout = dispatch.auth.logout
  const [password, setPassword] = useState('')
  const [requirements, setRequirements] = useState({})
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    setRequirements({
      one: rule1.test(password),
      two: rule2.test(password),
      three: rule3.test(password),
      four: rule4.test(password)
    })
  }, [password])

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  const getSubmitButtonId = accountType =>
    accountType === 'landlord'
      ? 'web-signup-submit-button-landlord'
      : 'web-signup-submit-button-renter'

  const formOptions = {
    initialValues: {
      first_name: '',
      last_name: '',
      new_password: '',
      password_confirm: '',
      email: '',
      account_type: '',
      company_name: '',
      agree_to_terms: false,
      operation_type: 'tenant'
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .matches(
          /^[ A-Za-z-_]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      last_name: Yup.string()
        .matches(
          /^[ A-Za-z-_]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      new_password: Yup.string()
        .min(8)
        .matches(
          /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?])[A-Za-z\d!@#$%^{}()[\]./\\,>~+<'"`|\-=_;:&*?]+$/,
          "Password doesn't match the requirements."
        )
        .required(),
      password_confirm: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required(),
      email: Yup.string().email().trim().required(),
      account_type: Yup.string().when('operation_type', {
        is: 'landlord',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      company_name: Yup.string().when(
        ['operation_type', 'account_type'],
        (opType, accType, schema) => {
          if (and(equals(opType, 'landlord'), equals(accType, 'business'))) {
            return schema.required()
          }
          return schema
        }
      ),
      agree_to_terms: Yup.bool().oneOf(
        [true],
        i18n.t(
          'b.auth.create.must_accept_conditions.text',
          'Must Accept Terms and Conditions'
        )
      ),
      operation_type: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      await registerUser(values)
      if (isMounted.current) {
        setSubmitting(false)
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('b.auth.create.create_account.title', 'Create Account')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LoginContainer>
        <TaskbarContainer>
          <TaskbarItem left={<Tool onClick={logout} type="back" />} />
          <Title>
            {t('b.auth.create.create_account.title', 'Create Account')}
          </Title>
        </TaskbarContainer>
        <ScrollWrapper>
          <DescriptionText>
            {t(
              'b.auth.create.first_time_user.text',
              'We noticed that this is your first time here.'
            )}
            <br />
            {t(
              'b.auth.create.introduce_yourself.text',
              'Please introduce yourself.'
            )}
          </DescriptionText>
          <Formik {...formOptions}>
            {({ values, isSubmitting, isValid, setFieldValue }) => (
              <Form>
                <PanelContainer>
                  <Row>
                    <Group col={12}>
                      <RadioSelectorList
                        name="operation_type"
                        value={values.operation_type}
                      >
                        <SelectorItemContainer
                          value="tenant"
                          inputId="checkbox-create-account-select-renter"
                        >
                          <TextContainer>
                            <Icon id="renter" width={28} height={28} />
                            {t('b.auth.create.is_renter.text', "I'm a renter")}
                          </TextContainer>
                        </SelectorItemContainer>
                        <SelectorItemContainer
                          value="landlord"
                          inputId="checkbox-create-account-select-landlord"
                        >
                          <TextContainer>
                            <Icon id="landlord" width={28} height={28} />
                            {t(
                              'b.auth.create.is_landlord.text',
                              "I'm a landlord"
                            )}
                          </TextContainer>
                        </SelectorItemContainer>
                      </RadioSelectorList>
                    </Group>
                  </Row>
                  <Row>
                    <Group col={colSize}>
                      <FieldInput
                        name="first_name"
                        id="first_name"
                        placeholder={t(
                          'b.auth.create.first_name.label',
                          'First Name'
                        )}
                      />
                    </Group>
                    <Group col={colSize}>
                      <FieldInput
                        name="last_name"
                        id="last_name"
                        placeholder={t(
                          'b.auth.create.last_name.label',
                          'Last Name'
                        )}
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group col={12}>
                      <FieldInput
                        name="email"
                        id="email"
                        placeholder={t('b.auth.create.email.label', 'Email')}
                      />
                    </Group>
                  </Row>
                  {values.operation_type === 'landlord' && (
                    <>
                      <Row>
                        <Group col={12}>
                          <FieldDropdown
                            placeholder={t(
                              'b.auth.create.account_type.label',
                              'Account Type'
                            )}
                            options={[
                              {
                                value: 'personal',
                                label: t(
                                  'b.auth.create.personal.label',
                                  'Personal (up to 3 units)'
                                )
                              },
                              {
                                value: 'family',
                                label: t(
                                  'b.auth.create.family.label',
                                  'Family (up to 15 units)'
                                )
                              },
                              {
                                value: 'business',
                                label: t(
                                  'b.auth.create.business.label',
                                  'Business (unlimited)'
                                )
                              }
                            ]}
                            isSearchable={false}
                            name="account_type"
                            id="account_type"
                          />
                        </Group>
                      </Row>
                      {values.account_type === 'business' && (
                        <Row>
                          <Group col={12}>
                            <FieldInput
                              name="company_name"
                              id="company_name"
                              placeholder={t(
                                'b.auth.create.company_name.label',
                                'Company Name'
                              )}
                            />
                          </Group>
                        </Row>
                      )}
                    </>
                  )}
                  <Row>
                    <Group col={12}>
                      <WarningBox>
                        <Trans i18nKey="b.auth.edit.password_requirements.text">
                          <RequirementTitle>
                            Password Requirements:
                          </RequirementTitle>
                          <Requirement complete={requirements.one}>
                            min. 8 characters
                          </Requirement>
                          <Requirement complete={requirements.two}>
                            at least 1 number
                          </Requirement>
                          <Requirement complete={requirements.three}>
                            at least 1 alphabet letter
                          </Requirement>
                          <Requirement complete={requirements.four}>
                            at least 1 special character
                          </Requirement>
                        </Trans>
                      </WarningBox>
                    </Group>
                  </Row>
                  <Row>
                    <Group col={12}>
                      <PasswordInput
                        name="new_password"
                        id="new_password"
                        placeholder={t(
                          'b.auth.create.password.label',
                          'Enter Password (min. 8 characters)'
                        )}
                        onChange={e => {
                          setFieldValue('new_password', e.target.value)
                          setPassword(e.target.value)
                        }}
                        visible={showPassword}
                        setVisible={setShowPassword}
                      />
                    </Group>
                  </Row>
                  <Row>
                    <Group col={12}>
                      <PasswordInput
                        name="password_confirm"
                        id="password_confirm"
                        placeholder={t(
                          'b.auth.create.confirm_password.label',
                          'Confirm Password'
                        )}
                        visible={showPassword}
                      />
                    </Group>
                  </Row>
                  <Row>
                    <TermsContainer col={12} inline>
                      <Trans i18nKey="b.appointment.modal.agree_terms_privacy.text">
                        <AgreeLabel htmlFor="agree_to_terms">
                          I have read and agree to liv.rent{`'`}s{' '}
                          <Link
                            href="https://liv.rent/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </Link>{' '}
                          and{' '}
                          <Link
                            href="https://liv.rent/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </Link>
                          .
                        </AgreeLabel>
                      </Trans>
                      <FieldInput
                        data-cy="agree_to_terms"
                        name="agree_to_terms"
                        id="agree_to_terms"
                        type="checkbox"
                      />
                    </TermsContainer>
                  </Row>
                  <Row>
                    <ButtonContainer col={12}>
                      <Button
                        id={getSubmitButtonId(values.operation_type)}
                        theme="primary"
                        width="100%"
                        disabled={isSubmitting || !isValid}
                        type="submit"
                        radius={2}
                      >
                        {t('b.auth.create.continue.button', 'Continue')}
                      </Button>
                    </ButtonContainer>
                  </Row>
                </PanelContainer>
              </Form>
            )}
          </Formik>
        </ScrollWrapper>
      </LoginContainer>
    </>
  )
}

export default RegisterContainer
