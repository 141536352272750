import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LabeledDetail from 'components/labeled-detail'
import useMediaQuery from 'hooks/use-media-query'
import { breakpoints } from 'styles'
import { Row, Group } from 'components/form'
import {
  SubtitleText,
  DetailsWrapper,
  ContactNameWrapper,
  SubtitleTextSmall
} from '../elements'

const RoommateRow = styled(Row)`
  width: 100%;
`

const RoommateView = ({ data }) => {
  const [t] = useTranslation(['translation', 'basic'])
  const widthPercent = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? `100%`
    : `50%`

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 5

  const infoColSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 7

  return (
    <RoommateRow>
      <Group col={colSize}>
        <ContactNameWrapper width={`100%`}>
          <SubtitleText>
            {data.first_name} {data.last_name}
          </SubtitleText>
          <SubtitleTextSmall>
            {t(`basic:b.contact.relationships.${data.relationship}.text`)}
          </SubtitleTextSmall>
        </ContactNameWrapper>
      </Group>
      <Group col={infoColSize}>
        <DetailsWrapper>
          <LabeledDetail
            label={t('r.profile.view.phone_number.label', 'Phone Number')}
            value={data.phone_number}
            width={widthPercent}
          />
          <LabeledDetail
            label={t('r.profile.view.email.label', 'Email')}
            value={data.email || '-'}
            width={widthPercent}
          />
        </DetailsWrapper>
      </Group>
    </RoommateRow>
  )
}

export default React.memo(RoommateView)
