import ENV_VARS from 'config'
import { get, post, del } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const getTimelines = get(`${BACKEND_SERVER}/timeline`)
export const getNextTimelines = get(
  `${BACKEND_SERVER}/timeline/%type%/next/%pageNumber%`,
  {
    noLoading: true
  }
)
export const getEvents = get(
  `${BACKEND_SERVER}/timeline/%timelineRoomId%/next/%lastEventId%`,
  { noLoading: true }
)
export const getSupportRoom = get(`${BACKEND_SERVER}/timeline/support_rooms`)
export const getSecureFile = get(`${BACKEND_SERVER}/secure/chat/%fileId%`, {
  responseType: 'blob'
})
export const sendMessage = post(`${BACKEND_SERVER}/timeline/%timelineRoomId%`, {
  noLoading: true
})
export const sendImage = post(
  `${BACKEND_SERVER}/timeline/%timelineRoomId%/upload/img`
)
export const sendFile = post(
  `${BACKEND_SERVER}/timeline/%timelineRoomId%/upload/secure`
)

export const getStats = get(`${BACKEND_SERVER}/timeline/stats/extended`, {
  noLoading: true
})
export const setTimelineRead = get(
  `${BACKEND_SERVER}/timeline/%timelineRoomId%/read`,
  { noLoading: true }
)
export const tenantStartChat = post(
  `${BACKEND_SERVER}/timeline/listings/%listingId%`
)

export const deleteTimeline = del(`${BACKEND_SERVER}/timeline/%timelineRoomId%`)

export const reportProblem = post(`${BACKEND_SERVER}/problem_reports/create`)
