import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { fontWeights, colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import CurrencyFormatter from 'components/currency-formatter'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'
import Icon from 'components/icon'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import useRole from 'hooks/use-role'
import moment from 'moment'
import MakePayment from './make-payment'
import { show as showPaidConfirmationModal } from './paid-confirmation-modal'

const Wrapper = styled.div`
  padding: 20px 30px;
  text-align: center;
  position: relative;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin: 20px;
  font-weight: ${fontWeights.medium};
  text-align: center;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
`

const SubHeader = styled.p`
  font-size: 15px;
  font-weight: ${fontWeights.medium};
  line-height: 35px;
  padding: 10px 0px 20px;
  margin-top: 0px;
  border-bottom: 1px solid ${colors.grey};
  text-align: left;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const LastRow = styled(Row)`
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.grey};
`

const LabelText = styled.p`
  margin: 10px 0px;
`

const ValueText = styled.p`
  margin: 10px 0px;
  font-weight: ${fontWeights.medium};
  max-width: 60%;
  text-align: right;
`

const StyledButton = styled(Button)`
  padding: 5px 25px;
  border-radius: 2px;
  margin-bottom: 10px;
`

const FeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`

const FeeText = styled.p`
  letter-spacing: -0.39px;
  line-height: 20px;
  margin: 0;
`

const NoticeText = styled.p`
  font-size: 15px;
  font-weight: ${fontWeights.medium};
  line-height: 20px;
  text-align: left;
  margin-bottom: 50px;
`

const PaymentDetailsModal = ({ paymentId, listingId, history }) => {
  const [t] = useTranslation()
  const { close } = useModalize()
  const isTenant = useRole() === 'tenant'
  const dispatch = useDispatch()
  const cancelRequest = dispatch.payment.cancelPaymentRequest
  const loadPaymentData = dispatch.payment.getPayment

  useEffect(() => {
    if (paymentId) {
      loadPaymentData({ paymentId })
    }
  }, [loadPaymentData, paymentId])

  const paymentData = useSelector(state =>
    select.payment.payment(state, { paymentId, listingId })
  )

  if (!paymentData) return null

  const handleCancelRequest = () => {
    return confirm(
      t(
        'l.payments.edit.cancel_payment_request.title',
        'Cancel Payment Request?'
      ),
      t(
        'l.payments.edit.cancel_payment_request_confirm.text',
        'Are you sure you want to cancel this payment request?'
      )
    ).then(async () => {
      const { error } = await cancelRequest({
        paymentId
      })
      if (!error) {
        close()
      }
    })
  }

  const handleOnPaymentMade = () => {
    showPaidConfirmationModal({ paymentId, listingId, paymentData })
    close()
  }

  const cancelButtonText = t(
    'l.payments.view.payment_details_modal_cancel_request.button',
    'Cancel Request'
  )
  const cancelButtonDisabled = paymentData.state_machine !== 'pending'

  return (
    <Wrapper
      role="dialog"
      aria-labelledby="payment-details-dialog-title"
      aria-describedby="payment-details-dialog-message"
    >
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      <Title id="payment-details-dialog-title">
        {t('l.payments.view.payment_details_modal.title', 'Payment Request')}
      </Title>
      <SubHeader id="payment-details-dialog-message">
        {t('l.payments.view.payment_details_modal.subtitle', 'Request Details')}
      </SubHeader>
      <Row>
        <LabelText>{t('b.payment.view.detail.label', 'Detail')}:</LabelText>
        <ValueText>{paymentData.description}</ValueText>
      </Row>
      <Row>
        <LabelText>
          {t('r.payment.edit.make_payment_due_date.label', 'Due date')}:
        </LabelText>
        <ValueText>
          {moment(paymentData.due_date).format('MM-DD-YYYY')}
        </ValueText>
      </Row>
      {isTenant ? (
        <LastRow>
          <LabelText>
            {t(
              'l.payments.view.payment_details_modal_requested_amount.text',
              'Requested amount'
            )}
            :
          </LabelText>
          <ValueText>
            <CurrencyFormatter value={paymentData.requested_amount} showCents />
          </ValueText>
        </LastRow>
      ) : (
        <>
          <Row>
            <LabelText>
              {t(
                'l.payments.view.payment_details_modal_requested_amount.text',
                'Requested amount'
              )}
              :
            </LabelText>
            <ValueText>
              <CurrencyFormatter
                value={paymentData.requested_amount}
                showCents
              />
            </ValueText>
          </Row>
          <LastRow>
            <FeeInfoWrapper onClick={showProcessingFeesModal}>
              <FeeText>
                {t(
                  'l.payment.edit.request_payment_transaction_fees.text',
                  'Payout fees'
                )}
              </FeeText>
              <Icon id="info_blue" width={16} height={16} />
            </FeeInfoWrapper>
          </LastRow>
          <NoticeText>
            **{' '}
            {t(
              'l.payment.view.payment_details_fee_may_vary.text',
              'Your Final Payout Total may vary based on the actual payment method.'
            )}
          </NoticeText>
        </>
      )}
      {isTenant ? (
        <MakePayment
          data={paymentData}
          onPaid={handleOnPaymentMade}
          close={close}
          listingId={listingId}
          history={history}
        />
      ) : (
        <StyledButton
          aria-label={cancelButtonText}
          aria-disabled={cancelButtonDisabled}
          disabled={cancelButtonDisabled}
          theme="primary"
          onClick={handleCancelRequest}
        >
          {cancelButtonText}
        </StyledButton>
      )}
    </Wrapper>
  )
}

export const show = createModal(PaymentDetailsModal)
