import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { breakpoints, colors, fontSizes, fontWeights, structure } from 'styles'
import { getStripePayout, verifyPayoutDeletion } from 'models/settlements/ports'
import Panel from 'components/panel'
import { Label, Row, Group } from 'components/form'
import PageTitle from 'components/page-title'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import Icon from 'components/icon'

const Wrapper = styled.div`
  padding-top: 80px;
  padding-bottom: ${structure.button_bar.height + 80}px;
`

const ContentWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 10px;
  }
`

export const StyledPanel = styled(Panel)`
  text-align: left;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0px;
  }
`

export const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

export const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  border-top: ${props => (props.borderTop ? 1 : 0)}px solid ${colors.grey};
`

export const StyledLabel = styled(Label)`
  flex: 1;
  margin-right: 20px;

  ${props =>
    props.bold &&
    `
      font-weight: ${fontWeights.medium};
  `}
`

export const StyledValue = styled(Label)`
  font-weight: ${fontWeights.medium};
  flex: 1.5;
  text-align: right;
`

export const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: 10px;
`

const ConnectedText = styled.p`
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.38px;
  line-height: 24px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  padding-bottom: 30px;
  margin: 0 10px 30px;
`

const Description = styled.p`
  font-size: 13px;
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0 10px;
`

const ViewStripe = ({ history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [deleteAllowed, setDeleteAllowed] = useState(false)

  useEffect(() => {
    const loadToken = async () => {
      const { body } = await getStripePayout()
      setToken(body.payout.token)
    }
    loadToken()
  }, [])

  useEffect(() => {
    const verifyDelete = async () => {
      const { response, body } = await verifyPayoutDeletion()
      if (response.ok) {
        setDeleteAllowed(body.is_allowed)
      }
    }
    verifyDelete()
  }, [])

  const handleRemoveStripe = async () => {
    const modalText = deleteAllowed
      ? t(
          'l.payment_settings.edit.stripe_disconnect.text',
          'Are you sure you want to continue? You will no longer be able to receive Visa / Mastercard / American Express payments.'
        )
      : t(
          'l.payment_settings.edit.delete_payment_account.text',
          'Are you sure you want to continue? If you delete this account now, your pending payment requests will be cancelled.'
        )

    return confirm(
      t(
        'b.payment_settings.edit.stripe_disconnect.title',
        'Disconnect with Stripe'
      ),
      modalText,
      {
        okLabel: t('b.payment_settings.edit.disconnect.button', 'Disconnect'),
        cancelLabel: t('b.payment_settings.edit.back.button', 'Back')
      }
    ).then(async () => {
      const response = await dispatch.settlements.deleteStripePayout()
      if (response.ok) {
        history.push('/settings/payment-settings')
      }
    })
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{t('l.payment_settings.view.stripe.title', 'Stripe')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('l.payment_settings.view.stripe.title', 'Stripe')}
      </PageTitle>
      <ContentWrapper>
        <StyledPanel hSpace={30} vSpace={40}>
          <Row>
            <ConnectedText>
              <StyledIcon id="checked_green" width={24} height={24} />
              {t(
                'l.payment_settings.view.stripe_connected.text',
                'Stripe Connected!'
              )}
            </ConnectedText>
          </Row>
          <Row>
            <Description>
              {t(
                'l.payment_settings.view.stripe_connected.description',
                'You’re now able to receive credit card payments. You may disconnect your Stripe account anytime.'
              )}
            </Description>
          </Row>
        </StyledPanel>
        <AreaTitle>
          {t(
            'l.payment_settings.view.account_information.subtitle',
            'Account Information'
          )}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={50}>
          <StyledRow>
            <StyledLabel>
              {t('l.payment_settings.view.account.label', 'Account')}
            </StyledLabel>
            <StyledValue>{token}</StyledValue>
          </StyledRow>
        </StyledPanel>
      </ContentWrapper>
      <ButtonBar sticky>
        <Button
          theme="sane"
          width={150}
          inversed
          onClick={() => history.push('/settings/payment-settings')}
        >
          {t('l.payment_settings.view.back.button', 'Back')}
        </Button>
        <Button theme="primary" width={150} onClick={handleRemoveStripe}>
          {t('l.payment_settings.view.disconnect.button', 'Disconnect')}
        </Button>
      </ButtonBar>
    </Wrapper>
  )
}

export default ViewStripe
