import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { prop, sortBy, values } from 'ramda'
import { colors, fontSizes, fontWeights, breakpoints, structure } from 'styles'
import capitalize from 'utils/capitalize'
import { getImageUrl } from 'utils/image'
import useMediaQuery from 'hooks/use-media-query'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCell
} from 'components/table'
import { EmptyListComponent } from 'components/table-list'
import PageTitle from 'components/page-title'
import { Avatar } from 'components/avatar'
import VerifiedBadge from 'components/verified-badge'
import MobileFooterButton from 'components/mobile-footer-button'
import useCompany from 'hooks/use-company'
import isVerified from 'utils/is-verified'
import ManageUsersHeader from './manage-users-header'

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.white};
  padding: 0 0 100px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 56px;
  }
`

const TableWrapper = styled.div`
  max-width: 900px;
  margin: 0 100px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 25px;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0;
  }
`

const TableHeaderText = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.25px;
  line-height: 18px;
  text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);
  height: 50px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.firstCell &&
    `
    justify-content: flex-start;
    margin-left: 30px;
  `}
`

const CellText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  padding: 35px 0;
  margin: 0 10px;
  text-align: center;

  ${props =>
    props.lastCell &&
    `
    text-align: right;
    margin-right: 30px;

    @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
      margin-right: 20px;
    }
  `}
`

const StyledTable = styled(Table)`
  width: 100%;
  border: 1px solid ${colors.mediumLightGrey};
  border-top: none;
  box-shadow: 0 2px 8px 0 ${colors.mediumLightGrey};

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    border: none;
    border-bottom: 1px solid ${colors.mediumLightGrey};
    box-shadow: none;
  }
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  :not(:last-child) {
    border-bottom: 1px solid ${colors.grey};
  }

  :hover {
    background-color: #eaeff3;
  }
`

const StyledTableCell = styled(TableCell)`
  display: table-cell;
  vertical-align: middle;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-left: 20px;
  }
`

const StyledAvatar = styled(Avatar)`
  float: left;
  margin-right: 15px;
  height: 55px;
  width: 55px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NameText = styled.p`
  display: block;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  padding: 30px 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    max-width: 130px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 170px;
    padding: 0 0 5px;
  }
`

const MobileUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
`

const MobileUserText = styled.p`
  font-size: ${fontSizes.small};
  line-height: 18px;
  margin: 0;
`

const LinkText = styled.span`
  color: ${colors.link};
`

const UserName = ({ user }) => {
  const company = useCompany(user.company_id)
  return (
    <>
      <NameText>
        {user.first_name} {user.last_name}
      </NameText>
      <VerifiedBadge
        type="id"
        width={30}
        height={20}
        left={5}
        bottom={-3}
        verified={isVerified(user, company)}
      />
    </>
  )
}

const ManageUsers = props => {
  const { t } = useTranslation()
  const users = useSelector(select.companyManagement.users)
  const [filteredData, setFilteredData] = useState(users)
  const displayTablet = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  const alphabetizeUsers = userList => sortBy(prop('first_name'), userList)

  const searchUsers = searchString => {
    if (!searchString) setFilteredData(alphabetizeUsers(users))
    const filtered = users.filter(user =>
      JSON.stringify(values(user))
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )
    setFilteredData(alphabetizeUsers(filtered))
  }

  useEffect(() => {
    setFilteredData(alphabetizeUsers(users))
  }, [users])

  return (
    <Wrapper>
      <Helmet>
        <title>{t('l.profile.list.manage_users.title', 'Manage Users')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('l.profile.list.manage_users.title', 'Manage Users')}
      </PageTitle>
      <ManageUsersHeader onSearch={searchUsers} />
      <TableWrapper>
        <StyledTable>
          {!displayMobile && (
            <TableHeader>
              <TableRow
                sticky
                top={displayTablet ? structure.header.heightMobile + 100 : 192}
              >
                <TableHeaderCell>
                  <TableHeaderText firstCell>
                    {t('l.profile.list.user.label', 'User')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.profile.list.role.label', 'Role')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.profile.list.units_managed.label', 'Units Managed')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>&nbsp;</TableHeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map(user => (
                <StyledTableRow
                  key={user.id}
                  onClick={() => props.history.push(`/manage/users/${user.id}`)}
                >
                  <StyledTableCell>
                    <UserWrapper>
                      <StyledAvatar
                        location={
                          user.avatar?.aws_s3_key
                            ? getImageUrl(user.avatar.aws_s3_key, {
                                width: 110,
                                height: 110
                              })
                            : undefined
                        }
                        shadow
                      />
                      {displayMobile ? (
                        <MobileUserWrapper>
                          <NameWrapper>
                            <UserName user={user} />
                          </NameWrapper>
                          <MobileUserText>
                            {t('l.profile.list.role.label', 'Role')}:{' '}
                            {capitalize(user.user_role)}
                          </MobileUserText>
                          <MobileUserText>
                            {t('l.profile.list.units.label', 'Units')}:{' '}
                            {user.units_managed}
                          </MobileUserText>
                        </MobileUserWrapper>
                      ) : (
                        <UserName user={user} />
                      )}
                    </UserWrapper>
                  </StyledTableCell>
                  {!displayMobile && (
                    <>
                      <StyledTableCell>
                        <CellText>{capitalize(user.user_role)}</CellText>
                      </StyledTableCell>
                      <StyledTableCell>
                        <CellText>{user.units_managed}</CellText>
                      </StyledTableCell>
                    </>
                  )}
                  <StyledTableCell>
                    <CellText lastCell>
                      <LinkText>
                        {t('l.profile.list.view.text', 'View')}
                      </LinkText>
                    </CellText>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <StyledTableCell colSpan={3}>
                  <EmptyListComponent />
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      <MobileFooterButton
        to="/manage/users/create"
        breakpoint={breakpoints.phoneMax}
      >
        {t('l.profile.list.add_new_user.button', 'Add new user')}
      </MobileFooterButton>
    </Wrapper>
  )
}

export default ManageUsers
