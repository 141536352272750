import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/button'

const MakePaymentButton = styled(Button)`
  padding: 5px 25px;
`

const MakePayment = ({ data = {}, listingId, history, close }) => {
  const { t } = useTranslation()

  const handleOnClick = () => {
    history.push(`/rental/${listingId}/payments/${data.id}`)
    close()
  }

  const buttonText = t(
    'l.payments.view.payment_details_modal_make_payment.button',
    'Make Payment'
  )
  const isDisabled = data.state_machine !== 'pending'

  return (
    <MakePaymentButton
      aria-label={buttonText}
      aria-disabled={isDisabled}
      disabled={isDisabled}
      theme="primary"
      onClick={handleOnClick}
    >
      {buttonText}
    </MakePaymentButton>
  )
}

export default MakePayment
