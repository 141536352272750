import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'
import { showSignatureModal } from 'components/signature-modal'
import { PageTitle } from 'components/headings'
import SignaturePreview from 'components/signature-preview'
import { CompanyAddress } from 'components/company'
import { Strong } from 'components/typography'

const GroupHeader = styled(Strong)`
  font-size: 17px;
`

const CompanyName = styled.span`
  font-weight: 700;
  display: block;
`

const ReviewSignatures = ({ currentUser, contract }) => {
  const { t } = useTranslation()
  const isBusiness = contract.company?.account_type === 'business'
  const dispatch = useDispatch()
  const localSignature = useSelector(path(['contract', 'signature']))

  return (
    <>
      <PageTitle key="page-title">
        {t('b.contract.view.signatures.subtitle', 'Signatures')}
      </PageTitle>
      <p key="signature-terms">
        {t(
          'b.contract.view.signatures_description.text',
          'IN WITNESS WHEREOF this Agreement has been executed by the Parties as of the Effective Date'
        )}
      </p>
      <p key="tenant-header">
        <GroupHeader>
          {t('b.contract.view.the_tenant.text', 'The Tenant')}
        </GroupHeader>
      </p>
      {contract.signingTenants.map(tenant => (
        <SignaturePreview
          key={tenant.id}
          isCurrentUser={currentUser === tenant.id}
          toggleSignaturePad={() =>
            showSignatureModal({ onSave: dispatch.contract.update })
          }
          signatures={contract.signatures}
          renter={tenant}
          localSignature={localSignature}
        />
      ))}
      {contract.extra_users.map(
        renter =>
          renter.signatureRequired && (
            <SignaturePreview
              key={renter.id}
              isCurrentUser={currentUser === renter.id}
              signatures={contract.signatures}
              toggleSignaturePad={() =>
                showSignatureModal({ onSave: dispatch.contract.update })
              }
              renter={renter}
              localSignature={localSignature}
            />
          )
      )}
      <div key="landlord-header">
        <GroupHeader>
          {t('b.contract.view.the_landlord.text', 'The Landlord')}
        </GroupHeader>
        {isBusiness && (
          <p>
            <CompanyName>{contract.company?.name}</CompanyName>
            <CompanyAddress {...contract.company} />
          </p>
        )}
      </div>
      {contract.landlords.map(landlord => (
        <SignaturePreview
          key={landlord.id}
          isCurrentUser={currentUser === landlord.id}
          toggleSignaturePad={() =>
            showSignatureModal({ onSave: dispatch.contract.update })
          }
          signatures={contract.signatures}
          renter={landlord}
          localSignature={localSignature}
        />
      ))}
    </>
  )
}

export default ReviewSignatures
