import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { propEq, concat, complement, path, filter } from 'ramda'
import { breakpoints } from 'styles'
import { Helmet } from 'react-helmet'
import toInteger from 'utils/to-integer'
import useMediaQuery from 'hooks/use-media-query'
import ButtonBar from 'features/Profile/button-bar'
import LandlordManagement from 'features/Listing/landlord-management'
import Page from 'components/page'
import PageTitle from 'components/page-title'
import { Button } from 'components/button'
import { toast } from 'components/toast-notifications'
import ContentWrapper from 'components/content-wrapper'

const isPrimary = propEq('primary', true)
const findPrimaryLandlord = filter(isPrimary)
const findSecondaryLandlords = filter(complement(isPrimary))

const ListingEdit = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [primaryLandlord, setPrimaryLandlord] = useState([])
  const [secondaryLandlords, setSecondaryLandlords] = useState([])

  const data = useSelector(state =>
    select.companyManagement.listingLandlords(state, {
      listingId
    })
  )

  useEffect(() => {
    if (!data) return
    setPrimaryLandlord(findPrimaryLandlord(data.listing_landlords))
    setSecondaryLandlords(findSecondaryLandlords(data.listing_landlords))
  }, [data])

  const unitId = useSelector(
    path(['companyManagement', 'listings', listingId, 'unit_id'])
  )
  const buildingId = useSelector(
    path(['companyManagement', 'units', unitId, 'building_id'])
  )
  const buildingAddress = useSelector(
    path(['companyManagement', 'buildings', buildingId, 'full_street_name'])
  )

  const handleSave = async () => {
    const landlordData = concat(primaryLandlord, secondaryLandlords)
    const formattedData = {
      primary_user_id: toInteger(findPrimaryLandlord(landlordData)[0].id),
      listing_landlords: landlordData.map(landlord => toInteger(landlord.id)),
      listing_id: data.id
    }

    const response = await dispatch.listing.updateLandlords(formattedData)
    if (response.ok) {
      toast(
        t(
          'l.profile.manage_listings.listing_updated.text',
          'Your listing has been successfully updated.'
        ),
        {
          title: t(
            'l.profile.manage_listings.listing_landlords_updated.title',
            'Listing Landlords Updated'
          ),
          iconId: 'square_check',
          autoClose: 6000
        }
      )
    }
  }

  if (!data) return null

  return (
    <Page>
      <ContentWrapper className="mb-50">
        <Helmet>
          <title>
            {t('l.listing.edit.edit_listing.title', 'Edit Listing')}
          </title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PageTitle>{buildingAddress}</PageTitle>
        <LandlordManagement
          primaryLandlord={primaryLandlord}
          setPrimaryLandlord={setPrimaryLandlord}
          secondaryLandlords={secondaryLandlords}
          setSecondaryLandlords={setSecondaryLandlords}
        />
      </ContentWrapper>
      <ButtonBar handleBack={() => history.goBack()} sticky>
        <Button
          type="submit"
          theme="primary"
          width={150}
          left={displayMobile ? 10 : 'auto'}
          disabled={!primaryLandlord.length}
          onClick={handleSave}
        >
          {t('l.listing.edit.update.button', 'Update')}
        </Button>
      </ButtonBar>
    </Page>
  )
}

export default ListingEdit
