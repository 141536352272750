import { useState, useContext, useCallback } from 'react'
import { Context } from './context'

const useModalize = () => {
  const [visible, setVisible] = useState(false)
  const { dispose } = useContext(Context)
  const close = useCallback(() => {
    setVisible(false)
    dispose()
  }, [dispose])
  return { visible, close }
}

export default useModalize
