import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import { toUnit } from 'utils/styles'
import {
  Accordion as BaseAccordion,
  AccordionHeader as BaseAccordionHeader,
  AccordionContent as BaseAccordionContent
} from 'components/accordion'

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${colors.mediumLightGrey};
  border-top: none;
  box-shadow: 0 2px 8px 0 ${colors.mediumLightGrey};

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    border: none;
    border-bottom: 1px solid ${colors.mediumLightGrey};
    box-shadow: none;
  }
`

export const TableHeader = styled.thead``

const HeaderCell = styled.th`
  background-color: ${colors.secondaryLightBlue};
`

export const TableHeaderCell = props => (
  <HeaderCell {...props}>{props.children}</HeaderCell>
)

export const TableBody = styled.tbody``

const Row = styled.tr`
  ${props =>
    props.sticky &&
    `
    th {
      position: sticky;
      top: ${props.top || 0}px;
      z-index: 20;
    }
  `}
`

export const TableHeaderText = styled.p`
  color: ${colors.white};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.25px;
  line-height: 18px;
  text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);
  height: 50px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.firstCell &&
    `
  justify-content: flex-start;
  margin-left: 30px;
`}

  ${props =>
    props.lastCell &&
    `
  justify-content: flex-end;
  margin-right: 30px;
  cursor: pointer;

  :active {
    opacity: 0.7;
  }
`}
`

export const TableRow = ({ children, ...rest }) => (
  <Row {...rest}>{children}</Row>
)

export const TableCell = styled.td`
  ${props =>
    props.width &&
    `
    width: ${props.width}px;
  `}
  ${props =>
    props.align &&
    `
    text-align: ${props.align};
  `}
`

export const StyledTableCell = styled(TableCell)`
  display: table-cell;
  vertical-align: middle;
  position: relative;

  ${props =>
    props.gapLeft &&
    `
    :not(:last-child) {
      :before {
        content: '';
        position: absolute;
        background: #eaeff3;
        width: 50px;
        height: 3px;
        bottom: -2px;
        left: 0;
      }
    }

    @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
      :not(:last-child) {
      :before {
        width: 35px;
      }
    }
  `}

  ${props =>
    props.gapRight &&
    `
    :before {
      content: '';
      position: absolute;
      background: #eaeff3;
      width: 25px;
      height: 3px;
      bottom: -2px;
      right: 0;
    }
  `}
`

export const SingleTableRow = styled(TableRow)`
  cursor: pointer;
  border-bottom: 1px solid ${colors.grey};
`

export const AccordionTableRow = styled(SingleTableRow)`
  background-color: #eaeff3;
`

const AccordionHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid ${colors.mediumLightGrey};
  padding: 30px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    align-items: flex-start;
    padding: 20px 25px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 20px 15px;
  }
`

export const AccordionHeaderText = styled.p`
  display: flex;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;

  span {
    display: block;
    font-weight: ${fontWeights.medium};
    margin-right: 30px;
    min-width: 175px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: block;

    span {
      margin-right: 20px;
      margin-bottom: 3px;
      min-width: 120px;
    }
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    max-width: 220px;
  }
`

const AccordionToggleButton = styled.button`
  display: block;
  border: none;
  background: none;
  outline: none;
  font-size: ${fontSizes.regular};
  color: ${colors.link};
  letter-spacing: -0.39px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  text-align: right;
  cursor: pointer;

  :active {
    opacity: 0.7;
  }
`

const ExpandButton = styled(AccordionToggleButton)``

const CollapseButton = styled(AccordionToggleButton)``

const AccordionHeaderRow = styled(TableRow)`
  ${props =>
    props.active
      ? `
      ${ExpandButton} {display: none;}
      ${CollapseButton} {display: block;}
    `
      : `
      ${ExpandButton} {display: block;}
      ${CollapseButton} {display: none;}
    `}
`

export const Accordion = BaseAccordion

export const AccordionHeader = ({ children, colSpan }) => {
  const { t } = useTranslation()

  return (
    <BaseAccordionHeader>
      <AccordionHeaderRow>
        <StyledTableCell colSpan={colSpan}>
          <AccordionHeaderContent>
            {children}
            <ExpandButton aria-expanded="false">
              {t('l.listing.list.expand.button', 'Expand')}
            </ExpandButton>
            <CollapseButton aria-expanded="true">
              {t('l.listing.list.collapse.button', 'Collapse')}
            </CollapseButton>
          </AccordionHeaderContent>
        </StyledTableCell>
      </AccordionHeaderRow>
    </BaseAccordionHeader>
  )
}

export const AccordionContent = BaseAccordionContent

export const MobileRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-left: ${props => (props.indent ? 35 : 25)}px;

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-left: ${props => (props.indent ? 35 : 15)}px;
  }
`

export const MobileUnitText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;

  strong {
    font-weight: ${fontWeights.medium};
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    max-width: 220px;
  }
`

export const MobileUnitDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: ${props => toUnit(props.bottom) || 0};

  p {
    color: ${colors.regular};
    font-size: 14px;
    letter-spacing: -0.34px;
    line-height: 16px;
    margin: 0;

    :not(:first-child) {
      padding-left: 8px;
      margin-left: 8px;
      border-left: 1px solid ${colors.regular};
    }
  }
`

export const CellText = styled.p`
  display: block;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  padding: 0;
  margin: 0 5px;
  text-align: center;
  padding-top: ${props => props.top || 30}px;
  padding-bottom: ${props => props.bottom || 30}px;

  strong {
    font-weight: ${fontWeights.medium};
  }

  ${props =>
    props.firstCell &&
    `
  padding: 20px 0;
  text-align: left;
  margin: 0;
  margin-left: 30px;
  max-width: 180px;
`}

  ${props =>
    props.lastCell &&
    `
  color: ${colors.link};
  text-align: right;
  margin-right: 30px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 0;
    margin-right: 25px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin-right: 15px;
  }
`}

${props =>
  props.indent &&
  `
    margin-left: 50px;
  `}
`
