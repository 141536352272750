import React, { useContext } from 'react'
import Context from './context'

const AccordionContent = ({ children }) => {
  const value = useContext(Context)
  return value.active
    ? React.Children.map(children, child =>
        React.cloneElement(child, {
          active: value.active
        })
      )
    : null
}

export default AccordionContent
