import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  smallPhone: '370px',
  phoneMax: '599px',
  tabletPortrait: '600px',
  ipadMiniMax: '767px',
  tabletLandscape: '900px',
  dashboardMobile: '1180px',
  desktop: '1200px',
  bigDesktop: '1800px'
})
export default media
