import { ReactComponent as AirConditioningIcon } from './air_conditioning.svg'
import { ReactComponent as Addendum } from './addendum.svg'
import { ReactComponent as ApplicationIcon } from './application.svg'
import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as BackArrow } from './thin_arrow_left.svg'
import { ReactComponent as AssistedLivingIcon } from './assisted_living.svg'
import { ReactComponent as AttachmentIcon } from './attachment.svg'
import { ReactComponent as BathIcon } from './bath.svg'
import { ReactComponent as EditIcon } from './edit_light_grey.svg'
import { ReactComponent as BadmintonIcon } from './badmington.svg'
import { ReactComponent as BalconyIcon } from './balcony.svg'
import { ReactComponent as BanIcon } from './ban.svg'
import { ReactComponent as BasketballCourtIcon } from './basketball_court.svg'
import { ReactComponent as BikeStorageIcon } from './bike_storage.svg'
import { ReactComponent as BilliardsRoom } from './billiards_room.svg'
import { ReactComponent as BirthdayIcon } from './birthday.svg'
import { ReactComponent as BbqIcon } from './bbq.svg'
import { ReactComponent as BowlingAlleyIcon } from './bowling_alley.svg'
import { ReactComponent as BuildingWhiteIcon } from './building_white.svg'
import { ReactComponent as BuildingIcon } from './building.svg'
import { ReactComponent as BuiltinVacuumIcon } from './builtin_vacuum.svg'
import { ReactComponent as CallIcon } from './call.svg'
import { ReactComponent as CameraIcon } from './camera.svg'
import { ReactComponent as CameraBlueIcon } from './camera_blue.svg'
import { ReactComponent as CarpetsIcon } from './carpets.svg'
import { ReactComponent as CarChargingIcon } from './car_charging.svg'
import { ReactComponent as CarValetIcon } from './car_valet.svg'
import { ReactComponent as CaretakerIcon } from './caretaker.svg'
import { ReactComponent as CarshareIcon } from './carshare.svg'
import { ReactComponent as ChangeRoomIcon } from './change_room.svg'
import { ReactComponent as ChatIcon } from './chat.svg'
import { ReactComponent as CheckIcon } from './checkmark_dark.svg'
import { ReactComponent as CheckedGreen } from './checked_green.svg'
import { ReactComponent as CloseButtonDarkIcon } from './close_button_dark.svg'
import { ReactComponent as CloseButtonIcon } from './close_button.svg'
import { ReactComponent as CloseIcon } from './close_dark.svg'
import { ReactComponent as ClubhouseIcon } from './clubhouse.svg'
import { ReactComponent as CoinLaundryIcon } from './coin_laundry.svg'
import { ReactComponent as ConcieregeIcon } from './concierge.svg'
import { ReactComponent as ConfettiIcon } from './confetti.svg'
import { ReactComponent as ContractIcon } from './contract.svg'
import { ReactComponent as MarkupEraser } from './markup_eraser.svg'
import { ReactComponent as CourtyardIcon } from './courtyard.svg'
import { ReactComponent as DaycareIcon } from './daycare.svg'
import { ReactComponent as DeleteIcon } from './delete.svg'
import { ReactComponent as DogMindingIcon } from './dog_minding.svg'
import { ReactComponent as DoormanIcon } from './doorman.svg'
import { ReactComponent as DishwasherIcon } from './dishwasher.svg'
import { ReactComponent as DryerIcon } from './dryer.svg'
import { ReactComponent as ElevatorIcon } from './elevator.svg'
import { ReactComponent as ErrorIcon } from './error.svg'
import { ReactComponent as ExerciseCentreIcon } from './exercise_centre.svg'
import { ReactComponent as FamilyRoomIcon } from './family_room.svg'
import { ReactComponent as FileUpIcon } from './file-up.svg'
import { ReactComponent as FireplaceIcon } from './fireplace.svg'
import { ReactComponent as FridgeIcon } from './fridge.svg'
import { ReactComponent as FurnishedIcon } from './furnished.svg'
import { ReactComponent as GamesRoomIcon } from './games_room.svg'
import { ReactComponent as GarbageIcon } from './garbage_pickup.svg'
import { ReactComponent as GarbageValetIcon } from './garbage_valet.svg'
import { ReactComponent as GarburatorIcon } from './garburator.svg'
import { ReactComponent as GardenIcon } from './garden.svg'
import { ReactComponent as GatedComplexIcon } from './gated_complex.svg'
import { ReactComponent as GourmetKitchensIcon } from './gourmet_kitchens.svg'
import { ReactComponent as GuestSuiteIcon } from './guest_suite.svg'
import { ReactComponent as GymIcon } from './gym.svg'
import { ReactComponent as HiddenIcon } from './hidden.svg'
import { ReactComponent as HighCeilingsIcon } from './high_ceilings.svg'
import { ReactComponent as HotTubIcon } from './hot_tub.svg'
import { ReactComponent as HouseWhiteIcon } from './house_white.svg'
import { ReactComponent as HouseIcon } from './house.svg'
import { ReactComponent as IndoorBasketballCourtIcon } from './indoor_basketball_court.svg'
import { ReactComponent as IndoorPoolIcon } from './indoor_pool.svg'
import { ReactComponent as InfoBlueIcon } from './info-blue.svg'
import { ReactComponent as InfoCircleIcon } from './info-circle.svg'
import { ReactComponent as InsuiteLaundryIcon } from './insuite_laundry.svg'
import { ReactComponent as InsuiteStorage } from './insuite_storage.svg'
import { ReactComponent as LandlordIcon } from './landlord.svg'
import { ReactComponent as LanguageIcon } from './language.svg'
import { ReactComponent as LaundryIcon } from './laundry.svg'
import { ReactComponent as LaundryNOIcon } from './laundryNO.svg'
import { ReactComponent as LibraryIcon } from './library.svg'
import { ReactComponent as ListingViewsIcon } from './listing_views.svg'
import { ReactComponent as ListingActiveIcon } from './listing_active.svg'
import { ReactComponent as LivLogoIcon } from './liv_logo.svg'
import { ReactComponent as LoungeIcon } from './lounge.svg'
import { ReactComponent as MailRoomIcon } from './mail_room.svg'
import { ReactComponent as MasssageSpaRoomIcon } from './massage_spa_room.svg'
import { ReactComponent as MediaRoomIcon } from './media_room.svg'
import { ReactComponent as MeetingRoomIcon } from './meeting_room.svg'
import { ReactComponent as MicrowaveIcon } from './microwave.svg'
import { ReactComponent as MoneyIcon } from './money.svg'
import { ReactComponent as NoteIcon } from './note.svg'
import { ReactComponent as DownArrowDarkIcon } from './down_arrow_dark.svg'
import { ReactComponent as DownArrowWhiteIcon } from './down_arrow_white.svg'
import { ReactComponent as OutdoorBasketballCourtIcon } from './outdoor_basketball_court.svg'
import { ReactComponent as OutdoorPoolIcon } from './outdoor_pool.svg'
import { ReactComponent as ParkingIcon } from './parking.svg'
import { ReactComponent as ParkingNOIcon } from './parkingNO.svg'
import { ReactComponent as PasswordVisible } from './password_visible.svg'
import { ReactComponent as PasswordMasked } from './password_masked.svg'
import { ReactComponent as PatioDeckTerraceIcon } from './patio_deck_terrace.svg'
import { ReactComponent as PaymentsIcon } from './payments.svg'
import { ReactComponent as PetsIcon } from './pets_yes.svg'
import { ReactComponent as PetsNotAllowedIcon } from './pets_no.svg'
import { ReactComponent as PlaceholderIcon } from './placeholder.svg'
import { ReactComponent as PlayIcon } from './play.svg'
import { ReactComponent as PlayVideoIcon } from './play_video.svg'
import { ReactComponent as PlaygroundIcon } from './playground.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as PlusWhiteIcon } from './plus_white.svg'
import { ReactComponent as PlusSmallIcon } from './plus_small.svg'
import { ReactComponent as PoolIcon } from './pool.svg'
import { ReactComponent as PrivateYardIcon } from './private_yard.svg'
import { ReactComponent as ProfessionIcon } from './profession.svg'
import { ReactComponent as MarkupMarker } from './markup_marker_filled.svg'
import { ReactComponent as ProcessingIcon } from './processing.svg'
import { ReactComponent as ProfileIcon } from './profile.svg'
import { ReactComponent as RecreationCentreIcon } from './recreation_centre.svg'
import { ReactComponent as RecreationRoomIcon } from './recreation_room.svg'
import { ReactComponent as RectangleIcon } from './rectangle.svg'
import { ReactComponent as RectangleFilledIcon } from './rectangle_filled.svg'
import { ReactComponent as RenterIcon } from './renter.svg'
import { ReactComponent as ReorderIcon } from './reorder_new.svg'
import { ReactComponent as ResourcesIcon } from './resources.svg'
import { ReactComponent as RestaurantIcon } from './restaurant.svg'
import { ReactComponent as RoofTerraceIcon } from './roof_terrace.svg'
import { ReactComponent as RoundCheckIcon } from './round_check.svg'
import { ReactComponent as PendingIcon } from './pending.svg'
import { ReactComponent as RoommateIcon } from './roommate.svg'
import { ReactComponent as RVParkingIcon } from './rv_parking.svg'
import { ReactComponent as SalaryRange } from './salary.svg'
import { ReactComponent as SaunaSteamRoomIcon } from './sauna_steam_room.svg'
import { ReactComponent as SaunaIcon } from './sauna.svg'
import { ReactComponent as SearchIcon } from './search.svg'
import { ReactComponent as SecureIcon } from './secure.svg'
import { ReactComponent as SecuredUndergroundParkingIcon } from './secured_underground_parking.svg'
import { ReactComponent as SecuritySystemIcon } from './security_system.svg'
import { ReactComponent as SecurityIcon } from './security.svg'
import { ReactComponent as SettingsIcon } from './settings.svg'
import { ReactComponent as SharedLaundryIcon } from './shared_laundry.svg'
import { ReactComponent as ShowingsIcon } from './showings.svg'
import { ReactComponent as SmallCheckIcon } from './small_check.svg'
import { ReactComponent as SmokingAreaIcon } from './smoking_area.svg'
import { ReactComponent as SnoozeIcon } from './snooze.svg'
import { ReactComponent as SnowRemovalIcon } from './snow_removal.svg'
import { ReactComponent as SewageDisposalIcon } from './sewage_disposal.svg'
import { ReactComponent as SquareCheckIcon } from './square_check.svg'
import { ReactComponent as SquashCourtIcon } from './squash_court.svg'
import { ReactComponent as SteamRoomIcon } from './steam_room.svg'
import { ReactComponent as StorageIcon } from './storage.svg'
import { ReactComponent as StorageAreaIcon } from './storage_area.svg'
import { ReactComponent as StorageAreaNOIcon } from './storage_areaNO.svg'
import { ReactComponent as SupportIcon } from './support.svg'
import { ReactComponent as SwitchMenu } from './switch_menu.svg'
import { ReactComponent as TableTennisRoomIcon } from './table_tennis_room.svg'
import { ReactComponent as TelephoneIcon } from './telephone.svg'
import { ReactComponent as TennisCourtIcon } from './tennis_court.svg'
import { ReactComponent as TownhouseWhiteIcon } from './townhouse_white.svg'
import { ReactComponent as TownhouseIcon } from './townhouse.svg'
import { ReactComponent as TvIcon } from './tv_unit.svg'
import { ReactComponent as UnfurnishedIcon } from './unfurnished.svg'
import { ReactComponent as UnitEntireWhiteIcon } from './unit_entire_white.svg'
import { ReactComponent as UnitEntireIcon } from './unit_entire.svg'
import { ReactComponent as UnitPartialWhiteIcon } from './unit_partial_white.svg'
import { ReactComponent as UnitPartialIcon } from './unit_partial.svg'
import { ReactComponent as UnitRoomWhiteIcon } from './unit_room_white.svg'
import { ReactComponent as UnitRoomIcon } from './unit_room.svg'
import { ReactComponent as VaultedCeilingsIcon } from './vaulted_ceilings.svg'
import { ReactComponent as VerifiedId } from './verified_id_badge.svg'
import { ReactComponent as VerifiedListingIcon } from './verified-listing-badge.svg'
import { ReactComponent as VisitorsParking } from './visitors_parking.svg'
import { ReactComponent as WallClimbingIcon } from './wall_climbing.svg'
import { ReactComponent as WarningIcon } from './warning_icon.svg'
import { ReactComponent as WasherIcon } from './washer.svg'
import { ReactComponent as WasteDisposalChuteIcon } from './waste_disposal_chute.svg'
import { ReactComponent as WheelchairIcon } from './wheelchair_accessible.svg'
import { ReactComponent as WindowCovering } from './window_covering.svg'
import { ReactComponent as WorkCase } from './work_case.svg'
import { ReactComponent as WorkshopIcon } from './workshop.svg'
import { ReactComponent as YogaRoomIcon } from './yoga_room.svg'

// LISTING SUMMARY
import { ReactComponent as BathDarkIcon } from './bath_dark.svg'
import { ReactComponent as BedroomIcon } from './bedroom.svg'
import { ReactComponent as EntranceIcon } from './entrance.svg'
import { ReactComponent as FurnishedDarkIcon } from './furnished_dark.svg'
import { ReactComponent as SizeIcon } from './size.svg'

// TRANSIT
import { ReactComponent as BusIcon } from './bus_dark.svg'
import { ReactComponent as FerryIcon } from './ferry_dark.svg'
import { ReactComponent as SubwayIcon } from './subway_dark.svg'
import { ReactComponent as TrainIcon } from './train_dark.svg'
import { ReactComponent as WalkIcon } from './walk_dark.svg'

// UTILITIES
import { ReactComponent as CableIcon } from './cable.svg'
import { ReactComponent as ElectricityIcon } from './electricity.svg'
import { ReactComponent as GasIcon } from './gas.svg'
import { ReactComponent as HeatIcon } from './heat.svg'
import { ReactComponent as InternetIcon } from './internet.svg'
import { ReactComponent as NoCableIcon } from './cable_no.svg'
import { ReactComponent as NoElectricityIcon } from './electricity_no.svg'
import { ReactComponent as NoGasIcon } from './gas_no.svg'
import { ReactComponent as NoInternetIcon } from './internet_no.svg'
import { ReactComponent as NoWaterIcon } from './water_no.svg'
import { ReactComponent as WaterIcon } from './water.svg'

// MENU
import { ReactComponent as MenuChatIcon } from './menu-chat.svg'
import { ReactComponent as MenuListingsIcon } from './menu-listings.svg'
import { ReactComponent as MenuMatchesIcon } from './menu-matches.svg'
import { ReactComponent as MenuProfileIcon } from './menu-profile.svg'
import { ReactComponent as MenuRentalsIcon } from './menu-rentals.svg'
import { ReactComponent as MenuShowingsIcon } from './menu-showings.svg'
import { ReactComponent as MenuTenantsIcon } from './menu-tenants.svg'

const icons = {
  age_restricted: BanIcon,
  age_restriction_19_plus: BanIcon,
  age_restriction_40_plus: BanIcon,
  age_restriction_45_plus: BanIcon,
  age_restriction_50_plus: BanIcon,
  age_restriction_55_plus: BanIcon,
  air_conditioning: AirConditioningIcon,
  addendum: Addendum,
  application: ApplicationIcon,
  arrow: Arrow,
  back_arrow: BackArrow,
  assisted_living: AssistedLivingIcon,
  attachment: AttachmentIcon,
  bathtub: BathIcon,
  bathroom: BathIcon,
  badminton: BadmintonIcon,
  balcony: BalconyIcon,
  basketball_court: BasketballCourtIcon,
  bike_storage: BikeStorageIcon,
  billiards_room: BilliardsRoom,
  birthday: BirthdayIcon,
  bbq: BbqIcon,
  bowling_ally: BowlingAlleyIcon,
  building_white: BuildingWhiteIcon,
  building: BuildingIcon,
  builtin_vacuum: BuiltinVacuumIcon,
  call: CallIcon,
  camera: CameraIcon,
  camera_blue: CameraBlueIcon,
  carpets: CarpetsIcon,
  car_charging: CarChargingIcon,
  car_valet: CarValetIcon,
  caretaker: CaretakerIcon,
  carshare: CarshareIcon,
  cats_allowed: CheckIcon,
  cats_not_allowed: BanIcon,
  edit: EditIcon,
  change_room: ChangeRoomIcon,
  chat: ChatIcon,
  check: CheckIcon,
  checked_green: CheckedGreen,
  close_button_dark: CloseButtonDarkIcon,
  close_button: CloseButtonIcon,
  close: CloseIcon,
  clubhouse: ClubhouseIcon,
  coin_laundry: CoinLaundryIcon,
  concierge: ConcieregeIcon,
  confetti: ConfettiIcon,
  contract: ContractIcon,
  courtyard: CourtyardIcon,
  daycare: DaycareIcon,
  delete: DeleteIcon,
  markup_marker: MarkupMarker,
  markup_eraser: MarkupEraser,
  dog_minding: DogMindingIcon,
  dogs_allowed: CheckIcon,
  dogs_not_allowed: BanIcon,
  doorman: DoormanIcon,
  down_arrow_dark: DownArrowDarkIcon,
  down_arrow_white: DownArrowWhiteIcon,
  disable_ready: WheelchairIcon,
  dishwasher: DishwasherIcon,
  dryer: DryerIcon,
  elevator: ElevatorIcon,
  error: ErrorIcon,
  exercise_centre: ExerciseCentreIcon,
  family_room: FamilyRoomIcon,
  file_up: FileUpIcon,
  fireplace: FireplaceIcon,
  fridge: FridgeIcon,
  furnished: FurnishedIcon,
  games_room: GamesRoomIcon,
  garbage_chute: GarbageIcon,
  garbage_pickup: GarbageIcon,
  garbage_valet: GarbageValetIcon,
  gated_complex: GatedComplexIcon,
  garburator: GarburatorIcon,
  garden: GardenIcon,
  gourmet_kitchens: GourmetKitchensIcon,
  guest_suite: GuestSuiteIcon,
  gym: GymIcon,
  hidden: HiddenIcon,
  high_ceilings: HighCeilingsIcon,
  hot_tub: HotTubIcon,
  house_white: HouseWhiteIcon,
  house: HouseIcon,
  indoor_basketball_court: IndoorBasketballCourtIcon,
  indoor_pool: IndoorPoolIcon,
  info_blue: InfoBlueIcon,
  info_circle: InfoCircleIcon,
  insuite_laundry: InsuiteLaundryIcon,
  insuite_storage: InsuiteStorage,
  landlord: LandlordIcon,
  jacuzzi: HotTubIcon,
  language: LanguageIcon,
  laundry: LaundryIcon,
  laundryNO: LaundryNOIcon,
  library: LibraryIcon,
  listing_views: ListingViewsIcon,
  listing_active: ListingActiveIcon,
  liv_logo: LivLogoIcon,
  lounge: LoungeIcon,
  mail_room: MailRoomIcon,
  massage_spa_room: MasssageSpaRoomIcon,
  max_1_pet_allowed: CheckIcon,
  max_2_pets_allowed: CheckIcon,
  media_room: MediaRoomIcon,
  meeting_room: MeetingRoomIcon,
  microwave: MicrowaveIcon,
  money: MoneyIcon,
  note: NoteIcon,
  no_of_pets_2: CheckIcon,
  no_rentals_allowed: BanIcon,
  outdoor_basketball_court: OutdoorBasketballCourtIcon,
  outdoor_pool: OutdoorPoolIcon,
  parking: ParkingIcon,
  parkingNO: ParkingNOIcon,
  password_visible: PasswordVisible,
  password_masked: PasswordMasked,
  patio_deck_terrace: PatioDeckTerraceIcon,
  payments: PaymentsIcon,
  petsNotAllowed: PetsNotAllowedIcon,
  pets_not_allowed: PetsNotAllowedIcon,
  petsAllowed: PetsIcon,
  pets_allowed: PetsIcon,
  placeholder: PlaceholderIcon,
  plus: PlusIcon,
  plus_white: PlusWhiteIcon,
  plus_small: PlusSmallIcon,
  play: PlayIcon,
  play_video: PlayVideoIcon,
  playground: PlaygroundIcon,
  pool: PoolIcon,
  private_yard: PrivateYardIcon,
  profession: ProfessionIcon,
  processing: ProcessingIcon,
  profile: ProfileIcon,
  recreation_centre: RecreationCentreIcon,
  recreation_room: RecreationRoomIcon,
  rectangle: RectangleIcon,
  rectangle_filled: RectangleFilledIcon,
  renter: RenterIcon,
  rentals_allowed: CheckIcon,
  rentals_allowed_with_restrictions: CheckIcon,
  reorder: ReorderIcon,
  resources: ResourcesIcon,
  restaurant: RestaurantIcon,
  roommate: RoommateIcon,
  rooftop: RoofTerraceIcon,
  round_check: RoundCheckIcon,
  pending: PendingIcon,
  rv_parking: RVParkingIcon,
  salary_range: SalaryRange,
  sauna_steam_room: SaunaSteamRoomIcon,
  sauna: SaunaIcon,
  search: SearchIcon,
  secure: SecureIcon,
  secured_underground_parking: SecuredUndergroundParkingIcon,
  security_system: SecuritySystemIcon,
  security: SecurityIcon,
  settings: SettingsIcon,
  shared_laundry: SharedLaundryIcon,
  showings: ShowingsIcon,
  small_check: SmallCheckIcon,
  smoking_area: SmokingAreaIcon,
  snooze: SnoozeIcon,
  snow_removal: SnowRemovalIcon,
  sewage_disposal: SewageDisposalIcon,
  square_check: SquareCheckIcon,
  squash_court: SquashCourtIcon,
  steam_room: SteamRoomIcon,
  storage_area: StorageAreaIcon,
  storage_areaNO: StorageAreaNOIcon,
  storage: StorageIcon,
  support: SupportIcon,
  switch_menu: SwitchMenu,
  table_tennis_room: TableTennisRoomIcon,
  telephone: TelephoneIcon,
  tennis_court: TennisCourtIcon,
  townhouse_white: TownhouseWhiteIcon,
  townhouse: TownhouseIcon,
  tv_unit: TvIcon,
  unfurnished: UnfurnishedIcon,
  unit_entire_white: UnitEntireWhiteIcon,
  unit_entire: UnitEntireIcon,
  unit_partial_white: UnitPartialWhiteIcon,
  unit_partial: UnitPartialIcon,
  unit_room_white: UnitRoomWhiteIcon,
  unit_room: UnitRoomIcon,
  vaulted_ceilings: VaultedCeilingsIcon,
  verified_id: VerifiedId,
  verified_listing: VerifiedListingIcon,
  visitors_parking: VisitorsParking,
  wall_climbing: WallClimbingIcon,
  warning: WarningIcon,
  washer: WasherIcon,
  waste_disposal_chite: WasteDisposalChuteIcon,
  wheelchair_accessible: WheelchairIcon,
  windows_covering: WindowCovering,
  work_case: WorkCase,
  workshop: WorkshopIcon,
  yoga_room: YogaRoomIcon,

  // LISTING SUMMARY
  bathtub_dark: BathDarkIcon,
  bedroom: BedroomIcon,
  entrance: EntranceIcon,
  furnished_dark: FurnishedDarkIcon,
  size: SizeIcon,

  // TRANSIT
  bus: BusIcon,
  boat: FerryIcon,
  subway: SubwayIcon,
  railway: TrainIcon,
  walk: WalkIcon,

  // UTILITIES
  cable: CableIcon,
  default: CheckIcon,
  electricity: ElectricityIcon,
  gas: GasIcon,
  heat: HeatIcon,
  internet: InternetIcon,
  noCable: NoCableIcon,
  noElectricity: NoElectricityIcon,
  noGas: NoGasIcon,
  noInternet: NoInternetIcon,
  noWater: NoWaterIcon,
  water: WaterIcon,

  // MENU
  menu_chat: MenuChatIcon,
  menu_listings: MenuListingsIcon,
  menu_matches: MenuMatchesIcon,
  menu_profile: MenuProfileIcon,
  menu_rentals: MenuRentalsIcon,
  menu_showings: MenuShowingsIcon,
  menu_tenants: MenuTenantsIcon
}

export default icons
