import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from 'components/icon'
import { colors, transitions } from 'styles'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import FieldInput from './field-input'

const Container = styled.div`
  position: relative;
  ${props =>
    props.hasIcon &&
    `
    > div {
      > input {
        width: calc(100% - 27px);
        padding-right: 3px !important;
      }
    }
  `}
`

const Button = styled.button`
  position: absolute;
  background: none;
  top: 7px;
  right: 0;
  background: none;
  height: 43px;
  line-height: 1;
  padding: 7px 1px 9px;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid ${colors.mediumGrey};
  transition: border-color ${transitions.ease};
  ${props =>
    props.focused &&
    `
    border-color: ${colors.darkBlue};
  `}
  ${props =>
    props.hasError &&
    `
    border-color: ${colors.red};
  `}
`
const MaskedIcon = props => <Icon id="password_masked" {...props} />

const VisibleIcon = props => <Icon id="password_visible" {...props} />

const ToggleVisible = ({ visible, width = 25, height = 25, show, ...rest }) => {
  const { t } = useTranslation()
  const InnerIcon = visible ? MaskedIcon : VisibleIcon
  if (!show) return null
  return (
    <Button
      {...rest}
      type="button"
      aria-pressed={visible}
      aria-label={t(
        'b.components.password_field.toggle.label',
        'Show password'
      )}
    >
      <InnerIcon width={width} height={height} />
    </Button>
  )
}

const PasswordInputBase = ({
  setVisible,
  visible,
  name,
  hideError,
  ...rest
}) => {
  const [, meta] = useField(name)
  const formik = useFormikContext()
  const [focused, setFocused] = useState()
  const hasError = !!meta.error && meta.touched && !hideError
  const onClick = () => setVisible(value => !value)
  return (
    <Container hasIcon={!!setVisible}>
      <FieldInput
        {...rest}
        name={name}
        type={visible ? 'text' : 'password'}
        hideError={hideError}
        onBlur={() => {
          const touched = { ...formik.touched }
          touched[name] = true
          formik.setTouched(touched)
          setFocused(false)
        }}
        onFocus={() => setFocused(true)}
      />
      <ToggleVisible
        visible={visible}
        onClick={onClick}
        show={!!setVisible}
        focused={focused}
        hasError={hasError}
      />
    </Container>
  )
}

const PasswordInputWithState = props => {
  const [visible, setVisible] = useState(false)
  return (
    <PasswordInputBase {...props} visible={visible} setVisible={setVisible} />
  )
}

const PasswordInput = props => {
  if (props.hasOwnProperty('visible')) return <PasswordInputBase {...props} />
  return <PasswordInputWithState {...props} />
}

export default PasswordInput
