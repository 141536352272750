import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors } from 'styles'
import { toUnit } from 'utils/styles'
import Breadcrumbs from 'components/breadcrumbs'
import { EditButton, ShareButton } from 'features/Listing/buttons'

const Wrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  z-index: 100;

  ${props =>
    props.showBorder &&
    `
    border-bottom: 1px solid ${colors.grey};
  `}
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${props => toUnit(props.height) || '75px'};
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  padding: 0 50px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 0 20px;
  }
`

const Group = styled.div`
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 12px;
  }
`

const DashboardHeader = ({
  listingId,
  isPublished,
  showButtons,
  border,
  currentPage,
  height
}) => {
  const { t } = useTranslation()

  const breadcrumbs = [
    {
      to: '/listings',
      label: t('l.listing.view.all_listings.link', 'All Listings')
    },
    {
      to: `/listing/${listingId}`,
      label: t('l.listing.view.dashboard.link', 'Dashboard')
    },
    { to: null, label: currentPage }
  ]

  return (
    <Wrapper showBorder={border}>
      <Content height={height}>
        <Group>
          <Breadcrumbs items={breadcrumbs} />
        </Group>
        {showButtons && (
          <Group>
            <EditButton listingId={listingId} />
            {isPublished && <ShareButton listingId={listingId} />}
          </Group>
        )}
      </Content>
    </Wrapper>
  )
}

export default DashboardHeader
