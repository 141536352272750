const error = {
  state: {
    type: 'notification',
    message: null,
    redirectTo: null
  },
  reducers: {
    setError: (state, { type = 'notification', ...rest }) => ({
      type,
      ...rest
    }),
    clearError: () => ({
      type: 'notification',
      message: null,
      redirectTo: null
    })
  }
}

export default error
