import React, { Suspense } from 'react'
import styled from 'styled-components'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { getStore } from 'store'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { noop } from 'lodash'
import { breakpoints, colors, zIndex } from 'styles'
import { ModalizeProvider } from './context'

const BackgroundWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: ${zIndex.modal};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.overlay &&
    `
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    );
  `}

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-height: 100vh;
  }
`

const ContentWrapper = styled.div`
  max-width: ${props => props.maxWidth};
  width: ${props => props.width};
  ${props =>
    props.borderRadius &&
    `
    border-radius: ${props.borderRadius};
    clip-path: inset(0 0 0 0 round ${props.borderRadius});
  `}
  max-height: 100%;
  position: relative;
  overflow: hidden;

  ${props =>
    props.isFullscreen &&
    `
    max-width: unset;
    width: 100%;
    border-radius: unset;
  `}

  ${props =>
    props.showBackground &&
    `
  background-color: ${colors.white};
  `}

${props =>
  props.allowOverflow &&
  `
    overflow: visible;
    clip-path: unset;
  `}

  @media screen and (max-height: 885px) {
    overflow: ${props => (props.allowOverflow ? 'visible' : 'auto')};
  }
`

const createModal = (
  Component,
  {
    overlay = true,
    maxWidth = '95%',
    width = '400px',
    showBackground = true,
    borderRadius = '6px',
    allowOverflow = false,
    clickOutside = true,
    isFullscreen,
    unmountDelay = 100,
    mountingNode = document.body
  } = {}
) => {
  return props => {
    const wrapper = mountingNode.appendChild(document.createElement('div'))

    const store = getStore()
    try {
      ReactDOM.render(
        <Suspense fallback={null}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <ModalizeProvider value={{ dispose }}>
                <BackgroundWrapper
                  id="modal-container"
                  overlay={overlay}
                  onClick={clickOutside ? dispose : noop}
                >
                  <ContentWrapper
                    onClick={e => e.stopPropagation()}
                    maxWidth={maxWidth}
                    width={width}
                    isFullscreen={isFullscreen}
                    showBackground={showBackground}
                    borderRadius={borderRadius}
                    allowOverflow={allowOverflow}
                  >
                    <Component {...props} />
                  </ContentWrapper>
                </BackgroundWrapper>
              </ModalizeProvider>
            </MuiPickersUtilsProvider>
          </Provider>
        </Suspense>,
        wrapper
      )
    } catch (e) {
      console.error(e)
      throw e
    }

    function dispose() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper)
      }, unmountDelay)
    }
  }
}

export default createModal
