import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { fontWeights, colors } from 'styles'
import { Input } from 'components/form'
import moment from 'moment'

const AgreeWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: ${fontWeights.medium};
  margin: 40px 0 10px;
`

const AgreeText = styled.p`
  flex: 9999;
  display: inline-block;
  margin: 0;
`

const DateSignedText = styled(AgreeText)`
  color: ${colors.link};
`

const AddendumAgreement = ({ addendum, onChange }) => {
  const dateSigned = moment().format('MMMM DD, YYYY')

  return (
    <AgreeWrapper>
      <Input
        id={`addendum-${addendum.id}`}
        type="checkbox"
        checked={addendum.complete}
        onChange={() => onChange(addendum)}
      />
      <AgreeText htmlFor={`addendum-${addendum.id}`}>
        <Trans
          i18nKey="r.contract.view.agree_with_addendum_terms.text"
          dateSigned={dateSigned}
        >
          This addendum has been agreed to on{' '}
          <DateSignedText>{{ dateSigned }}</DateSignedText>.
        </Trans>
      </AgreeText>
    </AgreeWrapper>
  )
}

export default AddendumAgreement
