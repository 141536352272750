import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Switch, NavLink, Route } from 'react-router-dom'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { Formik, Form } from 'formik'
import { isEmpty } from 'ramda'
import { colors, breakpoints, fontSizes, fontWeights, structure } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import { toUnit } from 'utils/styles'
import PageTitle from 'components/page-title'
import { Autosave } from 'components/form'
import { toast } from 'components/toast-notifications'
import { leftCloud, rightCloud, rightCloudSmall } from 'components/clouds'

import AccountTab from './account-tab'
import NotificationsTab from './notifications-tab'
import PreferencesTab from './preferences-tab'

import backgroundBottom from './images/background-bottom.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 50px 150px 100px;
  background: ${colors.white};
  min-height: calc(100vh - ${toUnit(structure.header.height)});

  background-image: url(${leftCloud}), url(${rightCloud}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -43px 63px, 98% 55px, 100% 92%;
  background-size: 168px, 230px, 400px;

  section:not(:last-child) {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid ${colors.grey};
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 50px 40px 150px;

    background-image: url(${leftCloud}), url(${rightCloudSmall}),
      url(${backgroundBottom});
    background-position: -70px 65px, 100% 70px, 100% 97%;
    background-size: 150px, 100px, 250px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 20px 25px 130px;
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});

    background-image: url(${rightCloudSmall}), url(${backgroundBottom});
    background-position: 100% 50px, 100% 96%;
    background-size: 100px, 170px;

    section:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid ${colors.grey};
    }
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 20px 15px 130px;
  }
`

const SettingsMenu = styled.nav`
  max-width: 660px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.grey};
  margin-bottom: 50px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 30px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: space-between;
  }
`

const activeClassName = 'nav-item-active'

const MenuItem = styled(NavLink)`
  height: 30px;
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0px;
  line-height: 25px;
  text-align: center;
  border: none;
  background: none;
  border-bottom: 3px solid transparent;

  :not(:last-child) {
    margin-right: 50px;
  }

  &.${activeClassName} {
    color: ${colors.secondary};
    border-bottom: 3px solid ${colors.secondary};
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    :not(:last-child) {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    :not(:last-child) {
      margin-right: 15px;
    }
  }
`

const EditSettings = () => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.languages[0]
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const dispatch = useDispatch()
  const settings = useSelector(select.profile.settings)

  const menuItems = [
    {
      link: `/settings`,
      text: t('b.settings.view.account_settings.link', 'Account Settings'),
      mobileText: t('b.settings.view.account.link', 'Account'),
      exact: true
    },
    {
      link: `/settings/preferences`,
      text: t('b.settings.view.preferences.link', 'Preferences'),
      exact: true
    },
    {
      link: `/settings/notifications`,
      text: t('b.settings.view.notifications.link', 'Notifications'),
      exact: true
    }
  ]

  const onSubmit = useCallback(
    async values => {
      const response = await dispatch.profile.updateSettings(values)
      if (!response?.ok) {
        toast(
          t(
            'b.settings.view.settings.failed_save',
            'Your settings could not be saved.'
          ),
          {
            title: t('common.error', 'Error'),
            iconId: 'error',
            autoClose: 6000
          }
        )
      }
    },
    [dispatch.profile, t]
  )

  if (isEmpty(settings)) return null

  return (
    <>
      <Helmet>
        <title>{t('b.settings.view.settings.title', 'Settings')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{t('b.settings.view.settings.title', 'Settings')}</PageTitle>
      <Wrapper>
        <SettingsMenu>
          {menuItems.map(item => {
            return (
              <MenuItem
                key={item.link}
                to={item.link}
                activeClassName={activeClassName}
                exact={item.exact}
                disabled={item.disabled}
              >
                {displayMobile ? item.mobileText || item.text : item.text}
              </MenuItem>
            )
          })}
        </SettingsMenu>
        <Formik
          initialValues={{ ...settings, setting_language: currentLanguage }}
          onSubmit={onSubmit}
        >
          {({ values }) => (
            <Autosave values={values} onSave={onSubmit}>
              <Form>
                <Switch>
                  <Route exact path="/settings" component={AccountTab} />
                  <Route
                    exact
                    path="/settings/preferences"
                    component={PreferencesTab}
                  />
                  <Route
                    exact
                    path="/settings/notifications"
                    component={NotificationsTab}
                  />
                </Switch>
              </Form>
            </Autosave>
          )}
        </Formik>
      </Wrapper>
    </>
  )
}

export default EditSettings
