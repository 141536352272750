import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { path, isEmpty, mergeRight } from 'ramda'
import * as Yup from 'yup'
import useProfile from 'hooks/use-profile'
import 'utils/validation'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import ContentWrapper from 'components/content-wrapper'
import PaymentMethodForm from './payment-method-form'
import BillingAddressForm from './billing-address-form'
import Context from '../context'

const PaymentMethod = ({ setCurrentStep, handleBack }) => {
  const { t } = useTranslation()
  const { formData, setFormData } = useContext(Context)
  const dispatch = useDispatch()
  const userProfile = useProfile()
  const hasAddress = userProfile.has_billing_address
  const addressData = useSelector(path(['settlements', 'billing_address']))

  const [cardInfoValid, setCardInfoValid] = useState(false)
  const [cardMethodValid, setCardMethodValid] = useState(false)

  useEffect(() => {
    if (hasAddress && !addressData) dispatch.settlements.getBillingAddress()
  }, [hasAddress, addressData, dispatch.settlements])
  const initialValues = isEmpty(formData)
    ? {
        promo_code: '',
        payment_method: '',
        first_name: '',
        last_name: '',
        card_number: '',
        expiration_date: '',
        security_code: '',

        street1: addressData?.street1 || '',
        street2: addressData?.street2 || '',
        city: addressData?.city || '',
        state: addressData?.state || '',
        country: addressData?.country || 'ca',
        zip: addressData?.zip || '',
        save_billing_address: false
      }
    : { ...formData }

  const validationSchema = Yup.object().shape({
    promo_code: Yup.string(),
    payment_method: Yup.string().required(),
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    expiration_date: Yup.string().length(4).required(),
    security_code: Yup.string().min(3).max(4).required(),
    card_number: Yup.string().required(),

    street1: Yup.string().when('payment_method', {
      is: 'stripe',
      then: Yup.string().required()
    }),
    street2: Yup.string(),
    city: Yup.string().when('payment_method', {
      is: 'stripe',
      then: Yup.string().required()
    }),
    country: Yup.string().when('payment_method', {
      is: 'stripe',
      then: Yup.string().required()
    }),
    state: Yup.string().when('payment_method', {
      is: 'stripe',
      then: Yup.string().required()
    }),
    zip: Yup.string().when('payment_method', {
      is: 'stripe',
      then: Yup.string().required()
    }),
    save_billing_address: Yup.bool().when('payment_method', {
      is: 'stripe',
      then: Yup.bool().required()
    })
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setFormData(mergeRight(formData, values))
    setSubmitting(false)
    setCurrentStep('details')
    window.scrollTo(0, 0)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setFieldValue, isValid }) => (
        <Form data-testid="payment-method-form" className="flex-full">
          <ContentWrapper className="mb-50">
            <PaymentMethodForm
              values={values}
              setCardInfoValid={setCardInfoValid}
              setCardMethodValid={setCardMethodValid}
              setFieldValue={setFieldValue}
            />
            {values.payment_method === 'stripe' && (
              <BillingAddressForm
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </ContentWrapper>
          <ButtonBar sticky>
            <Button theme="sane" width={150} inversed onClick={handleBack}>
              {t('r.payment.edit.make_payment_back.button', 'Back')}
            </Button>
            <Button
              theme="primary"
              width={150}
              type="submit"
              disabled={!cardInfoValid || !cardMethodValid || !isValid}
            >
              {t('r.payment.edit.make_payment_next.button', 'Next')}
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  )
}

export default PaymentMethod
