import styled from 'styled-components'

const UnstyledButton = styled.button`
  background: none;
  border: none;
  text-align: initial;
  outline: none;
  cursor: pointer;
`

export default UnstyledButton
