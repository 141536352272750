import React from 'react'
import { useTranslation } from 'react-i18next'
import useRole from 'hooks/use-role'
import equifaxUnknown from './images/Credit_Score_Unknown.png'
import CardTemplateWhite from './card-template-white'

const UnknownCredit = props => {
  const { t } = useTranslation()

  const userRole = useRole()
  const creditDescription =
    userRole === 'tenant'
      ? t(
          'r.liv_score_card.view.unknown_credit_description.text',
          'More information required to calculate the Liv Score. Please complete your renter profile to obtain a Liv Score.'
        )
      : t(
          'l.liv_score_card.view.unknown_credit_description.text',
          'More information required to calculate the Liv Score. Please encourage the applicant to completely fill out their profile.'
        )

  return (
    userRole !== 'tenant' && (
      <CardTemplateWhite
        title={props.title}
        creditDescription={creditDescription}
        creditRatingResult={t(
          'b.liv_score_card.view.no_available_profile.text',
          'No Available Profile'
        )}
        cardImage={equifaxUnknown}
        avatarUrl={props.avatarUrl}
        data-testid={props['data-testid']}
      />
    )
  )
}

UnknownCredit.defaultProps = {
  userRole: 'tenant',
  title: ''
}

export default UnknownCredit
