import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import * as Yup from 'yup'
import { createModal, useModalize } from 'components/modalize'
import { fontSizes, fontWeights, colors } from 'styles'
import { Formik, Form } from 'formik'
import { show as showCannotUndoModal } from 'features/EndTenancy/cannot-undo-modal'
import { confirm } from 'components/dialog'
import { Label, FieldDropdown, FieldTextarea } from 'components/form'
import Icon from 'components/icon'
import { Button } from 'components/button'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

const ContentWrapper = styled.div`
  padding: 0px 20px 30px;
  overflow-y: auto;
  max-height: 70vh;
`

const CloseButton = styled(Icon)`
  position: absolute;
  top: 25px;
  right: 25px;
  margin: 0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h2`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  color: ${colors.pink};
  padding: 25px 0px;
`

const DropDownContainer = styled.div`
  margin: 10px 0 30px;
  align-items: center;
`

const SubmitButton = styled(Button)`
  display: block;
  padding: 10px 20px;
  margin: 25px auto 0px auto;
  border-radius: 50px;
`

const ForceEndTenancyModal = ({
  listingId,
  endTenancyId,
  savedValues = {}
}) => {
  const { close } = useModalize()
  const [t] = useTranslation()

  const handleClose = values => {
    return values.reason || values.message
      ? confirm(
          t(
            'l.rental_dashboard.edit.resources_leave_force_end_tenancy_confirmation.title',
            'Warning'
          ),
          t(
            'l.rental_dashboard.edit.resources_leave_force_end_tenancy_confirmation.text',
            'Leaving this form now will lose all your entered information. \nAre you sure you want to leave?\n'
          )
        ).then(async () => await close())
      : close()
  }

  const formOptions = () => ({
    initialValues: {
      reason: savedValues.reason || '',
      message: savedValues.message || ''
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required(),
      message: Yup.string().required()
    }),
    onSubmit: values => {
      return (
        showCannotUndoModal({
          listingId: listingId,
          endTenancyId: endTenancyId,
          values
        }),
        close()
      )
    }
  })

  return (
    <Wrapper>
      <Formik {...formOptions()} padding="15px">
        {({ values }) => (
          <Form>
            <CloseButton
              id="close"
              width={24}
              height={24}
              onClick={() => handleClose(values)}
            />
            <ContentWrapper>
              <Title>
                {t(
                  'r.rental_dashboard.edit.resources_force_end_tenancy_modal.title',
                  'Force End Tenancy'
                )}
              </Title>
              <DropDownContainer>
                <FieldDropdown
                  name="reason"
                  placeholder={
                    t(
                      'r.rental_dashboard.edit.resources_force_end_tenancy_reason.label',
                      'Select your reason'
                    ) + '*:'
                  }
                  options={[
                    {
                      value: 'tenant_cant_be_reached',
                      label: t(
                        'l.force_end_tenancy.tenant_cant_be_reached.text',
                        'Tenant cannot be reached.'
                      )
                    },
                    {
                      value: 'eviction_notice_served',
                      label: t(
                        'l.force_end_tenancy.eviction_notice_served.text',
                        'Eviction Notice has been served'
                      )
                    },
                    {
                      value: 'other',
                      label: t('l.force_end_tenancy.other.text', 'Other')
                    }
                  ]}
                />
              </DropDownContainer>
              <Label>
                {t(
                  'r.rental_dashboard.edit.resources_force_end_tenancy_reason_message.label',
                  'Please outline what you have done to resolve this issue?*'
                )}
              </Label>
              <FieldTextarea
                name="message"
                maxLength={230}
                height={185}
                placeholder={t(
                  'r.rental_dashboard.edit.resources_force_end_tenancy_modal_reason_textarea.placeholder',
                  'Please state what you have done here.'
                )}
              />
              <SubmitButton theme="primary" type="submit">
                {t(
                  'r.rental_dashboard.edit.resources_force_end_tenancy_continue.button',
                  'Continue'
                )}
              </SubmitButton>
            </ContentWrapper>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export const show = createModal(ForceEndTenancyModal)
