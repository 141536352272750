import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import PageTitle from 'components/page-title'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import useMediaQuery from 'hooks/use-media-query'
import { showUserPicker } from 'components/user-picker/'
import { PanelHeader, PanelTitle } from 'features/Profile/elements'
import { breakpoints } from 'styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { pickAll, evolve, pipe } from 'ramda'
import { defaultIfFalsy } from 'utils'
import Page from 'components/page'
import Panel from 'components/panel'
import {
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  PhoneInput
} from 'components/form'
import ContentWrapper from 'components/content-wrapper'

const StyledPanel = styled(Panel)`
  margin-top: 30px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 20px 10px 0;
  }
`

const StyledPanelTitle = styled(PanelTitle)`
  margin-left: auto;
`

const LeftButtonsContainer = styled.div`
  > :first-child {
    margin-right: 10px;
  }
`

const PhoneRow = styled(Row)`
  align-items: flex-start;
  > div:first-of-type {
    padding-top: 6px;
  }
`

const UserEdit = ({ history, match }) => {
  const [t] = useTranslation()

  const dispatchCompanyManagement = useDispatch().companyManagement
  const createUser = dispatchCompanyManagement.createUser
  const updateUser = dispatchCompanyManagement.updateUser
  const deleteTargetUser = dispatchCompanyManagement.deleteTargetUser

  const userId = match?.params?.userId
  const users = useSelector(state => state?.companyManagement?.users)
  const user = users?.[userId]

  const allLandlords = useSelector(select.companyManagement.users)

  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  if (!user && userId) return null

  const handleBack = () =>
    history.replace(userId ? `/manage/users/${userId}` : `/manage/users/`)

  const handleReassign = async selectedUser => {
    const response = await deleteTargetUser({
      oldUserId: userId,
      newUserId: selectedUser[0]?.id
    })

    if (response?.response?.ok) {
      history.replace(`/manage/users/`)
    }
  }

  const getPageTitle = () => {
    if (userId && user) {
      const nameEndsWithS = user.first_name.match(/.*(s)$/i)
      return `${user.first_name}${nameEndsWithS ? `'` : `'s`} ${t(
        'l.company_management.edit.profile.title',
        'Profile'
      )}`
    }
    return t(
      'l.company_management.edit.create_new_user.title',
      'Create New User'
    )
  }

  const initialValues = pipe(
    pickAll([
      'first_name',
      'last_name',
      'preferred_name',
      'phone_number',
      'username',
      'user_role'
    ]),
    evolve({
      first_name: defaultIfFalsy(''),
      last_name: defaultIfFalsy(''),
      preferred_name: defaultIfFalsy(''),
      phone_number: defaultIfFalsy(''),
      username: defaultIfFalsy(''),
      user_role: defaultIfFalsy('')
    })
  )(user || {})

  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      last_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      preferred_name: Yup.string().matches(
        /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
        t(
          'b.profile.name.special_characters.error',
          'Cannot include special characters'
        )
      ),
      phone_number: Yup.string().phone().required(),
      username: Yup.string().email().trim().required(),
      user_role: Yup.string().required()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const action = userId ? updateUser : createUser
      const response = await action({ userId, values })
      setSubmitting(false)
      if (response?.response?.ok) {
        handleBack()
      }
    }
  })

  return (
    <Page>
      <PageTitle>{getPageTitle()}</PageTitle>
      <PanelHeader>
        <StyledPanelTitle>{getPageTitle()}</StyledPanelTitle>
      </PanelHeader>
      <Formik {...formOptions()} initialValues={initialValues}>
        {({ values, isSubmitting, handleChange, errors }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <StyledPanel>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="first_name"
                      placeholder={
                        t(
                          'l.company_management.edit.legal_first_and_middle_name.label',
                          'Legal First & Middle Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      name="last_name"
                      placeholder={
                        t(
                          'l.company_management.edit.legal_last_name.label',
                          'Legal Last Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                </Row>
                <PhoneRow>
                  <Group col={colSize}>
                    <FieldInput
                      name="preferred_name"
                      placeholder={
                        t(
                          'l.company_management.edit.preferred_name.label',
                          'Preferred Name'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <PhoneInput
                      value={values.phone_number}
                      name="phone_number"
                      onChange={handleChange('phone_number')}
                      countryCodeAlpha={user?.phone_country_code_alpha}
                      placeholder={
                        t('r.profile.edit.phone_number.label', 'Phone Number') +
                        ':'
                      }
                    />
                  </Group>
                </PhoneRow>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="username"
                      placeholder={
                        t('r.profile.edit.email.label', 'Email') + '*:'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldDropdown
                      name="user_role"
                      isSearchable={false}
                      placeholder={
                        t('l.company_management.edit.role.label', 'Role') + '*:'
                      }
                      options={[
                        {
                          value: 'administrator',
                          label: t(
                            'l.company_management.edit.user_role_admin.option',
                            'Administrator'
                          )
                        },
                        {
                          value: 'agent',
                          label: t(
                            'l.company_management.edit.user_role_agent.option',
                            'Agent'
                          )
                        }
                      ]}
                    />
                  </Group>
                </Row>
              </StyledPanel>
            </ContentWrapper>
            <ButtonBar sticky>
              <LeftButtonsContainer>
                <Button
                  theme="sane"
                  width={110}
                  type="button"
                  inversed
                  onClick={handleBack}
                >
                  {t('common.back', 'Back')}
                </Button>
                {!!userId && (
                  <Button
                    theme="dark"
                    type="button"
                    width={110}
                    onClick={() =>
                      showUserPicker({
                        title: t(
                          'l.company_management.edit.select_landlords_modal.title',
                          'Select Primary Landlord'
                        ),
                        maxSelect: 1,
                        allUsers: allLandlords,
                        activeUsers: [],
                        setActiveUsers: handleReassign,
                        disabledUsers: [user],
                        buttonLabel: t(
                          'l.company_management.edit.select_landlords_modal_reassign.button',
                          'Re-assign'
                        )
                      })
                    }
                  >
                    {t(
                      'l.company_management.view.user_profile_edit_delete_user.button',
                      'Delete User'
                    )}
                  </Button>
                )}
              </LeftButtonsContainer>
              <Button theme="primary" type="submit" width={150}>
                {userId
                  ? t('common.update', 'Update')
                  : t('common.save', 'Save')}
              </Button>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default React.memo(UserEdit)
