import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { defaultTo, equals, filter, pipe, prop } from 'ramda'

const activeApplications = pipe(
  defaultTo({}),
  Object.values,
  filter(pipe(prop('state_machine'), equals('active')))
)

const useActiveApplications = listingId => {
  const dispatch = useDispatch()
  const [applications, setApplications] = useState()
  useEffect(() => {
    const getApplications = async () => {
      const {
        applications: result
      } = await dispatch.application.getByListingId(listingId)
      setApplications(activeApplications(result))
    }
    getApplications()
  }, [dispatch.application, listingId])
  return applications
}

export default useActiveApplications
