import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import Content from 'components/content'
import { WhitePage } from 'components/page'
import PageTitle from 'components/page-title'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import PageSubTitle from 'components/page-subtitle'
import { NamedWizard, WizardContent } from 'components/wizard'
import ContentWrapper from 'components/content-wrapper'
import ReviewDetailsContainer from '../review-details'
import Terms from './terms'

const ContractPreview = ({
  match: {
    params: { contractId, currentStep: currStep }
  },
  history,
  noTitle
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [hasAgreed, setHasAgreed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const contract = useSelector(state =>
    select.contract.contractView(state, { contractId })
  )
  useEffect(() => {
    if (!contract.id) {
      dispatch.contract.load(contractId)
    }
    dispatch.agreement.loadAgreement('terms_conditions')
  }, [contract.id, contractId, dispatch.agreement, dispatch.contract])

  useEffect(() => {
    dispatch.contract.loadAddendumTemplates()
  }, [dispatch.contract])
  const currentStep = currStep ? 'terms' : 'preview'

  const handleBack = () => {
    const backRoute =
      currentStep === 'preview'
        ? `/listing/${contract?.listingId}/contract/create/${contractId}`
        : `/listing/${contract?.listingId}/contract/${contractId}/preview`
    history.replace(backRoute)
  }

  const handleContinue = async () => {
    if (currentStep === 'preview') {
      history.push(
        `/listing/${contract?.listingId}/contract/${contractId}/preview/terms`
      )
    } else {
      setSubmitting(true)
      const response = await dispatch.contract.send({
        contractId,
        listingId: contract?.listingId
      })
      setSubmitting(false)
      if (!response?.error) {
        history.push(`/listing/${contract?.listingId}`)
      }
    }
  }

  const WIZARD_STEPS = {
    preview: t('l.button_bar.preview.review_details.text', 'Review Details'),
    terms: t('l.button_bar.preview.read_terms.text', 'Read Terms')
  }

  return (
    <WhitePage bottom={120}>
      {!noTitle && (
        <PageTitle>
          {t('l.contract.preview.contract.title', 'Contract')}
        </PageTitle>
      )}
      <PageSubTitle>
        {t('l.contract.preview.contract.title', 'Contract')}
      </PageSubTitle>
      <NamedWizard steps={WIZARD_STEPS} currentStep={currentStep} />
      <ContentWrapper>
        <Content>
          <WizardContent currentStep={currentStep}>
            <ReviewDetailsContainer
              index="preview"
              contract={contract}
              userType="landlord"
            />
            <Terms
              index="terms"
              hasAgreed={hasAgreed}
              setHasAgreed={setHasAgreed}
            />
          </WizardContent>
        </Content>
      </ContentWrapper>
      <ButtonBar sticky>
        <Button
          theme="sane"
          width={110}
          inversed
          disabled={false}
          onClick={handleBack}
        >
          {currentStep === 'preview'
            ? t('l.button_bar.preview.cancel.button', 'Cancel')
            : t('l.button_bar.preview.back.button', 'Back')}
        </Button>

        <Button
          theme="primary"
          width={150}
          type="submit"
          left="auto"
          onClick={handleContinue}
          disabled={currentStep === 'terms' && (!hasAgreed || submitting)}
        >
          {currentStep === 'preview'
            ? t('l.contract.preview.continue.button', 'Continue')
            : t('l.contract.preview.send.button', 'Send')}
        </Button>
      </ButtonBar>
    </WhitePage>
  )
}

ContractPreview.defaultProps = {
  contract: {},
  user: {},
  currentStep: 'preview'
}

export default withRouter(ContractPreview)
