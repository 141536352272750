/* eslint-disable max-statements, complexity */
import React, {
  useEffect,
  useReducer,
  useCallback,
  useRef,
  isValidElement,
  cloneElement
} from 'react'
import styled from 'styled-components'
import { breakpoints, zIndex } from 'styles'
import eventManager from '../utils/eventManager'
import ACTION from '../utils/action'
import ToastItem from './ToastItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`

const Container = styled.div`
  position: fixed;
  top: 90px;
  right: 30px;
  align-self: flex-end;
  z-index: ${zIndex.toast};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    top: 70px;
    right: 25px;
  }
`

const ADD = 0
const UPDATE = 1
const REMOVE = 2
const CLEAR = 3

const reducer = (state, action) => {
  const { type, data } = action
  switch (type) {
    case ADD:
      return [...state, data.id]
    case UPDATE:
      return [...state].filter(id => id !== data.staleToastId)
    case REMOVE:
      return state.filter(id => id !== data.id)
    case CLEAR:
      return []
    default:
      return state
  }
}

const ToastContainer = ({
  containerId = 'toast-notification-container',
  ...props
}) => {
  const [data, dispatch] = useReducer(reducer, [])
  const toastRef = useRef(null)
  const collection = useRef({})
  const toastKey = useRef(1)

  const appendToast = (options, content, staleToastId) => {
    const { id, updateId } = options
    collection.current = {
      ...collection.current,
      [id]: {
        options,
        content
      }
    }

    dispatch({
      type: updateId ? UPDATE : ADD,
      data: { id, staleToastId }
    })
  }

  const buildToast = useCallback(
    (content, { delay, ...options }) => {
      const { toastId } = options

      const closeToast = () => removeToast(toastId)
      const toastOptions = {
        id: toastId,
        key: options.key || toastKey.current++,
        type: options.type,
        iconId: options.iconId || props.iconId,
        title: options.title || props.title,
        closeToast: closeToast,
        updateId: options.updateId,
        onClick: options.onClick || props.onClick,
        pauseOnHover:
          typeof options.pauseOnHover === 'boolean'
            ? options.pauseOnHover
            : props.pauseOnHover,
        pauseOnFocusLoss:
          typeof options.pauseOnFocusLoss === 'boolean'
            ? options.pauseOnFocusLoss
            : props.pauseOnFocusLoss,
        draggable:
          typeof options.draggable === 'boolean'
            ? options.draggable
            : props.draggable,
        closeOnClick:
          typeof options.closeOnClick === 'boolean'
            ? options.closeOnClick
            : props.closeOnClick,
        autoClose: options.autoClose || props.autoClose,
        hideProgressBar:
          typeof options.hideProgressBar === 'boolean'
            ? options.hideProgressBar
            : props.hideProgressBar,
        progress: parseFloat(options.progress || '0'),
        role: typeof options.role === 'string' ? options.role : props.role
      }

      typeof options.onOpen === 'function' &&
        (toastOptions.onOpen = options.onOpen)

      typeof options.onClose === 'function' &&
        (toastOptions.onClose = options.onClose)

      // add closeToast function to react component only
      if (
        isValidElement(content) &&
        typeof content.type !== 'string' &&
        typeof content.type !== 'number'
      ) {
        content = cloneElement(content, {
          closeToast
        })
      } else if (typeof content === 'function') {
        content = content({ closeToast })
      }

      if (delay) {
        setTimeout(
          () => appendToast(toastOptions, content, options.staleToastId),
          delay
        )
      } else {
        appendToast(toastOptions, content, options.staleToastId)
      }
    },
    [
      props.autoClose,
      props.closeOnClick,
      props.draggable,
      props.hideProgressBar,
      props.iconId,
      props.onClick,
      props.pauseOnFocusLoss,
      props.pauseOnHover,
      props.role,
      props.title
    ]
  )

  const clear = () => dispatch({ type: CLEAR })

  const removeToast = id => dispatch({ type: REMOVE, data: { id } })

  useEffect(() => {
    eventManager
      .cancelEmit(ACTION.WILL_UNMOUNT)
      .subscribe(ACTION.SHOW, buildToast)
      .subscribe(ACTION.CLEAR, id => (id == null ? clear() : removeToast(id)))
      .emit(ACTION.DID_MOUNT, containerId, collection)
    return () => eventManager.emit(ACTION.WILL_UNMOUNT, containerId)
  }, [buildToast, containerId])

  const renderToast = () => {
    const toasts = Object.values(collection.current)
    return toasts.map(({ options, content }) => {
      if (data.indexOf(options.id) !== -1) {
        return (
          <ToastItem {...options} key={`toast-${options.key}`}>
            {content}
          </ToastItem>
        )
      } else {
        delete collection.current[options.id]
        return null
      }
    })
  }

  return (
    <Wrapper id={containerId} ref={toastRef}>
      <Container>{renderToast()}</Container>
    </Wrapper>
  )
}

export default ToastContainer
