import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { isEmpty } from 'ramda'
import useCurrentSession from 'hooks/use-current-session'
import usePrevious from 'hooks/use-previous'
import useRole from 'hooks/use-role'
import PageSubTitle from 'components/page-subtitle'
import { showCongratulationsModal } from './congratulations-modal'
import Sign from './sign'
import Partial from './partial'
import Complete from './complete'
import Invalid from './invalid'

const VALID_STATES = ['sent', 'active', 'signed', 'history']

const getNewUrl = (role, contract) => {
  const where = role === 'tenant' ? 'rental' : 'listing'
  return `/${where}/${contract.listingId}/contract/${contract.id}`
}

const ContractView = ({
  history,
  match: {
    params: { contractId, listingId }
  },
  noTitle,
  BackButtonComponent
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const role = useRole()
  useEffect(() => {
    dispatch.contract.load(contractId)
  }, [contractId, dispatch.contract])

  useEffect(() => {
    dispatch.contract.loadAddendumTemplates()
  }, [dispatch.contract])

  const contract = useSelector(state =>
    select.contract.contractView(state, { contractId })
  )
  const user = useCurrentSession()
  const prevStateMachine = usePrevious(contract?.state_machine)
  useEffect(() => {
    if (
      prevStateMachine &&
      contract?.state_machine &&
      prevStateMachine !== contract?.state_machine &&
      contract?.state_machine !== 'draft'
    ) {
      showCongratulationsModal()
    }
  }, [contract, prevStateMachine])
  const isValidState = () => VALID_STATES.indexOf(contract.state_machine) !== -1

  const isSignee = () => {
    return !![...contract.signingTenants, ...contract.landlords].find(
      ({ id }) => id === user.id
    )
  }

  const hasSigned = () => {
    return !!contract.signatures.find(signature => signature.userId === user.id)
  }
  if (isEmpty(contract) || !contract.id) return null

  if (!isValidState()) return <Invalid />

  if (contract.state_machine === 'sent') {
    if (isSignee() && !hasSigned()) {
      if (!listingId) {
        return history.replace(getNewUrl(role, contract))
      }
      return (
        <>
          <PageSubTitle>
            {t('l.contract.preview.contract.title', 'Contract')}
          </PageSubTitle>
          <Sign
            contract={contract}
            user={user}
            sticky={!listingId}
            noTitle={noTitle}
          />
        </>
      )
    }
    return (
      <>
        <PageSubTitle>
          {t('l.contract.preview.contract.title', 'Contract')}
        </PageSubTitle>
        <Partial contract={contract} user={user} noTitle={noTitle} />
      </>
    )
  }

  return (
    <>
      <PageSubTitle>
        {t('l.contract.preview.contract.title', 'Contract')}
      </PageSubTitle>
      <Complete
        contract={contract}
        user={user}
        sticky={!listingId}
        noTitle={noTitle}
        BackButtonComponent={BackButtonComponent}
      />
    </>
  )
}

export default React.memo(ContractView)
