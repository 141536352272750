const LABELS = t => ({
  t4: t('r.profile.view.t4.text', 'T4'),
  tax_slip: t('r.profile.view.taxslip.text', 'Tax Slips'),
  bank_statement: t('r.profile.view.bank_statement.text', 'Bank Statement'),
  business_card: t('r.profile.view.business_card.text', 'Business Card'),
  job_offer: t('r.profile.view.job_offer_letter.text', 'Job Offer Letter'),
  payslip: t('r.profile.view.payslip.text', 'Pay Slip'),
  student_loan: t('r.profile.view.student_loans.text', 'Student Loans'),
  school_id: t('r.profile.view.school_id.text', 'School ID'),
  school_letter: t('r.profile.view.school_letter.text', 'Enrolment Letter')
})

export default LABELS
