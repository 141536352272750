import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Icon from 'components/icon'
import { Button } from 'components/button'
import { fontSizes, fontWeights } from 'styles'
import { any, length, values } from 'ramda'
import { show as showAddAddendumModal } from './add-addendum-modal'
import AddendumList from './addendum-list'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  justify-self: center;
`

const NoAddendumsText = styled.p`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
`

const AddButton = styled(Button)`
  padding: 5px 25px;
`

const ContractAddendums = ({ data, setFieldValue, match }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const hasAddendum = any(list => length(list) > 0, [
    values(data?.contract_predefined_addendums),
    data?.contract_addendums
  ])

  const handleCreateAddendum = payload => {
    dispatch.contract.createAddendum({
      ...payload,
      contractId: match.params.contractId
    })
  }

  const handleDeleteAddendum = payload => {
    dispatch.contract.deleteAddendum({
      ...payload,
      contractId: match.params.contractId
    })
  }

  const handleShowAddModal = () => {
    showAddAddendumModal({
      createAddendum: handleCreateAddendum,
      updateValue: setFieldValue
    })
  }

  return (
    <Wrapper>
      {hasAddendum ? (
        <AddendumList
          createAddendum={handleCreateAddendum}
          deleteAddendum={handleDeleteAddendum}
          data={data}
          setFieldValue={setFieldValue}
          listingId={match.params.listingId}
        />
      ) : (
        <>
          <Icon id="addendum" width={75} height={75} />
          <NoAddendumsText>
            {t(
              'l.contract.edit.addendums_no_addendum.subtitle',
              'No addendums selected'
            )}
          </NoAddendumsText>
          <AddButton onClick={handleShowAddModal} theme="sane" inversed>
            {t(
              'l.contract.edit.addendums_add_addendum.button',
              'Add Addendums'
            )}
          </AddButton>
        </>
      )}
    </Wrapper>
  )
}
export default React.memo(ContractAddendums)
