import styled from 'styled-components'
import { colors } from 'styles'

const Required = styled.span`
  background: ${colors.pink};
  border-radius: 50%;
  height: 6px;
  width: 6px;
  display: inline-block;
  margin-left: ${({ left }) => (left ? left : 10)}px;
  vertical-align: middle;
`

export default Required
