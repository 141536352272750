import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { mergeRight } from 'ramda'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { colors, fontSizes, fontWeights } from 'styles'
import noop from 'lodash/noop'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { Label, Group, FieldDropdown, FieldTextarea } from 'components/form'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const StyledGroup = styled(Group)`
  flex: 1;
  margin: 10px;
  padding: 25px 30px;
  margin: 0;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  padding: 0 29px;
  margin: 0;
  letter-spacing: 0.25px;
  margin: 30px auto;
`

const validationSchema = Yup.object().shape({
  user_message: Yup.string().min(1).max(500).required(),
  reference_type: Yup.string().required()
})

const ReportProblemModal = ({ data, onSave = noop }) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialValues = {
    user_message: '',
    user_id: data.user_id,
    reference_type: ''
  }

  const MAP = {
    listing: data.listing_id,
    user: data.user_ids.them[0],
    building: data.buildings.id
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const referenceId = MAP[values.reference_type]

    let updatedData = mergeRight(values, {
      reference_id: referenceId
    })

    const response = await dispatch.chat.reportProblem(updatedData)
    setSubmitting(false)

    if (!response.error) {
      onSave()
      close()
    }
  }

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>
          {t('b.chat.report_a_problem_modal.title', 'Report a Problem')}
        </Title>
      </Header>
      <Formik
        padding="15px"
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form>
            <StyledGroup>
              <FieldDropdown
                name="reference_type"
                placeholder={t(
                  'b.chat.report_a_problem_modal.description',
                  'What is the problem related to?'
                )}
                options={[
                  {
                    value: 'listing',
                    label: t(
                      'b.chat.report_a_problem_modal_dropdown.listing.text',
                      'Listing'
                    )
                  },
                  {
                    value: 'user',
                    label: t(
                      'b.chat.report_a_problem_modal_dropdown.user.text',
                      'User'
                    )
                  },
                  {
                    value: 'building',
                    label: t(
                      'b.chat.report_a_problem_modal_dropdown.building.text',
                      'Building'
                    )
                  },
                  {
                    value: 'other',
                    label: t(
                      'b.chat.report_a_problem_modal_dropdown.other.text',
                      'Other'
                    )
                  }
                ]}
              />
            </StyledGroup>
            <StyledGroup>
              {
                // prettier-ignore
                <Label>
                  {t(
                    'b.chat.report_a_problem_modal_describe_problem.label',
                    'Please describe the problem you\'re having'
                  )}
                </Label>
              }
              <FieldTextarea name="user_message" maxLength={500} height={200} />
            </StyledGroup>
            <ActionButton
              name="license_pill"
              theme="primary"
              width={125}
              left="auto"
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {t('b.chat.report_a_problem_modal.submit.button', 'Submit')}
            </ActionButton>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

export const showReportModal = createModal(ReportProblemModal)
