import { pipe, evolve, values as ramdaValues, map, find, propEq } from 'ramda'
import { renameKeys } from 'utils'
import toArray from 'utils/to-array'
import toUpper from 'lodash/toUpper'

const formatCountries = pipe(
  map(
    pipe(
      renameKeys({
        txt_id: 'value',
        name: 'label'
      })
    )
  ),
  ramdaValues
)

const formatStates = pipe(
  map(
    pipe(
      renameKeys({
        txt_id: 'value',
        name: 'label'
      }),
      evolve({ value: toUpper })
    )
  ),
  ramdaValues
)

export const getFormattedCountries = countries => formatCountries(countries)

export const getFormattedStates = (countries, countryCode) => {
  const countryData = find(
    propEq('txt_id', countryCode.toLowerCase()),
    toArray(countries)
  )
  return formatStates(countryData.states)
}
