import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontWeights } from 'styles'
import moment from 'moment'
import { Avatar, defaultAvatar } from 'components/avatar'
import { path } from 'ramda'
import useProfile from 'hooks/use-profile'
import { getImageUrl } from 'utils/image'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
`

const RequestedByRow = styled(Row)`
  color: ${colors.mediumGrey};
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 5px;
  padding: 0 20px;
  background-color: ${colors.lightGrey};
  margin-top: 0px;
`

const LabelHeader = styled.p`
  margin: 20px 0px;
  margin-bottom: ${props => props.bottom || 0}px;
  margin-top: ${props => props.top || 20}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${fontWeights.medium};
`

const LabelText = styled(LabelHeader)`
  font-weight: ${fontWeights.regualr};
`

const LandlordName = styled(LabelText)`
  margin: 10px 0;
`

const DateText = styled.p`
  margin: 20px 0px 0px;
  color: ${colors.pink};
  font-weight: 700;
`

const ReasonText = styled.p`
  color: ${colors.mediumGrey};
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 5px;
  padding: 20px;
  background-color: ${colors.lightGrey};
  margin-top: 0px;
  overflow-wrap: break-word;
`

const SuiteInfoWrapper = styled.div`
  display: flex;
  color: ${colors.regular};
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 5px;
  background-color: ${colors.lightGrey};
  height: 115px;
  overflow: hidden;
`

const RightSuiteWrapper = styled.div`
  width: 60%;
  margin: 0px 20px;
`

const LeftSuiteWrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  width: 40%;
`

const ListingImage = styled.img`
  height: 115px;
  width: 100%;
  object-fit: cover;
  max-height: 100%;
`

const RequestDetails = ({ data, listingData, showSummary = false }) => {
  const [t] = useTranslation()
  const userProfile = useProfile()

  const requestedBy = path(['users', data?.user_id], listingData) || userProfile

  const getImage = url => (url ? getImageUrl(url) : defaultAvatar)

  return (
    <>
      <Row>
        <LabelHeader>
          {t(
            'r.rental_dashboard.edit.resources_end_tenancy_modal_date_label.text',
            'Desired end tenancy date'
          )}
          :
        </LabelHeader>
        <DateText>
          {moment(data?.motive_end_date).format('YYYY-MM-DD')}
        </DateText>
      </Row>
      <ReasonText>{data?.motive_end_reason}</ReasonText>
      {showSummary && (
        <>
          <LabelHeader bottom={5}>
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_requested_by_label.text',
              'Requested by'
            )}
            :
          </LabelHeader>
          <RequestedByRow>
            <LandlordName>
              {`${requestedBy?.preferred_name} ${requestedBy?.last_name}`}
            </LandlordName>
            <Avatar
              location={getImage(requestedBy?.avatar?.aws_s3_key)}
              width={30}
              height={30}
            />
          </RequestedByRow>
          <LabelHeader bottom={5}>
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_listing_label.text',
              'Listing'
            )}
            :
          </LabelHeader>
          <SuiteInfoWrapper>
            <LeftSuiteWrapper>
              <ListingImage
                src={
                  listingData?.unitImages
                    ? getImageUrl(listingData?.unitImages[0]?.aws_s3_key)
                    : ''
                }
              />
            </LeftSuiteWrapper>
            <RightSuiteWrapper>
              <LabelText bottom={5}>
                <strong>
                  {listingData?.building?.name ||
                    t(
                      'r.rental_dashboard.edit.resources_end_tenancy_modal_house.text',
                      'House'
                    )}
                </strong>
              </LabelText>
              <LabelText top={5} bottom="0">
                {listingData?.building?.full_street_name}
              </LabelText>
              <LabelText top="0">
                {`${listingData?.building?.city}, ${listingData?.building?.zip}`}
              </LabelText>
            </RightSuiteWrapper>
          </SuiteInfoWrapper>
        </>
      )}
    </>
  )
}

export default RequestDetails
