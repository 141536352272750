import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { path } from 'ramda'
import { Input } from 'components/form'
import { AnchorButton } from 'components/anchor'
import { breakpoints, colors, fontWeights, fontSizes } from 'styles'

const PanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  border-bottom: 1px solid ${colors.grey};

  &:last-of-type {
    border: none;
  }
`

const PanelText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  margin: 0px;
  margin-right: 40px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 12px;
    margin-right: 20px;
  }
`

const ActionButton = styled(AnchorButton)`
  padding: 10px 20px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  min-width: 120px;
  outline: none;

  :active {
    opacity: 0.5;
  }

  ${props =>
    props.disabled &&
    `
    border: 2px solid ${colors.regular};
    background-color: ${colors.white};
    color: ${colors.regular};
    cursor: default;
    opacity: 0.3;
    &:hover {
      color: ${colors.regular};
    }
    :active {
      opacity: 0.3;
    }
    `}
`

const LinkText = styled.a`
  color: ${colors.link};
`

const CheckBox = styled(Input)``

const CheckBoxWrapper = styled.div`
  min-width: 83px;
  text-align: center;
`

const LivScoreForm = ({ data, userRole }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const creditRatingVerified =
    data?.liv_score?.credit_rating !== 'not enough' &&
    data?.liv_score?.credit_rating !== undefined
  const isInternationalUser =
    useSelector(path(['profile', 'international_user'])) === '1'

  const handleInternationalCheck = payload => {
    dispatch.livScore.updateInternationalUser(payload)
  }
  if (userRole !== 'tenant') return null
  return (
    <>
      {!creditRatingVerified && (
        <PanelRow>
          <PanelText>
            {t(
              'r.profile.edit.liv_score_international.text',
              'I do not currently live in Canada'
            )}
          </PanelText>
          <CheckBoxWrapper>
            <CheckBox
              onChange={() =>
                handleInternationalCheck(isInternationalUser ? 0 : 1)
              }
              checked={isInternationalUser}
              type="checkbox"
            />
          </CheckBoxWrapper>
        </PanelRow>
      )}
      {!isInternationalUser && (
        <PanelRow>
          <PanelText>
            <Trans i18nKey="r.profile.edit.liv_score_verify.text">
              Verify your financial standing by running your credit history now.
              Please see our{' '}
              <LinkText
                href="https://liv.rent/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </LinkText>{' '}
              for more details.
            </Trans>
          </PanelText>
          <ActionButton
            onClick={e => {
              if (creditRatingVerified) {
                e.preventDefault()
              }
            }}
            to="/profile/liv-score/edit/credit-check"
            disabled={!!creditRatingVerified}
          >
            {creditRatingVerified
              ? t('r.profile.edit.liv_score_verified.button', 'Verified')
              : t('r.profile.edit.liv_score_verify.button', 'Verify')}
          </ActionButton>
        </PanelRow>
      )}
    </>
  )
}

export default React.memo(LivScoreForm)
