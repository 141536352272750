import React from 'react'
import { useTranslation } from 'react-i18next'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import { FieldTextarea } from 'components/form'
import { Subtitle, StyledRow, StyledGroup } from './elements'

const Description = () => {
  const { t } = useTranslation()

  return (
    <>
      <AreaTitle>
        {t(
          'l.listing.edit.describe_property.subtitle',
          'Describe Your Property'
        )}
      </AreaTitle>
      <Panel vSpace={30} hSpace={30}>
        <Subtitle>
          {t('l.listing.edit.add_description.label', 'Add Description')}
        </Subtitle>
        <StyledRow gutter={20}>
          <StyledGroup>
            <FieldTextarea
              name="description"
              maxLength={1000}
              height={200}
              placeholder={t(
                'r.rental_dashboard.edit.description.placeholder',
                'Include additional information about your listing'
              )}
            />
          </StyledGroup>
        </StyledRow>
      </Panel>
    </>
  )
}

export default Description
