import React from 'react'
import { useTranslation } from 'react-i18next'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import formatCurrency from 'utils/format-currency'
import formatPaymentFrequency from 'utils/format-payment-frequency'
import RentInfoFooter from './rent-info-footer'

const RentInfo = ({
  price,
  priceFrequency,
  extraOffer,
  extraOfferFrequency
}) => {
  const { t } = useTranslation()
  return (
    <CollapsibleBox
      title={t('b.contract.view.rent_info.subtitle', 'Rent Info')}
      wrapChildren
      footer={
        <RentInfoFooter
          price={price}
          priceFrequency={priceFrequency}
          extraOffer={extraOffer}
          extraOfferFrequency={extraOfferFrequency}
        />
      }
    >
      <LabeledValue
        key="price"
        label={t('b.contract.view.rent.label', 'Rent')}
        value={formatCurrency(price)}
      />
      <LabeledValue
        key="frequency"
        label={t(
          'b.contract.view.payment_frequency.label',
          'Payment frequency'
        )}
        value={formatPaymentFrequency(priceFrequency)}
      />
      {extraOffer !== '0.00' && (
        <LabeledValue
          key="offer"
          label={t('b.contract.view.added_offer.label', 'Added offer')}
          value={formatCurrency(extraOffer)}
        />
      )}
      {extraOffer !== '0.00' && (
        <LabeledValue
          key="offer-frequency"
          label={t(
            'b.contract.view.payment_frequency.label',
            'Payment frequency'
          )}
          value={formatPaymentFrequency(extraOfferFrequency)}
        />
      )}
    </CollapsibleBox>
  )
}

RentInfo.defaultProps = {
  price: null,
  priceFrequency: null,
  extraOffer: null,
  extraOfferFrequency: null
}

export default RentInfo
