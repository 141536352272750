import React, { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { formatPhone } from 'utils/format-phone'
import { verifyPhoneLogin, verifyCodeLogin } from 'models/auth/ports'
import CodeContainer from './code'
import Context from './context'

const VerifyCodeLogin = () => {
  const { t } = useTranslation()
  const { codeRecipient, setCodeRecipient } = useContext(Context)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setCodeRecipient('')
    }
  }, [setCodeRecipient])

  if (!codeRecipient) {
    return <Redirect to="/" />
  }

  const sendCode = () => verifyPhoneLogin({ phone: codeRecipient })

  const validateCode = async code => {
    const { response } = await verifyCodeLogin({ code, phone: codeRecipient })
    if (response.ok) {
      dispatch.session.loadSession()
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {t('b.auth.view.verify_number.title', 'Verify Your Number')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <CodeContainer
        sendCode={sendCode}
        validate={validateCode}
        title={t('b.auth.view.verify_number.title', 'Verify Your Number')}
        sendTo={formatPhone(codeRecipient, 'US')}
      />
    </>
  )
}

export default VerifyCodeLogin
