import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { colors, fontSizes, breakpoints } from 'styles'
import noop from 'lodash/noop'
import { toUnit } from 'utils/styles'
import { OptionList } from 'components/option-list'
import arrowIcon from './drop-down-arrow.svg'

const Box = styled.div`
  height: 40px;
  border-radius: 6px;
  border: 1px solid ${colors.grey};
  padding: 0 10px;
  font-size: ${fontSizes.regular};
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  background: ${colors.white};
  min-width: ${props => (props.minWidth ? toUnit(props.minWidth) : 'unset')};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    height: 30px;
    border-radius: 4px;
    font-size: ${fontSizes.small};
  }
`

const Icon = styled.img`
  width: 13px;
  height: 9px;
  margin-left: 10px;
  transition: transform 0.3s;

  ${({ visible }) => (visible ? 'transform: rotate(180deg);' : '')}
`

const Options = styled.div`
  position: absolute;
  max-height: ${props => (props.visible ? '9999px' : 0)};
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
  z-index: 100;
  margin-top: 5px;
`

const Dropdown = ({
  onChange = noop,
  value,
  display = 'Select',
  minWidth,
  children
}) => {
  const [opened, setOpened] = useState(false)
  const ref = useRef()

  const onClickOutside = e => {
    if (ref.current.contains(e.target)) {
      return
    }
    close()
  }

  const toggle = () => {
    setOpened(curr => !curr)
  }

  const close = () => {
    setOpened(false)
  }

  const handleChange = newValue => {
    close()
    onChange(newValue)
  }

  return (
    <div ref={ref}>
      <Box onClick={toggle} minWidth={minWidth}>
        {display}
        <Icon visible={opened} src={arrowIcon} alt="Toggle Dropdown" />
      </Box>
      <Options visible={opened}>
        <OptionList
          value={value}
          onChange={handleChange}
          clickOutsideCallback={onClickOutside}
          rounded
        >
          {children}
        </OptionList>
      </Options>
    </div>
  )
}

Dropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ]),
  display: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired
}

export default Dropdown
