import React from 'react'
import { join, toLower, pipe, replace, propEq, defaultTo } from 'ramda'
import toInteger from 'lodash/toInteger'
import CurrencyFormatter from 'components/currency-formatter'
import { sqftToMeter } from './units'

const BUILDING_TYPE = {
  condo: 'apartment',
  highrise: 'apartment',
  lowrise: 'apartment',
  house: 'house',
  townhouse: 'townhouse'
}

const PAYMENT_FREQUENCY = {
  daily: 'Day',
  weekly: 'Week',
  monthly: 'Month',
  one_time: 'Single payment'
}

export const getUrl = (listing = {}) =>
  join('/', [
    '/rental-listings/detail',
    BUILDING_TYPE[listing.building_type] || 'apartment',
    pipe(defaultTo(''), toLower, replace(' ', '-'))(listing?.city),
    listing?.listing_id
  ])

export const getPaymentFrequency = key => PAYMENT_FREQUENCY[key]
export const isGroup = propEq('gr_unit', '1')

export const prefixUnitNumber = unitNumber => {
  if (unitNumber) {
    return unitNumber
  }
  return ''
}

export const parseAreaSize = (sizeInSqft, targetFormat) =>
  targetFormat === 'meter' ? toInteger(sqftToMeter(sizeInSqft)) : sizeInSqft

export const getAreaMeasurementLabel = format =>
  format === 'meter' ? 'm²' : 'ft²'

export const formatListingPrice = price => {
  return price ? <CurrencyFormatter value={price} /> : 'N/A'
}

export const renderListingPrice = listing => {
  const { gr_unit, gr_min_price, gr_max_price, price } = listing
  return gr_unit === '1' ? (
    <>
      {formatListingPrice(gr_min_price)} - {formatListingPrice(gr_max_price)}
    </>
  ) : (
    formatListingPrice(price)
  )
}
