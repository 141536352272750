import styled from 'styled-components'
import Panel from 'components/panel'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'

export const PanelHeader = styled.div`
  height: 100px;
  display: flex;
  align-items: flex-end;

  a:only-child {
    margin-left: auto;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

export const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin-right: auto;

  @media screen and (max-width: 414px) {
    line-height: 20px;
    font-size: 15px;
  }
`

export const EditButton = styled.button`
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
`

export const FlexWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%;'};
  position: relative;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

export const SubtitleText = styled.p`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 16px;
  margin: 0;
`
export const EditText = styled.p`
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  line-height: 12px;
  position: absolute;
  top: -38px;
  right: -24px;
`

export const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-wrap: break-word;
  margin-top: ${({ top }) => top || '0px'};
  margin-left: ${({ left }) => left || '0px'};
`

export const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
`

export const ContactNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  width: ${({ width }) => width || '45%'};
  padding-right: 20px;
`

export const SubtitleTextSmall = styled.p`
  color: #494949;
  font-size: 14px;
  line-height: 16px;
  margin: 10px 0 0 0;
`

export const StyledPanel = styled(Panel)`
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 20px 10px 0;
  }
`

export const RequiredText = styled.p`
  color: #fe5f55;
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  line-height: 19px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 11px;
    font-weight: normal;
    margin: 0 10px;
  }
`
