import styled from 'styled-components'
import { colors } from 'styles'

const ProgressWizard = styled.div`
  position: relative;
  background-color: ${colors.mediumGrey};
  height: 4px;

  &:before {
    content: ' ';
    position: absolute;
    background-color: ${colors.pink};
    height: 100%;
    left: 0;
    width: ${({ progress }) => `${progress}%`};
    transition: 500ms width ease-out;
    border-radius: 3px;
  }
`

export default ProgressWizard
