import React from 'react'
import { useTranslation } from 'react-i18next'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import { FieldInput, FieldDropdown, FieldDatePicker } from 'components/form'
import { Subtitle, StyledRow, StyledGroup } from './elements'

const Terms = ({ data, values }) => {
  const { t } = useTranslation()
  const isGroupListing = data.isGroupListing

  const renderFrequency = () => (
    <StyledGroup col={6}>
      <FieldDropdown
        name="price_frequency"
        id="price_frequency"
        placeholder={
          t('l.listing.edit.payment_frequency.label', 'Payment frequency') +
          '*:'
        }
        options={[
          {
            value: 'daily',
            label: t('l.listing.edit.payment_frequency_daily.text', 'Daily')
          },
          {
            value: 'weekly',
            label: t('l.listing.edit.payment_frequency_weekly.text', 'Weekly')
          },
          {
            value: 'monthly',
            label: t('l.listing.edit.payment_frequency_monthly.text', 'Monthly')
          },
          {
            value: 'one_time',
            label: t(
              'l.listing.edit.payment_frequency_one_time.text',
              'One-time'
            )
          }
        ]}
        isSearchable={false}
      />
    </StyledGroup>
  )

  const renderLeaseType = () => (
    <StyledGroup col={6}>
      <FieldDropdown
        name="lease_type"
        id="lease_type"
        placeholder={t('l.listing.edit.lease_type.label', 'Lease type') + '*:'}
        options={[
          {
            value: 'fixed',
            label: t('l.listing.edit.fixed_term.text', 'Fixed term')
          },
          {
            value: 'month_to_month',
            label: t('l.listing.edit.month_to_month.text', 'Month to month')
          }
        ]}
        isSearchable={false}
      />
    </StyledGroup>
  )

  const renderAvailabilityDate = () => (
    <StyledGroup col={6}>
      <FieldDatePicker
        name="availability_date"
        id="availability_date"
        placeholder={
          t('l.listing.edit.availability_date.label', 'Availability date') +
          '*:'
        }
      />
    </StyledGroup>
  )

  const renderMinLease = () => (
    <StyledRow gutter={20}>
      <StyledGroup col={6}>
        <FieldDropdown
          name="lease_length"
          id="lease_length"
          placeholder={
            t(
              'l.listing.edit.minimum_lease_duration.label',
              'Minimum lease duration'
            ) + ':'
          }
          options={[
            {
              value: 'no_minimum',
              label: t('l.listing.edit.no_minimum.text', 'No minimum')
            },
            {
              value: 'one_month',
              label: t('l.listing.edit.one_month.text', 'At least one month')
            },
            {
              value: 'six_months',
              label: t('l.listing.edit.six_months.text', 'At least six months')
            },
            {
              value: 'one_year',
              label: t('l.listing.edit.one_year.text', 'At least one year')
            },
            {
              value: 'other',
              label: t('l.listing.edit.other.text', 'Other')
            }
          ]}
          isSearchable={false}
        />
      </StyledGroup>
      {values.lease_length === 'other' && (
        <>
          <StyledGroup col={3}>
            <FieldInput
              name="min_lease_period"
              id="min_lease_period"
              autoComplete="off"
              placeholder={t('l.listing.edit.minimum.label', 'Minimum') + '*:'}
            />
          </StyledGroup>
          <StyledGroup col={3}>
            <FieldDropdown
              name="min_lease_period_type"
              id="min_lease_period_type"
              placeholder={t('l.listing.edit.unit.label', 'Unit') + '*:'}
              options={[
                {
                  value: 'days',
                  label: t('l.listing.edit.days.text', 'Days')
                },
                {
                  value: 'weeks',
                  label: t('l.listing.edit.weeks.text', 'Weeks')
                },
                {
                  value: 'months',
                  label: t('l.listing.edit.months.text', 'Months')
                }
              ]}
              isSearchable={false}
            />
          </StyledGroup>
        </>
      )}
    </StyledRow>
  )

  const renderGroupTerms = () => (
    <>
      <Subtitle>
        {t(
          'l.listing.edit.rent_prices_available.label',
          'Rent Prices Available'
        )}
      </Subtitle>
      <StyledRow gutter={20}>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_min_price"
            id="gr_min_price"
            prefix="$"
            placeholder={
              t(
                'l.listing.edit.min_rental_price.label',
                'Minimum rental price'
              ) + '*:'
            }
          />
        </StyledGroup>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_max_price"
            id="gr_max_price"
            prefix="$"
            placeholder={
              t(
                'l.listing.edit.max_rental_price.label',
                'Maximum rental price'
              ) + '*:'
            }
          />
        </StyledGroup>
      </StyledRow>
      <StyledRow gutter={20}>{renderFrequency()}</StyledRow>
      <Subtitle>
        {t('l.listing.edit.lease_information.label', 'Lease Information')}
      </Subtitle>
      <StyledRow gutter={20}>
        {renderLeaseType()}
        {renderAvailabilityDate()}
      </StyledRow>
      {renderMinLease()}
    </>
  )

  const renderTerms = () => (
    <>
      <StyledRow gutter={20}>
        <StyledGroup col={6}>
          <FieldInput
            name="price"
            id="price"
            prefix="$"
            placeholder={
              t('l.listing.edit.rental_price.label', 'Rental price') + '*:'
            }
          />
        </StyledGroup>
        {renderFrequency()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderLeaseType()}
        {renderAvailabilityDate()}
      </StyledRow>
      {renderMinLease()}
    </>
  )

  return (
    <>
      <AreaTitle>
        {t('l.listing.edit.lease_terms.subtitle', 'Define Lease Terms')}
      </AreaTitle>
      <Panel vSpace={30} hSpace={30}>
        {isGroupListing ? renderGroupTerms() : renderTerms()}
      </Panel>
    </>
  )
}

export default Terms
