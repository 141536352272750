import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { breakpoints } from 'styles'
import { getImageUrl } from 'utils/image'
import useMediaQuery from 'hooks/use-media-query'
import LabeledDetail from 'components/labeled-detail'
import { Avatar } from 'components/avatar'
import PetAvatar from './pet-avatar.svg'
import {
  FlexWrapper,
  SubtitleText,
  DetailsWrapper,
  DetailsRow
} from '../elements'

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: center;
  }
`

const PetView = ({ data }) => {
  const [t] = useTranslation(['translation', 'basic'])
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  return (
    <Wrapper>
      <Avatar
        width={160}
        height={160}
        right={displayMobile ? 0 : 40}
        bottom={displayMobile ? 20 : 0}
        location={getImageUrl(data.avatar?.aws_s3_key, {
          width: 160,
          height: 160
        })}
        alt={data.name}
        fallback={PetAvatar}
      />
      <FlexWrapper direction="column">
        <SubtitleText>{data.name}</SubtitleText>
        <DetailsWrapper top="20px">
          <DetailsRow>
            <LabeledDetail
              label={t('r.profile.view.type_of_pet.label', 'Type of Pet')}
              value={data.type}
              width="50%"
            />
            <LabeledDetail
              label={t('r.profile.view.breed.label', 'Breed')}
              value={data.breed}
              width="50%"
            />
          </DetailsRow>
          <LabeledDetail
            label={t('r.profile.view.age.label', 'Age')}
            value={
              data.birth_year ? moment().diff([data.birth_year], 'years') : '-'
            }
            width="50%"
          />
          <LabeledDetail
            label={t('r.profile.view.size.label', 'Size')}
            value={t(`basic:b.pets.pet_sizes.${data.size}.text`)}
            width="50%"
          />
        </DetailsWrapper>
      </FlexWrapper>
    </Wrapper>
  )
}

export default React.memo(PetView)
