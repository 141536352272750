import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { values, find, propEq } from 'ramda'
import { confirm } from 'components/dialog'
import InsuranceView from './view'

const InsuranceFullView = ({ data, editProps = {} }) => {
  const { t } = useTranslation()
  const insurance = find(propEq('tag', 'insurance_info'), values(data))
  const dispatch = useDispatch()

  const handleDeleteClick = ({ fileId, type }) => {
    return confirm(
      t(
        'r.profile.edit.delete_insurance_confirmation.title',
        'Delete Insurance Policy?'
      ),
      t(
        'r.profile.edit.delete_insurance_confirmation.text',
        'Are you sure you want to delete this policy?'
      )
    ).then(async () => {
      dispatch.insurance.deleteFile({
        fileId,
        type
      })
    })
  }

  return (
    <InsuranceView
      insurance={insurance}
      handleDelete={handleDeleteClick}
      editable
      editProps={editProps}
    />
  )
}

export default InsuranceFullView
