import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, structure } from 'styles'
import { toUnit } from 'utils/styles'
import useMediaQuery from 'hooks/use-media-query'
import SearchBar from 'components/search-bar'

import backgroundTopLeft from './images/background-top-left.png'
import backgroundTopRight from './images/background-top-right.png'

const PageHeader = styled.div`
  top: ${toUnit(structure.header.height)};
  position: sticky;
  width: 100%;
  z-index: 20;
  background-color: ${colors.white};

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    box-shadow: 0 2px 8px 0 rgba(146, 146, 146, 0.69);
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
  }
`

const PageHeaderInner = styled.div`
  height: 157px;
  max-width: 1130px;
  margin: 0 100px;
  padding: 45px 0 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    height: 115px;
    margin: 0 25px;
    padding: 20px 0;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: center;
    margin: 0 10px;
  }
`

const LeftCloud = styled.img`
  pointer-events: none;
  position: absolute;
  width: 238px;
  top: 115px;
  left: -160px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: none;
  }
`

const RightCloud = styled.img`
  pointer-events: none;
  position: absolute;
  width: 190px;
  top: 40px;
  right: 0;
  object-position: 90px 0;

  @media only screen and (max-width: 1470px) {
    top: 30px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: none;
  }
`

const HeaderTitle = styled.h1`
  font-size: 30px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 41px;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    display: none;
  }
`

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
    align-items: center;
    height: 115px;
  }
`

const SearchInner = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 100%;
    max-width: 340px;
  }
`

const ListingTableHeader = ({ title, onSearch, buttonComponent, children }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const ButtonComponent = buttonComponent

  return (
    <PageHeader>
      <RightCloud src={backgroundTopRight} alt="" />
      <PageHeaderInner>
        <LeftCloud src={backgroundTopLeft} alt="" />
        <HeaderTitle>{title}</HeaderTitle>
        <FiltersWrapper>
          {children}
          <SearchInner>
            {buttonComponent && <ButtonComponent />}
            <SearchBar
              placeholder={t('common.search', 'Search')}
              onSearch={onSearch}
              fullWidth={displayMobile}
            />
          </SearchInner>
        </FiltersWrapper>
      </PageHeaderInner>
    </PageHeader>
  )
}

export default ListingTableHeader
