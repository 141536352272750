import ENV_VARS from 'config'
import { post } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const deleteAccount = post(`${BACKEND_SERVER}/requests/users/remove`)

export const changePhonePassword = post(
  `${BACKEND_SERVER}/users/change_request_phone/step/password`
)
export const changePhoneNewNumber = post(
  `${BACKEND_SERVER}/users/change_request_phone/step/new_phone`
)
export const changePhoneConfirmCode = post(
  `${BACKEND_SERVER}/users/change_request_phone/step/confirm_code`
)
