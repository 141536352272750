import React from 'react'
import styled from 'styled-components'

const FallbackImage = ({
  fallback,
  alt,
  src,
  ImageComponent = styled.img``,
  ...rest
}) => {
  const onError = e => {
    e.target.src = fallback
  }
  return (
    <ImageComponent
      src={src || fallback}
      {...rest}
      alt={alt}
      onError={onError}
    />
  )
}

export default FallbackImage
