import React from 'react'
import { useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { CompanyListings } from 'features/CompanyManagement'

const ManageListings = ({ history }) => {
  const [t] = useTranslation()
  const buildings = useSelector(select.companyManagement.buildings)

  const handleRowClick = listingId => {
    history.push(`${listingId}`)
  }

  return (
    <CompanyListings
      pageTitle={t(
        'l.company_management.list.manage_listings.title',
        'Manage Listings'
      )}
      buildings={buildings}
      onRowClick={handleRowClick}
    />
  )
}

export default React.memo(ManageListings)
