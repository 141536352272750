import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { PhoneInput } from 'components/form'
import { Button } from 'components/button'
import { Formik } from 'formik'
import * as Yup from 'yup'

import AssistCodeContainer from './assist-code'

const Wrapper = styled.div`
  padding: 50px 30px 20px 30px;
  text-align: center;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const ConfirmButton = styled(Button)`
  margin-top: 30px;
`

const RemoteAssistModal = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [phone, setPhone] = useState()
  const [clicked, setClicked] = useState(false)
  const { close } = useModalize()

  const handleClick = phone_number => async () => {
    const { error } = await dispatch.profile.remoteAssistRequestToken(
      phone_number
    )
    setPhone(phone_number)
    if (!error) {
      setClicked(true)
    }
  }

  const validationSchema = Yup.object().shape({
    phone_number: Yup.string().phone().required()
  })

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      {clicked ? (
        <AssistCodeContainer phone={phone} close={close} />
      ) : (
        <Formik
          initialValues={{ phone_number: '' }}
          validationSchema={validationSchema}
        >
          {({ values, isValid }) => (
            <>
              <Title>{t('b.assist.view.liv_assist.title', 'Liv Assist')}</Title>

              <PhoneInput
                name="phone_number"
                value={values.phone_number}
                placeholder={
                  t('b.assist.view.phone_number.label', 'Phone Number') + ':'
                }
              />
              <ConfirmButton
                width={150}
                disabled={!isValid}
                theme="danger"
                onClick={handleClick(values.phone_number)}
              >
                {t(
                  'b.settings.edit.remote_assist_modal_proceed.button',
                  'Proceed'
                )}
              </ConfirmButton>
            </>
          )}
        </Formik>
      )}
    </Wrapper>
  )
}

export const show = createModal(React.memo(RemoteAssistModal))
