import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'
import { useTranslation } from 'react-i18next'
import { createModal, useModalize } from 'components/modalize'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  max-height: 500px;
`

const StyledTaskbar = styled(Taskbar)`
  background-color: ${colors.lightGrey};
  position: fixed;
  width: 400px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

const DeleteButton = styled.button`
  color: ${colors.secondary};
  border: none;
  outline: none;
  cursor: pointer;
  font-size: ${fontSizes.small};
  background-color: transparent;
`

const Document = styled.img`
  margin: 30px 0 0;
  width: auto;
  height: 300px;
`

const ViewDocumentModal = ({ src, id, handleDelete }) => {
  const [t] = useTranslation()
  const { close } = useModalize()

  const deleteAndClose = () => {
    handleDelete()
    close()
  }

  return (
    <Wrapper>
      <StyledTaskbar
        left={<Tool type="close" onClick={close} />}
        right={
          <DeleteButton onClick={() => deleteAndClose()}>
            {t('l.profile.edit.delete_verification_document.button', 'Delete')}
          </DeleteButton>
        }
      >
        {t(
          'l.profile.edit.view_verification_document.title',
          'Verification Document'
        )}
      </StyledTaskbar>
      <Document src={src} alt="Verification Document" />
    </Wrapper>
  )
}

export const showDocument = createModal(ViewDocumentModal)
