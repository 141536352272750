import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { select } from 'store'
import moment from 'moment'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import { getImageUrl } from 'utils/image'
import useMediaQuery from 'hooks/use-media-query'
import { show as showEndTenancyModal } from 'features/EndTenancy/end-tenancy-modal'
import { Avatar } from 'components/avatar'
import { MobileDrawer } from 'components/drawer'
import tenancyIcon from './tenancy-icon.svg'

const Wrapper = styled.div`
  width: 290px;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid ${colors.grey};
  border-radius: 6px;
  background: ${colors.white};
  grid-column: 2;

  @media screen and (max-width: ${breakpoints.desktop}) {
    width: 100%;
    grid-column: auto;
  }
`

const TopColor = styled.div`
  border-radius: 6px 6px 0 0;
  background: ${colors.secondary};
  height: 8px;

  @media screen and (max-width: ${breakpoints.desktop}) {
    display: none;
  }
`


const InnerWrapper = styled.div`
  padding: 10px 25px 35px;
`

const Tenants = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${colors.grey};
`

const Tenant = styled.div`
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`

const Name = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;
`

const TenancyInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const TenancyDates = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const TenancyIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`

const DateText = styled.p`
  color: ${colors.regular};
  font-size: 15px;
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;
`

const EndTenancyButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.33px;
  line-height: 18px;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
`

const TenantInfo = ({ tenants, contract }) => {
  const { t } = useTranslation()
  const showCollapsible = useMediaQuery(`(max-width: ${breakpoints.desktop})`)
  const [isOpen, setOpen] = useState(showCollapsible ? false : true)
  const [height, setHeight] = useState(0)
  const contentRef = useRef(null)

  useEffect(() => {
    setHeight(contentRef.current.clientHeight)
  }, [tenants])

  const endTenancyRequestData = useSelector(state =>
    select.endTenancy.endTenancyRequestData(state, {
      listingId: contract.listing_id
    })
  )

  const endTenancyActionText = () => {
    const incompleteText = t(
      'l.tenants.view.end_this_tenancy.button',
      'End this tenancy'
    )
    const activeText = t(
      'l.tenants.view.end_tenancy_requested.button',
      'End tenancy requested'
    )
    const completeText = t(
      'l.tenants.view.end_tenancy_completed.button',
      'End tenancy completed'
    )

    if (
      endTenancyRequestData?.end_response === 'declined' ||
      endTenancyRequestData?.end_response === 'support_requested'
    )
      return incompleteText
    const states = { done: completeText, active: activeText }
    return states[endTenancyRequestData?.state_machine] || incompleteText
  }

  const startDate = moment(contract.start_date).format('MMM DD, YYYY')
  const endDate = contract.end_date
    ? moment(contract.end_date).format('MMM DD, YYYY')
    : t('b.components.view.not_avaiable.text', 'N/A')

  return (
    <Wrapper>
      <TopColor />
      <MobileDrawer
        isOpen={isOpen}
        setOpen={setOpen}
        height={height}
        title={t('l.tenants.view.tenant_info.title', 'Tenant Info')}
        id="tenant-info-content"
      >
        <InnerWrapper ref={contentRef}>
          <Tenants>
            {tenants.map(tenant => (
              <Tenant key={`tenant-${tenant.id}`}>
                <Avatar
                  location={getImageUrl(tenant.avatar?.aws_s3_key, {
                    width: 80,
                    height: 80
                  })}
                  width={40}
                  height={40}
                  right={10}
                  shadow
                />
                <Name>{`${tenant.first_name} ${tenant.last_name}`}</Name>
              </Tenant>
            ))}
          </Tenants>
          <TenancyInfo>
            <TenancyDates>
              <TenancyIcon src={tenancyIcon} alt="" />
              <DateText>{`${startDate} - ${endDate}`}</DateText>
            </TenancyDates>
            <EndTenancyButton
              onClick={() =>
                showEndTenancyModal({ listingId: contract.listing_id })
              }
            >
              {endTenancyActionText()}
            </EndTenancyButton>
          </TenancyInfo>
        </InnerWrapper>
      </MobileDrawer>
    </Wrapper>
  )
}

export default TenantInfo
