import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { isEmpty, propEq, pathOr, applySpec, pipe } from 'ramda'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { colors, breakpoints, fontSizes, fontWeights, structure } from 'styles'
import { toNumber, toInteger, round } from 'lodash'
import toBoolean from 'utils/to-boolean'
import { toUnit } from 'utils/styles'
import VerifiedBadge from 'components/verified-badge'
import Breadcrumbs from 'components/breadcrumbs'
import { EditButton, ShareButton } from 'features/Listing/buttons'
import { leftCloud, rightCloud, rightCloudSmall } from 'components/clouds'
import ListingTypeText from 'components/listing-type-text'
import { useQuery, gql } from '@apollo/client'
import ListingInfo from 'features/Listing/listing-info'

import ListingAnalytics from './listing-analytics'
import HideListingToggle from './hide-listing-toggle'
import ListingMenuCards from './listing-menu-cards'

import backgroundBottom from './images/background-bottom.png'
import backgroundBottomMobile from './images/background-bottom-mobile.png'

const maxWidth = 950
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 50px 220px 100px;
  background: ${colors.white};
  min-height: calc(100vh - ${toUnit(structure.header.height)});

  background-image: url(${leftCloud}), url(${rightCloud}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -60px 475px, 92% 70px, 100% 95%;
  background-size: 245px, 230px, 566px;

  section:not(:last-child) {
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding-bottom: 150px;

    background-image: url(${leftCloud}), url(${rightCloudSmall}),
      url(${backgroundBottom});
    background-position: -90px 475px, 100% 60px, 100% 97%;
    background-size: 245px, 100px, 350px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 0 40px 150px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 0 25px 130px;

    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
    background-image: url(${leftCloud}), url(${rightCloudSmall}),
      url(${backgroundBottomMobile});
    background-position: -90px 465px, 100% 70px, 100% 98%;
    background-size: 238px, 90px, 375px;

    section:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 0 15px 130px;
  }
`

const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  max-width: ${maxWidth}px;
`

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 12px;
  }
`

const Badges = styled.div`
  display: flex;
`

const Badge = styled.div`
  background: ${colors.mediumGrey};
  border-radius: 2px;
  padding: 6px 20px;
  width: fit-content;
  color: ${colors.white};
  font-size: 14px;
  line-height: 19px;
  font-weight: ${fontWeights.medium};
  margin-bottom: 10px;

  :not(:last-child) {
    margin-right: 10px;
  }
`

const AddressSection = styled.section`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SectionHeading = styled.h1`
  font-size: 24px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 30px;
  margin: 0;
`

const ListingLinks = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 15px;
  }
`

const ListingLink = styled(Link)`
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.33px;
  line-height: 27px;
  text-decoration: underline;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid ${colors.mediumLightGrey};

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    :first-child {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  }
`

const DashboardGrid = styled.div`
  max-width: ${maxWidth}px;
  display: grid;
  grid-gap: 40px;
  justify-content: start;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 290px);


  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    grid-template-rows: 60px auto;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    grid-template-rows: auto;
    grid-gap: 20px 25px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const HeaderSpan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    flex-direction: column;
    align-items: flex-start;
    > div:first-of-type {
      margin-bottom: 10px;
    }
  }
`

const ListingSummary = styled.div`
  display: flex;
  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    flex-direction: column;
  }
`

const AVG_RENTAL = gql`
  query avgRentalPrice($listingId: Int) {
    listingDetail(id: $listingId) {
      metadata {
        avg_price
        difference
      }
    }
  }
`

const isVerified = propEq('verified_state_machine', 'approved')
const isPending = propEq('state_machine', 'pending')
const isPublished = propEq('state_machine', 'published')

const ListingDashboardHome = ({
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { data: avgPrice } = useQuery(AVG_RENTAL, {
    variables: {
      listingId: toInteger(listingId)
    }
  })
  const priceInfo = applySpec({
    difference: ({ difference }) => {
      const MAX = 999
      return pipe(
        Math.abs,
        diff => !isNaN(diff) ? diff : 0,
        diff => round(diff.toPrecision(4), 2),
        diff => diff > MAX ? `+${MAX}` : diff
      )(difference)
    },
    average: ({ avg_price }) => round(avg_price) || '',
    aboveAverage: ({ difference }) => difference > 0
  })(
    pathOr({}, ['listingDetail', 'metadata'], avgPrice)
  )
  const data =
    useSelector(state =>
      select.listing.listingDetail(state, {
        listingId: listingId
      })
    ) || {}
  const { building, listings, unit } = data
  const applications = useSelector(select.listing.applications)
  const timelines = useSelector(state =>
    select.chat.listingTimelines(state, { listingId })
  )

  useEffect(() => {
    dispatch.listing.load(listingId)
  }, [dispatch.listing, listingId])

  useEffect(() => {
    dispatch.listing.getApplications(listingId)
  }, [dispatch.listing, listingId])

  useEffect(() => {
    dispatch.listing.getListingTimelines(listingId)
  }, [dispatch.listing, listingId])

  if (isEmpty(data) || !listings) {
    return null
  }

  const unitType = unit.unit_type_txt_id
  const unitScope = unit.unit_type_scope_txt_id
  const listingVerified = isVerified(listings)
  const listingPending = isPending(listings)
  const listingPublished = isPublished(listings)
  const listingHidden = toBoolean(listings.is_hidden)
  const listingAddress =
    (unit.unit_number ? `${unit.unit_number} - ` : '') +
    building.full_street_name
  const viewCount = toNumber(listings.view_count)
  const unreadCount = timelines.reduce((count, timeline) => {
    const { last_read, last_event } = timeline
    if (!last_read || moment(last_event).isAfter(last_read)) {
      count++
    }
    return count
  }, 0)
  const applicationCount = applications.length

  const breadcrumbs = [
    {
      to: '/listings',
      label: t('l.listing.view.all_listings.link', 'All Listings')
    },
    { to: null, label: t('l.listing.view.dashboard.link', 'Dashboard') }
  ]

  return (
    <Wrapper>
      <Helmet>
        <title>{listingAddress}</title>
      </Helmet>
      <DashboardHeader>
        <Breadcrumbs items={breadcrumbs} />
        <HeaderButtons>
          <EditButton listingId={listingId} />
          {listingPublished && <ShareButton listingId={listingId} />}
        </HeaderButtons>
      </DashboardHeader>
      <Badges>
        {listingPending && (
          <Badge>{t('l.listing.view.pending.text', 'Pending')}</Badge>
        )}
        {listingHidden && (
          <Badge>{t('l.listing.view.hidden.text', 'Hidden')}</Badge>
        )}
      </Badges>
      <AddressSection>
        <SectionHeading>
          {listingAddress}
          <VerifiedBadge
            verified={listingVerified}
            type="listing"
            width={30}
            height={30}
            left={5}
            bottom={-6}
          />
        </SectionHeading>
        <ListingLinks>
          {!listingVerified && (
            <ListingLink to={`/listing/verify/${listingId}`}>
              {t(
                'l.listing.view.verify_this_listing.link',
                'Verify this listing'
              )}
            </ListingLink>
          )}
          <ListingLink to={`/listing/${listingId}/details`}>
            {t('l.listing.view.view_details.link', 'View details')}
          </ListingLink>
        </ListingLinks>
      </AddressSection>
      <DashboardGrid>
        <HeaderSpan>
          <ListingSummary>
            <ListingInfo listingId={listings.id} hideImage hideAddress>
              <ListingTypeText unitType={unitType} unitScope={unitScope} />
            </ListingInfo>
          </ListingSummary>
          <HideListingToggle listingId={listings.id} isHidden={listingHidden} />
        </HeaderSpan>
        <ListingAnalytics
          applicationCount={applicationCount}
          viewCount={viewCount}
          priceInfo={priceInfo}
        />
        <ListingMenuCards
          listingId={listingId}
          building={building}
          unreadCount={unreadCount}
          applicationCount={applicationCount}
          listingPending={listingPending}
        />
      </DashboardGrid>
    </Wrapper>
  )
}

export default ListingDashboardHome
