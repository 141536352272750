import React from 'react'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

const AddendumTitle = ({ addendumId, className }) => {
  const addendumTemplate = useSelector(
    path(['contract', 'listing_contract_addendum_templates', addendumId])
  )
  if (!addendumTemplate) return null

  return <p className={className}>{addendumTemplate.title}</p>
}

AddendumTitle.defaultProps = {
  title: null,
  className: null
}

export default AddendumTitle
