import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import Page from 'components/page'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Helmet } from 'react-helmet'
import { filter, propEq, concat, complement, isEmpty, any, not } from 'ramda'
import { Formik, Form } from 'formik'
import toInteger from 'utils/to-integer'
import useMediaQuery from 'hooks/use-media-query'
import ButtonBar from 'features/Profile/button-bar'
import LandlordManagement from 'features/Listing/landlord-management'
import Panel from 'components/panel'
import AreaTitle from 'components/area-title'
import PageTitle from 'components/page-title'
import { Button, ImageButton } from 'components/button'
import { SwitchToggle, FieldButtonPicker } from 'components/form'
import VerifiedBadge from 'components/verified-badge'
import { defaultAvatar } from 'components/avatar'
import ContentWrapper from 'components/content-wrapper'

import ChatWhite from './ChatWhite.svg'

const DisclaimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

const Disclaimer = styled.p`
  color: ${colors.darkGrey};
  text-align: center;

  span {
    font-weight: ${fontWeights.medium};
  }
`

const VerifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  margin: 0 auto 20px;
`

const VerifiedDiagram = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  margin-bottom: 5px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-left: 10px;
  }
`

const VerifiedCaptions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
  padding-left: 18px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-right: 10px;
    padding-left: 0;
  }
`

const ChatAvatarContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.mediumGrey};
  flex-shrink: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 45px;
    height: 45px;
  }
`

const ChatAvatar = styled.img`
  height: 27.02px;
  width: 27.02px;
  background-color: ${colors.mediumGrey};

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 22px;
    height: 22px;
  }
`

const BlankLandlordAvatar = styled.img`
  height: 60px;
  width: 60px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.4);
  border-radius: 50%;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 45px;
    height: 45px;
  }
`

const RentersAvatarContainer = styled.div`
  width: 120px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 95px;
  }
`

const RenterAvatar1 = styled(BlankLandlordAvatar)`
  position: absolute;
  z-index: 2;
  background-color: ${colors.white};
`

const RenterAvatar2 = styled(RenterAvatar1)`
  transform: translateX(50%);
  z-index: 1;
`

const RenterAvatar3 = styled(RenterAvatar1)`
  transform: translateX(100%);
  z-index: 0;
`

const VerifiedText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  margin-right: ${props => props.right || 0}px;
  margin-left: ${props => props.left || 0}px;
`

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 75px;
  margin: 0 10px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 5px;

    div:last-child {
      display: none;
    }
  }
`

const Dot = styled.div`
  border-radius: 50%;
  background-color: #fe5f55;
  width: 5px;
  height: 5px;
`

const SwitchToggleContainer = styled.div`
  padding: 0 0 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin-right: 5px;
  }
`

const ProfileCompletionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const RequiredText = styled.span`
  color: ${colors.darkGrey};
  font-family: 'Open Sans';
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 21px;
  margin-right: 10px;

  strong {
    font-weight: ${fontWeights.medium};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 25px;
    margin-right: 0;
  }
`

const StyledImageButton = styled(ImageButton)`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100px;
`

const StyledFieldButtonPicker = styled(FieldButtonPicker)`
  display: flex;
  align-items: center;
`

const ShowPercentage = ({ show, minProfileScore, setMinProfileScore }) => {
  const { t } = useTranslation()
  const formOptions = () => ({
    initialValues: {
      type: minProfileScore,
      profile_completion: minProfileScore
    }
  })

  const onTypeChange = ({ setFieldValue }) => type => {
    if (type === '50') {
      setFieldValue('profile_completion', '50')
      setMinProfileScore('50')
    } else if (type === '80') {
      setFieldValue('profile_completion', '80')
      setMinProfileScore('80')
    } else {
      setFieldValue('profile_completion', '100')
      setMinProfileScore('100')
    }
  }

  return show ? (
    <ProfileCompletionContainer>
      <RequiredText>
        <Trans i18nKey="l.listing.edit.profile_completion.label">
          <strong>Required renter profile completion</strong> to initiate chat
          with you
        </Trans>
        :
      </RequiredText>
      <Formik {...formOptions()}>
        {({ setFieldValue }) => (
          <Form>
            <Panel hSpace={0} vSpace={0}>
              <StyledFieldButtonPicker
                name="type"
                onChange={onTypeChange({ setFieldValue })}
              >
                <StyledImageButton value="50">
                  {t('l.listing.edit.profile_50_percent.text', '50%')}
                </StyledImageButton>
                <StyledImageButton value="80">
                  {t('l.listing.edit.profile_80_percent.text', '80%')}
                </StyledImageButton>
                <StyledImageButton value="100">
                  {t('l.listing.edit.profile_100_percent.text', '100%')}
                </StyledImageButton>
              </StyledFieldButtonPicker>
            </Panel>
          </Form>
        )}
      </Formik>
    </ProfileCompletionContainer>
  ) : null
}

const isPrimary = propEq('primary', true)
const isHidden = propEq('is_hidden', '1')
const findPrimaryLandlord = filter(isPrimary)
const findSecondaryLandlords = filter(complement(isPrimary))

const Settings = ({ data, handleBack, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [minProfileScore, setMinProfileScore] = useState(data.min_profile_score)
  const hasMinimum = !isEmpty(minProfileScore)
  const userId = useSelector(state => state.profile?.id)
  const [primaryLandlord, setPrimaryLandlord] = useState(
    findPrimaryLandlord(data.landlords)
  )
  const [secondaryLandlords, setSecondaryLandlords] = useState(
    findSecondaryLandlords(data.landlords)
  )
  const [hidden, setHidden] = useState(isHidden(data))
  useEffect(() => {
    if (!data) return
    setPrimaryLandlord(findPrimaryLandlord(data.landlords))
    setSecondaryLandlords(findSecondaryLandlords(data.landlords))
  }, [data])

  const toggleHidden = () => setHidden(!hidden)

  const toggleMinProfileScore = () =>
    hasMinimum ? setMinProfileScore('') : setMinProfileScore('50')

  const handleSave = async () => {
    const landlordData = concat(primaryLandlord, secondaryLandlords)
    const profileScore = minProfileScore ? toInteger(minProfileScore) : null
    const formattedData = {
      primary_user_id: toInteger(findPrimaryLandlord(landlordData)[0].id),
      listing_landlords: landlordData.map(landlord => toInteger(landlord.id)),
      listing_id: data.listing_id,
      min_profile_score: profileScore,
      is_hidden: hidden ? 1 : 0
    }
    await dispatch.listing.update(formattedData)

    const removedSelf = not(any(propEq('id', userId), landlordData))

    history.replace(removedSelf ? '/listings' : `/listing/${data.listing_id}`)
  }

  const statusText = hidden
    ? t(
        'l.listing.edit.listing_status_hidden.text',
        'This listing cannot be searched by renters'
      )
    : t(
        'l.listing.edit.listing_status_published.text',
        'This listing can be searched by renters'
      )

  if (!data) return null

  return (
    <Page>
      <ContentWrapper className="mb-50">
        <Helmet>
          <title>
            {t('l.listing.edit.edit_listing.title', 'Edit Listing')}
          </title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <PageTitle>{data.building.full_street_name}</PageTitle>
        <AreaTitle>
          {t('l.listing.edit.listing_status.subtitle', 'Listing Status')}
        </AreaTitle>
        <Panel vSpace={30} hSpace={30}>
          <SwitchToggleContainer>
            <p>{t('l.listing.edit.settings.published.label', 'Published')}</p>
            <SwitchToggle enabled={!hidden} onChange={toggleHidden} />
          </SwitchToggleContainer>
          <Disclaimer>{statusText}</Disclaimer>
        </Panel>
        <LandlordManagement
          primaryLandlord={primaryLandlord}
          setPrimaryLandlord={setPrimaryLandlord}
          secondaryLandlords={secondaryLandlords}
          setSecondaryLandlords={setSecondaryLandlords}
        />
        <AreaTitle>
          {t('l.listing.edit.chat_management.subtitle', 'Chat Management')}
        </AreaTitle>
        <Panel vSpace={30} hSpace={30}>
          <DisclaimerContainer>
            <Disclaimer>
              <Trans i18nKey="l.listing.edit.chat_inquiries_description.text">
                Inquiries with your listings allowed when renters have a{' '}
                <span>Verified Profile.</span>
              </Trans>
              <VerifiedBadge
                verified
                type="id"
                width={30}
                height={20}
                left={5}
                bottom={-5}
              />
            </Disclaimer>
          </DisclaimerContainer>
          <VerifiedWrapper>
            <VerifiedDiagram>
              <BlankLandlordAvatar src={defaultAvatar} />
              <DotContainer>
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
              </DotContainer>
              <ChatAvatarContainer>
                <ChatAvatar src={ChatWhite} alt="" />
              </ChatAvatarContainer>
              <DotContainer>
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
              </DotContainer>
              <RentersAvatarContainer>
                <RenterAvatar1 src={defaultAvatar} />
                <RenterAvatar2 src={defaultAvatar} />
                <RenterAvatar3 src={defaultAvatar} />
              </RentersAvatarContainer>
            </VerifiedDiagram>
            <VerifiedCaptions>
              <VerifiedText>
                {t('l.listing.edit.landlord.text', 'Landlord')}
                <VerifiedBadge
                  verified
                  width={24}
                  height={18}
                  left={5}
                  bottom={-2}
                />
              </VerifiedText>
              <VerifiedText>
                {t('l.listing.edit.renters.text', 'Renters')}
                <VerifiedBadge
                  verified
                  width={24}
                  height={18}
                  left={5}
                  bottom={-2}
                />
              </VerifiedText>
            </VerifiedCaptions>
          </VerifiedWrapper>
          <SwitchToggleContainer>
            <p>
              {t(
                'l.listing.edit.settings.additional_chat_requirement',
                'Additional chat requirement'
              )}
            </p>
            <SwitchToggle
              enabled={hasMinimum}
              onChange={toggleMinProfileScore}
            />
          </SwitchToggleContainer>
          <ShowPercentage
            show={hasMinimum}
            minProfileScore={minProfileScore}
            setMinProfileScore={setMinProfileScore}
          />
        </Panel>
      </ContentWrapper>
      <ButtonBar handleBack={handleBack} sticky>
        <Button
          type="submit"
          theme="primary"
          width={150}
          left={displayMobile ? 10 : 'auto'}
          disabled={!primaryLandlord.length}
          onClick={handleSave}
        >
          {t('l.listing.edit.update.button', 'Update')}
        </Button>
      </ButtonBar>
    </Page>
  )
}

export default withRouter(Settings)
