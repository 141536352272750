import React, { useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter } from 'react-router'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import Page from 'components/page'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import ButtonBar from 'features/Profile/button-bar'
import { Button } from 'components/button'
import PageTitle from 'components/page-title'
import {
  Label,
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldDatePicker
} from 'components/form'
import { getFormattedStates } from 'utils/address'
import Panel from 'components/panel'
import useProfile from 'hooks/use-profile'
import config from 'config'

const { WEB_URL } = config

const Subtitle = styled.h3`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
  margin-top: ${props => props.top || 0}px;
  margin-bottom: 20px;
`

const PanelContainer = styled(Panel)`
  margin-top: 60px;
  > :last-child {
    padding-bottom: 0px;
    border-bottom-width: 0px;
  }
`

const LinkText = styled.a`
  color: ${colors.link};
`

const GroupStyled = styled(Group)`
  align-self: center;
  font-size: ${fontSizes.small};
`

const Description = styled.p`
  margin: 0 0 15px;
  font-size: ${fontSizes.small};
`

const CreditCheck = ({ history }) => {
  const profile = useProfile()
  const verified =
    profile?.liv_score?.credit_rating !== 'not enough' &&
    profile?.liv_score?.credit_rating !== undefined
  useEffect(() => {
    if (verified) {
      history.goBack()
    }
  }, [history, verified])
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { countries } = useBasic(['countries'])
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  if (!profile.id) return null

  const initialValues = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    dob: profile?.dob || '',
    sin: '',
    driver_license: '',
    suite: profile?.company_info?.unit_number || '',
    street_name: '',
    street_number: '',
    city: profile?.company_info?.city || '',
    province: profile?.company_info?.state || '',
    zip: profile?.company_info?.zip || '',
    country: 'ca',
    terms: false,
    privacy: false
  }

  const validate = values => {
    let errors = {}
    if (values.sin?.length !== 9 && values.sin?.length > 0) {
      errors.sin = 'Sin number must be 9 digits'
    }
    if (!values.sin && !values.driver_license) {
      errors.sin = 'Please provide at least one identification document'
      errors.driver_license =
        'Please provide at least one identification document'
    }
    if (
      !/^(?!.*[DFIOQU])[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(
        values.zip
      )
    ) {
      errors.zip = 'Incorrect format (A1A 1A1)'
    }
    return errors
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(
        /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
        t(
          'b.profile.name.special_characters.error',
          'Cannot include special characters'
        )
      )
      .required(),
    last_name: Yup.string()
      .matches(
        /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
        t(
          'b.profile.name.special_characters.error',
          'Cannot include special characters'
        )
      )
      .required(),
    dob: Yup.string().required(),
    driver_license: Yup.string(),
    sin: Yup.string(),
    suite: Yup.string(),
    street_name: Yup.string().required(),
    street_number: Yup.string().required(),
    city: Yup.string().required(),
    province: Yup.string().required(),
    zip: Yup.string().required(),
    country: Yup.string().required(),
    privacy: Yup.bool().required(),
    terms: Yup.bool().required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    await dispatch.livScore.requestCreditCheck(values)
    setSubmitting(false)
  }

  const initialMonth = () => {
    return profile.dob ? moment(profile.dob) : moment().subtract(18, 'years')
  }

  return (
    <Formik
      validationSchema={validationSchema}
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="flex-full">
          <Page>
            <Helmet>
              <title>
                {t('r.profile.edit.credit_check.title', 'Credit Check')}
              </title>
            </Helmet>
            <PageTitle>
              {t('r.profile.edit.credit_check.title', 'Credit Check')}
            </PageTitle>
            <PanelContainer hSpace={30} vSpace={40}>
              <Subtitle>
                {t(
                  'r.profile.edit.credit_check_your_details.label',
                  'Your Details'
                )}
              </Subtitle>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldInput
                    name="first_name"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_first_middle_name.label',
                        'Legal First & Middle Name'
                      ) + '*:'
                    }
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="last_name"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_last_name.label',
                        'Legal Last Name'
                      ) + '*:'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldDatePicker
                    name="dob"
                    initialFocusedDate={initialMonth()}
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_birthday.label',
                        'Birthday'
                      ) + '*:'
                    }
                    minDate={moment().subtract(100, 'years')}
                    displayFormat="YYYY-MM-DD"
                    disableFuture
                  />{' '}
                </Group>
              </Row>
              <Subtitle top={30}>
                {t('r.profile.edit.identification.label', 'Identification')}
              </Subtitle>
              <Row top={10} gutter={20}>
                <Group col={12}>
                  <Description>
                    {t(
                      'r.profile.edit.complete_identification_documents.text',
                      'Provide at least one of these identification documents to get your Credit Check completed'
                    )}
                    :
                  </Description>
                </Group>
              </Row>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldInput
                    name="sin"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_sin_no.label',
                        'Social Insurance Number'
                      ) + ':'
                    }
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="driver_license"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_drivers_license_no.label',
                        `Driver's Licence No`
                      ) + ':'
                    }
                  />
                </Group>
              </Row>
              <Subtitle top={30}>
                {t('r.profile.edit.credit_check_address.label', 'Your Address')}
              </Subtitle>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldInput
                    name="suite"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_suite_no.label',
                        'Suite Number'
                      ) + ':'
                    }
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="street_number"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_street_no.label',
                        'Street Number'
                      ) + '*:'
                    }
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="street_name"
                    placeholder={
                      t(
                        'r.profile.edit.credit_street_name.label',
                        'Street Name'
                      ) + '*:'
                    }
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="city"
                    placeholder={
                      t('r.profile.edit.credit_check_city.label', 'City') + '*:'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldDropdown
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_province.label',
                        'Province'
                      ) + '*:'
                    }
                    options={getFormattedStates(countries, 'ca')}
                    isSearchable={false}
                    name="province"
                  />
                </Group>
                <Group col={colSize}>
                  <FieldInput
                    name="zip"
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_postal_code.label',
                        'Postal Code'
                      ) + '*:'
                    }
                  />
                </Group>
              </Row>
              <Row gutter={20}>
                <Group col={colSize}>
                  <FieldDropdown
                    placeholder={
                      t(
                        'r.profile.edit.credit_check_country.label',
                        'Country'
                      ) + ':'
                    }
                    options={[{ value: 'ca', label: 'Canada' }]}
                    name="country"
                    isDisabled
                  />
                </Group>
              </Row>
              <Row top={20} gutter={20}>
                <GroupStyled col={displayMobile ? 8 : 10}>
                  <Label htmlFor="privacy">
                    <Trans i18nKey="r.profile.edit.credit_check_read_policy.text">
                      I have read and agree to Liv{`'`}s{' '}
                      <LinkText href={`${WEB_URL}/privacy`} target="_blank">
                        Privacy Policy.
                      </LinkText>
                    </Trans>
                  </Label>
                </GroupStyled>
                <Group col={2}>
                  <FieldInput id="privacy" name="privacy" type="checkbox" />
                </Group>
              </Row>
              <Row gutter={20}>
                <GroupStyled col={displayMobile ? 8 : 10}>
                  <Label htmlFor="terms">
                    <Trans i18nKey="r.profile.edit.credit_check_read_terms.text">
                      I have read and agree to Liv{`'`}s{' '}
                      <LinkText href={`${WEB_URL}/terms`} target="_blank">
                        Terms & Conditions.
                      </LinkText>
                    </Trans>
                  </Label>
                </GroupStyled>
                <Group col={2}>
                  <FieldInput id="terms" name="terms" type="checkbox" />
                </Group>
              </Row>
              <Row top={10} gutter={20}>
                <Group col={12}>
                  <Label>
                    {t(
                      'r.profile.edit.credit_check_disclaimer.text',
                      'By clicking verify, I authorize the disclosure of this information by Liv to certain authorized consumer reporting agencies in order to request my credit history information to prepare my Liv Score.'
                    )}
                  </Label>
                </Group>
              </Row>
            </PanelContainer>
          </Page>
          <ButtonBar handleBack={history.goBack} sticky>
            <Button
              theme="primary"
              width={150}
              disabled={!values.terms || !values.privacy}
              left="auto"
              type="submit"
              isSubmitting={isSubmitting}
            >
              {t('r.profile.edit.credit_check_verify.button', 'Verify')}
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  )
}

export default withRouter(React.memo(CreditCheck))
