import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes } from 'styles'
import { Button } from 'components/button'
import PageTitle from 'components/page-title'
import ButtonBar from 'components/button-bar'

const StyledButton = styled(Button)`
  padding: 0px 15px;
  min-width: 150px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Description = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
`

const Invalid = ({ history }) => {
  const { t } = useTranslation()

  return (
    <>
      <PageTitle>
        {t('b.contract.view.invalid_contract.title', 'Invalid Contract')}
      </PageTitle>
      <Content>
        <Description>
          {t(
            'b.contract.view.invalid_contract_description.text',
            'This contract is no longer available.'
          )}
        </Description>
      </Content>
      <ButtonBar sticky>
        <StyledButton
          theme="sane"
          inversed
          disabled={false}
          onClick={history.goBack}
        >
          {t('b.contract.view.back.button', 'Back')}
        </StyledButton>
      </ButtonBar>
    </>
  )
}

export default withRouter(Invalid)
