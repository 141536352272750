import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import EmptyImage from './empty-image'

type Props = {
  text: string
}

const EmptyListContainer = styled.div`
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const EmptyListText = styled.p`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
`

const EmptyListComponent: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation()

  return (
    <EmptyListContainer>
      <EmptyImage
        alt={
          text ||
          t('b.contracts.view.nothing_here.text', 'Nothing here for now...')
        }
      />
      <EmptyListText>
        {text ||
          t('b.contracts.view.nothing_here.text', 'Nothing here for now...')}
      </EmptyListText>
    </EmptyListContainer>
  )
}

export default EmptyListComponent
