import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { createModal, useModalize } from 'components/modalize'
import { fontWeights } from 'styles'
import Icon from 'components/icon'
import ChequeEnglish from './cheque-english.png'
import ChequeChinese from './cheque-chinese.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 25px 40px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  padding-left: 5px;
`

const ModalText = styled.p`
  margin: 10px 0 40px;
  padding-left: 5px;
`

const ChequeImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
`

const FindNumbersModal = props => {
  const { t, i18n } = useTranslation()
  const { close } = useModalize()

  const localImg = i18n.language === 'zh' ? ChequeChinese : ChequeEnglish
  const titleText = t(
    'l.payment_settings.edit.find_financial_info.title',
    'Find your account info'
  )

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <ModalTitle>{titleText}</ModalTitle>
        <ModalText>
          {t(
            'l.payment_settings.edit.find_financial_info_description.text',
            `If you don't have a cheque, you can find the same information on Direct Deposit Form from your online banking.`
          )}
        </ModalText>
        <ChequeImage src={localImg} alt={titleText} />
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(FindNumbersModal)
