import React from 'react'
import styled from 'styled-components'
import { breakpoints, fontWeights } from 'styles'
import { useTranslation, Trans } from 'react-i18next'
import VerifiedBadge from 'components/verified-badge'
import ProfileRequired from 'components/profile-required'
import { showVerifyModal } from 'components/verify'
import { Label, Row } from 'components/form'
import { Button } from 'components/button'
import hasVerified from 'utils/has-verified'
import { PanelHeader, PanelTitle, StyledPanel, RequiredText } from '../elements'

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const StyledLabel = styled(Label)`
  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 12px;
  }
`

const VerifyIdentity = ({ data, showRequiredWarning = false }) => {
  const { t } = useTranslation()

  const PILL_THEME = {
    upload: 'primary',
    verify: 'primary',
    pending: 'primary',
    verified: 'neutral'
  }

  const PILL_NAME = {
    upload: t('b.profile.view.upload.text', 'Upload'),
    verify: t('b.profile.view.verify.text', 'Verify'),
    pending: t('b.profile.view.verifying.text', 'Verifying'),
    verified: t('b.profile.view.verified.text', 'Verified')
  }
  return (
    <>
      <PanelHeader>
        <PanelTitle>
          {t('r.profile.edit.verify_identity.subtitle', 'Verify Your Identity')}
          <ProfileRequired
            fields={['identity_verified_state']}
            area="liv_score"
          />
        </PanelTitle>
      </PanelHeader>
      {showRequiredWarning && (
        <RequiredText>
          {t('r.profile.view.required.label', '*Required')}
        </RequiredText>
      )}
      <StyledPanel hSpace={30} vSpace={30}>
        <StyledLabel>
          <Trans i18nKey="b.profile.edit.verify_id.description">
            Become <Bold>Verified</Bold>
            <VerifiedBadge
              type="id"
              verified
              width={28}
              height={19}
              right={5}
              bottom={-4}
              left={5}
            />
            by providing a government-issued photo ID. Rest assured that your ID
            will never be shared with other people on liv.rent.
          </Trans>
        </StyledLabel>
        <Row>
          <ActionButton
            name="license_pill"
            theme={
              PILL_THEME[data.identity_verified_state] || PILL_THEME.upload
            }
            width={125}
            left="auto"
            disabled={hasVerified(data.identity_verified_state)}
            onClick={() => {
              showVerifyModal({ data })
            }}
          >
            {PILL_NAME[data.identity_verified_state] || PILL_NAME.upload}
          </ActionButton>
        </Row>
      </StyledPanel>
    </>
  )
}

export default VerifyIdentity
