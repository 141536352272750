import React from 'react'
import styled from 'styled-components'
import { showImageModal } from 'components/image-modal'
import { colors } from 'styles'
import { getImageUrl } from 'utils/image'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  background-color: ${colors.secondary};
  > div {
    transition: 'all 0s ease-out 0s';
  }
`
const Image = styled.img`
  width: 230px;
  height: 300px;
  object-fit: cover;
`

const SupportImage = React.memo(({ event }) => {
  const image = getImageUrl(event.event_info.file_info.aws_s3_key, {
    width: 230,
    height: 300
  })

  return (
    <Wrapper
      onClick={() =>
        showImageModal({
          src: getImageUrl(event.event_info.file_info.aws_s3_key)
        })
      }
    >
      <Image src={image} />
    </Wrapper>
  )
})

export default SupportImage
