import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListingDetail } from 'pages/Listing'
import DashboardHeader from 'features/Tenant/dashboard-header'

const TenantListingDetail = props => {
  const { t } = useTranslation()

  return (
    <>
      <DashboardHeader
        listingId={props.match.params.listingId}
        border
        currentPage={t('l.tenant.view.view_details.link', 'View Details')}
      />
      <ListingDetail noTitle {...props} />
    </>
  )
}

export default TenantListingDetail
