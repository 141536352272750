import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import Icon from 'components/icon'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${props => props.padding || '0px'};
  height: ${props => props.height || '41px'};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey};
  width: ${props => (props.fullWidth ? '100%' : 'unset')};

  ${props =>
    props.borderless &&
    `
    border: none;
    width: 100%;
    justify-content: flex-start;
  `}
`

const TextInput = styled.input`
  width: ${props => (props.fullWidth ? '100%' : '210px')};
  height: 40px;
  padding: 0;
  font-size: 16px;
  border: none;
  background-color: ${colors.white};

  :focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.mediumGrey};
    opacity: 0.7;
  }

  ${props =>
    props.borderless &&
    `
    height: 100%;
    width: 100%;
  `}
`

const SearchIcon = styled(Icon)`
  margin-right: 12px;
  opacity: 0.5;
  flex-shrink: 0;
  cursor: pointer;
`

const SearchBar = ({
  placeholder,
  onSearch,
  padding,
  height,
  borderless,
  backgroundColor,
  fullWidth
}) => {
  const [searchString, setSearchString] = useState('')
  const handleSearch = e => setSearchString(e.target.value)

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onSearch(searchString)
    }
  }

  return (
    <Container
      borderless={borderless}
      padding={padding}
      height={height}
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
    >
      <SearchIcon
        onClick={() => {
          onSearch(searchString)
        }}
        id="search"
        height={18}
        width={18}
      />
      <TextInput
        borderless={borderless}
        value={searchString}
        onChange={handleSearch}
        type="text"
        placeholder={placeholder}
        onKeyPress={handleKeyPress}
        fullWidth={fullWidth}
      />
    </Container>
  )
}

export default SearchBar
