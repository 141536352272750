import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, transitions } from 'styles'
import CountryCodeSelect from 'components/country-code-select'
import { formatPhone, parsePhone } from 'utils/format-phone'
import { useFormikContext } from 'formik'
import noop from 'lodash/noop'
import useSafeCallback from 'hooks/use-safe-callback'
import useFieldFast from 'hooks/use-field-fast'
import Input from './input'
import Error from './error'

const Wrapper = styled.div`
  padding-top: 5px;
  display: flex;
  transition: border-color ${transitions.ease};
  border-bottom: 1px solid ${colors.mediumGrey};
  border-radius: 0;

  ${props =>
    props.focused &&
    `
    border-color: ${colors.darkBlue};
  `}

  ${props =>
    props.hasError &&
    `
    border-color: ${colors.red} !important;
  `}
`

const PhoneInputBox = styled(Input)`
  display: block;
  border-radius: 0px;
  border: none;
  flex: 1;
  padding: 0 10px;
`

const PhoneInput = ({
  countryCode,
  countryCodeAlpha,
  autoFocus = false,
  placeholder,
  disabled,
  showFlag = true,
  value: initialValue = '',
  onChange = noop,
  onCountryCodeChange = noop,
  name,
  ...rest
}) => {
  const formik = useFormikContext()
  const [field, meta, helpers] = useFieldFast(name)
  const { value, ...fields } = field
  const hasError = !!meta.error && meta.touched
  const focus = () => setFocus(true)
  const unfocus = () => {
    const touched = { ...formik.touched }
    touched[name] = true
    formik.setTouched(touched)
    setFocus(false)
  }
  const parsedPhone = parsePhone(initialValue || value)
  const [focused, setFocus] = useState(autoFocus)
  const [countryCodeState, setCountryCodeState] = useState(
    countryCode || {
      txt_id: countryCodeAlpha?.toLowerCase() || 'ca',
      calling_code: parsedPhone?.countryCode?.toString() || '1'
    }
  )
  const setCountryCode = code => {
    onCountryCodeChange(code)
    setCountryCodeState(code)
  }

  const [phoneState, setPhone] = useState(
    parsedPhone?.nationalNumber?.toString() || ''
  )

  const safeOnChange = useSafeCallback(onChange)

  useEffect(() => {
    const getFullPhoneNumber = () => {
      if (countryCodeState && phoneState) {
        const countryCodeBlock = countryCodeState.calling_code.replace(
          /[\W_]/g,
          ''
        )
        const phone = phoneState.replace(/[^\d]/g, '')
        return `+${countryCodeBlock}${phone}`
      }
      return null
    }
    let formattedValue = getFullPhoneNumber()
    if (formattedValue === null) formattedValue = ''
    safeOnChange({
      target: { value: formattedValue }
    })
    helpers.setValue(formattedValue)
  }, [phoneState, countryCodeState, safeOnChange, helpers])

  const getValue = () => {
    if (countryCodeState && phoneState.length) {
      try {
        return formatPhone(phoneState, countryCodeState.txt_id)
      } catch {
        return phoneState
      }
    }
    return phoneState
  }
  return (
    <>
      <Wrapper {...rest} focused={focused} hasError={hasError}>
        <CountryCodeSelect
          onChange={setCountryCode}
          value={countryCodeState}
          disabled={disabled}
          showFlag={showFlag}
          onBlur={unfocus}
          onFocus={focus}
        />
        <PhoneInputBox
          {...fields}
          type="tel"
          onBlur={unfocus}
          onFocus={focus}
          onChange={e => setPhone(e.target.value)}
          value={getValue()}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          data-testid="phone-input"
        />
      </Wrapper>
      <Error name={name} />
    </>
  )
}

export default PhoneInput
