import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useCurrentSession from 'hooks/use-current-session'
import Login from './login'
import ResetRequest from './reset-request'
import Reset from './reset'
import Signup from './signup'
import PhoneLogin from './phone-login'
import EmailLogin from './email'
import VerifyCodeSignup from './verify-code-signup'
import VerifyCodeLogin from './verify-code-login'
import Register from './register'
import CheckEmail from './check-email'
import Context from './context'

const Main = () => {
  const [codeRecipient, setCodeRecipient] = useState()
  const session = useCurrentSession()

  return (
    <Context.Provider value={{ setCodeRecipient, codeRecipient }}>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/login/reset" component={ResetRequest} />
        <Route exact path="/auth/signup" component={Signup} />
        <Route exact path="/auth/login/phone" component={PhoneLogin} />
        <Route exact path="/auth/login/email" component={EmailLogin} />
        <Route exact path="/auth/login/code" component={VerifyCodeLogin} />
        <Route exact path="/auth/signup/code" component={VerifyCodeSignup} />
        <Route
          exact
          path="/auth/register"
          render={props =>
            session ? (
              <Register {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location }
                }}
              />
            )
          }
        />
        <Route exact path="/reset_password/:hash" component={Reset} />
        <Route exact path="/auth/check-email" component={CheckEmail} />
        <Redirect to="/" />
      </Switch>
    </Context.Provider>
  )
}

export default Main
