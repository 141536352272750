import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'

const PageSubTitle = ({ children }) => {
  const [element, setElement] = useState()
  const el = document.getElementById('header-subtitle')

  useEffect(() => {
    setElement(el)
  }, [el])

  if (!element || !el) return null
  return ReactDOM.createPortal(
    children,
    document.getElementById('header-subtitle')
  )
}

export default PageSubTitle
