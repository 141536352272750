import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { withRouter } from 'react-router'
import usePrevious from 'hooks/use-previous'
const PARTIES_KEYS = {
  contract_signing_tenants: 'parties',
  contract_landlords: 'parties'
}

const TabResolver = ({ children, getUrl, history }) => {
  const formik = useFormikContext()
  const prevSubmitCount = usePrevious(formik.submitCount)
  const firstErrorKey = Object.keys(formik.errors)[0]

  useEffect(() => {
    if (prevSubmitCount !== formik.submitCount && !formik.isValid) {
      history.push(getUrl(PARTIES_KEYS[firstErrorKey] ? '' : 'terms'))
    }
  }, [
    firstErrorKey,
    formik.isValid,
    formik.submitCount,
    getUrl,
    history,
    prevSubmitCount
  ])
  return <>{children}</>
}

export default withRouter(TabResolver)
