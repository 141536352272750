import React, { useContext } from 'react'
import Context from './context'

const AccordionHeader = ({ children }) => {
  const value = useContext(Context)
  return React.Children.map(children, child =>
    React.cloneElement(child, {
      onClick: value.toggle,
      active: value.active
    })
  )
}

export default AccordionHeader
