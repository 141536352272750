import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, structure } from 'styles'
import { toUnit } from 'utils/styles'
import useMediaQuery from 'hooks/use-media-query'
import SearchBar from 'components/search-bar'

const PageHeader = styled.div`
  top: ${toUnit(structure.header.height)};
  position: sticky;
  z-index: 20;
  background-color: ${colors.white};

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    box-shadow: 0 2px 8px 0 rgba(146, 146, 146, 0.69);
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${toUnit(structure.header.heightMobile)};
  }
`

const PageHeaderInner = styled.div`
  height: 117px;
  max-width: 1130px;
  margin: 0 100px;
  padding: 40px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    height: 87px;
    margin: 0 25px;
    padding: 20px 0;
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: center;
  }
`

const HeaderTitle = styled.h1`
  font-size: 30px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 41px;
  margin: 0;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const SearchInner = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
    max-width: 325px;
  }
`

const MyRentalsHeader = ({ onSearch }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  return (
    <PageHeader>
      <PageHeaderInner>
        <HeaderTitle>
          {t('r.rentals.list.rentals.title', 'Rentals')}
        </HeaderTitle>
        <SearchInner>
          <SearchBar
            placeholder={t('common.search', 'Search')}
            onSearch={onSearch}
            fullWidth={displayMobile}
          />
        </SearchInner>
      </PageHeaderInner>
    </PageHeader>
  )
}

export default MyRentalsHeader
