import React, { useEffect } from 'react'
import styled from 'styled-components'
import useBasic from 'hooks/use-basic'
import { colors, fontSizes } from 'styles'
import toArray from 'utils/to-array'
import Flag from 'react-country-flag'
import { show as showCountrySelectModal } from './country-select-modal'

const CountrySelect = styled.button.attrs({ type: 'button' })`
  display: flex;
  flex-direction: row;
  flex: 0 1 ${({ showFlag }) => (showFlag ? '110px' : 'auto')};
  justify-content: center;
  align-items: center;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  background: none;
  min-width: ${({ showFlag }) => (showFlag ? '102px' : '70px')};
  border: none;
  padding: 0;
  height: 50px;
  outline: none;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`
const flagStyle = {
  marginRight: '5px',
  width: '32px',
  height: '32px',
  backgroundSize: 'contain'
}

const CountryCodeSelect = ({
  value,
  onChange,
  disabled,
  showFlag = true,
  ...rest
}) => {
  const { countries } = useBasic(['countries'])

  useEffect(() => {
    if (!value) {
      onChange({
        target: {
          value: {
            txt_id: 'ca',
            calling_code: '1'
          }
        }
      })
    }
  }, [onChange, value])

  const handleChangeCountryCode = () => {
    showCountrySelectModal({
      countries: toArray(countries),
      onSelect: onChange,
      currentValue: value.txt_id
    })
  }

  return (
    <CountrySelect
      onClick={handleChangeCountryCode}
      disabled={disabled}
      showFlag={showFlag}
      {...rest}
    >
      {showFlag && <Flag code={value?.txt_id} styleProps={flagStyle} svg />}
      {value?.txt_id?.toUpperCase()} +{value.calling_code}
    </CountrySelect>
  )
}

export default React.memo(CountryCodeSelect)
