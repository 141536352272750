import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import ContentWrapper from 'components/content-wrapper'
import ProfileButtonBar from 'features/Profile/button-bar'
import ContractList from './contract-list'

const ContractHistory = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loadAllContracts = dispatch.contract.getContracts

  useEffect(() => {
    loadAllContracts()
  }, [loadAllContracts])

  const groupListingContracts = useSelector(state =>
    select.contract.groupListingContracts(state, { listingId })
  )

  return (
    <>
      <ContentWrapper className="mb-50">
        <ContractList
          contracts={groupListingContracts.signed}
          title={t(
            'l.rental_dashboard.edit.resources_upcoming_contract.title',
            'Upcoming Contract'
          )}
        />
        <ContractList
          contracts={groupListingContracts.active}
          title={t(
            'l.rental_dashboard.edit.resources_current_contract.title',
            'Current Contract'
          )}
        />
        <ContractList
          contracts={groupListingContracts.history}
          title={t(
            'l.rental_dashboard.edit.resources_past_contract.title',
            'Past Contract'
          )}
        />
      </ContentWrapper>
      <ProfileButtonBar handleBack={history.goBack} sticky />
    </>
  )
}

export default ContractHistory
