import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { values as ramdaValues, pipe, map } from 'ramda'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import useHasLinkedIn from 'hooks/use-has-linkedin'
import useBasic from 'hooks/use-basic'
import fileAsDataUrl from 'utils/file-as-data-url'
import { show as showNotificationModal } from 'components/notification-modal'
import { showImageModal } from 'components/image-modal'
import UploadButton from 'components/upload-button'
import { Label, Row, Group, FieldInput, FieldDropdown } from 'components/form'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import Icon from 'components/icon'
import { show as showImageEditModal } from 'components/image-edit-modal.js'
import { confirm } from 'components/dialog'
import config from 'config'
import getLabels from './labels'
import linkedinBadge from './linkedin-icon.png'
import LinkedInButton from './linked-in'

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

const WorkCase = () => <StyledIcon id="work_case" width={24} height={24} />

const AreaWrapper = styled.div``

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const StyledRow = styled(Row)`
  margin-top: 30px;
  align-items: flex-end;
`

const LinkedInBadge = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 8px;
`

const FieldLabel = styled.p`
  line-height: 20px;
  font-size: ${fontSizes.regular};
  margin: 4px 15px 8px 5px;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
`

const LinkedInWrapper = styled.div`
  display: flex;
  padding: 20px 0 0 10px;
`

const UploadDescriptionText = styled.h2`
  margin: 20px 0px 20px;
  font-size: ${fontSizes.regular};
`

const BoldText = styled.p`
  font-weight: ${fontWeights.medium};
  margin: 0px;
  font-size: ${fontSizes.regular};
  display: inline;
`

const UploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 1px solid ${colors.mediumGrey};
  height: 50px;
`

const ProvideText = styled.p`
  padding-left: 10px;
`

const StyledGroup = styled(Group)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
`

const PanelText = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  margin: 10px 0;
  padding-left: 15px;
`

const ViewButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
`

const StyledUploadButton = styled(UploadButton)`
  width: 100px;
  align-self: flex-end;
  background: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  padding-right: 0;

  :hover {
    color: ${colors.link};
    border: none;
  }
`

const DeleteButton = styled.button`
  color: ${colors.red};
  background-color: ${colors.white};
  border: none;
  margin-right: 10px;
  font-size: ${fontSizes.small};
  outline: none;
  cursor: pointer;
  :active {
    opacity: 0.5;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-right: 5px;
  }
`

const ViewButton = styled.p`
  padding: 8px 18px;
  margin: 0px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  min-width: 83px;
  border: 2px solid ${colors.regular};
  cursor: pointer;
  :hover {
    border-color: ${colors.secondary};
    color: ${colors.secondary};
  }
  :active {
    opacity: 0.5;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 8px 10px;
    min-width: 65px;
  }
`

const EmploymentForm = ({ values, onFileSelected, setFieldValue }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const hasLinkedIn = useHasLinkedIn()
  const { professions } = useBasic(['professions'])

  const LABELS = getLabels(t)

  const studentFile = values.school_verification
  const employmentFile = values.employment_verification
  const employmentDocType = values.employment_document_type.type

  const { BACKEND_SERVER } = config

  const handleDeleteClick = ({ fileId, tag }) => {
    return confirm(
      t('r.profile.edit.delete_document.title', 'Delete Document?'),
      t(
        'r.profile.edit.delete_document_confirmation.text',
        'Are you sure you want to delete this document?'
      )
    ).then(async () => {
      await dispatch.livScore.deleteFile({
        fileId,
        tag
      })
      setFieldValue(tag, '')
    })
  }

  const getProfessions = useCallback(() => {
    return pipe(
      ramdaValues,
      map(profession => ({
        value: profession.txt_id,
        label: profession.name
      }))
    )(professions)
  }, [professions])

  return (
    <>
      <AreaWrapper>
        <AreaTitle>
          {t(
            'r.profile.edit.employment_verification.subtitle',
            'Employment Verification'
          )}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <Row>
            <Group col={colSize}>
              <Group col={12}>
                <FieldDropdown
                  id="job_title"
                  name="job_title"
                  placeholder={t('r.profile.edit.i_am.label', 'I am..')}
                  onChange={() => {
                    if (employmentFile.id) {
                      setFieldValue('employment_verification', '')
                      setFieldValue('income_verification', '')
                      showNotificationModal({
                        type: 'success',
                        message: t(
                          'r.profile.edit.livscore.employment_and_income_reupload_required.notification',
                          'Please upload new employment and income verification documents since your employment information has changed.'
                        )
                      })
                    }
                  }}
                  options={getProfessions()}
                >
                  <WorkCase />
                </FieldDropdown>
              </Group>
              {values.job_title !== 'student' &&
                values.job_title !== 'unemployed' && (
                  <Group col={12}>
                    <FieldInput
                      name="company_name"
                      placeholder={t(
                        'r.profile.edit.company_name.label',
                        'Company Name'
                      )}
                      onChange={e => {
                        setFieldValue('company_name', e.target.value)
                        if (employmentFile.id) {
                          setFieldValue('employment_verification', '')
                          setFieldValue('income_verification', '')
                          showNotificationModal({
                            type: 'success',
                            message: t(
                              'r.profile.edit.livscore.employment_and_income_reupload_required.notification',
                              'Please upload new employment and income verification documents since your employment information has changed.'
                            )
                          })
                        }
                      }}
                    />
                  </Group>
                )}
              {values.job_title === 'student' && (
                <>
                  <Group col={12}>
                    <FieldInput
                      name="school_name"
                      placeholder={t(
                        'r.profile.edit.school_name.label',
                        'Which school did you go to?'
                      )}
                      onChange={e => {
                        setFieldValue('school_name', e.target.value)
                        if (studentFile.id) {
                          setFieldValue('school_verification', '')
                          showNotificationModal({
                            type: 'success',
                            message: t(
                              'r.profile.edit.livscore.school_reupload_required.notification',
                              'Please upload a new enrolment letter since your school information has changed.'
                            )
                          })
                        }
                      }}
                    />
                  </Group>
                  <Group col={12}>
                    <FieldInput
                      name="school_subject"
                      placeholder={t(
                        'r.profile.edit.what_did-you_study.label',
                        'What did you study?'
                      )}
                    />
                  </Group>
                  <Group col={12}>
                    <FieldInput
                      name="school_start_date"
                      placeholder={t(
                        'r.profile.edit.school_start_date.label',
                        'Which year did you start?'
                      )}
                    />
                  </Group>
                  <Group col={12}>
                    <FieldDropdown
                      name="school_side_working"
                      placeholder={t(
                        'r.profile.edit.school_side_working.label',
                        'Do you work on the side?'
                      )}
                      options={[
                        {
                          value: 0,
                          label: t(
                            'r.profile.edit.school_side_working_no.text',
                            'No'
                          )
                        },
                        {
                          value: 1,
                          label: t(
                            'r.profile.edit.school_side_working_yes.text',
                            'Yes'
                          )
                        }
                      ]}
                    />
                  </Group>
                </>
              )}
            </Group>
            <Group col={colSize}>
              <Label>
                <Bold>
                  {t(
                    'r.profile.edit.linkedin.text',
                    'Have a LinkedIn account?'
                  )}
                </Bold>
              </Label>
              <Label>
                {t(
                  'r.profile.edit.linkedin.description',
                  'Help us verify your identity and reassure your landlord about your background.'
                )}
              </Label>
              <LinkedInWrapper>
                <LinkedInBadge src={linkedinBadge} alt="" />
                <FieldLabel>
                  {t(
                    'r.profile.edit.linkedin_account.label',
                    'LinkedIn Account'
                  )}
                </FieldLabel>
                <LinkedInButton enabled={hasLinkedIn} />
              </LinkedInWrapper>
            </Group>
          </Row>
          {values.job_title !== 'student' && values.job_title !== 'unemployed' && (
            <>
              <UploadDescriptionText>
                <Trans i18nKey="r.profile.edit.employment_verification_upload_description.text">
                  Provide an additional doccument to verify your employment.
                  Acceptable doccuments include <BoldText>T4</BoldText>,{' '}
                  <BoldText>Business Card</BoldText>,{' '}
                  <BoldText>Job Offer Letter</BoldText>, and{' '}
                  <BoldText>Pay Slip</BoldText>. Your documents are only used
                  for verification and never shared with other people.
                </Trans>
              </UploadDescriptionText>

              {!employmentFile.id && (
                <StyledRow>
                  <Group col={colSize}>
                    <FieldDropdown
                      id="employment_document_type"
                      name="employment_document_type"
                      placeholder={
                        t(
                          'r.profile.edit.liv_score_upload_verified_id.label',
                          'Document Type'
                        ) + '*:'
                      }
                      options={[
                        {
                          label: t('r.profile.view.t4.text', 'T4'),
                          value: {
                            tag: 'employment_verification',
                            type: 't4'
                          }
                        },
                        {
                          label: t(
                            'r.profile.view.business_card.text',
                            'Business Card'
                          ),
                          value: {
                            tag: 'employment_verification',
                            type: 'business_card'
                          }
                        },
                        {
                          label: t(
                            'r.profile.view.job_offer_letter.text',
                            'Job Offer Letter'
                          ),
                          value: {
                            tag: 'employment_verification',
                            type: 'job_offer'
                          }
                        },
                        {
                          label: t('r.profile.view.pay_slip.text', 'Pay Slip'),
                          value: {
                            tag: 'employment_verification',
                            type: 'payslip'
                          }
                        }
                      ]}
                    />
                  </Group>
                  {employmentFile ? (
                    <Group col={colSize}>
                      <Label>&nbsp;</Label>
                      <ViewButtonWrapper>
                        <DeleteButton
                          type="button"
                          onClick={() =>
                            setFieldValue('employment_verification', '')
                          }
                        >
                          {t(
                            'r.profile.edit.credit_check_delete_doc.button',
                            'delete'
                          )}
                        </DeleteButton>
                        <ViewButton
                          onClick={() =>
                            showImageModal({
                              src: values?.employment_verification
                            })
                          }
                        >
                          {t(
                            'r.profile.edit.credit_check_view_doc.button',
                            'View'
                          )}
                        </ViewButton>
                      </ViewButtonWrapper>
                    </Group>
                  ) : (
                    <Group col={colSize}>
                      <Label>&nbsp;</Label>
                      <UploadWrapper>
                        <ProvideText>
                          {t(
                            'b.components.dropdown.photo_of_doc.placeholder',
                            'Photo of Document'
                          )}
                        </ProvideText>
                        <StyledUploadButton
                          id="employment_upload_input"
                          data-cy="upload_employment_document"
                          name="employment"
                          onDropAccepted={async files =>
                            showImageEditModal({
                              initialImageSrc: await fileAsDataUrl(files[0]),
                              onSave: imageDataUrl =>
                                onFileSelected(
                                  setFieldValue,
                                  imageDataUrl,
                                  'employment_verification'
                                )
                            })
                          }
                          disabled={!values?.employment_document_type?.tag}
                          floatError
                        />
                      </UploadWrapper>
                    </Group>
                  )}
                </StyledRow>
              )}
              {employmentFile.id && (
                <Row>
                  <StyledGroup col={colSize}>
                    <PanelText>
                      {
                        LABELS[
                          employmentFile.document_type || employmentDocType
                        ]
                      }
                    </PanelText>
                  </StyledGroup>
                  <Group col={colSize}>
                    <Label>&nbsp;</Label>
                    <ViewButtonWrapper>
                      <DeleteButton
                        type="button"
                        onClick={() =>
                          handleDeleteClick({
                            fileId: employmentFile.id,
                            tag: employmentFile.tag
                          })
                        }
                      >
                        {t(
                          'r.profile.edit.credit_check_delete_doc.button',
                          'delete'
                        )}
                      </DeleteButton>
                      <ViewButton
                        onClick={() =>
                          showImageModal({
                            src: `${BACKEND_SERVER}/secure/${employmentFile?.tag}/${employmentFile.id}/form.png`
                          })
                        }
                      >
                        {t(
                          'r.profile.edit.credit_check_view_doc.button',
                          'View'
                        )}
                      </ViewButton>
                    </ViewButtonWrapper>
                  </Group>
                </Row>
              )}
            </>
          )}
        </StyledPanel>
      </AreaWrapper>
      {values.job_title === 'student' && (
        <AreaWrapper>
          <AreaTitle>
            {t(
              'r.profile.edit.enrolment_verification.label',
              'Enrolment Verification'
            )}
          </AreaTitle>
          <StyledPanel hSpace={30} vSpace={30}>
            <Row>
              <UploadDescriptionText>
                <Trans i18nKey="r.profile.edit.liv_score_upload_student_letter_description.text">
                  Provide your <BoldText>School Enrolment Letter</BoldText> or a
                  valid <BoldText>Student ID</BoldText> to prove your student
                  status.
                </Trans>
              </UploadDescriptionText>
            </Row>
            {!studentFile.id && (
              <StyledRow>
                <Group col={colSize}>
                  <FieldDropdown
                    name="school_document_type"
                    placeholder={
                      t(
                        'r.profile.edit.liv_score_upload_verified_id.label',
                        'Document Type'
                      ) + '*:'
                    }
                    options={[
                      {
                        label: t('r.profile.view.school_id.text', 'School ID'),
                        value: {
                          tag: 'school_verification',
                          type: 'school_id'
                        }
                      },
                      {
                        label: t(
                          'r.profile.view.school_letter.text',
                          'Enrolment Letter'
                        ),
                        value: {
                          tag: 'school_verification',
                          type: 'school_letter'
                        }
                      }
                    ]}
                  />
                </Group>
                {studentFile ? (
                  <Group col={colSize}>
                    <Label>&nbsp;</Label>
                    <ViewButtonWrapper>
                      <DeleteButton
                        type="button"
                        onClick={() => setFieldValue('school_verification', '')}
                      >
                        {t(
                          'r.profile.edit.credit_check_delete_doc.button',
                          'delete'
                        )}
                      </DeleteButton>
                      <ViewButton
                        onClick={() =>
                          showImageModal({
                            src: values?.school_verification
                          })
                        }
                      >
                        {t(
                          'r.profile.edit.credit_check_view_doc.button',
                          'View'
                        )}
                      </ViewButton>
                    </ViewButtonWrapper>
                  </Group>
                ) : (
                  <Group col={colSize}>
                    <Label>&nbsp;</Label>
                    <UploadWrapper>
                      <ProvideText>
                        {t(
                          'b.components.dropdown.photo_of_doc.placeholder',
                          'Photo of Document'
                        )}
                      </ProvideText>
                      <StyledUploadButton
                        id="uplaod_school_document"
                        name="school"
                        onDropAccepted={async files =>
                          showImageEditModal({
                            initialImageSrc: await fileAsDataUrl(files[0]),
                            onSave: imageDataUrl =>
                              onFileSelected(
                                setFieldValue,
                                imageDataUrl,
                                'school_verification'
                              )
                          })
                        }
                        disabled={!values?.school_document_type?.tag}
                        floatError
                      />
                    </UploadWrapper>
                  </Group>
                )}
              </StyledRow>
            )}
            {studentFile.id && (
              <Row>
                <StyledGroup col={colSize}>
                  <PanelText>{LABELS[studentFile.document_type]}</PanelText>
                </StyledGroup>
                <Group col={colSize}>
                  <Label>&nbsp;</Label>
                  <ViewButtonWrapper>
                    <DeleteButton
                      type="button"
                      onClick={() =>
                        handleDeleteClick({
                          fileId: studentFile.id,
                          tag: studentFile.tag
                        })
                      }
                    >
                      {t(
                        'r.profile.edit.credit_check_delete_doc.button',
                        'delete'
                      )}
                    </DeleteButton>
                    <ViewButton
                      onClick={() =>
                        showImageModal({
                          src: `${BACKEND_SERVER}/secure/${studentFile?.tag}/${studentFile.id}/form.png`
                        })
                      }
                    >
                      {t('r.profile.edit.credit_check_view_doc.button', 'View')}
                    </ViewButton>
                  </ViewButtonWrapper>
                </Group>
              </Row>
            )}
          </StyledPanel>
        </AreaWrapper>
      )}
    </>
  )
}

export default EmploymentForm
