import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Wrapper = styled.div`
  background-color: ${colors.white};
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WhitePage = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

export default WhitePage
