import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { show as showVideoLightBox } from 'components/video-light-box'
import Icon from 'components/icon'

const VideoWrapper = styled.div`
  position: static;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`

const VideoButtonWrapper = styled.button`
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6fa;
  border-radius: 6px;
  border: none;
  color: ${colors.link};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 20px;
  margin: 10px 0;
  cursor: pointer;

  svg {
    margin-right: 5px;

    g {
      stroke: ${colors.link};
      path {
        fill: ${colors.link};
      }
    }
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    cursor: default;
  `}

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    border-radius: 0;
    margin: 0;
    height: 54px;
  }
`

const VideoProcessingNote = styled.p`
  font-size: 12px;
  color: ${colors.regular};
  text-align: center;
  line-height: 15px;
  margin: 0 0 20px;
`

const VideoButton = ({ unitVideos, showProcessingText }) => {
  const { t } = useTranslation()

  return (
    <VideoWrapper>
      <VideoButtonWrapper
        disabled={showProcessingText}
        onClick={() =>
          showVideoLightBox({
            videoLocation: unitVideos[0].location,
            controls: true,
            autoPlay: true
          })
        }
      >
        <Icon id="play_video" height={16} width={18} />
        {showProcessingText
          ? `${t('b.listing.edit.processing.text', 'Processing')}...`
          : t('b.listing.view.watch_video.button', 'Watch video')}
      </VideoButtonWrapper>
      {showProcessingText && (
        <VideoProcessingNote>
          {t(
            'b.listing.view.video_processing.text',
            'Recently uploaded videos may take up to 10 minutes to be processed and displayed'
          )}
        </VideoProcessingNote>
      )}
    </VideoWrapper>
  )
}

export default VideoButton
