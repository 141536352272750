import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from 'styles'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100px;
  margin-bottom: ${props => (props.condensed ? `0` : `30px`)};
`

const TitleWrapper = styled.div`
  width: 100%;
  border-bottom: ${props => props.border || `1px solid ${colors.grey}`};
  margin: ${props => (props.condensed ? `30px 0 0` : `30px 0`)};
`

const TitleText = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 30px;
`

const ListingSection = ({ condensed, title, border, children }) => (
  <SectionWrapper condensed={condensed}>
    <TitleWrapper condensed={condensed} border={border}>
      <TitleText>{title}</TitleText>
    </TitleWrapper>
    {children}
  </SectionWrapper>
)

ListingSection.defaultProps = {
  title: '',
  condensed: false
}

export default ListingSection
