import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { breakpoints, colors, fontSizes } from 'styles'
import { path } from 'ramda'
import { getFees } from 'models/payment/ports'
import useMediaQuery from 'hooks/use-media-query'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'
import { Label, Row, Group, FieldDropdown, FieldInput } from 'components/form'
import Icon from 'components/icon'
import CurrencyFormatter from 'components/currency-formatter'
import { show as showDiscountCodeModal } from './discount-code-modal'
import {
  StyledPanel,
  AreaTitle,
  StyledRow,
  StyledLabel,
  StyledValue,
  FeeInfoWrapper,
  Error
} from '../elements'
import CreditCardInput from '../credit-card-input'
import Context from '../context'

const AssuranceText = styled.p`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 20px 0;

  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 20px 10px;
  }
`

const StyledLink = styled(StyledLabel)`
  color: ${colors.link};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`

const FeeText = styled.p`
  margin: 0;
`

const FeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`

const FeeValue = styled(StyledValue)`
  margin-bottom: 25px;
`

const PaymentMethodForm = ({
  values,
  setFieldValue,
  setCardInfoValid,
  setCardMethodValid
}) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const {
    feesInfo,
    setFeesInfo,
    paymentData: { description, due_date, requested_amount, user_id }
  } = useContext(Context)

  const method = values.payment_method
  const discount = path([method, 'discount'], feesInfo)
  const discountAllowed = path([method, 'discount_allowed'], feesInfo)
  const hasPromoCode = !!values.promo_code
  const feeAmount = path([method, 'fee'], feesInfo)
  const totalAmount = path([method, 'charge'], feesInfo)
  const companyId = useSelector(
    path(['session', 'users', user_id, 'company_id'])
  )
  const acceptedPaymentMethods = useSelector(
    path(['session', 'companies', companyId, 'ach_connected'])
  )

  const paymentMethodOptions = acceptedPaymentMethods.map(option => {
    if (option === 'upop') {
      return {
        value: 'upop',
        label: t('r.payment.edit.payment_method_unionpay.label', 'UnionPay')
      }
    }
    if (option === 'stripe') {
      return {
        value: 'stripe',
        label: t(
          'r.payment.edit.payment_method_visa_master.label',
          'Visa/Mastercard/American Express'
        )
      }
    }
    return null
  })

  const getFeeText = () => {
    const { percentage_fee, fixed_fee } = path([method, 'ach_info'], feesInfo)
    return `${percentage_fee}%${
      fixed_fee > 0 ? ` + $${Number(fixed_fee).toFixed(2)}` : ''
    }`
  }

  const acceptedCards =
    values.payment_method === 'stripe'
      ? ['visa', 'mastercard', 'american-express']
      : ['unionpay', 'visa', 'mastercard', 'american-express']

  const cardMethodErrors = {
    stripe: t(
      'r.payment.edit.visa_mastercard_american_express_card.error',
      '** The credit card you have entered is not accepted. Please try again by using a Mastercard, Visa, or American Express.'
    ),
    upop: t(
      'r.payment.edit.unionpay_card.error',
      '** The credit card you have entered is not accepted. Please try again by using a UnionPay card.'
    )
  }

  const handleRemoveDiscount = async () => {
    const { body, response } = await getFees({
      body: {
        amount: requested_amount
      }
    })
    if (response.ok) {
      setFieldValue('promo_code', '')
      setFeesInfo(body)
    }
  }

  if (!feesInfo) return null

  return (
    <>
      <AreaTitle>
        {t('r.payment.edit.payment_method.subtitle', 'Payment Method')}
      </AreaTitle>
      <StyledPanel hSpace={30} vSpace={30}>
        <Row bottom={10}>
          <Group col={colSize}>
            <FieldDropdown
              id="payment_method"
              name="payment_method"
              options={paymentMethodOptions}
              placeholder={
                t(
                  'r.payment.edit.make_payment_select_payment_method.label',
                  'Select payment method'
                ) + '*:'
              }
            />
          </Group>
        </Row>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.payment_detail.label', 'Detail')}
          </StyledLabel>
          <StyledValue>{description}</StyledValue>
        </StyledRow>
        <StyledRow bottom={10}>
          <StyledLabel>
            {t('r.payment.edit.make_payment_due_date.label', 'Due date')}
          </StyledLabel>
          <StyledValue>{moment(due_date).format('MM-DD-YYYY')}</StyledValue>
        </StyledRow>
        <StyledRow bottom="0">
          <StyledLabel>
            {t(
              'r.payment.edit.make_payment_payment_amount.label',
              'Payment amount'
            )}
          </StyledLabel>
          <StyledValue>
            <CurrencyFormatter value={requested_amount} showCents />
          </StyledValue>
        </StyledRow>
        {!!values.payment_method && (
          <>
            {hasPromoCode ? (
              <>
                <StyledRow top={10}>
                  <StyledLabel>
                    {t('r.payment.edit.discount.label', 'Discount')}
                  </StyledLabel>
                  <StyledValue data-testid="discount-amount">
                    -<CurrencyFormatter value={discount} showCents />
                  </StyledValue>
                </StyledRow>
                <StyledRow bottom={10}>
                  <div>
                    <StyledLink
                      role="button"
                      onClick={handleRemoveDiscount}
                      as="a"
                    >
                      {t(
                        'r.payment.edit.remove_discount.button',
                        'Remove discount'
                      )}
                    </StyledLink>
                    {!discountAllowed && (
                      <Error role="alert">
                        {t(
                          'r.payment.edit.invalid_discount_code_for_method.error',
                          '** The discount code used is not valid with the selected payment method.'
                        )}
                      </Error>
                    )}
                  </div>
                </StyledRow>
              </>
            ) : (
              <StyledRow bottom={10}>
                <StyledLink
                  role="button"
                  as="a"
                  onClick={() =>
                    showDiscountCodeModal({
                      requested_amount,
                      setFieldValue,
                      setFeesInfo
                    })
                  }
                >
                  +{' '}
                  {t(
                    'r.payment.edit.add_discount_code.button',
                    'Add discount code'
                  )}
                </StyledLink>
              </StyledRow>
            )}
            <StyledRow bottom={10} paddingTop={15} borderTop>
              <StyledLabel>
                {t('r.payment.edit.subtotal.label', 'Subtotal')}
              </StyledLabel>
              <StyledValue data-testid="subtotal-amount">
                <CurrencyFormatter
                  value={requested_amount - (discount || 0)}
                  showCents
                />
              </StyledValue>
            </StyledRow>
            <StyledRow bottom={10}>
              <FeeWrapper>
                <FeeInfoWrapper>
                  <Label bottom="0" noGutter={displayMobile}>
                    {t(
                      'r.payment.edit.make_payment_processing_fees.text',
                      'Processing fees'
                    )}
                  </Label>
                  <Icon
                    id="info_blue"
                    width={16}
                    height={16}
                    onClick={showProcessingFeesModal}
                  />
                </FeeInfoWrapper>
                <FeeText data-testid="fee-text">({getFeeText()})</FeeText>
              </FeeWrapper>
              <FeeValue data-testid="fee-amount">
                <CurrencyFormatter value={feeAmount} showCents />
              </FeeValue>
            </StyledRow>
            <StyledRow paddingTop={20} borderTop>
              <StyledLabel bold>
                {t('r.payment.edit.total.label', 'Total')}
              </StyledLabel>
              <StyledValue data-testid="total-amount">
                <CurrencyFormatter value={totalAmount} showCents />
              </StyledValue>
            </StyledRow>
          </>
        )}
      </StyledPanel>
      {!!values.payment_method && (
        <>
          <AreaTitle>
            {t('r.payment.edit.card_details.subtitle', 'Card Details')}
          </AreaTitle>
          <StyledPanel hSpace={30} vSpace={30}>
            <Row>
              <Group col={colSize}>
                <FieldInput
                  id="first_name"
                  name="first_name"
                  placeholder={
                    t(
                      'r.payment.edit.make_payment_first_name.label',
                      'First name'
                    ) + '*:'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  id="last_name"
                  name="last_name"
                  placeholder={
                    t(
                      'r.payment.edit.make_payment_last_name.label',
                      'Last name'
                    ) + '*:'
                  }
                />
              </Group>
            </Row>
            <CreditCardInput
              values={values}
              setCardInfoValid={setCardInfoValid}
              setCardMethodValid={setCardMethodValid}
              cardMethods={{
                acceptedCards,
                error: cardMethodErrors[values.payment_method]
              }}
            />
            <AssuranceText>
              <Icon id={'secure'} height={24} width={24} />
              {t(
                'l.payment_settings.edit.safe_data_transaction.text',
                'Your transaction will be paid through a secure encrypted network and your information will never be shared with anyone.'
              )}
            </AssuranceText>
          </StyledPanel>
        </>
      )}
    </>
  )
}

export default PaymentMethodForm
