import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toInteger } from 'lodash'
import { path } from 'ramda'
import { breakpoints } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import toBoolean from 'utils/to-boolean'
import { getAreaMeasurementLabel } from 'utils/listings'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import { Button } from 'components/button'
import {
  Label,
  FieldInput,
  FieldSwitchToggle,
  FieldButtonPicker
} from 'components/form'
import { Subtitle, StyledRow, StyledGroup } from './elements'

const StyledButton = styled(Button)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px 10px 10px 25px;
  }
`

const Details = ({ data, values, setFieldValue }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const user_session = useSelector(path(['session', 'session']))
  const isGroupListing = data.isGroupListing
  const unitType = values.unit_type_txt_id
  const unitScope = values.unit_type_scope_txt_id
  const areaMeasurementUnit = user_session.setting_size_unit

  const sizeLabel = ` (${getAreaMeasurementLabel(areaMeasurementUnit)})`

  const toggleHideUnitNumber = () => {
    setFieldValue(
      'hide_unit_number',
      toBoolean(values.hide_unit_number) ? 0 : 1
    )
  }

  const getBedroomSuffix = count =>
    t('b.listing.edit.count_bedrooms.text', {
      defaultValue: 'Bedroom',
      defaultValue_plural: 'Bedrooms',
      count: toInteger(count)
    })

  const getBathroomSuffix = count =>
    t('b.listing.edit.count_bathrooms.text', {
      defaultValue: 'Bathroom',
      defaultValue_plural: 'Bathrooms',
      count: toInteger(count)
    })

  const getDenSuffix = count =>
    t('b.listing.edit.count_dens.text', {
      defaultValue: 'Den',
      defaultValue_plural: 'Dens',
      count: toInteger(count)
    })

  const getPeopleSuffix = count =>
    t('b.listing.edit.max_occupancy.placeholder', {
      defaultValue: 'Person',
      defaultValue_plural: 'People',
      count: toInteger(count)
    })

  const getSizeSuffix = unit =>
    unit === 'sqft'
      ? t('b.listing.view.sqft.unit', 'ft²')
      : t('b.listing.view.sqm.unit', 'm²')
  const sizeUnit = getSizeSuffix(user_session.setting_size_unit)

  const handleAllowPets = () => {
    if (values.allow_pets) {
      setFieldValue('allow_cats', null)
      setFieldValue('allow_dogs', null)
      setFieldValue('pet_limitations', false)
    }
    setFieldValue('allow_pets', !values.allow_pets)
  }

  const handlePetLimitations = () => {
    setFieldValue('allow_cats', values.pet_limitations ? null : 1)
    setFieldValue('allow_dogs', values.pet_limitations ? null : 1)
    setFieldValue('pet_limitations', !values.pet_limitations)
  }

  const entireDescriptionText = {
    condo: t(
      'l.listing.edit.describe_apartment.label',
      'Describe the Apartment'
    ),
    house: t('l.listing.edit.describe_house.label', 'Describe the House'),
    townhouse: t(
      'l.listing.edit.describe_townhouse.label',
      'Describe the Townhouse'
    )
  }

  const partialDescriptionText = t(
    'l.listing.edit.describe_rental_unit.label',
    'Describe the Rental Unit'
  )

  const roomDescriptionText = t(
    'l.listing.edit.describe_rental_room.label',
    'Describe the Rental Room'
  )

  const entireSizeText = {
    condo: t('l.listing.edit.unit_size.label', 'Unit size') + sizeLabel,
    house: t('l.listing.edit.house_size.label', 'House size') + sizeLabel,
    townhouse:
      t('l.listing.edit.townhouse_size.label', 'Townhouse size') + sizeLabel
  }

  const partialSizeText = {
    condo:
      t(
        'l.listing.edit.partial_apartment_size.label',
        'Partial apartment size'
      ) + sizeLabel,
    house:
      t('l.listing.edit.partial_house_size.label', 'Partial house size') +
      sizeLabel,
    townhouse:
      t(
        'l.listing.edit.partial_townhouse_size.label',
        'Partial townhouse size'
      ) + sizeLabel
  }

  const roomSizeText =
    t('l.listing.edit.bedroom_size.label', 'Bedroom size') + sizeLabel

  const getSizeText = () => {
    if (unitScope === 'entire') return entireSizeText[unitType]
    if (unitScope === 'partial') return partialSizeText[unitType]
    return roomSizeText
  }

  const getBathroomsText = () =>
    unitScope === 'entire'
      ? t('l.listing.edit.number_bathrooms.label', 'Number of bathrooms')
      : t(
          'l.listing.edit.number_private_bathrooms.label',
          'Number of private bathrooms'
        )

  const renderUnitSize = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="size"
        id="size"
        suffix={sizeUnit}
        autoComplete="off"
        placeholder={getSizeText() + '*:'}
      />
    </StyledGroup>
  )

  const renderEntranceToggle = () => (
    <StyledGroup
      col={6}
      inline
      justify="space-between"
      bottom={displayMobile ? 25 : 0}
    >
      <Label htmlFor="private_entrance" noGutter id="private-entrance-label">
        {t('l.listing.edit.private_entrance.label', 'Private entrance')}
      </Label>
      <FieldSwitchToggle
        name="private_entrance"
        id="private_entrance"
        aria-labelledby="private-entrance-label"
      />
    </StyledGroup>
  )

  const renderBedrooms = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="count_bedrooms"
        id="count_bedrooms"
        suffix={getBedroomSuffix(values.count_bedrooms)}
        autoComplete="off"
        placeholder={
          t('l.listing.edit.number_bedrooms.label', 'Number of bedrooms') + '*:'
        }
      />
    </StyledGroup>
  )

  const renderBathrooms = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="count_full_bathrooms"
        id="count_full_bathrooms"
        suffix={getBathroomSuffix(values.count_full_bathrooms)}
        autoComplete="off"
        placeholder={getBathroomsText() + '*:'}
      />
    </StyledGroup>
  )

  const renderSharedBathrooms = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="count_full_shared_bathrooms"
        id="count_full_shared_bathrooms"
        suffix={getBathroomSuffix(values.count_full_shared_bathrooms)}
        autoComplete="off"
        placeholder={
          t(
            'l.listing.edit.number_shared_bathrooms.label',
            'Number of shared bathrooms'
          ) + '*:'
        }
      />
    </StyledGroup>
  )

  const renderDenFlex = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="count_dens"
        id="count_dens"
        suffix={getDenSuffix(values.count_dens)}
        autoComplete="off"
        placeholder={
          t('l.listing.edit.number_den.label', 'Number of den / flex') + ':'
        }
      />
    </StyledGroup>
  )

  const renderMaxOccupancy = () => (
    <StyledGroup col={6}>
      <FieldInput
        name="max_occupancy"
        id="max_occupancy"
        suffix={getPeopleSuffix(values.max_occupancy)}
        autoComplete="off"
        placeholder={
          t('l.listing.edit.max_occupancy.label', 'Max. occupancy') + ':'
        }
      />
    </StyledGroup>
  )

  const renderGroupListingFields = () => (
    <>
      <Subtitle>
        {t('l.listing.edit.unit_sizes_sqft.label', 'Unit Sizes') + sizeLabel}
      </Subtitle>
      <StyledRow gutter={20}>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_min_size"
            id="gr_min_size"
            suffix={sizeUnit}
            autoComplete="off"
            placeholder={
              t('l.listing.edit.min_unit_size.label', 'Minimum suite size') +
              '*:'
            }
          />
        </StyledGroup>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_max_size"
            id="gr_max_size"
            suffix={sizeUnit}
            autoComplete="off"
            placeholder={
              t('l.listing.edit.max_unit_size.label', 'Maximum suite size') +
              '*:'
            }
          />
        </StyledGroup>
      </StyledRow>
      <Subtitle>{t('l.listing.edit.unit_info.label', 'Unit Info')}</Subtitle>
      <StyledRow gutter={20}>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_count"
            id="gr_count"
            placeholder={
              t(
                'l.listing.edit.units_available.label',
                'Number of units available'
              ) + ':'
            }
          />
        </StyledGroup>
        <StyledGroup col={6}>
          <FieldInput
            name="gr_floors"
            id="gr_floors"
            placeholder={
              t('l.listing.edit.available_floors.label', 'Available floors') +
              ':'
            }
          />
        </StyledGroup>
      </StyledRow>
      <StyledRow gutter={20}>
        {renderBedrooms()}
        {renderBathrooms()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderDenFlex()}
        {renderMaxOccupancy()}
      </StyledRow>
    </>
  )

  const renderEntireFields = () => (
    <>
      <Subtitle>{entireDescriptionText[unitType]}</Subtitle>
      <StyledRow gutter={20}>
        {renderUnitSize()}
        {renderBedrooms()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderBathrooms()}
        {renderDenFlex()}
      </StyledRow>
      <StyledRow gutter={20}>{renderMaxOccupancy()}</StyledRow>
    </>
  )

  const renderPartialFields = () => (
    <>
      <Subtitle>{partialDescriptionText}</Subtitle>
      <StyledRow gutter={20}>
        {renderUnitSize()}
        {renderEntranceToggle()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderBedrooms()}
        {renderBathrooms()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderSharedBathrooms()}
        {renderDenFlex()}
      </StyledRow>
      <StyledRow gutter={20}>{renderMaxOccupancy()}</StyledRow>
    </>
  )

  const renderRoomFields = () => (
    <>
      <Subtitle>{roomDescriptionText}</Subtitle>
      <StyledRow gutter={20}>
        {renderUnitSize()}
        {renderBathrooms()}
      </StyledRow>
      <StyledRow gutter={20}>
        {renderSharedBathrooms()}
        {renderMaxOccupancy()}
      </StyledRow>
    </>
  )

  return (
    <>
      <AreaTitle>
        {t('l.listing.edit.enter_details.subtitle', 'Enter Details')}
      </AreaTitle>
      <Panel vSpace={30} hSpace={30}>
        {isGroupListing ? (
          renderGroupListingFields()
        ) : (
          <>
            {unitScope === 'entire' && renderEntireFields()}
            {unitScope === 'partial' && renderPartialFields()}
            {unitScope === 'single' && renderRoomFields()}
          </>
        )}
        <StyledRow>
          <StyledGroup col={12} justify="flex-start">
            <Label htmlFor="furnished">
              {t('l.listing.edit.this_unit_is.label', 'This unit is')}:
            </Label>
            <FieldButtonPicker name="furnished" id="furnished" width="100%">
              <StyledButton value="0">
                {t('common.unfurnished', 'Unfurnished')}
              </StyledButton>
              <StyledButton value="1">
                {t('common.furnished', 'Furnished')}
              </StyledButton>
              <StyledButton value="">
                {t('common.furnishings_available', 'Furnished / Unfurnished')}
              </StyledButton>
            </FieldButtonPicker>
          </StyledGroup>
        </StyledRow>
        <Subtitle top={30}>
          {t('l.listing.edit.property_rules.label', 'Property Rules')}
        </Subtitle>
        <StyledRow>
          <StyledGroup col={6} inline justify="space-between">
            <Label htmlFor="allow_smoking" noGutter>
              {t('l.listing.edit.smoking_allowed.label', 'Smoking is allowed')}
            </Label>
            <FieldSwitchToggle name="allow_smoking" id="allow_smoking" />
          </StyledGroup>
          <StyledGroup col={6} inline justify="space-between">
            <Label htmlFor="allow_pets" noGutter>
              {t('l.listing.edit.pets_allowed.label', 'Pets are allowed')}
            </Label>
            <FieldSwitchToggle
              name="allow_pets"
              id="allow_pets"
              onChange={handleAllowPets}
            />
          </StyledGroup>
        </StyledRow>
        {!!values.allow_pets && (
          <StyledRow justify="flex-end">
            <StyledGroup col={6} inline justify="space-between">
              <Label htmlFor="pet_limitations" noGutter>
                {t('l.listing.edit.pet_restrictions.label', 'Pet restrictions')}
              </Label>
              <FieldSwitchToggle
                name="pet_limitations"
                id="pet_limitations"
                onChange={handlePetLimitations}
              />
            </StyledGroup>
          </StyledRow>
        )}
        {!!values.pet_limitations && (
          <StyledRow justify="flex-end">
            <StyledGroup col={6} noGutter>
              <StyledRow>
                <StyledGroup col={6} justify="space-between">
                  <FieldInput
                    name="allow_cats"
                    id="allow_cats"
                    autoComplete="off"
                    placeholder={t('l.listing.edit.cats.label', 'Cats')}
                  />
                </StyledGroup>
                <StyledGroup col={6} justify="space-between">
                  <FieldInput
                    name="allow_dogs"
                    id="allow_dogs"
                    autoComplete="off"
                    placeholder={t('l.listing.edit.dogs.label', 'Dogs')}
                  />
                </StyledGroup>
              </StyledRow>
            </StyledGroup>
          </StyledRow>
        )}
        {!isGroupListing && (
          <>
            <Subtitle top={30}>
              {t('l.listing.edit.display_options.label', 'Display Options')}
            </Subtitle>
            <StyledRow>
              <StyledGroup col={6} inline justify="space-between">
                <Label htmlFor="hide_unit_number" noGutter>
                  {data.unit_type_txt_id !== 'house'
                    ? t(
                        'l.listing.create.hide_unit_number.label',
                        'Hide unit number'
                      )
                    : t(
                        'l.listing.create.hide_street_number.label',
                        'Hide unit and street number'
                      )}
                </Label>
                <FieldSwitchToggle
                  name="hide_unit_number"
                  id="hide_unit_number"
                  onChange={toggleHideUnitNumber}
                />
              </StyledGroup>
            </StyledRow>
          </>
        )}
      </Panel>
    </>
  )
}

export default Details
