import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { colors, breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'
import Icon from 'components/icon'
import { PhoneCode } from 'components/code'
import config from 'config'

const { WEB_URL } = config

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 375px;
  > div:last-of-type {
    box-shadow: none;
    border: none;
    background: ${colors.white};
  }
`

const LogoIcon = styled(Icon)`
  position: absolute;
  left: 40px;
  top: 20px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    left: 20px;
    top: 0px;
  }
`

const AssistCodeContainer = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const onSubmit = async ({ code }) => {
    const { error } = await dispatch.profile.remoteAssist({
      phone: props.phone,
      code
    })
    if (!error) {
      props.close()
    }
  }

  return (
    <Container>
      <a href={WEB_URL}>
        <LogoIcon id="liv_logo" width={75} height={75} />
      </a>
      <PhoneCode
        title={t('b.assist.view.liv_assist.title', 'Liv Assist')}
        onSubmit={onSubmit}
        sendTo={props.phone}
      />
    </Container>
  )
}

export default AssistCodeContainer
