import React from 'react'
import styled, { css } from 'styled-components'
import VerifiedBadge from 'components/verified-badge'
import { fontWeights, fontSizes, breakpoints } from 'styles'
import { formatPhone } from 'utils/format-phone'
import formatName from 'utils/format-name'
import isVerified from 'utils/is-verified'

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 0px;
  }
`

const NameText = styled.h1`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  line-height: 31px;
  max-width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: ${fontSizes.regular};
    max-width: 200px;
  }

  @media print {
    white-space: unset;
  }
`

const ContactText = styled.p`
  font-size: ${fontSizes.regular};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: ${fontSizes.small};
  }
`

const normalStyle = css`
  display: block;
  > :not(:last-child) {
    &:after {
      content: '';
    }
  }
  > * {
    padding-right: 15px;
  }
`

const BaseWrapper = styled.div`
  max-width: 450px;
  overflow: hidden;
`

const BreakLinesWrapper = styled(BaseWrapper)`
  ${normalStyle}
`

const WrapLinesWrapper = styled(BaseWrapper)`
  display: flex;
  flex-wrap: wrap;
  > :not(:last-child) {
    &:after {
      content: ' | ';
      font-weight: ${fontWeights.medium};
      font-size: ${fontSizes.regular};
      white-space: pre;
    }
  }
  > :last-child {
    padding-right: 15px;
  }
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    ${normalStyle}
  }
`

const formatFullName = data => {
  const end =
    data.preferred_name && data.preferred_name !== data.first_name
      ? ` ${data.preferred_name}`
      : ''
  return `${data.first_name} ${data.last_name}${end}`
}

const ContactInfo = ({ data, showFullName = true, lineWrap = true }) => {
  const isCompany = data.account_type === 'business'
  const company = data?.company_info
  const InfoWrapper = lineWrap ? BreakLinesWrapper : WrapLinesWrapper
  return (
    <>
      <NameWrapper>
        <NameText>
          {!showFullName ? formatName(data) : formatFullName(data)}
        </NameText>
        <VerifiedBadge
          type="id"
          width={26}
          height={20}
          left={5}
          bottom={-3}
          verified={isVerified(data, company)}
        />
      </NameWrapper>
      <InfoWrapper>
        {!isCompany && (
          <ContactText>
            {formatPhone(data.phone, data.phone_country_code_alpha)}
          </ContactText>
        )}
        <ContactText>
          {isCompany ? data.company_info?.name : data.username}
        </ContactText>
      </InfoWrapper>
    </>
  )
}

export default ContactInfo
