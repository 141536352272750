import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, breakpoints } from 'styles'
import ContextMenu from 'components/context-menu'
import { getImageUrl } from 'utils/image'
import { useTranslation } from 'react-i18next'

const ListingImageContainer = styled.div`
  object-fit: cover;
  height: 200px;
  width: 200px;
  position: relative;
  margin-bottom: 20px;
  &:nth-child(3n - 4) {
    margin: 0 30px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 156px;
    height: 156px;
    margin-left: 10px;
    margin-bottom: 10px;
    &:nth-child(3n - 4) {
      margin: 0 0 0 10px;
    }
  }
`

const ListingImageLabel = styled.h3`
  color: ${colors.white};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.39px;
  line-height: 22px;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.9), 1px 1px 7px #000,
    1px 1px 7px #000;
  position: absolute;
  width: 100%;
  left: 0;
  top: 10px;
  text-align: center;
`

const ListingImage = styled.img`
  object-fit: cover;
  height: 200px;
  width: 200px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 156px;
    height: 156px;
  }
`

const ImageComponent = ({ data: image, setCover, remove }) => {
  const [t] = useTranslation()
  const isCover = image.tag === 'cover_photo'
  const contextMenuItems = [
    ...(isCover
      ? []
      : [
          {
            onClick: () => setCover(image),
            label: t(
              'b.listing.edit.image_upload_set_cover_photo.button',
              'Set as cover photo'
            )
          }
        ]),
    {
      onClick: () => remove(image),
      label: t('b.listing.edit.image_upload_remove.button', 'Remove'),
      color: colors.red
    }
  ]
  return (
    <ListingImageContainer key={image.id}>
      {isCover && (
        <ListingImageLabel>
          {t('b.listing.edit.image_upload_cover_photo.text', 'Cover Photo')}
        </ListingImageLabel>
      )}
      <ContextMenu items={contextMenuItems} />
      <ListingImage
        src={getImageUrl(image.aws_s3_key, { width: 200, height: 200 })}
        alt=""
      />
    </ListingImageContainer>
  )
}

export default ImageComponent
