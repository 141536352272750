import { createSelector } from 'reselect'
import { prop, values, sortBy, toLower, when, pipe } from 'ramda'
import { isTruthy } from 'utils'
import data from 'basic-bootstrap'
import selectorBuilder from 'models/selector-builder'

const formatData = when(
  isTruthy,
  pipe(values, sortBy(pipe(prop('name'), toLower)))
)
const featuresSelector = createSelector(prop('unit_features'), formatData)

const utilitiesSelector = createSelector(prop('utilities'), formatData)

const basic = {
  state: data,
  selectors: selectorBuilder(slice => ({
    features() {
      return slice(featuresSelector)
    },
    utilities() {
      return slice(utilitiesSelector)
    }
  }))
}

export default basic
