import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import useBasic from 'hooks/use-basic'
import { breakpoints } from 'styles'
import { pathOr, pipe, pickAll, evolve, always, path } from 'ramda'
import * as Yup from 'yup'
import moment from 'moment'
import noop from 'lodash/noop'
import useMediaQuery from 'hooks/use-media-query'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import {
  Label,
  Row,
  Group,
  FieldInput,
  PhoneInput,
  FieldDropdown,
  FieldDatePicker,
  FieldTextarea,
  SwitchToggle
} from 'components/form'
import StateDropdown from 'components/state-dropdown'
import { defaultIfFalsy, mergeSpec, isFalsy } from 'utils'
import 'utils/validation'
import { getFormattedCountries } from 'utils/address'

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const WorkHereRow = styled(Row)`
  justify-content: space-between;
`

const LandlordReferenceForm = React.memo(
  ({ data = {}, onCancel = noop, onSave = noop }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(['translation', 'basic'])
    const displayMobile = useMediaQuery(
      `(max-width: ${breakpoints.ipadMiniMax})`
    )
    const colSize = displayMobile ? 12 : 6

    const { countries } = useBasic(['countries'])
    const formattedCountries = getFormattedCountries(countries)

    const initialValues = pipe(
      pickAll([
        'id',
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'contact_type',
        'relationship',
        'extra_info',
        'i_currently_live_here',
        'start_date',
        'end_date',
        'reason_for_leaving'
      ]),
      evolve({
        contact_type: always('landlord'),
        relationship: always('other'),
        first_name: defaultIfFalsy(''),
        last_name: defaultIfFalsy(''),
        email: defaultIfFalsy(''),
        phone_number: defaultIfFalsy(''),
        start_date: defaultIfFalsy(null),
        end_date: defaultIfFalsy(null),
        reason_for_leaving: defaultIfFalsy('')
      }),
      mergeSpec({
        i_currently_live_here: pipe(path(['extra_info', 'end_date']), isFalsy),
        start_date: pathOr('', ['extra_info', 'start_date']),
        end_date: pathOr('', ['extra_info', 'end_date']),
        reason_for_leaving: pathOr('', ['extra_info', 'reason_for_leaving']),
        street_address: pathOr('', ['extra_info', 'street_address']),
        suite_number: pathOr('', ['extra_info', 'suite_number']),
        city: pathOr('', ['extra_info', 'city']),
        zip: pathOr('', ['extra_info', 'zip']),
        state: pathOr('', ['extra_info', 'state']),
        country_code: pathOr('', ['extra_info', 'country_code'])
      })
    )(data)

    const validationSchema = Yup.object().shape({
      first_name: Yup.string().required(),
      last_name: Yup.string().required(),
      email: Yup.string().email().trim(),
      phone_number: Yup.string().phone().required(),
      country_code: Yup.string().required(),
      street_address: Yup.string().required(),
      suite_number: Yup.string(),
      city: Yup.string().required(),
      state: Yup.string().required(),
      zip: Yup.string().required(),
      start_date: Yup.date().required(),
      end_date: Yup.mixed().when('i_currently_live_here', {
        is: false,
        then: Yup.date().required(),
        otherwise: null
      }),
      reason_for_leaving: Yup.string().required()
    })

    const isEditing = !!data.id
    const handleDeleteClick = useCallback(async () => {
      await confirm(
        t(
          'r.profile.edit.delete_landlord_reference.title',
          'Delete Landlord Reference?'
        ),
        t(
          'r.profile.edit.delete_landlord_confirmation.text',
          'Are you sure you want to delete this landlord reference?'
        )
      )
      await dispatch.profile.deleteContact(data.id)
    }, [data.id, dispatch.profile, t])

    const onSubmit = async (values, { setSubmitting }) => {
      const action = isEditing
        ? dispatch.profile.updateContact
        : dispatch.profile.addContact
      const response = await action(values)
      setSubmitting(false)

      if (!response.error) {
        onSave()
      }
    }

    const initialMonth = values => {
      return values.start_date ? moment(values.start_date) : null
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          values,
          isSubmitting,
          handleChange,
          setFieldValue
        }) => (
          <Form>
            <Label bottom={20} strong>
              {t(
                'r.profile.edit.landlord_contact.subtitle',
                'Landlord Contact'
              )}
            </Label>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="first_name"
                  placeholder={
                    t('r.profile.edit.first_name.label', 'First Name') + '*:'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="last_name"
                  placeholder={
                    t('r.profile.edit.last_name.label', 'Last Name') + '*:'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="email"
                  placeholder={t('r.profile.edit.email.label', 'Email') + ':'}
                />
              </Group>
              <Group col={colSize}>
                <PhoneInput
                  name="phone_number"
                  value={values.phone_number}
                  countryCodeAlpha={data.phone_country_code_alpha}
                  onChange={handleChange('phone_number')}
                  placeholder={
                    t('r.profile.edit.phone_number.label', 'Phone Number') +
                    '*:'
                  }
                />
              </Group>
            </Row>
            <Label bottom={20} top={30} strong>
              {t(
                'r.profile.edit.previous_rental_address.subtitle',
                'Previous Rental Address'
              )}
            </Label>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldDropdown
                  placeholder={
                    t('r.profile.edit.country.label', 'Country') + '*:'
                  }
                  options={formattedCountries}
                  id="company_country_code"
                  name="country_code"
                />
              </Group>

              <Group col={colSize}>
                <FieldInput
                  name="street_address"
                  placeholder={
                    t(
                      'r.profile.edit.previous_address.label',
                      'Street Address'
                    ) + '*:'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldInput
                  name="suite_number"
                  placeholder={
                    t('r.profile.edit.previous_unit.label', 'Suite Number') +
                    ':'
                  }
                />
              </Group>
              <Group col={colSize}>
                <FieldInput
                  name="city"
                  placeholder={
                    t('r.profile.edit.previous_city.label', 'City') + '*:'
                  }
                />
              </Group>
            </Row>
            <Row gutter={20}>
              <StateDropdown
                name="state"
                col={colSize}
                country={values.country_code}
                countries={countries}
                required
              />
              <Group col={colSize}>
                <FieldInput
                  name="zip"
                  placeholder={
                    t('r.profile.edit.previous_zip.label', 'Postal Code') + '*:'
                  }
                />
              </Group>
            </Row>

            <Label bottom={20} top={30} strong>
              {t('r.profile.edit.rental_history.subtitle', 'Rental History')}
            </Label>
            <Group col={colSize}>
              <WorkHereRow>
                <Label>
                  {t(
                    'r.profile.edit.rental_history_currently_live_here.label',
                    'I currently live here'
                  )}
                </Label>
                <SwitchToggle
                  name="i_currently_live_here"
                  enabled={values.i_currently_live_here}
                  onChange={() => {
                    setFieldValue(
                      'i_currently_live_here',
                      !values.i_currently_live_here
                    )
                    setFieldValue('end_date', null)
                  }}
                />
              </WorkHereRow>
            </Group>
            <Row gutter={20}>
              <Group col={colSize}>
                <FieldDatePicker
                  name="start_date"
                  placeholder={
                    t(
                      'r.profile.edit.rental_history_duration.label',
                      'When did you live here?'
                    ) + '*:'
                  }
                  maxDate={
                    !!values.end_date ? moment(values.end_date) : moment()
                  }
                  format="YYYY-MM"
                  displayFormat="YYYY-MM"
                  views={['year', 'month']}
                  openTo="year"
                  disableFuture
                />
              </Group>
              {!values.i_currently_live_here && (
                <Group col={colSize}>
                  <FieldDatePicker
                    name="end_date"
                    placeholder={t(
                      'r.profile.edit.select_end_date.label',
                      'Select End Date'
                    )}
                    disabled={
                      values.i_currently_live_here || !values.start_date
                    }
                    initialFocusedDate={initialMonth(values)}
                    minDate={moment(values.start_date)}
                    format="YYYY-MM"
                    displayFormat="YYYY-MM"
                    views={['year', 'month']}
                    openTo="year"
                    disableFuture
                  />
                </Group>
              )}
            </Row>
            <Row gutter={20}>
              <Group>
                <Label>
                  {t(
                    'r.profile.edit.rental_history_reason.label',
                    'Reason for leaving'
                  )}
                  *:
                </Label>
                <FieldTextarea
                  name="reason_for_leaving"
                  maxLength={540}
                  placeholder={t(
                    'r.profile.edit.rental_history_reason.label',
                    'I ended my tenancy because...'
                  )}
                />
              </Group>
            </Row>
            <Buttons>
              {isEditing ? (
                <ActionButton
                  theme="danger"
                  onClick={handleDeleteClick}
                  size="small"
                >
                  {t('r.profile.edit.delete.button', 'Delete')}
                </ActionButton>
              ) : (
                <ActionButton theme="neutral" onClick={onCancel} size="small">
                  {t('r.profile.edit.cancel.button', 'Cancel')}
                </ActionButton>
              )}
              <ActionButton
                type="button"
                theme="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isEditing
                  ? t('r.profile.edit.update.button', 'Update')
                  : t('r.profile.edit.add.button', 'Add')}
              </ActionButton>
            </Buttons>
          </Form>
        )}
      </Formik>
    )
  }
)

export default LandlordReferenceForm
