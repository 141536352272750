import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useMediaQuery from 'hooks/use-media-query'
import CardHeader from './card-header'
import CardDetails from './card-details'
import minusDark from './images/MinusDark.png'
import plusWhite from './images/PlusWhite.png'

const Wrapper = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 188px;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.20);
  background: ${colors.grey};
  background-image: url('${({ url }) => url}');
  background-size: cover;

  @media print {
    box-shadow: none;
    border: 1px solid ${colors.grey};
  }
`

const ScoreDescription = styled.p`
  font-size: 11px;
  color: ${colors.white};
  line-height: 13px;
  letter-spacing: -0.2px;
  margin: 0;
  padding: 0 20px;

  @media print {
    color: ${colors.regular};
  }
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
`

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 10px;
`

const PointsValue = styled.p`
  display: inline;
  font-size: 47px;
  color: ${colors.white};
  font-weight: ${fontWeights.medium};
  line-height: 50px;
  margin: 0;

  @media print {
    color: ${colors.regular};
  }
`

const PointsText = styled.p`
  display: inline;
  font-size: 17px;
  line-height: 20px;
  color: ${colors.white};
  margin: 0;

  span {
    opacity: 0.5;
  }

  @media print {
    color: ${colors.regular};
  }
`

const UpdatedText = styled.p`
  font-size: 11px;
  color: ${colors.white};
  font-style: italic;
  line-height: 13px;
  margin: 0;

  @media print {
    color: ${colors.regular};
  }
`

const ToggleIcon = styled.img`
  width: 63px;
  height: 65px;
  cursor: pointer;

  @media print {
    display: none;
  }
`

const CardTemplate = props => {
  const {
    className,
    cardImage,
    title,
    creditRatingResult,
    avatarUrl,
    creditDescription,
    creditDetails,
    userRole,
    professionId,
    csoVerifiedState,
    salarySort,
    listingData
  } = props
  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false)
  const printMedia = useMediaQuery('print')

  const formatDate = date => moment(date, 'YYYY-MM-DD').format('MMM. DD, YYYY')

  return (
    <Wrapper className={className} data-testid={props['data-testid']}>
      <CardWrapper onClick={() => setShowDetails(!showDetails)} url={cardImage}>
        <CardHeader
          title={title}
          rating={creditRatingResult}
          avatarUrl={avatarUrl}
        />
        <ScoreDescription>{creditDescription}</ScoreDescription>
        <BottomWrapper>
          <PointsWrapper>
            <div>
              <PointsValue>{creditDetails.overall}</PointsValue>{' '}
              <PointsText>
                {t('b.liv_score_card.view.points.text', 'pts')}
                <span> /{creditDetails.maximum}</span>
              </PointsText>
            </div>
            <UpdatedText>
              {t('b.liv_score_card.view.last_updated.text', 'Last Updated:')}{' '}
              {`${formatDate(creditDetails.updated_at)}`}
            </UpdatedText>
          </PointsWrapper>
          <ToggleIcon src={showDetails ? minusDark : plusWhite} />
        </BottomWrapper>
      </CardWrapper>
      {(showDetails || printMedia) && (
        <CardDetails
          data={creditDetails}
          userRole={userRole}
          professionId={professionId}
          csoVerifiedState={csoVerifiedState}
          salarySort={salarySort}
          listingData={listingData}
        />
      )}
    </Wrapper>
  )
}

export default CardTemplate
