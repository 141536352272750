import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes } from 'styles'
import config from 'config'
import noop from 'lodash/noop'
import Icon from 'components/icon'
import Avatar from './avatar'

const { IMAGES_URL } = config

const Action = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: ${fontSizes.small};
`

const Plus = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  background-color: #494949;
  height: ${props => `${props.height / 5}px`};
  width: ${props => `${props.width / 5}px`};
  left: ${props => `${props.width / 1.35}px`};
  bottom: 5px;
`

const Wrapper = styled.div`
  height: ${props =>
    isNaN(props.height) ? props.height : `${props.height}px`};
  width: ${props => (isNaN(props.width) ? props.width : `${props.width}px`)};
  margin-left: ${props => (isNaN(props.left) ? props.left : `${props.left}px`)};
  margin-right: ${props =>
    isNaN(props.right) ? props.right : `${props.right}px`};
  margin-bottom: ${props =>
    isNaN(props.bottom) ? props.bottom : `${props.bottom}px`};
  position: relative;

  &:hover ${Action} {
    display: flex;
  }
`

const AvatarPicker = ({
  location,
  text,
  onSelect = noop,
  showPreview,
  showIcon,
  type = '',
  ...rest
}) => {
  const { t } = useTranslation()
  const [preview, setPreview] = useState()

  const { getInputProps, open } = useDropzone({
    accept: 'image/png,image/jpg,image/jpeg',
    multiple: false,
    noKeyboard: true,
    onDropAccepted: ([file]) => {
      if (showPreview) {
        setPreview(URL.createObjectURL(file))
      }
      onSelect(file)
    }
  })

  useEffect(() => {
    return () => URL.revokeObjectURL(preview)
  }, [preview])

  const isDefault = !location.includes(IMAGES_URL)
  return (
    <Wrapper {...rest}>
      <Avatar
        data-cy={`${type && `${type}-`}avatar`}
        {...rest}
        location={preview || location}
        shadow
      />
      <Action onClick={open}>
        {isDefault
          ? t('b.avatar_picker.edit.select_avatar_photo.text', 'Select')
          : t('b.avatar_picker.edit.replace_avatar_photo.text', 'Replace')}
      </Action>
      <input name="avatarInput" {...getInputProps()} />
      {showIcon && (
        <Plus {...rest} onClick={open}>
          <Icon
            id="plus_white"
            width={Math.ceil(rest.width / 7)}
            height={Math.ceil(rest.height / 7)}
          />
        </Plus>
      )}
    </Wrapper>
  )
}

export default AvatarPicker
