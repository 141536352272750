import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import ProfileRequired from 'components/profile-required'
import EmploymentReferenceView from './view'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const EmploymentReferences = ({
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()

  return (
    <MultiRowForm
      anchorTop={anchorTop}
      title={
        <>
          {t(
            'r.profile.view.employment_references.subtitle',
            'Employment References'
          )}
          <ProfileRequired
            fields={['employment_reference']}
            area="references"
          />
        </>
      }
      addText={t(
        'r.profile.view.emploment_reference_add.button',
        '+ Add Reference'
      )}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={EmploymentReferenceView}
      EditComponent={EditComponent}
      EmptyComponent={<EmptyMessage>{emptyMessage}</EmptyMessage>}
      editable={editable}
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(EmploymentReferences)
