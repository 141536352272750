import React from 'react'
import styled, { css } from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'

const buttonBase = css`
  border-radius: 2px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  color: ${colors.white};
  width: 45%;
  padding: 12px 15px;
  transition: box-shadow 150ms ease-in-out, background-color 100ms ease-in;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 6px;
  outline: none;
  cursor: pointer;

  &:active {
    box-shadow: none;
  }
`

const baseOnClick = onClick => e => {
  e.preventDefault()
  onClick()
}

const ButtonHOC = options => {
  const Button = styled.button`
    ${buttonBase};
    background-color: ${options.primaryColor};

    &:hover {
      background-color: ${options.hoverColor};
    }

    ${options.css};
  `

  class RoundedButton extends React.PureComponent {
    constructor(props) {
      super(props)
      this.onClick = baseOnClick(props.onClick)
    }

    render() {
      const { children, onClick, ...buttonProps } = this.props
      return (
        <Button {...buttonProps} onClick={this.onClick}>
          {children}
        </Button>
      )
    }
  }

  RoundedButton.defaultProps = {
    children: 'Button',
    className: '',
    disabled: false
  }

  return RoundedButton
}

export const PinkButton = ButtonHOC({
  primaryColor: colors.pink,
  hoverColor: colors.lightPink
})

export const GreyButton = ButtonHOC({
  primaryColor: colors.regular,
  hoverColor: colors.mediumGrey
})

export const GhostButton = ButtonHOC({
  primaryColor: colors.white,
  css: css`
    color: ${colors.secondary};
    border: 1px solid ${colors.grey};
    box-shadow: none;

    &:hover {
      color: ${colors.secondary};
    }
  `
})
