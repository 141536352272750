import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import { showUserPicker } from 'components/user-picker'
import Icon from 'components/icon'
import { concat } from 'ramda'
import { showAddNewTenantModal } from './new-tenant'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 30px;
  border-bottom: 2px solid ${colors.grey};
`

const ItemWrapper = styled.button`
  height: 90px;
  width: 100%;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid ${colors.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`

const TenantModalOptions = ({
  title = 'Add a Tenant From...',
  data,
  allTenants,
  onAddNewUser,
  onSelectApplicant,
  listingId,
  isExtraUser
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()

  const showTenantModal = () => {
    showUserPicker({
      title: t(
        'l.listing.contract.parties.select_tenant.title',
        'Select Tenant'
      ),
      allUsers: allTenants,
      activeUsers:
        data[
          isExtraUser
            ? 'contract_non_signing_tenants'
            : 'contract_signing_tenants'
        ],
      disabledUsers: concat(
        data.contract_non_signing_tenants,
        data.contract_signing_tenants
      ),
      setActiveUsers: isExtraUser ? onSelectApplicant : onAddNewUser
    })
  }

  const onAdd = newTenant =>
    onAddNewUser([
      ...(isExtraUser
        ? data?.contract_extra_users
        : data?.contract_signing_tenants),
      newTenant
    ])

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <Title>{title}</Title>
      {!!allTenants.length && (
        <ItemWrapper
          onClick={() => {
            close()
            showTenantModal()
          }}
        >
          {t(
            'l.listing.contract.parties.select_from_list.label',
            'Select from applicant list'
          )}
        </ItemWrapper>
      )}
      <ItemWrapper
        onClick={() => {
          close()
          showAddNewTenantModal({ listingId, onAdd, isExtraUser })
        }}
      >
        {t('l.listing.contract.parties.add_new_tenant.label', 'Add new tenant')}
      </ItemWrapper>
    </Wrapper>
  )
}

export const showTenantModalOptions = createModal(TenantModalOptions)
