const INITIAL_STATE = { ready: false }

const master = {
  state: INITIAL_STATE,
  reducers: {
    setReady: state => ({ ...state, ready: true })
  },
  effects: dispatch => ({
    async load() {
      const { search } = window.location
      const matched = search.replace(/^\?/, '&').match(/&continue=.+/)

      if (matched) {
        const loginHash = matched.shift().split('&continue=').pop()

        if (loginHash) {
          await dispatch.auth.autoLogin(loginHash)
        }
      }

      await dispatch.session.loadSession()
      dispatch.master.setReady()
    }
  }),
  selectors: {
    isReady: () => rootState => rootState.master.ready
  }
}

export default master
