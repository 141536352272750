import styled from 'styled-components'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import Panel from 'components/panel'
import { Label, Row } from 'components/form'

export const StyledPanel = styled(Panel)`
  text-align: left;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0px;
  }
`

export const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

export const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  border-top: ${props => (props.borderTop ? 1 : 0)}px solid ${colors.grey};
`

export const StyledLabel = styled(Label)`
  flex: 1;
  margin-right: 20px;

  ${props =>
    props.bold &&
    `
      font-weight: ${fontWeights.medium};
  `}
`

export const StyledValue = styled(Label)`
  font-weight: ${fontWeights.medium};
  flex: 1;
  text-align: right;
`

export const Error = styled.p`
  color: ${colors.pink};
  font-size: 11px;
  padding-left: 6px;
  line-height: 16px;
  margin: 0;
  width: 100%;
  text-align: left;
`

export const FeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }
`

export const Link = styled.a`
  color: ${colors.link};
  font-weight: ${fontWeights.medium};
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }
`
