import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes } from 'styles'
import { FieldSwitchToggle } from 'components/form'
import { SectionHeading, SectionRow } from '../elements'

const Heading = styled(SectionHeading)`
  ${props => props.condensed && `
    font-size: 20px;
    margin-bottom: 5px;
  `}
`

const Section = styled.section`
  ${props => !props.condensed && `
    max-width: 660px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const PanelText = styled.p`
  color: ${colors.regular};
  font-size: 13px;
  line-height: 18px;
  margin: 0 0 30px;
`

const Label = styled.label`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 22px;
  margin-right: 15px;
`

const ProfileDiscovery = ({ condensed, prefix = '' }) => {
  const { t } = useTranslation()

  return (
    <Section condensed={condensed}>
      <Heading condensed={condensed}>
        {t('r.settings.view.profile_discovery.title', 'Profile Discovery')}
      </Heading>
      <Container>
        <PanelText>
            {prefix}{prefix ? ' ' : ''}{t(
            'r.settings.view.profile_discovery.label',
            'By enabling Discovery, landlords will be able to see your profile and match with you.'
          )}
        </PanelText>
        <SectionRow>
          <Label>
            {t(
              'r.settings.view.allow_profile_discovery.label',
              'Allow Discovery'
            )}
          </Label>
          <FieldSwitchToggle name="allow_discovery" />
        </SectionRow>
      </Container>
    </Section>
  )
}

export default ProfileDiscovery
