import styled from 'styled-components'
import { fontSizes } from 'styles'

const Button = styled.button`
  border-radius: 6px;
  font-size: ${fontSizes.regular};
  -webkit-appearance: none;
  appearance: none;
`

export default Button
