import React from 'react'
import { compose, pure } from 'recompose'
import SelectorList from './selector-list-base'

const RadioSelectorList = ({
  name,
  value = [],
  children,
  disabled,
  ...rest
}) => (
  <SelectorList {...rest}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        name,
        type: 'radio',
        disabled,
        checked: value === child.props.value
      })
    )}
  </SelectorList>
)

export default compose(pure)(RadioSelectorList)
