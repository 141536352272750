import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fontWeights } from 'styles'
import { CollapsibleBox } from 'components/collapsible'
import LabeledValue from 'components/labeled-value'
import SectionSeparator from 'components/section-separator'
import formatCurrency from 'utils/format-currency'
import formatPaymentFrequency from 'utils/format-payment-frequency'
import isTruthy from 'utils/is-truthy'

const Strong = styled.span`
  font-weight: ${fontWeights.medium};
`

const ExtraFees = ({ miscFees }) => {
  const { t } = useTranslation()
  if (!isTruthy(miscFees)) {
    return null
  }

  return (
    <CollapsibleBox
      title={t(
        'b.contract.view.extra_fees_payments.subtitle',
        'Extra Fees / Payments'
      )}
      wrapChildren
    >
      {miscFees.reduce(
        (accum, fee, index) => [
          ...accum,
          <Strong key={`label-${index + 1}`}>
            {t('b.contract.view.extra_fee_payment.text', 'Extra fee / payment')}{' '}
            {index + 1}
          </Strong>,
          <p key={`name-${index + 1}`}>{fee.name}</p>,
          <LabeledValue
            key={`amount-${index + 1}`}
            label={t(
              'b.contract.view.extra_fee_payment.text',
              'Extra fee / payment'
            )}
            value={formatCurrency(fee.price)}
          />,
          <LabeledValue
            key={`frequency-${index + 1}`}
            label={t(
              'b.contract.view.payment_frequency.text',
              'Payment frequency'
            )}
            value={formatPaymentFrequency(fee.frequency)}
          />,
          index !== miscFees.length - 1 && (
            <SectionSeparator key={`separator-${index + 1}`} />
          )
        ],
        []
      )}
    </CollapsibleBox>
  )
}

ExtraFees.defaultProps = {
  miscFees: null
}

export default ExtraFees
