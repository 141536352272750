import React from 'react'
import { useTranslation } from 'react-i18next'
import ListingTableHeader from 'components/listings-table-header'
import { FilterBar, FilterButton } from 'components/search-filter'

const CompanyListingsHeader = ({
  pageTitle,
  tabCount,
  currentTab,
  setCurrentTab,
  onSearch,
  buttonComponent
}) => {
  const { t } = useTranslation()

  return (
    <ListingTableHeader
      title={pageTitle}
      onSearch={onSearch}
      buttonComponent={buttonComponent}
    >
      <FilterBar>
        <FilterButton
          active={currentTab === 'rented'}
          onClick={() => setCurrentTab('rented')}
        >
          {t('l.listing.list.rented.button', 'Rented')} {`(${tabCount.rented})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'published'}
          onClick={() => setCurrentTab('published')}
        >
          {t('l.listing.list.published.button', 'Published')}{' '}
          {`(${tabCount.published})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'draft'}
          onClick={() => setCurrentTab('draft')}
        >
          {t('l.listing.list.draft.button', 'Draft')} {`(${tabCount.draft})`}
        </FilterButton>
      </FilterBar>
    </ListingTableHeader>
  )
}

export default CompanyListingsHeader
