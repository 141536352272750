import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { pipe, pickAll, evolve, values as ramdaValues, map, omit } from 'ramda'
import * as Yup from 'yup'
import { breakpoints } from 'styles'
import { useDispatch } from 'react-redux'
import noop from 'lodash/noop'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import { getImageUrl } from 'utils/image'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import moment from 'moment'
import { Row, Group, FieldInput, FieldDropdown, Error } from 'components/form'
import { AvatarPicker } from 'components/avatar'
import { defaultIfFalsy } from 'utils'
import PetAvatar from './pet-avatar.svg'

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
`

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
`

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const PetForm = React.memo(({ data = {}, onCancel = noop, onSave = noop }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['translation', 'basic'])
  const { pet_sizes } = useBasic(['pet_sizes'])
  const colSize = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
    ? 12
    : 6

  const sizeOptions = pipe(
    ramdaValues,
    map(a => ({
      value: a.txt_id,
      label: t(`basic:b.pets.pet_sizes.${a.txt_id}.text`)
    }))
  )(omit(['tiny'], pet_sizes))

  const initialValues = pipe(
    pickAll(['id', 'name', 'type', 'birth_year', 'breed', 'size', 'file']),
    evolve({
      file: () => null,
      name: defaultIfFalsy(''),
      type: defaultIfFalsy(''),
      birth_year: defaultIfFalsy(''),
      breed: defaultIfFalsy(''),
      size: defaultIfFalsy('')
    })
  )(data)

  const validationSchema = Yup.object().shape({
    file: Yup.string()
      .nullable()
      .when(['id'], (id, schema) => {
        if (id) {
          return schema
        }
        return schema.required()
      }),
    name: Yup.string().required(),
    type: Yup.string().required(),
    birth_year: Yup.string()
      .test(
        'birth_year',
        'Invalid year',
        year =>
          moment(year, 'YYYY', true).isValid() &&
          moment(year).isBefore(moment(), 'year')
      )
      .required(),
    breed: Yup.string().required(),
    size: Yup.string().required()
  })

  const isEditing = !!data.id

  const handleDeleteClick = useCallback(() => {
    return confirm(
      t('r.profile.edit.delete_pet.title', 'Delete Pet?'),
      t(
        'r.profile.edit.delete_pet_confirmation.text',
        'Are you sure you want to delete this pet?'
      )
    ).then(async () => {
      await dispatch.profile.deletePet(data.id)
    })
  }, [data.id, t, dispatch.profile])

  const onSubmit = async (values, { setSubmitting }) => {
    const action = isEditing
      ? dispatch.profile.updatePet
      : dispatch.profile.addPet
    const response = await action(values)
    setSubmitting(false)

    if (!response.error) {
      dispatch.profile.updatePetOwner({ pet_owner: 1 })
      onSave()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <Form>
          <AvatarWrapper>
            <AvatarPicker
              location={
                data.avatar?.aws_s3_key
                  ? getImageUrl(data.avatar?.aws_s3_key, {
                      width: 160,
                      height: 160
                    })
                  : PetAvatar
              }
              width={160}
              height={160}
              showPreview
              onSelect={file => setFieldValue('file', file)}
              type="pet"
            />
            <Error name="file" />
          </AvatarWrapper>
          <Row gutter={20}>
            <Group col={colSize}>
              <FieldInput
                name="name"
                placeholder={
                  t('r.profile.edit.pet_name.label', 'Pet Name') + ':'
                }
              />
            </Group>
            <Group col={colSize}>
              <FieldInput
                name="type"
                placeholder={
                  t('r.profile.edit.type_of_pet.label', 'Type of Pet') + ':'
                }
              />
            </Group>
          </Row>
          <Row gutter={20}>
            <Group col={colSize}>
              <FieldInput
                name="birth_year"
                placeholder={
                  t('r.profile.edit.year_born.label', 'Birth Year') + ':'
                }
              />
            </Group>
            <Group col={colSize}>
              <FieldInput
                name="breed"
                placeholder={t('r.profile.edit.breed.label', 'Breed') + ':'}
              />
            </Group>
          </Row>
          <Row gutter={20}>
            <Group col={colSize}>
              <FieldDropdown
                name="size"
                id="size"
                placeholder={t('r.profile.edit.size.label', 'Size') + ':'}
                options={sizeOptions}
                isSearchable={false}
              />
            </Group>
          </Row>
          <Buttons>
            {isEditing ? (
              <ActionButton
                theme="danger"
                onClick={handleDeleteClick}
                size="small"
              >
                {t('r.profile.edit.delete.button', 'Delete')}
              </ActionButton>
            ) : (
              <ActionButton theme="neutral" onClick={onCancel} size="small">
                {t('r.profile.edit.cancel.button', 'Cancel')}
              </ActionButton>
            )}
            <ActionButton
              type="button"
              theme="primary"
              size="small"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {isEditing
                ? t('r.profile.edit.update.button', 'Update')
                : t('r.profile.edit.add.button', 'Add')}
            </ActionButton>
          </Buttons>
        </Form>
      )}
    </Formik>
  )
})

export default PetForm
