import ENV_VARS from 'config'
import { post } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const translateMessage = post(
  `${BACKEND_SERVER}/translate/timeline_message/%language%`,
  {
    noLoading: true
  }
)

export const translateSupportMessage = post(
  `${BACKEND_SERVER}/translate/support_message/%language%`,
  {
    noLoading: true
  }
)
