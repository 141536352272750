import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { colors, fontSizes } from 'styles'
import { useTranslation, Trans } from 'react-i18next'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { createModal, useModalize } from 'components/modalize'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import {
  CheckboxSelectorList,
  SelectorItem as SelectorItemBase
} from 'components/selector-list'
import {
  pipe,
  filter,
  map,
  prop,
  path,
  all,
  includes,
  values as rValues,
  assoc,
  propSatisfies,
  not,
  __
} from 'ramda'
import { renameKeys } from 'utils'
import { Button } from 'components/button'
import { show as showEditAddendumModal } from './edit-addendum-modal'

const OptionText = styled.span`
  padding-right: 15px;
`

const SelectorItem = ({ children, ...rest }) => (
  <SelectorItemBase {...rest} as={props => <label {...props} />}>
    <OptionText>{children}</OptionText>
  </SelectorItemBase>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  max-height: 90vh;
  height: 90vh;
`

const StyledTaskbar = styled(Taskbar)`
  background-color: ${colors.lightGrey};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`

const NewButton = styled.button`
  cursor: pointer;
  color: ${colors.link};
  border: none;
  outline: none;
  font-size: ${fontSizes.small};
  background-color: transparent;
  :hover {
    opacity: 0.7;
  }
`

const ContentWrapper = styled.div`
  background-color: ${colors.white};
  overflow-y: hidden;
  height: 100%;
`

const StyledCheckboxSelectorList = styled(CheckboxSelectorList)`
  border: none;
  overflow: auto;
  flex: 1;
`

const SelectAllText = styled.span`
  color: ${colors.link};
  cursor: pointer;
`

const DescriptionText = styled.p`
  border-bottom: 1px solid ${colors.grey};
  padding: 20px;
  margin: 0px;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledButton = styled(Button)`
  margin-top: auto;
  width: 100%;
`

const AddAddendumModal = ({
  createAddendum,
  data = [],
  updateValue,
  contract_addendums
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const addendumTemplates = useSelector(
    path(['contract', 'listing_contract_addendum_templates'])
  )

  const validAddendums = filter(
    pipe(prop('archived'), Number, not),
    rValues(addendumTemplates)
  )

  useEffect(() => {
    dispatch.contract.loadAddendumTemplates()
  }, [dispatch.contract])

  const { close } = useModalize()

  const handleNew = () => {
    close()
    showEditAddendumModal({
      createAddendum,
      addendums: data,
      updateValue,
      contract_addendums
    })
  }

  const initialValues = {
    contract_predefined_addendums: map(
      prop('listing_contract_addendum_template_txt_id'),
      data
    )
  }

  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      contract_predefined_addendums: Yup.array()
    }),
    onSubmit: async (values, { setSubmitting }) => {
      updateValue(
        'contract_predefined_addendums',
        pipe(
          filter(
            propSatisfies(
              includes(__, values.contract_predefined_addendums),
              'txt_id'
            )
          ),
          map(
            pipe(
              renameKeys({
                txt_id: 'listing_contract_addendum_template_txt_id'
              }),
              addendum =>
                assoc(
                  'allowed',
                  addendum.data_type === 'content' ? true : false,
                  addendum
                )
            )
          )
        )(validAddendums)
      )
      close()
    }
  })

  return (
    <Wrapper>
      <StyledTaskbar
        left={<Tool type="close" onClick={close} />}
        right={
          <NewButton onClick={handleNew}>
            {t('l.contract.edit.add_addendum_modal_new.button', 'New')}
          </NewButton>
        }
      >
        {t('l.contract.edit.add_addendum_modal.title', 'Add Addendum')}
      </StyledTaskbar>
      <ContentWrapper>
        <Formik {...formOptions()} initialValues={initialValues}>
          {({ values, setFieldValue, handleSubmit }) => {
            const handleSelectAll = e => {
              setFieldValue(
                'contract_predefined_addendums',
                e.target.checked ? map(prop('txt_id'), validAddendums) : []
              )
            }
            return (
              <StyledForm>
                <DescriptionText>
                  <Trans i18nKey="l.contract.edit.add_addendum_modal.description">
                    Add one of our standard addendums,or tap{' '}
                    <strong>New</strong> to create a custom one.
                  </Trans>
                </DescriptionText>
                <StyledCheckboxSelectorList
                  name="contract_predefined_addendums"
                  value={values.contract_predefined_addendums}
                >
                  <SelectorItem
                    value="all_selected"
                    onChange={handleSelectAll}
                    checked={all(
                      addendum =>
                        includes(
                          addendum,
                          values.contract_predefined_addendums
                        ),
                      map(prop('txt_id'), validAddendums)
                    )}
                  >
                    <SelectAllText>
                      {t(
                        `l.contract.edit.add_addendum_modal_select_all.label`,
                        'Select all addendums'
                      )}
                    </SelectAllText>
                  </SelectorItem>
                  {validAddendums.map(addendum => (
                    <SelectorItem key={addendum.txt_id} value={addendum.txt_id}>
                      {t(
                        `l.contract.edit.add_addendum_modal_${addendum.txt_id}.title`,
                        `${addendum.title}`
                      )}
                    </SelectorItem>
                  ))}
                </StyledCheckboxSelectorList>
                <StyledButton
                  onClick={handleSubmit}
                  height={50}
                  theme="primary"
                >
                  {t('common.add', 'Add')}
                </StyledButton>
              </StyledForm>
            )
          }}
        </Formik>
      </ContentWrapper>
    </Wrapper>
  )
}

export const show = createModal(AddAddendumModal, { width: '540px' })
