import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Wrapper = styled.div`
  display: flex;
  position: relative;

  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`

const InnerWrapper = styled.div`
  position: absolute;
  height: ${props => props.radius * 2 - 20}px;
  width: ${props => props.radius * 2 - 20}px;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProgressRing = ({ radius, stroke, progress: initialProgress, children }) => {
  const progress = !isNaN(initialProgress) ? initialProgress : 0
  const normalizedRadius = radius - stroke * 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - (progress / 100) * circumference
  return (
    <Wrapper>
      <InnerWrapper radius={radius}>{children}</InnerWrapper>
      <svg height={radius * 2 - 20} width={radius * 2 - 20}>
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(60)">
            <stop offset="70%" stopColor={colors.secondary} />
            <stop offset="100%" stopColor={colors.secondaryLightBlue} />
          </linearGradient>
        </defs>
        <circle
          stroke="#c8c8c8"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius - 10}
          cy={radius - 10}
        />
        <circle
          stroke="url(#gradient)"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius - 10}
          cy={radius - 10}
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  )
}

export default ProgressRing
