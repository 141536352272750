import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Input } from 'components/form'
import { Anchor } from 'components/anchor'
import P from 'components/paragraph'
import Tool from 'components/tools'
import { path } from 'ramda'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  LoginContainer as Login,
  TaskbarContainer,
  TaskbarItem,
  Title
} from './elements'

const RedirectText = styled.div`
  margin-bottom: 45px;
`
const LoginContainer = styled(Login)`
  height: 400px;
`

const DescriptionText = styled(P)`
  margin: 30px 20px;
`

const AccessText = styled(P)`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  margin: 0;
`

const SmallAnchor = styled(Anchor)`
  font-size: ${fontSizes.small};
`

const EmailInputWrapper = styled.div`
  background-color: ${colors.white};
  border-radius: 5px;
  width: 100%;
`

const EmailForm = styled(Form)`
  display: flex;
  flex-direction: row;
  margin: 0 20px 20px;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  overflow: hidden;
  background-color: ${colors.white};
  width: 80%;

  & fieldset {
    background-color: ${colors.white};
    margin-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 20px 10px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    margin: 0 20px 0;
  }
`

const EmailInputBox = styled(Input)`
  display: block;
  border-radius: 0px;
  border: none;
  flex: 1;
  padding: 0 10px;
`

const Submit = styled(Input)`
  background-color: ${colors.secondary};
  border-radius: 5px;
  width: auto;
  height: 40px;
  border: none;
  color: ${colors.white};
  padding: 0 10px;
  margin: 5px;
  -webkit-appearance: none;
  appearance: none;

  &:hover {
    opacity: 0.5;
  }
`

const EmailContainer = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const sendCode = dispatch.auth.sendEmailCode
  const codeSentTo = useSelector(path(['auth', 'codeSentTo']))

  const formOptions = {
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required()
    })
  }

  return codeSentTo ? (
    <Redirect to="/auth/code/email" />
  ) : (
    <>
      <Helmet>
        <title>{t('b.auth.view.enter_email.title', 'Enter Your Email')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LoginContainer>
        <TaskbarContainer>
          <TaskbarItem left={<Tool to="/" type="back" />} />
          <Title>
            {t('b.auth.view.enter_email.title', 'Enter Your Email')}
          </Title>
        </TaskbarContainer>
        <DescriptionText>
          {t(
            'b.auth.view.enter_email_instructions.text',
            'An authentication code will be sent to the email entered below.'
          )}
        </DescriptionText>
        <Formik {...formOptions} onSubmit={sendCode}>
          {({ handleChange }) => (
            <EmailForm>
              <EmailInputWrapper>
                <EmailInputBox
                  name="email"
                  autoFocus
                  placeholder={t('b.auth.view.email.label', 'Email')}
                  onChange={handleChange('email')}
                />
              </EmailInputWrapper>
              <Submit
                type="submit"
                name="submit"
                value={t('b.auth.view.send.button', 'Send')}
              />
            </EmailForm>
          )}
        </Formik>
        <RedirectText>
          {
            // prettier-ignore
            <AccessText>
              {t(
                'b.auth.view.email_access.text',
                'Don\'t have access to your email?'
              )}
            </AccessText>
          }
          <SmallAnchor to="/auth/login/" replace>
            {t('b.auth.view.login_phone.text', 'Login via Phone Number')}
          </SmallAnchor>
        </RedirectText>
      </LoginContainer>
    </>
  )
}

export default EmailContainer
