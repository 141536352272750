import { createSelector } from 'reselect'
import {
  values,
  pipe,
  prop,
  pick,
  last,
  merge,
  sortBy,
  defaultTo,
  when
} from 'ramda'

import { isTruthy } from 'utils'

export const supportRoomSelector = createSelector(
  prop('room_info'),
  prop('messages'),
  (roomInfo, messages) =>
    when(
      isTruthy,
      pipe(
        pick(['last_user_read_at', 'last_agent_event_at']),
        merge({
          last_event_message: pipe(values, last, prop('event_info'))(messages)
        })
      )
    )(roomInfo)
)

export const supportRoomEventsSelector = createSelector(
  prop('messages'),
  pipe(values, defaultTo([]), sortBy(prop('created_at')))
)
