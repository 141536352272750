import React from 'react'
import { connect } from 'react-redux'
import { select } from 'store'

const mapState = (state, ownProps) => {
  const agreement = select.agreement.agreements(state, ownProps) || {}
  return { title: agreement.title }
}

const Title = ({ title }) => title && <p>{title}</p>

Title.defaultProps = {
  title: null
}

const AgreementTitle = connect(mapState)(Title)

export default AgreementTitle
