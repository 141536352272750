import React from 'react'
import styled from 'styled-components'
import { breakpoints, fontSizes, fontWeights, colors } from 'styles'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toInteger } from 'lodash'
import useBasic from 'hooks/use-basic'
import capitalize from 'utils/capitalize'
import { showTenantProfile } from 'features/Profile/TenantProfileModal'
import { show as showChatModal } from 'features/Chat/chat-modal'
import Icon from 'components/icon'
import { AvatarWithStatus } from 'components/avatar'
import { show as showRequirementModal } from './requirement-modal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 442px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey};
  border-radius: 6px;
  box-shadow: 0 0 5px 2px ${colors.grey};
  overflow: hidden;

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    width: 100%;
  }
`

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 30px 25px 0;
  cursor: pointer;
`

const RemoveButton = styled.button`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: ${colors.white};
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const JobText = styled.p`
  margin: 0px;
  font-size: ${fontSizes.small};
  color: ${colors.regular};
  line-height: 18px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid ${colors.mediumLightGrey};
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ItemLabel = styled.p`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  line-height: 22px;
  padding-top: 20px;
  margin: 0;
`

const ItemValue = styled(ItemLabel)`
  font-weight: ${fontWeights.medium};
`

const ChatButton = styled.button`
  height: 42px;
  width: 100%;
  color: ${colors.white};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  line-height: 20px;
  background: ${colors.secondary};
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Card = ({ tenant, listingId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const userData = useSelector(state => state.profile)
  const { professions } = useBasic(['professions'])

  const formatSalaryRange = salary => {
    if (salary === 'No Income') {
      return t('l.listing.view.suggested_tenant_no_income.text', 'No income')
    } else if (salary === 'Rent Prepay') {
      return t(
        'l.listing.view.suggested_tenant_rent_prepay.text',
        'Rent prepay'
      )
    }
    return salary
      .split('-')
      .map(val => `$${val}`)
      .join(' - ')
  }

  const formatBudgetRange = (minBudget, maxBudget) => {
    const min = toInteger(minBudget)
    const max = toInteger(maxBudget)
    const hasMin = min > 0
    const hasMax = max > 0
    const minString = parseFloat(min, 10).toLocaleString('en-US')
    const maxString = parseFloat(max, 10).toLocaleString('en-US')

    if (hasMin && hasMax) {
      return `$${minString} - $${maxString}`
    } else if (hasMin && !hasMax) {
      return `> $${minString}`
    } else if (hasMax && !hasMin) {
      return `< $${maxString}`
    }
    return t(
      'l.listing.view.suggested_tenant_no_preference.text',
      'No preference'
    )
  }

  const isVerified = () => {
    const companyInfo = userData?.company_info
    const isBusinessLandlord = companyInfo?.account_type === 'business'
    return (
      (isBusinessLandlord
        ? companyInfo?.business_licence_verified_state
        : userData?.identity_verified_state) === 'verified'
    )
  }
  const handleClickChat = () => {
    if (!isVerified() || !userData?.avatar?.id) {
      showRequirementModal({ userData, isVerified, history })
    } else {
      showChatModal({
        tenant,
        sendMessage: message =>
          dispatch.listing.landlordSendFirstMessage({
            listingId,
            message,
            tenantId: tenant?.id
          })
      })
    }
  }

  const handleRemoveTenant = e => {
    e.stopPropagation()
    dispatch.listing.removeSuggestedTenant({
      listingId,
      userId: tenant?.id
    })
  }

  const handleViewProfile = () => {
    showTenantProfile({
      data: tenant,
      handleClickChat,
      handleRemoveTenant
    })
  }

  return (
    <Wrapper>
      <ContentWrapper onClick={handleViewProfile}>
        <RemoveButton
          onClick={handleRemoveTenant}
          type="button"
          aria-label="Remove"
        >
          <Icon id="close" width={22} height={22} />
        </RemoveButton>
        <AvatarWithStatus data={tenant} />
        <InfoWrapper>
          <JobText>{capitalize(professions[tenant.job_title].name)}</JobText>
          <ItemRow>
            <ItemLabel>
              {t(
                'l.listing.view.suggested_tenant_salary_range.label',
                'Salary range'
              )}
            </ItemLabel>
            <ItemValue>{formatSalaryRange(tenant.salary)}</ItemValue>
          </ItemRow>
          <ItemRow>
            <ItemLabel>
              {t('l.listing.view.suggested_tenant_budget.label', 'Budget')}
            </ItemLabel>
            <ItemValue>
              {formatBudgetRange(
                tenant.user_search_preferences.min_price,
                tenant.user_search_preferences.max_price
              )}
            </ItemValue>
          </ItemRow>
        </InfoWrapper>
      </ContentWrapper>
      <ChatButton onClick={handleClickChat} theme="primary">
        {t('l.listing.view.suggested_tenant_chat.button', 'Chat')}
      </ChatButton>
    </Wrapper>
  )
}

export default Card
