import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { getFees } from 'models/payment/ports'
import { NamedWizard, WizardContent } from 'components/wizard'
import Page from 'components/page'
import PaymentMethod from './payment-method'
import Details from './details'
import Context from './context'

const WizardWrapper = styled.div`
  background: ${colors.white};
`

const StyledNamedWizard = styled(NamedWizard)`
  margin: 0 auto;
  padding: 0 20px;
  width: ${breakpoints.tabletPortrait};
  background: transparent;
  border-bottom: none;

  > div {
    padding: 0px;
  }
`

const MakePayment = ({ paymentData, history }) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState('info')
  const [formData, setFormData] = useState({})
  const [feesInfo, setFeesInfo] = useState(null)

  useEffect(() => {
    const getFeesInfo = async () => {
      const { body } = await getFees({
        body: {
          amount: paymentData.requested_amount
        }
      })
      body && setFeesInfo(body)
    }
    if (paymentData) {
      getFeesInfo()
    }
  }, [paymentData])

  if (!paymentData) return null

  const WIZARD_STEPS = {
    info: t('r.payment.edit.make_payment_payment_info.text', 'Payment Info'),
    details: t(
      'r.payment.edit.make_payment_review_details.text',
      'Review Details'
    )
  }

  const handleBack = () => {
    if (currentStep === 'details') {
      setCurrentStep('info')
    } else {
      history.goBack()
    }
    window.scrollTo(0, 0)
  }

  return (
    <>
      <WizardWrapper>
        <StyledNamedWizard steps={WIZARD_STEPS} currentStep={currentStep} />
      </WizardWrapper>
      <Context.Provider
        value={{ formData, setFormData, paymentData, feesInfo, setFeesInfo }}
      >
        <WizardContent currentStep={currentStep}>
          <Page index="info">
            <PaymentMethod
              setCurrentStep={setCurrentStep}
              handleBack={handleBack}
            />
          </Page>
          <Page index="details">
            <Details handleBack={handleBack} />
          </Page>
        </WizardContent>
      </Context.Provider>
    </>
  )
}

export default MakePayment
