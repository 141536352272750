import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from 'styles'
import Default from './default'
import FileImage from './file-image'
import FileSecure from './file-secure'
import Message from './message-text'
import SupportMessage from './support-message-text'
import SupportImage from './support-message-image'
import SupportFile from './support-message-file'

const MessageBubble = styled.div`
  display: flex;
  max-width: 360px;
  min-width: 80px;
  min-height: 50px;
  margin-top: 5px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 ${colors.grey};
  overflow: hidden;
  word-break: break-word;
  word-wrap: break-word;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) and (min-width: ${breakpoints.ipadMiniMax}) {
    max-width: 270px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 270px;
  }
`

const LeftChat = styled(MessageBubble)`
  :before {
    content: '';
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 9px solid rgba(0, 0, 0, 0.05);
    top: 17px;
    left: -9px;
  }

  :after {
    content: '';
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 9px solid white;
    top: 15px;
    left: -9px;
  }
`

const RightChat = styled(MessageBubble)`
  :before {
    content: '';
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 9px solid rgba(0, 0, 0, 0.05);
    border-right: none;
    top: 17px;
    right: -9px;
    left: unset;
  }

  :after {
    content: '';
    position: absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 9px solid ${colors.secondary};
    border-right: none;
    top: 15px;
    right: -9px;
    left: unset;
  }
`

const ChatBubbleWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isThem ? 'row' : 'row-reverse')};
  align-items: flex-end;
`

const SecondaryEvent = styled(Default)`
  width: 100%;
`

const Events = {
  chat: Message,
  chat_timeline_deleted: Message,
  chat_file_img: FileImage,
  chat_file_secure: FileSecure,
  chat_first_message: Message,
  chat_initial_message: Message,
  support_room_event_chat: SupportMessage,
  support_room_internal_note: SupportMessage,
  support_room_event_chat_bot_file_img: SupportImage,
  support_room_event_chat_bot_file_secure: SupportFile,
  listing_document_approval_accepted: SupportMessage,
  listing_document_approval_declined: SupportMessage
}

const ChatEvent = ({ event, isThem, translatedText }) => {
  const Event = Events[event?.event_type] || Default
  const isChat = event?.event_type.match(
    /^(chat|support_room_event_chat|chat_initial_message)$/
  )
  const isChatAndCard = event?.event_type.includes('listing_document_approval')
  const ChatBubble = isThem ? LeftChat : RightChat

  if (isChatAndCard) {
    return (
      <>
        <MessageBubble>
          <Event isThem={isThem} event={event} />
        </MessageBubble>
        <MessageBubble>
          <SecondaryEvent isThem={isThem} event={event} />
        </MessageBubble>
      </>
    )
  }

  return isChat ? (
    <ChatBubbleWrapper isThem={isThem}>
      <ChatBubble>
        <Event translatedText={translatedText} isThem={isThem} event={event} />
      </ChatBubble>
    </ChatBubbleWrapper>
  ) : (
    <MessageBubble>
      <Event isThem={isThem} event={event} />
    </MessageBubble>
  )
}

export default React.memo(ChatEvent)
