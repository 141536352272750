import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import PageTitle from 'components/page-title'
import Support from 'features/Settings/Support'

const SupportPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t('b.support.view.support.title', 'Support')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{t('b.support.view.support.title', 'Support')}</PageTitle>
      <Support />
    </>
  )
}

export default SupportPage
