import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import useRole from 'hooks/use-role'

const ProfileButtonBar = ({
  handleBack,
  isSubmitting,
  children,
  buttonText,
  history,
  ...rest
}) => {
  const [t] = useTranslation()
  const role = useRole()
  const isLandlord = role === 'landlord'
  const onHandleBack = () => {
    if (handleBack) {
      return handleBack()
    }
    history.push(isLandlord ? '/profile' : '/profile/basic-info')
  }
  return (
    <ButtonBar {...rest} sticky>
      <Button
        theme="sane"
        width={150}
        inversed
        disabled={isSubmitting}
        onClick={onHandleBack}
      >
        {!buttonText ? t('l.button_bar.edit.back.button', 'Back') : buttonText}
      </Button>
      {children}
    </ButtonBar>
  )
}
export default withRouter(ProfileButtonBar)
