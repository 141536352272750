import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import useChatCount from 'hooks/use-chat-count'
import Icon from 'components/icon'
import Required from 'components/required-indicator'

const MobileIndicator = styled(Required)`
  position: absolute;
  right: ${props => props.right || 0};
  top: ${props => props.top || 0};
`

const Wrapper = styled.div`
  ${props =>
    props.showCount &&
    `
    width: 31px;
    height: 20px;
  `}
  position: relative;
  margin-left: ${props => props.marginLeft || 0};
  margin-right: ${props => props.marginRight || 0};
`

const Balloon = styled.span`
  position: absolute;
  right: ${props => props.right || 0};
  top: ${props => props.top || 0};
  background-color: ${colors.primary};
  border-radius: 15px;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
  text-align: center;
  color: ${colors.white};
  font-size: 10px;
  font-weight: ${fontWeights.medium};
  padding: 0 3px;

  ${props =>
    props.large &&
    `
      height: 24px;
      min-width: 24px;
      line-height: 24px;
      font-size: 14px;
      padding: 0 5px;
    `}
`

const truncateNumber = n => (n > 99 ? '99+' : n.toString())

const ChatNotification = ({
  showIcon,
  type,
  showCount = true,
  load = false,
  top,
  right,
  marginLeft,
  marginRight,
  large
}) => {
  const dispatch = useDispatch()
  const unreadCount = useChatCount(type)
  useEffect(() => {
    if (!load) return
    dispatch.chat.checkUnreadMessages()
  }, [dispatch.chat, load])

  return (
    <Wrapper
      marginLeft={marginLeft}
      marginRight={marginRight}
      showCount={showCount}
    >
      {showIcon && <Icon id="chat" width={31} height={25} />}
      {!!unreadCount &&
        (showCount ? (
          <Balloon large={large} top={top} right={right}>
            {truncateNumber(unreadCount)}
          </Balloon>
        ) : (
          <MobileIndicator top={top} right={right} />
        ))}
    </Wrapper>
  )
}

export default React.memo(ChatNotification)
