import { useEffect } from 'react'
import useProfile from 'hooks/use-profile'
import { useSelector } from 'react-redux'
import { path } from 'ramda'
import useRole from 'hooks/use-role'
import { showWelcomeModal } from './welcome-modal'

const WelcomeModal = () => {
  const session = useSelector(path(['session', 'session']))
  const data = useProfile()
  const userId = session?.id
  const isTenant = useRole() === 'tenant'

  useEffect(() => {
    if (!isTenant || !session || !data.id) return

    const welcomeFormComplete =
      !!data.avatar?.id && !!data.salary && !!data.job_title
    const hasLoggedBefore = localStorage.getItem('@session:hasLoggedBefore')
    if (
      userId &&
      !hasLoggedBefore &&
      !welcomeFormComplete &&
      data.registration_complete === '1'
    ) {
      showWelcomeModal()
      localStorage.setItem('@session:hasLoggedBefore', true)
    }
  }, [session, data, isTenant, userId])

  return null
}

export default WelcomeModal
