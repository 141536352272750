import React from 'react'
import { Anchor, Button } from 'components/anchor'
import * as Tools from 'components/tools/tools'

const Tool = ({ type, to, onClick, ...rest }) => {
  const ToolComponent = Tools[type]

  if (ToolComponent) {
    if (to) {
      return (
        <Anchor to={to} {...rest}>
          <ToolComponent />
        </Anchor>
      )
    }

    if (onClick) {
      return (
        <Button onClick={onClick} {...rest}>
          <ToolComponent />
        </Button>
      )
    }
  }

  return null
}

Tool.defaultProps = {
  type: '',
  to: null,
  onClick: null
}

export default Tool
