import React from 'react'
import { FieldArray } from 'formik'
import { assoc, find, findIndex, propEq } from 'ramda'
import { Error } from 'components/form'
import SelectorList from './selector-list-base'

const CheckboxSelectorList = ({
  name,
  defaultType = 'private',
  value = [],
  children,
  ...rest
}) => (
  <>
    <SelectorList {...rest}>
      <FieldArray name={name}>
        {arrayHelpers =>
          React.Children.map(children, child => {
            return React.cloneElement(child, {
              name,
              type: 'checkbox',
              checked: child.props.checked
                ? child.props.checked
                : find(propEq('txt_id', child.props.value), value),
              currentType: find(propEq('txt_id', child.props.value), value)
                ?.type,
              onChange: child.props.onChange
                ? child.props.onChange
                : e => {
                    const target = e.target
                    if (target.checked) {
                      arrayHelpers.push({
                        name: child.props.featureName,
                        txt_id: target.value,
                        type: defaultType
                      })
                    } else {
                      const idx = findIndex(
                        propEq('txt_id', target.value),
                        value
                      )
                      arrayHelpers.remove(idx)
                    }
                  },
              onTypeChange: (val, type) => {
                const idx = findIndex(propEq('txt_id', val), value)
                const newVal = assoc('type', type, value[idx])
                arrayHelpers.replace(idx, newVal)
              }
            })
          })
        }
      </FieldArray>
    </SelectorList>
    <Error name={name} />
  </>
)

export default React.memo(CheckboxSelectorList)
