import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { colors, fontSizes, fontWeights } from 'styles'

const HorizontalStyle = css`
  border-right: 1px solid ${colors.grey};
  flex: 1;
  color: ${p => (p.isSelected ? colors.white : colors.regular)};
  background: ${p => (p.isSelected ? colors.dark : colors.white)};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VerticalStyle = css`
  border-bottom: 1px solid ${colors.grey};
  color: ${p => (p.isSelected ? colors.mediumLightGrey : colors.regular)};
  height: 60px;
  background: ${colors.white};
  padding: 20px;
  &:hover,
  &:active {
    background: ${p => (p.isSelected ? colors.white : colors.regular)};
    color: ${p => (p.isSelected ? colors.mediumLightGrey : colors.white)};
  }
`

const Wrapper = styled.div.attrs(props => ({
  align: ({ horizontal }) => (horizontal ? 'center' : 'left')
}))`
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};

  ${({ horizontal }) => (horizontal ? HorizontalStyle : VerticalStyle)}

  ${({ customStyle }) => customStyle}
`

const OptionItem = ({ children, value, onClick, ...rest }) => (
  <Wrapper {...rest} onClick={() => onClick(value)}>
    {children}
  </Wrapper>
)

OptionItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object
  ])
}

OptionItem.defaultProps = {
  isSelected: false,
  onClick: () => {},
  value: null
}

export default OptionItem
