import { path } from 'ramda'
import { agreeToAgreements, getAgreement } from 'ports'
import selectorBuilder from 'models/selector-builder'
import agreementSelector from './selectors'

const agreement = {
  state: {
    currentAgreement: null,
    agreements: {},
    mismatched: {}
  },
  reducers: {
    update: (state, payload) => ({ ...state, ...payload })
  },
  effects: dispatch => ({
    validateAgreements: (payload, { basic, session }) => {
      const baseAgreements = path(['agreements'], session)
      const userAgreements = path(['session', 'agreements'], session) || {}
      const mismatched =
        Array.isArray(userAgreements) && !userAgreements.length
          ? baseAgreements
          : {}
      Object.keys(userAgreements).forEach(key => {
        const userAgreement = userAgreements[key]
        const baseAgreement = baseAgreements[key]

        if (userAgreement.system_agreement_version !== baseAgreement.version) {
          mismatched[key] = baseAgreement
        }
      })

      dispatch.agreement.update({ mismatched })
    },
    async loadAgreement(agreementId) {
      try {
        const response = await getAgreement({ agreementId })

        if (response.response.ok) {
          dispatch.agreement.update({ currentAgreement: response.body })
        }
      } catch (error) {
        console.error('[agreement/loadAgreement] Error occured: ', error)
      }
    },
    async agreeToAgreements(payload, rootScope) {
      try {
        const agreementIds = Object.keys(rootScope.agreement.mismatched)
        const response = await agreeToAgreements({ body: agreementIds })

        if (response.response.ok) {
          dispatch.agreement.update({ mismatched: {} })
        }
      } catch (error) {
        console.error('[agreement/agreeToAgreements] Error occured: ', error)
      }
    }
  }),
  selectors: selectorBuilder(slice => ({
    agreements() {
      return slice(agreementSelector)
    }
  }))
}

export default agreement
