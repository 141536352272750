import { prop, pipe, omit, set, lensPath, path } from 'ramda'
import moment from 'moment'
import { headObj } from 'utils'
import selectorBuilder from 'models/selector-builder'
import config from 'config'
import {
  getSupportRoom,
  updateSupportRoom,
  getEvents,
  sendMessage,
  sendFile,
  sendImage
} from './ports'
import { supportRoomSelector, supportRoomEventsSelector } from './selectors'

const INITIAL_STATE = {}
const supportRoom = {
  name: 'supportRoom',
  state: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    saveEvents: (state, payload) => {
      return {
        ...state,
        messages: {
          ...state.messages,
          payload
        }
      }
    },
    update: (state, payload) => ({
      ...state,
      ...payload
    }),
    patch: (state, payload) => ({
      ...state,
      room_info: {
        ...state.room_info,
        ...payload.room_info
      },
      messages: {
        ...state.messages,
        ...payload.messages
      }
    }),
    addEvent: (state, payload) =>
      set(lensPath(['messages', payload.id]), payload, state),
    removePhantom: (state, passthrough) => {
      const messages = omit([passthrough], state.messages)

      return {
        ...state,
        messages
      }
    }
  },
  effects: dispatch => ({
    getEvents: async payload => {
      try {
        const response = await getEvents({
          lastEventId: payload.lastEventId
        })

        if (response.response.ok) {
          dispatch.supportRoom.saveEvents({
            ...response.body
          })
        }
      } catch (error) {
        console.log('[Chat/getEvents]', error)
      }
    },
    getSupportRoom: async (_, rootState) => {
      if (rootState.supportRoom.room_info) return
      try {
        const { body, response } = await getSupportRoom()
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.supportRoom.update(body)
      } catch (error) {
        console.log('[Chat/getSupportRoom]', error)
      }
    },
    updateSupportRoom: async supportRoomId => {
      try {
        const { body, response } = await updateSupportRoom(undefined, {
          id: supportRoomId
        })
        if (response.ok) {
          dispatch.supportRoom.update({
            room_info: body
          })
        }
      } catch (error) {
        console.log('[Chat/getSupportRoom]', error)
      }
    },
    async sendMessage(payload, rootState) {
      payload.passthrough = Date.now()
      dispatch.supportRoom.addPhantomEvent(payload)
      try {
        const { response, body } = await sendMessage(
          {
            body: { message: payload.message }
          },
          { id: rootState.supportRoom.room_info.id }
        )
        if (response.ok) {
          dispatch.supportRoom.removePhantom(payload.passthrough)
          dispatch.supportRoom.patch({
            messages: body.messages,
            room_info: headObj(body.room)
          })
        }
      } catch (error) {
        console.log('[Chat/sendMessage]', error)
      }
    },
    async sendFile(payload, rootState) {
      payload.passthrough = Date.now()
      dispatch.supportRoom.addPhantomEvent(payload)
      try {
        const { response, body } = await sendFile(
          { file: payload.file },
          { id: rootState.supportRoom.room_info.id }
        )
        if (response.ok) {
          dispatch.supportRoom.removePhantom(payload.passthrough)
          dispatch.supportRoom.patch({
            messages: body.messages,
            room_info: body.room
          })
        }
      } catch (error) {
        console.log('[Chat/sendFile]', error)
      }
    },
    async sendImage(payload, rootState) {
      payload.passthrough = Date.now()
      dispatch.supportRoom.addPhantomEvent(payload)
      try {
        const { response, body } = await sendImage(
          {
            file: payload.file,
            body: {
              tag: 'chat_image',
              name: payload.file.path,
              reference_id: Number(rootState.supportRoom.room_info.id),
              reference_type: 'chat_bot'
            }
          },
          { id: rootState.supportRoom.room_info.id }
        )
        if (response.ok) {
          dispatch.supportRoom.removePhantom(payload.passthrough)
          dispatch.supportRoom.patch({
            messages: body.messages,
            room_info: body.room
          })
        }
      } catch (error) {
        console.log('[Chat/sendFile]', error)
      }
    },
    addPhantomEvent(payload, rootState) {
      const message = {
        id: payload.passthrough,
        event_type: 'support_room_event_chat',
        pending: true,
        event_info: payload.message,
        created_at: moment.utc().format(),
        user_id: rootState.session.session.id
      }
      dispatch.supportRoom.addEvent(message)
    },
    parseSupportChatSSE(payload) {
      dispatch.supportRoom.patch({
        messages: payload.message,
        room_info: payload.room
      })
      const options = {
        body: pipe(headObj, path(['event_info', 'message']))(payload.message)
      }
      const timelineRoom = pipe(headObj, prop('timeline_room'))(payload.message)
      if ('Notification' in window && Notification.permission === 'granted') {
        const notification = new Notification('New message', options)
        notification.onclick = function (event) {
          event.preventDefault()
          location.replace(`${config.APP_PATH}/chat/${timelineRoom}`)
        }
      }
    }
  }),

  selectors: selectorBuilder(slice => ({
    roomInfo() {
      return slice(supportRoomSelector)
    },
    supportRoomEvents() {
      return slice(supportRoomEventsSelector)
    }
  }))
}

export default supportRoom
