import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import { SwitchToggle, Label } from 'components/form'
import { useTranslation } from 'react-i18next'
import { Form } from 'formik'
import {
  map,
  all,
  prop,
  includes,
  values as rValues,
  pipe,
  path,
  concat,
  equals,
  reject
} from 'ramda'
import { CheckboxSelectorList, SelectorItem } from 'components/selector-list'
import ButtonBar from 'components/button-bar'

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledCheckboxSelectorList = styled(CheckboxSelectorList)`
  border: none;
  div {
    :hover {
      opacity: 0.6;
    }
  }
`

const DescriptionText = styled.p`
  border: 1px solid ${colors.regular};
  background-color: ${colors.lightGrey};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 60px;
`

const StyledButtonBar = styled(ButtonBar)`
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  color: ${colors.white};
  background-color: ${colors.secondary};
  cursor: pointer;
  :hover {
    background-color: #8cb6e7;
  }
  div {
    justify-content: center;
  }
`

const Options = ({ addendum, values, setFieldValue, handleBackAction }) => {
  const [t] = useTranslation()

  useEffect(() => {
    if (values?.options?.length === 0) {
      setFieldValue('allowed', false)
    }
  }, [setFieldValue, values])

  const allOptions = rValues(
    addendum.listing_contract_addendum_template_options
  )

  const displayOptions = () =>
    values.allowed
      ? 'Smoking is allowed only in designated areas including: ' +
        map(
          option =>
            path(
              [option, 'title'],
              addendum.listing_contract_addendum_template_options
            ),
          values.options
        ).join(', ') +
        `. Anywhere else in the building is strictly ` +
        'prohibited under Strata Bylaw S2.3'
      : 'We would like to clarify that as per your tenancy agreement, your ' +
        'suite is designated "non-smoking". This also applies to the use of ' +
        'cannabis, e-cigarettes (vaping) and similar devices.  Smoking is ' +
        'prohibited inclusive of balconies (limited common area). This is a ' +
        'mandatory term for your tenancy.  Please note that a breach of a ' +
        'mandatory term will result in a notice to end tenancy. \n\n' +
        'Growing marijuana/cannabis is not permitted in the unit, especially ' +
        'due to humidity, mold and odor issues. Please note: growing marijuana ' +
        'plants is also not permitted on limited common areas such as ' +
        'patios and balconies.'

  const setOptions = value =>
    setFieldValue(
      'options',
      value ? pipe(map(prop('txt_id')), rValues)(allOptions) : []
    )

  const handleSelectAll = e => setOptions(e.target.checked)

  const handleSelect = (options, id) =>
    includes(id, options)
      ? setFieldValue('options', reject(equals(id), options))
      : setFieldValue('options', concat(options, [id]))

  const handleToggle = e => {
    setFieldValue('allowed', e)
    setOptions(e)
  }

  // prettier-ignore
  return (
    <Form>
      <ToggleWrapper>
        <Label>
          {t('l.addendums.edit.smoking_allowed.label', 'Smoking Allowed')}
        </Label>
        <SwitchToggle
          onClick={handleToggle}
          name="allowed"
          enabled={values.allowed}
        />
      </ToggleWrapper>
      {values.allowed && (
        <StyledCheckboxSelectorList name="options" value={values.options}>
          <SelectorItem
            value="all_selected"
            onChange={handleSelectAll}
            checked={all(
              option => includes(option, values.options),
              pipe(
                map(prop('txt_id')),
                rValues
              )(allOptions)
            )}
          >
            {t(
              'l.contract.edit.add_addendum_modal_select_all.label',
              'Select all addendums'
            )}
          </SelectorItem>
          {map(
            option => (
              <SelectorItem
                key={option.txt_id}
                value={option.txt_id}
                onChange={() => handleSelect(values.options, option.txt_id)}
                checked={includes(option.txt_id, values.options)}
              >
                {t(
                  `l.contract.edit.addendum_modal_smoking_${option.txt_id}.title`,
                  `${option.title}`
                )}
              </SelectorItem>
            ),
            allOptions
          )}
        </StyledCheckboxSelectorList>
      )}
      <DescriptionText>{displayOptions(values)}</DescriptionText>
      <StyledButtonBar onClick={handleBackAction} height={50} sticky>
        {t('common.save', 'Save')}
      </StyledButtonBar>
    </Form>
  )
}

export default Options
