import ENV_VARS from 'config'
import { get, put, post } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const getEvents = get(`${BACKEND_SERVER}/chatbots/%id%/more/%page%/id`)
export const getSupportRoom = get(`${BACKEND_SERVER}/timeline/support_rooms`)

export const sendMessage = put(`${BACKEND_SERVER}/chatbots/%id%/user`, {
  noLoading: true
})
export const sendFile = post(`${BACKEND_SERVER}/chatbots/%id%/upload/secure`, {
  noLoading: true
})
export const sendImage = post(`${BACKEND_SERVER}/chatbots/%id%/upload/img`, {
  noLoading: true
})

export const updateSupportRoom = put(`${BACKEND_SERVER}/chatbots/%id%/update`, {
  noLoading: true
})
