import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { find, propEq } from 'ramda'
import { breakpoints, fontSizes, fontWeights } from 'styles'
import Panel from 'components/panel'
import { Row } from 'components/form'
import placeholderImage from './empty-placeholder@2x.png'

const EmptyText = styled.p`
  font-size: ${fontSizes.small};
  margin: 0 0 5px;
`

const Placeholder = styled.img`
  width: 375px;
  height: auto;
  margin: 50px auto;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    width: 250px;
  }
`

const AddressText = styled.p`
  font-size: 16px;
  letter-spacing: -0.39px;
  line-height: 22px;
  margin: 0;
  font-weight: ${fontWeights.medium};
`

const View = ({ hasAddress, addressData, formattedCountries }) => {
  const { t } = useTranslation()

  const getCountryName = countryCode => {
    return find(propEq('value', countryCode), formattedCountries).label
  }

  const formatAddress = ({ street1, street2, city, state, country, zip }) => {
    const fullCountry = getCountryName(country)
    return `${street1}, ${
      street2 ? street2 + ',' : ''
    } ${city}, ${state} ${fullCountry} ${zip.toUpperCase()}`
  }

  return (
    <Panel vSpace={40} hSpace={40}>
      {hasAddress ? (
        <>
          <Row>
            <EmptyText>
              {t('r.settings.edit.current_address.label', 'Current address')}:
            </EmptyText>
          </Row>
          <Row>
            {addressData && (
              <AddressText>{formatAddress(addressData)}</AddressText>
            )}
          </Row>
        </>
      ) : (
        <>
          <Row>
            <EmptyText>
              {t(
                'r.settings.edit.no_billing_address.text',
                'You haven’t saved a billing address yet.'
              )}
            </EmptyText>
          </Row>
          <Row>
            <Placeholder src={placeholderImage} alt="" />
          </Row>
        </>
      )}
    </Panel>
  )
}

export default View
