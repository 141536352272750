import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { toInteger, toNumber } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { colors, fontSizes, breakpoints, fontWeights } from 'styles'
import { getImageUrl } from 'utils/image'
import { select } from 'store'
import config from 'config'
import {
  equals,
  isEmpty,
  times,
  always,
  head,
  propEq,
  anyPass,
  path
} from 'ramda'
import { isNotEmpty } from 'utils'
import useRole from 'hooks/use-role'
import useCurrentSession from 'hooks/use-current-session'
import useWindowDimensions from 'hooks/use-window-dimensions'
import { Button } from 'components/button'
import PageTitle from 'components/page-title'
import PageSubtitle from 'components/page-subtitle'
import PhotoSlider from 'components/photo-slider'
import ListingSection from 'components/listing-section'
import ButtonBar from 'features/Listing/button-bar'
import BuildingInfo from 'features/Building/building-info'
import hideUnit from 'utils/hide-unit'
import DashboardHeader from 'features/Listing/dashboard-header'
import { defaultAvatar } from 'components/avatar'
import ListingSummary from './listing-summary'
import SummaryBlock from './summary-block'
import LeaseDetails from './lease-details'
import { AmenitiesList } from './amenities-list'
import TransitInfo from './transit-info'
import { show as showPublishModal } from './publish-modal'

import defaultBuilding from './default-building.jpg'

const isDraft = propEq('state_machine', 'draft')
const isPublished = propEq('state_machine', 'published')
const isRented = propEq('state_machine', 'rented')
const isPending = propEq('state_machine', 'pending')
const showAddress = anyPass([isPublished, isRented, isPending])

const { WEB_URL } = config

const Wrapper = styled.div`
  background: ${colors.white};
`

const ListingInfo = styled.div`
  position: relative;
  display: flex;
  width: 95%;
  max-width: 1096px;
  min-height: 400px;
  margin-right: auto;
  margin-left: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 100%;
    margin-top: 0;
  }
`

const ListingInfoLeft = styled.div`
  display: block;
  max-width: 672px;
  margin: 0 30px 0 0;

  @media (max-width: 1165px) {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 715px) {
    width: 100%;
    margin-bottom: 80px;
  }
`

const MobilePadding = styled.div`
  padding: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 0 25px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 0 15px;
  }
`

const ListingInfoRight = styled.div`
  display: block;
  max-width: 350px;
  min-width: 330px;

  @media (max-width: 1165px) {
    display: none;
  }
`

const Spacer = styled.div`
  height: 80px;
`

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  line-height: 26px;
  box-shadow: inset 0 -1px 0 0 #d8d8d8;
  ${props =>
    props.column &&
    `
    flex-direction: column;
    height: auto;
  `}

  &:last-child {
    box-shadow: none;
  }
`

const DetailRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  line-height: 26px;
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }
`

const DetailText = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin: 16px 0;
`

const DetailTextBold = styled(DetailText)`
  font-weight: ${fontWeights.medium};
`

const equalsOne = equals('1')

const ListingDetail = ({ match: { params }, noTitle, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { width: windowWidth } = useWindowDimensions()
  const session = useCurrentSession()
  const data =
    useSelector(state =>
      select.listing.listingDetail(state, {
        listingId: params.listingId
      })
    ) || {}
  const contract =
    useSelector(path(['listing', params.listingId, 'contract'])) || {}
  const {
    building,
    listings,
    unit,
    users,
    unitImages,
    unitVideos,
    buildingImages
  } = data

  useEffect(() => {
    dispatch.listing.load(params.listingId)
  }, [dispatch.listing, params.listingId])

  useEffect(() => {
    if (isNotEmpty(data)) {
      if (!params.mode && isDraft(data.listings)) {
        history.replace(`/listing/${params.listingId}/preview/details`)
      } else if (params.mode && !isDraft(data.listings)) {
        history.replace(`/listing/${params.listingId}/details`)
      }
    }
  }, [data, history, params.listingId, params.mode])

  const isTenant = useRole() === 'tenant'

  if (isEmpty(data) || !listings) {
    return null
  }

  const handleSave = () => {
    history.push('/listings')
  }
  const handlePublish = async () => {
    const { response, body } = await dispatch.listing.update({
      listing_id: listings.id,
      state_machine: 'published'
    })

    if (response.ok) {
      const updatedListings = path(['listings', listings.id], body)
      const published = isPublished(updatedListings)
      return showPublishModal({
        isPublished: published,
        goToSuggestedTenants: () =>
          history.replace(`/listing/${params.listingId}/suggested-tenants`),
        onClose: () => history.push('/listings')
      })
    }
  }
  const isHouse = () => {
    return unit.unit_type_txt_id !== 'condo'
  }
  const getAddress = () => {
    const { full_street_name, street_name, city, state } = building
    return isStreetNumberHidden()
      ? `${street_name}, ${city}, ${state}`
      : `${full_street_name}, ${city}, ${state}`
  }
  const getPartialAddress = () => {
    const { full_street_name, street_name, city, state } = building

    return isStreetNumberHidden()
      ? `${street_name}, ${city}, ${state}`
      : `${full_street_name}, ${city}`
  }
  const unitHidden = hideUnit(unit, contract) && isTenant
  const getUnitNumber = () => {
    return unitHidden ? null : unit.unit_number
  }
  const getCompanyName = () => {
    return unit.company_name
  }
  const getLandlord = () => {
    const landlordId = Number(listings.primary_landlord)
    const userImage = users[landlordId].avatar
    return {
      ...users[landlordId],
      picture: userImage
        ? getImageUrl(userImage.aws_s3_key, {
            width: 60,
            height: 60
          })
        : defaultAvatar
    }
  }
  const getBuildingPhoto = () => {
    return buildingImages.length
      ? getImageUrl(buildingImages[0].aws_s3_key)
      : defaultBuilding
  }
  const isStreetNumberHidden = () => {
    return unitHidden && isHouse()
  }
  const isFurnished = () => {
    if (listings.furnished === null)
      return t('common.furnished_available', 'Furnish Avail.')
    return equalsOne(listings.furnished)
      ? t('common.furnished', 'Furnished')
      : t('common.unfurnished', 'Unfurnished')
  }
  const smokingAllowed = () => {
    return equalsOne(listings.allow_smoking)
      ? t('b.listing.view.allowed.text', 'Allowed')
      : t('b.listing.view.no_smoking.text', 'No smoking')
  }
  const isPetAllowed = value => {
    return !!value && toInteger(value) > 0
  }
  const catAndDogText = value => {
    if (isPetAllowed(value)) {
      return t('b.listing.view.pets_allowed_count.text', {
        defaultValue: 'Max {{count}} allowed',
        count: toNumber(value)
      })
    }
    return t('b.listing.view.not_allowed.text', 'Not allowed')
  }
  const getPetsAllowed = () => {
    const { allow_pets, allow_cats, allow_dogs } = listings
    const allowPets = equalsOne(allow_pets)
    const allowCats = isPetAllowed(allow_cats)
    const allowDogs = isPetAllowed(allow_dogs)
    const petLimitations = allowPets && (allowCats || allowDogs)

    return petLimitations ? (
      <DetailItem column>
        <DetailRow>
          <DetailText>{t('b.listing.view.pets.text', 'Pets')}</DetailText>
          <DetailTextBold>
            {t('b.listing.view.allowed.text', 'Allowed')}
          </DetailTextBold>
        </DetailRow>
        <DetailRow>
          <DetailText>{t('b.listing.view.cats.text', 'Cats')}</DetailText>
          <DetailTextBold>{catAndDogText(allow_cats)}</DetailTextBold>
        </DetailRow>
        <DetailRow>
          <DetailText>{t('b.listing.view.dogs.text', 'Dogs')}</DetailText>
          <DetailTextBold>{catAndDogText(allow_dogs)}</DetailTextBold>
        </DetailRow>
      </DetailItem>
    ) : (
      <DetailItem>
        <DetailText>{t('b.listing.view.pets.text', 'Pets')}</DetailText>
        <DetailTextBold>
          {allowPets
            ? t('b.listing.view.allowed.text', 'Allowed')
            : t('b.listing.view.not_allowed.text', 'Not allowed')}
        </DetailTextBold>
      </DetailItem>
    )
  }
  const getTitle = () => {
    return showAddress(listings)
      ? building.full_street_name
      : t('l.listing.view.create_listing.title', 'Create a New Listing')
  }
  const getCarouselPhotos = unitPhotos => {
    return unitPhotos.length === 1
      ? times(always(head(unitPhotos)), 3)
      : unitPhotos
  }
  const sliderSettings = unitPhotos => {
    const showNavigation = unitPhotos.length > 1
    return {
      dots: showNavigation,
      infinite: showNavigation,
      arrows: showNavigation,
      className: 'center',
      centerMode: true,
      centerPadding: `${(windowWidth - 750) / 2}px`,
      swipeToSlide: showNavigation,
      swipe: showNavigation,
      initialSlide: showNavigation ? 0 : 1,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            centerMode: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            centerMode: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            initialSlide: 0,
            centerMode: false
          }
        }
      ]
    }
  }
  const renderCarousel = () => {
    if (!unitImages.length) return null
    return (
      <div className="photo-carousel">
        <PhotoSlider
          images={getCarouselPhotos(unitImages)}
          category="Carousel"
          action="Change photo"
          {...sliderSettings(unitImages)}
        />
      </div>
    )
  }
  const renderAboutBuilding = () => {
    if (isHouse()) return <Spacer />
    return (
      <ListingSection
        title={t('b.listing.view.about_building.text', 'About the Building')}
      >
        <BuildingInfo
          data={building}
          photo={getBuildingPhoto()}
          link={{
            text: t('b.listing.view.more_info.button', 'More Info'),
            href: `${WEB_URL}/building/${building.id}`,
            target: '_blank'
          }}
        />
      </ListingSection>
    )
  }
  const landlord = getLandlord()
  const isEditableDraft = isDraft(listings)
  let features = unit.unit_features

  //shared bathrooms need to be listed in features
  if (
    unit.unit_type_scope_txt_id !== 'entire' &&
    unit.count_full_shared_bathrooms &&
    parseFloat(unit.count_full_shared_bathrooms) > 0
  ) {
    const dummyBathroomFeature = {
      name: 'Bathroom',
      txt_id: 'bathroom',
      types: {
        private: parseFloat(unit.count_full_bathrooms || 0),
        shared: parseFloat(unit.count_full_shared_bathrooms)
      }
    }

    features = [dummyBathroomFeature, ...features]
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {showAddress(listings)
            ? building.full_street_name
            : t('l.listing.view.create_listing.title', 'Create a New Listing')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {!noTitle && <PageTitle>{getTitle()}</PageTitle>}
      <PageSubtitle>
        {t('b.rental.view.overview.link', 'Overview')}
      </PageSubtitle>
      {(isPublished(listings) || isPending(listings)) && (
        <DashboardHeader
          listingId={listings.id}
          isPublished={isPublished(listings)}
          showButtons
          currentPage={t('l.listing.view.view_details.link', 'View Details')}
        />
      )}
      {renderCarousel()}
      <ListingInfo>
        <ListingInfoLeft>
          <MobilePadding>
            <ListingSummary
              address={getAddress()}
              partialAddress={getPartialAddress()}
              furnished={isFurnished()}
              companyName={getCompanyName()}
              landlord={landlord}
              unitNumber={getUnitNumber()}
              listings={listings}
              unit={unit}
              unitVideos={unitVideos}
              areaMeasurementUnit={session.setting_size_unit}
            />
            <LeaseDetails listings={listings} unit={unit} />
            <ListingSection
              title={t('b.listing.view.amenities.text', 'Amenities')}
            >
              <AmenitiesList
                title={t(
                  'b.listing.view.features_included.text',
                  'Features included'
                )}
                amenities={features}
                translationContext="basic:b.listing.unit_features"
                customAmenities={listings.custom_features}
                showType={unit.unit_type_scope_txt_id !== 'entire'}
              />
              <AmenitiesList
                title={t(
                  'b.listing.view.utilities_included.text',
                  'Utilities included'
                )}
                amenities={listings.listing_utilities}
                translationContext="basic:b.listing.utilities"
                customAmenities={listings.custom_utilities}
                showType={unit.unit_type_scope_txt_id !== 'entire'}
              />
            </ListingSection>
            <ListingSection
              condensed
              title={t('b.listing.view.location.text', 'Location')}
              direction="row"
              border="none"
            >
              <TransitInfo
                data={building}
                unitNumber={getUnitNumber()}
                address={getAddress()}
                isHidden={isStreetNumberHidden()}
              />
            </ListingSection>
            <ListingSection
              condensed
              title={t('b.listing.view.property_rules.text', 'Property Rules')}
              direction="row"
            >
              <DetailItem>
                <DetailText>
                  {t('b.listing.view.smoking.text', 'Smoking')}
                </DetailText>
                <DetailTextBold>{smokingAllowed()}</DetailTextBold>
              </DetailItem>
              {getPetsAllowed()}
            </ListingSection>
            {renderAboutBuilding()}
          </MobilePadding>
        </ListingInfoLeft>
        <ListingInfoRight>
          <SummaryBlock
            companyName={getCompanyName()}
            landlord={landlord}
            listings={listings}
            unit={unit}
          />
        </ListingInfoRight>
      </ListingInfo>
      {isEditableDraft && (
        <ButtonBar listingId={listings.id} saveAndExit={handleSave}>
          <Button
            theme="primary"
            width={150}
            left="auto"
            onClick={handlePublish}
          >
            {t('l.listing.edit.publish.button', 'Publish Listing')}
          </Button>
        </ButtonBar>
      )}
    </Wrapper>
  )
}

export default ListingDetail
