import React from 'react'
import { Switch } from 'react-router'
import useRole from 'hooks/use-role'
import Dashboard from 'features/Profile/Dashboard'
import AuthenticatedView from 'authenticated-view'
import LandlordProfile from './LandlordProfile'
import EditLandlordProfile from './EditLandlordProfile'
import EditTenantProfile from './EditTenantProfile'
import TenantProfile from './TenantProfile'
import { EditLivScore, CreditCheck } from './TenantProfile/LivScore'
import { EditInsuranceInfo } from './TenantProfile/InsuranceInfo'

const Profile = () => {
  const role = useRole()
  return (
    <Switch>
      {role === 'landlord' ? (
        <>
          <AuthenticatedView
            exact
            path="/profile"
            component={Dashboard}
            allowTo={['landlord']}
          />
          <AuthenticatedView
            exact
            path="/profile/view"
            component={LandlordProfile}
            allowTo={['landlord']}
          />
          <AuthenticatedView
            exact
            path="/profile/edit"
            component={EditLandlordProfile}
            allowTo={['landlord']}
          />
        </>
      ) : (
        <>
          <AuthenticatedView
            exact
            path="/profile"
            component={Dashboard}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/:tab(basic-info|liv-score|references|extra-info)"
            component={TenantProfile}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/:mode(apply)/:listingId"
            component={TenantProfile}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/edit"
            component={EditTenantProfile}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/liv-score/edit"
            component={EditLivScore}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/liv-score/edit/credit-check"
            component={CreditCheck}
            allowTo={['tenant']}
          />
          <AuthenticatedView
            exact
            path="/profile/insurance-info"
            component={EditInsuranceInfo}
            allowTo={['tenant']}
          />
        </>
      )}
    </Switch>
  )
}

export default React.memo(Profile)
