import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import ArrowDownIcon from './arrow-down.svg'

const rotate = ({ isOpen }) => (isOpen ? '0deg' : '180deg')

const Header = styled.div`
  position: relative;
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid #ccc;
  padding: 0 40px 0 15px;
  font-weight: ${fontWeights.medium};
  height: 65px;
  display: flex;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  ${props =>
    props.hasArrow &&
    `
      cursor: pointer;

      &:hover {
        background-color: ${colors.lightGrey};
      }

      &:after {
        position: absolute;
        top: 0;
        right: 15px;
        height: 100%;
        width: 20px;
        content: ' ';
        background-image: url('${ArrowDownIcon}');
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-transform: rotate(${rotate});
        -moz-transform: rotate(${rotate});
        -ms-transform: rotate(${rotate});
        -o-transform: rotate(${rotate});
        transform: rotate(${rotate});
        -webkit-transition: -webkit-transform 250ms linear;
        transition: transform 250ms linear;
      }
  `}
`

export default Header
