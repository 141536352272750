import React from 'react'
import styled from 'styled-components'
import { fontSizes, fontWeights, colors } from 'styles'
import { useTranslation } from 'react-i18next'
import Icon from 'components/icon'
import RequestDetails from './request-details'

const Title = styled.h2`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  margin: 25px 0px;
`

const RowsWrapper = styled.div`
  margin-top: 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: ${props => props.top || 10}px;
  color: ${colors.mediumGrey};
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 5px;
  background-color: ${colors.lightGrey};
  opacity: ${props => (props.istrue === 'true' ? 1 : 0.5)};
`

const StyledIcon = styled(Icon)`
  display: ${props => (props.istrue === 'true' ? 'visible' : 'none')};
`

const LabelHeader = styled.p`
  margin-bottom: ${props => props.bottom || 20}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-weight: ${fontWeights.medium};
`

const LabelText = styled(LabelHeader)`
  font-weight: ${fontWeights.regular};
  white-space: nowrap;
`

const Completed = ({ data, onSubmit, listingData, endTenancyId }) => {
  const [t] = useTranslation()

  const responseEqualTo = response =>
    data?.end_response === response ? 'true' : 'false'

  return (
    <>
      <Title>
        {t(
          'r.rental_dashboard.edit.resources_end_tenancy_modal_complete.title',
          'End Tenancy Details'
        )}
      </Title>
      <RequestDetails
        data={data}
        listingData={listingData}
        showSummary={true}
      />
      {!!data?.end_response && !data?.force_requested && (
        <RowsWrapper>
          <LabelHeader bottom={5}>
            {t(
              'r.rental_dashboard.edit.resources_end_tenancy_modal_complete_response.text',
              'Response'
            )}
            :
          </LabelHeader>
          <Row top="0" istrue={responseEqualTo('accepted')}>
            <LabelText>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_complete_agreed.text',
                'Agreed to end tenancy'
              )}
            </LabelText>
            <StyledIcon istrue={responseEqualTo('accepted')} id="check" />
          </Row>
          <Row istrue={responseEqualTo('declined')}>
            <LabelText>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_complete_declined.text',
                'Does not want to end tenancy'
              )}
            </LabelText>
            <StyledIcon istrue={responseEqualTo('declined')} id="check" />
          </Row>
          <Row istrue={responseEqualTo('support_requested')}>
            <LabelText>
              {t(
                'r.rental_dashboard.edit.resources_end_tenancy_modal_complete_support.text',
                'I need Liv Support'
              )}
            </LabelText>
            <StyledIcon
              istrue={responseEqualTo('support_requested')}
              id="check"
            />
          </Row>
        </RowsWrapper>
      )}
      {data?.force_requested && (
        <RowsWrapper>
          <LabelHeader bottom={5}>
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy_modal_complete_reason.text',
              'Reason'
            )}
            :
          </LabelHeader>
          <LabelText>
            {t(`l.force_end_tenancy.${data?.force_requested.reason}.text`)}
          </LabelText>
          <LabelHeader bottom={5}>
            {t(
              'r.rental_dashboard.edit.resources_force_end_tenancy_modal_complete_message.text',
              'Please outline what you have done to resolve this issue'
            )}
            :
          </LabelHeader>
          <LabelText>{data?.force_requested.message}</LabelText>
        </RowsWrapper>
      )}
    </>
  )
}

export default Completed
