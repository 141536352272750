import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { select } from 'store'
import { path } from 'ramda'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import Content from 'components/content'
import { WhitePage } from 'components/page'
import PageTitle from 'components/page-title'
import { NamedWizard, WizardContent } from 'components/wizard'
import { confirm } from 'components/dialog'
import { toast } from 'components/toast-notifications'
import ContentWrapper from 'components/content-wrapper'
import scrollTop from 'utils/scroll-top'

import ReviewDetailsContainer from '../../review-details'
import ReviewTerms from '../../review-terms'
import ReviewSignatures from '../../review-signatures'

const WIZARD_ID = 'sign-wizard'

const VALID_STEPS = ['review', 'terms', 'sign']

const StyledButton = styled(Button)`
  padding: 0px 15px;
  min-width: 150px;
`

const getPreviousStep = step => {
  const currentStepIndex = VALID_STEPS.indexOf(step)
  return currentStepIndex === 0 ? null : VALID_STEPS[currentStepIndex - 1]
}

const validateStep = step =>
  VALID_STEPS.indexOf(step) !== -1 ? step : 'review'

const mapState = (state, ownProps) => {
  const currentStep = validateStep(ownProps.match.params.step || 'review')
  const previousStep = getPreviousStep(currentStep)
  const isPreviousStepDone = select.contract.isStepComplete(state, {
    step: previousStep
  })
  const stepStatus = select.contract.isStepComplete(state, {
    step: currentStep
  })

  return {
    stepStatus,
    signature: path(['contract', 'signature'], state),
    role: path(['session', 'session', 'operation_type'], state),
    isPreviousStepDone: previousStep ? isPreviousStepDone : null
  }
}

const mapDispatch = dispatch => ({
  clearSteps: dispatch.contract.clearSteps,
  signContract: dispatch.contract.signContract,
  signRoommate: dispatch.contract.signRoommate,
  cancelContract: dispatch.contract.cancelContract
})

class Sign extends React.Component {
  componentDidMount() {
    this.resetScroll = scrollTop(WIZARD_ID)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPreviousStepDone === false) {
      this.goToStep('review')
    }

    if (prevProps.match !== this.props.match) {
      this.resetScroll()
    }
  }

  goToStep(step) {
    const {
      history,
      match: {
        params: { listingId, contractId }
      },
      contract,
      role
    } = this.props
    if (role === 'tenant') {
      history.push(
        `/rental/${
          listingId || contract?.listingId
        }/contract/${contractId}/${step}`
      )
    } else {
      history.push(
        `/listing/${
          listingId || contract?.listingId
        }/contract/${contractId}/${step}`
      )
    }
  }

  componentWillUnmount() {
    this.props.clearSteps()
  }

  goToNextStep() {
    const {
      match: {
        params: { step = 'review' }
      }
    } = this.props
    const steps = VALID_STEPS
    const currentIndex = steps.indexOf(step)

    if (currentIndex !== steps.length - 1) {
      this.goToStep(steps[currentIndex + 1])
    }
  }

  handleBack = async () => {
    const {
      match: {
        params: { step = 'review' }
      },
      t,
      cancelContract,
      contract,
      history,
      role
    } = this.props
    if (step === 'review' && role === 'tenant') {
      history.replace(`/rental/${contract?.listingId}`)
      this.resetScroll()
    } else if (step === 'review') {
      confirm(
        t(
          'b.contract.edit.cancel_contract_confirmation.title',
          'Cancel Contract?'
        ),
        t(
          'b.contract.edit.cancel_contract_confirmation.text',
          'Are you sure you want to cancel this contract?'
        )
      ).then(async () => {
        const { response } = await cancelContract({
          contractId: contract?.id,
          listingId: contract?.listingId
        })

        if (response.ok) {
          history.replace(`/listing/${contract?.listingId}`)
          toast(
            t(
              'b.contract.view.contract_cancelled.text',
              'The contract has been cancelled.'
            ),
            {
              title: t(
                'b.contract.view.contract_cancelled.title',
                'Contract Cancelled'
              ),
              iconId: 'square_check',
              autoClose: 6000
            }
          )
        }
      })
    } else {
      role === 'tenant'
        ? history.replace(
            `/rental/${contract?.listingId}/contract/${contract?.id}/review`
          )
        : history.replace(
            `/listing/${contract?.listingId}/contract/${contract?.id}/review`
          )
      this.resetScroll()
    }
  }

  handleContinue = async () => {
    const {
      match: {
        params: { step = 'review' }
      },
      signContract,
      contract,
      history,
      role
    } = this.props
    if (step !== 'sign') {
      this.goToNextStep()
    } else {
      await signContract(contract.id)
      role === 'tenant'
        ? history.push(`/rental/${contract?.listingId}/contract/${contract.id}`)
        : history.push(
            `/listing/${contract?.listingId}/contract/${contract.id}`
          )
    }
  }

  allowedToCancel(step, user) {
    const operationType = user?.operation_type
    return (
      (step === 'review' && operationType === 'landlord') || step !== 'review'
    )
  }

  render() {
    const {
      t,
      contract,
      stepStatus,
      user,
      signature,
      match: {
        params: { step = 'review' }
      },
      sticky,
      noTitle
    } = this.props

    const WIZARD_STEPS = {
      review: t('b.contract.view.sign_step_review_details', 'Review Details'),
      terms: t('b.contract.view.sign_step_read_terms', 'Read Terms'),
      sign: t('b.contract.view.sign_step_sign.text', 'Sign')
    }

    return (
      <WhitePage sticky={sticky} hideOverflow>
        {!noTitle && (
          <PageTitle>
            {t('b.contract.view.contract.title', 'Contract')}
          </PageTitle>
        )}
        <NamedWizard steps={WIZARD_STEPS} currentStep={step} />
        <ContentWrapper className="mb-50">
          <Content id={WIZARD_ID}>
            <WizardContent currentStep={step}>
              <ReviewDetailsContainer
                index="review"
                contract={contract}
                userType={user.operation_type}
              />
              <ReviewTerms index="terms" />
              <ReviewSignatures
                index="sign"
                contract={contract}
                userType={user.operation_type}
                currentUser={user.id}
              />
            </WizardContent>
          </Content>
        </ContentWrapper>
        <ButtonBar sticky>
          {this.allowedToCancel(step, user) && (
            <StyledButton
              theme="sane"
              inversed
              disabled={false}
              onClick={this.handleBack}
            >
              {step === 'review'
                ? t('b.contract.view.cancel_contract.button', 'Cancel Contract')
                : t('b.contract.view.back.button', 'Back')}
            </StyledButton>
          )}

          <Button
            theme="primary"
            width={150}
            type="submit"
            left="auto"
            disabled={step !== 'sign' ? !stepStatus : signature === null}
            onClick={this.handleContinue}
          >
            {step !== 'sign'
              ? t('b.contract.view.continue.button', 'Continue')
              : t(
                  'b.contract.view.complete_signing.button',
                  'Complete Signing'
                )}
          </Button>
        </ButtonBar>
      </WhitePage>
    )
  }
}

Sign.defaultProps = {
  contract: {},
  user: {},
  stepStatus: false,
  userType: null,
  isPreviousStepDone: null,
  signature: null
}

const SignContainer = compose(
  withRouter,
  withTranslation(),
  connect(mapState, mapDispatch)
)(Sign)

export default SignContainer
