import { useSelector } from 'react-redux'
import { getImageUrl } from 'utils/image'
import { path } from 'ramda'
import { isNotEmpty } from 'utils'
import { defaultAvatar } from 'components/avatar'

const useAvatar = settings => {
  const avatar = useSelector(path(['profile', 'avatar'])) || {}
  return avatar && isNotEmpty(avatar)
    ? getImageUrl(avatar.aws_s3_key, settings)
    : defaultAvatar
}

export default useAvatar
