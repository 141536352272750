import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import Icon from 'components/icon'
import { values, prop, pipe, assoc, path, map } from 'ramda'
import { toInteger } from 'lodash'
import toBoolean from 'utils/to-boolean'
import toArray from 'utils/to-array'
import { show as showAddAddendumModal } from './add-addendum-modal'
import { show as showEditAddendumModal } from './edit-addendum-modal'

const Wrapper = styled.div`
  width: 100%;
  margin: 50px 0px;
  overflow: hidden;
`

const AddendumWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  padding: 15px 30px;
  margin-bottom: 3px;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`

const TextWrapper = styled.div`
  max-width: 90%;
`

const Title = styled.h1`
  color: ${colors.secondary};
  margin: 5px 0px;
  font-weight: ${fontWeights.medium};
`

const AddTitle = styled(Title)`
  color: ${colors.regular};
`

const Description = styled.p`
  font-size: ${fontSizes.small};
  margin: 5px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Option = styled.span`
  &:not(:last-child):after {
    content: ', ';
  }
`

const AddendumList = ({
  data: { contract_addendums = [], contract_predefined_addendums = [] } = {},
  data,
  listingId,
  setFieldValue,
  createAddendum,
  deleteAddendum
}) => {
  const [t] = useTranslation()

  const addendumTemplates = useSelector(
    path(['contract', 'listing_contract_addendum_templates'])
  )

  const handleRowPress = addendum => {
    showEditAddendumModal({
      data: addendum,
      updateValue: setFieldValue,
      contract_addendums,
      addendums,
      deleteAddendum,
      contract: data,
      listingId
    })
  }

  const handleAddAddendum = () => {
    showAddAddendumModal({
      data: addendums,
      createAddendum,
      updateValue: setFieldValue,
      contract_addendums
    })
  }

  const customAddendumms = contract_addendums
  const addendums = values(contract_predefined_addendums)

  return (
    <Wrapper>
      <AddendumWrapper onClick={handleAddAddendum}>
        <TextWrapper>
          <AddTitle>
            {t('l.contract.edit.add_addendum.button', 'Add Addendums')}
          </AddTitle>
          <Description />
        </TextWrapper>
        <Icon id="plus_small" />
      </AddendumWrapper>
      {customAddendumms?.map(addendum => (
        <AddendumWrapper
          key={addendum.id}
          onClick={() => handleRowPress(addendum)}
        >
          <TextWrapper>
            <Title>{addendum?.title}</Title>
            <Description>{addendum?.description}</Description>
          </TextWrapper>
          <Icon id="check" />
        </AddendumWrapper>
      ))}
      {addendums?.map(addendum => {
        const selectedAddendum = pipe(
          prop(addendum.listing_contract_addendum_template_txt_id),
          assoc(
            'allowed',
            pipe(prop('allowed'), toInteger, toBoolean)(addendum)
          ),
          assoc('id', prop('id', addendum)),
          assoc(
            'listing_contract_addendum_template_txt_id',
            prop('listing_contract_addendum_template_txt_id', addendum)
          ),
          assoc(
            'listing_contract_predefined_addendum_options',
            prop('listing_contract_predefined_addendum_options', addendum)
          )
        )(addendumTemplates)

        if (selectedAddendum.data_type === 'content') {
          return (
            <AddendumWrapper
              key={addendum.id}
              onClick={() => handleRowPress(selectedAddendum)}
            >
              <TextWrapper>
                <Title>{selectedAddendum?.title}</Title>
                <Description>{selectedAddendum?.content_preview}</Description>
              </TextWrapper>
              <Icon id="check" />
            </AddendumWrapper>
          )
        }

        return (
          <AddendumWrapper
            key={addendum.id}
            onClick={() => handleRowPress(selectedAddendum)}
          >
            <TextWrapper>
              <Title>{selectedAddendum?.title}</Title>
              {selectedAddendum?.allowed ? (
                <Description>
                  {t('b.contract.view.addendum_allowed.text', 'Allowed')}:{' '}
                  {toArray(
                    map(
                      ({
                        listing_contract_addendum_template_option_txt_id: id
                      }) => {
                        const option =
                          selectedAddendum
                            .listing_contract_addendum_template_options[id]
                        return (
                          option && (
                            <Option key={option.id}>{option.title}</Option>
                          )
                        )
                      },
                      selectedAddendum.listing_contract_predefined_addendum_options
                    )
                  )}
                </Description>
              ) : (
                <Description>
                  {t(
                    'b.contract.view.addendum_not_allowed.text',
                    'Not allowed'
                  )}
                </Description>
              )}
            </TextWrapper>
            <Icon id="check" />
          </AddendumWrapper>
        )
      })}
    </Wrapper>
  )
}

export default AddendumList
