import { createSelector } from 'reselect'
import {
  all,
  always,
  any,
  anyPass,
  append,
  ascend,
  assoc,
  complement,
  curry,
  defaultTo,
  evolve,
  filter,
  find,
  flip,
  identity,
  ifElse,
  includes,
  map,
  mergeRight,
  mergeAll,
  path,
  pick,
  pipe,
  prop,
  propEq,
  props,
  slice,
  sortBy,
  sortWith,
  trim,
  values,
  when,
  groupBy,
  __,
  reduceBy,
  reject,
  propSatisfies,
  startsWith
} from 'ramda'
import { toNumber, toInteger } from 'lodash'
import {
  renameKeys,
  isTruthy,
  parseObjectData,
  callOr,
  mergeSpec,
  headObj,
  findFile,
  defaultIfFalsy,
  isNotEmpty
} from 'utils'
import toBoolean from 'utils/to-boolean'
import { sqftToMeter } from 'utils/units'
import moment from 'moment'

const listingHasValidData = (
  data = ['users', 'unit', 'building', 'listings']
) => pipe(defaultTo({}), props(data), all(isTruthy))

const isVideo = propSatisfies(startsWith('video'), 'tag')
const isPhoto = propSatisfies(includes('photo'), 'tag')

/** FORMATTERS */
const formatLeaseLength = (number, descriptor) => {
  if (descriptor === 'no_minimum') {
    return 'no_minimum'
  } else if (number === '1' && descriptor === 'months') {
    return 'one_month'
  } else if (number === '6' && descriptor === 'months') {
    return 'six_months'
  } else if (number === '1' && descriptor === 'years') {
    return 'one_year'
  } else if (toBoolean(toInteger(number))) {
    return 'other'
  }
  return ''
}

/** SELECTORS */
const getListingSelector = (
  fields = ['users', 'unit', 'building', 'listings', 'files']
) =>
  createSelector(
    flip(prop('listingId')),
    identity,
    pipe(prop, defaultTo({}), pick(fields))
  )

export const unitImagesSelector = createSelector(
  path(['files', 'unit']),
  pipe(
    headObj,
    values,
    map(
      evolve({
        position: toInteger
      })
    ),
    filter(isPhoto),
    sortWith([ascend(prop('position')), ascend(prop('tag'))]),
    sortBy(complement(propEq('tag', 'cover_photo'))),
    slice(0, 20),
    map(pick(['location', 'id', 'aws_s3_key']))
  )
)

export const unitVideosSelector = createSelector(
  path(['files', 'unit']),
  pipe(
    headObj,
    values,
    map(
      evolve({
        position: toInteger
      })
    ),
    filter(isVideo),
    map(pick(['location', 'id', 'aws_s3_key', 'created_at']))
  )
)

export const buildingImagesSelector = createSelector(
  path(['files', 'building']),
  pipe(headObj, values)
)

export const listingSelector = createSelector(
  createSelector(prop('listing'), flip(identity), getListingSelector()),
  path(['session', 'session']),
  ifElse(
    listingHasValidData(),
    createSelector(
      identity,
      unitImagesSelector,
      unitVideosSelector,
      buildingImagesSelector,
      flip(identity),
      //eslint-disable-next-line max-params
      (base, unitImages, unitVideos, buildingImages, session) =>
        pipe(
          evolve({
            unit: {
              size: callOr(parseUnitSize(session), ''),
              gr_count: callOr(toInteger, ''),
              gr_min_size: callOr(parseUnitSize(session), ''),
              gr_max_size: callOr(parseUnitSize(session), '')
            }
          }),
          assoc('unitImages', unitImages),
          assoc('unitVideos', unitVideos),
          assoc('buildingImages', buildingImages)
        )(base)
    ),
    always({})
  )
)

const feeNotAvailable = propEq('fee_frequency_txt_id', 'not_available')
const feeFree = propEq('fee_frequency_txt_id', 'free')

const getFee = type => {
  return pipe(
    prop('listing_fees'),
    defaultTo([]),
    find(propEq('fee_txt_id', type)),
    ifElse(
      isTruthy,
      pipe(
        pick(['fee_frequency_txt_id', 'name', 'fee']),
        mergeSpec({
          enabled: complement(feeNotAvailable),
          hasFee: complement(anyPass([feeNotAvailable, feeFree]))
        }),
        parseObjectData
      ),
      always({
        enabled: false,
        name: '',
        fee: '',
        fee_frequency_txt_id: 'not_available'
      })
    )
  )
}

const parseUnitSize = curry((session, size) =>
  ifElse(
    complement(propEq('setting_size_unit', 'meter')),
    always(size),
    pipe(always(sqftToMeter(size)), toInteger)
  )(session)
)

export const groupedListings = createSelector(
  identity,
  pipe(
    map(building =>
      evolve(
        {
          building_name: defaultIfFalsy(trim(building.address))
        },
        building
      )
    ),
    sortWith([ascend(prop('building_name')), ascend(prop('address'))])
  )
)

export const editListingSelector = createSelector(
  createSelector(prop('listing'), flip(identity), getListingSelector()),
  path(['session', 'session']),
  (listingInfo, session) =>
    when(
      pipe(defaultTo({}), isNotEmpty),
      createSelector(
        prop('listings'),
        prop('unit'),
        prop('building'),
        path(['files', 'unit']),
        path(['files', 'building', listingInfo.building?.id]),
        prop('users'),
        // eslint-disable-next-line max-params
        (listing, unit, building, images, buildingImages = {}, users) =>
          pipe(
            mergeSpec({
              isGroupListing: pipe(prop('gr_unit'), toBoolean),
              pet_limitations: pipe(
                props(['allow_cats', 'allow_dogs']),
                map(toInteger),
                any(isTruthy)
              ),
              storage: getFee('storage'),
              parking: getFee('parking'),
              landlords: pipe(
                prop('listing_landlords'),
                map(
                  pipe(
                    prop(__, users),
                    defaultTo({}),
                    mergeSpec({
                      primary: propEq('id', listing.primary_landlord)
                    })
                  )
                )
              ),
              videos: pipe(
                prop('images'),
                callOr(pipe(headObj, values, filter(isVideo)), [])
              ),
              lease_length: () =>
                formatLeaseLength(
                  listing.min_lease_period,
                  listing.min_lease_period_type
                )
            }),
            evolve({
              building: assoc('images', values(buildingImages)),
              count_full_bathrooms: callOr(toNumber, ''),
              count_full_shared_bathrooms: callOr(toNumber, ''),
              unit_features: values,
              images: callOr(
                pipe(
                  headObj,
                  values,
                  reject(isVideo),
                  map(
                    evolve({
                      position: toInteger
                    })
                  ),
                  sortWith([ascend(prop('position')), ascend(prop('tag'))]),
                  sortBy(complement(propEq('tag', 'cover_photo')))
                ),
                []
              ),
              size: callOr(parseUnitSize(session), ''),
              gr_count: callOr(toInteger, ''),
              gr_min_size: callOr(parseUnitSize(session), ''),
              gr_max_size: callOr(parseUnitSize(session), ''),
              allow_smoking: callOr(toBoolean, false),
              allow_pets: callOr(toBoolean, false),
              allow_cats: callOr(toInteger),
              allow_dogs: callOr(toInteger),
              price: callOr(toInteger),
              listing_utilities: values,
              custom_features: values,
              custom_utilities: values,
              hide_unit_number: callOr(toInteger),
              private_entrance: callOr(toBoolean, false)
            }),
            renameKeys({
              id: 'unit_id'
            }),
            parseObjectData
          )(
            mergeRight(unit, {
              building,
              images,
              ...renameKeys({
                id: 'listing_id'
              })(listing)
            })
          )
      )
    )(listingInfo)
)

export const applicantsSelector = createSelector(
  path(['listing_applications', 'users']),
  path(['listing_applications', 'files', 'user']),
  (users, files) =>
    pipe(
      values,
      defaultTo([]),
      map(user => assoc('avatar', pipe(prop(user.id), headObj)(files))(user))
    )(users)
)

export const applicationsSelector = createSelector(
  path(['listing_applications', 'applications']),
  path(['listing_applications', 'users']),
  path(['listing_applications', 'liv_score']),
  path(['listing_applications', 'files', 'user']),
  (applications, users, liv_scores, files) =>
    pipe(
      values,
      map(app =>
        mergeAll([
          pick(
            [
              'id',
              'extra_offer',
              'extra_offer_frequency',
              'user_id',
              'created_at',
              'favourite'
            ],
            app
          ),
          pick(
            [
              'first_name',
              'last_name',
              'preferred_name',
              'salary',
              'job_title'
            ],
            users[app.user_id]
          ),
          pick(['overall', 'credit_rating'], liv_scores[app.user_id] || {}),
          { avatar: findFile('tag', 'user_avatar', prop(app.user_id, files)) }
        ])
      ),
      map(
        evolve({
          favourite: toNumber
        })
      )
    )(applications)
)

const formatDate = x => moment(x).format('YYYY-MM-DD')

export const slotsSelector = createSelector(
  getListingSelector(['listing_appointments']),
  when(
    listingHasValidData(['listing_appointments']),
    createSelector(
      prop('listing_appointments'),
      pipe(
        values,
        filter(({ meeting_at }) => moment(meeting_at).isAfter(moment())),
        groupBy(pipe(prop('meeting_at'), formatDate)),
        values
      )
    )
  )
)

export const attendeesAvatarSelector = createSelector(
  getListingSelector(['users', 'files']),
  createSelector(path(['files', 'user']), prop('users'), (files, users) =>
    pipe(
      values,
      map(
        mergeSpec({
          avatar: pipe(prop('id'), prop(__, files), headObj)
        })
      )
    )(users)
  )
)

export const appointmentsSelector = createSelector(
  getListingSelector(['listing_appointments']),
  when(
    listingHasValidData(['listing_appointments']),
    createSelector(
      prop('listing_appointments'),
      pipe(
        values,
        reduceBy(flip(append), [], ({ meeting_at }) =>
          moment(meeting_at).isAfter(moment()) ? 'upcoming' : 'history'
        ),
        map(
          pipe(
            sortBy(prop('meeting_at')),
            groupBy(pipe(prop('meeting_at'), formatDate)),
            values
          )
        )
      )
    )
  )
)

const LISTING_LANDLORDS_FIELDS = ['listings', 'users']
export const listingLandlordsSelector = createSelector(
  getListingSelector(LISTING_LANDLORDS_FIELDS),
  when(
    listingHasValidData(LISTING_LANDLORDS_FIELDS),
    createSelector(
      path(['listings', 'listing_landlords']),
      prop('users'),
      (landlords, users) =>
        pipe(
          map(pipe(prop(__, users), defaultTo({}))),
          sortWith([ascend(prop('first_name'))])
        )(landlords)
    )
  )
)

export const contractDataSelector = createSelector(
  getListingSelector(),
  when(
    listingHasValidData(),
    createSelector(
      prop('listings'),
      prop('unit'),
      prop('building'),
      prop('users'),
      (listing, unit, building, users) =>
        pipe(
          pick([
            'lease_type',
            'price',
            'price_frequency',
            'custom_features',
            'custom_utilities',
            'strata_plan_number',
            'strata_lot_number',
            'listing_utilities',
            'unit_features',
            'listing_landlords',
            'listing_fees',
            'allow_pets',
            'allow_cats',
            'allow_dogs'
          ]),
          renameKeys({
            listing_utilities: 'contract_utilities',
            unit_features: 'contract_features',
            listing_landlords: 'contract_landlords',
            custom_features: 'contract_custom_features',
            custom_utilities: 'contract_custom_utilities'
          }),
          evolve({
            allow_pets: callOr(toBoolean, false),
            allow_cats: callOr(toInteger),
            allow_dogs: callOr(toInteger),
            contract_landlords: map(pipe(prop(__, users), defaultTo({}))),
            contract_features: values,
            contract_utilities: values
          }),
          mergeSpec({
            storage: getFee('storage'),
            parking: getFee('parking'),
            pet_policy: pipe(prop('allow_pets'), any(isTruthy)),
            pet_limitations: pipe(
              props(['allow_cats', 'allow_dogs']),
              map(toInteger),
              any(isTruthy)
            )
          })
        )(mergeAll([listing, unit, building]))
    )
  )
)

const SUGGESTED_TENANTS_FIELDS = ['listing_matches', 'users']
export const suggestedTenantsSelector = createSelector(
  getListingSelector(SUGGESTED_TENANTS_FIELDS),
  ifElse(
    listingHasValidData(SUGGESTED_TENANTS_FIELDS),
    createSelector(
      prop('listing_matches'),
      prop('users'),
      (listingMatches, users) =>
        pipe(
          defaultTo([]),
          map(match => prop(match.user_id, users))
        )(listingMatches)
    ),
    always({})
  )
)
