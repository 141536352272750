import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontWeights, fontSizes } from 'styles'
import { CloseClickOutside } from 'components/window'
import { Manager, Reference, Popper } from 'react-popper'

const MenuButton = styled.div`
  position: absolute;
  top: 10px;
  right: 2px;
  cursor: pointer;

  &::after {
    content: '\\2807';
    font-size: ${fontSizes.extraLarge};
    color: ${colors.white};
    font-family: none;
    padding: 3px;
    text-shadow: 1px 3px 4px #000;
  }
`

const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  margin: 0;
  padding: 0;
`

const MenuItem = styled.li`
  display: block;
  padding: 5px 10px;
  border-bottom: 2px solid #d1d2d1;
  cursor: pointer;
  color: ${props => props.color || colors.regular};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.29px;
  line-height: 17px;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    border: none;
  }
`

const ContextMenu = ({ items }) => {
  const [opened, setOpen] = useState(false)
  const close = () => setOpen(false)
  const toggle = () => {
    setOpen(!opened)
  }
  return (
    <Manager>
      <Reference>
        {({ ref }) => <MenuButton ref={ref} onClick={toggle} />}
      </Reference>
      <Popper placement="bottom-end">
        {({ ref, style, placement }) =>
          opened && (
            <CloseClickOutside onClickOutside={close}>
              <MenuList ref={ref} style={style} data-placement={placement}>
                {items.map(({ label, onClick, ...rest }) => (
                  <MenuItem key={label} onClick={onClick} {...rest}>
                    {label}
                  </MenuItem>
                ))}
              </MenuList>
            </CloseClickOutside>
          )
        }
      </Popper>
    </Manager>
  )
}

export default React.memo(ContextMenu)
