import styled from 'styled-components'

const Wrapper = styled.div`
  border-bottom: 1px solid #eee;
  height: ${props => props.height || '65px'};
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;

  &:last-of-type {
    border-bottom: none;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
    & [type="radio"]:disabled,
    & [type="checkbox"]:disabled {
    cursor: default;
    opacity: 1;
  }
  `}
`

export default Wrapper
