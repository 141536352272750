import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import useRole from 'hooks/use-role'
import { pipe, reject, join, path, includes, isEmpty } from 'ramda'
import { isFalsy } from 'utils'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import moment from 'moment'
import formatName from 'utils/format-name'
import { getImageUrl } from 'utils/image'
import { confirm } from 'components/dialog'
import { createModal, useModalize } from 'components/modalize'
import { Avatar } from 'components/avatar'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { Label, Group } from 'components/form'
import { showAddToCalendarModal } from 'features/Listing/add-to-calendar-modal.js'
import {
  Box,
  ShadowedBox,
  DateText,
  TimeText,
  BoldText,
  DateWrapper
} from './chat-event/chat-icons/elements'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const StyledGroup = styled(Group)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 5px 40px;
  margin: 0;
`

const ImgTextWrapper = styled.div`
  position: relative;
  text-align: start;
  color: white;
  padding: 20px 40px 5px 40px;
  margin: 0;
`

const TextWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 50px;
`

const MajorText = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: bold;
  margin: 25px 5px 10px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
`

const MinorText = styled.p`
  font-size: 14px;
  margin: 0 5px;
  line-height: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
`

const Img = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 6px;
`

const LandlordAvatar = styled(Avatar)`
  height: 60px;
  width: 60px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  border-radius: 50%;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 6px 10px 1px;
  font-size: ${fontSizes.small};
`

const EventContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  color: ${colors.mediumGrey};
  width: 120px;
  height: 125px;
`

const StyledShadowBox = styled(ShadowedBox)`
  cursor: default;
`

const StyledBox = styled(Box)`
  cursor: default;
`

const StyledDateWrapper = styled(DateWrapper)`
  padding-top: 10px;
`

const DayText = styled(BoldText)`
  font-size: ${fontSizes.medium};
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${colors.white};
  width: 200px;
  padding: 10px 10px 10px 0;
`

const MeetingWithWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

const LandlordTextWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  text-overflow: ellipsis;
  margin-right: 15px;
`

const CalendarText = styled.button`
  margin-top: 15px;
  padding-left: 10px;
  border: none;
  color: ${colors.secondary};
  background-color: ${colors.white};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 30px;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-around;
  margin: 0;
  letter-spacing: 0.25px;
  padding: 0 10px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 135px;
  }
`

const BookingDetailsModal = ({
  timelineId = '',
  landlordAvatar,
  landlordName,
  listingId,
  slotData,
  title = false
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const role = useRole()

  const loadListingData = dispatch.listing.load
  const listingData = useSelector(state =>
    select.listing.listingDetail(state, {
      listingId
    })
  )

  const SnoozeTime = useSelector(
    path([
      'listing',
      listingId,
      'listing_appointments',
      slotData.id,
      'last_snooze_time'
    ])
  )

  const updatedTime =
    slotData.last_snooze_time || SnoozeTime ? SnoozeTime : slotData.meeting_at

  const isTenant = role === 'tenant'
  const userID = isTenant ? 'them' : 'us'
  const landlordId = useSelector(
    path(['chat', 'timelines', timelineId, 'primary_user_ids', userID])
  )

  const landlordInfo = useSelector(path(['session', 'users', landlordId]))
  const landlordFirstLastName = landlordName || formatName(landlordInfo)
  const landlordPic =
    landlordAvatar ||
    getImageUrl(landlordInfo.avatar.aws_s3_key, {
      width: 60,
      height: 60
    })
  const month = moment(updatedTime).format('MMM')
  const day = moment(updatedTime).format('DD')
  const time = moment(updatedTime).format('h:mm a')

  useEffect(() => {
    loadListingData(listingId)
  }, [listingId, loadListingData])

  const formatAddress = address => {
    const rest = pipe(
      reject(isFalsy),
      join(', ')
    )([address.city, address.state, address.zip?.toUpperCase()])
    return `${rest}`
  }

  const attending = useSelector(path(['listing', listingId, 'attending_to']))

  const handleCancel = async () => {
    const response = await dispatch.listing.cancelShowing({
      timeslotId: slotData.id,
      listingId
    })
    if (response.ok) {
      close()
    }
  }

  const confirmCancel = () => {
    return confirm(
      t('r.showings.view.confirm_cancel_showing.title', 'Cancel Showing?'),
      t(
        'r.showings.view.confirm_cancel_showing.text',
        'You could book another one that suits you more.'
      )
    ).then(() => handleCancel())
  }

  if (!listingData?.unitImages) return null

  const unitHidden = !!Number(listingData?.unit?.hide_unit_number)

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>
          {title || t('r.showings.view.showing_booked.title', 'Showing Booked')}
        </Title>
      </Header>
      <ImgTextWrapper>
        <Img src={getImageUrl(listingData?.unitImages[0]?.aws_s3_key)}></Img>
        <TextWrapper>
          <MajorText>
            {(!unitHidden && listingData.unit.unit_number) || '-'}
          </MajorText>
          <MinorText>{listingData.building.name || '-'}</MinorText>
          <MinorText>{formatAddress(listingData.building)}</MinorText>
        </TextWrapper>
      </ImgTextWrapper>
      <StyledGroup>
        <Label noGutter bottom={20}>
          {t('r.showings.view.showing_details.label', 'Showing Details')}
        </Label>
        <DetailsWrapper>
          <EventContainer>
            <StyledShadowBox
              shadow="0 2px 4px 2px rgba(0, 0, 0, 0.19)"
              height={80}
              width={80}
            >
              <StyledBox height={65} color={colors.secondary} top={5}>
                <StyledDateWrapper>
                  <DateText>{month}</DateText>
                  <DayText>{day}</DayText>
                </StyledDateWrapper>
              </StyledBox>
              <TimeText color={colors.secondary}>{time}</TimeText>
            </StyledShadowBox>
          </EventContainer>
          <TitleContainer>
            <MeetingWithWrapper>
              <LandlordTextWrapper>
                <Label noGutter size={12}>
                  {t('r.showings.view.meeting-with.label', 'Meeting With')}
                </Label>
                <Label noGutter size={16} fontWeights="strong">
                  {landlordFirstLastName}
                </Label>
              </LandlordTextWrapper>
              <LandlordAvatar
                location={landlordPic}
                alt="User Avatar"
              ></LandlordAvatar>
            </MeetingWithWrapper>
            <CalendarText
              onClick={() => {
                showAddToCalendarModal({
                  values: {
                    meeting_at: updatedTime,
                    duration: slotData.duration
                  },
                  listingId: listingId
                })
              }}
            >
              {t(
                'r.showings.view.sync_with_calendar.button',
                'Sync with my calendar'
              )}
            </CalendarText>
          </TitleContainer>
        </DetailsWrapper>
      </StyledGroup>
      <ButtonWrapper>
        <ActionButton
          name="license_pill"
          theme="neutral"
          width={150}
          left="auto"
          type="submit"
          disabled={
            isEmpty(attending) || !includes(slotData.id, attending[listingId])
          }
          onClick={confirmCancel}
        >
          {t('r.showing.edit.cancel_showing.button', 'Cancel Showing')}
        </ActionButton>
        <ActionButton
          name="license_pill"
          theme="primary"
          width={150}
          left="auto"
          type="submit"
          onClick={close}
        >
          {t('r.showing.edit.done.button', 'Done')}
        </ActionButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

export const showBookingDetailsModal = createModal(BookingDetailsModal)
