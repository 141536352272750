import {
  always,
  equals,
  identity,
  ifElse,
  pick,
  set,
  lensPath,
  lensProp,
  map,
  prop,
  pipe,
  path,
  defaultTo,
  mergeDeepLeft,
  dissocPath
} from 'ramda'
import { batch } from 'react-redux'
import selectorBuilder from 'models/selector-builder'
import { isNotEmpty } from 'utils'
import {
  getCompanyListings,
  createUser,
  updateUser,
  deleteTargetUser
} from './ports'
import {
  buildingsSelector,
  userSelector,
  listingLandlordsSelector
} from './selectors'

const companyManagement = {
  state: {},
  reducers: {
    clear: () => ({}),
    update: (state, payload) => ({ ...state, ...payload, error: null }),
    updateAt: (state, at, payload) => set(lensPath(at), payload, state),
    remove: (state, at) => dissocPath(at, state),
    patch: (state, at, payload) =>
      set(
        lensPath(at),
        pipe(path(at), defaultTo({}), mergeDeepLeft(payload))(state),
        state
      ),
    replaceListingLandlord: (state, payload) => ({
      ...state,
      listings: map(listing =>
        set(
          lensProp('listing_landlords'),
          pipe(
            prop('listing_landlords'),
            map(
              ifElse(
                equals(payload.oldUserId),
                always(payload.newUserId),
                identity
              )
            )
          )(listing),
          listing
        )
      )(state.listings)
    })
  },

  effects: dispatch => ({
    getCompanyListings: async (_, rootState) => {
      if (isNotEmpty(rootState.companyManagement)) return
      try {
        const { response, body } = await getCompanyListings()
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        const updatedBody = pick(
          [
            'buildings',
            'categorized_listings',
            'companies',
            'files',
            'listings',
            'users',
            'units'
          ],
          body
        )
        dispatch.companyManagement.update(updatedBody)
      } catch (error) {
        console.error('[companyManagement/getCompanyListings]', error)
      }
    },
    createUser: async payload => {
      const data = pick([
        'first_name',
        'last_name',
        'preferred_name',
        'phone_number',
        'username',
        'user_role'
      ])(payload.values)
      try {
        const { response, body } = await createUser({
          body: data
        })
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.companyManagement.updateAt(['users', body.id], body)
          dispatch.session.update(['company_users', body.id], body.id)
          dispatch.session.patch(['users', body.id], body)
        })
        return { response }
      } catch (error) {
        console.error('[companyManagement/createUser]', error)
      }
    },
    updateUser: async payload => {
      const data = pick([
        'first_name',
        'last_name',
        'preferred_name',
        'phone_number',
        'username',
        'user_role'
      ])(payload.values)
      try {
        const { response, body } = await updateUser(
          {
            body: data
          },
          {
            userId: payload.userId
          }
        )
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        dispatch.companyManagement.updateAt(['users', body.id], body)

        return { response }
      } catch (error) {
        console.error('[companyManagement/updateUser]', error)
      }
    },
    deleteTargetUser: async payload => {
      const { oldUserId, newUserId } = payload
      try {
        const { response, body } = await deleteTargetUser(undefined, {
          oldUserId,
          newUserId
        })

        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        batch(() => {
          dispatch.session.remove(['company_users', oldUserId])
          dispatch.companyManagement.remove(['users', oldUserId])
          dispatch.companyManagement.replaceListingLandlord({
            oldUserId,
            newUserId
          })
        })
        return { response }
      } catch (error) {
        console.error('[companyManagement/deleteTargetUser]', error)
        return { error }
      }
    }
  }),
  selectors: selectorBuilder(slice => ({
    buildings() {
      return slice(buildingsSelector)
    },
    users() {
      return slice(userSelector)
    },
    listingLandlords() {
      return slice(listingLandlordsSelector)
    }
  }))
}

export default companyManagement
