const loading = {
  state: {
    semaphore: 0
  },
  reducers: {
    setLoading: state => ({ semaphore: state.semaphore + 1 }),
    stopLoading: state => {
      const newValue = state.semaphore - 1
      return { semaphore: newValue < 0 ? 0 : newValue }
    }
  }
}

export default loading
