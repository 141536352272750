import React, { Fragment } from 'react'
import styled from 'styled-components'
import { filter } from 'ramda'
import { colors } from 'styles'
import Icon from 'components/icon'

const Label = styled.p`
  background-color: ${props => props.color};
  border: ${props => props.color};
  color: ${props => props.fontColor};
  border: ${props => props.color};
  border-radius: 2px;
  display: inline-block;
  font-size: 11px;
  padding: 0 4px;
  margin: 0 5px 5px 0;
`

const IconWrapper = styled.div`
  margin-right: 10px;
`

const RouteCategory = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const RouteList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 2px;
`

const RouteItem = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: center;
  margin: 0 0 5px 0;
`

const BusItem = styled.span`
  display: inline-block;
  font-size: 11px;
  line-height: 19px;
  color: ${colors.regular};
  border: 1px solid ${colors.regular};
  min-width: 30px;
  border-radius: 2px;
  padding: 0 4px;
  margin: 0 5px 5px 0;
  text-align: center;
`

const checkTransitData = array => array && array.length > 0

const RouteDisplay = ({ data }) => {
  const routeWithRoutes = filter(checkTransitData, data)
  return Object.values(routeWithRoutes).length > 0 ? (
    <Fragment>
      {Object.keys(routeWithRoutes).map(type =>
        type === 'bus' ? (
          <RouteCategory key={type}>
            <IconWrapper>
              <Icon id="bus" width={24} height={24} />
            </IconWrapper>
            <RouteList>
              {data.bus.map(bus => (
                <RouteItem key={bus}>
                  <BusItem overflow="ellipsis">{bus}</BusItem>
                </RouteItem>
              ))}
            </RouteList>
          </RouteCategory>
        ) : (
          <RouteCategory key={type}>
            <IconWrapper>
              <Icon id={type} width={24} height={24} />
            </IconWrapper>
            <RouteList>
              {data[type].map(routeGroup =>
                routeGroup.routes.map(route => (
                  <RouteItem key={route}>
                    <Label
                      fontColor={routeGroup.additional_info.font_color}
                      color={routeGroup.additional_info.color}
                      overflow="ellipsis"
                    >
                      {route}
                    </Label>
                  </RouteItem>
                ))
              )}
            </RouteList>
          </RouteCategory>
        )
      )}
    </Fragment>
  ) : null
}

RouteDisplay.defaultProps = {
  data: {}
}

export default RouteDisplay
