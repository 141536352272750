import {
  pipe,
  mergeRight,
  pick,
  evolve,
  dissocPath,
  prop,
  omit,
  set,
  lensPath,
  path,
  defaultTo,
  mergeDeepLeft
} from 'ramda'
import selectorBuilder from 'models/selector-builder'
import { toNumber } from 'lodash'
import { callOr, headObj } from 'utils'
import { batch } from 'react-redux'
import {
  getApplication,
  getApplications,
  createApplication,
  deleteApplication,
  favouriteApplication,
  unfavouriteApplication,
  addExternal
} from './ports'
import { applicationSelector, contractDataSelector } from './selectors'

const formatApplicationFields = pipe(
  pick(['move_in_date', 'extra_offer', 'extra_offer_frequency']),
  evolve({
    extra_offer: callOr(toNumber, null)
  })
)

const parseApplication = raw => {
  const livScore = prop('liv_score', raw)
  const data = omit(['liv_score'], raw)
  const obj = pipe(headObj)(data)
  return set(lensPath([obj.id, 'liv_score']), livScore, data)
}

const INITIAL_STATE = {}

const application = {
  state: INITIAL_STATE,
  reducers: {
    clear: () => INITIAL_STATE,
    remove: (state, at) => dissocPath(at, state),
    update: (state, at, payload) =>
      set(
        lensPath(at),
        pipe(path(at), defaultTo({}), mergeDeepLeft(payload))(state),
        state
      ),
    patch: mergeRight
  },

  effects: dispatch => ({
    create: async ({ listingId, message, ...payload }) => {
      try {
        const { error } = await dispatch.chat.startChat({
          message,
          listingId
        })
        if (error) return { error }
        const { body, response } = await createApplication(
          {
            body: formatApplicationFields(payload)
          },
          { listingId }
        )
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.application.patch(parseApplication(body))
        return { body: headObj(body), response }
      } catch (error) {
        return { error }
      }
    },
    delete: async applicationId => {
      try {
        const { response } = await deleteApplication(undefined, {
          applicationId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.application.remove([applicationId])
        return {}
      } catch (error) {
        return { error }
      }
    },
    load: async (applicationId, rootState) => {
      if (rootState.application[applicationId]) return
      try {
        const { body, response } = await getApplication({
          applicationId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.application.patch(parseApplication(body))
      } catch (error) {
        return { error }
      }
    },
    getByListingId: async (listingId, rootState) => {
      try {
        const { body, response } = await getApplications({
          listingId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        const applications = parseApplication(body?.applications)
        dispatch.application.patch(applications)
        return { applications }
      } catch (error) {
        return { error }
      }
    },
    favourite: async applicationId => {
      try {
        const { response, body } = await favouriteApplication(undefined, {
          applicationId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.listing.setValue(
          ['listing_applications', 'applications', applicationId, 'favourite'],
          body[applicationId]?.favourite
        )
      } catch (error) {
        return { error }
      }
    },
    unfavourite: async applicationId => {
      try {
        const { response, body } = await unfavouriteApplication(undefined, {
          applicationId
        })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        dispatch.listing.setValue(
          ['listing_applications', 'applications', applicationId, 'favourite'],
          body[applicationId]?.favourite
        )
      } catch (error) {
        return { error }
      }
    },
    addExternal: async payload => {
      try {
        const { response, body } = await addExternal({ body: payload })
        if (!response.ok) {
          throw Error(response.body.message || response.statusText)
        }
        batch(() => {
          dispatch.listing.patch(
            ['listing_applications', 'applications'],
            body.applications
          )
          dispatch.listing.patch(['listing_applications', 'users'], body.users)
        })
        return { response, body }
      } catch (error) {
        return { error }
      }
    }
  }),

  selectors: selectorBuilder(slice => ({
    applicationDetail() {
      return slice(applicationSelector)
    },
    contractData() {
      return slice(contractDataSelector)
    }
  }))
}

export default application
