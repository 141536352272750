import ENV_VARS from 'config'
import { get, post, put, del } from 'ports/api'

const { BACKEND_SERVER } = ENV_VARS

export const getCompanyListings = get(
  `${BACKEND_SERVER}/web/users/bootstrap/session/coportfolio`
)

export const updateUser = put(`${BACKEND_SERVER}/users/%userId%`)
export const createUser = post(`${BACKEND_SERVER}/users/`)
export const deleteUser = del(`${BACKEND_SERVER}/users/%userId%`)

export const deleteTargetUser = del(
  `${BACKEND_SERVER}/users/%oldUserId%/target_user/%newUserId%`
)
