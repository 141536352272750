import styled from 'styled-components'
import { colors } from 'styles'

const Stripe = styled.div`
  height: ${props => `${props.height}px`};
  background-color: ${props => props.color};
  width: ${props => `${props.width}%`};
`

const StripeBar = Stripe

StripeBar.defaultProps = {
  height: 41,
  color: colors.secondary,
  width: 100
}
export default StripeBar
