import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import { Formik, Form } from 'formik'
import { Persist } from 'formik-persist'
import { useFlag } from 'flags'
import config from 'config'
import TenantBasicInfo from 'features/Profile/TenantBasicInfo'
import LivScore from 'features/Profile/LivScore'
import { EmploymentInfo } from 'features/Profile/EmploymentInfo'
import { PetsFullList } from 'features/Profile/Pets'
import { LandlordReferencesFullList } from 'features/Profile/LandlordReferences'
import { EmploymentReferencesFullList } from 'features/Profile/EmploymentReferences'
import { EmergencyContactsFullList } from 'features/Profile/EmergencyContacts'
import { RoommatesFullList } from 'features/Profile/Roommates'
import { InsuranceFullView } from 'features/Profile/InsuranceInfo'
import { AutomobileFullList } from 'features/Profile/Automobile'
import VerifyIdentity from 'features/Profile/VerifyIdentity'
import ListingInfo from 'features/Listing/listing-info'
import { PanelHeader, PanelTitle, StyledPanel } from 'features/Profile/elements'
import Page from 'components/page'
import Panel from 'components/panel'
import PageTitle from 'components/page-title'
import { Anchor } from 'components/anchor'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import useMediaQuery from 'hooks/use-media-query'
import useRole from 'hooks/use-role'
import useLivScore from 'hooks/use-liv-score'
import useProfile from 'hooks/use-profile'
import useActiveApplications from 'hooks/use-active-applications'
import {
  Label,
  Row,
  Group,
  FieldInput,
  Input,
  FieldDropdown,
  FieldDatePicker,
  FieldTextarea
} from 'components/form'
import { breakpoints, fontSizes, fontWeights } from 'styles'
import * as Yup from 'yup'
import {
  anyPass,
  propSatisfies,
  pathSatisfies,
  propEq,
  converge,
  and,
  or,
  complement,
  path,
  values as ramdaValues,
  filter,
  includes,
  mergeRight,
  equals,
  length,
  pipe,
  __
} from 'ramda'
import { isFalsy } from 'utils'
import ContentWrapper from 'components/content-wrapper'

const { WEB_URL } = config

const Title = styled.h1`
  display: block;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  margin: 40px 0;
  text-align: center;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 0;
  }
`

const Checkbox = styled(Input)``

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-right: 40px;

  ${Checkbox} {
    margin-left: 20px;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 0 20px;

    ${Checkbox} {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 0 10px 0 0;
  }
`

const CheckboxText = styled.div`
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    font-size: ${fontSizes.small};
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledAnchor = styled(Anchor)`
  margin-left: 4px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-left: 0;
  }
`

const BackButton = styled(Button)`
  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    display: none;
  }
`

const Optional = styled.small`
  font-size: 14px;
`

const propIsFalsy = propSatisfies(isFalsy)
const pathIsFalsy = pathSatisfies(isFalsy)

const isMissingBasicInfo = anyPass([
  propIsFalsy('avatar'),
  propIsFalsy('dob'),
  propIsFalsy('job_title'),
  propIsFalsy('salary'),
  propIsFalsy('about_me'),
  pathIsFalsy(['company_info', 'address']),
  pathIsFalsy(['company_info', 'city']),
  pathIsFalsy(['company_info', 'state']),
  pathIsFalsy(['company_info', 'zip']),
  pathIsFalsy(['company_info', 'country_code']),
  propIsFalsy('identity_verified_state'),
  propEq('identity_verified_state', 'declined')
])

const isMissingLivScore = converge(and, [
  anyPass([propIsFalsy('liv_verified_state')]),
  converge(or, [
    complement(propEq('international_user', '1')),
    propSatisfies(x => equals(0, length(x)), 'secureFiles')
  ])
])

const isIdVerifiedOrPending = anyPass([
  propEq('identity_verified_state', 'pending'),
  propEq('identity_verified_state', 'verified')
])

const isIdNotVerifiedOrPending = complement(isIdVerifiedOrPending)

const DOCUMENT_TYPES = [
  't4',
  'tax_slip',
  'bank_statement',
  'business_card',
  'job_offer',
  'payslip',
  'student_loan',
  'school_id',
  'school_letter'
]

const ApplicationCreate = ({
  match: {
    params: { listingId }
  },
  history,
  location
}) => {
  const dispatch = useDispatch()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const data = useProfile()
  const pets = useSelector(select.profile.pets)
  const employmentReferences = useSelector(select.profile.employers)
  const landlordReferences = useSelector(state =>
    select.profile.contacts(state, {
      type: 'landlord'
    })
  )
  const emergencyContacts = useSelector(state =>
    select.profile.contacts(state, {
      type: 'emergency'
    })
  )
  const { t } = useTranslation()
  const userRole = useRole()
  const livScore = useLivScore()
  const activeApplications = useActiveApplications(listingId)
  const hasAlreadyApplied = activeApplications?.length > 0

  const secureFiles = useSelector(
    pipe(
      path(['profile', 'files', 'user_secure_files']),
      ramdaValues,
      filter(propSatisfies(includes(__, DOCUMENT_TYPES), 'tag'))
    )
  )
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  useEffect(() => {
    dispatch.listing.load(listingId)
  }, [dispatch.listing, listingId])

  useEffect(() => {
    if (hasAlreadyApplied)
      history.replace(`/application/${activeApplications[0].id}`)
  }, [hasAlreadyApplied, activeApplications, history])

  const showNewProfile = useFlag(['features', 'newProfile'])

  const listingData = useSelector(path(['listing', listingId, 'listings']))

  const extraOfferFrequencyOptions = [
    {
      value: 'one_time',
      label: t(
        'r.application.create.extra_offer_frequency_type_one_time.placeholder',
        'One-time'
      )
    },
    {
      value: 'monthly',
      label: t(
        'r.application.create.extra_offer_frequency_type_monthly.placeholder',
        'Monthly'
      )
    }
  ]

  const validationSchema = Yup.object().shape({
    message: Yup.string().required(),
    extra_offer: Yup.number()
      .typeError(
        t(
          'r.application.create.extra_offer_type.error',
          'Extra offer must only contain numbers'
        )
      )
      .min(0)
      .integer(
        t(
          'r.application.create.extra_offer_integer.error',
          'Extra offer must be a whole number'
        )
      )
      .required(),
    move_in_date: Yup.date().required()
  })

  const initialValues = {
    extra_offer: '0',
    extra_offer_frequency: 'one_time',
    move_in_date: '',
    message:
      'Hi, I’m interested in your unit, please see my tenancy application.'
  }

  if (!data.id) return null

  const onSubmit = async (values, { setSubmitting }) => {
    const { error, body } = await dispatch.application.create({
      ...values,
      listingId: listingId
    })
    setSubmitting(false)
    if (!error) {
      history.push(`/chat/${body.timeline_room}`)
    }
  }

  const notPetOwner = data.pet_owner === '0'
  const firstTimeRenter = data.first_time_renter === '1'
  const isMissingFields = !(acceptedTerms && data.completion_percentage === 100)
  const unemployed = data.job_title === 'unemployed'
  const selfEmployed = data.job_title === 'self_employed'
  const idNotUploaded = isIdNotVerifiedOrPending(data)

  return (
    <Page>
      <Helmet>
        <title>
          {t(
            'b.application.view.tenancy_application.title',
            'Tenancy Application'
          )}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t(
          'b.application.view.tenancy_application.title',
          'Tenancy Application'
        )}
      </PageTitle>
      <Title>
        {t(
          'b.application.view.tenancy_application.title',
          'Tenancy Application'
        )}
      </Title>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <Panel hSpace={30} vSpace={30}>
                <ListingInfo listingId={listingId} />
                <Row top={50}>
                  <Group col={colSize}>
                    <FieldDatePicker
                      name="move_in_date"
                      disablePast
                      placeholder={
                        t(
                          'r.application.create.move_in_date.label',
                          'Desired Move-In Date'
                        ) + '*:'
                      }
                    />
                  </Group>
                </Row>
                <Row top={30}>
                  <Group col={12} bottom={0}>
                    <Label noGutter strong size={18} bottom={0}>
                      {t('r.application.create.offer_more.label', 'Offer More')}
                      <Optional>
                        {' '}
                        ({t('r.application.create.optional.label', 'Optional')})
                      </Optional>
                    </Label>
                  </Group>
                </Row>
                <Row>
                  <Group col={12}>
                    <Label noGutter bottom={0} size={14}>
                      {t(
                        'b.application.view.you_can_offer_more.label',
                        'You can offer more to the landlord to gain ahead of other renters!'
                      )}
                    </Label>
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize} bottom={displayMobile ? 30 : 0}>
                    <FieldInput
                      name="extra_offer"
                      prefix="$"
                      placeholder={
                        t(
                          'r.application.create.additional_offer.label',
                          'Additional Offer'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldDropdown
                      id="extra_offer_frequency"
                      name="extra_offer_frequency"
                      options={extraOfferFrequencyOptions}
                      placeholder={
                        t(
                          'r.application.create.offer_frequency.label',
                          'Offer Frequency'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
              </Panel>
              <TenantBasicInfo
                data={data}
                editable
                editProps={{
                  onSuccessRedirectTo: location.pathname
                }}
                showRequiredWarning={isMissingBasicInfo(data)}
              />
              <PetsFullList
                showRequiredWarning={!(pets.length || notPetOwner)}
                isChecked={notPetOwner}
              />
              <EmergencyContactsFullList
                showRequiredWarning={!emergencyContacts.length}
              />
              <LivScore
                data={data}
                livScore={livScore}
                userRole={userRole}
                listingData={listingData}
                editable
                editProps={{
                  onSuccessRedirectTo: location.pathname,
                  onBackRedirectTo: location.pathname
                }}
                showRequiredWarning={isMissingLivScore(
                  mergeRight(data, { secureFiles })
                )}
              />
              {showNewProfile && (
                <EmploymentInfo
                  data={data}
                  editable
                  editProps={{
                    onSuccessRedirectTo: location.pathname,
                    onBackRedirectTo: location.pathname
                  }}
                />
              )}
              <EmploymentReferencesFullList
                showRequiredWarning={
                  !(employmentReferences.length || unemployed || selfEmployed)
                }
              />
              <VerifyIdentity data={data} showRequiredWarning={idNotUploaded} />
              <LandlordReferencesFullList
                showRequiredWarning={
                  !(landlordReferences.length || firstTimeRenter)
                }
                isChecked={firstTimeRenter}
              />
              <RoommatesFullList />
              <InsuranceFullView
                data={data.files.user_secure_files}
                editProps={{ onSuccessRedirectTo: location.pathname }}
              />
              <AutomobileFullList />
              <PanelHeader>
                <PanelTitle>
                  {t(
                    'r.profile.create.additional_notes.subtitle',
                    'Additional Notes / Comments'
                  )}
                </PanelTitle>
              </PanelHeader>
              <StyledPanel hSpace={30} vSpace={30}>
                <FieldTextarea
                  name="message"
                  fontSize={14}
                  fontWeight={fontWeights.regular}
                />
              </StyledPanel>
            </ContentWrapper>
            <ButtonBar sticky>
              <BackButton
                theme="neutral"
                width={150}
                left={0}
                onClick={history.goBack}
              >
                {t('l.button_bar.edit.back.button', 'Back')}
              </BackButton>
              <CheckboxContainer>
                <CheckboxText>
                  <Trans i18nKey="b.application.view.agree_to_terms_and_conditions.text">
                    I have read and agree to the{' '}
                    <StyledAnchor
                      as="a"
                      href={`${WEB_URL}/terms`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions.
                    </StyledAnchor>
                  </Trans>
                </CheckboxText>
                <Checkbox
                  data-cy="agree_to_terms"
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={e => setAcceptedTerms(e.target.checked)}
                />
              </CheckboxContainer>
              <Button
                id={`logged-apply-submit-${listingId}`}
                theme="primary"
                width={150}
                type="submit"
                disabled={
                  isMissingFields ||
                  !isValid ||
                  errors['move_in_date'] ||
                  !touched['move_in_date']
                }
              >
                {t('r.application.create.apply.button', 'Apply')}
              </Button>
            </ButtonBar>
            <Persist
              name={`create-application-${listingId}`}
              isSessionStorage
            />
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default ApplicationCreate
