import React, { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { append, find, map, mergeRight, pick, pipe, propEq, prop } from 'ramda'
import { breakpoints, colors } from 'styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { renameKeys } from 'utils'
import toArray from 'utils/to-array'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import { Row, Group, FieldInput, FieldDropdown } from 'components/form'
import Icon from 'components/icon'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import StateDropdown from 'components/state-dropdown'
import ContentWrapper from 'components/content-wrapper'
import { show as showFindNumbersModal } from './find-numbers-modal'
import {
  Wrapper,
  StyledAreaTitle,
  StyledPanel,
  AssuranceText
} from './elements'
import Context from './context'

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
`

const InfoText = styled.p`
  height: 20px;
  color: ${colors.link};
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 20px;
  margin: 0 0 0 5px;
`

const FinancialInfo = ({ setCurrentStep, handleBack }) => {
  const { t } = useTranslation()
  const { formData, setFormData } = useContext(Context)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const { countries, financial_institutions } = useBasic([
    'countries',
    'financial_institutions'
  ])

  const financialInstitutions = append(
    {
      id: '46',
      name: t(
        'l.payment_settings.edit.i_cannot_find_my_bank.text',
        'I cannot find my bank'
      ),
      txt_id: 'other',
      fin: null
    },
    toArray(financial_institutions)
  )
  const financialInstitutionsOptions = map(
    pipe(
      pick(['name', 'txt_id']),
      renameKeys({
        name: 'label',
        txt_id: 'value'
      })
    )
  )(financialInstitutions)

  const updateFIN = (setFieldValue, institutionName) => {
    const currentInstitution = find(propEq('txt_id', institutionName))(
      financialInstitutions
    )
    const FIN = prop('fin', currentInstitution) || ''
    if (currentInstitution && institutionName !== FIN) {
      setFieldValue('financial_institution_number', FIN)
    }
  }

  const initialValues = {
    financial_institution_name: formData?.financial_institution_name || '',
    custom_financial_institution: formData?.custom_financial_institution || '',
    account_type: formData?.account_type || '',
    transit_number: formData?.transit_number || '',
    financial_institution_number: formData?.financial_institution_number || '',
    account_number: formData?.account_number || '',
    bank_street1: formData?.bank_street1 || '',
    bank_street2: formData?.bank_street2 || '',
    bank_city: formData?.bank_city || '',
    bank_zip: formData?.bank_zip || '',
    bank_country: 'ca',
    bank_state: formData?.bank_state || ''
  }

  const validationSchema = Yup.object().shape({
    financial_institution_name: Yup.string().min(2).required(),
    custom_financial_institution: Yup.mixed().when(
      'financial_institution_name',
      {
        is: 'other',
        then: Yup.string().min(2).required(),
        otherwise: Yup.string()
      }
    ),
    account_type: Yup.string().required(),
    transit_number: Yup.string()
      .matches(
        /^[0-9]{5}$/,
        t(
          'l.payment_settings.edit.transit_number_length.error',
          'Must be a 5-digit number, no spaces or dashes'
        )
      )
      .required(),
    financial_institution_number: Yup.string()
      .matches(
        /^[0-9]{3}$/,
        t(
          'l.payment_settings.edit.institution_number_length.error',
          'Must be a 3-digit number, no spaces or dashes'
        )
      )
      .required(),
    account_number: Yup.string()
      .matches(
        /^[0-9]{7,12}$/,
        t(
          'l.payment_settings.edit.account_number_length.error',
          'Must be a 7-12 digit number, no spaces or dashes'
        )
      )
      .required(),
    bank_street1: Yup.string().min(2).required(),
    bank_street2: Yup.string(),
    bank_city: Yup.string().min(2).required(),
    bank_zip: Yup.string().min(2).required(),
    bank_country: Yup.string().required(),
    bank_state: Yup.string().required()
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setFormData(mergeRight(formData, values))
    setSubmitting(false)
    setCurrentStep('review')
    window.scrollTo(0, 0)
  }

  const accountTypes = [
    {
      value: 'checking',
      label: t(
        'l.payment_settings.edit.checking_account.label',
        'Chequing account'
      )
    },
    {
      value: 'savings',
      label: t(
        'l.payment_settings.edit.savings_account.label',
        'Savings account'
      )
    }
  ]

  const countryDropDown = (setValue, countryName, stateName) => (
    <Group col={colSize}>
      <FieldDropdown
        id={countryName}
        name={countryName}
        placeholder={
          t('l.payment_settings.edit.country.label', 'Country') + ':'
        }
        options={[
          {
            value: 'ca',
            label: t('l.payment_settings.edit.canada_only.value', 'Canada only')
          }
        ]}
        onChange={() => setValue(stateName, '')}
        disabled
      />
    </Group>
  )

  const provinceDropDown = (formValues, countryName, stateName) => (
    <StateDropdown
      col={colSize}
      country={formValues[countryName]}
      countries={countries}
      id={stateName}
      name={stateName}
    />
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="flex-full">
          <Wrapper>
            <ContentWrapper className="mb-50">
              <StyledAreaTitle>
                {t(
                  'l.payment_settings.edit.financial_account_information.subtitle',
                  'Financial Account Information'
                )}
              </StyledAreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Row>
                  <Group col={colSize}>
                    <FieldDropdown
                      id="financial_institution_name"
                      name="financial_institution_name"
                      options={financialInstitutionsOptions}
                      placeholder={
                        t(
                          'l.payment_settings.edit.financial_institution_name.label',
                          'Financial institution name'
                        ) + ':'
                      }
                      onChange={institutionName =>
                        updateFIN(setFieldValue, institutionName)
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldDropdown
                      id="account_type"
                      name="account_type"
                      options={accountTypes}
                      placeholder={
                        t(
                          'l.payment_settings.edit.account_type.label',
                          'Select account'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                {values.financial_institution_name === 'other' && (
                  <Row>
                    <Group col={colSize}>
                      <FieldInput
                        id="custom_financial_institution"
                        name="custom_financial_institution"
                        placeholder={
                          t(
                            'l.payment_settings.edit.financial_institution_name.label',
                            'Financial institution name'
                          ) + ':'
                        }
                      />
                    </Group>
                  </Row>
                )}
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="transit_number"
                      name="transit_number"
                      placeholder={
                        t(
                          'l.payment_settings.edit.transit_number.label',
                          'Transit number'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="financial_institution_number"
                      name="financial_institution_number"
                      placeholder={
                        t(
                          'l.payment_settings.edit.financial_institution_number.label',
                          'Financial institution number'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="account_number"
                      name="account_number"
                      placeholder={
                        t(
                          'l.payment_settings.edit.account_number.label',
                          'Account number'
                        ) + ':'
                      }
                    />
                    <InfoWrapper onClick={showFindNumbersModal}>
                      <Icon id="info_blue" width={16} height={16} />
                      <InfoText>
                        {t(
                          'l.payment_settings.edit.find_account_numbers_info.button',
                          'Where do I find these numbers?'
                        )}
                      </InfoText>
                    </InfoWrapper>
                  </Group>
                </Row>
              </StyledPanel>
              <StyledAreaTitle>
                {t(
                  'l.payment_settings.edit.institution_address.subtitle',
                  'Financial institution address'
                )}
              </StyledAreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="bank_street1"
                      name="bank_street1"
                      placeholder={
                        t(
                          'l.payment_settings.edit.street_address.label',
                          'Street address'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="bank_street2"
                      name="bank_street2"
                      placeholder={
                        t(
                          'l.payment_settings.edit.unit_number.label',
                          'Unit number'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      id="bank_city"
                      name="bank_city"
                      placeholder={
                        t('l.payment_settings.edit.city.label', 'City') + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      id="bank_zip"
                      name="bank_zip"
                      placeholder={
                        t(
                          'l.payment_settings.edit.postal_code.label',
                          'Postal code'
                        ) + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  {countryDropDown(setFieldValue, 'bank_country', 'bank_state')}
                  {provinceDropDown(values, 'bank_country', 'bank_state')}
                </Row>
              </StyledPanel>
              <AssuranceText>
                <Icon id={'secure'} height={24} width={24} />
                {t(
                  'l.payment_settings.edit.safe_data_policy.text',
                  'Your info will be encrypted, safely stored and will never be shared with anybody.'
                )}
              </AssuranceText>
            </ContentWrapper>
          </Wrapper>
          <ButtonBar sticky>
            <Button theme="sane" width={150} inversed onClick={handleBack}>
              {t('l.payment_settings.view.back.button', 'Back')}
            </Button>
            <Button
              type="submit"
              theme="primary"
              width={150}
              isSubmitting={isSubmitting}
            >
              {t('l.payment_settings.view.next.button', 'Next')}
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  )
}

export default FinancialInfo
