import { createSelector } from 'reselect'
import {
  __,
  flip,
  any,
  ascend,
  defaultTo,
  descend,
  filter,
  find,
  includes,
  map,
  path,
  pick,
  pipe,
  prop,
  propEq,
  reject,
  reverse,
  sortWith,
  values,
  and
} from 'ramda'
import { mergeSpec, headObj } from 'utils'

export const timelinesSelector = createSelector(
  path(['chat', 'timelines']),
  path(['chat', 'users']),
  path(['chat', 'lastEvents']),
  (timelines, users = {}, lastEvents) =>
    pipe(
      values,
      reverse,
      sortWith([descend(prop('last_event'))]),
      map(
        mergeSpec({
          users: {
            them: pipe(
              path(['user_ids', 'them']),
              defaultTo([]),
              pick(__, users),
              reject(
                and(propEq('first_name', 'Liv'), propEq('last_name', 'Support'))
              ),
              values
            ),
            us: pipe(
              path(['user_ids', 'us']),
              defaultTo([]),
              pick(__, users),
              values
            )
          },
          last_event_message: pipe(
            prop('timeline_room'),
            prop(__, lastEvents),
            defaultTo({}),
            headObj
          )
        })
      )
    )(timelines)
)

export const timelineSelector = createSelector(
  flip(prop('timelineId')),
  timelinesSelector,
  (timelineId, timelines) =>
    find(propEq('timeline_room', timelineId))(timelines)
)

export const listingTimelinesSelector = createSelector(
  timelinesSelector,
  flip(prop('listingId')),
  (timelines, listingId) => filter(propEq('listing_id', listingId), timelines)
)

export const tenantTimelinesSelector = createSelector(
  listingTimelinesSelector,
  (state, props) =>
    path(['listing', props.listingId, 'contract', 'tenant_ids'], state),
  (timelines, tenantIds) =>
    filter(
      pipe(
        path(['users', 'them']),
        map(prop('id')),
        any(includes(__, defaultTo([], tenantIds)))
      ),
      timelines
    )
)

export const timelineEventsSelector = createSelector(
  flip(prop('timelineId')),
  prop('events'),
  (timelineId, events) =>
    pipe(
      prop(timelineId),
      values,
      reject(propEq('event_type', 'chat_listing_match')),
      sortWith([ascend(prop('created_at')), ascend(prop('id'))])
    )(events)
)

export const callbackUserSelector = createSelector(
  prop('users'),
  path(['files', 'user']),
  (users, files) =>
    pipe(
      headObj,
      pick(['id', 'first_name', 'last_name', 'preferred_name']),
      mergeSpec({
        avatar: pipe(prop('id'), prop(__, files), headObj)
      })
    )(users)
)
