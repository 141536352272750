import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { Formik, Form } from 'formik'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import MobileFooterButton from 'components/mobile-footer-button'
import { RadioSelectorList, SelectorItem } from 'components/selector-list'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const ModalClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.7;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 50px 20px 70px;
`

const Title = styled.p`
  font-size: 23px;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  margin: 0;
`

const StyledRadioSelectorList = styled(RadioSelectorList)`
  margin: 30px 0;
  border-radius: 2px;
  width: 325px;

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    width: 280px;
  }
`

const StyledSelectorItem = styled(SelectorItem)`
  background-color: ${colors.white};
  margin: 0;
  padding: 0 10px;

  &:first-child {
    border-radius: 2px 2px 0 0;
  }

  &:last-child {
    border-radius: 0 0 2px 2px;
  }
`

const Label = styled.label`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  margin: 0;
`

const SortModal = ({ currentValue, handleSortChange }) => {
  const { close } = useModalize()
  const { t } = useTranslation()

  const SORT_VALUES = {
    verified: {
      1: 'verified_1'
    },
    salary: {
      2: 'salary_2'
    },
    budget: {
      2: 'budget_2',
      1: 'budget_1'
    },
    completion: {
      1: 'completion_1'
    }
  }
  const formatSort = ({ sort_by, order }) => SORT_VALUES[sort_by][order]
  const formatSortObject = sortString => {
    const values = sortString.split('_')
    return {
      sort_by: values[0],
      order: values[1]
    }
  }

  const formOptions = {
    initialValues: {
      sort_filter: formatSort(currentValue)
    },
    onSubmit: async (values, { setSubmitting }) => {
      handleSortChange(formatSortObject(values.sort_filter))
      setSubmitting(false)
      close()
    }
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Title>{t('b.listings.view.sort_by.label', 'Sort by')}</Title>
        <Formik {...formOptions}>
          {({ values }) => (
            <Form>
              <StyledRadioSelectorList
                name="sort_filter"
                value={values.sort_filter}
              >
                <StyledSelectorItem value="verified_1">
                  <Label>
                    {t(
                      'l.listings.view.suggested_tenants_sort_id_verified.text',
                      'ID Verified'
                    )}
                  </Label>
                </StyledSelectorItem>
                <StyledSelectorItem value="salary_2">
                  <Label>
                    {t(
                      'l.listings.view.suggested_tenants_sort_salary_highest.text',
                      'Salary (highest)'
                    )}
                  </Label>
                </StyledSelectorItem>
                <StyledSelectorItem value="budget_2">
                  <Label>
                    {t(
                      'l.listings.view.suggested_tenants_sort_budget_highest.text',
                      'Budget (highest)'
                    )}
                  </Label>
                </StyledSelectorItem>
                <StyledSelectorItem value="budget_1">
                  <Label>
                    {t(
                      'l.listings.view.suggested_tenants_sort_budget_lowest.text',
                      'Budget (lowest)'
                    )}
                  </Label>
                </StyledSelectorItem>
                <StyledSelectorItem value="completion_1">
                  <Label>
                    {t(
                      'l.listings.view.suggested_tenants_sort_profile_completion.text',
                      'Profile Completion'
                    )}
                  </Label>
                </StyledSelectorItem>
              </StyledRadioSelectorList>
              <MobileFooterButton
                breakpoint={breakpoints.phoneMax}
                type="submit"
                onClick={() => {}}
              >
                {t('common.save', 'Save')}
              </MobileFooterButton>
            </Form>
          )}
        </Formik>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(SortModal, {
  width: '100%',
  maxWidth: '100%',
  borderRadius: '0px'
})
