import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { select } from 'store'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import { reject, propEq, path, sortBy, prop } from 'ramda'
import useMediaQuery from 'hooks/use-media-query'
import formatName from 'utils/format-name'
import { Avatar } from 'components/avatar'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import Pill from 'components/pill'
import { confirm } from 'components/dialog'
import { showUserPicker } from 'components/user-picker/'
import VerifiedBadge from 'components/verified-badge'
import useCompany from 'hooks/use-company'
import { getImageUrl } from 'utils/image'
import isVerified from 'utils/is-verified'

const LandlordSectionTitle = styled.p`
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  margin-top: 30px;
`

const LandlordContainer = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 2px solid ${colors.grey};
  border-radius: 5px;
`

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const Disclaimer = styled.p`
  color: ${colors.darkGrey};
`

const EmptyLandlordContainer = styled(LandlordContainer)`
  border-style: dashed;
  cursor: pointer;
`

const EmptyLandlordText = styled.p`
  font-size: 15px;
`

const LandlordInfo = styled.div`
  display: flex;
  align-items: center;
`

const EmptyLandlordInfo = styled(LandlordInfo)`
  opacity: 0.8;
`

const LandlordName = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    max-width: 130px;
  }
`

const Primary = styled(Pill)`
  margin: 0;
  margin-left: 20px;
  position: relative;
  background-color: ${colors.pink};

  @media screen and (max-width: 450px) {
    display: none;
  }
`

const LandlordButton = styled.button`
  color: ${colors.link};
  background-color: ${colors.white};
  border: none;
  width: 90px;
  height: 50px;
  font-size: ${fontSizes.small};
  outline: none;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }
`

const MAX_SECONDARY = 4

const alphabetize = sortBy(prop('preferred_name'))

const Landlord = ({ landlord, children, onClick }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const company = useCompany(landlord.company_id)
  return (
    <LandlordContainer>
      <LandlordInfo>
        <Avatar
          location={getImageUrl(landlord.avatar?.aws_s3_key, {
            width: 40,
            height: 40
          })}
          shadow
          width={40}
          height={40}
          right={15}
          left={displayMobile ? 10 : 25}
        />
        <LandlordName>{formatName(landlord)}</LandlordName>
        <VerifiedBadge
          type="id"
          width={24}
          height={18}
          right={5}
          left={5}
          verified={isVerified(landlord, company)}
        />
        {children}
      </LandlordInfo>
      <LandlordButton onClick={onClick}>
        {t('l.listing.edit.remove.button', 'Remove')}
      </LandlordButton>
    </LandlordContainer>
  )
}

const LandlordManagement = ({
  primaryLandlord,
  setPrimaryLandlord,
  secondaryLandlords,
  setSecondaryLandlords
}) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const currentUser = useSelector(path(['profile', 'id']))
  const allLandlords = alphabetize(useSelector(select.user.companyUsers))

  const handleDeletePrimary = user => {
    const updatedData = reject(propEq('id', user.id), primaryLandlord)
    setPrimaryLandlord(updatedData)
  }

  const handleDeleteSecondary = user => {
    const updatedData = reject(propEq('id', user.id), secondaryLandlords)
    setSecondaryLandlords(updatedData)
  }

  const confirmDelete = (userId, handleDelete) => {
    userId === currentUser
      ? confirm(
          t('l.listing.edit.update_confirmation.title', 'Update Confirmation'),
          t(
            'l.listing.edit.remove_yourself_confirmation.text',
            'Are you sure you want to remove yourself from this listing?'
          )
        ).then(async () => {
          handleDelete()
        })
      : handleDelete()
  }

  const handleSetPrimary = user => {
    const primaryCopy = user[0]
    primaryCopy.primary = true
    setPrimaryLandlord([primaryCopy])
  }

  const handleSetSecondary = users => {
    setSecondaryLandlords(users)
  }

  const renderPrimaryLandlord = landlord => {
    return landlord ? (
      <Landlord
        landlord={landlord}
        onClick={() =>
          confirmDelete(landlord.id, () =>
            handleDeletePrimary(landlord, primaryLandlord, setPrimaryLandlord)
          )
        }
      >
        <Primary theme="primary" radius={6} height={24} width={60}>
          {t('l.listing.edit.primary.text', 'Primary')}
        </Primary>
      </Landlord>
    ) : (
      <EmptyLandlordContainer
        onClick={() =>
          showUserPicker({
            title: t(
              'l.listing.edit.select_landlords.title',
              'Select Primary Landlord'
            ),
            maxSelect: 1,
            allUsers: allLandlords,
            activeUsers: primaryLandlord,
            disabledUsers: secondaryLandlords,
            setActiveUsers: handleSetPrimary
          })
        }
      >
        <EmptyLandlordInfo>
          <Avatar
            shadow
            width={40}
            height={40}
            right={15}
            left={displayMobile ? 10 : 25}
          />
          <EmptyLandlordText>
            {t(
              'l.listing.edit.add_primary_landlord.text',
              'Add a primary landlord'
            )}
          </EmptyLandlordText>
        </EmptyLandlordInfo>
        <LandlordButton>{t('l.listing.edit.add.button', 'Add')}</LandlordButton>
      </EmptyLandlordContainer>
    )
  }

  const renderLandlords = landlords => {
    const emptySlots = []
    for (let i = 0; i < MAX_SECONDARY - landlords.length; i++) {
      emptySlots.push(
        <EmptyLandlordContainer
          key={`empty-slot-${i}`}
          onClick={() =>
            showUserPicker({
              title: t(
                'l.listing.edit.select_secondary_landlords.title',
                'Select Secondary Landlords'
              ),
              maxSelect: MAX_SECONDARY,
              allUsers: allLandlords,
              activeUsers: secondaryLandlords,
              disabledUsers: primaryLandlord,
              setActiveUsers: handleSetSecondary
            })
          }
        >
          <EmptyLandlordInfo>
            <Avatar
              shadow
              width={40}
              height={40}
              right={15}
              left={displayMobile ? 10 : 25}
            />
            <EmptyLandlordText>
              {t(
                'l.listing.edit.add_additional_landlord.text',
                'Add an additional landlord (optional)'
              )}
            </EmptyLandlordText>
          </EmptyLandlordInfo>
          <LandlordButton>
            {t('l.listing.edit.add.button', 'Add')}
          </LandlordButton>
        </EmptyLandlordContainer>
      )
    }
    return (
      <>
        {landlords.map(landlord => (
          <Landlord
            landlord={landlord}
            key={landlord.id}
            onClick={() =>
              confirmDelete(landlord.id, () => handleDeleteSecondary(landlord))
            }
          />
        ))}
        {emptySlots}
      </>
    )
  }

  return (
    <>
      <AreaTitle>
        {t(
          'l.listing.edit.landlord_management.subtitle',
          'Landlord Management'
        )}
      </AreaTitle>
      <StyledPanel vSpace={30} hSpace={30}>
        <Disclaimer>
          {t(
            'l.listing.edit.landlords_description.text',
            'Landlord(s) in charge of this listing (must have at least one landlord):'
          )}
        </Disclaimer>
        <LandlordSectionTitle>
          {t('l.listing.edit.primary_landlord.text', 'Primary Landlord')}
        </LandlordSectionTitle>
        {renderPrimaryLandlord(primaryLandlord[0])}
        <LandlordSectionTitle>
          {t(
            'l.listing.edit.additional_landlords.text',
            'Additional Landlords'
          )}
        </LandlordSectionTitle>
        {renderLandlords(secondaryLandlords)}
      </StyledPanel>
    </>
  )
}

export default LandlordManagement
