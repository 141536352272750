import React, { useContext } from 'react'
import styled from 'styled-components'
import Thumbnail from 'components/thumbnail'
import moment from 'moment'
import { withRouter } from 'react-router'
import { breakpoints, colors, fontWeights, fontSizes } from 'styles'
import Moment from 'react-moment'
import capitalizeSentence from 'utils/capitalize-sentence'
import { useTranslation } from 'react-i18next'
import { ChatContext } from 'features/Chat/chat-side-bar/chat-context'
import VerifiedBadge from 'components/verified-badge'
import NameBanner from '../name-banner'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 84px;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid ${colors.grey};
  
  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.isSelected &&
    `
    background-color: rgba(75, 143, 226, 0.15);
  `}

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding-left: 25px;
  }
`

const AvatarContainer = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
`

const Notification = styled.div`
  position: absolute;
  left: 1px;
  top: 4px;
  background-color: ${colors.pink};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.6);
`

const ChatInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px;
  width: 130px;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NameText = styled.p`
  margin: 0px;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  color: ${colors.regular};
  line-height: 15px;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const BuildingText = styled(NameText)`
  font-size: 10px;
  font-weight: ${fontWeights.regular};
  line-height: 12px;
  margin: 2px 0 12px;
`

const MessageText = styled(NameText)`
  font-weight: ${fontWeights.regular};
  font-size: 11px;
  line-height: 14px;

  ${props =>
    props.isUnread &&
    `
    font-weight: ${fontWeights.medium};
  `}
`

const TimeStampContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  flex: 0 1 75px;
  align-items: flex-end;
  padding-top: 17px;
`

const TimeStampText = styled.p`
  margin: 0 0 10px 0;
  font-size: 10px;
  color: ${colors.regular};
  text-align: right;
  line-height: 10px;
`

const GenericTag = styled.p`
  font-size: 9px;
  line-height: 9px;
  font-weight: ${fontWeights.medium};
  margin: 0px;
  padding: 3px 9px;
  border-radius: 25px;
`

const UpcomingTag = styled(GenericTag)`
  border: 1px solid ${colors.brightGreen};
  color: ${colors.brightGreen};
`

const AppliedTag = styled(GenericTag)`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
`

const TimelineTag = ({
  hasApplied,
  isRented,
  stateMachine,
  listingStateMachine
}) => {
  const { t } = useTranslation()
  if (stateMachine === 'locked') return null
  if (listingStateMachine === 'upcoming' && isRented)
    return (
      <UpcomingTag>{t('b.chat.view.upcoming.text', 'Upcoming')}</UpcomingTag>
    )
  if (hasApplied && !isRented)
    return <AppliedTag>{t('b.chat.view.applied.text', 'Applied')}</AppliedTag>
  return null
}

const ChatCard = ({
  address,
  avatar,
  hasApplied,
  history,
  state_machine,
  isRented,
  stateMachine,
  listingStateMachine,
  last_event,
  last_event_message,
  last_read,
  match,
  names,
  timeline_room,
  isIdVerified
}) => {
  const { t } = useTranslation()
  const isUnread = !last_read || moment(last_event).isAfter(last_read)
  const getPath = useContext(ChatContext)
  const timelineId = match?.params?.timelineId
  const path = getPath(timeline_room)

  const calendarStrings = {
    lastDay: `[${t('common.yesterday', 'Yesterday')}]`,
    sameDay: `[${t('common.today', 'Today')}]`,
    lastWeek: 'MM-DD',
    sameElse: 'MM-DD'
  }

  return (
    <Wrapper
      isUnread={isUnread}
      isSelected={timeline_room === timelineId}
      onClick={() => history.push(path)}
    >
      <AvatarContainer>
        <Thumbnail height={55} width={55} src={avatar} />
        {isUnread && <Notification />}
      </AvatarContainer>
      <ChatInfoContainer>
        <NameWrapper>
          <NameText>
            <NameBanner names={names} />
          </NameText>
          <VerifiedBadge
            verified={isIdVerified}
            type="id"
            width={20}
            height={14}
            left={5}
          />
        </NameWrapper>
        <BuildingText>{address}</BuildingText>
        <MessageText isUnread={isUnread}>
          {capitalizeSentence(
            last_event_message?.message || last_event_message
          )}
        </MessageText>
      </ChatInfoContainer>
      {last_event && (
        <TimeStampContainer>
          <TimeStampText>
            <Moment calendar={calendarStrings}>{`${last_event}-0000`}</Moment>
          </TimeStampText>
          {state_machine}
          <TimelineTag
            hasApplied={hasApplied}
            isRented={isRented}
            stateMachine={stateMachine}
            listingStateMachine={listingStateMachine}
          />
        </TimeStampContainer>
      )}
    </Wrapper>
  )
}

ChatCard.defaultProps = {
  timeline: {},
  url: '',
  name: '',
  unit: '',
  last_read: '',
  last_event: '',
  isIdVerified: false
}

export default withRouter(React.memo(ChatCard))
