import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'
import useSession from 'hooks/use-current-session'
import { showAgreementModal } from './agreement-modal'

const AgreementModal = () => {
  const dispatch = useDispatch()
  const userAgreements = useSelector(path(['session', 'session', 'agreements']))
  const mismatched = useSelector(path(['agreement', 'mismatched']))
  const mismatchLength = Object.keys(mismatched).length
  const session = useSession()
  const registrationComplete = session?.registration_complete
  useEffect(() => {
    dispatch.agreement.validateAgreements()
  }, [dispatch.agreement, userAgreements])

  useEffect(() => {
    if (mismatchLength > 0 && registrationComplete === '1') {
      showAgreementModal()
    }
  }, [mismatchLength, registrationComplete])

  return null
}

export default AgreementModal
