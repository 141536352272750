import { useSelector } from 'react-redux'
import { path, pipe, prop } from 'ramda'
import { isTruthy } from 'utils'

const useHasLinkedIn = () => {
  const session = useSelector(prop('session')) || {}
  const userId = session.session?.id

  const hasLinkedIn = pipe(
    path([userId, 'social_media']),
    isTruthy
  )(session.user_social_media_tokens)

  return hasLinkedIn
}

export default useHasLinkedIn
