import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from 'components/button'
import { confirm } from 'components/dialog'
import ButtonBar from 'components/button-bar'

const ListingButtonBar = ({
  listingId,
  saveAndExit,
  isSubmitting,
  isDraft,
  children,
  ...rest
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleDelete = async () => {
    const { response } = await dispatch.listing.delete(listingId)
    if (response.ok) {
      history.replace('/listings')
    }
  }

  const deleteTitleText = isDraft
    ? t('l.delete_listing_modal.edit.delete_draft.title', 'Delete Draft?')
    : t('l.delete_listing_modal.edit.delete_listing.title', 'Delete Listing?')

  const deleteMessageText = isDraft
    ? t(
        'l.delete_listing_modal.edit.draft_confirmation.text',
        'Are you sure you want to delete this draft?'
      )
    : [
        t(
          'l.delete_listing_modal.edit.confirmation.text',
          'Are you sure you want to delete this listing?'
        ),
        t(
          'l.delete_listing_modal.edit.warning.text',
          'All saved data will be lost.'
        )
      ]

  const confirmDelete = () => {
    confirm(deleteTitleText, deleteMessageText).then(async () => {
      handleDelete()
    })
  }

  return (
    <ButtonBar {...rest} sticky>
      <Button
        theme="sane"
        width={110}
        inversed
        disabled={isSubmitting}
        onClick={confirmDelete}
      >
        {t('l.button_bar.edit.delete.button', 'Delete')}
      </Button>
      <Button
        theme="sane"
        width={150}
        left={10}
        right={10}
        disabled={isSubmitting}
        onClick={saveAndExit}
      >
        {t('l.button_bar.edit.save_and_exit.button', 'Save & Exit')}
      </Button>
      {children}
    </ButtonBar>
  )
}

export default React.memo(ListingButtonBar)
