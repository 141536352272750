import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Manager, Reference, Popper } from 'react-popper'
import { CloseClickOutside } from 'components/window'
import ShareMenu from './share-menu'
import shareIcon from './share-icon.svg'
import Base from '../base'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const MenuWrapper = styled.div`
  z-index: 101;
`

const ShareButton = ({ listingId }) => {
  const { t } = useTranslation()
  const [showShare, setShowShare] = useState(false)
  const closeShare = () => setShowShare(false)
  const toggleShare = () => setShowShare(!showShare)

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <ButtonWrapper ref={ref}>
            <Base onClick={toggleShare}>
              <img src={shareIcon} alt="" />
              {t('common.share', 'Share')}
            </Base>
          </ButtonWrapper>
        )}
      </Reference>
      <Popper placement="bottom-end">
        {({ ref, style, placement }) =>
          showShare && (
            <CloseClickOutside onClickOutside={closeShare}>
              <MenuWrapper ref={ref} style={style} data-placement={placement}>
                <ShareMenu listingId={listingId} />
              </MenuWrapper>
            </CloseClickOutside>
          )
        }
      </Popper>
    </Manager>
  )
}

export default ShareButton
