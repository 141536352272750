import { useEffect } from 'react'
import { parseUrl } from 'query-string'
import { useDispatch } from 'react-redux'

const StripeConnectContainer = ({ location, history }) => {
  const { query } = parseUrl(location?.search)
  const dispatch = useDispatch()

  useEffect(() => {
    if (query) {
      const connect = async () => {
        if (!query.error) {
          const path = window.localStorage.getItem('redirect-stripe-url')
          await dispatch.payment.connectSuccessful(query)

          if (!window.ReactNativeWebView) {
            history.push(path || '/')
          }
        }

        if (query.error) {
          await dispatch.payment.connectFailed()
        }
      }
      connect()
    }
  }, [dispatch.payment, history, query])

  return null
}

export default StripeConnectContainer
