import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { colors, fontWeights } from 'styles'
import config from 'config'
import CurrencyFormatter from 'components/currency-formatter'
import { createModal, useModalize } from 'components/modalize'
import { Button } from 'components/button'
import Icon from 'components/icon'
import { CollapsibleBox } from 'components/collapsible'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 95vh;
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`

const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
  margin: 15px 0;
`

const ModalText = styled.p`
  margin: 0 0 50px 0;
  text-align: center;

  span {
    font-weight: ${fontWeights.medium};
  }
`

const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.totalRow &&
    `
    flex-direction: row;
    padding-top: 20px;
    border-top: 1px solid ${colors.grey};
    font-weight: ${fontWeights.medium};
  `}
`

const Label = styled.span`
  flex: 9999;
  margin-right: 30px;
`

const Value = styled.span`
  justify-content: flex-end;
  font-weight: ${fontWeights.medium};
`

const LabeledValue = ({ label, value, totalRow }) => (
  <ValueWrapper totalRow={totalRow}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </ValueWrapper>
)

const PaymentCompletedModal = ({ data, onClose }) => {
  const { t } = useTranslation()
  const { close } = useModalize()

  const paymentRequestId = data.id
  const payeeName = `${data.preferred_name} ${data.last_name}.`
  const hasDiscount = !!data.discount_amount && data.discount_amount > 0
  const paymentAmount = hasDiscount
    ? data.requested_amount - data.discount_amount
    : data.requested_amount

  const getPdfUrl = () =>
    `${config.BACKEND_SERVER}/payment_request/receipt/tenant/${paymentRequestId}/receipt.pdf`

  const handleClose = () => {
    close()
    onClose()
  }

  return (
    <Wrapper>
      <ScrollWrapper>
        <Icon id="round_check" width={75} height={75} />
        <ModalTitle>
          {t('r.payment.view.payment_completed.title', 'Payment Completed')}
        </ModalTitle>
        <ModalText>
          <Trans i18nKey="r.payment.view.payment_made_to.text">
            You just made a payment to <span>{{ payeeName }}</span>
          </Trans>
        </ModalText>
        <CollapsibleBox
          title={t(
            'r.payment.view.payment_summary.subtitle',
            'Payment Summary'
          )}
          wrapChildren
          bottom={50}
          minWidth={250}
        >
          <LabeledValue
            key="confirmation-number"
            label={t(
              'b.contract.view.confirmation_number.label',
              'Confirmation No.'
            )}
            value={data.transaction_info?.charge_id}
          />
          <LabeledValue
            key="payment-detail"
            label={t('r.payment.view.subtotal.label', 'Subtotal')}
            value={<CurrencyFormatter value={paymentAmount} showCents />}
          />
          <LabeledValue
            key="payment-fees"
            label={t('r.payment.view.processing_fees.label', 'Processing fees')}
            value={<CurrencyFormatter value={data.fee} showCents />}
          />
          <LabeledValue
            totalRow
            key="payment-total"
            label={t('r.payment.view.total.label', 'Total')}
            value={<CurrencyFormatter value={data.charged} showCents />}
          />
        </CollapsibleBox>
        <ButtonWrapper>
          <Button
            as="a"
            theme="sane"
            inversed
            width={140}
            right={15}
            href={getPdfUrl()}
            target="_blank"
          >
            {t('r.payment.view.save_copy.button', 'Save a Copy')}
          </Button>
          <Button theme="primary" width={140} onClick={handleClose}>
            {t('r.payment.view.done.button', 'Done')}
          </Button>
        </ButtonWrapper>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(PaymentCompletedModal, { clickOutside: false })
