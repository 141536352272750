import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { select } from 'store'
import useMediaQuery from 'hooks/use-media-query'
import { EmploymentInfoForm } from 'features/Profile/EmploymentInfo'
import { show as showNotificationModal } from 'components/notification-modal'
import { FieldDropdown, Row, Group, Error } from 'components/form'
import { Button } from 'components/button'
import ProfileButtonBar from 'features/Profile/button-bar'
import Page from 'components/page'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import ContentWrapper from 'components/content-wrapper'
import UploadIncomeForm from './upload-income-form'
import salaryRange from './salary-range.png'

const SalaryWrapper = styled.div`
  margin-bottom: 20px;
`

const PanelHeader = styled.h2`
  margin: 60px 0px 30px 0px;
  font-size: ${fontSizes.medium};
  color: ${colors.regular};
  text-align: center;
`

const PanelContainer = styled(Panel)`
  > :first-child {
    padding-top: 0px;
  }
  > :last-child {
    padding-bottom: 0px;
    border-bottom-width: 0px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const UploadDescriptionText = styled.h2`
  margin: 0px 0px 20px;
  font-size: ${fontSizes.regular};
`

const BoldText = styled.p`
  font-weight: ${fontWeights.medium};
  margin: 0px;
  font-size: ${fontSizes.regular};
  display: inline;
`

const SalaryIcon = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;
  background-image: url('${salaryRange}');
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 5px;
`

const EditLivScore = ({ history, location }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6

  const data = useSelector(select.profile.editTenantProfile)
  const employmentFile = data?.employment_verification
  const studentFile = data?.school_verification

  const getSchoolVerificationBody = (values, incomeBody) => {
    const schoolBody = {
      data: values.school_verification,
      tag: 'school_verification',
      metadata: {
        document_type: values.school_document_type?.type
      }
    }
    if (values.income_verification.id) {
      return [schoolBody]
    }

    if (values.school_verification.id) {
      return [incomeBody]
    }

    return [incomeBody, schoolBody]
  }

  const getEmploymentVerificationBody = (values, incomeBody) => {
    const employmentBody = {
      data: values.employment_verification,
      tag: 'employment_verification',
      metadata: {
        document_type: values.employment_document_type?.type
      }
    }
    if (values.income_verification.id) {
      return [employmentBody]
    }

    if (values.employment_verification.id) {
      return [incomeBody]
    }
    return [incomeBody, employmentBody]
  }

  const handleSend = values => {
    const body = (() => {
      if (values.job_title === 'student') {
        const incomeBody = {
          data: values.income_verification,
          tag: 'income_verification',
          metadata: {
            document_type: values.income_document_type?.type,
            user: {
              salary: values.salary || null
            }
          }
        }
        return getSchoolVerificationBody(values, incomeBody)
      } else if (values.job_title === 'unemployed') {
        return [
          {
            data: values.income_verification,
            tag: 'income_verification',
            metadata: {
              document_type: values.income_document_type?.type,
              user: {
                job_title: values.job_title,
                salary: values.salary || null
              }
            }
          }
        ]
      } else if (values.job_title === 'self_employed') {
        const incomeBody = {
          data: values.income_verification,
          tag: 'income_verification',
          metadata: {
            document_type: values.income_document_type?.type,
            user: {
              job_title: 'self_employed',
              company_info: {
                name: values.company_info.name
              },
              salary: values.salary || null
            }
          }
        }
        return getEmploymentVerificationBody(values, incomeBody)
      } else {
        const incomeBody = {
          data: values.income_verification,
          tag: 'income_verification',
          metadata: {
            document_type: values.income_document_type?.type,
            user: {
              job_title: values.job_title,
              company_info: {
                name: values.company_info.name || null
              },
              salary: values.salary || null
            }
          }
        }
        return getEmploymentVerificationBody(values, incomeBody)
      }
    })()
    return dispatch.livScore.sendFile(body)
  }

  const onFileSelected = async (setFieldValue, fileUrl, fileType) => {
    setFieldValue(fileType, fileUrl)
  }

  const handleBack = useCallback(() => {
    history.replace(location.state?.onBackRedirectTo || '/profile/liv-score')
  }, [history, location])

  const initialValues = {
    ...data,
    income_document_type: {},
    employment_document_type: {},
    school_document_type: {}
  }

  const userNoLongerAStudent = formValues => {
    return (
      formValues.job_title !== 'student' &&
      initialValues.job_title === 'student'
    )
  }

  const userBecameStudentAndWasNotUnemployed = formValues =>
    formValues.job_title === 'student' &&
    initialValues.job_title &&
    initialValues.job_title !== 'student' &&
    initialValues.job_title !== 'unemployed'

  const deleteSchoolVerification = async setFieldValue => {
    setFieldValue('school_verification', '')
    await dispatch.livScore.deleteFile({
      fileId: studentFile.id,
      tag: studentFile.tag
    })
  }

  const deleteEmploymentVerification = async setFieldValue => {
    setFieldValue('employment_verification', '')
    await dispatch.livScore.deleteFile({
      fileId: employmentFile.id,
      tag: employmentFile.tag
    })
  }

  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      job_title: Yup.string(),
      school_name: Yup.string().when('job_title', {
        is: 'student',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      school_subject: Yup.string().when('job_title', {
        is: 'student',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      school_start_date: Yup.number()
        .max('2040')
        .min('1900')
        .when('job_title', {
          is: 'student',
          then: Yup.number().required(),
          otherwise: Yup.number()
        }),
      income_document_type: Yup.object(),
      employment_document_type: Yup.object(),
      school_document_type: Yup.object(),
      company_name: Yup.string().when(['job_title'], (jobTitle, schema) => {
        if (jobTitle !== 'student' && jobTitle !== 'unemployed') {
          return schema.required()
        }
        return schema
      }),
      income_verification: Yup.string().required(),
      employment_verification: Yup.string().when(
        ['job_title'],
        (jobTitle, schema) => {
          if (jobTitle !== 'student' && jobTitle !== 'unemployed') {
            return schema.required()
          }
          return schema
        }
      ),
      school_verification: Yup.string().when(
        ['job_title'],
        (jobTitle, schema) => {
          if (jobTitle === 'student') {
            return schema.required()
          }
          return schema
        }
      )
    }),
    onSubmit: async (formValues, { setSubmitting, setFieldValue }) => {
      const employeeResponse = await dispatch.profile.save(formValues)
      if (userNoLongerAStudent(formValues)) {
        deleteSchoolVerification(setFieldValue)
      }
      if (userBecameStudentAndWasNotUnemployed(formValues)) {
        deleteEmploymentVerification(setFieldValue)
      }
      const verificationResponse =
        formValues?.income_document_type.tag ||
        formValues?.employment_document_type.tag ||
        (formValues.school_verification && !formValues.school_verification.id)
          ? await handleSend(formValues)
          : { response: { ok: true } }
      setSubmitting(false)
      if (employeeResponse.ok && verificationResponse?.response?.ok) {
        history.push(
          location.state?.onSuccessRedirectTo || '/profile/liv-score'
        )
        window.scrollTo(0, 0)
      }
    }
  })

  if (!data.id) return null

  return (
    <Page>
      <Helmet>
        <title>{t('r.profile.edit.liv_score.title', 'Liv Score')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{t('r.profile.edit.liv_score.title', 'Liv Score')}</PageTitle>
      <Formik {...formOptions()} initialValues={initialValues}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <EmploymentInfoForm
                setFieldValue={setFieldValue}
                onFileSelected={onFileSelected}
                values={values}
              />
              <PanelHeader>
                {t(
                  'r.profile.edit.income_verification.subtitle',
                  'Income Verification'
                )}
              </PanelHeader>
              <PanelContainer hSpace={30} vSpace={40}>
                <SalaryWrapper>
                  <Row>
                    <Group col={colSize}>
                      <FieldDropdown
                        id="salary"
                        name="salary"
                        placeholder={t(
                          'r.profile.edit.my_salary_range_is.label',
                          'My salary range is..'
                        )}
                        onChange={() => {
                          if (values.income_verification.id) {
                            setFieldValue('income_verification', '')
                            showNotificationModal({
                              type: 'success',
                              message: t(
                                'r.profile.edit.livscore.income_reupload_required.notification',
                                'Please upload a new income verification document since your employment information has changed.'
                              )
                            })
                          }
                        }}
                        options={[
                          {
                            value: 'No Income',
                            label: t(
                              'r.profile.edit.no_income.text',
                              'No Income'
                            )
                          },
                          {
                            value: 'Rent Prepay',
                            label: t(
                              'r.profile.edit.income_prepay_12_months.text',
                              'I can prepay 12 months rent'
                            )
                          },
                          { value: '1-29k', label: '$1-$29k' },
                          { value: '30k-59k', label: '$30k-$59k' },
                          { value: '60k-79k', label: '$60k-$79k' },
                          { value: '80k-99k', label: '$80k-$99k' },
                          { value: '100k-149k', label: '$100-$149k' },
                          { value: '150k+', label: '$150k+' }
                        ]}
                      >
                        <SalaryIcon />
                      </FieldDropdown>
                    </Group>
                  </Row>
                </SalaryWrapper>
                <UploadDescriptionText>
                  <Trans i18nKey="r.profile.edit.liv_score_upload_description.text">
                    Provide an additional document to verify your income.
                    Acceptable documents include <BoldText>T4</BoldText>,{' '}
                    <BoldText>Tax Slips</BoldText>,{' '}
                    <BoldText>Pay Slip</BoldText>,{' '}
                    <BoldText>Student Loans</BoldText>,{' '}
                    <BoldText>Bank Statement</BoldText>, and{' '}
                    <BoldText>Job Offer Letter</BoldText>. Your documents are
                    only used for verification and never shared with other
                    people.
                  </Trans>
                </UploadDescriptionText>
                <UploadIncomeForm
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <Error name="income_verification" />
              </PanelContainer>
            </ContentWrapper>
            <ProfileButtonBar handleBack={handleBack} sticky>
              <Button
                type="submit"
                theme="primary"
                width={150}
                left="auto"
                isSubmitting={isSubmitting}
              >
                {t('r.profile.edit.save.label', 'Save')}
              </Button>
            </ProfileButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default React.memo(EditLivScore)
