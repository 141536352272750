import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toNumber } from 'lodash'
import { colors, fontSizes, fontWeights } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { CodeInput } from 'components/code'
import showMessageModal from 'components/message-modal'
import { show as showSuccessModal } from 'components/success-modal'
import VerifiedBadge from 'components/verified-badge'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  min-height: 400px;
  padding: 20px;
`

const ModalClose = styled.div`
  text-align: right;

  svg {
    cursor: pointer;
    margin: 0;
  }

  :hover {
    opacity: 0.8;
  }
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0;
`

const ModalText = styled.p`
  margin: 20px 10px;
  text-align: center;
`

const CodeWrapper = styled.div`
  width: 100%;
  padding: 30px 0;
`

const ErrorText = styled.p`
  color: ${colors.pink};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  text-align: center;
`

const VerifyCodeModal = ({ listingId, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { close } = useModalize()
  const [error, setError] = useState(false)
  const [remaining, setRemaining] = useState()

  const onSubmit = useCallback(
    async values => {
      const { response, body } = await dispatch.listing.submitVerifyCode({
        listingId: toNumber(listingId),
        code: toNumber(values.code)
      })

      if (!response.ok) {
        const remainingAttempts = 5 - (body.attempts_count || 5)
        if (remainingAttempts > 0) {
          setRemaining(remainingAttempts)
          setError(true)
        } else {
          showMessageModal({
            icon: 'warning',
            title: t(
              'l.verify_listing.view.max_attempts_reached.title',
              'Max. Attempts Reached'
            ),
            message: t(
              'l.verify_listing.view.max_attempts_reached.text',
              'Oops, you have used up your 5 attempts. Please request again.'
            )
          })
          close()
        }
      }

      if (response.ok) {
        showSuccessModal({
          title: t(
            'l.verify_listing.view.verify_code_success.title',
            'Congratulations!'
          ),
          message: (
            <Trans i18nKey="l.verify_listing.view.verify_code_success.text">
              This listing has been verified! Renters will see a verified badge{' '}
              <VerifiedBadge
                type="listing"
                verified
                height={20}
                width={20}
                bottom={-4}
              />{' '}
              on your listing.
            </Trans>
          ),
          onClose: () => history.replace(`/listing/${listingId}`)
        })
        close()
      }
    },
    [dispatch.listing, listingId, t, close, history]
  )

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Title>
          {t('l.verify_listing.view.enter_code.title', 'Enter Code')}
        </Title>
        <ModalText>
          {t(
            'l.verify_listing.view.enter_code.text',
            'Enter the 6-digit code on the postcard we sent you.'
          )}
        </ModalText>
        <CodeWrapper>
          <CodeInput codeLength={6} onSubmit={onSubmit} />
        </CodeWrapper>
        {error && (
          <ErrorText role="alert">
            {t(
              'l.verify_listing.view.verify_code_error.text',
              '** Wrong code. Please try again.'
            )}{' '}
            <Trans
              i18nKey="l.verify_listing.view.remaining_attempts.text"
              count={remaining}
            >
              <strong>{{ remaining }}</strong> attempts left.
            </Trans>
          </ErrorText>
        )}
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(VerifyCodeModal)
