import { colors } from 'styles'
import { show as showEndTenancyModal } from 'features/EndTenancy/end-tenancy-modal'
import { show as showPaymentDetailsModal } from 'features/Payments/payment-details-modal'
import { showBookingDetailsModal } from 'features/Chat/chat-room/booking-details-modal'
import AgreementCompletedIcon from '../chat-icons/AgreementCompleted.svg'
import ApplicationReceivedIcon from '../chat-icons/ApplicationReceived.svg'
import ApplicationWithdrawnIcon from '../chat-icons/ApplicationWithdrawn.svg'
import DocumentSentIcon from '../chat-icons/DocumentSent.svg'
import ContractReceivedIcon from '../chat-icons/ContractReceived.svg'
import ContractExpiredIcon from '../chat-icons/ContractExpired.svg'
import CancelledIcon from '../chat-icons/Cancelled.svg'
import ContractSignedLandlordIcon from '../chat-icons/ContractSignedLandlord.svg'
import ContractRenewalIcon from '../chat-icons/ContractRenewalReceived.svg'
import EndTenancyRequestRefusedIcon from '../chat-icons/EndTenancyRequestRefused.svg'
import EndTenancyRequestSentIcon from '../chat-icons/EndTenancyRequestSent.svg'
import EndTenancyRequestReceivedIcon from '../chat-icons/EndTenancyRequestReceived.svg'
import EndTenancyConfirmedIcon from '../chat-icons/EndTenancyConfirmed.svg'
import EndTenancyNoticeIcon from '../chat-icons/EndTenancyNotice.svg'
import SupportRequestIcon from '../chat-icons/SupportRequest.svg'
import TenantFoundIcon from '../chat-icons/TenantFound.svg'
import ApplicationNotSelectedIcon from '../chat-icons/ApplicationNotSelected.svg'
import RentPaymentReceivedIcon from '../chat-icons/RentPaymentReceived.svg'
import RentPaymentSentIcon from '../chat-icons/RentPaymentSent.svg'
import PaymentRequestReceivedIcon from '../chat-icons/PaymentRequestReceived.svg'
import ProofDocumentApproved from '../chat-icons/ProofDocumentApproved.svg'
import ProofDocumentNotApproved from '../chat-icons/ProofDocumentNotApproved.svg'

const metadata = (isThem, event, t, userRole, history) => {
  const isTenant = userRole === 'tenant'
  const displayName = event?.display_name
  const eventInfo = event?.event_info || {}
  const data = {
    chat_all_signed_contract: {
      title: t(
        'b.chat.view.contract_fully_signed.title',
        'Agreement Completed!'
      ),
      to: `/contract/${eventInfo.contract_id || event?.reference_object_id}`,
      background: colors.brightGreen,
      icon: AgreementCompletedIcon
    },
    chat_appointment_canceled: {
      title: isThem
        ? t('r.chat.view.landlord_showing_cancelled.title', {
            defaultValue: 'Showing Cancelled by {{user}}',
            user: isTenant ? t('common.landlord') : t('common.renter')
          })
        : t('r.chat.view.showing_cancelled.title', 'Showing Cancelled'),
      background: colors.mediumGrey
    },
    chat_appointment_user_subscribed: {
      title: t('b.chat.view.showing_booked.text', 'Showing Booked'),
      background: colors.pink,
      onClick: () => {
        return isTenant
          ? showBookingDetailsModal({
              listingId: event?.listing_id,
              slotData: {
                id: event?.reference_object_id,
                meeting_at: eventInfo.meeting_at,
                last_snooze_time: eventInfo.last_snooze_time,
                duration: eventInfo.duration
              },
              timelineId: event?.timeline_room
            })
          : history.push(`/listing/${event.listing_id}/showings`)
      }
    },

    chat_appointment_delayed: {
      title: t('b.chat.view.showing_delayed.text', 'Showing Delayed'),
      background: colors.pink
    },
    chat_appointment_user_unsubscribed: {
      title: isThem
        ? t('r.chat.view.landlord_showing_cancelled.title', {
            defaultValue: 'Showing Cancelled by {{user}}',
            user: isTenant ? 'Landlord' : 'Renter'
          })
        : t('r.chat.view.showing_cancelled.title', 'Showing Cancelled'),
      background: colors.mediumGrey
    },
    chat_cancel_application: {
      title: t(
        'b.chat.view.application_withdrawn.title',
        'Application Withdrawn'
      ),
      background: colors.regular,
      icon: ApplicationWithdrawnIcon
    },
    chat_cancel_contract: {
      title: t('b.chat.view.contract_cancelled.title', 'Contract Cancelled'),
      background: colors.mediumGrey,
      icon: CancelledIcon
    },
    chat_end_contract_ended: {
      title: t('b.chat.view.contract_expired.text', 'Contract Expired'),
      background: colors.regular,
      icon: ContractExpiredIcon
    },
    chat_end_contract_moving_out: {
      title: t('b.chat.view.end_tenancy_notice.title', 'End Tenancy Notice'),
      background: colors.brightGreen,
      icon: EndTenancyNoticeIcon
    },
    chat_end_contract_renew_refused: {
      title: t(
        'b.chat.view.contract_renewal_refused.text',
        'Contract Renewal Request Refused'
      ),
      background: colors.regular,
      icon: EndTenancyRequestRefusedIcon
    },
    chat_end_contract_renew_requested: {
      title: isThem
        ? t(
            'b.chat.view.renewal_request_received.title',
            'Contract Renewal Request Received'
          )
        : t(
            'b.chat.view.renewal_request_sent.title',
            'Contract Renewal Request Sent'
          ),
      background: colors.pink,
      icon: ContractRenewalIcon
    },
    chat_end_tenancy_request_done: {
      title: t('b.chat.view.end_tenancy_done.title', 'End Tenancy Done'),
      background: colors.brightGreen,
      icon: EndTenancyConfirmedIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },
    chat_end_tenancy_request_cancelled: {
      title: t(
        'b.chat.view.end_tenancy_cancelled.title',
        'End Tenancy Request Cancelled'
      ),
      background: colors.mediumGrey,
      icon: CancelledIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },
    chat_end_tenancy_request_confirmed: {
      title: t(
        'b.chat.view.end_tenancy_confirmed.title',
        'End Tenancy Request Confirmed'
      ),
      background: colors.brightGreen,
      icon: EndTenancyConfirmedIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },
    chat_end_tenancy_request_created: {
      title: isThem
        ? t(
            'b.chat.view.end_tenancy_received.title',
            'End Tenancy Request Received'
          )
        : t('b.chat.view.end_tenancy_sent.title', 'End Tenancy Request Sent'),
      background: colors.brightGreen,
      icon: isThem ? EndTenancyRequestReceivedIcon : EndTenancyRequestSentIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },

    chat_end_tenancy_request_refused: {
      title: t(
        'b.chat.view.end_tenancy_refused .title',
        'End Tenancy Request Refused'
      ),
      background: colors.regular,
      icon: EndTenancyRequestRefusedIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },
    chat_end_tenancy_support_requested: {
      title: isThem
        ? t('b.chat.view.tenant_support_requested.title', {
            defaultValue: 'Support Requested by {{user}}',
            user: isTenant ? 'Landlord' : 'Tenant'
          })
        : t(
            'b.chat.view.tenancy_support_requested.title',
            'End Tenancy Support Requested'
          ),
      background: colors.mediumGrey,
      icon: SupportRequestIcon,
      onClick: e =>
        showEndTenancyModal({
          listingId: e?.listing_id,
          endTenancyId: e?.event_info?.id
        })
    },
    chat_listing_has_been_rented: {
      title: isThem
        ? t(
            'b.chat.view.application_not_selected.title',
            'Application Not Selected!'
          )
        : t('b.chat.view.tenant_found.title', 'Tenant Found'),
      background: isThem ? colors.regular : colors.brightGreen,
      icon: isThem ? ApplicationNotSelectedIcon : TenantFoundIcon
    },
    chat_new_contract: {
      title: isThem
        ? t('l.chat.view.contract_received.title', 'Contract Received')
        : t('r.chat.view.contract_sent.title', 'Contract Sent'),
      to: `/contract/${eventInfo.contract_id || event?.reference_object_id}`,
      background: colors.pink,
      icon: isThem ? ContractReceivedIcon : DocumentSentIcon
    },
    chat_new_application: {
      title: isThem
        ? t('l.chat.view.application_received.title', 'Application Received')
        : t('r.chat.view.application_sent.title', 'Application Sent'),
      to: `/application/${event?.reference_object_id}`,
      background: isThem ? colors.pink : colors.brightGreen,
      icon: isThem ? ApplicationReceivedIcon : DocumentSentIcon
    },
    chat_payment_request_approved: {
      title: isThem
        ? t('l.chat.view.rent_received.title', 'Payment Received')
        : t('r.chat.view.rent_sent.text', 'Payment Sent'),
      background: colors.brightGreen,
      icon: isThem ? RentPaymentReceivedIcon : RentPaymentSentIcon,
      onClick: () => {
        return isTenant
          ? history.push(
              `/rental/${event.listing_id}/payments/${event.reference_object_id}`
            )
          : history.push(
              `/tenant/${event.listing_id}/payments/${event.reference_object_id}`
            )
      }
    },
    chat_payment_request_cancelled: {
      title: t(
        'b.chat.view.payment_request_cancelled.title',
        'Payment Request Cancelled'
      ),
      background: colors.mediumGrey,
      icon: CancelledIcon
    },
    chat_payment_request_created: {
      title: isThem
        ? t(
            'b.chat.view.payment_request_received.title',
            'Payment Request Received'
          )
        : t('b.chat.view.payment_request_sent.title', 'Payment Request Sent'),
      background: colors.brightGreen,
      icon: isThem ? PaymentRequestReceivedIcon : RentPaymentSentIcon,
      onClick: e =>
        showPaymentDetailsModal({
          listingId: e?.listing_id,
          paymentId: e?.reference_object_id,
          history
        })
    },
    chat_user_signed_contract: {
      title: isThem
        ? t('l.chat.view.contract_signed_by.text', {
            defaultValue: 'Contract Signed by {{displayName}}',
            displayName
          })
        : t('b.chat.view.contract_signed.text', 'Contract Signed'),
      to: `/contract/${eventInfo.contract_id || event?.reference_object_id}`,
      background: colors.brightGreen,
      icon: ContractSignedLandlordIcon
    },
    chat_contract_extension_started: {
      title: t(
        'b.chat.view.contract_extension_started.title',
        'New lease has taken effect'
      ),
      background: colors.secondary
    },
    listing_document_approval_accepted: {
      title: t(
        'b.chat.view.proof_document_approved.title',
        'Proof Document Approved'
      ),
      to: `/listing/${eventInfo.listing_id}`,
      link: t('b.chat.view.view_listing.link', 'View listing'),
      background: colors.brightGreen,
      icon: ProofDocumentApproved
    },
    listing_document_approval_declined: {
      title: t(
        'b.chat.view.proof_document_not_approved.title',
        'Proof Document Not Approved'
      ),
      to: `/listing/verify/${eventInfo.listing_id}`,
      background: colors.pink,
      icon: ProofDocumentNotApproved
    }
  }
  return data[event?.event_type]
}
export default metadata
