import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'styles'

const Container = styled.div`
  background: ${colors.white};
  display: flex;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
`

class TabList extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  render() {
    const { children, ...rest } = this.props
    return <Container {...rest}>{children}</Container>
  }
}

export default styled(TabList)``
