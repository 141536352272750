import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import toNumber from 'lodash/toNumber'
import { colors, fontWeights } from 'styles'
import { getPaymentFrequency, formatListingPrice } from 'utils/listings'
import { getImageUrl } from 'utils/image'

const Wrapper = styled.div`
  display: flex;
`

const ListingImage = styled.img`
  height: 73px;
  width: 98px;
  min-width: 98px;
  object-fit: cover;
  margin-right: 10px;
`

const ListingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ListingAddress = styled.p`
  color: ${colors.regular};
  font-size: 15px;
  font-weight: ${fontWeights.medium};
  line-height: 20px;
  margin: 0;
`

const Info = styled.span`
  color: ${colors.regular};
  font-size: 12px;
  line-height: 14px;
  display: inline-block;

  :not(:last-child) {
    margin-right: 5px;
    padding-right: 5px;
    border-right: 1px solid ${colors.darkGrey};
  }
`

const ListingInfoSmall = ({ listing }) => {
  const { t } = useTranslation()

  const listingUnit = listing.unit_number ? `${listing.unit_number} - ` : ''
  const listingAddress = `${listingUnit}${listing.full_street_name}, ${listing.city}, ${listing.state}`
  const isGroupListing = listing.gr_unit === '1'

  const bedrooms = () => {
    if (listing.unit_type_scope_txt_id === 'single')
      return t('b.listing.view.private_room.text', 'Private room')

    const count = toNumber(listing.bedrooms)
    if (count === 0) return t('common.studio')
    return `${count} ${t('common.bed', 'Bed')}`
  }

  const bathrooms = () =>
    `${toNumber(listing.bathrooms)} ${t('common.bath', 'Bath')}`

  const renderPrice = () => {
    const { gr_min_price, price } = listing
    return isGroupListing ? (
      <>
        {t('b.listings.view.starting_at.text', 'Starting at')}{' '}
        {formatListingPrice(gr_min_price)}
      </>
    ) : (
      formatListingPrice(price)
    )
  }

  return (
    <Wrapper>
      <ListingImage
        src={getImageUrl(listing.cover_photo, { width: 196, height: 146 })}
        alt=""
      />
      <ListingInfo>
        <ListingAddress>{listingAddress}</ListingAddress>
        <div>
          <Info>
            {renderPrice()} /{' '}
            {getPaymentFrequency(listing.price_frequency)?.toLowerCase()}
          </Info>
          {!isGroupListing && (
            <>
              <Info>{bedrooms()}</Info>
              {listing.unit_type_scope_txt_id === 'entire' && (
                <Info>{bathrooms()}</Info>
              )}
            </>
          )}
        </div>
      </ListingInfo>
    </Wrapper>
  )
}

export default React.memo(ListingInfoSmall)
