import React, { Fragment, forwardRef, useState } from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights, transitions } from 'styles'
import { noop } from 'lodash'
import toId from 'utils/to-id'
import Label, { active } from './floating-label'
import CheckedIcon from './checked.svg'

const Control = styled.input`
  filter: none;
  height: 50px;
  border-radius: ${props => (props.round ? '50%' : '6px')};
  border: 1px solid ${colors.mediumGrey};
  padding: 0 15px;
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  color: ${colors.regular};
  width: 100%;
  max-width: 100%;
  outline: none;
  transition: border-color ${transitions.ease};

  &::placeholder {
    color: #ccc;
    font-weight: ${fontWeights.regular};
  }

  &[type="tel"] {
    border: none;
    background: none;
  }

  &:not([type]),
  &[type=""],
  &[type="text"],
  &[type="password"] {
    border: none;
    border-bottom: 1px solid ${colors.mediumGrey};
    border-radius: 0;
    background: none;
    &:focus {
      border-color: ${colors.darkBlue};
    }
    ${props =>
      props.hasError &&
      `
      border-color: ${colors.red} !important;
    `}
  }

  &:not([type]),
  &[type=""],
  &[type="tel"],
  &[type="password"] {
    &:disabled {
      color: ${colors.mediumGrey};
      border-color: ${colors.mediumGrey};
      background-color: ${colors.white};
      opacity: 0.85;
      cursor: not-allowed;
    }
  }

  &:not([type]),
  &[type=""],
  &[type="text"],
  &[type="code"],
  &[type="tel"],
  &[type="password"] {
    padding: 0 10px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      transition: background-color 5000s ease-in-out 0s;
    }
    &::placeholder {
      opacity: 0;
    }
  }

  &[type="password"] {
    padding-left: 9.5px;
    @-moz-document url-prefix() {
      padding-left: 10px;
    }
  }

  &[type="checkbox"],
  &[type="radio"],
  &[type="submit"] {
    cursor: pointer;
  }

  &[type="radio"],
  &[type="checkbox"] {
    height: 26px;
    width: 26px;
    min-width: 26px;
    padding: 0;
    appearance: none;
    border-color: ${colors.darkGrey};
    border-width: 1px;
    border-radius: 4px;
    background-color: ${colors.white};
  }

  &[type="radio"] {
    border-radius: 13px;
  }

  &[type="radio"]:disabled,
  &[type="checkbox"]:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &[type="radio"]:checked,
  &[type="checkbox"]:checked {
    background-color: ${colors.secondary};
    border-color: ${colors.secondary};
    background-image: url('${CheckedIcon}');
    background-size: 15px 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &:disabled {
    opacity: 0.3;
  }
`

const Container = styled.div`
  position: relative;
  input:not(:placeholder-shown) + label {
    ${active}
  }
  &:-webkit-autofill + label,
  &:-webkit-autofill:hover + label,
  &:-webkit-autofill:focus + label {
    ${active}
  }
`

const Input = forwardRef(
  (
    {
      onFocus = noop,
      onBlur = noop,
      prefix,
      suffix,
      value,
      hasError,
      placeholder,
      id: idInitial,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false)

    const handleFocus = e => {
      setFocused(true)
      onFocus(e)
    }
    const handleBlur = e => {
      setFocused(false)
      onBlur(e)
    }
    const getPrefix = () => (prefix ? prefix : '')
    const getSuffix = () => (suffix ? ` ${suffix}` : '')
    const getValue = () =>
      value && !focused ? `${getPrefix()}${value}${getSuffix()}` : value
    const Outer = placeholder ? Container : Fragment
    const id = idInitial || toId(placeholder)
    return (
      <Outer>
        <Control
          {...props}
          id={id}
          hasError={hasError}
          placeholder={placeholder}
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={getValue()}
        />
        {placeholder && <Label htmlFor={id}>{placeholder}</Label>}
      </Outer>
    )
  }
)

Input.displayName = 'Input'

export default Input
