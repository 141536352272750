import ENV_VARS from 'config'
import { get, post, put, del } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

// CONTRACT
export const getContractTerms = get(
  `${BACKEND_SERVER}/contracts/listings/%listingId%/terms_template`
)
export const sendSignature = post(
  `${BACKEND_SERVER}/contracts/%contractId%/sign`
)
export const sendRoomateSignature = post(
  `${BACKEND_SERVER}/contracts/%contractId%/sign/roommate/%roommateId%`
)
export const exportContract = get(
  `${BACKEND_SERVER}/contracts/%contractId%/pdfData`
)
export const createContract = post(
  `${BACKEND_SERVER}/contracts/listings/%listingId%`
)
export const cancelContract = del(`${BACKEND_SERVER}/contracts/%contractId%`)

export const sendContract = post(
  `${BACKEND_SERVER}/contracts/%contractId%/send`
)
export const getTenants = get(`${BACKEND_SERVER}/contracts`)
export const getGroupedTenants = get(
  `${BACKEND_SERVER}/contracts/grouped_by_building`
)
export const getContracts = get(`${BACKEND_SERVER}/web/contracts`)
export const getContract = get(`${BACKEND_SERVER}/web/contracts/%contractId%`)
export const updateContract = put(`${BACKEND_SERVER}/contracts/%contractId%`)
export const renewContract = post(
  `${BACKEND_SERVER}/contracts/%contractId%/extensions`
)

export const createAddendum = post(
  `${BACKEND_SERVER}/contracts/%contractId%/addendums`
)
export const deleteAddendum = del(
  `${BACKEND_SERVER}/contracts/%contractId%/addendums/%addendumId%`
)

export const loadAddendumTemplates = get(
  `${BACKEND_SERVER}/users/bootstrap/acts`
)
