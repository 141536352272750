import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { colors, fontWeights, breakpoints } from 'styles'
import ListingTableHeader from 'components/listings-table-header'
import { FilterBar, FilterButton } from 'components/search-filter'

const CreateButton = styled(Link)`
  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background: ${colors.white};
  padding: 10px 20px;
  color: ${colors.secondary};
  font-size: 13px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.08px;
  line-height: 18px;
  text-align: center;
  margin-right: 30px;

  :hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin-right: 20px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const MyListingsHeader = ({
  tabCount,
  currentTab,
  setCurrentTab,
  onSearch
}) => {
  const { t } = useTranslation()

  const CreateListingButton = () => (
    <CreateButton to="/listing/create">
      {t('l.listing.list.create_new_listing.button', 'Create new listing')}
    </CreateButton>
  )

  return (
    <ListingTableHeader
      title={t('l.listing.list.listings.title', 'Listings')}
      onSearch={onSearch}
      buttonComponent={CreateListingButton}
    >
      <FilterBar>
        <FilterButton
          active={currentTab === 'draft'}
          onClick={() => setCurrentTab('draft')}
        >
          {t('l.listing.list.draft.button', 'Draft')} {`(${tabCount.draft})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'published'}
          onClick={() => setCurrentTab('published')}
        >
          {t('l.listing.list.published.button', 'Published')}{' '}
          {`(${tabCount.published})`}
        </FilterButton>
        <FilterButton
          active={currentTab === 'hidden'}
          onClick={() => setCurrentTab('hidden')}
        >
          {t('l.listing.list.hidden.button', 'Hidden')} {`(${tabCount.hidden})`}
        </FilterButton>
      </FilterBar>
    </ListingTableHeader>
  )
}

export default MyListingsHeader
