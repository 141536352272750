import { useSelector } from 'react-redux'
import { pipe, path, length, defaultTo } from 'ramda'

const MAP = {
  rented_count: 'unread_rented_rooms',
  inquiry_count: 'unread_inquiry_rooms'
}
const useChatCount = (type = '') =>
  useSelector(
    pipe(
      path(['chat', MAP[type] || 'unread_timeline_rooms']),
      defaultTo([]),
      length
    )
  )

export default useChatCount
