import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import AddToCalendar from 'react-add-to-calendar'
import { fontSizes, fontWeights, colors } from 'styles'
import { createModal, useModalize } from 'components/modalize'
import Icon from 'components/icon'
import { Group, Label } from 'components/form'
import { Button } from 'components/button'
import useRole from 'hooks/use-role'
import moment from 'moment'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  margin: auto;
  padding: 0 18x;
`

const Header = styled.div`
  background-color: ${colors.white};
  height: 120px;
  position: sticky;
  top: 0;
`

const ModalClose = styled.div`
  text-align: right;
  margin: 25px 25px 0 0;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 0 20px;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px 20px 22px;
`

const AddToCalendarWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 20px 0px 20px;
  position: relative;
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  width: 250px;
  margin: auto;
  overflow: hidden;
  .react-add-to-calendar__button {
    cursor: pointer;
    width: 250px;
    display: block;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.grey};
    :hover {
      opacity: 0.7;
    }
    > span {
      margin-right: 10px;
    }
  }
  .react-add-to-calendar {
    z-index: 2;
  }
  .react-add-to-calendar__dropdown {
    margin-top: -10px;
    a {
      color: black;
    }
    ul {
      list-style: none;
      margin-bottom: 0px;
      padding: 0px;
    }
    li {
      transition: all 0.2s ease-in-out;
      color: ${colors.mediumGrey};
      height: 42px;
      display: flex;

      a {
        padding: 10px 15px;
        flex: 1;
      }
      :hover {
        a {
          color: ${colors.white};
        }
        background-color: ${colors.secondary};
      }
    }
  }
  > svg path {
    fill: ${colors.darkGrey};
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 18px;
  width: 20px;
  height: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px 0 30px;
`

const AddToCalendarModal = ({
  title = 'Add To Calendar',
  values,
  listingId
}) => {
  const { t } = useTranslation()
  const { close } = useModalize()
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const role = useRole()
  useEffect(() => {
    const loadData = async () => {
      const { body } = await dispatch.listing.getListingSummary(listingId)
      setData(body)
    }
    loadData()
  }, [dispatch.listing, listingId])

  const unitHidden =
    (!!Number(data?.hide_unit_number) || !data?.unit_number) &&
    role === 'tenant'
  const isApartment = data?.building_type !== 'house'
  const location = `${!unitHidden ? data?.unit_number + ' - ' : ''}${
    !unitHidden || isApartment ? data?.full_street_name : data?.street_name
  }, ${data?.city}, ${data?.state}`

  const event = {
    title: 'Showing Appointment',
    description: '',
    location: location,
    startTime: moment(values.meeting_at),
    endTime: moment(values.meeting_at).add(values.duration, 'minutes')
  }

  const items = [
    { outlook: 'Outlook' },
    { outlookcom: 'Outlook.com' },
    { apple: 'Apple Calendar' },
    { yahoo: 'Yahoo' },
    { google: 'Google' }
  ]

  return (
    <Wrapper>
      <Header>
        <ModalClose>
          <Icon
            aria-label="Close"
            id="close"
            width={24}
            height={24}
            onClick={close}
          />
        </ModalClose>
        <Title>{title}</Title>
      </Header>
      <LabelWrapper>
        <Label>
          {t(
            'l.listing.showings.add_to_calendar.label',
            'Add this appointment to your preferred calendar.'
          )}
        </Label>
      </LabelWrapper>
      <Group bottom={20}>
        <AddToCalendarWrapper>
          <AddToCalendar
            listItems={items}
            event={event}
            buttonLabel="Select Your Calendar"
          />
          <StyledIcon id="down_arrow_white" />
        </AddToCalendarWrapper>
      </Group>
      <ButtonWrapper>
        <Button theme="primary" type="submit" width={150} onClick={close}>
          {t('b.listing.showings.calendar.done.button', 'Done')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export const showAddToCalendarModal = createModal(AddToCalendarModal)
