import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { colors, breakpoints, structure } from 'styles'
import { useTranslation } from 'react-i18next'
import { pick } from 'ramda'
import { noop, toNumber } from 'lodash'
import { groupedListings } from 'models/listing/selectors'
import useMediaQuery from 'hooks/use-media-query'
import useProfile from 'hooks/use-profile'
import { toUnit } from 'utils/styles'
import formatDate from 'utils/format-date'
import {
  prefixUnitNumber,
  parseAreaSize,
  renderListingPrice
} from 'utils/listings'
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  StyledTableCell,
  SingleTableRow,
  Accordion,
  AccordionContent,
  AccordionTableRow,
  AccordionHeaderText,
  AccordionHeader,
  TableHeaderText,
  MobileRowWrapper,
  MobileUnitText,
  MobileUnitDetails,
  CellText
} from 'components/listings-table'
import { EmptyListComponent } from 'components/table-list'
import PageTitle from 'components/page-title'
import MobileFooterButton from 'components/mobile-footer-button'
import useMyListings from './hooks/use-my-listings'
import MyListingsHeader from './my-listings-header'

import backgroundBottom from './images/background-bottom.png'
import backgroundBottomMobile from './images/background-bottom-mobile.png'

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 0 0 220px;
  flex: 1;

  background-image: url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 566px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
    padding-bottom: 125px;
    margin-bottom: 56px;

    background-image: url(${backgroundBottomMobile});
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 375px;
  }
`

const TableWrapper = styled.div`
  max-width: 1130px;
  margin: 0 100px 0;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin: 0 30px 0;
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 0;
  }
`

const Badge = styled.div`
  background: ${colors.mediumGrey};
  border-radius: 2px;
  padding: 6px 20px;
  width: fit-content;
  color: ${colors.white};
  font-size: 14px;
  line-height: 19px;
  margin: 0 auto;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 2px 10px;
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 0 15px;
  }
`

const routes = {
  draft: '/listing/create/'
}
const getRoutes = listing => {
  if (listing.state_machine === 'history') {
    return noop()
  } else {
    return `${routes[listing.state_machine] || '/listing/'}${
      listing.listing_id
    }`
  }
}

const draftStates = ['draft']
const publishedState = ['published', 'pending']
const ignoredListingStates = ['history', 'upcoming', 'rented']

const MyListings = props => {
  const { t } = useTranslation()
  const session = useProfile()
  const [data, setData] = useState([])
  const [currentTab, setCurrentTab] = useState('published')
  const [tabCount, setTabCount] = useState({
    draft: 0,
    published: 0,
    hidden: 0
  })
  const [filteredData, setFilteredData] = useState([])
  const [tabFilteredData, setTabFilteredData] = useState([])
  const [expanded, setExpanded] = useState(true)

  const displayTablet = useMediaQuery(
    `(max-width: ${breakpoints.tabletLandscape})`
  )
  const showSizeColumn = useMediaQuery(`(min-width: 1000px)`)

  // Fetch data from BE
  const listings = useMyListings()
  useEffect(() => {
    if (!listings) return
    setData(listings)
    setFilteredData(groupedListings(listings))
  }, [listings])

  // Calculate count for each tab
  useEffect(() => {
    const calculateTabCounts = filtered => {
      let draft = 0
      let published = 0
      let hidden = 0
      filtered.forEach(building => {
        building.listings.forEach(listing => {
          if (
            listing.is_hidden === '1' &&
            !ignoredListingStates.includes(listing.state_machine)
          ) {
            hidden++
          } else if (draftStates.includes(listing.state_machine)) {
            draft++
          } else if (publishedState.includes(listing.state_machine)) {
            published++
          }
        })
      })
      setTabCount({ draft, published, hidden })
    }
    calculateTabCounts(filteredData)
  }, [filteredData])

  // Handle tab changes
  const handleSetTabDraft = useCallback(() => {
    const filtered = filteredData
      .map(building => ({
        ...building,
        listings: building.listings.filter(
          listing =>
            draftStates.includes(listing.state_machine) &&
            listing.is_hidden !== '1'
        )
      }))
      .filter(building => building.listings && building.listings.length > 0)
    setTabFilteredData(filtered)
  }, [filteredData])
  const handleSetTabPublished = useCallback(() => {
    const filtered = filteredData
      .map(building => ({
        ...building,
        listings: building.listings.filter(
          listing =>
            publishedState.includes(listing.state_machine) &&
            listing.is_hidden !== '1'
        )
      }))
      .filter(building => building.listings && building.listings.length > 0)
    setTabFilteredData(filtered)
  }, [filteredData])
  const handleSetTabHidden = useCallback(() => {
    const filtered = filteredData
      .map(building => ({
        ...building,
        listings: building.listings.filter(
          listing =>
            listing.is_hidden === '1' &&
            !ignoredListingStates.includes(listing.state_machine)
        )
      }))
      .filter(building => building.listings && building.listings.length > 0)
    setTabFilteredData(filtered)
  }, [filteredData])
  useEffect(() => {
    if (currentTab === 'draft') {
      handleSetTabDraft()
    } else if (currentTab === 'published') {
      handleSetTabPublished()
    } else if (currentTab === 'hidden') {
      handleSetTabHidden()
    }
  }, [currentTab, handleSetTabDraft, handleSetTabHidden, handleSetTabPublished])

  // Handle search
  const searchListings = searchString => {
    const filtered = data
      .map(building => ({
        ...building,
        listings: building.listings.filter(listing => {
          const searchFields = pick(
            ['unit_number', 'address', 'building_name', 'city', 'state'],
            listing
          )
          return JSON.stringify(searchFields)
            .toLowerCase()
            .includes(searchString.trim().toLowerCase())
        })
      }))
      .filter(building => building.listings && building.listings.length > 0)
    setFilteredData(filtered)
  }

  const getBuildingTitle = (building, count) => {
    const { building_name, address, city, state } = building
    return (
      <AccordionHeaderText>
        <span>
          {building_name || address} ({count})
        </span>
        {`${address}, ${city}${state === null ? '' : `, ${state}`}`}
      </AccordionHeaderText>
    )
  }

  const getListingAddress = listing => {
    const { unit_number, address, city, state } = listing
    const unitNumber = unit_number ? `${prefixUnitNumber(unit_number)} - ` : ''
    const fullAddress = `${address}, ${city}, ${state}`
    return (
      <strong>
        {unitNumber}
        {fullAddress}
      </strong>
    )
  }

  const getUnitNumber = listing => {
    const { unit_number, unit_type_txt_id } = listing
    if (unit_number) return prefixUnitNumber(unit_number)
    if (unit_type_txt_id === 'house') return t('common.house', 'House')
    if (unit_type_txt_id === 'townhouse')
      return t('common.townhouse', 'Townhouse')
    return '-'
  }

  const getBedrooms = listing => {
    const { bedrooms } = listing
    if (bedrooms === '0') return t('common.studio', 'Studio')
    return bedrooms
      ? `${bedrooms} ${t('common.bed', 'Bed')}`
      : t('b.components.view.not_avaiable.text', 'N/A')
  }

  const getSize = listing => {
    const unit = session.setting_size_unit
    const unitText =
      unit === 'meter'
        ? t('b.listing.view.sqm.unit', 'm²')
        : t('b.listing.view.sqft.unit', 'ft²')

    const na = t('b.components.view.not_avaiable.text', 'N/A')
    const { gr_min_size, gr_max_size, size, gr_unit } = listing
    return gr_unit === '1'
      ? `${parseAreaSize(gr_min_size, unit) || na} - ${
          parseAreaSize(gr_max_size, unit) || na
        } ${unitText}`
      : `${size ? parseAreaSize(size, unit) + ` ${unitText}` : na}`
  }

  const MobileListingRow = ({
    listing,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <MobileRowWrapper indent={groupedListing}>
          <MobileUnitText>
            {singleListing
              ? getListingAddress(listing)
              : getUnitNumber(listing)}
          </MobileUnitText>
          <MobileUnitDetails>
            <p>{renderListingPrice(listing)}</p>
            <p>{getBedrooms(listing)}</p>
            <p>
              {t('l.listing.list.count_applied.title', {
                defaultValue: '{{ applicationCount }} Applied',
                applicationCount: toNumber(listing.applications_count)
              })}
            </p>
            {listing.state_machine === 'pending' && (
              <Badge>{t('l.listing.view.pending.text', 'Pending')}</Badge>
            )}
          </MobileUnitDetails>
        </MobileRowWrapper>
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.listing.list.view.text', 'View')}</CellText>
      </StyledTableCell>
    </>
  )

  const ListingRow = ({
    listing,
    displayGaps,
    groupedListing,
    singleListing
  }) => (
    <>
      <StyledTableCell gapLeft={displayGaps}>
        <CellText firstCell indent={groupedListing}>
          {singleListing ? getListingAddress(listing) : getUnitNumber(listing)}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>{getBedrooms(listing)}</CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>{renderListingPrice(listing)}</strong>
        </CellText>
      </StyledTableCell>
      {showSizeColumn && (
        <StyledTableCell>
          <CellText>{getSize(listing)}</CellText>
        </StyledTableCell>
      )}
      <StyledTableCell>
        <CellText>
          {formatDate(listing.availability, 'MMM D, YYYY') ||
            t('b.components.view.not_avaiable.text', 'N/A')}
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        <CellText>
          <strong>{listing.applications_count}</strong>
        </CellText>
      </StyledTableCell>
      <StyledTableCell>
        {listing.state_machine === 'pending' && (
          <Badge>{t('l.listing.view.pending.text', 'Pending')}</Badge>
        )}
      </StyledTableCell>
      <StyledTableCell gapRight={displayGaps}>
        <CellText lastCell>{t('l.listing.list.view.text', 'View')}</CellText>
      </StyledTableCell>
    </>
  )

  return (
    <Wrapper>
      <Helmet>
        <title>{t('l.listing.list.listings.title', 'Listings')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>{t('l.listing.list.listings.title', 'Listings')}</PageTitle>
      <MyListingsHeader
        tabCount={tabCount}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        onSearch={searchListings}
      />
      <TableWrapper>
        <Table>
          {!displayTablet && (
            <TableHeader>
              <TableRow sticky top={structure.header.height + 157}>
                <TableHeaderCell>
                  <TableHeaderText firstCell>
                    {t('common.unit', 'Unit')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('common.rooms', 'Rooms')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('common.price', 'Price')}
                  </TableHeaderText>
                </TableHeaderCell>
                {showSizeColumn && (
                  <TableHeaderCell>
                    <TableHeaderText>
                      {t('l.listing.list.size.text', 'Size')}
                    </TableHeaderText>
                  </TableHeaderCell>
                )}
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.listing.list.available_date.text', 'Available Date')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>
                    {t('l.listing.list.applications.text', 'Applications')}
                  </TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText>&nbsp;</TableHeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <TableHeaderText
                    lastCell
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded
                      ? t('l.listing.list.collapse_all.text', 'Collapse All')
                      : t('l.listing.list.expand_all.text', 'Expand All')}
                  </TableHeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
          )}
          <TableBody>
            {tabFilteredData.length > 0 ? (
              tabFilteredData.map(building => {
                const listingCount = building.listings.length

                if (listingCount === 1) {
                  const listing = building.listings[0]
                  return (
                    <SingleTableRow
                      key={listing.listing_id}
                      onClick={() => props.history.push(getRoutes(listing))}
                    >
                      {displayTablet ? (
                        <MobileListingRow listing={listing} singleListing />
                      ) : (
                        <ListingRow listing={listing} singleListing />
                      )}
                    </SingleTableRow>
                  )
                }

                return (
                  <Accordion key={building.building_id} expanded={expanded}>
                    <AccordionHeader colSpan={8}>
                      {getBuildingTitle(building, listingCount)}
                    </AccordionHeader>
                    <AccordionContent>
                      {building.listings.map((listing, index) => {
                        const isLastRow = index + 1 === listingCount

                        return (
                          <AccordionTableRow
                            key={listing.listing_id}
                            onClick={() =>
                              props.history.push(getRoutes(listing))
                            }
                          >
                            {displayTablet ? (
                              <MobileListingRow
                                listing={listing}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            ) : (
                              <ListingRow
                                listing={listing}
                                displayGaps={!isLastRow}
                                groupedListing
                              />
                            )}
                          </AccordionTableRow>
                        )
                      })}
                    </AccordionContent>
                  </Accordion>
                )
              })
            ) : (
              <TableRow>
                <StyledTableCell colSpan={8}>
                  <EmptyListComponent />
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <MobileFooterButton
        to="/listing/create"
        breakpoint={breakpoints.ipadMiniMax}
      >
        {t('l.listing.list.create_new_listing.button', 'Create new listing')}
      </MobileFooterButton>
    </Wrapper>
  )
}

export default React.memo(MyListings)
