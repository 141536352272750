import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import usePrevious from 'hooks/use-previous'

const useFocusOnError = ({ fieldRef, name }) => {
  const formik = useFormikContext()
  const prevSubmitCount = usePrevious(formik.submitCount) || 0
  const firstErrorKey = Object.keys(formik.errors)[0]

  useEffect(() => {
    if (prevSubmitCount !== formik.submitCount) {
      if (fieldRef.current && firstErrorKey === name) {
        fieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [
    formik.submitCount,
    formik.isValid,
    firstErrorKey,
    fieldRef,
    name,
    prevSubmitCount
  ])
}
export default useFocusOnError
