import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { includes } from 'ramda'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import toBoolean from 'utils/to-boolean'
import useMediaQuery from 'hooks/use-media-query'
import { showSnoozeModal } from 'pages/Listing/ListingShowings/snooze-modal'
import { Input } from 'components/form'
import { TableRow, TableCell } from 'components/table'
import { Button } from 'components/button'
import {
  Accordion,
  AccordionHeader,
  AccordionContent
} from 'components/accordion'
import Icon from 'components/icon'
import { showAttendeesModal } from './attendees-modal'

const ActiveTableRow = styled(TableRow)`
  background-color: ${colors.mediumGrey};
  border-bottom: 1px solid white;
  ${props =>
    props.active &&
    `
    & ${ArrowIcon} {
      transform: rotate(0deg);
      transition: all 0.3s ease;
    }
  `}

  &:hover {
    cursor: pointer;
  }
`

const ArrowIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  transform: rotate(-90deg);
  transition: all 0.3s ease;
`

const AccordionHeaderContent = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const RowTitle = styled.p`
  color: ${colors.white};
  text-align: left;
  margin: 10px 50px;
  font-size: 14px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 10px 40px;
    min-width: 40px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 10px 50px 10px 20px;
  }
`

const ShowingRow = styled(TableRow)`
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
`

const ShowingCell = styled(TableCell)`
  padding: 10px 0 20px 0;
  max-width: 220px;
  display: table-cell;
  vertical-align: middle;
  text-overflow: ellipsis;
  align-items: center;
`

const CheckBox = styled(Input)``

const SelectCell = styled(ShowingCell)`
  display: table-cell;
  text-align: center;
  max-width: 20px;
  width: 20px;
  padding: 10px;
`

const CellText = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  margin-left: 25px;
  text-overflow: ellipsis;
  overflow: hidden;

  strong {
    font-weight: ${fontWeights.medium};
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    font-size: ${fontSizes.small};
    margin: 0;
    max-width: none;
  }
`

const DateText = styled(CellText)`
  width: 150px;
`

const AttendeesText = styled(CellText)`
  margin: 0 25px;
  text-align: center;
`

const HiddenWrapper = styled.div`
  display: flex;
  padding: 0 30px 0 0;
  margin-left: 25px;
`

const HiddenText = styled(CellText)`
  margin: 0;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-left: 0;
  }
`

const SnoozeButton = styled(Button)`
  margin: 0 10px 0 0;
  width: 130px;

  :hover {
    path {
      stroke: ${colors.secondary};
    }
  }

  ${props =>
    props.disabled &&
    `
    :hover {
      path {
        stroke: ${colors.white};
      }
    }
  `}

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: 115px;
  }

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
      margin: 0 0 10px 0;
  }
`

const ButtonContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 15px;

  svg {
    margin-left: 5px;
  }
`

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
`

const MobileContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 5px 0;
`

const MobileTopCell = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`

const MobileDateText = styled.p`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.medium};
  margin: 0 0 5px 0;
`

const TimeText = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  margin: 0;
`

const MobileContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const HiddenIcon = styled(Icon)`
  margin-left: 5px;

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: -5px;
  }
`

const ShowingsContainer = ({
  listingId,
  showing,
  selectedShowings,
  onSelectChange,
  active,
  currentTab
}) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  const dateWithTime = showing[0].meeting_at

  const formatDate = moment(dateWithTime).calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: () => 'dddd - MMM DD, YYYY',
    sameElse: () => 'dddd - MMM DD, YYYY'
  })

  const checkMeetingAt = appointment => {
    let meetingTime = ''
    appointment.last_snooze_time
      ? (meetingTime = appointment.last_snooze_time)
      : (meetingTime = appointment.meeting_at)
    return moment(meetingTime)
  }

  const renderSnoozeButton = appointment => (
    <SnoozeButton
      theme="primary"
      type="submit"
      width={130}
      disabled={!appointment.user_count}
      onClick={event => {
        event.stopPropagation()
        showSnoozeModal({
          appointmentId: appointment.id,
          delay15: moment(checkMeetingAt(appointment))
            .add(15, 'minutes')
            .format('h:mm a'),
          delay30: moment(checkMeetingAt(appointment))
            .add(30, 'minutes')
            .format('h:mm a')
        })
      }}
    >
      <ButtonContentWrapper>
        {t('l.showings.view.snooze.text', 'Snooze')}
        <Icon id="snooze" alt="Snooze" height={20} width={20} />
      </ButtonContentWrapper>
    </SnoozeButton>
  )

  const getMobileAppointmentStatus = appointment => {
    if (currentTab === 'history') {
      return <strong>{t('l.showings.view.completed.text', 'Completed')}</strong>
    }
    return Number(appointment.hidden) ? (
      <>
        <strong>{t('l.showings.view.hidden.text', 'Hidden')}</strong>
        <HiddenIcon id="hidden" alt="" height={20} width={20} />
      </>
    ) : (
      <strong>{t('l.showings.view.active.text', 'Active')}</strong>
    )
  }

  const getAppointmentStatus = appointment => {
    if (currentTab === 'history') {
      return (
        <CellText>{t('l.showings.view.completed.text', 'Completed')}</CellText>
      )
    }
    return Number(appointment.hidden) ? (
      <HiddenWrapper>
        <HiddenText>{t('l.showings.view.hidden.text', 'Hidden')}</HiddenText>
        <HiddenIcon id="hidden" alt="" height={20} width={20} />
      </HiddenWrapper>
    ) : (
      <CellText>{t('l.showings.view.active.text', 'Active')}</CellText>
    )
  }

  const renderMobileShowingRow = appointment => (
    <>
      {currentTab === 'upcoming' && (
        <SelectCell>
          <CheckBox
            onClick={event => event.stopPropagation()}
            onChange={() => onSelectChange(appointment.id)}
            checked={includes(appointment.id, selectedShowings)}
            type="checkbox"
          ></CheckBox>
        </SelectCell>
      )}
      <ShowingCell>
        <MobileWrapper>
          <MobileContentTop>
            <MobileTopCell>
              <MobileDateText>
                {moment(dateWithTime).format('MMMM DD, YYYY')}
              </MobileDateText>
              <TimeText>
                {checkMeetingAt(appointment).format('h:mm a')}
              </TimeText>
            </MobileTopCell>
            {currentTab === 'upcoming' && renderSnoozeButton(appointment)}
          </MobileContentTop>
          <MobileContent>
            <CellText>
              {t('common.status', 'Status')}:{' '}
              {getMobileAppointmentStatus(appointment)}
            </CellText>
          </MobileContent>
          <MobileContent>
            <CellText>
              {t('common.duration', 'Duration')}:{' '}
              <strong>{`${appointment.duration} min.`}</strong>
            </CellText>
            <CellText>
              {t('common.attendees', 'Attendees')}:{' '}
              <strong>{appointment.user_count}</strong>
            </CellText>
          </MobileContent>
        </MobileWrapper>
      </ShowingCell>
    </>
  )

  const renderShowingRow = appointment => (
    <>
      {currentTab === 'upcoming' && (
        <SelectCell>
          <CheckBox
            onClick={event => event.stopPropagation()}
            onChange={() => onSelectChange(appointment.id)}
            checked={includes(appointment.id, selectedShowings)}
            type="checkbox"
          ></CheckBox>
        </SelectCell>
      )}
      <ShowingCell>
        <DateText>{moment(dateWithTime).format('MMMM DD, YYYY')}</DateText>
      </ShowingCell>
      <ShowingCell>
        <CellText>{checkMeetingAt(appointment).format('h:mm a')}</CellText>
      </ShowingCell>
      <ShowingCell>
        <CellText>{`${appointment.duration} min.`}</CellText>
      </ShowingCell>
      <ShowingCell>
        <AttendeesText>{appointment.user_count}</AttendeesText>
      </ShowingCell>
      <ShowingCell>{getAppointmentStatus(appointment)}</ShowingCell>
      <ShowingCell>
        {currentTab === 'upcoming' && renderSnoozeButton(appointment)}
      </ShowingCell>
    </>
  )

  return (
    <Accordion
      expanded={formatDate === 'Today' ? true : active}
      preventAutoCollapse
    >
      <AccordionHeader>
        <ActiveTableRow>
          <TableCell colSpan={7}>
            <AccordionHeaderContent>
              <RowTitle>{formatDate}</RowTitle>
              <ArrowIcon id="down_arrow_white" width={20} height={20} />
            </AccordionHeaderContent>
          </TableCell>
        </ActiveTableRow>
      </AccordionHeader>
      <AccordionContent>
        {showing.map(appointment => (
          <ShowingRow
            key={appointment.id}
            onClick={() =>
              showAttendeesModal({
                title: t(
                  'l.showings.view.attendees_modal.title.text',
                  'Showing Info'
                ),
                duration: appointment.duration,
                listingId,
                appointmentId: appointment.id,
                lastSnoozeTime: appointment.last_snooze_time,
                meetingAt: appointment.meeting_at,
                currentTab,
                hidden: toBoolean(appointment.hidden)
              })
            }
          >
            {displayMobile
              ? renderMobileShowingRow(appointment)
              : renderShowingRow(appointment)}
          </ShowingRow>
        ))}
      </AccordionContent>
    </Accordion>
  )
}
export default ShowingsContainer
