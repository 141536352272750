import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useTranslateFormErrors = (errors, touched, setFieldTouched) => {
  const { i18n } = useTranslation()
  useEffect(() => {
    const updateErrors = () => {
      Object.keys(errors).forEach(fieldName => {
        if (Object.keys(touched).includes(fieldName)) {
          setFieldTouched(fieldName)
        }
      })
    }
    i18n.on('languageChanged', updateErrors)
    return () => {
      i18n.off('languageChanged', updateErrors)
    }
  }, [errors, i18n, setFieldTouched, touched])
}

export default useTranslateFormErrors
