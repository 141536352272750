import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { NavLink, Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import ContentWrapper from 'components/content-wrapper'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Formik, Form } from 'formik'
import { colors, fontWeights, breakpoints } from 'styles'
import { toNumber } from 'lodash'
import * as Yup from 'yup'
import {
  pickAll,
  evolve,
  mapObjIndexed,
  pipe,
  over,
  lensProp,
  values as ramdaValues,
  any,
  equals,
  not,
  map
} from 'ramda'
import throttle from 'lodash/throttle'
import { defaultIfFalsy, mergeSpec } from 'utils'
import isTruthy from 'utils/is-truthy'
import useMediaQuery from 'hooks/use-media-query'
import ListingInfo from 'features/Listing/listing-info'
import ContractParties from 'pages/Contract/ContractCreate/ContractParties'
import ContractTerms from 'pages/Contract/ContractCreate/ContractTerms'
import ContractAddendums from 'pages/Contract/ContractCreate/ContractAddendums'
import PageTitle from 'components/page-title'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import Page from 'components/page'
import TabResolver from './tab-resolver'

const ListingInfoContainer = styled(ContentWrapper)`
  padding: 30px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 10px;
  }
`

const TabsContainer = styled(ContentWrapper)`
  display: flex;
  flex: 1;
`

const ContractTab = styled(NavLink)`
  height: 50px;
  color: ${colors.regular};
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom-color: ${colors.secondary};
  }
`

const ContractHeader = styled.div`
  background: ${colors.white};
`

const Separator = styled.hr`
  border: 1px solid ${colors.grey};
`

const Content = styled.div`
  display: flex;
  flex: 1;
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const Notification = styled.span`
  background-color: #ff0000;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  display: inline-block;
  margin-right: 5px;
`

const getInitialValue = pipe(
  mapObjIndexed(defaultIfFalsy('')),
  evolve({
    contract_features: defaultIfFalsy([]),
    contract_custom_features: defaultIfFalsy([]),
    contract_utilities: defaultIfFalsy([]),
    contract_custom_utilities: defaultIfFalsy([]),
    parking: defaultIfFalsy({
      enabled: false,
      name: '',
      hasFee: false,
      fee_txt_id: 'parking',
      fee_frequency_txt_id: '',
      fee: ''
    }),
    storage: defaultIfFalsy({
      enabled: false,
      name: '',
      hasFee: false,
      fee_txt_id: 'storage',
      fee_frequency_txt_id: '',
      fee: ''
    }),
    pet_policy: isTruthy,
    pet_deposit: defaultIfFalsy('0'),
    contract_fees: defaultIfFalsy([]),
    additional_fees: defaultIfFalsy([]),
    contract_landlords: defaultIfFalsy([]),
    contract_predefined_addendums: defaultIfFalsy({}),
    contract_addendums: defaultIfFalsy([]),
    contract_extra_users: defaultIfFalsy([])
  }),
  mergeSpec({
    pet_limitations: ({ allow_dogs, allow_cats }) =>
      not(
        equals(map(defaultIfFalsy('0'), [allow_dogs, allow_cats]), ['0', '0'])
      ),
    extra_offer_enabled: pipe(
      pickAll(['extra_offer', 'extra_offer_frequency']),
      over(lensProp('extra_offer'), Number),
      ramdaValues,
      any(isTruthy)
    )
  })
)

const CreateContractForm = ({
  contract,
  listingId,
  applicationId,
  contractId,
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [validPartiesInfo, setValidPartiesInfo] = useState(true)
  const [validTermsInfo, setValidTermsInfo] = useState(true)

  const emptyNumber = (
    typeErrorMessage = t(
      'l.contract.create.fee_must_be_number.error',
      'Fee amount must be a number'
    )
  ) => {
    return Yup.number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value
        if (!originalValue || !originalValue.trim()) {
          return null
        }
        return originalValue
      })
      .nullable(true)
      .typeError(typeErrorMessage)
  }

  const partiesValidationSchema = Yup.object().shape({
    contract_extra_users: Yup.array(),
    contract_non_signing_tenants: Yup.array(),
    contract_signing_tenants: Yup.array()
      .required(
        t(
          'l.contract.create.signing_tenant_required.error',
          'Must add at least one signing tenant'
        )
      )
      .min(1),
    contract_landlords: Yup.array()
      .required(
        t(
          'l.contract.create.landlord_required.error',
          'Must select at least one landlord'
        )
      )
      .min(1)
  })

  const termsValidationSchema = Yup.object().shape({
    lease_type: Yup.string().required(),
    start_date: Yup.date().required(
      t(
        'l.contract.create.start_date_required.error',
        'Start date is a required field'
      )
    ),
    end_date: Yup.mixed().when('lease_type', {
      is: 'fixed',
      then: Yup.date()
        .required(
          t(
            'l.contract.create.end_date_required.error',
            'End date is a required field'
          )
        )
        .min(
          Yup.ref('start_date'),
          t(
            'l.contract.create.end_date_minimum.error',
            'End date must be greater than the start date'
          )
        ),
      otherwise: Yup.string()
    }),
    lease_end_action: Yup.mixed().when('lease_type', {
      is: 'fixed',
      then: Yup.string().required(
        t(
          'l.contract.create.lease_end_action_required.error',
          'Lease end action is required'
        )
      ),
      otherwise: Yup.string()
    }),
    strata_lot_number: Yup.string(),
    strata_plan_number: Yup.string(),
    price: Yup.number()
      .typeError(
        t(
          'l.contract.create.rental_price_type.error',
          'Rental price must be a number'
        )
      )
      .positive()
      .integer(
        t(
          'l.contract.create.rental_price_integer.error',
          'Rental price must be a whole number'
        )
      )
      .required(),
    price_frequency: Yup.string().required(),
    security_deposit: Yup.number()
      .typeError(
        t(
          'l.contract.create.security_deposit_type.error',
          'Security deposit must be a number'
        )
      )
      .min(
        0,
        t(
          'l.contract.create.security_deposit_minimum.error',
          'Deposit can not be lower than 0'
        )
      )
      .required(
        t(
          'l.contract.create.security_deposit_required.error',
          'Security deposit is a required field'
        )
      ),
    extra_offer_enabled: Yup.bool(),
    extra_offer: Yup.mixed().when('extra_offer_enabled', {
      is: true,
      then: Yup.number()
        .typeError(
          t(
            'l.contract.create.added_offer_type.error',
            'Added offer must be a number'
          )
        )
        .min(
          1,
          t(
            'l.contract.create.added_offer_minimum.error',
            'Added offer can not be lower than 1'
          )
        )
        .integer(
          t(
            'l.contract.create.added_offer_integer.error',
            'Added offer must be a whole number'
          )
        )
        .required(),
      otherwise: Yup.string()
    }),
    extra_offer_frequency: Yup.mixed().when('extra_offer_enabled', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    contract_features: Yup.array(),
    contract_custom_features: Yup.array(),
    contract_utilities: Yup.array(),
    contract_custom_utilities: Yup.array(),
    pet_policy: Yup.bool().required(),
    pet_deposit: Yup.mixed().when('pet_policy', {
      is: true,
      then: Yup.number()
        .typeError(
          t(
            'l.contract.create.pet_deposit_type.error',
            'Pet deposit must be a number'
          )
        )
        .min(
          0,
          t(
            'l.contract.create.pet_deposit_minimum.error',
            'Deposit can not be lower than 0'
          )
        )
        .integer(
          t(
            'l.contract.create.pet_deposit_integer.error',
            'Pet deposit must be a whole number'
          )
        )
        .required(),
      otherwise: Yup.string()
    }),
    allow_cats: Yup.mixed().when('pet_limitations', {
      is: true,
      then: Yup.number()
        .typeError(
          t(
            'l.contract.create.cats_integer.error',
            'Cats must be a whole number'
          )
        )
        .min(
          0,
          t(
            'l.contract.create.cats_minimum.error',
            'Cats can not be lower than 0'
          )
        )
        .integer(
          t(
            'l.contract.create.cats_integer.error',
            'Cats must be a whole number'
          )
        )
        .required(),
      otherwise: Yup.string()
    }),
    allow_dogs: Yup.mixed().when('pet_limitations', {
      is: true,
      then: Yup.number()
        .typeError(
          t(
            'l.contract.create.dogs_integer.error',
            'Dogs must be a whole number'
          )
        )
        .min(
          0,
          t(
            'l.contract.create.dogs_minimum.error',
            'Dogs can not be lower than 0'
          )
        )
        .integer(
          t(
            'l.contract.create.dogs_integer.error',
            'Dogs must be a whole number'
          )
        )
        .required(),
      otherwise: Yup.string()
    }),
    parking: Yup.object().shape({
      enabled: Yup.bool(),
      name: Yup.string(),
      hasFee: Yup.bool(),
      fee_frequency_txt_id: Yup.mixed().when('hasFee', {
        is: 'true',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      fee: Yup.mixed().when('hasFee', {
        is: true,
        then: Yup.number()
          .typeError(
            t(
              'l.contract.create.parking_fee_type.error',
              'Parking fee must be a number'
            )
          )
          .min(
            0,
            t(
              'l.contract.create.parking_fee_minimum.error',
              'Parking fee can not be lower than 0'
            )
          )
          .integer(
            t(
              'l.contract.create.parking_fee_integer.error',
              'Parking fee must be a whole number'
            )
          )
          .required(),
        otherwise: Yup.string()
      })
    }),
    storage: Yup.object().shape({
      enabled: Yup.bool(),
      name: Yup.string(),
      hasFee: Yup.bool(),
      fee_frequency_txt_id: Yup.mixed().when('hasFee', {
        is: 'true',
        then: Yup.string().required(),
        otherwise: Yup.string()
      }),
      fee: Yup.mixed().when('hasFee', {
        is: true,
        then: Yup.number()
          .typeError(
            t(
              'l.contract.create.storage_fee_type.error',
              'Storage fee must be a number'
            )
          )
          .integer(
            t(
              'l.contract.create.storage_fee_integer.error',
              'Storage fee must be a whole number'
            )
          )
          .required(),
        otherwise: Yup.string()
      })
    }),
    additional_fees: Yup.array().of(
      Yup.object().shape({
        fee: emptyNumber().required(
          t(
            'l.contract.create.additional_fee_required.error',
            'Fee amount is a required field'
          )
        ),
        fee_frequency_txt_id: Yup.string().required(
          t(
            'l.contract.create.additional_fee_frequency_required.error',
            'Fee frequency is a required field'
          )
        ),
        name: Yup.string()
      })
    )
  })

  const validationSchema = partiesValidationSchema.concat(termsValidationSchema)

  const getUrl = useCallback(
    (appendPath = '') => {
      const part = applicationId
        ? `/application/${applicationId}`
        : `/listing/${listingId}`
      return `${part}/contract/create/${
        contractId ? `${contractId}/` : ''
      }${appendPath}`
    },
    [applicationId, contractId, listingId]
  )

  const getPath = (appendPath = '') => {
    const part = applicationId
      ? '/application/:applicationId'
      : '/listing/:listingId'
    return `${part}/contract/create/${
      contractId ? ':contractId/' : ''
    }${appendPath}`
  }

  const handleCancel = () => {
    const backUrl = applicationId
      ? `/application/${applicationId}`
      : `/listing/${listingId}`
    history.replace(backUrl)
  }

  const handleSave = async values => {
    const action = contractId
      ? dispatch.contract.save
      : dispatch.contract.create

    const { error } = await action({
      contractId,
      listing_id: toNumber(listingId),
      ...values
    })
    if (!error) {
      history.replace(`/listing/${listingId}`)
    }
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const action = contractId
      ? dispatch.contract.save
      : dispatch.contract.create
    const { error, body } = await action({
      contractId,
      listing_id: toNumber(listingId),
      ...values
    })
    setSubmitting(false)
    if (!error) {
      history.replace(
        `/listing/${listingId}/contract/${contractId || body.id}/preview`
      )
    }
  }

  const validate = throttle(data => {
    partiesValidationSchema.isValid(data).then(setValidPartiesInfo)
    termsValidationSchema.isValid(data).then(setValidTermsInfo)
  }, 1000)

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={getInitialValue(contract)}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, handleSubmit, errors }) => {
        validate(values)
        return (
          <TabResolver getUrl={getUrl}>
            <ContractHeader>
              <ListingInfoContainer>
                <ListingInfo listingId={listingId} />
              </ListingInfoContainer>
              <Separator />
              <TabsContainer>
                <ContractTab exact to={getUrl()}>
                  {!validPartiesInfo && <Notification />}
                  {t('l.contract.create.parties.tab', 'Parties')}
                </ContractTab>
                <ContractTab to={getUrl('terms')}>
                  {!validTermsInfo && <Notification />}
                  {t('l.contract.create.terms.tab', 'Terms')}
                </ContractTab>
                <ContractTab to={getUrl('addendums')}>
                  {t('l.contract.create.addendums.tab', 'Addendums')}
                </ContractTab>
              </TabsContainer>
            </ContractHeader>
            <Content>
              <StyledForm>
                <ContentWrapper flex={false}>
                  <Switch>
                    <Route
                      exact
                      path={getPath()}
                      render={props => (
                        <ContractParties
                          {...props}
                          data={values}
                          contractId={contractId}
                          listingId={listingId}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={getPath('terms')}
                      render={props => (
                        <ContractTerms
                          {...props}
                          data={values}
                          errors={errors}
                          contractId={contractId}
                          listingId={listingId}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={getPath('addendums')}
                      render={props => (
                        <ContractAddendums
                          {...props}
                          data={values}
                          contractId={contractId}
                          listingId={listingId}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                  </Switch>
                </ContentWrapper>
                <ButtonBar sticky>
                  <Button
                    theme="sane"
                    width={110}
                    inversed
                    disabled={isSubmitting}
                    onClick={handleCancel}
                  >
                    {t('common.back', 'Back')}
                  </Button>
                  <Button
                    theme="sane"
                    width={displayMobile ? 110 : 150}
                    left={10}
                    disabled={isSubmitting}
                    onClick={() => handleSave(values)}
                  >
                    {t('l.button_bar.edit.save_and_exit.button', 'Save & Exit')}
                  </Button>
                  <Button
                    theme="primary"
                    width={displayMobile ? 110 : 150}
                    left={displayMobile ? 10 : 'auto'}
                    onClick={handleSubmit}
                  >
                    {t('r.contract.create.preview.button', 'Preview')}
                  </Button>
                </ButtonBar>
              </StyledForm>
            </Content>
          </TabResolver>
        )
      }}
    </Formik>
  )
}

const CreateContract = ({
  contract,
  contractId,
  listingId,
  applicationId,
  history,
  noTitle
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch.listing.getApplications(listingId)
  }, [dispatch.listing, listingId])

  return (
    <Page>
      <Helmet>
        <title>
          {t(
            'b.contract.view.residential_tenancy_agreement.title',
            'Residential Tenancy Agreement'
          )}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {!noTitle && (
        <PageTitle>
          {t(
            'b.contract.view.residential_tenancy_agreement.title',
            'Residential Tenancy Agreement'
          )}
        </PageTitle>
      )}
      <CreateContractForm
        contract={contract}
        listingId={listingId}
        applicationId={applicationId}
        contractId={contractId}
        history={history}
      />
    </Page>
  )
}

export default withRouter(CreateContract)
