import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { toNumber } from 'lodash'
import moment from 'moment'
import config from 'config'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import Panel from 'components/panel'
import { Label, Row } from 'components/form'
import CurrencyFormatter from 'components/currency-formatter'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import Icon from 'components/icon'
import { show as showProcessingFeesModal } from 'features/Payments/processing-fees-modal'

const ContentWrapper = styled.div`
  max-width: 700px;
  margin: 60px auto 70px auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 60px 10px 70px 10px;
  }
`

const StyledPanel = styled(Panel)`
  text-align: left;
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0px;
  }
`

const AreaTitle = styled.h3`
  height: 35px;
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 35px;
  text-align: center;
  margin-bottom: ${props => props.bottom || 30}px;
  margin-top: ${props => props.top || 60}px;
`

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  border-top: ${props => (props.borderTop ? 1 : 0)}px solid ${colors.grey};
`

const StyledLabel = styled(Label)`
  flex: 1;
  margin-right: 20px;

  ${props =>
    props.bold &&
    `
      font-weight: ${fontWeights.medium};
  `}
`

const StyledValue = styled(Label)`
  font-weight: ${fontWeights.medium};
  flex: 1;
  text-align: right;
`

const FeeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }
`

const TenantPaymentHistory = ({ paymentData = {}, history }) => {
  const { t } = useTranslation()
  const transactionInfo = paymentData.transaction_info
  const paymentRequestId = paymentData.id
  const hasDiscount =
    !!paymentData.discount_amount && toNumber(paymentData.discount_amount) > 0

  const getPdfUrl = () =>
    `${config.BACKEND_SERVER}/payment_request/receipt/tenant/${paymentRequestId}/receipt.pdf`

  return (
    <>
      <ContentWrapper>
        <AreaTitle>
          {t('b.payment.view.payment_summary.title', 'Payment Summary')}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={50}>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.status.label', 'Status')}
            </StyledLabel>
            <StyledValue>
              {paymentData.state_machine === 'approved'
                ? t('b.payment.view.successful.text', 'Successful')
                : t('b.payment.view.unsuccessful.text', 'Unsuccessful')}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.confirmation_number.label', 'Confirmation #')}
            </StyledLabel>
            <StyledValue>{transactionInfo.charge_id || '-'}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.sent_by.label', 'Sent by')}
            </StyledLabel>
            <StyledValue>{`${paymentData.tenant?.preferred_name} ${paymentData.tenant?.last_name}.`}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>{t('b.payment.view.sent_to.label', 'To')}</StyledLabel>
            <StyledValue>{`${paymentData.user?.preferred_name} ${paymentData.user?.last_name}.`}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.detail.label', 'Detail')}
            </StyledLabel>
            <StyledValue>{paymentData.description}</StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.due_date.label', 'Due date')}
            </StyledLabel>
            <StyledValue>
              {moment(paymentData.due_date).format('MM-DD-YYYY')}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.payment_made.label', 'Payment made')}
            </StyledLabel>
            <StyledValue>
              {moment(paymentData.paid_at).format('MM-DD-YYYY')}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.paid_with.label', 'Paid with')}
            </StyledLabel>
            <StyledValue>
              {transactionInfo.card_type} •••• {transactionInfo.last4}
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={10}>
            <StyledLabel>
              {t('b.payment.view.payment_amount.label', 'Payment amount')}
            </StyledLabel>
            <StyledValue data-testid="requested-value">
              <CurrencyFormatter
                value={paymentData.requested_amount}
                showCents
              />
            </StyledValue>
          </StyledRow>
          {hasDiscount && (
            <StyledRow bottom={10}>
              <StyledLabel>
                {t('b.payment.view.discount.label', 'Discount')}
              </StyledLabel>
              <StyledValue data-testid="discount-value">
                -
                <CurrencyFormatter
                  value={paymentData.discount_amount}
                  showCents
                />
              </StyledValue>
            </StyledRow>
          )}
          <StyledRow bottom={10} paddingTop={20} borderTop>
            <StyledLabel>
              {t('b.payment.view.subtotal.label', 'Subtotal')}
            </StyledLabel>
            <StyledValue data-testid="subtotal-value">
              <CurrencyFormatter
                value={
                  paymentData.requested_amount - paymentData.discount_amount
                }
                showCents
              />
            </StyledValue>
          </StyledRow>
          <StyledRow bottom={20}>
            <FeeInfoWrapper>
              <Label bottom="0">
                {t(
                  'b.payment.view.make_payment_processing_fees.text',
                  'Processing fees'
                )}
              </Label>
              <Icon
                id="info_blue"
                width={16}
                height={16}
                onClick={showProcessingFeesModal}
              />
            </FeeInfoWrapper>
            <StyledValue bottom="0" data-testid="fee-value">
              <CurrencyFormatter value={paymentData.fee} showCents />
            </StyledValue>
          </StyledRow>
          <StyledRow bottom="0" paddingTop={20} borderTop>
            <StyledLabel bottom="0" bold>
              {t('b.payment.view.make_payment_total.label', 'Total')}
            </StyledLabel>
            <StyledValue bottom="0" data-testid="charged-value">
              <CurrencyFormatter value={paymentData.charged} showCents />
            </StyledValue>
          </StyledRow>
        </StyledPanel>
      </ContentWrapper>

      <ButtonBar sticky>
        <Button theme="sane" width={150} inversed onClick={history.goBack}>
          {t('b.payment.view.make_payment_back.button', 'Back')}
        </Button>
        <Button
          as="a"
          theme="primary"
          width={150}
          href={getPdfUrl()}
          target="_blank"
        >
          {t('b.payment.view.export_payment_receipt.button', 'Export')}
        </Button>
      </ButtonBar>
    </>
  )
}

export default TenantPaymentHistory
