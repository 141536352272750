import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { colors, breakpoints, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import separatorArrow from './breadcrumb-separator.svg'
import backArrow from './back-arrow.svg'

const BreadcrumbNav = styled.nav``

const BreadcrumbList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`

const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;

  :not(:last-child) {
    :after {
      content: url(${separatorArrow});
      padding: 0 10px;
      height: 16px;
      width: 16px;
    }
  }
`

const TextStyles = css`
  font-size: 13px;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: 0.27px;
  line-height: 18px;
  margin: 0;
`

const BreadcrumbLink = styled(Link)`
  ${TextStyles}

  :hover {
    color: ${colors.secondary};
    text-decoration: underline;
  }

  ${props =>
    props.current &&
    `
    pointer-events: none;

    :hover {
      color: ${colors.regular};
      text-decoration: none;;
    }
  `}
`

const BackButton = styled.button`
  ${TextStyles}
  background: ${colors.white};
  border: none;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;

  :before {
      content: url(${backArrow});
      padding-right: 5px;
      height: 15px;
      width: 18px;
  }
`

const Breadcrumbs = ({ items }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  return (
    <>
      {displayMobile ? (
        <BackButton onClick={() => history.push(items[items.length - 2].to)}>
          {t('common.back', 'Back')}
        </BackButton>
      ) : (
        <BreadcrumbNav aria-label="breadcrumb">
          <BreadcrumbList>
            {items.map(({ to, label }) =>
              !!to ? (
                <BreadcrumbItem key={label}>
                  <BreadcrumbLink to={to}>{label}</BreadcrumbLink>
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem key={label}>
                  <BreadcrumbLink to="#" current="true" aria-current="location">
                    {label}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )
            )}
          </BreadcrumbList>
        </BreadcrumbNav>
      )}
    </>
  )
}

export default React.memo(Breadcrumbs)
