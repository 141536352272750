import React, { useState } from 'react'
import { compose, pure } from 'recompose'
import styled from 'styled-components'
import Icon from 'components/icon'

import Base from '../base'

const Wrapper = styled(Base)`
  display: inline-flex;
  align-items: center;

  ${props =>
    props.hasChildren &&
    `
    > .i {
      margin-right: 15px;
    }
  `}

  ${props =>
    props.align === 'center' &&
    `
    justify-content: center;
  `}

  ${props =>
    props.align === 'left' &&
    `
    text-align: left;
    justify-content: flex-start;
    padding-left: 7%;
  `}
`

const ImageButton = ({
  children,
  iconProps,
  icon,
  activeIcon = '',
  ...rest
}) => {
  const [hover, setHover] = useState(false)

  const toggleHover = () => {
    setHover(!hover)
  }

  const getIconId = () => {
    if (!activeIcon) return icon
    if (rest['aria-checked']) return activeIcon
    return hover ? activeIcon : icon
  }

  return (
    <Wrapper
      hasChildren={!!children}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      {...rest}
    >
      {icon && <Icon id={getIconId()} className="i" {...iconProps} />}
      {children}
    </Wrapper>
  )
}

ImageButton.defaultProps = {
  align: 'center'
}

export default compose(pure)(ImageButton)
