import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, fontSizes } from 'styles'

const style = css`
  color: ${colors.link};
  background: transparent;
  border: none;
  font-size: ${fontSizes.small};
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.7;
  }
`

export const TextLink = styled(NavLink)`
  ${style}
`

const TextButton = styled.button`
  ${style}
`

export default TextButton
