import React, { useState, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { breakpoints } from 'styles'
import config from 'config'
import useProfile from 'hooks/use-profile'
import useMediaQuery from 'hooks/use-media-query'
import ContentWrapper from 'components/content-wrapper'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import { show as showSuccessModal } from 'components/success-modal'
import { showSignatureModal } from 'components/signature-modal'
import SignaturePreview from 'components/signature-preview'
import {
  Wrapper,
  StyledAreaTitle,
  StyledPanel,
  DataRow,
  TextRow,
  WarningRow,
  CheckboxContainer,
  Checkbox,
  CheckboxText,
  StyledAnchor
} from './elements'
import Context from './context'

const { WEB_URL } = config

const Review = ({ handleBack, history }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userProfile = useProfile()
  const { formData } = useContext(Context)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const initialValues = {
    ...formData,
    user_signature: null
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const response = await dispatch.settlements.updateUnionPayPayout(values)
    setSubmitting(false)
    if (response.ok) {
      return showSuccessModal({
        title: t(
          'l.payment_settings.view.you_are_all_set.text',
          `You're all set!`
        ),
        message: t(
          'l.payment_settings.view.payout_deposit_submit_successfully.text',
          'Congratulations! You have successfully submitted your payout deposit information.'
        ),
        buttonText: t('l.payment_settings.view.done.button', 'Done'),
        onClose: () => history.push('/settings/payment-settings')
      })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, setFieldValue, isSubmitting }) => {
        const handleSaveSignature = ({ signature }) => {
          setFieldValue('user_signature', signature)
        }
        const hasSigned = !!values.user_signature

        return (
          <Form className="flex-full">
            <Wrapper>
              <ContentWrapper className="mb-50">
                <StyledAreaTitle>
                  {t(
                    'l.payment_settings.view.review_terms.subtitle',
                    'Review Terms'
                  )}
                </StyledAreaTitle>
                <StyledPanel hSpace={displayMobile ? 20 : 40} vSpace={30}>
                  <DataRow>
                    <p>
                      {t(
                        'l.payment_settings.view.date_of_agreement.label',
                        'Date of agreement'
                      )}
                      :
                    </p>
                    <p>
                      <strong>{moment().format('MM-DD-YYYY')}</strong>
                    </p>
                  </DataRow>
                  <WarningRow>
                    {t(
                      'l.payment_settings.view.add_payout_agreement_warning.text',
                      'By signing below, you acknowledge and agree to the following:'
                    )}
                  </WarningRow>
                  <TextRow>
                    <ul>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_1.text',
                          'Liv Rent will deposit all payments, less any applicable fees, into the bank account indicated. '
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_2.text',
                          'The amount on your monthly statement may vary depending on the actual amount paid by tenant(s), payment method(s) used, and applicable transaction fees.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_3.text',
                          'Payments will be deposited into your account 5 Business Days after a payment has been processed.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_4.text',
                          'Payout fees are non-refundable.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_5.text',
                          'Liv Rent will not be responsible for incomplete, late, or missed payments caused by a Tenant’s failure to pay (in part or in full), or technical issues, outages, or delays caused by any financial institution or payment processor.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_6.text',
                          'Your account will be automatically debited for any chargebacks that occur with your received transactions. For any disputes, please contact Liv Support.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_7.text',
                          'Liv Rent is not responsible for any refunds and disputes between the Landlord and the Tenant.'
                        )}
                      </li>
                      <li>
                        {t(
                          'l.payment_settings.view.add_payout_agreement_paragraph_8.text',
                          'Deposit instructions may be changed by contacting Liv Support at support@liv.rent'
                        )}
                      </li>
                    </ul>
                  </TextRow>
                  <CheckboxContainer>
                    <CheckboxText htmlFor="agree_to_terms">
                      <strong>
                        <Trans i18nKey="b.payment_settings.view.agree_to_terms_and_conditions.text">
                          I have read and agree to the terms above and in Liv’s{' '}
                          <StyledAnchor
                            as="a"
                            href={`${WEB_URL}/terms`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions.
                          </StyledAnchor>
                        </Trans>
                      </strong>
                    </CheckboxText>
                    <Checkbox
                      data-cy="agree_to_terms"
                      id="agree_to_terms"
                      data-testid="agree-to-terms"
                      type="checkbox"
                      checked={acceptedTerms}
                      onChange={e => setAcceptedTerms(e.target.checked)}
                    />
                  </CheckboxContainer>
                  <SignaturePreview
                    key={userProfile.id}
                    isCurrentUser
                    toggleSignaturePad={() =>
                      showSignatureModal({ onSave: handleSaveSignature })
                    }
                    signatures={hasSigned ? [values.user_signature] : []}
                    renter={userProfile}
                    localSignature={values.user_signature}
                  />
                </StyledPanel>
              </ContentWrapper>
            </Wrapper>
            <ButtonBar sticky>
              <Button theme="sane" width={150} inversed onClick={handleBack}>
                {t('l.payment_settings.view.back.button', 'Back')}
              </Button>
              <Button
                type="submit"
                theme="primary"
                width={150}
                disabled={!acceptedTerms || !hasSigned}
                isSubmitting={isSubmitting}
              >
                {t('l.payment_settings.view.submit.button', 'Submit')}
              </Button>
            </ButtonBar>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Review
