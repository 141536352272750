import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router-dom'
import { breakpoints } from 'styles'
import { path } from 'ramda'
import useGetSuiteInfo from 'hooks/get-suite-info'
import PageTitle from 'components/page-title'
import DashboardMenu from 'components/dashboard-menu'
import ContractHistory from 'pages/Tenants/TenantResources/contract-history'
import ResourcesContractView from 'pages/Tenants/TenantResources/resources-contract-view'
import { ListingDetail } from '../Listing'
import { ContractView } from '../Contract'
import RentalChat from './RentalChat'
import Resources from './Resources'
import { PaymentsDashboard, Payment } from './Payments'

const Wrapper = styled.div`
  margin-top: ${props => `${props.top}px`};
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    min-height: calc(100vh - 94px);
  }
`

const RentalDashboard = ({
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contract = useSelector(path(['listing', listingId, 'contract']))
  const contractId = contract?.listing_contract_id
  const listingDetails = useGetSuiteInfo(listingId)

  useEffect(() => {
    dispatch.listing.getContract(listingId)
  }, [dispatch.listing, listingId])

  if (!listingDetails) return null

  const menuItems = [
    {
      link: `/rental/${listingId}`,
      text: t('b.rental.view.overview.link', 'Overview'),
      exact: true
    },
    {
      link: `/rental/${listingId}/chat`,
      text: t('b.rental.view.chat.link', 'Chat')
    },
    {
      link: `/rental/${listingId}/contract/${contractId}`,
      text: t('b.rental.view.contract.link', 'Contract')
    },
    {
      link: `/rental/${listingId}/payments/`,
      text: t('b.rental.view.payments.link', 'Payments')
    },
    {
      link: `/rental/${listingId}/resources`,
      text: t('b.rental.view.resources.link', 'Resources')
    }
  ]

  return (
    <>
      <PageTitle>{listingDetails.full_street_name}</PageTitle>
      <DashboardMenu menuItems={menuItems} />
      <Wrapper>
        <Switch>
          <Route
            exact
            path="/rental/:listingId"
            render={props => <ListingDetail noTitle {...props} />}
          />
          <Route exact path="/rental/:listingId/chat" component={RentalChat} />
          <Route
            exact
            path="/rental/:listingId/chat/:timelineId"
            component={RentalChat}
          />
          <Route
            exact
            path="/rental/:listingId/contract/:contractId/:step(review|terms|sign)?"
            render={props => <ContractView noTitle {...props} />}
          />

          <Route
            exact
            path="/rental/:listingId/payments/:paymentId"
            component={Payment}
          />
          <Route
            exact
            path="/rental/:listingId/payments"
            component={PaymentsDashboard}
          />
          <Route
            exact
            path="/rental/:listingId/resources"
            component={Resources}
          />
          <Route
            exact
            path="/rental/:listingId/resources/contract-history"
            component={ContractHistory}
          />
          <Route
            exact
            path="/rental/:listingId/resources/contract-history/:contractId"
            component={ResourcesContractView}
          />
        </Switch>
      </Wrapper>
    </>
  )
}

export default RentalDashboard
