import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { map, path } from 'ramda'
import { useTranslation } from 'react-i18next'
import striptags from 'striptags'
import formatContent from 'utils/format-content'
import toArray from 'utils/to-array'

const Option = styled.span`
  &:not(:last-child):after {
    content: ', ';
  }
`

const AddendumPlainDescription = ({ addendum, className, variables }) => {
  const { t } = useTranslation()
  const addendumTemplate = useSelector(
    path(['contract', 'listing_contract_addendum_templates', addendum.id])
  )
  if (!addendumTemplate) return null

  if (addendumTemplate.data_type === 'content') {
    const content = toArray(
      addendumTemplate.listing_contract_addendum_template_options
    ).shift()
    const formattedContent = formatContent(content.description, variables)
    return <p className={className}>{striptags(formattedContent)}</p>
  }

  return (
    <div className={className}>
      {addendum.allowed ? (
        <span>
          {t('b.contract.view.addendum_allowed.text', 'Allowed')}:&nbsp;
          {map(({ id }) => {
            const option =
              addendumTemplate.listing_contract_addendum_template_options[id]
            return option && <Option key={option.id}>{option.title}</Option>
          }, addendum.options)}
        </span>
      ) : (
        <span>
          {t('b.contract.view.addendum_not_allowed.text', 'Not allowed')}
        </span>
      )}
    </div>
  )
}

AddendumPlainDescription.defaultProps = {
  addendum: {},
  addendumTemplate: {},
  className: null,
  variables: {}
}

export default AddendumPlainDescription
