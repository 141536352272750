import React, { useRef } from 'react'
import { useField, useFormikContext } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import SwitchToggle from './switch-toggle'
import Error from './error'

const FieldSwitchToggle = ({ name, ...rest }) => {
  const formik = useFormikContext()
  const [field] = useField(name)
  const fieldRef = useRef()
  useFocusOnError({ fieldRef, name })

  return (
    <>
      <SwitchToggle
        ref={fieldRef}
        enabled={field.value}
        onChange={v => formik.setFieldValue(field.name, v)}
        {...rest}
      />
      <Error name={name} />
    </>
  )
}

export default FieldSwitchToggle
