import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'
import LoadingIcon from './loading.svg'

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  ${props =>
    props.inline &&
    `
  display: inline-block;`}
  ${props =>
    !props.inline &&
    `
  height: 100%;
  width: 100%;`}
  > img {
    height: ${props => props.size || 50}px;
    width: ${props => props.size || 50}px;
    animation: spin 1s linear infinite;
    transform-origin: center;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
    path {
      fill: ${props => props.color || colors.grey};
    }
  }
`

const Loading = props => {
  return (
    <LoadingWrapper {...props}>
      <img src={LoadingIcon} alt="loading icon" />
    </LoadingWrapper>
  )
}

export default Loading
