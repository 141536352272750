import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
  ${({ customStyle }) => customStyle}
`

const PhoneNumber = ({ phoneNumber, children = x => x, ...rest }) => {
  if (!phoneNumber) return null
  return (
    <Link href={phoneNumber.getURI()} {...rest}>
      {children(phoneNumber.formatInternational())}
    </Link>
  )
}

export default PhoneNumber
