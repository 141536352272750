import agreement from './agreement'
import application from './application'
import auth from './auth'
import basic from './basic'
import building from './building'
import chat from './chat'
import chatPreset from './chat-preset'
import contract from './contract'
import error from './error'
import geocoding from './geocoding'
import listing from './listing'
import companyManagement from './company-management'
import loading from './loading'
import master from './master'
import payment from './payment'
import session from './session'
import settlements from './settlements'
import supportRoom from './support-room'
import sse from './sse'
import user from './user'
import profile from './profile'
import livScore from './liv-score'
import insurance from './insurance'
import deeplink from './deeplink'
import endTenancy from './end-tenancy'

export default {
  agreement,
  application,
  auth,
  basic,
  building,
  chat,
  chatPreset,
  contract,
  endTenancy,
  livScore,
  insurance,
  error,
  geocoding,
  listing,
  companyManagement,
  loading,
  master,
  payment,
  session,
  settlements,
  supportRoom,
  sse,
  user,
  profile,
  deeplink
}
