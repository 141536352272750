import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js'

const toLowerCase = value => value && value.toLowerCase()

export const formatPhone = (phoneNumber, countryCode) => {
  if (!phoneNumber || phoneNumber.length <= 3) return phoneNumber
  return new AsYouType((countryCode || 'ca').toUpperCase()).input(phoneNumber)
}

export const formatPhoneObject = (phoneNumber, countryCode, countryAlpha) => {
  const country =
    countryCode && countryAlpha
      ? { txt_id: toLowerCase(countryAlpha), calling_code: countryCode }
      : { txt_id: 'ca', calling_code: '1' }

  const number =
    phoneNumber && phoneNumber.replace(`+${country.calling_code}`, '')

  return { phoneNumber: number, phoneCountry: country }
}

export const parsePhone = phoneNumber => {
  try {
    return parsePhoneNumberFromString(phoneNumber)
  } catch (err) {
    return null
  }
}
