import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router'
import { Button } from 'components/button'
import ButtonBar from 'components/button-bar'
import ContentWrapper from 'components/content-wrapper'
import CardDetails from './card'
import BillingDetails from './billing'
import PaymentDetails from './payment'
import { show as showPaymentCompletedModal } from '../payment-completed-modal'
import Context from '../context'

const Details = ({
  handleBack,
  history,
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { formData, feesInfo, paymentData } = useContext(Context)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const paymentAmount = feesInfo[formData.payment_method].amount

  const initialValues = {
    ...formData,
    amount: paymentAmount,
    paymentRequestId: paymentData.id
  }

  const redirectToRentalPayments = () => {
    history.push(`/rental/${listingId}/payments`)
    window.scrollTo(0, 0)
  }

  const onSubmit = async (values, { setSubmitting }) => {
    const { response, body } = await dispatch.settlements.makePayment(values)
    setSubmitting(false)
    if (response.ok) {
      const data = {
        preferred_name: paymentData.user.preferred_name,
        last_name: paymentData.user.last_name,
        ...body
      }
      return showPaymentCompletedModal({
        data,
        onClose: redirectToRentalPayments
      })
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, isSubmitting }) => (
        <Form className="flex-full">
          <ContentWrapper className="mb-50">
            <PaymentDetails
              values={values}
              paymentData={paymentData}
              feesInfo={feesInfo}
            />
            <CardDetails
              values={values}
              acceptedTerms={acceptedTerms}
              setAcceptedTerms={setAcceptedTerms}
            />
            {values.payment_method === 'stripe' && (
              <BillingDetails
                values={values}
                acceptedTerms={acceptedTerms}
                setAcceptedTerms={setAcceptedTerms}
              />
            )}
          </ContentWrapper>
          <ButtonBar sticky>
            <Button theme="sane" width={150} inversed onClick={handleBack}>
              {t('r.payment.edit.make_payment_back.button', 'Back')}
            </Button>
            <Button
              theme="primary"
              width={150}
              type="submit"
              disabled={isSubmitting || !acceptedTerms}
            >
              {t('r.payment.edit.make_payment_pay_now.button', 'Pay Now')}
            </Button>
          </ButtonBar>
        </Form>
      )}
    </Formik>
  )
}

export default withRouter(Details)
