import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { useTranslation } from 'react-i18next'
import { path, isEmpty } from 'ramda'
import { isNotEmpty } from 'utils'
import CreateContract from 'features/Contract/CreateContract'
import PageSubTitle from 'components/page-subtitle'

const ListingCreateContract = ({
  match: {
    params: { listingId, contractId }
  },
  history,
  noTitle
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const listingContract = useSelector(path(['listing', listingId, 'contract']))

  const contract = useSelector(state =>
    select.contract.contractDetail(state, {
      contractId,
      listingId
    })
  )

  useEffect(() => {
    dispatch.contract.loadAddendumTemplates()
  }, [dispatch.contract])

  useEffect(() => {
    dispatch.listing.load(listingId)
    if (!contractId) {
      dispatch.listing.getContract(listingId)
    }
  }, [contractId, dispatch.listing, listingId])

  useEffect(() => {
    if (!contractId && listingContract?.listing_contract_id) {
      history.replace(
        `/listing/${listingId}/contract/create/${
          listingContract.listing_contract_id || ''
        }`
      )
    }
  }, [contractId, history, listingContract, listingId])

  useEffect(() => {
    if (contractId) {
      dispatch.contract.load(contractId)
    }
  }, [contractId, dispatch.contract])
  const preventRender = () => {
    if (contractId) return isEmpty(contract)
    return (
      !listingContract || (isNotEmpty(listingContract) && isEmpty(contract))
    )
  }

  if (preventRender()) return null

  return (
    <>
      <PageSubTitle>
        {t('l.contract.edit.contract.title', 'Contract')}
      </PageSubTitle>
      <CreateContract
        contractId={contractId}
        listingId={listingId}
        contract={contract}
        noTitle={noTitle}
      />
    </>
  )
}

export default ListingCreateContract
