import ENV_VARS from 'config'
import { post, del, put } from 'ports/api'
const { BACKEND_SERVER } = ENV_VARS

export const sendFile = post(`${BACKEND_SERVER}/secure/%portTag%`)
export const deleteFile = del(`${BACKEND_SERVER}/secure/%tag%/%fileId%`)
export const updateCreditCheck = put(`${BACKEND_SERVER}/users/me`)
export const requestCreditCheck = post(`${BACKEND_SERVER}/users/credit_check`, {
  failSilently: true
})
