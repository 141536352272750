import {
  pipe,
  evolve,
  pick,
  toUpper,
  path,
  values,
  map,
  prop,
  reject,
  includes,
  concat,
  __
} from 'ramda'
import { batch } from 'react-redux'
import { defaultIfFalsy, callOr, headObj, renameKeys } from 'utils'
import {
  sendFile,
  deleteFile,
  updateCreditCheck,
  requestCreditCheck
} from './ports'

const LivScore = {
  state: {},
  reducers: {},
  effects: dispatch => ({
    sendFile: async (payload, rootState) => {
      const portTag = 'affordability_verification'
      try {
        const response = await sendFile({ body: payload }, { portTag })
        if (response.response.ok) {
          const newSecureFiles = values(response.body.files)
          const oldSecureFiles = values(
            path(['profile', 'files', 'user_secure_files'], rootState)
          )
          const newTags = pipe(map(prop('tag')))(newSecureFiles)
          const secureFilesToDisplay = pipe(
            reject(pipe(prop('tag'), includes(__, newTags))),
            concat(newSecureFiles)
          )(oldSecureFiles)
          const newLivScore = pipe(
            values,
            headObj,
            prop('liv_score')
          )(response.body.users)
          dispatch.profile.updateSecureFiles(secureFilesToDisplay)
          dispatch.profile.update(['liv_score'], newLivScore)
        }
        return response
      } catch (error) {
        console.log('[LivScore/sendFile]', error)
      }
    },
    deleteFile: async ({ fileId, tag }, rootState) => {
      try {
        const response = await deleteFile(undefined, {
          fileId,
          tag
        })
        if (response.response.ok) {
          dispatch.profile.deleteSecureFile(fileId)
        }
      } catch (error) {
        console.log('[LivScore/deleteFile]', error)
      }
    },
    updateInternationalUser: async payload => {
      try {
        const response = await updateCreditCheck({
          body: { international_user: payload }
        })
        if (response.response.ok) {
          dispatch.profile.update(['international_user'], payload.toString())
        }
      } catch (error) {
        console.log('[LivScore/updateInternationalUser]', error)
      }
    },
    requestCreditCheck: async payload => {
      const data = {
        body: pipe(
          evolve({
            first_name: defaultIfFalsy(null),
            last_name: defaultIfFalsy(null),
            dob: defaultIfFalsy(null),
            suite: defaultIfFalsy(null),
            street_number: defaultIfFalsy(null),
            street_name: defaultIfFalsy(null),
            city: defaultIfFalsy(null),
            province: defaultIfFalsy(null),
            zip: callOr(toUpper, null),
            sin: defaultIfFalsy(null),
            driver_license: defaultIfFalsy(null)
          })
        )(
          pick(
            [
              'first_name',
              'last_name',
              'dob',
              'suite',
              'street_number',
              'street_name',
              'city',
              'province',
              'zip',
              'sin',
              'driver_license'
            ],
            payload
          )
        )
      }
      try {
        const response = await requestCreditCheck(data)
        if (!response.response.ok) {
          throw Error(
            'Credit check failed, please make sure you have filled in your information correctly.'
          )
        }
        batch(() => {
          dispatch.profile.merge(
            pipe(
              headObj,
              pick(['completion_percentage', 'liv_verified_state'])
            )(response.body.users)
          )

          dispatch.profile.update(
            ['liv_score'],
            renameKeys({ message: 'credit_rating' }, response.body.liv_score)
          )
        })

        return response
      } catch (error) {
        dispatch.error.setError({
          type: 'error',
          message: [error.message]
        })
      }
    }
  })
}

export default LivScore
