import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFormattedStates } from 'utils/address'
import { FieldInput, FieldDropdown, Group } from 'components/form'

const StateDropdown = ({
  col,
  gutter,
  country: initialCountry = 'ca',
  countries,
  required,
  ...rest
}) => {
  const postfix = required ? '*:' : ':'
  const { t } = useTranslation()
  const country = initialCountry.toLowerCase()
  const getField = () => {
    if (country === 'ca')
      return (
        <FieldDropdown
          id="state"
          options={getFormattedStates(countries, 'ca')}
          placeholder={t('r.profile.edit.province.label', 'Province') + postfix}
          {...rest}
        />
      )
    if (country === 'us')
      return (
        <FieldDropdown
          id="state"
          options={getFormattedStates(countries, 'us')}
          placeholder={t('r.profile.edit.state.label', 'State') + postfix}
          {...rest}
        />
      )
    return (
      <FieldInput
        id="state"
        placeholder={
          t('r.profile.edit.state_province.label', 'State / Province') + postfix
        }
        {...rest}
      />
    )
  }
  return (
    <Group col={col} gutter={gutter}>
      {getField()}
    </Group>
  )
}

export default StateDropdown
