import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { path } from 'ramda'
import { colors, fontSizes } from 'styles'
import pluralize from 'utils/pluralize'
import { createModal, useModalize } from 'components/modalize'
import { H1 } from 'components/headings'
import { Button } from 'components/button'
import P from 'components/paragraph'
import Logo from 'assets/liv-logo.svg'

import Agreement from './agreement'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 95vh;
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px 0 20px 0;
`

const Title = styled(H1)`
  font-size: ${fontSizes.medium};
  line-height: 26px;
`

const AgreementLink = styled(Button)`
  font-size: ${fontSizes.regular};
  outline: none;
  background: none;
  border: none;
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  padding: 0;
  cursor: pointer;
  height: 22px;

  :hover {
    opacity: 0.8;
    background: none;
    color: ${colors.secondary};
  }
`

const LivLogo = styled.img`
  margin: 30px 0;
  height: 72px;
  width: auto;
`

const AgreementModal = () => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const mismatched = useSelector(path(['agreement', 'mismatched']))
  const [agreementId, setAgreementId] = useState()

  useEffect(() => {
    if (Object.keys(mismatched).length < 1) {
      close()
    }
  }, [close, mismatched])

  const onClose = () => {
    setAgreementId(null)
    dispatch.agreement.update({ currentAgreement: null })
  }

  const handleAgreeClick = () => {
    dispatch.agreement.agreeToAgreements()
  }

  const getLink = agreement => {
    return (
      <AgreementLink
        onClick={() => viewAgreement(agreement.txt_id)}
        key={agreement.txt_id}
      >
        {agreement.title}
      </AgreementLink>
    )
  }

  const getTitleLinks = () => {
    const agreements = Object.keys(mismatched).map(key => mismatched[key])
    const last = agreements.pop()

    const links = agreements.map((agreement, index) => (
      <span key={agreement.txt_id}>
        {getLink(agreement)}
        {index < agreements.length - 1 && ', '}
      </span>
    ))

    if (last) {
      return [
        ...links,
        <span key={last.txt_id}>
          {' and '}
          {getLink(last)}
        </span>
      ]
    }

    return links
  }

  const getTitles = () => {
    const titles = Object.keys(mismatched).map(key => mismatched[key].title)

    const last = titles.pop()
    const title = titles.join(', ')

    if (last) {
      return `${title} and ${last}`
    }

    return title
  }

  const viewAgreement = id => {
    dispatch.agreement.loadAgreement(id)
    setAgreementId(id)
  }

  const mismatchLength = Object.keys(mismatched).length
  return (
    <Wrapper>
      {agreementId ? (
        <Agreement
          agreementId={agreementId}
          onClose={onClose}
          onChange={viewAgreement}
        />
      ) : (
        <ScrollWrapper>
          <LivLogo src={Logo} alt="Liv Logo" />
          <Title>
            {`
              Our
              ${getTitles()}
              ${pluralize(mismatchLength, 'has', 'have')}
              changed.
            `}
          </Title>
          <P>
            We have updated our {getTitleLinks()}. Please review these updated
            versions and let us know if you consent to these as part of your
            ongoing use the Liv App.
          </P>
          <ButtonWrapper>
            <Button
              theme="primary"
              type="button"
              size="large"
              width={220}
              onClick={handleAgreeClick}
            >
              {t(
                'b.agreement_modal.view.accept_and_continue.button',
                'Accept and Continue'
              )}
            </Button>
          </ButtonWrapper>
        </ScrollWrapper>
      )}
    </Wrapper>
  )
}

export const showAgreementModal = createModal(AgreementModal, {
  width: '450px',
  clickOutside: false
})
