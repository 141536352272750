import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { breakpoints, fontSizes, colors } from 'styles'
import { find, propEq } from 'ramda'
import useBasic from 'hooks/use-basic'
import toArray from 'utils/to-array'
import LabeledDetail from 'components/labeled-detail'
import ProfileRequired from 'components/profile-required'
import TenantSummary from 'features/Profile/TenantSummary'
import { StyledPanel, PanelHeader, EditButton, RequiredText } from '../elements'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: center;
  }

  @media print {
    page-break-after: always;
  }
`

const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin-right: auto;

  @media screen and (max-width: 414px) {
    font-size: 15px;
    line-height: 20px;
  }
`

const ResponsivePanel = styled(StyledPanel)`
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 20px 15px;
  }
`

const FlexWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width || '100%;'};
  position: relative;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
`

const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: ${({ top }) => top || '0px'};
  margin-left: ${({ left }) => left || '0px'};
  > div:last-of-type {
    margin-bottom: 0;
  }
`

const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    justify-content: space-between;
  }
`

const notNull = value => value !== null

const TenantBasicInfo = ({
  data,
  editable = false,
  showRequiredWarning = false,
  editProps = {},
  showSummary = true
}) => {
  const { t } = useTranslation()
  const { languages, countries } = useBasic([
    'languages',
    'professions',
    'countries'
  ])

  const getCountry = countryCode => {
    const userCountry = find(
      propEq('txt_id', countryCode.toLowerCase()),
      toArray(countries)
    )
    return userCountry?.name
  }

  const renderAddress = userData => {
    const unit = userData.unit_number ? `${userData.unit_number} - ` : ''
    const address = userData.address ? `${userData.address}, ` : ''
    const city = userData.city ? `${userData.city}, ` : ''
    const state = userData.state ? `${userData.state} ` : ''
    const zip = userData.zip?.toUpperCase() || ''
    const country = userData.country_code
      ? ` ${getCountry(userData.country_code)}`
      : ''
    return `${unit}${address}${city}${state}${zip}${country}`
  }

  const getLanguages = spokenLanguages => {
    const filteredLanguages = languages.filter(
      lang => spokenLanguages.indexOf(lang.txt_id) >= 0
    )
    return filteredLanguages.map(lang => lang.name).join(', ')
  }

  return (
    <>
      <PanelHeader>
        <PanelTitle>
          {t('r.profile.view.basic_info.title', 'Basic Info')}
          <ProfileRequired
            fields={['dob', 'about_me', 'current_address']}
            area="basic_info"
          />
        </PanelTitle>
        {editable && (
          <Link to={{ pathname: '/profile/edit', state: editProps }}>
            <EditButton id="edit_basic_info">
              {t('r.profile.view.edit.button', 'Edit')}
            </EditButton>
          </Link>
        )}
      </PanelHeader>
      {showRequiredWarning && (
        <RequiredText>
          {t('r.profile.view.required.label', '*Required')}
        </RequiredText>
      )}
      <ResponsivePanel hSpace={30} vSpace={40}>
        <Wrapper>
          <FlexWrapper direction="column">
            {showSummary && <TenantSummary editable={editable} data={data} />}
            <DetailsWrapper top={showSummary ? '45px' : 0}>
              <DetailsRow>
                <LabeledDetail
                  label={t(
                    'r.profile.view.birthday.label',
                    'My Birthday Is...'
                  )}
                  value={data.dob || '-'}
                  width="33%"
                />
                <LabeledDetail
                  label={t(
                    'r.profile.view.languages_spoken.label',
                    'I Speak...'
                  )}
                  value={
                    data.spoken_languages && !!data.spoken_languages.length
                      ? getLanguages(data.spoken_languages)
                      : '-'
                  }
                  width="33%"
                />
              </DetailsRow>
              <DetailsRow>
                <LabeledDetail
                  label={
                    <>
                      {t(
                        'r.profile.view.current_address.label',
                        'Current Address'
                      )}
                      <ProfileRequired
                        fields={['current_address']}
                        area="basic_info"
                      />
                    </>
                  }
                  value={
                    notNull(data.company_info.address)
                      ? renderAddress(data.company_info)
                      : '-'
                  }
                  width="100%"
                />
              </DetailsRow>
              <DetailsRow>
                <LabeledDetail
                  label={
                    <>
                      {t('r.profile.view.about_me.label', 'About Me')}
                      <ProfileRequired
                        fields={['about_me']}
                        area="basic_info"
                      />
                    </>
                  }
                  value={notNull(data.about_me) ? data.about_me : '-'}
                  width="100%"
                />
              </DetailsRow>
            </DetailsWrapper>
          </FlexWrapper>
        </Wrapper>
      </ResponsivePanel>
    </>
  )
}

export default TenantBasicInfo
