import React, { useState, useRef, forwardRef } from 'react'
import styled from 'styled-components'
import { useField, useFormikContext } from 'formik'
import useFocusOnError from 'hooks/use-focus-on-error'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable'
import { equals } from 'ramda'
import { noop } from 'lodash'
import { colors, transitions } from 'styles'
import toId from 'utils/to-id'
import Icon from 'components/icon'
import Error from './error'
import Label, { active } from './floating-label'

const Wrapper = styled.div`
  input {
    font-weight: 600 !important;
  }
  > div {
    margin-top: 3px;
    > span + div {
      border-color: ${colors.darkBlue};
    }
  }
  ${props =>
    props.hasError &&
    `
    > div {
      > div {
        border-color: ${colors.red} !important;
      }
    }
  `}
  position: relative;
  ${props =>
    props.isFilled &&
    `
    > label:last-of-type {
      ${active}
    }
  `}
`

const IconWrapper = styled.div`
  position: relative;
  > svg {
    position: absolute;
    right: 3px;
    top: -12px;
    transform: rotate(180deg);
    path {
      fill: ${colors.mediumGrey};
    }
  }
`

const DropdownIndicator = ({ height = 20, width = 20 }) => (
  <IconWrapper>
    <Icon id="arrow" height={height} width={width} />
  </IconWrapper>
)

const getStyles = styles => ({
  container: (provided, state) => ({
    ...provided,
    ...styles.container,
    opacity: state.isDisabled ? 0.3 : 1
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.blueGrey : 'transparent',
    color: colors.regular,
    '&:hover': {
      backgroundColor: colors.blueGrey
    },
    ...styles.option
  }),
  input: provided => ({
    ...provided,
    margin: '0'
  }),
  menuPortal: ({ left, ...provided }) => ({
    ...provided,
    ...styles.menuPortal
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
    zIndex: 100,
    ...styles.menu
  }),
  control: provided => ({
    ...provided,
    height: 50,
    transition: `border-color ${transitions.ease}`,
    border: 'none',
    borderBottom: `1px solid ${colors.mediumGrey}`,
    borderRadius: 0,
    backgroundColor: 'none',
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.darkBlue
    },
    ...styles.control
  }),
  indicatorSeparator: () => ({}),
  indicatorContainer: () => ({
    ...styles.indicatorContainer
  }),
  placeholder: provided => ({
    ...provided,
    ...styles.placeholder,
    marginLeft: 0,
    color: '#ccc'
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    marginRight: 0,
    color: colors.regular,
    fontWeight: 600,
    ...styles.singleValue
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 15px'
  })
})

const getAsyncComponent = creatable =>
  creatable ? AsyncCreatableSelect : AsyncSelect

const Dropdown = React.memo(
  forwardRef(
    (
      {
        styles = {},
        creatable,
        value = null,
        placeholder,
        id: initialId,
        onChange,
        onInputChange = noop,
        disabled,
        hasError,
        defaultValue,
        children,
        ...rest
      },
      ref
    ) => {
      const [isFilled, setIsFilled] = useState(!!defaultValue)
      const Component = rest.loadOptions
        ? getAsyncComponent(creatable)
        : ReactSelect
      const onInputChangeWrap = (val, options) => {
        const { action } = options
        setIsFilled(filled =>
          action === 'input-change' || action === 'menu-close' ? !!val : filled
        )
        return onInputChange(val, options)
      }
      const onChangeWrap = (val, options = {}) => {
        const { action } = options
        setIsFilled(action !== 'cleared')
        return onChange(val, options)
      }
      const id = initialId || toId(placeholder)
      return (
        <Wrapper ref={ref} hasError={hasError} isFilled={isFilled || !!value}>
          <Component
            components={{ DropdownIndicator }}
            {...rest}
            onChange={onChangeWrap}
            onInputChange={onInputChangeWrap}
            defaultValue={defaultValue}
            placeholder=""
            inputId={id}
            value={value}
            styles={getStyles(styles)}
            isDisabled={disabled}
          />
          {placeholder && (
            <Label x={15} htmlFor={id}>
              {children} {placeholder}
            </Label>
          )}
        </Wrapper>
      )
    }
  )
)

Dropdown.displayName = 'Dropdown'

export default Dropdown

const findValue = (options = [], value) =>
  options.find(option => equals(option.value, value))

export const FieldDropdown = React.memo(
  ({ name, onChange = noop, options, isDisabled, ...rest }) => {
    const formik = useFormikContext()
    const [field, meta] = useField(name)
    const fieldRef = useRef()
    useFocusOnError({ fieldRef, name })
    const { onBlur, ...fields } = field
    const hasError = !!meta.error && meta.touched
    const onBlurWrapper = (...args) => {
      const touched = { ...formik.touched }
      touched[field.name] = true
      formik.setTouched(touched)
      onBlur(...args)
    }
    return (
      <>
        <Dropdown
          ref={fieldRef}
          onBlur={onBlurWrapper}
          {...fields}
          hasError={hasError}
          options={options}
          value={findValue(options, field.value)}
          onChange={option => {
            onChange(option.value)
            formik.setFieldValue(field.name, option.value)
          }}
          disabled={isDisabled}
          {...rest}
        />
        <Error name={name} />
      </>
    )
  }
)

FieldDropdown.displayName = 'FieldDropdown'
