import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { append, propEq, reject, find } from 'ramda'
import toBoolean from 'utils/to-boolean'
import formatName from 'utils/format-name'
import { getImageUrl } from 'utils/image'
import { createModal, useModalize } from 'components/modalize'
import { Input } from 'components/form'
import { Button } from 'components/button'
import { Avatar } from 'components/avatar'
import useCompany from 'hooks/use-company'
import Icon from 'components/icon'
import VerifiedBadge from 'components/verified-badge'
import isVerified from 'utils/is-verified'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-height: 95vh;
`

const ModalClose = styled.div`
  text-align: right;
  padding-right: 20px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const Title = styled.h4`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.medium};
  text-align: center;
  width: 100%;
  padding: 20px 20px 30px;
  border-bottom: 2px solid ${colors.grey};
`

const ScrollWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const ItemWrapper = styled.div`
  height: 90px;
  width: 100%;
  border-bottom: 1px solid ${colors.grey};
  display: flex;
  align-items: center;
`

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const Name = styled.p`
  font-size: 17px;
  font-weight: ${fontWeights.medium};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 145px;

  ${props =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`

const CheckBox = styled(Input)`
  margin-right: 28px;
  flex-shrink: 0;
`

const AddButton = styled(Button)`
  margin: 40px auto 20px;
  flex-shrink: 0;
`

const isChecked = (userId, selectedUsers) =>
  toBoolean(find(propEq('id', userId))(selectedUsers))

const isDisabled = (userId, disabledUsers) =>
  toBoolean(find(propEq('id', userId))(disabledUsers))

const User = ({ user, toggleSelected, selectedUsers, disabledUsers }) => {
  const disabled = isDisabled(user.id, disabledUsers)
  const company = useCompany(user.company_id)
  return (
    <ItemWrapper onClick={() => toggleSelected(user)}>
      <Avatar
        location={getImageUrl(user.avatar?.aws_s3_key, {
          width: 46,
          height: 46
        })}
        shadow
        opacity={disabled ? 0.6 : 1}
        width={46}
        height={46}
        left={18}
        right={13}
      />
      <NameWrapper>
        <Name disabled={disabled}>{formatName(user)}</Name>
        <VerifiedBadge
          type="id"
          width={24}
          height={18}
          left={5}
          bottom={-3}
          verified={isVerified(user, company)}
        />
      </NameWrapper>
      <CheckBox
        onChange={() => toggleSelected(user)}
        checked={isChecked(user.id, selectedUsers)}
        disabled={disabled}
        round
        type="checkbox"
      />
    </ItemWrapper>
  )
}

const UserPicker = ({
  title,
  maxSelect,
  allUsers,
  activeUsers,
  disabledUsers = [],
  setActiveUsers,
  buttonLabel
}) => {
  const { close } = useModalize()
  const { t } = useTranslation()
  const [selectedUsers, setSelectedUsers] = useState(activeUsers)

  const toggleSelected = user => {
    if (isDisabled(user.id, disabledUsers)) return
    if (isChecked(user.id, selectedUsers)) {
      setSelectedUsers(reject(propEq('id', user.id), selectedUsers))
      return
    }
    if (!maxSelect || selectedUsers.length < maxSelect) {
      setSelectedUsers(append(user, selectedUsers))
    }
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <Title>{title}</Title>
      <ScrollWrapper>
        {allUsers.map(user => (
          <User
            key={`user-${user.id}`}
            user={user}
            toggleSelected={toggleSelected}
            selectedUsers={selectedUsers}
            disabledUsers={disabledUsers}
          />
        ))}
      </ScrollWrapper>
      <AddButton
        theme="primary"
        width={150}
        onClick={() => {
          setActiveUsers(selectedUsers)
          close()
        }}
        type="button"
        disabled={selectedUsers.length < 1}
      >
        {buttonLabel || t('b.listing.edit.add.button', 'Add')}
      </AddButton>
    </Wrapper>
  )
}

export const showUserPicker = createModal(UserPicker)
