import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { colors, fontSizes } from 'styles'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import 'utils/validation'
import { Button } from 'components/button'
import { PhoneInput } from 'components/form'

const Title = styled.span`
  font-size: ${fontSizes.large};
  font-weight: bold;
  color: ${colors.regular};
  text-align: center;
`

const Description = styled.span`
  font-size: ${fontSizes.regular};
  color: ${colors.regular};
  text-align: center;
  padding: 30px 0;
`

const PhoneForm = styled(Form)`
  width: 100%;
  overflow: hidden;
  text-align: center;
`

const Container = styled.div`
  > div:last-child {
    margin-bottom: 30px;
  }
  > div + p {
    margin-bottom: 13px;
  }
`

const validationSchema = Yup.object().shape({
  phone: Yup.string().phone().required()
})

const ShareByPhone = ({ listingId, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSend = async (values, { setSubmitting }) => {
    await dispatch.listing.sendTextInvite({
      listingId: listingId,
      phoneNumber: values.phone
    })
    setSubmitting(false)
    onClose()
  }

  return (
    <>
      <Title>
        {t('l.listing.view.share_phone_modal.title', 'Invite Contacts!')}
      </Title>
      <Description>
        {t(
          'l.listing.view.share_phone_modal_description.text',
          'Invite potential renters by sharing your listing and connecting them with liv.rent'
        )}
      </Description>
      <Formik
        initialValues={{ phone: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSend}
      >
        {({ values, isSubmitting, handleChange }) => (
          <PhoneForm>
            <Container>
              <PhoneInput
                name="phone"
                placeholder={t(
                  'l.listing.view.share_phone_modal_placeholder.text',
                  'Enter a phone number'
                )}
                onChange={handleChange('phone')}
                value={values.phone}
                autoFocus
              />
            </Container>
            <Button
              type="submit"
              name="submit"
              theme="primary"
              size="medium"
              width={160}
              disabled={isSubmitting}
            >
              {t(
                'l.listing.view.share_phone_modal_invite.button',
                'Invite Renter'
              )}
            </Button>
          </PhoneForm>
        )}
      </Formik>
    </>
  )
}

export default ShareByPhone
