import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import Button from './button'

const StickyButton = styled(Button)`
  text-align: center;
  font-size: ${fontSizes.regular};
  color: ${colors.white};
  display: block;
  width: 100%;
  position: relative;
  height: 56px;
  font-weight: ${fontWeights.bold};
  background-color: ${colors.pink};

  &:not(:disabled):not([disabled]):hover {
    background-color: ${colors.lightPink};
  }
`

export default StickyButton
