import React from 'react'
import moment from 'moment'
import { colors } from 'styles'
import {
  Box,
  ShadowedBox,
  DateText,
  TimeText,
  BoldText,
  DateWrapper
} from './elements'

const ShowingIcon = props => {
  const month = moment(props.info.meeting_at).format('MMM')
  const day = moment(props.info.meeting_at).format('DD')
  const time = moment(props.info.meeting_at).format('h:mm a')
  return (
    <ShadowedBox shadow={props.shadow} height={80} width={80}>
      <Box height={55} color={props.color || colors.secondary} top={5}>
        <DateWrapper>
          <DateText>{month}</DateText>
          <BoldText>{day}</BoldText>
        </DateWrapper>
      </Box>
      <TimeText color={props.color}>{time}</TimeText>
    </ShadowedBox>
  )
}

export default ShowingIcon
