import eventManager from './utils/eventManager'
import ACTION from './utils/action'
import TYPE from './utils/type'

let containers = new Map()
let latestInstance = null
let queue = []

function isAnyContainerMounted() {
  return containers.size > 0
}

function generateToastId() {
  return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10)
}

function getToast(toastId, { containerId = latestInstance }) {
  const container = containers.get(containerId)
  if (!container) return null
  const toast = container.current[toastId]
  if (typeof toast === 'undefined') return null

  return toast
}

function getToastId(options) {
  if (
    options &&
    (typeof options.toastId === 'string' ||
      (typeof options.toastId === 'number' && !isNaN(options.toastId)))
  ) {
    return options.toastId
  }

  return generateToastId()
}

function mergeOptions(options, type) {
  return {
    iconId: 'square_check',
    ...options,
    toastId: getToastId(options),
    type
  }
}

function dispatchToast(content, options) {
  if (isAnyContainerMounted()) {
    eventManager.emit(ACTION.SHOW, content, options)
  } else {
    queue.push({ action: ACTION.SHOW, content, options })
  }
  return options.toastId
}

const toast = (content, options = {}) =>
  dispatchToast(content, mergeOptions(options, options.type || TYPE.DEFAULT))

toast.progress = options => dispatchToast('', mergeOptions(options, 'progress'))
toast.update = (toastId, options = {}) => {
  // if you call toast and toast.update directly nothing will be displayed
  // this is why I defered the update
  setTimeout(() => {
    const oldToast = getToast(toastId, options)
    if (oldToast) {
      const { options: oldOptions, content: oldContent } = oldToast

      const nextOptions = {
        ...oldOptions,
        ...options,
        toastId: options.toastId || toastId
      }

      if (!options.toastId || options.toastId === toastId) {
        nextOptions.updateId = generateToastId()
      } else {
        nextOptions.staleToastId = toastId
      }

      const content =
        typeof nextOptions.render !== 'undefined'
          ? nextOptions.render
          : oldContent
      delete nextOptions.render
      dispatchToast(content, nextOptions)
    }
  }, 0)
}

toast.remove = (id = null) =>
  isAnyContainerMounted() && eventManager.emit(ACTION.CLEAR, id)

eventManager
  .subscribe(ACTION.DID_MOUNT, (containerId, collection) => {
    latestInstance = containerId
    containers.set(containerId, collection)

    queue.forEach(item => {
      eventManager.emit(item.action, item.content, item.options)
    })

    queue = []
  })
  .subscribe(ACTION.WILL_UNMOUNT, containerId => {
    if (containerId) containers.delete(containerId)
    else containers.clear()
    if (containers.size === 0) {
      eventManager.unsubscribe(ACTION.SHOW).off(ACTION.CLEAR)
    }
  })

export default toast
