import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { colors, breakpoints, fontWeights } from 'styles'
import { pipe, forEach, any, prop, __ } from 'ramda'
import { useTranslation, Trans } from 'react-i18next'
import { isTruthy, findFileByTag } from 'utils'
import { select } from 'store'
import { Formik, Form } from 'formik'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import PageTitle from 'components/page-title'
import Panel from 'components/panel'
import Page from 'components/page'
import AreaTitle from 'components/area-title'
import { showVerifyModal } from 'components/verify'
import {
  Label,
  Row,
  Group,
  FieldInput,
  FieldDropdown,
  FieldTextarea,
  FieldSwitchToggle,
  PhoneInput
} from 'components/form'
import ButtonBar from 'features/Profile/button-bar'
import VerifiedBadge from 'components/verified-badge'
import StateDropdown from 'components/state-dropdown'
import { Button } from 'components/button'
import { getFormattedCountries } from 'utils/address'
import useIsMounted from 'hooks/use-is-mounted'
import ContentWrapper from 'components/content-wrapper'

const AreaWrapper = styled.div``

const ActionButton = styled(Button)`
  padding: 0 29px;
  letter-spacing: 0.25px;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const Description = styled(Label)`
  margin-bottom: 20px;
`

const OffsetButton = styled(Button)`
  position: relative;
  float: right;
  margin-top: 30px;

  &:disabled {
    border: 2px solid ${colors.darkGrey};
    background-color: ${colors.white};
    color: ${colors.darkGrey};
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 5px;
  }
`
const PhoneToggleContainer = styled(Group)`
  display: flex;
  align-items: center;
`

const PhoneRow = styled(Row)`
  align-items: flex-start;
  > div:first-of-type {
    padding-top: 6px;
  }
`

const ToggleLabel = styled(Label)`
  margin-right: 15px;
  margin-bottom: 0;
  font-weight: ${fontWeights.medium};
  padding-left: 0 !important;
`

const EditLandlordProfile = props => {
  const { t } = useTranslation()
  const data = useSelector(select.profile.editLandlordProfile)
  const verifyBusiness = useRef()
  const { countries } = useBasic(['countries'])
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const isMounted = useIsMounted()
  const shouldVerify = props.location?.data?.verifyBusiness
  useEffect(() => {
    if (!verifyBusiness.current || !shouldVerify) return
    verifyBusiness.current.scrollIntoView()
  }, [verifyBusiness, shouldVerify])
  const colSize = displayMobile ? 12 : 6
  const session = useSelector(prop('session')) || {}
  const secureFiles = session.user_secure_files

  data['identification_type'] = findFileByTag(
    'verification_id',
    secureFiles
  )?.document_type

  const formattedCountries = getFormattedCountries(countries)

  if (!data.id) return null
  const PILL_THEME = {
    upload: 'primary',
    verify: 'primary',
    pending: 'primary',
    verified: 'neutral'
  }

  const PILL_NAME = {
    upload: t('b.profile.view.upload.text', 'Upload'),
    verify: t('b.profile.view.verify.text', 'Verify'),
    pending: t('b.profile.view.verifying.text', 'Verifying'),
    verified: t('b.profile.view.verified.text', 'Verified')
  }

  const validate = values => {
    let errors = {}
    const companyAdminInfo = [
      'company_address',
      'company_name',
      'company_city',
      'company_state',
      'company_zip'
    ]
    const companyPersonalInfo = [
      'company_address',
      'company_city',
      'company_state',
      'company_zip'
    ]
    let companyInfo = []
    if (values.account_type === 'business') {
      companyInfo = companyAdminInfo
    } else {
      companyInfo = companyPersonalInfo
    }

    const hasValue = any(pipe(prop(__, values), isTruthy), companyInfo)

    forEach(info => {
      if (!values[info] && hasValue) {
        errors[info] = 'Required'
      }
    }, companyInfo)
    return errors
  }
  const formOptions = () => ({
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      last_name: Yup.string()
        .matches(
          /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
          t(
            'b.profile.name.special_characters.error',
            'Cannot include special characters'
          )
        )
        .required(),
      preferred_name: Yup.string().matches(
        /^[ A-Za-z-_\u3000\u3400-\u4DBF\u4E00-\u9FFF]*$/,
        t(
          'b.profile.name.special_characters.error',
          'Cannot include special characters'
        )
      ),
      username: Yup.string().email().required(),
      company_email: Yup.string().email(),
      company_phone_number: Yup.string().phone(),
      company_unit_number: Yup.string(),
      company_address: Yup.string(),
      company_name: Yup.string(),
      company_city: Yup.string(),
      company_state: Yup.string(),
      company_zip: Yup.string(),
      work_history_year_started: Yup.number().positive().min(1000)
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const response = await dispatch.profile.save(values)
      if (isMounted.current) {
        setSubmitting(false)
      }
      if (response.ok) {
        props.history.push('/profile')
      }
    }
  })

  const renderBusinessIdentificationSection = () =>
    data.account_type === 'business' ? (
      <AreaWrapper>
        <AreaTitle ref={verifyBusiness}>
          {t(
            'l.profile.edit.business_license.subtitle',
            'Verify Your Business Licence'
          )}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <Description>
            <Trans i18nKey="l.profile.edit.verify_business.description">
              Become <Bold>Verified</Bold>
              <VerifiedBadge
                type="id"
                verified
                width={28}
                height={19}
                right={5}
                bottom={-4}
                left={5}
              />
              by providing your Business Licence Number or your Brokerage
              Licence
            </Trans>
          </Description>
          <Row>
            <Group col={colSize}>
              <FieldInput
                name="business_licence"
                placeholder={
                  t(
                    'l.profile.edit.identification_document.business_license',
                    'Business Licence No.'
                  ) + ':'
                }
                disabled={isDisabled(data.company_verified_state)}
              />
            </Group>
            {data.company_verified_state ? (
              <Group col={colSize}>
                <OffsetButton
                  name="license_pill"
                  theme={PILL_THEME[data.company_verified_state]}
                  width={125}
                  disabled={isDisabled(data.company_verified_state)}
                >
                  {PILL_NAME[data.company_verified_state]}
                </OffsetButton>
              </Group>
            ) : (
              <Group col={colSize} />
            )}
          </Row>
        </StyledPanel>
      </AreaWrapper>
    ) : null

  const renderWorkHistorySection = () =>
    data.account_type === 'business' ? (
      <AreaWrapper>
        <AreaTitle>
          {t('l.profile.edit.work_history.subtitle', 'Enter Your Work History')}
        </AreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <Row>
            <Group col={colSize}>
              <FieldInput
                name="work_history_year_started"
                placeholder={
                  t('l.profile.edit.year_started.label', 'Year Started') + ':'
                }
              />
            </Group>
            <Group col={colSize}>
              <FieldDropdown
                id="work_history_properties_managed"
                name="work_history_properties_managed"
                placeholder={
                  t(
                    'l.profile.edit.properties_managed.label',
                    'Properties Managed'
                  ) + ':'
                }
                options={[
                  { value: '0-9', label: '0-9' },
                  { value: '10-49', label: '10-49' },
                  { value: '50-99', label: '50-99' },
                  { value: '100+', label: '100+' }
                ]}
              />
            </Group>
          </Row>
        </StyledPanel>
      </AreaWrapper>
    ) : null

  const provinceDropDown = values => (
    <StateDropdown
      id="company_state"
      name="company_state"
      country={values.company_country_code}
      countries={countries}
      col={colSize}
    />
  )

  const countryDropDown = setFieldValue => (
    <Group col={colSize}>
      <FieldDropdown
        id="company_country_code"
        name="company_country_code"
        options={formattedCountries}
        placeholder={t('r.profile.edit.country.label', 'Country') + ':'}
        onChange={() => setFieldValue('company_state', '')}
      />
    </Group>
  )

  const companyZip = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_zip"
        placeholder={t('l.profile.edit.postal_code.label', 'Postal Code') + ':'}
      />
    </Group>
  )

  const companyCity = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_city"
        placeholder={t('l.profile.edit.city.label', 'City') + ':'}
      />
    </Group>
  )

  const companyAddress = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_address"
        placeholder={
          t('l.profile.edit.street_address.label', 'Street Address') + ':'
        }
      />
    </Group>
  )

  const companyUnitNumber = () => (
    <Group col={colSize}>
      <FieldInput
        name="company_unit_number"
        placeholder={t('l.profile.edit.unit_number.label', 'Unit Number') + ':'}
      />
    </Group>
  )

  const renderAddressSection = (setFieldValue, values) =>
    data.account_type === 'business' ? (
      <AreaWrapper>
        <AreaTitle>{getAreaTitleText()}</AreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <PhoneRow>
            <Group col={colSize}>
              <FieldInput
                name="company_name"
                placeholder={
                  t('l.profile.edit.company_name.label', 'Company Name') + ':'
                }
              />
            </Group>
            <Group col={colSize}>
              <PhoneInput
                name="company_phone_number"
                countryCodeAlpha={data.company_phone_country_code_alpha}
                countryCode={
                  data.company_phone_country_code_alpha &&
                  data.company_phone_country_code
                    ? {
                        txt_id: data.company_phone_country_code_alpha,
                        calling_code: data.company_phone_country_code
                      }
                    : undefined
                }
                placeholder={
                  t(
                    'l.profile.edit.company_phone_number.label',
                    'Phone Number'
                  ) + ':'
                }
                onCountryCodeChange={code => {
                  setFieldValue('company_phone_country_code', code.calling_code)
                  setFieldValue('company_phone_country_code_alpha', code.txt_id)
                }}
              />
            </Group>
          </PhoneRow>
          <Row>
            <Group col={colSize}>
              <FieldInput
                name="company_email"
                placeholder={
                  t('l.profile.edit.company_email.label', 'Email') + ':'
                }
              />
            </Group>
            {companyAddress()}
          </Row>
          <Row>
            {companyUnitNumber()}
            {companyCity()}
          </Row>
          <Row>
            {countryDropDown(setFieldValue)}
            {provinceDropDown(values)}
          </Row>
          <Row>{companyZip()}</Row>
        </StyledPanel>
      </AreaWrapper>
    ) : (
      <AreaWrapper>
        <AreaTitle>{getAreaTitleText()}</AreaTitle>
        <Panel hSpace={30} vSpace={30}>
          <Row>
            {companyUnitNumber()}
            {companyAddress()}
          </Row>
          <Row>
            {companyCity()}
            {companyZip()}
          </Row>
          <Row>
            {countryDropDown(setFieldValue)}
            {provinceDropDown(values)}
          </Row>
        </Panel>
      </AreaWrapper>
    )

  const getAreaTitleText = () =>
    data.account_type === 'business'
      ? t('l.profile.edit.company_address.text', 'Company Information')
      : t('l.profile.edit.current_address.text', 'Enter Your Current Address')

  const isDisabled = field => {
    return field === 'pending' || field === 'verified'
  }
  return (
    <Page>
      <Helmet>
        <title>{t('l.profile.edit.edit_profile.title', 'Edit Profile')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Formik {...formOptions(props)} initialValues={data} validate={validate}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="flex-full">
            <ContentWrapper className="mb-50">
              <PageTitle>
                {t(
                  'l.profile.edit.basic_information.title',
                  'Basic Information'
                )}
              </PageTitle>
              <AreaTitle>
                {t(
                  'l.profile.edit.basic_information.subtitle',
                  'Enter Your Basic Information'
                )}
              </AreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="first_name"
                      disabled={data.identity_verified_state === 'verified'}
                      placeholder={
                        t(
                          'l.profile.edit.legal_first_and_middle_name.label',
                          'Legal First & Middle Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      name="last_name"
                      disabled={data.identity_verified_state === 'verified'}
                      placeholder={
                        t(
                          'l.profile.edit.legal_last_name.label',
                          'Legal Last Name'
                        ) + '*:'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <FieldInput
                      name="preferred_name"
                      placeholder={
                        t(
                          'l.profile.edit.preferred_name.label',
                          'Preferred Name'
                        ) + ':'
                      }
                    />
                  </Group>
                  <Group col={colSize}>
                    <FieldInput
                      name="username"
                      placeholder={
                        t('l.profile.edit.email.label', 'Email') + ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <Group col={colSize}>
                    <PhoneInput
                      name="phone"
                      disabled
                      value={values.phone_number}
                      countryCodeAlpha={data.phone_company_country_code_alpha}
                      placeholder={
                        t('l.profile.edit.phone_number.label', 'Phone Number') +
                        ':'
                      }
                    />
                  </Group>
                </Row>
                <Row>
                  <PhoneToggleContainer col={colSize}>
                    <ToggleLabel htmlFor="hide_phone_number">
                      {t(
                        'l.profile.edit.hide_phone_number.label',
                        'Hide phone number from public'
                      )}
                    </ToggleLabel>
                    <FieldSwitchToggle
                      name="hide_phone_number"
                      id="hide_phone_number"
                    />
                  </PhoneToggleContainer>
                </Row>
              </StyledPanel>
              <AreaTitle>
                {t(
                  'l.profile.edit.identification_document.subtitle',
                  'Verify Your Identity'
                )}
              </AreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <Description>
                  <Trans i18nKey="b.profile.edit.verify_id.description">
                    Become <Bold>Verified</Bold>
                    <VerifiedBadge
                      verified
                      width={28}
                      height={19}
                      right={5}
                      bottom={-4}
                      left={5}
                    />
                    by providing a government-issued photo ID. Rest assured that
                    your ID will never be shared with other people on liv.rent.
                  </Trans>
                </Description>
                <Row>
                  <ActionButton
                    name="license_pill"
                    theme={
                      PILL_THEME[values.identity_verified_state] ||
                      PILL_THEME.upload
                    }
                    width={125}
                    left="auto"
                    disabled={isDisabled(data.identity_verified_state)}
                    onClick={() => {
                      showVerifyModal({ data })
                    }}
                  >
                    {PILL_NAME[data.identity_verified_state] ||
                      PILL_NAME.upload}
                  </ActionButton>
                </Row>
              </StyledPanel>
              <AreaTitle>
                {t('l.profile.edit.my_story.subtitle', 'Enter Your Story')}
              </AreaTitle>
              <StyledPanel hSpace={30} vSpace={30}>
                <FieldTextarea
                  name="about_me"
                  maxLength={260}
                  height={displayMobile ? 230 : 130}
                  placeholder={t(
                    'l.profile.edit.my_story.placeholder',
                    'Tell us a little about yourself...'
                  )}
                />
              </StyledPanel>
              {data.user_role === 'administrator' && (
                <>
                  {renderAddressSection(setFieldValue, values)}
                  {renderBusinessIdentificationSection()}
                </>
              )}
              {renderWorkHistorySection()}
            </ContentWrapper>
            <ButtonBar sticky handleBack={props.history.goBack}>
              <Button
                type="submit"
                theme="primary"
                width={150}
                left="auto"
                isSubmitting={isSubmitting}
              >
                {t('l.profile.edit.save.label', 'Save')}
              </Button>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </Page>
  )
}

export default React.memo(EditLandlordProfile)
