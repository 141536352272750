import React from 'react'
import styled from 'styled-components'
import useMediaQuery from 'hooks/use-media-query'
import { Link } from 'react-router-dom'
import { colors, fontSizes, fontWeights } from 'styles'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.white};
  z-index: 100;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);

  ${props =>
    props.displayBlock &&
    `
    display: block;
    position: static;
  `}
`

const Button = styled(Link)`
  background: ${colors.secondary};
  color: ${colors.white};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 20px;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 10px 20px;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;

  :hover {
    opacity: 0.7;
  }
`

const MobileFooterButton = ({
  to,
  onClick,
  breakpoint,
  displayBlock,
  children
}) => {
  const displayButton = useMediaQuery(`(max-width: ${breakpoint})`)

  if (!displayButton) return null

  return (
    <Wrapper displayBlock={displayBlock}>
      <Button as={onClick ? 'button' : Link} to={to} onClick={onClick}>
        {children}
      </Button>
    </Wrapper>
  )
}

export default MobileFooterButton
