import { createSelector } from 'reselect'
import { flip, prop } from 'ramda'

const formatAgremeent = raw => ({
  id: raw.txt_id,
  version: raw.version,
  versionType: raw.version_type,
  title: raw.title,
  description: raw.content,
  gracePeriodDate: raw.grace_period_date
})

const agreementSelector = createSelector(
  prop('currentAgreement'),
  flip(prop('agreementId')),
  (agreement, agreementId) =>
    agreement && agreement.txt_id === agreementId
      ? formatAgremeent(agreement)
      : null
)

export default agreementSelector
