import styled from 'styled-components'
import Panel from 'components/panel'
import { breakpoints, fontWeights, colors } from 'styles'

export const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

export const PanelHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: flex-end;

  &:not(:first-child) {
    height: 110px;
  }

  a:only-child {
    margin-left: auto;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    height: 30px;
    margin: 0 10px;
  }
`

export const PanelContainer = styled(Panel)`
  > :first-child {
    padding-top: 0px;
  }
  > :last-child {
    padding-bottom: 0px;
    border-bottom-width: 0px;
    > div {
      border: none;
    }
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0;
    border: none;
  }
`

export const SectionHeading = styled.h1`
  font-size: 25px;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  line-height: 34px;
  margin: 0 0 50px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 30px;
  }
`

export const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 20px;
    ${props =>
      props.bordered &&
      `
      padding-bottom: 20px;
      border-bottom: 1px solid ${colors.grey};
    `}
  }
`
