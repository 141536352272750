import React from 'react'
import Preferences from 'features/Settings/Preferences'
import ProfileDiscovery from 'features/Settings/ProfileDiscovery'
import useRole from 'hooks/use-role'

const PreferencesTab = ({ setFieldValue, values }) => {
  const role = useRole()

  return (
    <>
      <Preferences setFieldValue={setFieldValue} values={values} />
      {role === 'tenant' && <ProfileDiscovery />}
    </>
  )
}

export default PreferencesTab
