import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Switch, Route } from 'react-router'
import PaymentSettingsDashboard from './payment-settings-dashboard'
import AddUnionPay from './add-unionpay'
import ViewUnionPay from './view-unionpay'
import ViewStripe from './view-stripe'

const PaymentSettings = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {t('l.settings.view.payment_settings.title', 'Payment Settings')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Switch>
        <Route
          exact
          path="/settings/payment-settings/unionpay/add"
          component={AddUnionPay}
        />
        <Route
          exact
          path="/settings/payment-settings/unionpay/view"
          component={ViewUnionPay}
        />
        <Route
          exact
          path="/settings/payment-settings/stripe/view"
          component={ViewStripe}
        />
        <Route
          exact
          path="/settings/payment-settings"
          component={PaymentSettingsDashboard}
        />
      </Switch>
    </>
  )
}

export default PaymentSettings
