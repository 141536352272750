import React from 'react'
import styled from 'styled-components'
import { colors, fontSizes, fontWeights } from 'styles'
import { toUnit } from 'utils/styles'

const Wrapper = styled.button`
  border-radius: ${props => props.radius || 2}px;
  appearance: none;
  letter-spacing: -0.39px;
  height: ${props => toUnit(props.height || '45px')};
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  font-weight: ${fontWeights.medium};
  border: none;
  width: ${props => toUnit(props.width || 'auto')};
  outline: none;
  margin-left: ${props => (isNaN(props.left) ? props.left : `${props.left}px`)};
  margin-right: ${props =>
    isNaN(props.right) ? props.right : `${props.right}px`};
  :active {
    opacity: 0.3;
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.theme === 'default' &&
    `
    background-color: ${colors.white};
    color: ${colors.regular};
  `}

  ${props =>
    props.theme === 'default' &&
    !props.disabled &&
    `
    &:hover, &.selected {
      background-color: ${colors.secondary};
      color: ${colors.white};
    }
  `}

  ${props =>
    props.theme === 'primary' &&
    `
    background-color: ${colors.secondary};
    color: ${colors.white};
    border: 1px solid transparent;
  `}

  ${props =>
    props.theme === 'primary' &&
    !props.disabled &&
    `
    &:hover, &.selected {
      background-color: ${colors.white};
      color: ${colors.secondary};
      border: 1px solid ${colors.secondary};

      > svg path {
        fill: ${colors.secondary};
      }
    }
  `}

${props =>
  props.theme === 'primary' &&
  props.inversed &&
  `
    background-color: ${colors.white};
    color: ${colors.secondary};
    border: 2px solid ${colors.secondary};

    &:hover {
      background-color: ${colors.secondary};
      color: ${colors.white};
      border: 2px solid ${colors.secondary};
    }
  `}

  ${props =>
    props.theme === 'sane' &&
    `
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    border: 2px solid transparent;
  `}

  ${props =>
    props.theme === 'sane' &&
    !props.disabled &&
    `
    &:hover, &.selected {
      background-color: ${colors.white};
      color: ${colors.darkGrey};
      border: 2px solid ${colors.darkGrey};

      > svg path {
        fill: ${colors.darkGrey};
      }
    }
  `}

  ${props =>
    props.theme === 'sane' &&
    props.inversed &&
    `
    background-color: ${colors.white};
    color: ${colors.darkGrey};
    border: 2px solid ${colors.darkGrey};
  `}

  ${props =>
    props.theme === 'sane' &&
    props.inversed &&
    !props.disabled &&
    `
    &:hover, &.selected {
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border: none;

      > svg path {
        fill: ${colors.white};
      }
    }
  `}

  ${props =>
    props.theme === 'dark' &&
    `
    background-color: ${colors.darkGrey};
    color: ${colors.white};
  `}

  ${props =>
    props.theme === 'dark' &&
    !props.disabled &&
    `
    &:hover, &.selected {
      background-color: ${colors.mediumGrey};
      color: ${colors.white};

      > svg path {
        fill: ${colors.white};
      }
    }
  `}

  ${props =>
    props.theme === 'danger' &&
    `
    background-color: ${colors.pink};
    color: ${colors.white};

    > svg path {
        fill: ${colors.white};
    }

    &:not(:disabled) {
      &:hover, &.selected {
        background-color: ${colors.white};
        color: ${colors.pink};
        border: 1px solid ${colors.pink};

        > svg path {
          fill: ${colors.pink};
        }
      }
    }
  `}

  ${props =>
    props.theme === 'neutral' &&
    `
    background-color: ${colors.white};
    color: ${colors.regular};
    border: 2px solid ${colors.regular};
    font-weight: 700;

    > svg path {
        fill: ${colors.regular};
      }
  `}

${props =>
  props.theme === 'ghost' &&
  `
    background-color: #fbfafa;
    color: #626262;
    border: 1px solid #eae9e9;
    font-weight: ${fontWeights.medium};

    > svg path {
        fill: ${colors.regular};
      }
  `}

  ${props =>
    props.size === 'large' &&
    `
    font-size: ${fontSizes.regular};
    height: 68px;
    line-height: 22px;
  `}

  ${props =>
    props.size === 'small' &&
    `
    font-size: 11px;
    height: 40px;
    line-height: 11px;
    letter-spacing: 1.73px;
  `}

  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    cursor: default;
  `}

  ${({ customStyle }) => customStyle}
`

const Button = ({ children, ...rest }) => (
  <Wrapper {...rest}>{children}</Wrapper>
)

Button.defaultProps = {
  theme: 'default',
  type: 'button',
  radius: '0',
  left: 0,
  right: 0
}

export default React.memo(Button)
