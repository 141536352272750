import { createBuilding } from 'ports'

const BUILDING_TYPE = {
  condo: 'highrise',
  house: 'house',
  townhouse: 'townhouse'
}

const building = {
  state: null,
  effects: dispatch => ({
    create: async payload => {
      const data = {
        body: {
          building_type_txt_id: BUILDING_TYPE[payload.type],
          country_code: payload.country,
          zip: payload.zip,
          name: payload.building_name,
          street_name: payload.address,
          street_number: payload.street_number,
          city: payload.city,
          state: payload.province
        }
      }
      try {
        const { response, body } = await createBuilding(data)
        if (!response.ok) {
          throw Error(body.message || response.statusText)
        }
        return { body }
      } catch (error) {
        return { error }
      }
    }
  })
}

export default building
