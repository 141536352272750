import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty, path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { ContractView } from 'pages/Contract'
import PageTitle from 'components/page-title'
import Breadcrumbs from 'components/breadcrumbs'
import TenantDashboardHome from '../TenantDashboardHome'
import TenantListingDetail from '../TenantListingDetail'
import TenantChat from '../TenantChat'
import TenantPayment from '../TenantPayment'
import TenantPayments from '../TenantPayments'
import ResourcesContractView from '../TenantResources/resources-contract-view'
import TenantsResources from '../TenantResources'

const TenantDashboard = ({
  match: {
    params: { listingId }
  }
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contract = useSelector(path(['listing', listingId, 'contract'])) || {}
  const listingAddress = isEmpty(contract)
    ? ''
    : `${contract.unit_number ? `${contract.unit_number} - ` : ''}${
        contract.address
      }`

  useEffect(() => {
    dispatch.listing.getContract(listingId)
  }, [dispatch.listing, listingId])

  if (isEmpty(contract)) return null

  const ContractBreadcrumbs = () => {
    const breadcrumbs = [
      {
        to: '/tenants',
        label: t('l.tenants.view.all_tenants.link', 'All Tenants')
      },
      {
        to: `/tenant/${listingId}`,
        label: t('l.tenants.view.dashboard.link', 'Dashboard')
      },
      { to: null, label: t('l.tenants.view.contract.link', 'Contract') }
    ]
    return <Breadcrumbs items={breadcrumbs} />
  }

  return (
    <>
      <PageTitle>{listingAddress}</PageTitle>
      <Switch>
        <Route
          exact
          path="/tenant/:listingId"
          render={props => (
            <TenantDashboardHome contract={contract} {...props} />
          )}
        />
        <Route
          exact
          path="/tenant/:listingId/details"
          component={TenantListingDetail}
        />
        <Route
          exact
          path="/tenant/:listingId/chat/:timelineId?"
          component={TenantChat}
        />
        <Route
          exact
          path="/tenant/:listingId/payments/:paymentId"
          component={TenantPayment}
        />
        <Route
          exact
          path="/tenant/:listingId/payments"
          component={TenantPayments}
        />
        <Route
          exact
          path="/tenant/:listingId/resources/contract-history/:contractId"
          component={ResourcesContractView}
        />
        <Route
          path="/tenant/:listingId/resources"
          component={TenantsResources}
        />
        <Route
          exact
          path="/tenant/:listingId/contract/:contractId"
          render={props => (
            <ContractView
              noTitle
              {...props}
              BackButtonComponent={ContractBreadcrumbs}
            />
          )}
        />
      </Switch>
    </>
  )
}

export default TenantDashboard
