import React from 'react'
import { breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'hooks/use-media-query'
import useBasic from 'hooks/use-basic'
import { getFormattedCountries } from 'utils/address'
import {
  Row,
  Group,
  FieldDropdown,
  FieldInput,
  FieldSwitchToggle
} from 'components/form'
import StateDropdown from 'components/state-dropdown'
import { StyledPanel, AreaTitle, StyledRow, StyledLabel } from '../elements'

const BillingAddressForm = ({ values, setFieldValue }) => {
  const { t } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)
  const colSize = displayMobile ? 12 : 6
  const { countries } = useBasic(['countries'])
  const formattedCountries = getFormattedCountries(countries)

  const countryDropDown = () => (
    <Group col={colSize}>
      <FieldDropdown
        id="country"
        name="country"
        options={formattedCountries}
        onChange={() => setFieldValue('state', '')}
        placeholder={t('r.settings.edit.country.label', 'Country') + '*:'}
      />
    </Group>
  )

  const provinceDropDown = () => (
    <StateDropdown
      name="state"
      col={colSize}
      country={values.country}
      countries={countries}
      required
    />
  )

  return (
    <>
      <AreaTitle>
        {t(
          'r.payment.edit.make_payment_billing_address.subtitle',
          'Billing Address'
        )}
      </AreaTitle>
      <StyledPanel hSpace={30} vSpace={30}>
        <Row>
          <Group col={colSize}>
            <FieldInput
              name="street1"
              placeholder={
                t(
                  'r.payment.edit.make_payment_street_address.label',
                  'Street address'
                ) + '*:'
              }
            />
          </Group>
          <Group col={colSize}>
            <FieldInput
              name="street2"
              htmlFor="creditcard"
              placeholder={
                t(
                  'r.payment.edit.make_payment_apt_suite_unit_number.label',
                  'Suite No. / Unit No.'
                ) + ':'
              }
            />
          </Group>
        </Row>
        <Row>
          <Group col={colSize}>
            <FieldInput
              name="city"
              placeholder={
                t('r.payment.edit.make_payment_city.label', 'City') + '*:'
              }
            />
          </Group>
          <Group col={colSize}>
            <FieldInput
              name="zip"
              placeholder={
                t('r.payment.edit.make_payment_zip.label', 'Postal code') + '*:'
              }
            />
          </Group>
        </Row>
        <Row>
          {countryDropDown()}
          {provinceDropDown()}
        </Row>
        <StyledRow top={10}>
          <StyledLabel>
            {t(
              'r.payment.edit.make_payment_save_address.label',
              'Save address for future'
            )}
          </StyledLabel>
          <FieldSwitchToggle name="save_billing_address" />
        </StyledRow>
      </StyledPanel>
    </>
  )
}

export default BillingAddressForm
