import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { breakpoints, colors } from 'styles'
import ChatSkeleton from 'features/Chat/basic-skeleton'
import Breadcrumbs from 'components/breadcrumbs'
import ListingInfoSmall from 'components/listing-info-small'
import MobileFooterButton from 'components/mobile-footer-button'

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 20px;
  border-bottom: 1px solid ${colors.grey};

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    display: none;
  }
`

const ListingSummaryWrapper = styled.div`
  padding: 30px 20px;
  border-bottom: 1px solid ${colors.grey};

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 20px 25px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
`

const ListingChat = props => {
  const { t } = useTranslation()
  const listing = props.listing

  const noTimelinesMessage =
    listing.verified_state_machine === 'approved' ? (
      t(
        'l.listing.view.no_inquiries_for_this_listing.text',
        'No inquiries for this listing yet.'
      )
    ) : (
      <>
        {t(
          'l.listing.view.no_inquiries_for_this_listing.text',
          'No inquiries for this listing yet.'
        )}
        <br />
        <Trans i18nKey="l.listing.view.verify_this_listing_to_attract_more.text">
          <Link to={`/listing/verify/${props.match.params.listingId}`}>
            Verify this listing
          </Link>{' '}
          to attract more renters!
        </Trans>
      </>
    )

  const notSelectedMessage = (
    <>
      {t(
        'l.listing.view.here_are_inquiries_for_listing.text',
        'Here are the inquiries for this listing.'
      )}
      <br />
      {t(
        'l.listing.view.select_chat_room_to_get_started.text',
        'Select a chat room to get started!'
      )}
    </>
  )

  const ChatDashboardHeader = () => {
    const breadcrumbs = [
      {
        to: '/listings',
        label: t('l.listing.view.all_listings.link', 'All Listings')
      },
      {
        to: `/listing/${props.match.params.listingId}`,
        label: t('l.listing.view.dashboard.link', 'Dashboard')
      },
      { to: null, label: t('l.listing.view.chats.link', 'Chats') }
    ]

    return (
      <HeaderWrapper>
        <BreadcrumbsWrapper>
          <Breadcrumbs items={breadcrumbs} />
        </BreadcrumbsWrapper>
        <ListingSummaryWrapper>
          <ListingInfoSmall listing={listing} />
        </ListingSummaryWrapper>
      </HeaderWrapper>
    )
  }

  const ChatFooterButton = () => {
    return (
      <MobileFooterButton
        to={`/listing/${props.match.params.listingId}`}
        breakpoint={breakpoints.ipadMiniMax}
        displayBlock
      >
        {t(
          'l.listing.view.back_to_listing_dashboard.button',
          'Back to Listing Dashboard'
        )}
      </MobileFooterButton>
    )
  }

  return (
    <ChatSkeleton
      {...props}
      basePath="listing"
      getTimelineSelector={(select, listingId) => state =>
        select.chat.listingTimelines(state, { listingId })}
      sideBarHeader={ChatDashboardHeader}
      chatFooterButton={ChatFooterButton}
      noTimelinesMessage={noTimelinesMessage}
      notSelectedMessage={notSelectedMessage}
    />
  )
}

export default ListingChat
