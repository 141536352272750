import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, fontSizes, fontWeights } from 'styles'
import formatName from 'utils/format-name'
import { getImageUrl } from 'utils/image'
import { createModal, useModalize } from 'components/modalize'
import { Avatar } from 'components/avatar'
import Icon from 'components/icon'
import { Textarea } from 'components/form'
import { Button } from 'components/button'

const Wrapper = styled.div`
  padding: 20px 30px;
  text-align: center;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding: 20px;
  }
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin: 10px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const UserName = styled.p`
  margin: 0px;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
`

const UserContainer = styled.div`
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SendButton = styled(Button)`
  padding: 10px 20px;
  margin: 30px 0 10px;
`

const ChatModal = ({ tenant, sendMessage }) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState()
  const { close } = useModalize()

  const handleClick = () => {
    sendMessage(message)
    close()
  }

  const userName = formatName(tenant)

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      <Title>
        {t('l.listings.view.suggested_tenants_chat_modal.title', 'Chat')}
      </Title>
      <UserContainer>
        <UserName>{userName}</UserName>
        <Avatar
          location={getImageUrl(tenant?.avatar?.aws_s3_key, {
            width: 90,
            height: 90
          })}
          width={45}
          height={45}
          absolute={false}
        />
      </UserContainer>
      <Textarea
        value={message}
        onChange={e => setMessage(e.target.value)}
        maxLength={500}
        height={200}
      />
      <SendButton disabled={!message} theme="primary" onClick={handleClick}>
        {t(
          'l.listings.view.suggested_tenants_chat_modal_send.button',
          'Send Message'
        )}
      </SendButton>
    </Wrapper>
  )
}

export const show = createModal(React.memo(ChatModal))
