import React from 'react'
import { Switch, Route } from 'react-router-dom'
import useGetSuiteInfo from 'hooks/get-suite-info'
import PageTitle from 'components/page-title'
import config from 'config'
import { ContractCreate, ContractPreview, ContractView } from 'pages/Contract'
import ListingDashboardHome from '../ListingDashboardHome'
import ListingDetail from '../ListingDetail'
import ListingNotes from '../ListingNotes'
import ListingShowings from '../ListingShowings'
import ListingApplications from '../ListingApplications'
import ListingChat from '../ListingChat'
import ListingSuggestedTenants from '../ListingSuggestedTenants'

const { WEB_URL } = config

const ListingDashboard = ({
  match: {
    params: { listingId }
  }
}) => {
  const listing = useGetSuiteInfo(listingId, {
    retry: 0,
    onError: err => {
      if (err.message === 'access denied') {
        window.location = `${WEB_URL}/rental-listings/${listingId}`
      }
    }
  })
  if (!listing) return null

  const listingAddress = `${
    listing.unit_number ? `${listing.unit_number} - ` : ''
  }${listing.full_street_name}`

  return (
    <>
      <PageTitle>{listingAddress}</PageTitle>
      <Switch>
        <Route
          exact
          path="/listing/:listingId"
          component={ListingDashboardHome}
        />
        <Route
          exact
          path="/listing/:listingId/:mode(preview/)?details"
          render={props => <ListingDetail noTitle {...props} />}
        />
        <Route
          exact
          path="/listing/:listingId/chat/:timelineId?"
          render={props => <ListingChat listing={listing} {...props} />}
        />
        <Route
          exact
          path="/listing/:listingId/suggested-tenants"
          component={ListingSuggestedTenants}
        />
        <Route
          exact
          path="/listing/:listingId/notes"
          component={ListingNotes}
        />
        <Route
          exact
          path="/listing/:listingId/showings"
          component={ListingShowings}
        />
        <Route
          exact
          path="/listing/:listingId/applications"
          component={ListingApplications}
        />
        <Route
          exact
          path="/listing/:listingId/contract/:contractId/preview/:currentStep(terms)?"
          render={props => <ContractPreview noTitle {...props} />}
        />
        <Route
          path="/listing/:listingId/contract/create/:contractId(\d+)?"
          render={props => <ContractCreate noTitle {...props} />}
        />
        <Route
          exact
          path="/listing/:listingId/contract/:contractId/:step(review|terms|sign)?"
          render={props => <ContractView noTitle {...props} />}
        />
      </Switch>
    </>
  )
}

export default ListingDashboard
