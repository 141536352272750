import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from 'styles'
import Card from './card'

const Wrapper = styled.div`
  margin: 30px 0 50px;
  display: grid;
  grid-gap: 30px;
  justify-content: start;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 325px);

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    justify-content: center;
    grid-gap: 15px 10px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-gap: 30px 25px;
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    grid-template-columns: 1fr;
  }
`

const CardList = ({ tenants, listingId }) => {
  return (
    <Wrapper>
      {tenants.map(tenant => (
        <Card listingId={listingId} tenant={tenant} key={tenant.id} />
      ))}
    </Wrapper>
  )
}

CardList.propTypes = {
  tenants: PropTypes.array
}

CardList.defaultProps = {
  tenants: []
}

export default CardList
