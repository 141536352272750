import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import EmploymentReferencesList from './list'
import EmploymentReferenceForm from './form'

const EmploymentReferences = ({ showRequiredWarning = false, anchorTop }) => {
  const { t } = useTranslation()
  const employmentReferences = useSelector(select.profile.employers)

  return (
    <EmploymentReferencesList
      anchorTop={anchorTop}
      data={employmentReferences}
      AddComponent={EmploymentReferenceForm}
      EditComponent={EmploymentReferenceForm}
      emptyMessage={t(
        'r.profile.view.employment_reference_empty.text',
        "You don't have employment references in your profile."
      )}
      editable
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(EmploymentReferences)
