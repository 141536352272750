import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import PetsList from './list'
import PetForm from './form'

const PetsFullList = ({
  isChecked,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  const pets = useSelector(select.profile.pets)
  return (
    <PetsList
      anchorTop={anchorTop}
      data={pets}
      AddComponent={PetForm}
      EditComponent={PetForm}
      emptyMessage={t('r.profile.view.empty_pets.text', 'I do not have a pet.')}
      isChecked={isChecked}
      editable
      showRequiredWarning={showRequiredWarning}
    />
  )
}

export default React.memo(PetsFullList)
