import { createSelector } from 'reselect'
import moment from 'moment'
import ENV_VARS from 'config'
import {
  __,
  assoc,
  path,
  find,
  values,
  reduce,
  flip,
  prop,
  pipe,
  defaultTo,
  pickAll,
  evolve,
  identity,
  propEq,
  complement,
  mergeWithKey,
  when,
  filter,
  always,
  pick,
  anyPass,
  map,
  omit,
  groupBy,
  flatten,
  sortWith,
  descend
} from 'ramda'
import formatDate from 'utils/format-date'
import { formatPhoneObject } from 'utils/format-phone'
import onlyIfDefined from 'utils/only-if-defined'
import toInteger from 'lodash/toInteger'
import toBoolean from 'utils/to-boolean'
import toArray from 'utils/to-array'
import {
  mergeSpec,
  mapBy,
  parseObjectData,
  isTruthy,
  isFalsy,
  defaultIfEmpty,
  headObj
} from 'utils'

/** HELPERS */
const formatSignature = raw => ({
  id: raw.id,
  userId: raw.user_id,
  userType: raw.user_type,
  contractId: raw.contract_id,
  createdAt: raw.created_at,
  url: `${ENV_VARS.BACKEND_SERVER}/contracts/\
${raw.contract_id}/signatures/${raw.id}/signature.png`
})

const formatFee = fee => ({
  id: fee.id,
  name: fee.name,
  price: fee.fee,
  frequency: fee.fee_frequency_txt_id
})

const formatAdditionalFees = pipe(
  filter(additionalFee => additionalFee.fee_txt_id === 'misc'),
  map(formatFee),
  toArray
)

const getFee = (id, data) => data?.find(item => item.fee_txt_id === id)

const formatPredefinedAddendums = pipe(
  map(addendum => ({
    id: addendum.listing_contract_addendum_template_txt_id,
    allowed: toBoolean(addendum.allowed),
    options: toArray(
      map(
        option => ({
          id: option.listing_contract_addendum_template_option_txt_id
        }),
        addendum.listing_contract_predefined_addendum_options
      )
    )
  })),
  toArray
)

const getSelectedUsers = (landlords, users) =>
  pipe(values, map(prop('user_id')), map(prop(__, users)))(landlords)

const formatExtraUser = user => ({
  id: user.id,
  first_name: user.first_name,
  last_name: user.last_name,
  dob: user.dob,
  email: user.email_address,
  signatureRequired: isTruthy(user.signature_required),
  ...formatPhoneObject(
    user.phone_number,
    user.phone_country_code,
    user.phone_country_code_alpha
  )
})

const getLandlordId = landlords =>
  onlyIfDefined(path([0], landlords), e => e.user_id)

const getLandlord = contract =>
  path(['users', getLandlordId(contract.contract_landlords)], contract)

const getCompany = contract => {
  const landlord = getLandlord(contract)
  if (!landlord) return null
  return path(['companies', landlord.company_id], contract)
}

const getSystemUsers = (usersList = [], users = {}) =>
  usersList.reduce(
    (accum, user) => {
      const key = hasSignatureRequired(user)
        ? 'signingTenants'
        : 'nonSigningTenants'
      return {
        ...accum,
        [key]: [...accum[key], users[user.user_id]]
      }
    },
    { signingTenants: [], nonSigningTenants: [] }
  )

const calculateStartDate = availabilityDate => {
  if (moment(availabilityDate).isAfter(moment())) {
    return availabilityDate
  }
  const firstDayOfCurrentMonth = moment().startOf('month')
  return moment().date() > 1
    ? firstDayOfCurrentMonth.add('month', 1)
    : firstDayOfCurrentMonth
}

const calculateDeposit = listing => securityDeposit => {
  if (securityDeposit) return securityDeposit
  const daysByFrequency = { daily: 1, weekly: 7, monthly: 30 }
  const price = Number(prop('price', listing))
  const frequency = prop('price_frequency', listing)
  const divisor = daysByFrequency[frequency]
  const deposit = ((price / divisor) * 30) / 2

  return toInteger(deposit)
}

/** FORMATTERS */
const formatContract = (raw = {}) => ({
  id: raw.id,
  state_machine: raw.state_machine,
  company: getCompany(raw),
  listing: raw.listings,
  unit: raw.units,
  building: raw.buildings,
  signatures: map(formatSignature, values(raw.contract_signatures)),
  listingId: raw.listing_id,
  tenancyStartDate: formatDate(raw.start_date),
  tenancyEndDate: formatDate(raw.end_date),
  effective_date: formatDate(raw.effective_date),
  lease_end_action: raw.lease_end_action,
  leaseType: raw.lease_type,
  price: raw.price,
  price_frequency: raw.price_frequency,
  extra_offer: raw.extra_offer,
  extra_offer_frequency: raw.extra_offer_frequency,
  security_deposit: raw.security_deposit,
  pet_deposit: raw.pet_deposit,
  pet_policy: toBoolean(raw.pet_policy),
  allow_cats: raw.allow_cats,
  allow_dogs: raw.allow_dogs,
  utilities: raw.contract_utilities,
  customUtilities: raw.contract_custom_utilities,
  features: raw.contract_features,
  customFeatures: raw.contract_custom_features,
  additional_fees: formatAdditionalFees(toArray(raw.contract_fees)),
  parking_fee: onlyIfDefined(getFee('parking', raw.contract_fees), formatFee),
  storage_fee: onlyIfDefined(getFee('storage', raw.contract_fees), formatFee),
  addendums: formatPredefinedAddendums(raw.contract_predefined_addendums || {}),
  custom_addendums: toArray(raw.contract_addendums),
  landlords: getSelectedUsers(raw.contract_landlords, raw.users),
  extra_users: map(formatExtraUser, toArray(raw.contract_extra_users)),
  strata_plan_number: raw.strata_plan_number,
  strata_lot_number: raw.strata_lot_number,
  is_complete: ['active', 'signed'].indexOf(raw.state_machine) !== -1,
  ...getSystemUsers(raw.contract_rentals, raw.users)
})

/** SELECTORS */
const formatList = ({
  listings: listing,
  units: unit,
  buildings: building,
  users,
  ...raw
}) => {
  const landlords = reduce(
    (acc, item) => {
      const user = users[item.user_id]
      if (!user) return acc
      return [...acc, `${user.first_name} ${user.last_name}`]
    },
    [],
    raw.contract_landlords
  ).join('\n')
  return {
    address: building.full_street_name,
    availability: listing.availability_date,
    bedrooms: unit.count_bedrooms,
    building_name: building.name || building.street_name,
    city: building.city,
    end_date: raw.end_date,
    gr_max_price: unit.gr_max_price,
    gr_max_size: unit.gr_max_size,
    gr_min_price: unit.gr_min_price,
    gr_min_size: unit.gr_min_size,
    gr_unit: unit.gr_unit,
    is_signed: true,
    lease_type: raw.lease_type,
    listing_contract_id: raw.id,
    listing_id: raw.listing_id,
    price: raw.price,
    size: unit.size,
    start_date: raw.start_date,
    state: building.state,
    state_machine: raw.state_machine,
    unit_number: unit.unit_number,
    tenants: landlords
  }
}

export const activeContractsSelector = createSelector(
  omit([
    'completedSteps',
    'signature',
    'terms',
    'listing_contract_addendum_templates'
  ]),
  pipe(values, map(formatList))
)

export const termsSelector = createSelector(
  prop('terms'),
  flip(prop('listingId')),
  (terms, listingId) => terms[listingId]
)

export const isStepCompleteSelector = createSelector(
  prop('completedSteps'),
  flip(prop('step')),
  (completedSteps, step) => completedSteps.indexOf(step) !== -1
)

// Selectors above are from previous implmentation
export const getContractSelector = createSelector(
  flip(prop('contractId')),
  identity,
  pipe(prop, defaultTo({}))
)

const CONTRACT_PROPS = [
  'contract_addendums',
  'contract_custom_features',
  'contract_custom_utilities',
  'contract_extra_users',
  'contract_features',
  'contract_fees',
  'contract_landlords',
  'contract_non_signing_tenants',
  'contract_predefined_addendums',
  'contract_rentals',
  'contract_utilities',
  'end_date',
  'extra_offer',
  'extra_offer_frequency',
  'lease_end_action',
  'lease_type',
  'parking',
  'pet_deposit',
  'pet_policy',
  'allow_cats',
  'allow_dogs',
  'pets',
  'price',
  'price_frequency',
  'security_deposit',
  'start_date',
  'storage',
  'strata_lot_number',
  'strata_plan_number'
]

const hasSignatureRequired = propEq('signature_required', '1')

const mergeIfFalsy = data =>
  mergeWithKey((k, l, r) => when(isFalsy, always(r))(l), __, data)

const isAdditionalFee = propEq('fee_txt_id', 'misc')

const feeNotAvailable = propEq('fee_frequency_txt_id', 'not_available')

const feeFree = propEq('fee_frequency_txt_id', 'free')

const getFeeData = type => {
  return pipe(
    prop('contract_fees'),
    defaultTo([]),
    find(propEq('fee_txt_id', type)),
    when(
      isTruthy,
      pipe(
        pick(['fee_frequency_txt_id', 'name', 'fee']),
        mergeSpec({
          enabled: complement(feeNotAvailable),
          hasFee: complement(anyPass([feeNotAvailable, feeFree]))
        }),
        parseObjectData
      )
    )
  )
}

export const contractDataSelector = (
  contractData,
  listingData,
  applicationData = {}
) => {
  return createSelector(
    pickAll(CONTRACT_PROPS),
    prop('users'),
    (contract, users) =>
      pipe(
        pickAll(CONTRACT_PROPS),
        evolve({
          contract_landlords: mapBy('user_id', users),
          contract_predefined_addendums: defaultIfEmpty({})
        }),
        mergeSpec({
          storage: pipe(getFeeData('storage'), assoc('fee_txt_id', 'storage')),
          parking: pipe(getFeeData('parking'), assoc('fee_txt_id', 'parking')),
          contract_signing_tenants: pipe(
            prop('contract_rentals'),
            defaultTo([]),
            filter(hasSignatureRequired),
            mapBy('user_id', users)
          ),
          contract_non_signing_tenants: pipe(
            prop('contract_rentals'),
            defaultTo([]),
            filter(complement(hasSignatureRequired)),
            mapBy('user_id', users)
          ),
          additional_fees: pipe(
            prop('contract_fees'),
            defaultTo([]),
            filter(isAdditionalFee)
          )
        }),
        mergeIfFalsy(listingData),
        mergeIfFalsy(applicationData),
        evolve({
          contract_signing_tenants: defaultIfEmpty(
            applicationData.contract_signing_tenants
          ),
          start_date: pipe(calculateStartDate, formatDate),
          security_deposit: calculateDeposit(listingData)
        })
      )(contract)
  )(contractData)
}

export const contractViewSelector = createSelector(
  getContractSelector,
  formatContract
)

export const listingContractsSelector = createSelector(
  omit([
    'completedSteps',
    'signature',
    'terms',
    'listing_contract_addendum_templates'
  ]),
  flip(prop('listingId')),
  (contracts, listingId) =>
    pipe(values, filter(propEq('listing_id', listingId)))(contracts)
)

export const groupListingContractsSelector = createSelector(
  listingContractsSelector,
  groupBy(prop('state_machine'))
)

export const nextContractSelector = createSelector(
  listingContractsSelector,
  pipe(
    groupBy(prop('state_machine')),
    omit(['history', 'active']),
    values,
    flatten,
    find(propEq('contract_type', 'extension'))
  )
)

export const lastActiveContractSelector = createSelector(
  listingContractsSelector,
  pipe(
    groupBy(prop('state_machine')),
    omit(['draft']),
    values,
    flatten,
    sortWith([descend(prop('id'))]),
    headObj
  )
)
