import React, { useRef, useMemo, useState } from 'react'
import styled from 'styled-components'
import useRefCallback from 'hooks/use-ref-callback'
import useEventListener from 'hooks/use-event-listener'
import throttle from 'lodash/throttle'

const Wrapper = styled.div`
  height: calc(100vh - 272px);
  overflow-y: scroll;
  flex: 1;
  min-width: 350px;
`

const ChatCardsContainer = ({ children, onClick, id, onEndReached }) => {
  const [theRef, setTheRef] = useState()
  const [chatCardContainerRef] = useRefCallback(node => {
    setTheRef(node)
  })

  const loading = useRef(false)
  const onScroll = useMemo(
    () =>
      throttle(async event => {
        if (loading.current) return
        const el = event.target
        const scrollTop = el.scrollTop
        const scrollHeight = el.scrollHeight - el.clientHeight
        const ratio = scrollTop / scrollHeight
        if (ratio > 0.9) {
          loading.current = true
          await onEndReached(id)
          loading.current = false
        }
      }, 300),
    [id, onEndReached]
  )
  useEventListener('scroll', onScroll, theRef)

  return (
    <Wrapper onClick={onClick} ref={chatCardContainerRef}>
      {children}
    </Wrapper>
  )
}
export default ChatCardsContainer
