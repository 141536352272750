const formatLeaseType = (type, t) => {
  const LEASE_TYPE_LABELS = {
    fixed: t('common.fixed_term', 'Fixed term'),
    month_to_month: t('common.month_to_month', 'Month-to-month'),
    other: t('common.other', 'Other')
  }

  return LEASE_TYPE_LABELS[type]
}

export default formatLeaseType
