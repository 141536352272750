import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { breakpoints, colors, fontSizes, fontWeights } from 'styles'
import useMediaQuery from 'hooks/use-media-query'
import { FieldInput } from 'components/form'
import { hiddenStyle } from 'components/hidden'
import { SectionHeading, SectionRow } from '../elements'

const Section = styled.section`
  max-width: 660px;
`

const OptionText = styled.label`
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  color: ${colors.darkGrey};
  margin: 0 0 0 10px;
`

const Option = styled(OptionText)`
  cursor: pointer;
  padding: 5px 10px !important;
  border: 1px solid transparent;
  min-width: 100px;
  text-align: center;

  :hover {
    border-color: ${colors.darkGrey};
  }
`

const InputWrapper = styled.div`
  input {
    ${hiddenStyle}
  }
  > input[checked] + label {
    color: ${colors.secondary};
    border-color: ${colors.secondary};
  }
  > input:focus + label {
    padding: 4px 9px !important;
    border-width: 2px;
  }
`

const PreferenceOption = styled.div`
  display: flex;
`

const Label = styled.label`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  line-height: 22px;
  margin: 0;
`

const LANGUAGES = {
  en: {
    value: 'en',
    label: 'Canada (EN)'
  },
  zh: {
    value: 'zh',
    label: '中文 (CN)'
  }
}

const Preference = ({ name, value, children, onChange, ...rest }) => (
  <InputWrapper>
    <FieldInput
      type="radio"
      name={name}
      value={value}
      id={`input-${name}-${value}`}
      onToggle={onChange}
    />
    <Option {...rest} htmlFor={`input-${name}-${value}`}>
      {children}
    </Option>
  </InputWrapper>
)

const Preferences = () => {
  const { t, i18n } = useTranslation()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  const feetText = displayMobile
    ? t('b.settings.view.feet.text', 'Feet')
    : t('b.settings.view.sqft.text', 'Square feet')
  const metersText = displayMobile
    ? t('b.settings.view.meters.text', 'Meters')
    : t('b.settings.view.sqm.text', 'Square meters')

  return (
    <Section>
      <SectionHeading>
        {t('b.settings.view.preferences.title', 'Preferences')}
      </SectionHeading>
      <SectionRow bordered>
        <Label>{t('b.settings.view.measurements.label', 'Measurements')}</Label>
        <PreferenceOption>
          <Preference name="setting_size_unit" value="sqft">
            {feetText}
          </Preference>
          <Preference name="setting_size_unit" value="meter">
            {metersText}
          </Preference>
        </PreferenceOption>
      </SectionRow>
      <SectionRow bordered>
        <Label>{t('b.settings.view.distances.label', 'Distances')}</Label>
        <PreferenceOption>
          <Preference name="setting_distance_unit" value="km">
            {t('b.settings.view.km.text', 'Kilometers')}
          </Preference>
          <Preference name="setting_distance_unit" value="mile">
            {t('b.settings.view.miles.text', 'Miles')}
          </Preference>
        </PreferenceOption>
      </SectionRow>
      <SectionRow bordered>
        <Label>{t('b.settings.view.currency.label', 'Currency')}</Label>
        <OptionText>
          {t('b.settings.view.currency_cad.label', 'CAD ($)')}
        </OptionText>
      </SectionRow>
      <SectionRow bordered>
        <Label>{t('b.settings.view.language.label', 'Language')}</Label>
        <PreferenceOption>
          <Preference
            name="setting_language"
            value="en"
            onChange={() => i18n.changeLanguage('en')}
          >
            {LANGUAGES.en.label}
          </Preference>
          <Preference
            name="setting_language"
            value="zh"
            onChange={() => i18n.changeLanguage('zh')}
          >
            {LANGUAGES.zh.label}
          </Preference>
        </PreferenceOption>
      </SectionRow>
    </Section>
  )
}

export default Preferences
