import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes, fontWeights } from 'styles'
import Icon from 'components/icon'
import { MobileDrawer } from 'components/drawer'
import CurrencyFormatter from 'components/currency-formatter'

const Analytics = styled.div`
  display: flex;
  justify-content: space-between;
  background: #e9f2fc;
  border-radius: 6px;
  grid-column: 1 / -1;
`

const AnalyticsItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 25px 30px 20px 30px;
`

const AnalyticTitle = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.3px;
  line-height: 22px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
    margin: 0 0 5px;
  }
  @media screen and (max-width: ${breakpoints.smallPhone}) {
    font-size: 11px;
  }
`

const AnalyticValue = styled.span`
  color: ${colors.regular};
  font-size: ${fontSizes.extraLarge};
  font-weight: ${fontWeights.medium};
  line-height: 41px;
  margin: 0;
`

const Abbr = styled.abbr`
  text-decoration: none;
`

const RentValue = styled(AnalyticValue)`
  font-size: 15px;
`

const AverageValue = styled(AnalyticValue)`
  font-size: 19px;
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.regular};
  }
`

const DifferenceValue = styled(AnalyticValue)`
  margin: 0 5px;
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 25px;
  }
`

const BoldPrice = styled(CurrencyFormatter)`
  font-weight: ${fontWeights.medium};
`

const Container = styled.div`
  width: ${props => props.width};
  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    width: 100%;
  }
`

const Flex = styled.div`
  display: flex;
  > div:last-of-type {
    padding-left: 5px;
    padding-right: 12px;
  }
  @media screen and (max-width: ${breakpoints.dashboardMobile}) {
    flex-wrap: wrap;
    > div {
      flex: 0 0 50%;
      max-width: 50%;
    }
    > div:nth-of-type(3) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 25px 30px 20px 30px;
    }
  }
  @media screen and (max-width: ${breakpoints.phoneMax}) {
    > div {
      padding: 0 0 0 20px;
    }
    > div:nth-of-type(3) {
      padding: 20px 20px 15px;
    }
    svg {
      display: none;
    }
  }
`

const Price = ({ children }) => {
  if (!React.Children.count(children)) return null
  return <BoldPrice value={children} />
}

const AverageRent = ({ difference, aboveAverage }) => {
  const { t } = useTranslation()
  if (difference === 0) return (
    <AverageValue>{t('l.listing.view.rent_average.text', 'Your rent is exactly average')}</AverageValue>
  )
  return aboveAverage ? (
    <Trans i18nKey="l.listing.view.rent_higher.text" difference={difference}>
      Your rent is <DifferenceValue>{{difference}}%</DifferenceValue> higher
    </Trans>
  ) : (
    <Trans i18nKey="l.listing.view.rent_lower.text" difference={difference}>
      Your rent is <DifferenceValue>{{difference}}%</DifferenceValue> lower
    </Trans>
  )
}

const ListingAnalytics = ({
  applicationCount,
  viewCount,
  priceInfo: {
    average,
    aboveAverage,
    difference
  }
}) => {
  const { t } = useTranslation()
  const container = useRef()
  const [isOpen, setOpen] = useState()
  const hidePrice = !average || (difference >= 100 && !aboveAverage)
  return (
    <Analytics>
      <Container width={hidePrice ? 'calc(100% * 2 / 3)' : '100%'}>
        <MobileDrawer
          id="landlord-listing-analytics"
          height={container?.current?.clientHeight}
          title={t('l.listing.view.insights.title', 'Insights')}
          breakpoint={`(max-width: ${breakpoints.dashboardMobile})`}
          isOpen={isOpen}
          setOpen={setOpen}
          hideHeader
        >
          <Flex ref={container}>
            <AnalyticsItem>
              <AnalyticTitle>
                <Icon id="application" height={24} width={24} />
                {t(
                  'l.listing.view.applications_received.text',
                  'Applications received'
                )}
              </AnalyticTitle>
              <AnalyticValue>{applicationCount}</AnalyticValue>
            </AnalyticsItem>
            <AnalyticsItem>
              <AnalyticTitle>
                <Icon id="listing_views" height={24} width={24} />
                {t(
                  'l.listing.view.views_in_last_7_days.text',
                  'Views in the last 7 days'
                )}
              </AnalyticTitle>
              <AnalyticValue>{viewCount}</AnalyticValue>
            </AnalyticsItem>
            {!hidePrice && (
              <AnalyticsItem>
                <AnalyticTitle>
                  <Icon id="listing_active" height={24} width={24} />
                  <span>
                    <Trans i18nKey="l.listing.view.average_rent.text" average={average}>
                      <Abbr title={t('l.listing.view.average_rent.title', 'Average')}>Avg.</Abbr>
                      {' '}rent price near you:{' '}
                      <Price>{{average}}</Price>
                    </Trans>
                  </span>
                </AnalyticTitle>
                <RentValue>
                  <AverageRent difference={difference} aboveAverage={aboveAverage} />
                </RentValue>
              </AnalyticsItem>
            )}
          </Flex>
        </MobileDrawer>
      </Container>
    </Analytics>
  )
}

export default ListingAnalytics
