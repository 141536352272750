import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import formatName from 'utils/format-name'
import VerifiedBadge from 'components/verified-badge'
import { Avatar } from 'components/avatar'
import Pill from 'components/pill'
import useCompany from 'hooks/use-company'
import { getImageUrl } from 'utils/image'
import isVerified from 'utils/is-verified'

const UserContainer = styled.div`
  background-color: ${colors.white};
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
`
const UserInfo = styled.div`
  display: flex;
  align-items: center;
`

const UserName = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
`

const UserButton = styled.button`
  color: ${colors.link};
  background-color: ${colors.white};
  border: none;
  width: 90px;
  height: 50px;
  font-size: ${fontSizes.small};
  outline: none;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`

const Primary = styled(Pill)`
  margin: 0;
  margin-left: 20px;
  position: relative;
  background-color: ${colors.pink};
`

const ListingUsersContainer = ({ user, showPrimary, className, ...props }) => {
  const { t } = useTranslation()
  const company = useCompany(user.company_id)
  return (
    <UserContainer className={className}>
      <UserInfo>
        <Avatar
          location={getImageUrl(user?.avatar?.aws_s3_key, {
            width: 40,
            height: 40
          })}
          shadow
          width={40}
          height={40}
          left={25}
          right={15}
        />
        <UserName>{formatName(user)}</UserName>
        <VerifiedBadge
          type="id"
          width={20}
          height={15}
          right={5}
          left={5}
          verified={isVerified(user, company)}
        />
        {showPrimary ? (
          <Primary theme="primary" radius={6} height={24} width={60}>
            {t('l.listing.edit.primary.text', 'Primary')}
          </Primary>
        ) : null}
      </UserInfo>
      <UserButton type="submit" onClick={props.handleDelete}>
        {t('l.listing.edit.remove.button', 'Remove')}
      </UserButton>
    </UserContainer>
  )
}
export default ListingUsersContainer
