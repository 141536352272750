import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MultiRowForm } from 'components/multi-row-form'
import RoommateView from './view'

const EmptyMessage = styled.p`
  font-size: 12px;
`

const RoommatesList = ({
  data,
  emptyMessage,
  AddComponent,
  EditComponent,
  editable = false,
  anchorTop
}) => {
  const { t } = useTranslation()

  return (
    <MultiRowForm
      anchorTop={anchorTop}
      title={t('r.profile.view.roommate.subtitle', 'Roommate Information')}
      addText={t('r.profile.view.add_roommate.button', '+ Add Roommate')}
      data={data}
      AddComponent={AddComponent}
      ViewComponent={RoommateView}
      EditComponent={EditComponent}
      EmptyComponent={<EmptyMessage>{emptyMessage}</EmptyMessage>}
      editable={editable}
    />
  )
}

export default React.memo(RoommatesList)
