import styled from 'styled-components'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import Page from 'components/page'
import AreaTitle from 'components/area-title'
import Panel from 'components/panel'
import { Input } from 'components/form'
import { Anchor } from 'components/anchor'

export const Wrapper = styled(Page)`
  margin-bottom: 80px;
`

export const StyledAreaTitle = styled(AreaTitle)`
  height: auto;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
`

export const StyledPanel = styled(Panel)`
  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

export const AssuranceText = styled.p`
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 30px 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 30px 10px;
  }
`

export const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  p {
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeights.regular};
    margin: 0;
  }

  strong {
    font-weight: ${fontWeights.medium};
  }
`

export const TextRow = styled.div`
  margin: 20px 0;

  p {
    font-size: ${fontSizes.regular};
    font-weight: ${fontWeights.regular};
    margin: 0;
    letter-spacing: -0.39px;
    line-height: 20px;
  }

  ul {
    padding-inline-start: 20px;
    margin-block-start: 20px;
    margin-block-end: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.grey};

    li {
      margin: 20px 0;
    }
  }
`

export const WarningRow = styled(TextRow)`
  margin-top: 30px;
  color: #dc0027;
`

export const Checkbox = styled(Input)``

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  ${Checkbox} {
    margin-left: 10px;
  }
`

export const CheckboxText = styled.label`
  letter-spacing: -0.39px;
  line-height: 22px;

  strong {
    font-weight: ${fontWeights.medium};
  }
`

export const StyledAnchor = styled(Anchor)`
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.39px;
  line-height: 22px;
`
