import { geoCoding } from 'ports'

const COMMON_PREPOSITIONS = [
  'a',
  'the',
  'of',
  'are',
  'is',
  'am',
  'with',
  'on',
  'at',
  'from',
  'to',
  'on',
  'must'
]

const removePrepositions = search => {
  const regex = new RegExp(`\\s+(${COMMON_PREPOSITIONS.join('|')})`)
  return search.replace(regex, '')
}

const geocoding = {
  name: 'geocoding',
  state: {},
  effects: () => ({
    async search(query) {
      const params = {
        searchtext: removePrepositions(query),
        maxresults: 3,
        country: 'can'
      }
      try {
        return await geoCoding(undefined, params)
      } catch (error) {
        console.error('[geocoding/geocoding] Error occured: ', error)
        return null
      }
    }
  })
}

export default geocoding
