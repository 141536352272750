import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { createModal, useModalize } from 'components/modalize'
import { fontSizes, fontWeights, colors } from 'styles'
import Icon from 'components/icon'
import { Button } from 'components/button'

const Wrapper = styled.div`
  padding: 20px 30px;
  text-align: center;
  position: relative;
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

const Title = styled.h1`
  margin: 20px;
  font-weight: ${fontWeights.medium};
  text-align: center;
`

const SubHeader = styled.p`
  font-size: ${fontSizes.medium};
  font-weight: 700;
  padding: 20px 0px;
  margin-top: 0px;
  border-bottom: 1px solid ${colors.grey};
  text-align: left;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const LabelText = styled.p`
  margin: 10px 0px;
`

const ValueText = styled.p`
  margin: 10px 0px;
  font-weight: ${fontWeights.medium};
`

const TotalWrapper = styled(Row)`
  margin-top: 20px;
  border-top: 1px solid ${colors.grey};
`

const TotalText = styled(SubHeader)`
  border: none;
`

const StyledButton = styled(Button)`
  width: 160px;
  padding: 5px 25px;
  margin-bottom: 10px;
`

const PaidConfirmationModal = ({ paymentId, listingId, paymentData }) => {
  const [t] = useTranslation()
  const { close } = useModalize()

  if (!paymentData) return null

  return (
    <Wrapper>
      <CloseButton
        aria-label="Close"
        id="close"
        width={24}
        height={24}
        onClick={close}
      />
      <Title>
        {t(
          'r.payment.view.payment_confirmation_modal.title',
          'Payment Completed'
        )}
      </Title>
      <SubHeader>
        {t(
          'r.payment.view.payment_confirmation_modal.subtitle',
          'Payment Summary'
        )}
      </SubHeader>
      <Row>
        <LabelText>
          {t(
            'r.payment.view.payment_confirmation_modal_payment_amount.text',
            'Payment Amount'
          )}
          :
        </LabelText>
        <ValueText>{paymentData.requested_amount}</ValueText>
      </Row>
      <Row>
        <LabelText>
          {t(
            'r.payment.view.payment_confirmation_modal_processing_fees.text',
            'Processing fees'
          )}
          :
        </LabelText>

        <ValueText>${paymentData.fee}</ValueText>
      </Row>
      <TotalWrapper>
        <TotalText>
          {t('r.payment.view.payment_confirmation_modal_total.text', 'Total')}:
        </TotalText>
        <TotalText>${paymentData.charged}</TotalText>
      </TotalWrapper>

      <StyledButton theme="primary" onClick={close}>
        {t('r.payment.view.payment_confirmation_modal_done.button', 'Done')}
      </StyledButton>
    </Wrapper>
  )
}

export const show = createModal(PaidConfirmationModal)
