import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { path } from 'ramda'
import { isNotEmpty } from 'utils'
import formatDate from 'utils/format-date'
import { PageTitle } from 'components/headings'
import Section from 'components/section'
import Addendums from './addendums'
import BasicTerms from './basic-terms'
import BCTenancyAgreement from './bc-tenancy-agreement'
import Signatorees from './signatorees'
import Amenities from './amenities'

const completedDate = date =>
  date ? formatDate(date, 'YYYY-MM-DD') : '(Completed Date)'

const DateText = styled.span`
  font-weight: 700;
`

const ReviewDetails = ({ contract, userType, partialView }) => {
  const { t } = useTranslation()
  const unitScope = useSelector(
    path(['listing', contract.listingId, 'unit', 'unit_type_scope_txt_id'])
  )

  if (!contract.id) return null

  let features = contract.features
  //shared bathrooms need to be listed in features
  if (
    unitScope !== 'entire' &&
    contract.unit.count_full_shared_bathrooms &&
    parseFloat(contract.unit.count_full_shared_bathrooms) > 0
  ) {
    const dummyBathroomFeature = {
      name: 'Bathroom',
      txt_id: 'bathroom',
      types: {
        private: parseFloat(contract.unit.count_full_bathrooms || '0'),
        shared: parseFloat(contract.unit.count_full_shared_bathrooms)
      }
    }

    features = [dummyBathroomFeature, ...features]
  }

  return (
    <>
      <PageTitle key="page-title">
        {t(
          'b.contract.view.residential_tenancy_agreement.title',
          'Residential Tenancy Agreement'
        )}
      </PageTitle>
      <p>
        <DateText>
          {t('b.contract.view.effective_date.text', 'Effective Date:')}{' '}
          {completedDate(contract.effective_date)}
        </DateText>
      </p>

      <Section>
        <p>
          {t(
            'b.contract.view.agreement_description.text',
            'THIS AGREEMENT made as of the Effective Date is entered into between the Landlord(s) and the Tenant(s), each as defined below in the basic terms ("Basic Terms") of this Residential Tenancy Agreement ("Agreement"), who, for good and valuable consideration, the receipt and sufficiency of which is mutually acknowledged, agree to be bound as follows:'
          )}
        </p>
      </Section>

      <Signatorees
        landlords={contract.landlords}
        signingTenants={contract.signingTenants}
        nonSigningTenants={contract.nonSigningTenants}
        extraRenters={contract.extra_users}
        signatures={contract.signatures}
        company={contract.company}
        partialView={partialView}
      />
      <BasicTerms contract={contract} />
      <Amenities
        amenities={features}
        customAmenities={contract.customFeatures}
        unitScope={unitScope}
        header={t('b.contract.view.features.subtitle', 'Features')}
        translationContext="basic:b.listing.unit_features"
      />
      {isNotEmpty(contract.utilities) && (
        <Amenities
          header={t('b.contract.view.utilities.subtitle', 'Utilities')}
          amenities={contract.utilities}
          customAmenities={contract.customUtilities}
          unitScope={unitScope}
          translationContext="basic:b.listing.utilities"
        />
      )}
      <BCTenancyAgreement contract={contract} />
      <Addendums
        contract={contract}
        userType={userType}
        disabled={contract.is_complete || partialView}
      />
    </>
  )
}

ReviewDetails.defaultProps = {
  userType: null,
  partialView: false
}

export default ReviewDetails
