import useWindowDimensions from 'hooks/use-window-dimensions'

const { innerHeight: initialHeight } = window

const useHasHeightChanged = (heightThreshold = 550) => {
  const { height } = useWindowDimensions()

  return height < initialHeight && height < heightThreshold
}

export default useHasHeightChanged
