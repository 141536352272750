import styled from 'styled-components'
import { breakpoints } from 'styles'

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  width: 1096px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 95%;
    justify-content: ${props => (props.center ? 'center' : 'space-between')};
  }

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    max-width: 720px;
  }
`

export default styled(SearchBarContainer)``
