import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { isGroup } from 'utils/listings'
import CurrencyFormatter from 'components/currency-formatter'
import LandlordInfo from '../landlord-info'
import CompanyInfo from '../company-info'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid ${colors.grey};
  border-radius: 6px;
`

const TopColor = styled.div`
  border-radius: 6px 6px 0 0;
  background: ${colors.secondary};
  height: 8px;
`

const InnerWrapper = styled.div`
  margin: 30px 25px 0 25px;
`

const PriceWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`

const PriceColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const PriceStarting = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  font-weight: ${fontWeights.regular};
  margin: 0;
`

const Price = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.extraLarge};
  font-weight: 700;
  margin: 0 5px 0 0;
  line-height: 30px;
`

const Frequency = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  margin: 0;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.grey};
  margin: 30px 0;
  padding: 30px 0 15px;

  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`

const formatPrice = price => <CurrencyFormatter value={price} />

const renderPrice = (listing, unit, t) => {
  if (listing.state_machine === 'rented') {
    return (
      <PriceWrapper>
        <Price>{t('b.listing.view.rented.text', 'Rented')}</Price>
      </PriceWrapper>
    )
  }

  const price = isGroup(unit)
    ? formatPrice(unit.gr_min_price)
    : formatPrice(listing.price)

  return (
    <PriceColumn>
      {isGroup(unit) && (
        <PriceStarting>
          {t('b.listings.view.starting_at.text', 'Starting at')}...
        </PriceStarting>
      )}
      <PriceWrapper>
        <Price>{price}</Price>
        <Frequency>
          {`/ ${t(
            `b.listing.view.price_frequency_${listing.price_frequency}.text`
          )}`}
        </Frequency>
      </PriceWrapper>
    </PriceColumn>
  )
}

const SummaryBlock = ({ listings, unit, landlord, companyName }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TopColor />
      <InnerWrapper>
        {renderPrice(listings, unit, t)}
        <InfoWrapper>
          <LandlordInfo landlord={landlord} />
          <CompanyInfo name={companyName} />
        </InfoWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default SummaryBlock
