import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { select } from 'store'
import { colors, fontWeights, breakpoints, structure } from 'styles'
import { Helmet } from 'react-helmet'
import i18n from 'i18n'
import useMediaQuery from 'hooks/use-media-query'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCell
} from 'components/table'
import { SearchBarContainer, StripeBar } from 'components/search-filter'
import { EmptyListComponent } from 'components/table-list'
import CurrencyFormatter from 'components/currency-formatter'
import SearchBar from 'components/search-bar'
import Pill from 'components/pill'
import formatDate from 'utils/format-date'
import PageTitle from 'components/page-title'
import { path } from 'ramda'

const canViewDetail = state => ['draft', 'history'].indexOf(state) < 0

const PILL_THEME = {
  active: 'default',
  sent: 'primary',
  draft: 'invalid'
}

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding-top: 87px;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 100px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 213px);
  background-color: ${colors.white};
  min-width: 768px;
`

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  min-width: 768px;
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: auto;
  }
`

const TableBodyContainer = styled(TableBody)`
  & ${TableRow} {
    border-bottom: 1px solid ${colors.grey};
  }
  & ${TableRow}:last-child {
    border-bottom: 0px solid ${colors.grey};
  }
`

const StickyContainer = styled.div`
  top: ${structure.header.height}px;
  position: fixed;
  width: 100%;
  z-index: 20;
  background: ${colors.white};

  @media only screen and (max-width: ${breakpoints.ipadMiniMax}) {
    top: ${structure.header.heightMobile}px;
  }
`

const HeaderText = styled.p`
  color: ${colors.white};
  text-align: left;
  margin: 10px 25px;
  font-size: 14px;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin: 10px 18px;
  }
`

const CellText = styled.p`
  color: ${colors.regular};
  font-size: 14px;
  margin-left: 25px;
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;

  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-left: 18px;
  }
`

const ContractRow = styled(TableRow)`
  ${props =>
    canViewDetail(props.state) &&
    `
    &:hover {
      cursor: pointer;
    }
  `}
`

const ListingCell = styled(TableCell)`
  padding: 10px 0 20px 0;
`

const OffsetPill = styled(Pill)`
  position: relative;
  top: 8px;
`

const AddressText = styled(CellText)`
  max-width: 130px;
  min-width: 80px;
  margin-top: 0px;
  margin-bottom: 0px;
`

const UnitText = styled(AddressText)`
  font-weight: ${fontWeights.medium};
  margin-bottom: 2px;
`

const CityText = styled(AddressText)`
  font-size: 12px;
  line-height: 20px;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const ContractsTable = styled(Table)`
  width: 1096px;
  min-width: 768px;
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    max-width: 95%;
  }
  @media only screen and (max-width: ${breakpoints.tabletLandscape}) {
    width: auto;
  }
`

const createTitle = tenants => {
  var title = tenants[0]
  if (tenants.length > 1) {
    title.concat(` +${tenants.length - 1}`)
  }
  return title
}

const formatPrice = price => {
  return price ? (
    <CurrencyFormatter value={price} />
  ) : (
    i18n.t('b.components.view.not_avaiable.text', 'N/A')
  )
}

const renderPrice = contract => {
  const { gr_min_price, gr_max_price, price, gr_unit } = contract
  const isGroupListing = gr_unit === '1'
  return isGroupListing ? (
    <Fragment>
      {formatPrice(gr_min_price)} - {formatPrice(gr_max_price)}
    </Fragment>
  ) : (
    formatPrice(price)
  )
}

const ContractList = props => {
  const { t } = useTranslation()
  const session = useSelector(path(['session', 'session']))
  const [data, setData] = useState([])
  const [filteredContracts, setFilteredContracts] = useState()
  const contracts = useSelector(select.contract.activeContracts)
  const dispatch = useDispatch()
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.ipadMiniMax})`)

  useEffect(() => {
    dispatch.contract.getContracts()
  }, [dispatch.contract, session])

  useEffect(() => {
    setData(contracts)
    setFilteredContracts(contracts)
  }, [contracts])

  if (!filteredContracts) return null

  const searchContracts = searchString => {
    const filteredContract = data.filter(contract =>
      JSON.stringify(Object.values(contract))
        .toLowerCase()
        .includes(searchString.trim().toLowerCase())
    )
    setFilteredContracts(filteredContract)
  }

  const goToContractDetail = contract => {
    if (!canViewDetail(contract.state_machine)) return
    props.history.push(`/contract/${contract.listing_contract_id}`)
  }

  const getBedrooms = contract => {
    const { bedrooms } = contract
    if (bedrooms === '0') return t('common.studio', 'Studio')
    return bedrooms
      ? `${bedrooms} ${t('common.bedroom', 'Bedroom')}`
      : i18n.t('b.components.view.not_avaiable.text', 'N/A')
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          {t('b.contracts.view.contracts.title', 'Contracts to Sign')}
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <PageTitle>
        {t('b.contracts.view.contracts.title', 'Contracts to Sign')}
      </PageTitle>
      <StickyContainer>
        <SearchBarContainer>
          <SearchBar
            placeholder={t('common.search', 'Search')}
            onSearch={searchContracts}
          />
        </SearchBarContainer>
        <StripeBar />
      </StickyContainer>
      <Container>
        <TableContainer>
          <ContractsTable>
            <TableHeader>
              <TableRow
                sticky
                top={
                  displayMobile
                    ? structure.header.heightMobile + 87
                    : structure.header.height + 87
                }
              >
                <TableHeaderCell>
                  <HeaderText>{t('common.unit', 'Unit')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>{t('common.rooms', 'Rooms')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>{t('common.price', 'Price')}</HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.contracts.view.landlord.text', 'Landlord')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.contracts.view.lease_start.text', 'Lease Start')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.contracts.view.lease_end.text', 'Lease End')}
                  </HeaderText>
                </TableHeaderCell>
                <TableHeaderCell>
                  <HeaderText>
                    {t('b.contracts.view.signing.text', 'Signing')}
                  </HeaderText>
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBodyContainer>
              {filteredContracts.length > 0 ? (
                filteredContracts.map((contract, index) => {
                  const state = contract.state_machine
                  const tenants = contract.tenants.split('\n')
                  return (
                    <ContractRow
                      key={`Contract - ${index}`}
                      state={state}
                      onClick={() => goToContractDetail(contract)}
                    >
                      <ListingCell>
                        <UnitText>
                          {`${
                            !!contract.unit_number ? contract.unit_number : ''
                          } ${contract.building_name}`}
                        </UnitText>
                        <AddressText>{contract.address}</AddressText>
                        <CityText>
                          {`${contract.city}, ${contract.state}`}
                        </CityText>
                      </ListingCell>
                      <ListingCell>
                        <CellText>{getBedrooms(contract)}</CellText>
                      </ListingCell>
                      <ListingCell>
                        <CellText>
                          <Bold>{renderPrice(contract)}</Bold>
                        </CellText>
                      </ListingCell>
                      <ListingCell>
                        <CellText>
                          <Bold>{createTitle(tenants)}</Bold>
                        </CellText>
                      </ListingCell>
                      <ListingCell>
                        <CellText>
                          {formatDate(contract.start_date, 'MMM Do, YYYY') ||
                            i18n.t(
                              'b.components.view.not_avaiable.text',
                              'N/A'
                            )}
                        </CellText>
                      </ListingCell>
                      <ListingCell>
                        <CellText>
                          {formatDate(contract.end_date, 'MMM Do, YYYY') ||
                            i18n.t(
                              'b.components.view.not_avaiable.text',
                              'N/A'
                            )}
                        </CellText>
                      </ListingCell>
                      <ListingCell>
                        <OffsetPill
                          theme={PILL_THEME[state]}
                          height={40}
                          width={90}
                        >
                          {t(`b.contract.list.status_${state}.badge`)}
                        </OffsetPill>
                      </ListingCell>
                    </ContractRow>
                  )
                })
              ) : (
                <TableRow>
                  <ListingCell colSpan={7}>
                    <EmptyListComponent />
                  </ListingCell>
                </TableRow>
              )}
            </TableBodyContainer>
          </ContractsTable>
        </TableContainer>
      </Container>
    </Wrapper>
  )
}

export default ContractList
