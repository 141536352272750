import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { propEq } from 'ramda'
import { select } from 'store'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { headKey } from 'utils'
import { colors, breakpoints, fontSizes, fontWeights, structure } from 'styles'
import { toUnit } from 'utils/styles'
import Breadcrumbs from 'components/breadcrumbs'
import { toast } from 'components/toast-notifications'
import { leftCloud, rightCloud, rightCloudSmall } from 'components/clouds'

import { showRelistModal } from './relist-modal'
import TenantInfo from './tenant-info'
import TenantMenuCards from './tenant-menu-cards'

import backgroundBottom from './images/background-bottom.png'
import backgroundBottomMobile from './images/background-bottom-mobile.png'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 50px 220px 100px;
  background: ${colors.white};
  min-height: calc(100vh - ${toUnit(structure.header.height)});

  background-image: url(${leftCloud}), url(${rightCloud}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -60px 330px, 92% 70px, 100% 95%;
  background-size: 238px, 230px, 566px;

  section:not(:last-child) {
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    padding-bottom: 150px;

    background-image: url(${leftCloud}), url(${rightCloudSmall}),
      url(${backgroundBottom});
    background-position: -90px 330px, 100% 60px, 100% 97%;
    background-size: 238px, 100px, 350px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 0 40px 150px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 0 25px 130px;

    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
    background-image: url(${leftCloud}), url(${rightCloudSmall}),
      url(${backgroundBottomMobile});
    background-position: -90px 350px, 100% 20px, 100% 98%;
    background-size: 238px, 90px, 375px;

    section:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: ${breakpoints.smallPhone}) {
    padding: 0 15px 130px;
  }
`

const DashboardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  max-width: 950px;
`

const AddressSection = styled.section`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SectionHeading = styled.h1`
  font-size: 24px;
  color: ${colors.regular};
  font-weight: 700;
  line-height: 30px;
  margin: 0;
`

const ListingLinks = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 15px;
  }
`

const ListingLink = styled(Link)`
  color: ${colors.secondary};
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.medium};
  background: ${colors.white};
  border: none;
  letter-spacing: 0.33px;
  line-height: 27px;
  text-decoration: underline;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid ${colors.mediumLightGrey};
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    :first-child {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
  }
`

const DashboardGrid = styled.div`
  max-width: 1280px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 950px 290px;

  @media screen and (max-width: 1500px) {
    max-width: 950px;
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    max-width: 620px;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    grid-gap: 30px;
  }
`

const MenuCardsGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  justify-content: start;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-template-rows: min-content;
  grid-row-start: 1;

  @media screen and (max-width: ${breakpoints.desktop}) {
    grid-row-start: auto;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    grid-gap: 20px 25px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const isHistory = propEq('state_machine', 'history')

const TenantDashboardHome = ({
  history,
  match: {
    params: { listingId }
  },
  contract
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const timelines = useSelector(state =>
    select.chat.tenantTimelines(state, { listingId })
  )
  const tenants = Object.values(contract?.tenants || {})
  useEffect(() => {
    dispatch.listing.getListingTimelines(listingId)
  }, [dispatch.listing, listingId])

  const listingAddress =
    (contract.unit_number ? `${contract.unit_number} - ` : '') +
    contract.address
  const contractHasEnded = isHistory(contract)
  const unreadCount = timelines.reduce((count, timeline) => {
    const { last_read, last_event } = timeline
    if (!last_read || moment(last_event).isAfter(last_read)) {
      count++
    }
    return count
  }, 0)

  const duplicateData = async () => {
    const { body, error } = await dispatch.listing.cloneListing(listingId)
    if (!error) {
      close()
      const newlistingId = headKey(body.listings)
      history.replace(`/listing/create/${newlistingId}`)
    }
  }

  const verifyCreateListing = async () => {
    const { error, body } = await dispatch.listing.verifyCreateListing({
      unit_number: contract.unit_number,
      street_number: contract.street_number,
      zip: contract.zip
    })
    if (error) {
      return error
    }
    if (!body.listing_allowed_to_clone) {
      toast(body.message, {
        title: t('common.error', 'Error'),
        iconId: 'error',
        autoClose: 6000
      })
    }
    if (body.listing_allowed_to_clone) {
      return body.message
        ? showRelistModal({ listingId, history })
        : duplicateData(listingId)
    }
  }

  const breadcrumbs = [
    {
      to: '/tenants',
      label: t('l.tenants.view.all_tenants.link', 'All Tenants')
    },
    { to: null, label: t('l.tenants.view.dashboard.link', 'Dashboard') }
  ]

  return (
    <Wrapper>
      <Helmet>
        <title>{listingAddress}</title>
      </Helmet>
      <DashboardHeader>
        <Breadcrumbs items={breadcrumbs} />
      </DashboardHeader>
      <AddressSection>
        <SectionHeading>{listingAddress}</SectionHeading>
        <ListingLinks>
          <ListingLink to={`/tenant/${listingId}/details`}>
            {t('l.tenants.view.view_listing.link', 'View listing')}
          </ListingLink>
          <ListingLink
            as="button"
            onClick={() => verifyCreateListing(listingId)}
          >
            {t(
              'l.tenants.view.relist_this_listing.link',
              'Re-list this listing'
            )}
          </ListingLink>
        </ListingLinks>
      </AddressSection>
      <DashboardGrid>
        <TenantInfo tenants={tenants} contract={contract} />
        <MenuCardsGrid>
          <TenantMenuCards
            contract={contract}
            unreadCount={unreadCount}
            paymentsLinkDisabled={contractHasEnded}
          />
        </MenuCardsGrid>
      </DashboardGrid>
    </Wrapper>
  )
}

export default TenantDashboardHome
