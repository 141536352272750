import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'
import { colors, fontSizes, breakpoints } from 'styles'
import { AnchorButton } from 'components/anchor'
import AreaTitle from 'components/area-title'
import ButtonBar from 'components/button-bar'
import { Button } from 'components/button'
import PageTitle from 'components/page-title'
import Page from 'components/page'
import Panel from 'components/panel'
import Icon from 'components/icon'
import ContentWrapper from 'components/content-wrapper'
import UploadButton from './upload-button'
import { show as showSampleDocumentModal } from './sample-document-modal'
import { show as showVerifyAddressModal } from './verify-address-modal'
import { show as showVerifyCodeModal } from './verify-code-modal'

const StyledAreaTitle = styled(AreaTitle)`
  height: auto;
  line-height: 26px;
  margin-left: 20px;
  margin-right: 20px;
`

const StyledPanel = styled(Panel)`
  padding: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  padding: 50px 0;

  :first-child {
    border-bottom: 1px solid ${colors.grey};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    align-items: center;
  }
`

const Label = styled.p`
  display: flex;
  align-items: center;
  width: 180px;
  color: ${colors.regular};
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.31px;
  line-height: 21px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-bottom: 10px;
    width: 300px;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: auto;
    margin-bottom: 20px;
    text-align: center;
  }
`

const Description = styled.p`
  flex: 1;
  color: ${colors.regular};
  font-size: ${fontSizes.small};
  letter-spacing: -0.08px;
  line-height: 18px;
  margin: 0;
  max-width: 300px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    text-align: center;
    margin-bottom: 30px;
  }
`

const ContactText = styled(Description)`
  text-align: center;
  margin: 50px auto;

  a {
    color: ${colors.link};
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    margin-bottom: 0;
  }
`

const StyledButton = styled(AnchorButton)`
  letter-spacing: -0.39px;
  min-width: 120px;
  padding: 0 20px;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: -3px;
`

const VerifyListing = ({
  match: {
    params: { listingId }
  },
  history
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const listingData = useSelector(path(['listing', listingId]))
  const typesRequested = listingData?.listings?.verified_request_types || []
  const documentSubmitted = typesRequested.includes('file_upload')
  const postcardSent = typesRequested.includes('mail')

  useEffect(() => {
    dispatch.listing.load(listingId)
  }, [dispatch.listing, listingId])

  if (!listingData) return null

  const documentText = documentSubmitted ? (
    t(
      'l.verify_listing.view.proof_document_submitted.text',
      `We're busy verifying your document. Thanks for your patience!`
    )
  ) : (
    <>
      {t(
        'l.verify_listing.view.upload_proof_document.text',
        'Upload your land title document or property tax notice.'
      )}
      <StyledIcon
        id="info_blue"
        width={16}
        height={16}
        onClick={showSampleDocumentModal}
      />
    </>
  )

  const documentButton = documentSubmitted ? (
    <StyledButton height={48} as="button" role="button" disabled aria-disabled>
      {t('l.verify_listing.view.pending_approval.button', 'Pending Approval')}
    </StyledButton>
  ) : (
    <UploadButton listingId={listingId} />
  )

  const mailText = postcardSent
    ? t(
        'l.verify_listing.view.postcard_sent.text',
        'A postcard is on the way! (can take 5-10 business days) Enter the 6-digit verification code.'
      )
    : t(
        'l.verify_listing.view.request_verify_by_mail.text',
        `Receive a verification postcard to this listing's address. This can take 5-10 business days.`
      )

  const mailButton = postcardSent ? (
    <StyledButton
      height={48}
      as="button"
      role="button"
      onClick={() => showVerifyCodeModal({ listingId, history })}
    >
      {t('l.verify_listing.view.enter_code.button', 'Enter Code')}
    </StyledButton>
  ) : (
    <StyledButton
      height={48}
      as="button"
      role="button"
      onClick={() =>
        showVerifyAddressModal({
          listingData,
          onGetSupport: () => history.push('/chat/support-room')
        })
      }
    >
      {t('l.verify_listing.view.request.button', 'Request')}
    </StyledButton>
  )

  return (
    <Page>
      <ContentWrapper className="mb-50">
        <PageTitle>
          {t('l.verify_listing.view.verify_listing.title', 'Verify Listing')}
        </PageTitle>
        <StyledAreaTitle>
          {t(
            'l.verify_listing.view.verify_your_listing.title',
            'Choose one method to verify your listing'
          )}
        </StyledAreaTitle>
        <StyledPanel hSpace={30} vSpace={30}>
          <Row>
            <LabelWrapper>
              <Label>
                {t(
                  'l.verify_listing.view.proof_document.label',
                  'Verify by Proof Document'
                )}
              </Label>
              <Description>{documentText}</Description>
            </LabelWrapper>
            {documentButton}
          </Row>
          <Row>
            <LabelWrapper>
              <Label>
                {t(
                  'l.verify_listing.view.verify_by_mail.label',
                  'Verify by Mail'
                )}
              </Label>
              <Description>{mailText}</Description>
            </LabelWrapper>
            {mailButton}
          </Row>
        </StyledPanel>
        <ContactText>
          <Trans i18nKey="l.verify_listing.view.postcard_sent_contact_us.text">
            Contact <a href="mailto:support@liv.rent">support@liv.rent</a> if
            you need help.
          </Trans>
        </ContactText>
      </ContentWrapper>
      <ButtonBar sticky>
        <Button
          theme="sane"
          width={150}
          inversed
          disabled={false}
          onClick={() => history.replace(`/listing/${listingId}`)}
        >
          {t('l.verify_listing.view.back.button', 'Back')}
        </Button>
      </ButtonBar>
    </Page>
  )
}

export default VerifyListing
