import React, { useMemo } from 'react'
import { withRouter } from 'react-router'
import ChatSideBar from '../chat-side-bar'
import { ChatProvider } from '../chat-side-bar/chat-context'

const SideBar = ({
  match: {
    params: { listingId }
  },
  closeNav,
  isOpen,
  basePath,
  timelines,
  sideBarHeader,
  chatFooterButton
}) => {
  const getPath = timelineId => `/${basePath}/${listingId}/chat/${timelineId}`

  const tabs = useMemo(
    () => [
      {
        id: 'inquiries',
        data: timelines
      }
    ],
    [timelines]
  )

  return (
    <ChatProvider value={getPath}>
      <ChatSideBar
        isOpen={isOpen}
        closeNav={closeNav}
        initialTab="inquiries"
        tabs={tabs}
        top="0"
        sideBarHeader={sideBarHeader}
        chatFooterButton={chatFooterButton}
      />
    </ChatProvider>
  )
}

export default withRouter(React.memo(SideBar))
