import React, { useState } from 'react'
import styled from 'styled-components'
import Panel from 'components/panel'
import AvoidBreakInside from 'components/avoid-break-inside'
import useRefCallback from 'hooks/use-ref-callback'
import { colors, fontWeights, fontSizes, breakpoints } from 'styles'
import { useTranslation } from 'react-i18next'
import FormItem from './form-item'

const StyledPanel = styled(Panel)`
  position: relative;
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 20px 10px 0;
  }
`

const PanelHeader = styled.div`
  height: 100px;
  display: flex;
  align-items: flex-end;

  &:not(:first-child) {
    height: 110px;
  }

  a:only-child {
    margin-left: auto;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin: 0 10px;
  }
`

const PanelTitle = styled.h2`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.medium};
  line-height: 30px;
  margin-right: auto;
  @media screen and (max-width: 414px) {
    font-size: 15px;
    line-height: 20px;
  }
`

const EditButton = styled.button.attrs({ type: 'button' })`
  height: 28px;
  width: auto;
  padding: 0 15px;
  border: 1px solid ${colors.mediumGrey};
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  line-height: 12px;
  text-align: center;
  &:disabled {
    opacity: 0.3;
  }
  @media screen and (max-width: 414px) {
    padding: 0 10px;
    font-size: 10px;
  }
`

export const FormWrapper = styled.div`
  position: relative;
`

export const EditText = styled.p`
  color: ${colors.secondary};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  line-height: 12px;
  position: absolute;
  top: -38px;
  right: -24px;
`

const Anchor = styled.div`
  position: absolute;
  top: ${props => props.top || -70}px;
  visibility: hidden;
`

export const RequiredText = styled.p`
  color: #fe5f55;
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  line-height: 19px;
  margin: 0;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 11px;
    font-weight: normal;
    margin: 0 10px;
  }
`

const MultiRowForm = ({
  title,
  addText,
  data = [],
  initialVisible = false,
  editable = true,
  AddComponent,
  EditComponent,
  ViewComponent,
  EmptyComponent,
  disabled = false,
  showRequiredWarning = false,
  anchorTop
}) => {
  const { t } = useTranslation()
  const [adding, setAdding] = useState(initialVisible)
  const [addRef] = useRefCallback(node => {
    node.scrollIntoView({
      behavior: 'smooth'
    })
  })
  return (
    <AvoidBreakInside>
      <PanelHeader>
        <PanelTitle>{title}</PanelTitle>
        {editable && (
          <EditButton onClick={() => setAdding(true)} disabled={disabled}>
            {addText}
          </EditButton>
        )}
      </PanelHeader>
      {showRequiredWarning && (
        <RequiredText>
          {t('r.profile.view.required.label', '*Required')}
        </RequiredText>
      )}
      {(!adding || !!data.length) && (
        <StyledPanel hSpace={40} vSpace={0}>
          {!data.length && EmptyComponent}
          {data.map(item => (
            <FormItem
              key={item.id}
              data={item}
              editable={editable}
              ViewComponent={ViewComponent}
              EditComponent={EditComponent}
            />
          ))}
        </StyledPanel>
      )}
      {adding && (
        <StyledPanel hSpace={40} vSpace={40} top={4}>
          <Anchor top={anchorTop} ref={addRef} />
          <FormWrapper>
            <AddComponent
              onCancel={() => setAdding(false)}
              onSave={() => setAdding(false)}
            />
          </FormWrapper>
        </StyledPanel>
      )}
    </AvoidBreakInside>
  )
}

export default React.memo(MultiRowForm)
