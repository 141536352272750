import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { select } from 'store'
import { Formik } from 'formik'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { pipe, reject, append, when, reduce } from 'ramda'
import { createModal, useModalize } from 'components/modalize'
import { fontSizes, fontWeights } from 'styles'
import arrayMove from 'utils/array-move'
import Icon from 'components/icon'
import './styles.css'
import ExpandableTextArea from './expandable-text-area'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-shrink: 0;
  height: 667px;
  max-height: 75vh;
  max-width: 767px;
  padding-top: 20px;
  position: relative;
`

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const FormContentWrapper = styled(FormWrapper)`
  padding: 0 30px;
`

const ModalClose = styled.div`
  text-align: right;
  margin-right: 20px;

  svg {
    cursor: pointer;
    margin: 0;
  }
  :hover {
    opacity: 0.5;
  }
`

const Title = styled.h3`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 35px;
  margin: 30px 0 20px 0px;
  padding: 0px;
`

const FieldWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`

const ScrollWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 0px;
  padding-bottom: 80px;
`

const SortContainer = SortableContainer(({ children }) => <div>{children}</div>)
const SortItem = SortableElement(FieldWrapper)

//insert message with generated id
const insertMessage = (messages, messageContent) =>
  append(
    {
      id: messageContent + messages.length,
      content: messageContent
    },
    messages
  )

const removeMessage = (messages, targetId) =>
  reject(message => message.id === targetId, messages)

const updateMessage = (messages, message) =>
  messages.map(msg => (msg.id === message.id ? message : msg))

/*eslint-disable-next-line max-statements*/
const EditTemplateModal = ({ maxTemplates = 10 }) => {
  const [t] = useTranslation()
  const { close } = useModalize()
  const presetMessages = useSelector(select.chatPreset.presets)
  const dispatch = useDispatch()
  const idNewMessage = 'new'

  //use state to instantly reflect changes without waiting for backend response
  const [currentFormValues, setCurrentFormValues] = useState(presetMessages)
  const [expandedField, setExpandedField] = useState()

  //form needs to reinitialize when state updates.
  //but shouldn't reinitialize for sorting that way current input isn't lost
  const isFormReinitializeEnabled = useRef(true)

  useEffect(() => {
    setCurrentFormValues(presetMessages)
  }, [setCurrentFormValues, presetMessages])

  const saveMessage = async message => {
    const { id, content } = message
    let result
    if (id === idNewMessage) {
      result = await dispatch.chatPreset.createPreset(content)
    } else {
      result = await dispatch.chatPreset.modifyPreset(message)
    }

    if (!result) {
      setCurrentFormValues(presetMessages)
    }
  }

  const deleteMessage = async messageId => {
    if (messageId === idNewMessage) return
    const result = await dispatch.chatPreset.deletePreset(messageId)

    if (!result) {
      setCurrentFormValues(presetMessages)
    }
  }

  const updateMessagePosition = async (presetId, position) => {
    const result = await dispatch.chatPreset.setPresetPosition({
      presetId,
      position
    })

    if (!result) {
      setCurrentFormValues(presetMessages)
    }
  }

  const formOptions = () => {
    const formattedFormValues = pipe(
      reject(msg => !msg),
      when(
        msgs => msgs.length < maxTemplates,
        append({ id: idNewMessage, content: '' })
      ),
      reduce(
        (total, currentItem) => ({
          ...total,
          [currentItem.id]: currentItem.content
        }),
        {}
      )
    )(currentFormValues)
    return {
      initialValues: {
        ...formattedFormValues
      },
      enableReinitialize: isFormReinitializeEnabled.current
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    isFormReinitializeEnabled.current = false
    updateMessagePosition(currentFormValues[oldIndex].id, newIndex + 1)
    setCurrentFormValues(arrayMove(currentFormValues, oldIndex, newIndex))
  }

  return (
    <Wrapper>
      <ModalClose>
        <Icon
          aria-label="Close"
          id="close"
          width={24}
          height={24}
          onClick={close}
        />
      </ModalClose>
      <ScrollWrapper>
        <Title>
          {t('l.chat.reply_template_modal.title.text', 'Quick Reply Templates')}
        </Title>

        <Formik {...formOptions()}>
          {({ values, setFieldValue, resetForm }) => {
            const onDone = messageId => {
              isFormReinitializeEnabled.current = true
              setExpandedField(null)
              const message = {
                id: messageId,
                content: values[messageId]
              }
              saveMessage(message)
              setCurrentFormValues(
                messageId === idNewMessage
                  ? insertMessage(currentFormValues, values[messageId])
                  : updateMessage(currentFormValues, message)
              )
            }
            const onMessageDelete = messageId => {
              isFormReinitializeEnabled.current = true
              resetForm()
              setExpandedField(null)
              deleteMessage(messageId)
              setCurrentFormValues(removeMessage(currentFormValues, messageId))
            }
            const changeInputFocus = target => {
              if (!!expandedField) resetForm()
              setExpandedField(target)
            }
            return (
              <FormWrapper>
                <FormContentWrapper>
                  <SortContainer
                    onSortEnd={onSortEnd}
                    helperClass="detached-sortable-element"
                    pressDelay={100}
                  >
                    {currentFormValues.map((message, index) => (
                      <SortItem
                        key={message.id}
                        index={index}
                        pressDelay={100}
                        disabled={
                          !values[message.id] || expandedField === message.id
                        }
                      >
                        <ExpandableTextArea
                          isExpanded={expandedField === message.id}
                          onClick={changeInputFocus}
                          onDelete={onMessageDelete}
                          onDone={onDone}
                          name={message.id}
                          maxLength={500}
                          expandedHeight={100}
                          previewPlaceholder="Add new template"
                          editPlaceholder={t(
                            'l.chat.reply_template_modal.edit.placeholder.label',
                            'Please enter a message.'
                          )}
                        />
                      </SortItem>
                    ))}
                    {currentFormValues.length < maxTemplates && (
                      <FieldWrapper>
                        <ExpandableTextArea
                          isReorderEnabled={false}
                          isExpanded={expandedField === idNewMessage}
                          onClick={changeInputFocus}
                          onDelete={onMessageDelete}
                          onDone={onDone}
                          name={idNewMessage}
                          maxLength={500}
                          expandedHeight={100}
                          previewPlaceholder="Add new template"
                          editPlaceholder={t(
                            'l.chat.reply_template_modal.edit.placeholder.label',
                            'Please enter a message.'
                          )}
                        />
                      </FieldWrapper>
                    )}
                  </SortContainer>
                </FormContentWrapper>
              </FormWrapper>
            )
          }}
        </Formik>
      </ScrollWrapper>
    </Wrapper>
  )
}

export const show = createModal(EditTemplateModal, {
  width: '375px',
  clickOutside: false
})
