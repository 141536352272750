import React from 'react'
import styled from 'styled-components'
import { PinkButton } from 'components/anchor'
import Content from 'components/content'
import Modal from 'components/modal'
import Page from 'components/page'
import Taskbar from 'components/taskbar'
import Tool from 'components/tools'
import scrollTop from 'utils/scroll-top'
import ProgressWizard from './progress-wizard'
import WizardContent from './wizard-content'

const CONTENT_ID = 'modal-wizard-content'

const Wrapper = styled(Page)`
  max-width: 720px;
  margin: auto;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px 0 60px 0;
`

const Button = styled(PinkButton)`
  width: 60%;
`

class ModalWizard extends React.Component {
  constructor(props) {
    super(props)

    this.initialState = {
      currentStep: props.currentStep
    }

    this.state = { ...this.initialState }
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount() {
    this.resetScroll = scrollTop(CONTENT_ID)
  }

  componentDidUpdate(prevProps, prevState) {
    this.setButtonLabel(prevState)
    this.setCurrentStep(prevProps)
  }

  onClose() {
    this.props.onClose(this.state.data)
  }

  setButtonLabel(prevState) {
    const incompleteSteps = this.props.data.filter(({ complete }) => !complete)
      .length
    const { label: currentLabel, currentStep } = this.state
    let label = null

    if (incompleteSteps > 1) {
      label = this.props.continueLabel
    } else if (prevState.currentStep !== currentStep) {
      label = this.props.doneLabel
    }

    if (label && currentLabel !== label) {
      this.setState({ label })
    }
  }

  setStep(step) {
    this.setState({ currentStep: step })
    this.resetScroll()
  }

  setCurrentStep(prevProps) {
    if (this.props.isVisible && this.props.isVisible !== prevProps.isVisible) {
      this.setStep(this.props.currentStep)
    }
  }

  getIncompleteSteps() {
    return this.props.data.filter(({ complete }) => !complete)
  }

  getStepKey(step) {
    const { getKey } = this.props
    return getKey ? getKey(step) : step.key
  }

  getSteps() {
    const { data, hideComplete } = this.props
    const { currentStep } = this.state
    const steps = hideComplete
      ? data.filter(
          step => !step.complete || currentStep === this.getStepKey(step)
        )
      : data

    // Move any steps before the currently selected
    // step at the end of the current step list
    const stepIndex = steps.findIndex(
      item => this.getStepKey(item) === currentStep
    )
    const before = steps.slice(0, stepIndex)
    const after = steps.slice(stepIndex)

    return [...after, ...before]
  }

  getProgress() {
    const { data } = this.props
    const completed = data.filter(item => item.complete)
    return (completed.length / data.length) * 100
  }

  goToNextStep(steps) {
    if (steps.length === 1) {
      this.onClose()
    } else {
      const itemIndex = steps.findIndex(
        item => this.getStepKey(item) === this.state.currentStep
      )
      const newStep = steps[itemIndex + 1]
      this.setStep(this.getStepKey(newStep))
    }
  }

  isButtonDisabled() {
    const currentStep =
      this.props.data.find(
        item => this.getStepKey(item) === this.state.currentStep
      ) || {}
    return !currentStep.complete
  }

  renderStep(step, index, arr) {
    const { renderStep: render } = this.props
    const key = this.getStepKey(step)
    return (
      <Step index={key} key={key}>
        {render(step, index, arr)}
      </Step>
    )
  }

  render() {
    const { isVisible, contentId } = this.props
    const { currentStep, label } = this.state
    const validSteps = this.getSteps()

    return (
      <Modal isVisible={isVisible}>
        <Wrapper>
          <ProgressWizard progress={this.getProgress()} />
          <Taskbar left={<Tool type="close" onClick={this.onClose} />} />
          <Content id={contentId}>
            <WizardContent currentStep={currentStep}>
              {validSteps.map((step, index, arr) =>
                this.renderStep(step, index, arr)
              )}
            </WizardContent>
            <ButtonWrapper>
              <Button
                onClick={() => this.goToNextStep(this.getSteps())}
                disabled={this.isButtonDisabled()}
              >
                {label}
              </Button>
            </ButtonWrapper>
          </Content>
        </Wrapper>
      </Modal>
    )
  }
}

ModalWizard.defaultProps = {
  data: [],
  isVisible: false,
  hideComplete: false,
  getKey: null,
  currentStep: null,
  continueLabel: 'Continue',
  doneLabel: 'Done',
  contentId: CONTENT_ID
}

export default ModalWizard
