import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { getLandlordPaymentReceipt } from 'models/payment/ports'
import SuiteInfo from 'features/Listing/suite-info'
import LandlordPaymentHistory from 'features/Payments/landlord-payment-history'

const Wrapper = styled.div``

const TenantPayment = ({
  match: {
    params: { listingId, paymentId }
  },
  history
}) => {
  const { t } = useTranslation()
  const [paymentData, setPaymentData] = useState({})

  useEffect(() => {
    const loadPaymentData = async () => {
      const { body } = await getLandlordPaymentReceipt({
        paymentRequestId: paymentId
      })
      setPaymentData(body)
    }
    if (paymentId) {
      loadPaymentData()
    }
  }, [paymentId])

  if (!paymentData || isEmpty(paymentData)) return null

  return (
    <Wrapper>
      <Helmet>
        <title>{t('b.payments.view.payments.title', 'Payments')}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SuiteInfo listingId={listingId} />
      <LandlordPaymentHistory
        paymentId={paymentId}
        paymentData={paymentData}
        history={history}
      />
    </Wrapper>
  )
}

export default TenantPayment
