import React from 'react'
import styled from 'styled-components'
import { colors } from 'styles'

const Span = styled.span`
  & {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: ${props => (props.checked ? '50%' : 0)};
  border-radius: 17px;
  background-color: ${colors.secondary};
  border: 2px solid ${colors.darkGrey};
  z-index: 2;
  transition: left 150ms linear;
 }

 &:before {
  content: '${props => (props.checked ? props.option1 : props.option2)}';
  position: absolute;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${colors.white};
 }
`
const Input = styled.input`
  opacity: 0;
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 135px;
  height: 31px;
  background-color: ${colors.darkGrey};
  border-radius: 17px;

 &:before,
 &:after {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
 }
 
 &:before {
  content: '${props => props.option2}';
  left: 0;
  z-index: 1;
  color: ${colors.white};

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 }
 
 &:after {
  content: '${props => props.option1}';
  right: 0;
  z-index: 1;
  color: ${colors.white};

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 }
`

const LabeledToggle = props => (
  <Label option1={props.option1} option2={props.option2}>
    <Input type="checkbox" onChange={props.onChange} />
    <Span
      checked={props.checked}
      option1={props.option1}
      option2={props.option2}
    />
  </Label>
)

LabeledToggle.defaultProps = {
  checked: false
}

export default LabeledToggle
