import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useTranslation, Trans } from 'react-i18next'
import { colors, fontWeights } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  border: 1px solid ${colors.mediumLightGrey};
  margin: 0 auto;
  padding: 20px;
  background-color: ${colors.white};
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  :hover {
    border-color: ${colors.secondary};
  }
`

const Title = styled.p`
  margin: 0 0 20px 0;
  font-weight: bold;
`

const DueText = styled.p`
  color: ${props => (props.isPast ? colors.red : colors.regualr)};
  letter-spacing: -0.39px;
  line-height: 20px;
  margin: 0px;

  strong {
    font-weight: ${fontWeights.medium};
  }
`

const PaymentCard = ({ data = {}, onClick }) => {
  const { t } = useTranslation()
  const todayDate = moment().format('YYYY-MM-DD')
  const paidOnDate = moment(data.paid_at).format('MMM DD, YYYY')

  const wasDueOnDate = `${t(
    'l.listing.view.payment_was_due_on.text',
    'was due on'
  )} ${moment(data.due_date).format('MMM DD, YYYY')}`

  const isDueInDate = `${t(
    'l.listing.view.payment_is_due_in.text',
    'is due in'
  )} ${moment(data.due_date).diff(todayDate, 'days')} ${t(
    'l.listing.view.payment_days.text',
    'days'
  )}`

  const formatDate = moment(data.due_date).calendar(null, {
    lastWeek: function (now) {
      return `[${wasDueOnDate}]`
    },
    lastDay: `[${t(
      'l.listing.view.payment_was_due_yesterday.text',
      'was due Yesterday'
    )}]`,
    sameDay: `[${t(
      'l.listing.view.payment_is_due_today.text',
      'is due Today'
    )}]`,
    nextDay: `[${t(
      'l.listing.view.payment_is_due_tomorrow.text',
      'is due Tomorrow'
    )}]`,
    nextWeek: function (now) {
      return `[${isDueInDate}]`
    },
    sameElse: function (now) {
      if (this.isBefore(todayDate)) {
        return `[${wasDueOnDate}]`
      }
      return `[${isDueInDate}]`
    }
  })

  return (
    <Wrapper onClick={onClick} data-testid={`payment-${data.id}`}>
      <Title>{data.description}</Title>
      {data.state_machine === 'pending' ? (
        <DueText isPast={moment(data.due_date).isBefore(todayDate)}>
          <Trans i18nKey="l.listing.view.payment_of.text">
            Payment of <strong>${{ amount: data.requested_amount }}</strong>
          </Trans>{' '}
          {formatDate}
        </DueText>
      ) : (
        <DueText>
          <Trans
            i18nKey="l.listing.view.paid_on.text"
            values={{ date: paidOnDate }}
            defaults="Paid on {{date}}"
          />
        </DueText>
      )}
    </Wrapper>
  )
}

export default PaymentCard
