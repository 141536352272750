import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getImageUrl } from 'utils/image'
import { getPaymentFrequency, formatListingPrice } from 'utils/listings'
import { isFalsy } from 'utils'
import useGetSuiteInfo from 'hooks/get-suite-info'
import useRole from 'hooks/use-role'
import { colors, fontSizes, fontWeights, breakpoints } from 'styles'
import { join, reject, pipe } from 'ramda'
import toNumber from 'lodash/toNumber'

const Wrapper = styled.div`
  display: flex;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    flex-direction: column;
  }
`

const Image = styled.img`
  margin-right: 20px;
  max-height: 88px;
  object-fit: cover;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-right: 0;
    max-height: 250px;
    height: 250px;
  }
`

const Address = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.38px;
  line-height: 24px;
  margin: 5px 0 10px;

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin-top: 15px;
  }
`

const Info = styled.span`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: -0.3px;
  line-height: 24px;
  display: inline-block;

  :not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid ${colors.darkGrey};
  }

  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 14px;
  }
`

const InfoWrap = styled(Info)`
  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    display: block;
    border: none !important;
  }
`

const formatAddress = (address, role) => {
  const unitHidden = !!Number(address.hide_unit_number) && role === 'tenant'
  const unit =
    address.unit_number && !unitHidden ? `${address.unit_number} - ` : ''
  const isApartment = address.building_type !== 'house'
  const rest = pipe(
    reject(isFalsy),
    join(', ')
  )([
    !unitHidden || isApartment ? address.full_street_name : address.street_name,
    address.city,
    address.state,
    address.zip?.toUpperCase()
  ])
  return `${unit}${rest}`
}

const ListingInfo = ({ children, listingId, hideImage, hideAddress }) => {
  const { t } = useTranslation()
  const data = useGetSuiteInfo(listingId)
  const role = useRole()

  if (!data) return null

  const bedrooms = () => {
    if (data.unit_type_scope_txt_id === 'single')
      return t('b.listing.view.private_room.text', 'Private room')

    const count = toNumber(data.bedrooms)
    if (count === 0) return t('common.studio')
    return t('b.listing.view.count_bedrooms.text', {
      count,
      defaultValue: '{{count}} Bedroom',
      defaultValue_plural: '{{count}} Bedrooms'
    })
  }

  const bathrooms = () => {
    return t('b.listing.view.count_bathrooms.text', {
      count: toNumber(data.bathrooms),
      defaultValue: '{{count}} Bathroom',
      defaultValue_plural: '{{count}} Bathrooms'
    })
  }

  const renderPrice = listing => {
    const { gr_min_price, price, gr_unit } = listing
    const isGroupListing = gr_unit === '1'
    return isGroupListing ? (
      <>
        {t('b.listings.view.starting_at.text', 'Starting at')}{' '}
        {formatListingPrice(gr_min_price)}
      </>
    ) : (
      formatListingPrice(price)
    )
  }
  const elements = React.Children.map(children,
    (el, key) => <InfoWrap key={`listing-info-${key}`}>{el}</InfoWrap>
  )
  return (
    <Wrapper>
      {!hideImage && (
        <Image
          src={getImageUrl(data.cover_photo, { width: 369, height: 264 })}
          alt=""
        />
      )}
      <div>
        {elements}
        { !hideAddress && <Address>{formatAddress(data, role)}</Address> }
        <Info>
          {renderPrice(data)} /{' '}
          {getPaymentFrequency(data.price_frequency)?.toLowerCase()}
        </Info>
        <Info>{bedrooms()}</Info>
        {data.unit_type_scope_txt_id === 'entire' && <Info>{bathrooms()}</Info>}
      </div>
    </Wrapper>
  )
}

export default React.memo(ListingInfo)
