import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, breakpoints, fontSizes, fontWeights, structure } from 'styles'
import { toUnit } from 'utils/styles'
import { leftCloud, rightCloud, rightCloudSmall } from 'components/clouds'

import backgroundBottom from './images/background-bottom.png'

const Wrapper = styled.div`
  padding: 70px 50px 100px 100px;
  min-height: calc(100vh - ${toUnit(structure.header.height)});
  background: ${colors.white};

  background-image: url(${leftCloud}), url(${rightCloud}),
    url(${backgroundBottom});
  background-repeat: no-repeat;
  background-position: -45px 370px, 92% 40px, 100% 92%;
  background-size: 168px, 230px, 400px;

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    padding: 50px 40px 100px;

    background-image: url(${rightCloudSmall}), url(${backgroundBottom});
    background-position: 100% 30px, 100% 96%;
    background-size: 100px, 170px;
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 40px 25px 100px;
    min-height: calc(100vh - ${toUnit(structure.header.heightMobile)});
  }
`

const ContentWrapper = styled.div`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const SectionHeading = styled.h1`
  font-size: 25px;
  color: ${colors.regular};
  font-weight: ${fontWeights.medium};
  line-height: 34px;
  margin: 0 0 30px;
`

const Section = styled.section`
  margin-top: 50px;

  :not(:last-child) {
    padding-bottom: 60px;
    border-bottom: 1px solid ${colors.grey};
  }

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    margin-top: 30px;
  }
`

const SectionSubHeading = styled.h2`
  color: ${colors.regular};
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.medium};
  line-height: 24px;
  letter-spacing: -0.38px;
  margin: 0 0 30px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 325px;
  margin-top: 20px;
`

const Text = styled.p`
  color: ${colors.regular};
  font-size: ${fontSizes.regular};
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
`

const BoldText = styled(Text)`
  font-weight: ${fontWeights.medium};
`

const EmailLink = styled.a`
  color: ${colors.link};
`

const Support = () => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  return (
    <Wrapper>
      <ContentWrapper>
        <SectionHeading>
          {t('b.support.view.support.title', 'Support')}
        </SectionHeading>
        <Text>
          {t(
            'b.support.view.have_problem.text',
            "Have a problem? Have some suggestions to make your experience even better? We'd love to hear from you."
          )}
        </Text>
        <Section>
          <SectionSubHeading>
            {t('b.support.view.email_us.title', 'Email Us')}
          </SectionSubHeading>
          <EmailLink href="mailto:support@liv.rent">support@liv.rent</EmailLink>
          <Row>
            <Text>
              {t('b.support.view.monday_friday.text', 'Monday - Friday')}
            </Text>
            <BoldText>
              {t('b.support.view.10am_6pm.text', '10am - 6pm PST')}
            </BoldText>
          </Row>
          <Row>
            <Text>{t('b.support.view.saturday.text', 'Saturday')}</Text>
            <BoldText>
              {t('b.support.view.11am_4pm.text', '11am - 4pm PST')}
            </BoldText>
          </Row>
        </Section>
        <Section>
          <SectionSubHeading>
            {t('b.support.view.write_us.title', 'Write Us')}
          </SectionSubHeading>
          <BoldText>
            {t('b.support.view.liv_strategies.title', `Liv Strategies Inc. © `)}
            {currentYear}
          </BoldText>
          <Text>1A - 1601 Bayshore Dr</Text>
          <Text>Vancouver, BC</Text>
          <Text>V6G 2V4</Text>
        </Section>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Support
