import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toNumber } from 'lodash'
import { head } from 'ramda'
import { colors } from 'styles'
import { useDropzone } from 'react-dropzone'
import { AnchorButton } from 'components/anchor'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledButton = styled(AnchorButton)`
  letter-spacing: -0.39px;
  min-width: 120px;
  padding: 0 20px;
  cursor: pointer;
`

const ErrorText = styled.p`
  color: ${colors.pink};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 55px;
  width: 220px;
  right: 0px;
  text-align: right;
`

const UploadButton = ({ listingId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [rejectError, setRejectError] = useState('')
  const [isRejected, setIsRejected] = useState(false)

  const handleOnClick = () => {
    setIsRejected(false)
    open()
  }

  const onUploadAccepted = async files => {
    dispatch.listing.uploadVerifyDocument({
      listingId: toNumber(listingId),
      file: head(files)
    })
  }

  const sizeError = t(
    'b.profile.edit.max_upload_size.error',
    'Max file upload size is 6MB'
  )
  const typeError = t(
    'b.profile.edit.wrong_file_type.error',
    'Sorry, we do not support this file type'
  )
  const MAX_SIZE = 6 * 1000000

  const { getInputProps, open } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg, application/pdf',
    multiple: false,
    noKeyboard: true,
    maxSize: MAX_SIZE,
    onDrop: (_, d) => {
      if (d.length > 0) {
        d[0].size > MAX_SIZE
          ? setRejectError(sizeError)
          : setRejectError(typeError)
        setIsRejected(true)
      }
    },
    onDropAccepted: onUploadAccepted
  })

  return (
    <Wrapper>
      <input {...getInputProps()} />
      <StyledButton
        height={48}
        as="button"
        role="button"
        onClick={handleOnClick}
      >
        {t('l.verify_listing.view.upload.button', 'Upload')}
      </StyledButton>
      {isRejected && <ErrorText>{rejectError}</ErrorText>}
    </Wrapper>
  )
}

export default UploadButton
