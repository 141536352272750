import React from 'react'
import styled from 'styled-components'
import { colors, fontWeights } from 'styles'
import { confirmable } from 'react-confirm'
import Icon from 'components/icon'
import BaseDialog from './base'

const ModalWrapper = styled.div`
  width: 100%;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 10px;
  }
`
const ModalTitle = styled.p`
  font-size: 26px;
  font-weight: ${fontWeights.medium};
  line-height: 32px;
  text-align: center;
  margin-top: 10px;
`

const ModalText = styled.p`
  margin: 0 0 2px 0;
  text-align: center;
`

const isDisabled = () => false

const Confirmation = ({ title, messages, icon = 'warning', ...rest }) => (
  <BaseDialog {...rest} isDisabled={isDisabled}>
    <ModalWrapper>
      <Icon id={icon} width={73} height={73} />
      <ModalTitle>{title}</ModalTitle>
      {messages.map((message, index) => (
        <ModalText key={index}>{message}</ModalText>
      ))}
    </ModalWrapper>
  </BaseDialog>
)

export default confirmable(Confirmation)
