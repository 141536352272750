import { useRef, useEffect } from 'react'

const useSafeCallback = fn => {
  const latestFn = useRef(fn)
  useEffect(() => {
    latestFn.current = fn
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return latestFn.current
}

export default useSafeCallback
