import React from 'react'
import styled from 'styled-components'
import useRole from 'hooks/use-role'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { colors, fontSizes, fontWeights } from 'styles'
import { Button } from 'components/button'
import Content from 'components/content'
import { WhitePage } from 'components/page'
import PageTitle from 'components/page-title'
import ButtonBar from 'components/button-bar'
import { confirm } from 'components/dialog'
import { toast } from 'components/toast-notifications'
import ContentWrapper from 'components/content-wrapper'

import ReviewSignatures from '../review-signatures'
import ReviewDetailsContainer from '../review-details'

const SIGNATURES_ID = 'signatures'

const SubHeaderWrapper = styled.div`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.mediumLightGrey};

  @media only screen and (min-width: 719px) {
    width: 100%;
  }
`

const SubHeader = styled.div`
  max-width: 720px;
  margin: 0 auto;
  min-height: 80px;
  padding: 17px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SubHeaderLabel = styled.p`
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  flex: 99999;
`

const SubHeaderButton = styled.p`
  font-size: ${fontSizes.small};
  color: ${colors.secondary};
  font-weight: ${fontWeights.regular};
  cursor: pointer;
`

const StyledButton = styled(Button)`
  padding: 0px 15px;
  min-width: 150px;
`

const scrollToSignatures = () => {
  const elem = document.getElementById(SIGNATURES_ID)
  if (!!elem && elem.scrollIntoView) {
    elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

const Partial = ({ contract, user, history, noTitle }) => {
  const role = useRole()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleCancel = () => {
    if (role === 'tenant') {
      history.goBack()
    } else {
      confirm(
        t(
          'b.contract.edit.cancel_contract_confirmation.title',
          'Cancel Contract?'
        ),
        t(
          'b.contract.edit.cancel_contract_confirmation.text',
          'Are you sure you want to cancel this contract?'
        )
      ).then(async () => {
        const { response } = await dispatch.contract.cancelContract({
          contractId: contract?.id,
          listingId: contract?.listingId
        })
        if (response.ok) {
          history.push(`/listing/${contract?.listingId}`)
          toast(
            t(
              'b.contract.view.contract_cancelled.text',
              'The contract has been cancelled.'
            ),
            {
              title: t(
                'b.contract.view.contract_cancelled.title',
                'Contract Cancelled'
              ),
              iconId: 'square_check',
              autoClose: 6000
            }
          )
        }
      })
    }
  }

  return (
    <WhitePage>
      {!noTitle && (
        <PageTitle>{t('b.contract.view.contract.title', 'Contract')}</PageTitle>
      )}
      <SubHeaderWrapper>
        <SubHeader>
          <SubHeaderLabel>
            {t(
              'b.contract.view.awaiting_signature.link',
              'Awaiting signature...'
            )}
          </SubHeaderLabel>
          <SubHeaderButton onClick={scrollToSignatures}>
            {t('b.contract.view.see_status.link', 'See status')}
          </SubHeaderButton>
        </SubHeader>
      </SubHeaderWrapper>
      <ContentWrapper className="mb-50">
        <Content>
          <ReviewDetailsContainer
            contract={contract}
            userType={role}
            partialView
          />
          <div id={SIGNATURES_ID}>
            <ReviewSignatures
              contract={contract}
              userType={role}
              currentUser={user.id}
            />
          </div>
        </Content>
      </ContentWrapper>
      <ButtonBar sticky>
        <StyledButton
          theme="sane"
          inversed
          disabled={false}
          onClick={handleCancel}
        >
          {role === 'tenant'
            ? t('b.contract.view.back.button', 'Back')
            : t('b.contract.view.cancel_contract.button', 'Cancel Contract')}
        </StyledButton>
      </ButtonBar>
    </WhitePage>
  )
}

Partial.defaultProps = {
  contract: {}
}

export default withRouter(Partial)
