import { useEffect } from 'react'

const getIsMobile = userAgent => {
  const isAndroid = Boolean(userAgent.match(/Android/i))
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = Boolean(userAgent.match(/IEMobile/i))

  return Boolean(isAndroid || isIos || isOpera || isWindows)
}

const useIsMobile = () => {
  useEffect(() => {}, [])
  if (typeof navigator !== 'undefined') {
    return getIsMobile(navigator.userAgent)
  }
}

export default useIsMobile
