import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { breakpoints, fontSizes, fontWeights } from 'styles'
import Tool from 'components/tools'
import { Trans, useTranslation } from 'react-i18next'
import {
  LoginContainer,
  TaskbarContainer,
  TaskbarItem,
  Title
} from './elements'
import dog from './images/check-email.png'

const DescriptionText = styled.p`
  font-size: ${fontSizes.regular};
  font-weight: ${fontWeights.regular};
  margin-bottom: 30px;
  margin-top: 0;
  text-align: center;
`

const Bold = styled.span`
  font-weight: ${fontWeights.medium};
`

const Image = styled.div`
  width: 100%;
  height: 160px;
  background: url(${dog}) 50% 100% no-repeat;
  background-size: auto 100%;
  margin-top: 5px;
`

const Container = styled.div`
  width: 100%;
  padding: 0 15px;
  margin: 20px 0 25px;
  @media only screen and (max-width: ${breakpoints.phoneMax}) {
    margin: 20px 0 20px;
  }

  > p:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const CheckEmail = ({ history, location }) => {
  const email = location?.state?.email
  const { t } = useTranslation()
  const title = t('b.auth.modal.check_email.title', 'Check Your Email')
  useEffect(() => {
    if (!email) history.replace('/')
  }, [email, history])
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LoginContainer>
        <TaskbarContainer>
          <TaskbarItem left={<Tool to="/" type="back" />} />
          <Title>{title}</Title>
        </TaskbarContainer>
        <Container>
          <DescriptionText>
            <Trans i18nKey="b.auth.view.check_email.text" email={email}>
              We emailed a link to <Bold>{{ email }}</Bold>.
            </Trans>
          </DescriptionText>
          <DescriptionText>
            {t(
              'b.auth.view.sign_in_link.text',
              'Click on the link to sign in.'
            )}
          </DescriptionText>
          <Image />
        </Container>
      </LoginContainer>
    </>
  )
}

export default CheckEmail
