import React from 'react'
import styled, { keyframes } from 'styled-components'
import { breakpoints, colors } from 'styles'
import Notification from './Notification'
import Progress from './Progress'

const slideIn = keyframes`
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 85px;
  width: 375px;
  border: 1px solid ${colors.mediumLightGrey};
  border-radius: 6px;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
  z-index: 25;

  animation-duration: 0.3s;
  animation-name: ${slideIn};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 330px;
    padding: 15px;
  }
`

const COMPONENTS = {
  default: Notification,
  progress: Progress
}

const getComponent = toastType => COMPONENTS[toastType] || Notification

const ToastItem = ({ type, children, ...rest }) => {
  const Component = getComponent(type)
  return (
    <Wrapper>
      <Component {...rest}>{children}</Component>
    </Wrapper>
  )
}

export default ToastItem
