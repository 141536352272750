import React, { useEffect } from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles'
import TextButton from 'components/button/text-button'
import noop from 'lodash/noop'
import { translateMessage, translateSupportMessage } from '../../ports'

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isThem ? 'row-reverse' : 'row')};
`

const calendarStrings = {
  lastDay: 'MMM DD, h:mm a',
  sameDay: 'h:mm a',
  lastWeek: 'MMM DD, h:mm a',
  sameElse: 'MMM DD, h:mm a'
}

const TimeStampWrapper = styled.div`
  align-self: ${props =>
    props.isSystemMessage
      ? 'center'
      : props.isThem
      ? 'flex-start'
      : 'flex-end'};
  margin: ${props => (props.isSystemMessage ? '0' : '3px 0px 20px 0px')};
  font-size: 10px;
  min-height: 21px;
  color: ${colors.mediumGrey};
`

const TranslateButton = styled(TextButton)`
  ${props =>
    props.isTranslated &&
    `
    font-style: italic;
    color: ${colors.mediumGrey};
    text-decoration: underline;
    cursor: default;
    :hover {
      opacity: 1;
    }
  `}
  font-size: 10px;
  outline: none;
`

const Timestamp = ({
  isThem,
  event,
  isSystemMessage = false,
  setTranslatedText = noop,
  translatedText
}) => {
  const { i18n, t } = useTranslation()

  const currentLanguage = i18n.languages[0]
  useEffect(() => {
    setTranslatedText(undefined)
  }, [currentLanguage, setTranslatedText])

  const handleTranslate = async () => {
    const fn = isSupport ? translateSupportMessage : translateMessage
    const { body, response } = await fn(
      { body: { id: event.id, message: event?.event_info?.message } },
      { language: currentLanguage }
    )
    if (response.ok) {
      setTranslatedText(body[event.id]?.translated_text)
    }
  }
  const isSupport = event?.event_type === 'support_room_event_chat'
  const isChat = event?.event_type.match(
    /^(chat|support_room_event_chat|chat_initial_message)$/
  )
  const shouldTranslate =
    !(currentLanguage === 'en' && event.detected_lang === 'latin') &&
    !!event.detected_lang
  return (
    <TimeStampWrapper isThem={isThem} isSystemMessage={isSystemMessage}>
      {event.pending ? (
        t('b.chat.view.pending.text', 'Pending')
      ) : (
        <Wrapper isThem={isThem}>
          {shouldTranslate && isChat && (
            <>
              <TranslateButton
                isTranslated={!!translatedText}
                onClick={handleTranslate}
              >
                {!!translatedText
                  ? t('b.chat.chat_event.translated.text', 'Translated')
                  : t('b.chat.chat_event.translate.button', 'Translate')}
              </TranslateButton>
            </>
          )}
          <Moment calendar={calendarStrings}>
            {`${event?.created_at}-0000`}
          </Moment>
        </Wrapper>
      )}
    </TimeStampWrapper>
  )
}

export default Timestamp
