import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Manager, Reference, Popper } from 'react-popper'
import { breakpoints, colors } from 'styles'
import { CloseClickOutside } from 'components/window'
import useRole from 'hooks/use-role'
import { getUrl } from 'utils/listings'
import { useFlag } from 'flags'
import { confirm } from 'components/dialog'
import { NavLink } from 'react-router-dom'
import config from 'config'
import { showReportModal } from './report-problem-modal'
const { WEB_URL } = config

const MenuList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${colors.white};
  margin: 20px 0;
  margin: 22px 0;
  z-index: 200;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 6px 2px;
  padding: 0;
`

const MenuItemWrapper = styled.li`
  display: block;
  border-bottom: 1px solid ${colors.mediumLightGrey};
  cursor: pointer;
  line-height: 17px;
  letter-spacing: 0.1px;
  width: 100%;

  button,
  a {
    display: inline-block;
    width: 100%;
    color: ${colors.regular};
    font-size: 15px;
    padding: 15px 25px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    border: none;
  }

  ${props =>
    props.mobileOnly &&
    `
    display: none;
    @media screen and (max-width: 1120px) {
      display: block;
    }
  `}
`

const Link = styled.a``

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

const Clickable = ({ onClick, to, href, ...rest }) => {
  if (typeof onClick === 'function')
    return <Button onClick={onClick} {...rest} />
  if (href && typeof href === 'string') return <Link href={href} {...rest} />
  return <NavLink to={to} {...rest} />
}

const MenuItem = ({ children, onClick, to, href, ...rest }) => (
  <MenuItemWrapper {...rest}>
    <Clickable onClick={onClick} to={to} href={href}>
      {children}
    </Clickable>
  </MenuItemWrapper>
)

const MenuButton = styled.div`
  cursor: pointer;
  margin-left: 40px;

  &::after {
    content: '\\2807';
    font-size: 26px;
    color: ${colors.regular};
    font-family: none;
    padding: 3px;
    height: 20px;
    display: block;
  }

  @media screen and (max-width: ${breakpoints.tabletLandscape}) {
    margin-left: 15px;
  }
`

const Menu = React.memo(({ children }) => {
  const [opened, setOpen] = useState(false)
  const close = () => setOpen(false)
  const toggle = () => {
    setOpen(!opened)
  }
  return (
    <Manager>
      <Reference>
        {({ ref }) => <MenuButton ref={ref} onClick={toggle} />}
      </Reference>
      <Popper placement="bottom-end">
        {props => children({ ...props, opened, close })}
      </Popper>
    </Manager>
  )
})

Menu.displayName = 'Menu'

const ChatMenu = ({ timelineRoomId, data, history }) => {
  const { t } = useTranslation()
  const showMenuReport = useFlag(['features', 'chat', 'menuReport'])
  const dispatch = useDispatch()
  const isLandlord = useRole() === 'landlord'
  const url = getUrl({
    building_type: data?.buildings?.building_type_txt_id,
    city: data?.buildings?.city,
    listing_id: data?.listing_id
  })

  const handleDelete = () => {
    return confirm(
      t('b.chat.delete_chat_room.title', 'Delete Conversation?'),
      t(
        'b.chat.delete_chat_room_confirmation.text',
        'You will not be able to recover this conversation.'
      )
    ).then(async () => {
      await dispatch.chat.deleteTimeline(timelineRoomId)
      history.push('/chat')
    })
  }
  const onlyIf = (condition, value) => (condition ? value : undefined)
  return (
    <Menu>
      {({ ref, style, placement, opened, close }) =>
        opened && (
          <CloseClickOutside onClickOutside={close}>
            <MenuList ref={ref} style={style} data-placement={placement}>
              <MenuItem
                to={onlyIf(isLandlord, `/listing/${data?.listing_id}`)}
                href={onlyIf(!isLandlord, `${WEB_URL}${url}`)}
                mobileOnly
              >
                {t(
                  'b.chat.menu.listing_details.button',
                  'View listing details'
                )}
              </MenuItem>
              {showMenuReport && (
                <MenuItem onClick={() => showReportModal({ data })}>
                  {t('b.chat.menu.report_a_problem.button', 'Report a Problem')}
                </MenuItem>
              )}
              <MenuItem onClick={handleDelete}>
                {t('b.chat.menu.delete_chat.button', 'Remove this chat')}
              </MenuItem>
            </MenuList>
          </CloseClickOutside>
        )
      }
    </Menu>
  )
}

export default withRouter(ChatMenu)
